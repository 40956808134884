import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Auth } from '@weco/common';
import { LoginForm } from '@weco/ui';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import { useLoginPageStyles } from './styles/LoginPage.styles';
import { RouterPaths } from '../../router/RouterPaths';
import { Alert } from '@material-ui/lab';
import Link from '@material-ui/core/Link';
import { SentVerifyEmail } from './SentVerifyEmail';

const FORM_LAYOUT = 'form';
const SENT_LAYOUT = 'sent';

interface LoginPagePropsInterface {
    hideSocials?: () => void;
}

export const LoginPage = ({ hideSocials }: LoginPagePropsInterface) => {
    const history = useHistory();
    const { authenticate, resendSignUp } = useCurrentAuthData();
    const [error, setError] = useState<Error | null>(null);
    const classes = useLoginPageStyles();
    const [layout, setLayout] = useState(FORM_LAYOUT);
    const [username, setUsername] = useState();

    function authenticateViaPassword(email, password) {
        return authenticate(new Auth.UsernamePasswordToken(email, password))
            .then(() => {
                history.push(RouterPaths.dashboard);
            })
            .catch((e) => {
                setUsername(email);
                setLayout(SENT_LAYOUT);
                hideSocials();
                if (e.data.code === 'UserNotConfirmedException') {
                    resendSignUp(
                        new Auth.AmplifyResendSignUpToken(email),
                    ).catch(setError);
                } else {
                    setUsername(email);
                    setError(e);
                    return Promise.reject(e);
                }
            });
    }

    return (
        <Box className={classes.root}>
            {error && (
                <Box className={classes.alertContainer}>
                    <Alert severity="error">
                        {error.message || error}. Maybe try to&nbsp;
                        <Link href={RouterPaths.signup} variant="body2">
                            Signup
                        </Link>
                        ?
                    </Alert>
                </Box>
            )}
            <Typography
                variant="h2"
                align="center"
                className={classes.signInText}
            >
                Log In to WeCo
            </Typography>
            <LoginForm onSubmit={authenticateViaPassword} />
        </Box>
    );
};

LoginPage.URL = '/login';
