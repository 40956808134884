import { Amplify } from '@aws-amplify/core';
import { CHAT_SERVICES, ChatService } from '@weco/chat';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { onError } from 'apollo-link-error';
import {
    AnalyticsService,
    Auth,
    COMMON_SERVICES,
    ConfigService,
    CoreEventDispatcher,
    GTMProvider,
    MixpanelProvider,
    SmartlookProvider,
    ProvidersContainer,
    Exceptions,
} from '@weco/common';
import {
    CORE_SERVICES,
    CurrentUserProvider,
    MatchPercentService,
    PeopleRepository,
    PeopleService,
    PersonRepository,
    PersonService,
    ProjectRepository,
    ProjectsService,
    SkillSetRepository,
    SkillsService,
    TestService,
    PassedTestRepository,
} from '@weco/core';
import { QuizService } from '@weco/quiz';
import { NoticeStore } from '@weco/ui';
import {
    ApolloClient,
    ApolloLink,
    concat,
    HttpLink,
    InMemoryCache,
} from 'apollo-boost';
import firebase from 'firebase/app';
import initHelpHero from 'helphero';
import { PeopleMainPageStore } from './app/pages/main/People/store/PeopleMainPageStore';
import { ProjectsMainPageStore } from './app/pages/main/Projects/store/ProjectsMainPageStore';
// import { SchoolsStore } from './app/pages/main/Schools/store/SchoolsStore';
import OnboardingStore from './app/pages/onboarding/OnboardingStore';
import { QuizStore } from './app/pages/quiz/store/QuizStore';
import di from './app/services/di';
import { HelpHeroEventSubscriber } from './app/services/eventSubscribers/HelpHeroEventSubscriber';
import { MailchimpEventSubscriber } from './app/services/eventSubscribers/MailchimpEventSubscriber';
import { SERVICES } from './app/services/services';
import { AppStore } from './app/store/AppStore';
import { CoolsStore } from './app/store/CoolsStore';
import { ChatStore } from './app/store/ChatStore';
import { MyUserStore } from './app/store/MyUserStore';
import { RootStore } from './app/store/RootStore';
import { environment } from './environments/environment';
import { AnalyticsEventSubscriber } from './app/services/eventSubscribers/AnalyticsEventSubscriber';
import { ApplicationEventSubscriber } from './app/services/eventSubscribers/ApplicationEventSubscriber';
import { WeCoLoggedInEventSubscriber } from './app/services/eventSubscribers/WeCoLoggedInEventSubscriber';
import { CoolService } from '../../../libs/core/src/lib/services/CoolService';
import { CoolRepository } from '../../../libs/core/src/lib/repositories/CoolRepository';

export function bootstrapMainModule(): Promise<ProvidersContainer> {
    di.register([
        { provide: SERVICES.AppStore, useClass: AppStore },
        { provide: SERVICES.CoolsStore, useClass: CoolsStore },
        { provide: SERVICES.RootStore, useClass: RootStore },
        { provide: OnboardingStore, useClass: OnboardingStore },
        { provide: QuizStore, useClass: QuizStore },
        { provide: MyUserStore, useClass: MyUserStore },
        { provide: CHAT_SERVICES.ChatStore, useClass: ChatStore },
        MailchimpEventSubscriber,
        HelpHeroEventSubscriber,
        AnalyticsEventSubscriber,
        ApplicationEventSubscriber,
        WeCoLoggedInEventSubscriber,
        // VENDORS
        {
            provide: SERVICES.HelpHero,
            useFactory: (config: ConfigService) => {
                return config.has('helphero.clientId')
                    ? initHelpHero(config.get('helphero.clientId'))
                    : { identify: () => null, anonymous: () => null };
            },
            inject: [COMMON_SERVICES.ConfigService],
        },
        {
            provide: SERVICES.Sentry,
            useFactory: (config: ConfigService) => {
                if (config.has('sentry.dsn')) {
                    Sentry.init({
                        ...config.get('sentry'),
                        integrations: [new Integrations.BrowserTracing()],
                    });
                    return Sentry;
                } else {
                    return { identify: () => null, anonymous: () => null };
                }
            },
            inject: [COMMON_SERVICES.ConfigService],
        },
        {
            provide: SERVICES.FirebaseApp,
            useFactory: (config: ConfigService) =>
                firebase.initializeApp(config.get('firebaseConfig')),
            inject: [COMMON_SERVICES.ConfigService],
        },
        // Common Services
        {
            provide: COMMON_SERVICES.ConfigService,
            useFactory: () => new ConfigService(environment),
        },
        {
            provide: COMMON_SERVICES.NoticeStore,
            useFactory: () => new NoticeStore(),
        },
        {
            provide: COMMON_SERVICES.CoreEventDispatcher,
            useFactory: (...subscribers) =>
                new CoreEventDispatcher(subscribers),
            inject: [
                MailchimpEventSubscriber,
                HelpHeroEventSubscriber,
                AnalyticsEventSubscriber,
                ApplicationEventSubscriber,
                WeCoLoggedInEventSubscriber,
            ],
        },
        {
            provide: COMMON_SERVICES.AnalyticsService,
            useFactory: (config: ConfigService) =>
                new AnalyticsService([
                    new GTMProvider(config.get('analytics.gtm')),
                    new MixpanelProvider(config.get('analytics.mixpanel')),
                    new SmartlookProvider(config.get('analytics.smartlook')),
                ]),
            inject: [COMMON_SERVICES.ConfigService],
        },
        {
            provide: Auth.AuthService,
            useFactory: (amplify) => {
                return new Auth.AuthService([
                    new Auth.AmplifyAuthProvider(amplify.Auth),
                ]);
            },
            inject: [COMMON_SERVICES.Amplify],
        },
        {
            provide: COMMON_SERVICES.Amplify,
            useFactory: () => {
                Amplify.configure(environment.amplify);
                return Amplify;
            },
        },
        PersonService,
        {
            provide: 'ApolloSharedCache',
            useFactory: () => {
                return new InMemoryCache();
            },
        },
        {
            provide: 'apolloErrorLink',
            useFactory: (sentry, analytics: AnalyticsService) => {
                return onError(({ graphQLErrors, networkError }) => {
                    if (graphQLErrors) {
                        // analytics.exception(new Exceptions.GraphQLException('GraphQL Error', graphQLErrors));
                        graphQLErrors.map(({ message, locations, path }) => {
                            sentry.withScope((scope) => {
                                scope.setExtras({ locations, path });
                                scope.setTag(
                                    'pathname',
                                    window.location.pathname,
                                );
                                scope.setTag('payload', window.location.search);
                                sentry.captureException(
                                    new Exceptions.GraphQLException(message),
                                );
                            });
                        });
                    }
                    if (networkError) {
                        sentry.captureException(networkError);
                        // analytics.exception(new Exceptions.GraphQLException('GraphQL Network Error', networkError));
                    }
                });
            },
            inject: [SERVICES.Sentry, COMMON_SERVICES.AnalyticsService],
        },
        {
            provide: CORE_SERVICES.ApolloAuthorizedClient,
            useFactory: (
                appStore: AppStore,
                apolloSharedCache: InMemoryCache,
                apolloErrorLink,
            ) => {
                const httpLink = new HttpLink({
                    uri: environment.apollo.endpoint,
                });
                const authMiddleware = new ApolloLink((operation, forward) => {
                    // add the authorization to the headers
                    operation.setContext({
                        headers: {
                            authorization:
                                appStore.token.isAuthenticated() &&
                                appStore.token instanceof
                                    Auth.AmplifySessionToken
                                    ? `${appStore.token.idToken}`
                                    : '',
                        },
                    });
                    return forward(operation);
                });
                return new ApolloClient({
                    link: ApolloLink.from([
                        authMiddleware,
                        apolloErrorLink,
                        httpLink,
                    ]),
                    cache: apolloSharedCache,
                });
            },
            inject: [SERVICES.AppStore, 'ApolloSharedCache', 'apolloErrorLink'],
        },
        {
            provide: CORE_SERVICES.ApolloUnAuthorizedClient,
            useFactory: (apolloSharedCache: InMemoryCache, apolloErrorLink) => {
                const httpLink = new HttpLink({
                    uri: environment.apollo.endpoint,
                    headers: {
                        'X-Api-Key':
                            environment.apollo.ApolloUnAuthorizedApiKey,
                    },
                });

                return new ApolloClient({
                    link: ApolloLink.from([apolloErrorLink, httpLink]),
                    cache: apolloSharedCache,
                });
            },
            inject: ['ApolloSharedCache', 'apolloErrorLink'],
        },
        {
            provide: CORE_SERVICES.IProjectRepository,
            useClass: ProjectRepository,
        },
        {
            provide: CORE_SERVICES.PersonRepository,
            useClass: PersonRepository,
        },
        {
            provide: CORE_SERVICES.MatchPercentServiceInterface,
            useClass: MatchPercentService,
        },
        { provide: ProjectsService, useClass: ProjectsService },
        { provide: ProjectsMainPageStore, useClass: ProjectsMainPageStore },
        { provide: PeopleMainPageStore, useClass: PeopleMainPageStore },
        { provide: PeopleService, useClass: PeopleService },
        {
            provide: CORE_SERVICES.IPeopleRepository,
            useClass: PeopleRepository,
        },
        {
            provide: CORE_SERVICES.ICurrentUserProvider,
            useFactory: () =>
                new CurrentUserProvider(() => {
                    if (di.has(SERVICES.AppStore)) {
                        const store = di.get<AppStore>(SERVICES.AppStore);
                        return store.token.getUser()?.id;
                    }
                }),
        },
        // { provide: SchoolsStore, useClass: SchoolsStore },
        // { provide: SchoolsService, useClass: SchoolsService },
        // {
        //     provide: CORE_SERVICES.SchoolsRepositoryInterface,
        //     useClass: SchoolsRepository,
        // },
        {
            provide: ChatService,
            useFactory: (gqClient, firebaseClient) =>
                new ChatService({ apolloClient: gqClient, firebaseClient }),
            inject: [
                CORE_SERVICES.ApolloAuthorizedClient,
                SERVICES.FirebaseApp,
            ],
        },
        {
            provide: QuizService,
            useFactory: (gqClient) => new QuizService(gqClient),
            inject: [CORE_SERVICES.ApolloAuthorizedClient],
        },
        {
            provide: CORE_SERVICES.SkillSetRepositoryInterface,
            useClass: SkillSetRepository,
        },
        {
            provide: CORE_SERVICES.PassedTestRepositoryInterface,
            useClass: PassedTestRepository,
        },
        {
            provide: CORE_SERVICES.ICoolRepository,
            useClass: CoolRepository,
        },
        CoolService,
        SkillsService,
        TestService,
    ]);
    return Promise.resolve(di);
}
