import classNames from 'classnames';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { ROOM_TYPE_PEOPLE, ROOM_TYPE_PROJECTS } from '@weco/chat';
import useToggle from 'react-use/lib/useToggle';
import ForumIcon from '@material-ui/icons/Forum';
import { makeStyles } from '@material-ui/styles';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { CircularProgress, IconButton, Theme } from '@material-ui/core';
import { useChatData } from '../../../../../apps/client/src/app/store/hooks/useChatData';
import { useMyUserProfileData } from 'apps/client/src/app/store/hooks/useMyUserProfileData';
import { UserProfileEntity } from '@weco/core';

const useStyles = makeStyles((theme: Theme) => ({ root: {} }));

export interface GoToChatButtonProps {
    person?: UserProfileEntity;
    projectId?: string;
    className?: string;
    isMember?: boolean;
}

const GoToChatButton = memo((props: GoToChatButtonProps) => {
    const classes = useStyles();
    const history = useHistory();
    const rootClasses = classNames(classes.root, props.className);

    const { createRoom } = useChatData();
    const { currentUserId } = useMyUserProfileData();

    const [isLoading, toggleLoading] = useToggle(false);

    function goToChat() {
        if (props.isMember !== false && props.projectId) {
            return history.push(
                `${RouterPaths.chat.root}/${ROOM_TYPE_PROJECTS}/${props.projectId}`,
            );
        }
        return createRoom(
            {
                type: ROOM_TYPE_PEOPLE,
                userIds: [props.person.id, currentUserId],
            },
            currentUserId,
            props.person,
        ).then((roomId: string) => {
            history.push(
                `${RouterPaths.chat.root}/${ROOM_TYPE_PEOPLE}/${roomId}`,
            );
        });
    }

    return (
        <IconButton
            disabled={isLoading}
            onClick={(e) => {
                e.stopPropagation();
                toggleLoading();
                goToChat();
            }}
            className={rootClasses}
        >
            {isLoading ? (
                <CircularProgress size={24} color="secondary" />
            ) : (
                <ForumIcon />
            )}
        </IconButton>
    );
});

GoToChatButton.displayName = 'GoToChatButton';

export { GoToChatButton };
