import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Auth } from '@weco/common';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import { useLoginPageStyles } from './styles/LoginPage.styles';
import { RouterPaths } from '../../router/RouterPaths';
import {
    ResetPasswordConfirmForm,
    ResetPasswordForm,
    useNotice,
} from '@weco/ui';

const RESET_PASSWORD_FORM = 'RESET_PASSWORD_FORM';
const RESET_PASSWORD_CONFIRM_FORM = 'RESET_PASSWORD_CONFIRM_FORM';

export const ForgotPasswordPage = () => {
    const history = useHistory();
    const { forgotPassword, forgotPasswordSubmit } = useCurrentAuthData();
    const [form, setForm] = useState<string>(RESET_PASSWORD_FORM);
    const [email, setEmail] = useState<string>(null);
    const { info, error, success } = useNotice();
    const classes = useLoginPageStyles();

    function forgotPasswordHandler(userEmail = null) {
        return forgotPassword(
            new Auth.AmplifyForgotPasswordToken(userEmail || email),
        )
            .then(() => {
                info('A reset password email has been sent');
                setForm(RESET_PASSWORD_CONFIRM_FORM);
                if (userEmail && userEmail !== email) {
                    setEmail(userEmail);
                }
            })
            .catch((e) => {
                error(
                    e.message === 'Username/client id combination not found.'
                        ? 'Email not found'
                        : e.message,
                );
                return Promise.reject(e);
            });
    }

    function forgotPasswordSubmitHandler(code: string, newPassword: string) {
        return forgotPasswordSubmit(
            new Auth.AmplifyForgotPasswordSubmitToken(email, code, newPassword),
        )
            .then(() => {
                success('Password was changed successful');
                history.push(RouterPaths.login);
            })
            .catch((e) => {
                error(e.message);
                return Promise.reject(e);
            });
    }

    return (
        <Box className={classes.root}>
            <Typography
                variant="h2"
                align="center"
                className={classes.signInText}
            >
                Recover Password
            </Typography>

            {form === RESET_PASSWORD_FORM && (
                <ResetPasswordForm onSubmit={forgotPasswordHandler} />
            )}
            {form === RESET_PASSWORD_CONFIRM_FORM && (
                <ResetPasswordConfirmForm
                    forgotPassword={forgotPasswordHandler}
                    onSubmit={forgotPasswordSubmitHandler}
                />
            )}
        </Box>
    );
};

ForgotPasswordPage.URL = RouterPaths.forgotPassword;
