import { Box, Theme, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { AnimatedArrowsPointingToSomething } from './AnimatedArrowsPointingToSomething';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: 300,
        margin: '0 auto',
    },
}));

export interface AnimatedArrowsWithAdditionalTextProps {
    text: JSX.Element | string;
    className?: string;
}

const AnimatedArrowsWithAdditionalText = memo(
    (props: AnimatedArrowsWithAdditionalTextProps) => {
        const classes = useStyles();
        const rootClasses = classNames(classes.root, props.className);

        return (
            <Box className={rootClasses}>
                <Typography
                    align="center"
                    variant="body1"
                    color="textSecondary"
                    children={props.text}
                />
                <AnimatedArrowsPointingToSomething />
            </Box>
        );
    },
);

AnimatedArrowsWithAdditionalText.displayName =
    'AnimatedArrowsWithAdditionalText';

export { AnimatedArrowsWithAdditionalText };
