import {
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Field } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import { WeTextField } from '@weco/ui';
import { makeStyles } from '@material-ui/core/styles';

export interface SearchFormControlProps {
    name?: string;
    error?: boolean;
    handleSubmit: () => void;
    advanced: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
    searchField: {
        marginTop: '8px',
    },
    searchInput: {
        height: '40px',
    },
}));

export const SearchFormControl = ({
    name,
    handleSubmit,
    advanced,
    error,
}: SearchFormControlProps) => {
    const classes = useStyles();
    return (
        <FormControl
            fullWidth
            variant="outlined"
            style={{ backgroundColor: '#fff', borderRadius: '5px' }}
            className={classes.searchField}
        >
            <Field label="" name={name || 'query'} component={WeTextField}>
                {({ field }) => {
                    return (
                        <OutlinedInput
                            className={classes.searchInput}
                            notched={false}
                            type="text"
                            onKeyDown={(e: KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                    handleSubmit();
                                }
                            }}
                            placeholder="Search"
                            {...field}
                            error={error}
                            endAdornment={
                                <InputAdornment position="end">
                                    {advanced}
                                </InputAdornment>
                            }
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                        onClick={handleSubmit}
                                        edge="start"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={50}
                        />
                    );
                }}
            </Field>
        </FormControl>
    );
};
