import React from 'react';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useFirstStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        color: '#fff',
        width: 38,
        height: 38,
        fontSize: '24px',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#7268FF',
    },
    active: {
        backgroundColor: '#7268FF',
        // backgroundImage: `url(${SteppersActiveAnimation})`,
        // backgroundSize: 'cover',
    },
    completed: {
        borderRadius: '50%',
        backgroundColor: '#7268FF',
        color: '#ffffff',
    },
});

export type FirstStepIconProps = {
    active: boolean;
    completed: boolean;
    icon?: any;
};

export const FirstStepIcon = (props: FirstStepIconProps) => {
    const classes = useFirstStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: '1',
        2: '2',
        3: '3',
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                icons[String(props.icon)]
            )}
        </div>
    );
};
