import debug from 'debug';
import React from 'react';
import { useHistory } from 'react-router';
import Toolbar from '@material-ui/core/Toolbar';
import { ChatInformerContainer } from '@weco/chat';
import { RouterPaths } from '../../../../router/RouterPaths';
import { useChatData } from '../../../../store/hooks/useChatData';
import { useMyUserData } from '../../../../store/hooks/useMyUserData';
import { Box, Button, CardMedia, IconButton } from '@material-ui/core';
import FullLogo from '../../../../../assets/images/full-logo-beta.svg';
import { useToolbarContentStyles } from './styles/ToolbarContent.styles';
import {
    CompareLocationContainer,
    NotificationsIndicator,
    SpriteSvgIcon,
    TopBarIcon,
} from '@weco/ui';
import { useCurrentAuthData } from '../../../../store/hooks/useCurrentAuthData';
import { environment } from '../../../../../environments/environment';
import { TopBarButton } from '../../../molecules/TopBarButton';

const log = debug('ToolbarContent');

export interface ToolbarContentProps {}

const ToolbarContent = () => {
    const { push } = useHistory();
    const classes = useToolbarContentStyles();

    const { profile } = useMyUserData();
    const { chatUser, firestore } = useChatData();
    const { isAuthenticated } = useCurrentAuthData();

    function goTo(path) {
        return () => push(path);
    }
    const goToLanding = () => (window.location.href = environment.landingPage);

    log('isAuthenticated: ', isAuthenticated);
    log('profile picture: %O', profile?.picture);

    return (
        <Toolbar className={classes.toolbar}>
            <Box className={classes.toolbarFullLogo}>
                <IconButton
                    className={classes.toolbarFullLogoButton}
                    onClick={() =>
                        isAuthenticated
                            ? goTo(RouterPaths.dashboard)()
                            : goToLanding()
                    }
                >
                    <CardMedia
                        src={FullLogo}
                        component="img"
                        className={classes.logoImage}
                    />
                </IconButton>
            </Box>
            {isAuthenticated && (
                <Box className={classes.toolbarNavigation}>
                    <ChatInformerContainer
                        firestore={firestore}
                        chatUserId={chatUser?.user?.uid}
                    >
                        {(props: any) => {
                            return (
                                <CompareLocationContainer
                                    route={RouterPaths.chat.root}
                                >
                                    {({ isMatch }) => (
                                        <NotificationsIndicator
                                            isChecked={isMatch}
                                            unreadCount={props.unreadCount}
                                            onClick={goTo(
                                                RouterPaths.chat.root,
                                            )}
                                        />
                                    )}
                                </CompareLocationContainer>
                            );
                        }}
                    </ChatInformerContainer>
                    <Box id="container-anchor-topbar-search">
                        <CompareLocationContainer
                            route={RouterPaths.search.root}
                        >
                            {({ isMatch }) => (
                                <TopBarIcon
                                    isSvg
                                    onClick={goTo(RouterPaths.search.root)}
                                >
                                    <SpriteSvgIcon
                                        id={
                                            !isMatch
                                                ? 'MainMenu-Search'
                                                : 'MainMenu-Search-Checked'
                                        }
                                    />
                                </TopBarIcon>
                            )}
                        </CompareLocationContainer>
                    </Box>
                </Box>
            )}

            {!isAuthenticated && (
                <Box className={classes.toolbarNavigation}>
                    <Box id="container-anchor-topbar-search">
                        <CompareLocationContainer
                            route={RouterPaths.search.root}
                        >
                            {({ isMatch }) => (
                                <TopBarIcon
                                    isSvg
                                    onClick={goTo(RouterPaths.search.root)}
                                >
                                    <SpriteSvgIcon
                                        id={
                                            !isMatch
                                                ? 'MainMenu-Search'
                                                : 'MainMenu-Search-Checked'
                                        }
                                    />
                                </TopBarIcon>
                            )}
                        </CompareLocationContainer>
                    </Box>
                    <Button
                        className={classes.toolbarButton}
                        onClick={goTo(RouterPaths.login)}
                    >
                        Login
                    </Button>
                    <Button
                        className={classes.toolbarJoinAction}
                        onClick={goTo(RouterPaths.signup)}
                    >
                        Join WeCo!
                    </Button>
                </Box>
            )}
        </Toolbar>
    );
};

export default ToolbarContent;
