import { makeStyles } from '@material-ui/core';

export const departmentsDrawerStyles = makeStyles(() => ({
    customDrawer: {
        '& :first-child': {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
        },
        '& :first-child *': {
            borderTopRightRadius: 'initial',
            borderTopLeftRadius: 'initial',
        },
        '& :last-child': {
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        '& :last-child *': {
            borderBottomRightRadius: 'initial',
            borderBottomLeftRadius: 'initial',
        },
    },
    warningButton: {
        background: 'transparent',
        padding: 5,
        marginLeft: 'auto',
        fontSize: '12px',
        border: 0,
        color: '#000',
        borderRadius: 8,
    },
    startTestLink: {
        textDecoration: 'none',
    },
    customDrawerElement: {
        padding: '15px',
        background: '#FFF',
        margin: '2px',
        marginBottom: '20px',
        minWidth: '260px',
        boxShadow: '0px 5px 16px 6px rgba(0, 0, 0, 0.05)',
        borderRadius: '6px',
    },
    title: {
        color: '#2d2963',
        fontWeight: 'bold',
        fontSize: '15px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
    },
    layoutToBreakChildStylesInheritance: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    itemsRow: {
        '&:first-child': {
            marginTop: '15px',
        },
        display: 'flex',
        padding: '5px',
        justifyContent: 'space-between',
    },
    itemField: {
        minWidth: '100px',
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    opened: {
        opacity: 1,
        transition: 'visibility 0s, opacity 0.3s ease-in-out',
        visibility: 'visible',
        height: 'auto',
    },
    closed: {
        opacity: 0,
        transition: 'visibility 0.1s, opacity 0.3s',
        visibility: 'hidden',
        height: 0,
        overflow: 'hidden',
    },
    chevron: {
        display: 'inline-block',
        borderRight: '2px solid black',
        borderBottom: '2px solid black',
        width: '10px',
        height: '10px',
        transform: 'rotate(45deg)',
        transition: 'transform 0.2s ease-in-out',
        marginLeft: '10px',
    },
    rotated: {
        transform: 'rotate(-135deg)',
    },
    chatBubble: {
        cursor: 'pointer',
    },
    chatIcon: {
        marginTop: '-15px',
        color: '#2d2963',
        padding: '8px',
    },
    sectionName: {
        flexGrow: 1,
        '& .MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
        },
    },
    actionBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
}));
