import React from 'react';
import {
    TimePicker as MuiTimePicker,
    TimePickerProps as MuiTimePickerProps,
} from '@material-ui/pickers';
import { FieldProps, getIn } from 'formik';

export interface WeTimePickerProps
    extends FieldProps,
        Omit<MuiTimePickerProps, 'name' | 'value' | 'error'> {}

export const fieldToTimePicker = ({
    field,
    form: { isSubmitting, touched, errors, setFieldValue, setFieldError },
    disabled,
    ...props
}: WeTimePickerProps): MuiTimePickerProps => {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled != undefined ? disabled : isSubmitting,
        onChange(date) {
            props.onChange
                ? props.onChange(date)
                : setFieldValue(field.name, date);
        },
        onError(error) {
            if (error !== fieldError && !(error == '' && !fieldError)) {
                setFieldError(field.name, error ? String(error) : undefined);
            }
        },
    };
};

export const WeTimePicker = ({ children, ...props }: WeTimePickerProps) => {
    return (
        <MuiTimePicker {...fieldToTimePicker(props)}>{children}</MuiTimePicker>
    );
};
