import { ResultEntity, TestAnswerEntity } from '@weco/core';
import { resultData } from './resultData';

export const getResultsByAnswers = (
    answers: TestAnswerEntity[][],
): ResultEntity[] => {
    const results: ResultEntity[] = [];
    resultData.forEach((defaultResult) => {
        let points = 0;
        answers.forEach((answerArr) => {
            answerArr.forEach((answer) => {
                points += getPoint(
                    answer.questionId,
                    defaultResult.name,
                    answer.option,
                );
            });
        });

        const result = new ResultEntity();
        result.department = defaultResult.name;
        result.points = points;

        results.push(result);
    });

    return results.sort((a: any, b: any): number => {
        return b.points - a.points;
    });
};

const getPoint = (
    questionId: number,
    department: string,
    option: number,
): number => {
    let point = 0;
    calculationPoints.forEach((pointObject) => {
        if (
            pointObject.questionId === questionId &&
            pointObject.department === department &&
            pointObject.option === option
        ) {
            point = pointObject.points;
            return;
        }
    });

    return point;
};

const calculationPoints = [
    {
        questionId: 1,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 1,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 1,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 1,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 1,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 1,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 1,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 1,
        department: 'marketing',
        option: 4,
        points: 2,
    },
    {
        questionId: 1,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 1,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 1,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 1,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 1,
        department: 'execution',
        option: 1,
        points: 2,
    },
    {
        questionId: 1,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 1,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 1,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 1,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 1,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 1,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 1,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 1,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 1,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 1,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 1,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 1,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 1,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 1,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 1,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 1,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 1,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 1,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 1,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 2,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 2,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 2,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 2,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 2,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 2,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 2,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 2,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 2,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 2,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 2,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 2,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 2,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 2,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 2,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 2,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 2,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 2,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 2,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 2,
        department: 'finance',
        option: 4,
        points: 2,
    },
    {
        questionId: 2,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 2,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 2,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 2,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 2,
        department: 'hr',
        option: 1,
        points: 2,
    },
    {
        questionId: 2,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 2,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 2,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 2,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 2,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 2,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 2,
        department: 'ideas',
        option: 4,
        points: 2,
    },
    {
        questionId: 3,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 3,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 3,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 3,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 3,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 3,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 3,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 3,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 3,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 3,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 3,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 3,
        department: 'legal',
        option: 4,
        points: 2,
    },
    {
        questionId: 3,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 3,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 3,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 3,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 3,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 3,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 3,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 3,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 3,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 3,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 3,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 3,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 3,
        department: 'hr',
        option: 1,
        points: 2,
    },
    {
        questionId: 3,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 3,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 3,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 3,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 3,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 3,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 3,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 4,
        department: 'strategy',
        option: 1,
        points: 2,
    },
    {
        questionId: 4,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 4,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 4,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 4,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 4,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 4,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 4,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 4,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 4,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 4,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 4,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 4,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 4,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 4,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 4,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 4,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 4,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 4,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 4,
        department: 'finance',
        option: 4,
        points: 2,
    },
    {
        questionId: 4,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 4,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 4,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 4,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 4,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 4,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 4,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 4,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 4,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 4,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 4,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 4,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 5,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 5,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 5,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 5,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 5,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 5,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 5,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 5,
        department: 'marketing',
        option: 4,
        points: 2,
    },
    {
        questionId: 5,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 5,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 5,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 5,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 5,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 5,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 5,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 5,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 5,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 5,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 5,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 5,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 5,
        department: 'itweb',
        option: 1,
        points: 2,
    },
    {
        questionId: 5,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 5,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 5,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 5,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 5,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 5,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 5,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 5,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 5,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 5,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 5,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 6,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 6,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 6,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 6,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 6,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 6,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 6,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 6,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 6,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 6,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 6,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 6,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 6,
        department: 'execution',
        option: 1,
        points: 2,
    },
    {
        questionId: 6,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 6,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 6,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 6,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 6,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 6,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 6,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 6,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 6,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 6,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 6,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 6,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 6,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 6,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 6,
        department: 'hr',
        option: 4,
        points: 2,
    },
    {
        questionId: 6,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 6,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 6,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 6,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 7,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 7,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 7,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 7,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 7,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 7,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 7,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 7,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 7,
        department: 'legal',
        option: 1,
        points: 2,
    },
    {
        questionId: 7,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 7,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 7,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 7,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 7,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 7,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 7,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 7,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 7,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 7,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 7,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 7,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 7,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 7,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 7,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 7,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 7,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 7,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 7,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 7,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 7,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 7,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 7,
        department: 'ideas',
        option: 4,
        points: 2,
    },
    {
        questionId: 8,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 8,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 8,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 8,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 8,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 8,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 8,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 8,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 8,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 8,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 8,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 8,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 8,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 8,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 8,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 8,
        department: 'execution',
        option: 4,
        points: 2,
    },
    {
        questionId: 8,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 8,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 8,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 8,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 8,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 8,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 8,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 8,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 8,
        department: 'hr',
        option: 1,
        points: 2,
    },
    {
        questionId: 8,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 8,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 8,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 8,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 8,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 8,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 8,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 9,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 9,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 9,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 9,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 9,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 9,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 9,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 9,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 9,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 9,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 9,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 9,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 9,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 9,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 9,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 9,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 9,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 9,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 9,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 9,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 9,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 9,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 9,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 9,
        department: 'itweb',
        option: 4,
        points: 2,
    },
    {
        questionId: 9,
        department: 'hr',
        option: 1,
        points: 2,
    },
    {
        questionId: 9,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 9,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 9,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 9,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 9,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 9,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 9,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 10,
        department: 'strategy',
        option: 1,
        points: 2,
    },
    {
        questionId: 10,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 10,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 10,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 10,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 10,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 10,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 10,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 10,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 10,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 10,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 10,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 10,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 10,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 10,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 10,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 10,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 10,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 10,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 10,
        department: 'finance',
        option: 4,
        points: 2,
    },
    {
        questionId: 10,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 10,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 10,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 10,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 10,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 10,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 10,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 10,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 10,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 10,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 10,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 10,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 11,
        department: 'strategy',
        option: 1,
        points: 2,
    },
    {
        questionId: 11,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 11,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 11,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 11,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 11,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 11,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 11,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 11,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 11,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 11,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 11,
        department: 'legal',
        option: 4,
        points: 2,
    },
    {
        questionId: 11,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 11,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 11,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 11,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 11,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 11,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 11,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 11,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 11,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 11,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 11,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 11,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 11,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 11,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 11,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 11,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 11,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 11,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 11,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 11,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 12,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 12,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 12,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 12,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 12,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 12,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 12,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 12,
        department: 'marketing',
        option: 4,
        points: 2,
    },
    {
        questionId: 12,
        department: 'legal',
        option: 1,
        points: 2,
    },
    {
        questionId: 12,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 12,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 12,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 12,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 12,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 12,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 12,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 12,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 12,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 12,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 12,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 12,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 12,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 12,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 12,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 12,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 12,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 12,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 12,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 12,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 12,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 12,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 12,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 13,
        department: 'strategy',
        option: 1,
        points: 2,
    },
    {
        questionId: 13,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 13,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 13,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 13,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 13,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 13,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 13,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 13,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 13,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 13,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 13,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 13,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 13,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 13,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 13,
        department: 'execution',
        option: 4,
        points: 2,
    },
    {
        questionId: 13,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 13,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 13,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 13,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 13,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 13,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 13,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 13,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 13,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 13,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 13,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 13,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 13,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 13,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 13,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 13,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 14,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 14,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 14,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 14,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 14,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 14,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 14,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 14,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 14,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 14,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 14,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 14,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 14,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 14,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 14,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 14,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 14,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 14,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 14,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 14,
        department: 'finance',
        option: 4,
        points: 2,
    },
    {
        questionId: 14,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 14,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 14,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 14,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 14,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 14,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 14,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 14,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 14,
        department: 'ideas',
        option: 1,
        points: 2,
    },
    {
        questionId: 14,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 14,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 14,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 15,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 15,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 15,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 15,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 15,
        department: 'marketing',
        option: 1,
        points: 2,
    },
    {
        questionId: 15,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 15,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 15,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 15,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 15,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 15,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 15,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 15,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 15,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 15,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 15,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 15,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 15,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 15,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 15,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 15,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 15,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 15,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 15,
        department: 'itweb',
        option: 4,
        points: 2,
    },
    {
        questionId: 15,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 15,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 15,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 15,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 15,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 15,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 15,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 15,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 16,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 16,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 16,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 16,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 16,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 16,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 16,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 16,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 16,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 16,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 16,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 16,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 16,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 16,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 16,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 16,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 16,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 16,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 16,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 16,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 16,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 16,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 16,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 16,
        department: 'itweb',
        option: 4,
        points: 2,
    },
    {
        questionId: 16,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 16,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 16,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 16,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 16,
        department: 'ideas',
        option: 1,
        points: 2,
    },
    {
        questionId: 16,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 16,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 16,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 17,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 17,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 17,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 17,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 17,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 17,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 17,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 17,
        department: 'marketing',
        option: 4,
        points: 2,
    },
    {
        questionId: 17,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 17,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 17,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 17,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 17,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 17,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 17,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 17,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 17,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 17,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 17,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 17,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 17,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 17,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 17,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 17,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 17,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 17,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 17,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 17,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 17,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 17,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 17,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 17,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 18,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 18,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 18,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 18,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 18,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 18,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 18,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 18,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 18,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 18,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 18,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 18,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 18,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 18,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 18,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 18,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 18,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 18,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 18,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 18,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 18,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 18,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 18,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 18,
        department: 'itweb',
        option: 4,
        points: 2,
    },
    {
        questionId: 18,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 18,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 18,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 18,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 18,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 18,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 18,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 18,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 19,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 19,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 19,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 19,
        department: 'strategy',
        option: 4,
        points: 2,
    },
    {
        questionId: 19,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 19,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 19,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 19,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 19,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 19,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 19,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 19,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 19,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 19,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 19,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 19,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 19,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 19,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 19,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 19,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 19,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 19,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 19,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 19,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 19,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 19,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 19,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 19,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 19,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 19,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 19,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 19,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 20,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 20,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 20,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 20,
        department: 'strategy',
        option: 4,
        points: 2,
    },
    {
        questionId: 20,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 20,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 20,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 20,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 20,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 20,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 20,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 20,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 20,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 20,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 20,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 20,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 20,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 20,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 20,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 20,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 20,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 20,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 20,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 20,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 20,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 20,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 20,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 20,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 20,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 20,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 20,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 20,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 21,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 21,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 21,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 21,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 21,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 21,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 21,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 21,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 21,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 21,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 21,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 21,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 21,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 21,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 21,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 21,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 21,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 21,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 21,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 21,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 21,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 21,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 21,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 21,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 21,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 21,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 21,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 21,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 21,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 21,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 21,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 21,
        department: 'ideas',
        option: 4,
        points: 2,
    },
    {
        questionId: 22,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 22,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 22,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 22,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 22,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 22,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 22,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 22,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 22,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 22,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 22,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 22,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 22,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 22,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 22,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 22,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 22,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 22,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 22,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 22,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 22,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 22,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 22,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 22,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 22,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 22,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 22,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 22,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 22,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 22,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 22,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 22,
        department: 'ideas',
        option: 4,
        points: 2,
    },
    {
        questionId: 23,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 23,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 23,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 23,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 23,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 23,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 23,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 23,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 23,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 23,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 23,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 23,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 23,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 23,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 23,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 23,
        department: 'execution',
        option: 4,
        points: 2,
    },
    {
        questionId: 23,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 23,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 23,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 23,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 23,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 23,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 23,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 23,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 23,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 23,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 23,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 23,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 23,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 23,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 23,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 23,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 24,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 24,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 24,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 24,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 24,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 24,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 24,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 24,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 24,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 24,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 24,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 24,
        department: 'legal',
        option: 4,
        points: 2,
    },
    {
        questionId: 24,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 24,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 24,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 24,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 24,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 24,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 24,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 24,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 24,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 24,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 24,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 24,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 24,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 24,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 24,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 24,
        department: 'hr',
        option: 4,
        points: 1,
    },
    {
        questionId: 24,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 24,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 24,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 24,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 25,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 25,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 25,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 25,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 25,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 25,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 25,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 25,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 25,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 25,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 25,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 25,
        department: 'legal',
        option: 4,
        points: 2,
    },
    {
        questionId: 25,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 25,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 25,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 25,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 25,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 25,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 25,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 25,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 25,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 25,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 25,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 25,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 25,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 25,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 25,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 25,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 25,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 25,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 25,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 25,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 26,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 26,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 26,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 26,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 26,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 26,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 26,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 26,
        department: 'marketing',
        option: 4,
        points: 2,
    },
    {
        questionId: 26,
        department: 'legal',
        option: 1,
        points: 0,
    },
    {
        questionId: 26,
        department: 'legal',
        option: 2,
        points: 0,
    },
    {
        questionId: 26,
        department: 'legal',
        option: 3,
        points: 1,
    },
    {
        questionId: 26,
        department: 'legal',
        option: 4,
        points: 1,
    },
    {
        questionId: 26,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 26,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 26,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 26,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 26,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 26,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 26,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 26,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 26,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 26,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 26,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 26,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 26,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 26,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 26,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 26,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 26,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 26,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 26,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 26,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 27,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 27,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 27,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 27,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 27,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 27,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 27,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 27,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 27,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 27,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 27,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 27,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 27,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 27,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 27,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 27,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 27,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 27,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 27,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 27,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 27,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 27,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 27,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 27,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 27,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 27,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 27,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 27,
        department: 'hr',
        option: 4,
        points: 2,
    },
    {
        questionId: 27,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 27,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 27,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 27,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 28,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 28,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 28,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 28,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 28,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 28,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 28,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 28,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 28,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 28,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 28,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 28,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 28,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 28,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 28,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 28,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 28,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 28,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 28,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 28,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 28,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 28,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 28,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 28,
        department: 'itweb',
        option: 4,
        points: 2,
    },
    {
        questionId: 28,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 28,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 28,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 28,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 28,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 28,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 28,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 28,
        department: 'ideas',
        option: 4,
        points: 1,
    },
    {
        questionId: 29,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 29,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 29,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 29,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 29,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 29,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 29,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 29,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 29,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 29,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 29,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 29,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 29,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 29,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 29,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 29,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 29,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 29,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 29,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 29,
        department: 'finance',
        option: 4,
        points: 2,
    },
    {
        questionId: 29,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 29,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 29,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 29,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 29,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 29,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 29,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 29,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 29,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 29,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 29,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 29,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 30,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 30,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 30,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 30,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 30,
        department: 'marketing',
        option: 1,
        points: 1,
    },
    {
        questionId: 30,
        department: 'marketing',
        option: 2,
        points: 1,
    },
    {
        questionId: 30,
        department: 'marketing',
        option: 3,
        points: 0,
    },
    {
        questionId: 30,
        department: 'marketing',
        option: 4,
        points: 0,
    },
    {
        questionId: 30,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 30,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 30,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 30,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 30,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 30,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 30,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 30,
        department: 'execution',
        option: 4,
        points: 2,
    },
    {
        questionId: 30,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 30,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 30,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 30,
        department: 'finance',
        option: 4,
        points: 1,
    },
    {
        questionId: 30,
        department: 'itweb',
        option: 1,
        points: 0,
    },
    {
        questionId: 30,
        department: 'itweb',
        option: 2,
        points: 0,
    },
    {
        questionId: 30,
        department: 'itweb',
        option: 3,
        points: 1,
    },
    {
        questionId: 30,
        department: 'itweb',
        option: 4,
        points: 1,
    },
    {
        questionId: 30,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 30,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 30,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 30,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 30,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 30,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 30,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 30,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 31,
        department: 'strategy',
        option: 1,
        points: 0,
    },
    {
        questionId: 31,
        department: 'strategy',
        option: 2,
        points: 0,
    },
    {
        questionId: 31,
        department: 'strategy',
        option: 3,
        points: 1,
    },
    {
        questionId: 31,
        department: 'strategy',
        option: 4,
        points: 1,
    },
    {
        questionId: 31,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 31,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 31,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 31,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 31,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 31,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 31,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 31,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 31,
        department: 'execution',
        option: 1,
        points: 0,
    },
    {
        questionId: 31,
        department: 'execution',
        option: 2,
        points: 0,
    },
    {
        questionId: 31,
        department: 'execution',
        option: 3,
        points: 1,
    },
    {
        questionId: 31,
        department: 'execution',
        option: 4,
        points: 1,
    },
    {
        questionId: 31,
        department: 'finance',
        option: 1,
        points: 0,
    },
    {
        questionId: 31,
        department: 'finance',
        option: 2,
        points: 0,
    },
    {
        questionId: 31,
        department: 'finance',
        option: 3,
        points: 1,
    },
    {
        questionId: 31,
        department: 'finance',
        option: 4,
        points: 2,
    },
    {
        questionId: 31,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 31,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 31,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 31,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 31,
        department: 'hr',
        option: 1,
        points: 1,
    },
    {
        questionId: 31,
        department: 'hr',
        option: 2,
        points: 1,
    },
    {
        questionId: 31,
        department: 'hr',
        option: 3,
        points: 0,
    },
    {
        questionId: 31,
        department: 'hr',
        option: 4,
        points: 0,
    },
    {
        questionId: 31,
        department: 'ideas',
        option: 1,
        points: 1,
    },
    {
        questionId: 31,
        department: 'ideas',
        option: 2,
        points: 1,
    },
    {
        questionId: 31,
        department: 'ideas',
        option: 3,
        points: 0,
    },
    {
        questionId: 31,
        department: 'ideas',
        option: 4,
        points: 0,
    },
    {
        questionId: 32,
        department: 'strategy',
        option: 1,
        points: 1,
    },
    {
        questionId: 32,
        department: 'strategy',
        option: 2,
        points: 1,
    },
    {
        questionId: 32,
        department: 'strategy',
        option: 3,
        points: 0,
    },
    {
        questionId: 32,
        department: 'strategy',
        option: 4,
        points: 0,
    },
    {
        questionId: 32,
        department: 'marketing',
        option: 1,
        points: 0,
    },
    {
        questionId: 32,
        department: 'marketing',
        option: 2,
        points: 0,
    },
    {
        questionId: 32,
        department: 'marketing',
        option: 3,
        points: 1,
    },
    {
        questionId: 32,
        department: 'marketing',
        option: 4,
        points: 1,
    },
    {
        questionId: 32,
        department: 'legal',
        option: 1,
        points: 1,
    },
    {
        questionId: 32,
        department: 'legal',
        option: 2,
        points: 1,
    },
    {
        questionId: 32,
        department: 'legal',
        option: 3,
        points: 0,
    },
    {
        questionId: 32,
        department: 'legal',
        option: 4,
        points: 0,
    },
    {
        questionId: 32,
        department: 'execution',
        option: 1,
        points: 1,
    },
    {
        questionId: 32,
        department: 'execution',
        option: 2,
        points: 1,
    },
    {
        questionId: 32,
        department: 'execution',
        option: 3,
        points: 0,
    },
    {
        questionId: 32,
        department: 'execution',
        option: 4,
        points: 0,
    },
    {
        questionId: 32,
        department: 'finance',
        option: 1,
        points: 1,
    },
    {
        questionId: 32,
        department: 'finance',
        option: 2,
        points: 1,
    },
    {
        questionId: 32,
        department: 'finance',
        option: 3,
        points: 0,
    },
    {
        questionId: 32,
        department: 'finance',
        option: 4,
        points: 0,
    },
    {
        questionId: 32,
        department: 'itweb',
        option: 1,
        points: 1,
    },
    {
        questionId: 32,
        department: 'itweb',
        option: 2,
        points: 1,
    },
    {
        questionId: 32,
        department: 'itweb',
        option: 3,
        points: 0,
    },
    {
        questionId: 32,
        department: 'itweb',
        option: 4,
        points: 0,
    },
    {
        questionId: 32,
        department: 'hr',
        option: 1,
        points: 0,
    },
    {
        questionId: 32,
        department: 'hr',
        option: 2,
        points: 0,
    },
    {
        questionId: 32,
        department: 'hr',
        option: 3,
        points: 1,
    },
    {
        questionId: 32,
        department: 'hr',
        option: 4,
        points: 2,
    },
    {
        questionId: 32,
        department: 'ideas',
        option: 1,
        points: 0,
    },
    {
        questionId: 32,
        department: 'ideas',
        option: 2,
        points: 0,
    },
    {
        questionId: 32,
        department: 'ideas',
        option: 3,
        points: 1,
    },
    {
        questionId: 32,
        department: 'ideas',
        option: 4,
        points: 1,
    },
];
