import { AnalyticsEvent, AuthAnalyticsEvent } from './AnalyticsEvent';
import { AnalyticsProviderInterface } from './AnalyticsProviderInterface';

export class AnalyticsService implements AnalyticsProviderInterface {
    private providers: AnalyticsProviderInterface[] = [];

    constructor(providers = []) {
        this.providers = providers;
        this.initWeClickEvent();
    }

    load(): Promise<any> {
        return Promise.all(
            this.providers.map((p) =>
                p
                    .load()
                    .catch((e) =>
                        Promise.resolve(
                            e instanceof Error ? e : new Error(e.toString()),
                        ),
                    ),
            ),
        ).then((r) => {
            this.providers = this.providers.filter((provider, index) => {
                if (r[index] instanceof Error || r[index] === false) {
                    console?.warn(
                        `Analytics ${provider.constructor.name} is not loaded, because of "${r[index]}"`,
                    );
                    return false;
                }
                return true;
            });
            return Promise.resolve(r);
        });
    }

    pageView = (url: string, type?: string) => {
        this.providers.forEach((p) => p.pageView && p.pageView(url, type));
    };

    identify = (event: AuthAnalyticsEvent) => {
        this.providers.forEach((p) => p.identify && p.identify(event));
    };

    event = (event: AnalyticsEvent) => {
        this.providers.forEach((p) => p.event && p.event(event));
    };

    click = (event: AnalyticsEvent) => {
        this.providers.forEach((p) => p.click && p.click(event));
    };

    exception = (e: Error, severity: string = 'LOW'): void => {
        this.providers.forEach((p) => p.exception && p.exception(e, severity));
    };

    getProvider(providerType: any): AnalyticsProviderInterface | undefined {
        return this.providers.find((p) => p instanceof providerType);
    }

    private initWeClickEvent() {
        // is browser test
        if (typeof window?.document?.addEventListener !== 'function') {
            return;
        }
        window.document.addEventListener(
            'click',
            (event) => {
                const path = [];
                let currentElem = event.target as Element;
                while (currentElem) {
                    path.push(currentElem);
                    currentElem = currentElem.parentElement;
                }
                // If the clicked element doesn't have the right selector, bail
                const el = path.find(
                    (el) =>
                        el.id && ['BUTTON', 'A'].indexOf(el.nodeName) !== -1,
                );
                if (!el) {
                    return;
                }

                let payload;
                try {
                    payload = JSON.parse(
                        el.attributes['data-analytics']?.value,
                    );
                } catch (e) {
                    payload = {};
                }

                this.click(
                    new AnalyticsEvent(el.id, payload, {
                        gtm: {
                            'gtm.element': el,
                            'gtm.elementId': el.id,
                            'gtm.elementClasses': el.classList.toString(),
                            'gtm.clickText': el.innerText,
                            'gtm.clickNodeName': el.nodeName,
                        },
                    }),
                );
            },
            false,
        );
    }
}
