import React, { memo } from 'react';
import { SearchFieldViewOrEdit } from './SearchFieldViewOrEdit';
import { SearchFieldData } from './SearchFieldData';
import { SearchFieldEdit } from './SearchFieldEdit';

interface SearchFieldProps {
    field?: string; // for Chechu's functionality
    title: string;
    selected: string[];
    options: string[];
    isOpenField?: boolean;
    onChange: (key: string, value: string[]) => void;
}

const SearchField = memo(
    ({
        field,
        title,
        selected,
        options,
        isOpenField,
        onChange,
    }: SearchFieldProps) => {
        return (
            <SearchFieldViewOrEdit title={title} field={field}>
                {({ editing, handleCancel }) => (
                    <div>
                        {!editing ? (
                            <SearchFieldData values={selected} title={title} />
                        ) : (
                            <SearchFieldEdit
                                title={title}
                                options={options}
                                onChange={onChange}
                                selected={selected}
                                isOpenField={isOpenField}
                                handleCancel={handleCancel}
                            />
                        )}
                    </div>
                )}
            </SearchFieldViewOrEdit>
        );
    },
);

SearchField.displayName = 'SearchField';

export { SearchField };
