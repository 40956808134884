import React, { memo } from 'react';
import { Box, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    percentIcon: {
        backgroundColor: theme.palette.success[50],
        color: theme.palette.success[500],
        borderRadius: '14px',
        fontSize: '12px',
        fontWeight: 500,
        border: 'none',
        padding: 0,
        minWidth: 41,
        width: 41,
        height: 28,
        '&:hover': {
            backgroundColor: theme.palette.success[50],
            boxShadow: 'none',
        },
    },
}));

interface Props {
    number: number;
    className?: string;
}

const Component = ({ number = 0, className }: Props) => {
    const classes = useStyles();
    const isProperNumber = !isNaN(number);

    return (
        isProperNumber && (
            <Box>
                <Button className={classnames(classes.percentIcon, className)}>
                    {number}%
                </Button>
            </Box>
        )
    );
};

const MatchPercentage = memo(Component);

MatchPercentage.displayName = 'MatchPercentage';

export { MatchPercentage };
