import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const useProjectFieldWrapperStyles = makeStyles((theme) => ({
    infoItem: {
        width: '100%',
        marginTop: 15,
        marginBottom: 15,
    },
    infoHeading: {
        display: 'flex',
        color: theme.palette.text.primary,
        fontWeight: 500,
        alignItems: 'center',
        marginBottom: '20px',
    },
    infoText: {
        marginTop: 10,
        fontSize: '16px',
        color: theme.palette.text.secondary[650],
        marginRight: 15,
        width: '100%',
        overflow: 'hidden',
    },
    infoRow: {
        margin: 0,
        minHeight: 10,
    },
    infoDescription: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        fontStyle: 'italic',
        margin: '5px 0',
    },
    infoIcon: {
        color: theme.palette.text.secondary,
        marginBottom: 15,
        marginLeft: 15,
        fontSize: '18px',
        fontWeight: 300,
    },
}));

export interface HelpIconType {
    showed?: boolean;
    onClick?: () => void;
}

interface Props {
    title: string;
    children?: JSX.Element;
    description?: string;
    placeholder?: any;
    /**
     * Detect if field is empty to display special message to user.
     */
    isEmpty?: boolean;
    sectionHiddenWhenEmpty?: boolean;
    /**
     * Optional help icon to the right of the section.
     */
    helpIcon?: HelpIconType;
    rightAction?: JSX.Element;
}

export const SectionWithTitle = ({
    title,
    isEmpty,
    description,
    placeholder,
    children,
    helpIcon,
    rightAction,
    sectionHiddenWhenEmpty,
}: Props) => {
    const classes = useProjectFieldWrapperStyles();

    if (isEmpty && sectionHiddenWhenEmpty) {
        return null;
    }

    return (
        <Box className={classes.infoItem}>
            <Typography
                component="div"
                variant={'h4'}
                className={classes.infoHeading}
            >
                {title}
                {helpIcon?.showed && (
                    <InfoOutlinedIcon
                        onClick={helpIcon.onClick}
                        className={classes.infoIcon}
                    />
                )}
                {rightAction && (
                    <Typography style={{ marginLeft: 'auto' }}>
                        {rightAction}
                    </Typography>
                )}
            </Typography>
            {!!description && (
                <Typography className={classes.infoDescription}>
                    {description}
                </Typography>
            )}
            {isEmpty ? (
                <Typography
                    component={'div'}
                    className={classes.infoDescription}
                >
                    {!!placeholder ? placeholder : 'This field is empty'}
                </Typography>
            ) : (
                children
            )}
        </Box>
    );
};
