import { UserTokenInterface } from '../../common';

export class AmplifyConfirmAccountToken implements UserTokenInterface {
    constructor(public username: string, public code: string) {}

    isAuthenticated() {
        return false;
    }

    hasRole(name = '*') {
        return false;
    }

    getUsername() {
        return 'Unknown';
    }

    getUser() {
        return undefined;
    }
}
