import {
    AdvancedSearchFilter,
    SearchEntity,
    UserProfileEntity,
} from '@weco/core';
import debug from 'debug';
import { extend, omit, xor } from 'lodash';
import { toJS } from 'mobx';
import React, { memo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMyUserData } from '../../store/hooks/useMyUserData';
import { useSearchData } from '../search/useSearchData';
import { AdvancedSearchPage } from '@weco/ui';
import { RouterPaths } from '../../router/RouterPaths';
import { ProjectRolesContainer } from 'apps/client/src/app/store/containers/ProjectRolesContainer';
import { PersonRolesContainer } from '../../store/containers/PersonRolesContainer';
import { RecentSearchLocalStorageKeys } from '@weco/common';

const log = debug('AdvancedSearchPageContainer');

//TODO this is terrible. Needs refactoring.
function mergeSearchWithProfileSettings({
    userProfile,
    advancedSearch,
    ...options
}: {
    searchName: string;
    searchType: 'project' | 'person';
    advancedSearch: AdvancedSearchFilter;
    userProfile: UserProfileEntity;
}): UserProfileEntity {
    const searchToSave = {
        ...advancedSearch,
        name: options.searchName,
        type: options.searchType,
    } as SearchEntity;
    const mergedData = [
        ...(userProfile.settings?.searches || []),
        toJS(omit(searchToSave, 'Roles')),
    ];
    log('saveSearch() merged searches: %O: ', mergedData);
    const payload = extend(userProfile, {
        settings: {
            ...userProfile?.settings,
            searches: mergedData,
        },
    });
    log('saveSearch() profile payload: %O', payload);
    return payload;
}

const AdvancedSearchPageContainer = memo((props: {}) => {
    const location = useLocation();
    const history = useHistory();

    const locationIncludesProjects = !location.pathname.includes('people');
    const [isProjectTab, setIsProjectsTab] = useState(locationIncludesProjects);
    useEffect(() => {
        setIsProjectsTab(locationIncludesProjects);
        if (!advancedFilter) {
            setAdvancedSearchFilter(new AdvancedSearchFilter());
        }
    }, [location.pathname, locationIncludesProjects]);

    const {
        profile,
        updateProfile,
        activeProjectId,
        currentUserId,
    } = useMyUserData();
    const savedSearches = profile?.settings?.searches || [];
    const {
        constants,
        advancedFilter,
        searchPeople,
        searchProjects,
        setAdvancedSearchFilter,
        updatedSearchSettings,
        setUpdatedSearchSettings,
    } = useSearchData();

    function submitWithAdvancedSearch(): void {
        log('advanced search submitted');
        log('advancedFilter: ', advancedFilter);
        history.replace(
            RouterPaths.search[isProjectTab ? 'projects' : 'people'],
        );
        isProjectTab ? searchProjects() : searchPeople();
    }

    function saveSearch(searchName: string) {
        log('saveSearch() is called.');
        return updateProfile(
            mergeSearchWithProfileSettings({
                searchName,
                advancedSearch: advancedFilter,
                searchType: isProjectTab ? 'project' : 'person',
                userProfile: profile,
            }),
        );
    }

    function onSavedSearchClick(search: AdvancedSearchFilter) {
        setAdvancedSearchFilter(search);
    }

    function updateSavedSearch(searchName: string) {
        log('updateSearch() is called.', searchName);

        const updatedSearches = savedSearches.map((search) => {
            if (search.name === searchName) {
                return { ...search, ...advancedFilter };
            } else return search;
        });
        const updatedProfile = {
            ...profile,
            settings: {
                ...profile?.settings,
                searches: updatedSearches,
            },
        } as UserProfileEntity;
        log('updatedProfile: %O', updatedProfile);

        return updateProfile(updatedProfile);
    }

    function deleteSavedSearch(search: SearchEntity) {
        log('deleteSearch() called: %O', search);
        const mergedData = xor(profile?.settings?.searches, [toJS(search)]);
        log('deleteSearch() merged searches: %O: ', mergedData);
        const payload = extend(profile, {
            settings: {
                ...profile?.settings,
                searches: mergedData,
            },
        });
        log('deleteSearch() profile payload: %O', payload);
        return updateProfile(payload);
    }

    function resetSearch() {
        setAdvancedSearchFilter(new AdvancedSearchFilter());
        localStorage.removeItem(
            RecentSearchLocalStorageKeys.bufferAdvancedFilterPeople,
        );
        localStorage.removeItem(
            RecentSearchLocalStorageKeys.bufferAdvancedFilterProject,
        );
    }

    return (
        <PersonRolesContainer personId={currentUserId} searchPage>
            <ProjectRolesContainer projectId={activeProjectId} searchPage>
                <AdvancedSearchPage
                    {...{
                        constants,
                        advancedFilter,
                        onSavedSearchClick,
                        onSave: saveSearch,
                        onReset: resetSearch,
                        updatedSearchSettings,
                        setUpdatedSearchSettings,
                        onUpdate: updateSavedSearch,
                        onDelete: deleteSavedSearch,
                        savedSearches,
                        isAuthenticated: !!currentUserId,
                        onChange: setAdvancedSearchFilter,
                        onSearch: submitWithAdvancedSearch,
                    }}
                />
            </ProjectRolesContainer>
        </PersonRolesContainer>
    );
});

AdvancedSearchPageContainer.displayName = 'AdvancedSearchPageContainer';

export { AdvancedSearchPageContainer };
