import classNames from 'classnames';
import React, { memo } from 'react';
import { defaultPicture, Picture } from '@weco/core';
import { useToggle } from 'react-use';
import { Skeleton } from '@material-ui/lab';
import { getPictureUrl } from '@weco/common';
import { makeStyles } from '@material-ui/styles';
import { MatchPercentage } from './MatchPercentage';
import { Box, Theme, Icon, ButtonBase } from '@material-ui/core';
import { Avatar as MuiAvatar } from '@material-ui/core';
import { OnAnyEventHandler } from '../helpers/CommonTypes';
import LightBulbIcon from '@material-ui/icons/EmojiObjects';

const useStyles = makeStyles(({ palette, ...theme }: Theme) => ({
    root: {
        position: 'relative',
        display: 'inline-block',
        maxHeight: '120px',
    },
    avatarBox: {
        display: 'inline-block',
        borderRadius: '8px',
        overflow: 'hidden',
    },
    image: {
        maxHeight: '100%',
        width: '100%',
        height: '100%',
    },
    roundImageBorder: {
        border: `3px solid ${palette.common.white}`,
    },
    squareImageBorder: {
        border: `5px solid ${palette.common.white}`,
    },
    imageShadow: {
        background: palette.common.white,
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.14)',
    },
    roundShape: {
        borderRadius: '100px',
    },
    percentage: {
        position: 'absolute',
    },
    percentageHidden: {
        display: 'none',
    },
    percentTopLeftPosition: {
        top: 5,
        left: 5,
    },
    percentBottomCenterPosition: {
        bottom: '-8px',
        right: 'calc(50% - 20px)',
    },
    iconPlacement: {
        top: 5,
        left: 5,
        position: 'absolute',
    },
    icon: {
        width: 30,
        height: 30,
        padding: 0,
        minWidth: 30,
        borderRadius: '50%',
        color: palette.primary.contrastText,
    },
    projectOwnerIconContainer: {
        fontSize: '12px',
    },
    projectOwnerIcon: {
        margin: '2px 0 0 3px',
    },
    normal: {
        width: '135px',
        height: '135px',
    },
    mini: {
        width: 24,
        height: 24,
    },
    'xs-small': {
        width: 60,
        height: 60,
    },
    small: {
        width: 80,
        height: 80,
    },
    large: {
        width: 120,
        height: 120,
    },
    visibleImage: {
        opacity: 1,
        position: 'inherit',
    },
    invisibleImage: {
        // Make component transparent.
        opacity: 0,
        // Remove it from the DOM.
        position: 'fixed',
    },
    muiAvatarImage: {
        borderRadius: '5px',
    },
}));

export interface AvatarProps {
    alt?: string;
    percent?: number;
    className?: string;
    withBorder?: boolean;
    withShadow?: boolean;
    isProjectOwner?: boolean;
    shape: 'square' | 'round';
    picture: Picture | string;
    isAuthenticated?: boolean;
    onClick?: OnAnyEventHandler;
    addUniqueVersionIdToURL?: boolean;
    size: 'mini' | 'xs-small' | 'small' | 'normal' | 'large';
    percentagePlacement?: 'top-left' | 'bottom-center' | 'none';
}

const Avatar = memo(
    ({
        picture,
        percentagePlacement = 'top-left',
        size,
        addUniqueVersionIdToURL,
        ...props
    }: AvatarProps) => {
        const classes = useStyles();
        const rootClasses = classNames(props.className, classes.root);

        const imageSizeClass = size ? classes[size] : classes.normal;
        const imageClasses = classNames(classes.avatarBox, imageSizeClass, {
            [classes.imageShadow]: props.withShadow,
            [classes.roundImageBorder]: props.withBorder,
            [classes.squareImageBorder]:
                props.withBorder && props.shape === 'square',
            [classes.roundShape]: props.shape === 'round',
        });
        const percentageClasses = classNames(
            classes.percentage,
            percentagePlacement === 'top-left'
                ? classes.percentTopLeftPosition
                : classes.percentBottomCenterPosition,
            percentagePlacement === 'none' && classes.percentageHidden,
        );

        const [isImageLoading, toggleImageLoading] = useToggle(true);
        const imageUrl = getPictureUrl(
            picture,
            defaultPicture,
            addUniqueVersionIdToURL,
        );

        return (
            <Box className={rootClasses}>
                <Box display={isImageLoading ? 'inline-block' : 'none'}>
                    <Skeleton
                        animation="wave"
                        className={props.className}
                        variant={props.shape === 'round' ? 'circle' : 'rect'}
                    >
                        <Box className={imageSizeClass} />
                    </Skeleton>
                </Box>
                <ButtonBase
                    onClick={props.onClick}
                    className={classNames(
                        props.shape === 'round' && classes.roundShape,
                    )}
                >
                    <MuiAvatar
                        src={imageUrl}
                        alt={props.alt}
                        onLoad={() => toggleImageLoading(false)}
                        classes={{ img: classes.muiAvatarImage }}
                        className={classNames(
                            props.className,
                            imageClasses,
                            isImageLoading
                                ? classes.invisibleImage
                                : classes.visibleImage,
                        )}
                    />
                </ButtonBase>
                {props.isProjectOwner ? (
                    <Icon
                        color="action"
                        className={classNames(
                            classes.icon,
                            classes.iconPlacement,
                            classes.projectOwnerIconContainer,
                        )}
                    >
                        <LightBulbIcon className={classes.projectOwnerIcon} />
                    </Icon>
                ) : (
                    props.isAuthenticated && (
                        <>
                            {/*<MatchPercentage*/}
                            {/*    number={props.percent}*/}
                            {/*    className={percentageClasses}*/}
                            {/*/>*/}
                        </>
                    )
                )}
            </Box>
        );
    },
);

Avatar.displayName = 'Avatar';

export { Avatar };
