export const APP_EVENTS = {
    TEST: 'TEST',
    // APP
    APP_ON_ROUTE_CHANGED: 'APP_ON_ROUTE_CHANGED',

    // AUTH
    AUTH_USER_TOKEN_CHANGED: 'AUTH_USERTOKEN_CHANGED',
    AUTH_USER_AUTHENTICATED: 'AUTH_USER_AUTHENTICATED',
    AUTH_SIGNUP: 'AUTH_SIGNUP',

    //ONBOARDING
    ONBOARDING_STARTED: 'ONBOARDING_STARTED',
    ONBOARDING_FINISHED: 'ONBOARDING_FINISHED',

    //CATEGORY_ACTION_LABEL

    //USER
    USER_PROFILE_UPDATED: 'PROFILE_UPDATED',
    USER_PROFILE_ACTIVATED: 'USER_PROFILE_ACTIVATED',
    USER_PROFILE_DEACTIVATED: 'USER_PROFILE_DEACTIVATED',
    DELETE_PROFILE: 'PROFILE_DELETE',
    USER_PROFILE_POSITION_SAVED: 'USER_POSITION_SAVED',

    //PROJECT
    CREATE_FIRST_PROJECT: 'PROJECT_FIRST_CREATED',
    CREATE_NEW_PROJECT_AGAIN: 'PROJECT_CREATE_NEW_PROJECT_AGAIN',
    DELETE_PROJECT: 'PROJECT_DELETED',
    PROJECT_PROFILE_UPDATED: 'PROJECT_UPDATED',
    PROJECT_PROFILE_ACTIVATED: 'PROJECT_PROFILE_ACTIVATED',
    PROJECT_PROFILE_DEACTIVATED: 'PROJECT_PROFILE_DEACTIVATED',

    //----------CONNECT------------
    USER_SEND_PROJECT_INVITE: 'CONNECT_USER_SEND_PROJECT_INVITE',
    PROFILE_INVITE_RECALL_REMOVE: 'CONNECT_PROFILE_NOT_ACCEPTED',
    USER_JOIN_THE_PROJECT: 'CONNECT_USER_JOIN_THE_PROJECT',
    APPLY_TO_PROJECT: 'CONNECT_APPLY_TO_JOIN',
    INVITE_TO_PROJECT: 'CONNECT_INVITE_TO_JOIN',
    PROFILE_ACCEPT: 'CONNECT_PROFILE_ACCEPTED',
    USER_RECALL: 'CONNECT_USER_RECALL',
    PROJECT_RECALL: 'CONNECT_PROJECT_RECALL',
    PROJECT_REJECT: 'CONNECT_PROJECT_REJECT',
    USER_REJECT: 'CONNECT_USER_REJECT',
    PROJECT_INVITE_RECALL_REMOVE: 'CONNECT_INVITATION_NOT_ACCEPTED',
    PROJECT_ACCEPT: 'CONNECT_USER_BEEN_ACCEPTED',

    //SEARCH
    HAPPY_SEARCH_RESULTS_PROJECTS: 'HAPPYSEARCH_RESULTS_PROJECTS',
    HAPPY_SEARCH_RESULTS_PEOPLE: 'HAPPYSEARCH_RESULTS_PEOPLE',
    HAPPY_SEARCH_SURF_PEOPLE: 'HAPPYSEARCH_SURF_PEOPLE',

    //SHARE
    SHARE_OTHER_PROJECT: 'SHARE_OTHER_PROJECT',
    SHARE_OTHER_PERSON: 'SHARE_OTHER_PERSON',
    SHARED_OWN_PROFILE: 'SHARE_OWN_PROFILE',
    SHARED_OWN_PROJECT: 'SHARE_OWN_PROJECT',
    SHARED_OWN_ACTIVE_PROJECT: 'SHARE_OWN_ACTIVE_PROJECT',

    //COOL
    COOL_UP: 'COOL_UP',
    COOL_DOWN: 'COOL_DOWN',

    //CHAT
    NEW_CHAT: 'CHAT_NEW',
    CHAT_RESPONSE: 'CHAT_MESSAGE_RESPONSE',
    CHAT_MESSAGE_RECEIVED: 'CHAT_MESSAGE_RECEIVED',
};
// plz do not remove
// copy(Object.keys(qwe).map(prop => qwe[prop]).reduce((acc, cv)=>{ acc[cv.split('_')[0]]= [].concat(acc[cv.split('_')[0]] || [], (cv.split('_')[1] ? cv.split('_').slice(1).join('_') : cv)); return acc },{}))
