import { createMuiTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';
import { getThemeDepartmentColors } from './DepartmentColors';
import {
    WePrimary,
    WeSecondary,
    WeWarning,
    WeSuccess,
    WeInfo,
    WeError,
} from './colors';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import typographyStyles from './styles/typographyStyles';

const breakpoints = createBreakpoints({});

const primaryTextColor = '#2D2963';
const secondaryTextColor = '#bdbdbd';
const white = '#ffffff';
const gray = '#8e8e8e';

let mainTheme = createMuiTheme({
    typography: typographyStyles,
    // https://material-ui.com/customization/palette/#palette-colors
    palette: createPalette({
        primary: WePrimary,
        secondary: WeSecondary,
        info: WeInfo,
        success: WeSuccess,
        error: WeError,
        warning: WeWarning,
        text: {
            primary: primaryTextColor,
            secondary: secondaryTextColor,
            disabled: gray,
            hint: '#8B8E9E',
        },
        background: {
            default: white,
        },
    }),
    /**
     * Change default styles of MUI components.
     * https://v1.material-ui.com/customization/themes/#customizing-all-instances-of-a-component-type
     */
    overrides: {
        MuiButtonBase: {
            root: {
                textTransform: 'none',
            },
        },
        MuiButton: {
            root: {
                // Buttons must not be uppercased.
                textTransform: 'none',
                borderRadius: '10px',
                '&$disabled': {
                    border: 'none',
                },
            },
            contained: {
                paddingBottom: '8px',
                paddingLeft: '38px',
                paddingRight: '38px',
                boxShadow: 'none',
                color: 'white',
                backgroundColor: WeSecondary[400],
            },
            containedPrimary: {
                backgroundColor: WePrimary[400],
                border: `1px ${WePrimary[400]} solid`,
            },
            containedSecondary: {
                backgroundColor: 'white',
                color: WeSecondary[600],
                border: `1px ${WeSecondary[600]} solid`,
                '&:hover': {
                    color: white,
                },
            },
        },
        MuiListItem: {
            gutters: {
                paddingLeft: 0,
                paddingRight: 0,
                [breakpoints.up('md')]: {
                    marginTop: 15,
                },
            },
        },
        MuiDialogTitle: {
            root: {
                color: white,
                backgroundColor: primaryTextColor,
            },
        },
        MuiChip: {
            root: {
                height: '33px',
                fontSize: '14px',
                borderRadius: '6px',
                padding: '7px 10px',
                backgroundColor: white,
                color: gray,
                border: `1px solid ${gray}`,
                fontWeight: 500,
            },
            clickableColorPrimary: {
                '&:hover': {
                    backgroundColor: WeInfo[50],
                    borderColor: WeInfo[50],
                },
                '&:focus': {
                    backgroundColor: WeInfo[50],
                    borderColor: WeInfo[50],
                },
            },
            clickable: {
                '&:hover': {
                    backgroundColor: white,
                    borderColor: gray,
                },
                '&:focus': {
                    backgroundColor: white,
                    borderColor: gray,
                },
            },
            colorPrimary: {
                backgroundColor: WeInfo[50],
                color: primaryTextColor,
                borderColor: WeInfo[50],
            },
            sizeSmall: {
                fontSize: '12px',
                height: '28px',
            },
        },
        MuiTabs: {
            root: {
                backgroundColor: 'transparent',
            },
            indicator: {
                backgroundColor: 'transparent',
            },
        },
        MuiTab: {
            root: {
                color: WePrimary[500],
                textTransform: 'capitalize',
                '&$selected': {
                    backgroundColor: 'transparent',
                },
            },
            textColorInherit: {
                color: WePrimary[500],
            },
        },
        MuiIconButton: {
            root: {
                '&:hover': {
                    backgroundColor: '$labelcolor',
                },
            },
        },
        MuiLink: {
            root: {
                color: WePrimary['A100'],
            },
        },
        MuiFormHelperText: {
            root: {
                '&.Mui-error': {
                    fontSize: '12px',
                },
            },
        },
        MuiFormLabel: {
            root: {
                '&.Mui-required': {
                    color: WePrimary['A700'],
                },
            },
        },
    },
    /**
     * Change default props of MUI components.
     */
    props: {
        MuiButton: {
            color: 'primary',
            variant: 'contained',
        },
    },
});

mainTheme = getThemeDepartmentColors(mainTheme);

export const MainTheme = mainTheme;
