import { Box, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import debug from 'debug';
import { Field, Formik } from 'formik';
import React, { memo } from 'react';
import { WeTextField } from '../forms/WeTextField';
import { WE_CLICKS } from '../../../../../apps/client/src/we_clicks';

const log = debug('EnterNameDialog');

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

interface Props {
    onSubmit: (string) => void;
    isOpen: boolean;
    className?: string;
    toggleIsOpen: () => void;
}

const EnterNameDialog = memo((props: Props) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return (
        <Box className={rootClasses}>
            <Formik
                initialValues={{ name: '' }}
                validate={({ name }) => {
                    const errors: Partial<{ name: string }> = {};
                    if (!name.trim()) errors.name = 'Required';
                    return errors;
                }}
                onSubmit={(values) => {
                    props.onSubmit(values.name);
                    values.name = '';
                    props.toggleIsOpen();
                }}
            >
                {({ submitForm, values }) => (
                    <Dialog
                        open={props.isOpen}
                        onClose={() => {
                            values.name = '';
                            props.toggleIsOpen();
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Please enter a name
                        </DialogTitle>
                        <DialogContent>
                            <Field
                                name="name"
                                component={WeTextField}
                                autoFocus
                                margin="dense"
                                label="Search Name"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    values.name = '';
                                    props.toggleIsOpen();
                                }}
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                id={WE_CLICKS.SUCCESS_ADVANCE_SEARCH}
                                type="submit"
                                color="primary"
                                onClick={submitForm}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </Box>
    );
});

EnterNameDialog.displayName = 'EnterNameDialog';

export { EnterNameDialog };
