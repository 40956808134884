import React from 'react';
import { Box } from '@material-ui/core';
import DesktopModeLeftSideBar from './Desktop/LeftSideBar';
import Drawer from '@material-ui/core/Drawer';
import MobileModeLeftSideBar from './Mobile/LeftSideBar';
import { useLeftSidebarStyles } from './styles/LeftSidebar.styles';

export interface LeftSidebarProps {
    desktopMode: boolean;
    open: boolean;
    toggle: () => void;
}

export const LeftSidebar = ({
    open,
    toggle,
    desktopMode,
}: LeftSidebarProps) => {
    const classes = useLeftSidebarStyles();
    return (
        <>
            {!!desktopMode && (
                <Box className={classes.leftDrawer}>
                    <Box className={classes.leftDrawerPaper}>
                        <DesktopModeLeftSideBar />
                    </Box>
                </Box>
            )}
            {!desktopMode && (
                <Drawer
                    className={classes.leftDrawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{ paper: classes.leftDrawerPaper }}
                >
                    <MobileModeLeftSideBar toggle={toggle} />
                </Drawer>
            )}
        </>
    );
};
