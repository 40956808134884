import { AnalyticsProviderInterface } from '../AnalyticsProviderInterface';
import { AnalyticsEvent, AuthAnalyticsEvent } from '../AnalyticsEvent';
import * as mixpanel from 'mixpanel-browser';
import _ from 'lodash';

export class MixpanelProvider implements AnalyticsProviderInterface {
    private mixpanel: any;
    isUserIdentified = false;
    // get isReady():boolean {
    //     return this.isUserIdentified;
    // }
    constructor(private settings) {}

    load(): Promise<boolean> {
        if (!this.settings?.id) {
            return Promise.resolve(true);
        }
        return new Promise<boolean>((resolve, reject) => {
            mixpanel.init(this.settings?.id, {
                debug: !!this.settings?.debug,
                loaded: () => {
                    resolve(true);
                    this.mixpanel = mixpanel;
                },
            });
        });
    }

    event = (event: AnalyticsEvent) => {
        this.mixpanel.track(event.name, event.payload);
    };

    click = (event: AnalyticsEvent) => {
        this.mixpanel.track('WE_CLICK', {
            ...(event.payload || {}),
            name: event.name,
        });
    };

    exception = _.debounce(
        (e: Error, severity: string = 'LOW'): void => {
            this.mixpanel.track('EXCEPTION', {
                severity,
                name: e.name,
            });
        },
        1000,
        { leading: true, trailing: false },
    );
    identify = (event: AuthAnalyticsEvent) => {
        if (!event.userId) {
            return;
        }
        this.mixpanel.identify(event.userId);
        this.mixpanel.people.set({
            $email: event.payload.email,
            $name: `${event.payload.firstName} ${event.payload.lastName}`,
        });
        // this.isUserIdentified = true;
    };

    setProfile = (props: { [name: string]: string | number | boolean }) => {
        this.mixpanel.people.set(props);
    };

    alias = (userId: string) => {
        this.mixpanel.alias(userId);
    };

    pageView = (url: string, type?: string) => {
        this.mixpanel.track('PageView', {
            url,
            name: type,
        });
        // this.dataLayer.push({
        //     event: 'PageView',
        //     payload: { url },
        // })
    };
}
