import { BindingScopeEnum, Container, interfaces } from 'inversify';
import {
    ClassProvider,
    FactoryProvider,
    Provider,
    ValueProvider,
} from './interfaces';
import { Type } from '../types';

export class ProvidersContainer {
    readonly container: Container;

    // readonly container: ContainerInstance;

    constructor(services: Provider[] = []) {
        this.container = new Container({
            defaultScope: BindingScopeEnum.Singleton,
        });
        // this.container = Container.of('default');
        this.register(services);
    }

    register(services: Provider[]) {
        services.forEach((s) => this.add(s));
    }

    add(provider: Provider) {
        if ((provider as ClassProvider).useClass) {
            return this.addUseClassProvider(provider as ClassProvider);
        }
        if ((provider as ValueProvider).useValue) {
            return this.addUseValueProvider(provider as ValueProvider);
        }
        if ((provider as FactoryProvider).useFactory) {
            return this.addUseFactoryProvider(provider as FactoryProvider);
        }
        return this.addUseSelfClassProvider(provider);
    }

    get<T = any>(identity): T {
        if (identity.constructor) {
            return this.container.get<T>(identity);
        }
        return this.container.get<T>(identity);
    }

    has(identity): boolean {
        return this.container.isBound(identity);
        // return this.container.has(identity);
    }

    resolve<T = any>(classType: interfaces.Newable<T>): T {
        return this.container.resolve<T>(classType);
    }

    unbind(serviceIdentifier: interfaces.ServiceIdentifier<any>) {
        return this.container.unbind(serviceIdentifier);
    }

    private addUseClassProvider(provider: ClassProvider) {
        return this.container
            .bind(provider.provide)
            .to(provider.useClass)
            .inSingletonScope();
        // this.container.set({id: provider.provide as any, name: provider.useClass})
    }

    private addUseValueProvider(provider: ValueProvider) {
        return this.container
            .bind(provider.provide)
            .toConstantValue(provider.useValue);
        // this.container.set({name:provider.provide as any, value: provider.useValue});
    }

    private addUseFactoryProvider(provider: FactoryProvider) {
        return this.container.bind(provider.provide).toFactory(() => {
            return provider.useFactory(
                ...[]
                    .concat(provider.inject || [])
                    .map((dep) => this.container.get(dep)),
            );
        });
        // this.container.set({name: provider.provide as any, factory: () => {
        //             return provider.useFactory(...[].concat(provider.inject).map(dep => this.container.get(dep)));
        //         }});
    }

    private addUseSelfClassProvider(provider: Provider) {
        return this.container
            .bind(provider as Type<Provider>)
            .toSelf()
            .inSingletonScope();
        // this.container.set(provider as any);
    }
}
