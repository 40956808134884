import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import randomMC from 'random-material-color';
import { OnAnyEventHandler } from '@weco/ui';
import { getPictureUrl } from '@weco/common';
import { Picture, defaultPicture } from '@weco/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },

    avatar: {
        maxHeight: '100%',
        width: '100%',
        height: '100%',
    },
}));

export type ProfileAvatarProps = {
    picture?: Picture | string;
    onClick?: OnAnyEventHandler;
};

const ProfileAvatar = ({ picture, onClick }: ProfileAvatarProps) => {
    const classes = useStyles();
    const myClass = classes.root;
    const placeholderImage =
        typeof picture === 'string'
            ? picture
            : getPictureUrl(picture, defaultPicture, true);
    return (
        <Box
            className={myClass}
            style={{
                backgroundColor: randomMC.getColor({
                    text: 'test',
                }),
            }}
        >
            <Box
                className={classes.avatar}
                style={{
                    backgroundImage: `url(${placeholderImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'center',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    cursor: 'pointer',
                }}
                onClick={onClick}
            />
        </Box>
    );
};

export default ProfileAvatar;
