import { connect } from 'react-redux';
import RoomHeader from '../components/RoomHeader';
import firebase from 'firebase/app';
import {
    deleteRoom,
    renameRoom,
    leaveRoom,
    setActiveRoom,
} from '../store/rooms/actions';
import UserInterface from '../models/interfaces/UserInterface';
import { RoomInterface } from '../models/interfaces/RoomInterface';
import { searchUsers } from '../store/users/actions';

const mapStateToProps = (state: any) => ({
    currentUser:
        state.chat.authUser.uid &&
        state.chat.users.list.items[state.chat.authUser.uid]
            ? state.chat.users.list.items[state.chat.authUser.uid]
            : null,
    users: state.chat.users.list.items,
    searchUsersList: state.chat.users.search.items,
    room: state.chat.rooms.list.items[state.chat.rooms.list.activeRoom] || null,
});

const mapDispatchToProps = (dispatch: Function) => ({
    renameRoom: (
        firestore: firebase.firestore.Firestore,
        roomId: string,
        name: string,
    ) => dispatch(renameRoom(firestore, roomId, name)),
    leaveRoom: (
        firestore: firebase.firestore.Firestore,
        user: UserInterface,
        room: RoomInterface,
    ) => dispatch(leaveRoom(firestore, user, room)),
    deleteRoom: (firestore: firebase.firestore.Firestore, roomId: string) =>
        dispatch(deleteRoom(firestore, roomId)),
    searchUsers: (findUserByName: Function, q: string, exclude: string[]) =>
        dispatch(searchUsers(findUserByName, q, exclude)),
    setActiveRoom: (
        firestore: firebase.firestore.Firestore,
        id: string,
        subscribe: boolean,
    ) => dispatch(setActiveRoom(firestore, id, subscribe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomHeader);
