import { EventSubscriberInterface, Injectable } from '@weco/common';
import { APP_EVENTS } from '../../../app_events';
import { fetchDataToMailChimp } from '../../helpers/fetchDataToMailChimp';
import { UserProjectInviteEvent } from '../../events/UserProjectInviteEvent';
import { MailChimpEvent } from '../../events/MailChimpEvent';
import { Event } from '@weco/common';
import { environment } from '../../../environments/environment';

@Injectable()
export class MailchimpEventSubscriber implements EventSubscriberInterface {
    subscribedEvents: Map<string | RegExp, (event: any) => void> = new Map();

    constructor() {
        this.subscribedEvents.set(
            APP_EVENTS.USER_SEND_PROJECT_INVITE,
            this.onUserSendProjectInvite,
        );
        this.subscribedEvents.set(
            APP_EVENTS.CREATE_FIRST_PROJECT,
            this.onCreateFirstProject,
        );
        this.subscribedEvents.set(APP_EVENTS.AUTH_SIGNUP, this.onUserSignUp);
        this.subscribedEvents.set(APP_EVENTS.TEST, this.onTest);
        this.subscribedEvents.set(
            APP_EVENTS.DELETE_PROFILE,
            this.onDeleteProfile,
        );
        this.subscribedEvents.set(
            APP_EVENTS.PROJECT_INVITE_RECALL_REMOVE,
            this.onProjectInviteRecallRemove,
        );
        this.subscribedEvents.set(
            APP_EVENTS.PROJECT_ACCEPT,
            this.onProjectAccept,
        );
        this.subscribedEvents.set(APP_EVENTS.NEW_CHAT, this.onNewChat);
        this.subscribedEvents.set(
            APP_EVENTS.CHAT_RESPONSE,
            this.onChatResponse,
        );
        this.subscribedEvents.set(
            APP_EVENTS.DELETE_PROJECT,
            this.onDeleteProject,
        );
        this.subscribedEvents.set(
            APP_EVENTS.PROFILE_INVITE_RECALL_REMOVE,
            this.onProfileInviteRecallRemove,
        );
        this.subscribedEvents.set(
            APP_EVENTS.PROFILE_ACCEPT,
            this.onProfileAccept,
        );
        this.subscribedEvents.set(
            APP_EVENTS.APPLY_TO_PROJECT,
            this.onApplyToProject,
        );
        this.subscribedEvents.set(
            APP_EVENTS.INVITE_TO_PROJECT,
            this.onInviteIntoProject,
        );
    }

    onUserSendProjectInvite = (event: UserProjectInviteEvent) => {
        console.log('MailChimpEventSubscriber', event);
    };

    onCreateFirstProject = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'INVITE_CREATE',
            ),
        );
    };

    onProjectAccept = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'YOURBEENACCEPTED',
            ),
        );
    };

    onUserSignUp = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'AUTH_CONFIRMATION_SIGNUP',
            ),
        );
    };

    onTest = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'TEST',
            ),
        );
    };

    onDeleteProfile = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'PROFILE_DELETE',
            ),
        );
    };

    onProjectInviteRecallRemove = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'INVITATION_NOTACCEPTED',
            ),
        );
    };

    onNewChat = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'CHAT_NEW',
            ),
        );
    };

    onChatResponse = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'CHAT_MESSAGE_RESPONSE',
            ),
        );
    };

    onDeleteProject = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'PROJECT_DELETE',
            ),
        );
    };

    onProfileInviteRecallRemove = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'PROFILE_NOT_ACCEPTED',
            ),
        );
    };

    onProfileAccept = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'PROFILE_ACCEPTED',
            ),
        );
    };

    onApplyToProject = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'APPLYTOJOIN',
            ),
        );
    };

    onInviteIntoProject = (event: Event) => {
        fetchDataToMailChimp(
            new MailChimpEvent(
                environment.mailchimp.listId,
                event.subject.email,
                'INVITETOJOIN',
            ),
        );
    };
}
