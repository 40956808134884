import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, memo } from 'react';
import { useHistory } from 'react-router';
import { OnboardingPageSubtitle } from './OnboardingPageSubtitle';
import { OnboardingPageTitle } from './OnboardingPageTitle';
import LoginBackground from '../../../../assets/images/login-background.png';

const useStyles = makeStyles((theme) => ({
    container: {
        // minHeight: '100vh',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    closeButton: {
        padding: '12px 0',
    },
    closeButtonIcon: {
        fontSize: '33px',
        color: '#BDBDBD',
    },
}));

interface props {
    title: string;
    subtitle?: string;
    onBackButtonPressed?: Function;
    onForwardButtonPressed?: Function;
    children: JSX.Element;
    bottomChildren?: JSX.Element;
}

const component: FunctionComponent<props> = (props) => {
    const cx = useStyles();
    const history = useHistory();
    return (
        <>
            <Grid item>
                <Box pb={3} px={2}>
                    <OnboardingPageTitle>{props.title}</OnboardingPageTitle>
                </Box>
                {/* TODO refactor via <If /> component */}
                {props.subtitle && (
                    <Box pb={3}>
                        <OnboardingPageSubtitle>
                            {props.subtitle}
                        </OnboardingPageSubtitle>
                    </Box>
                )}
            </Grid>
            <Grid>{props.children}</Grid>
            <Grid>
                <Box pb={2}>{props.bottomChildren}</Box>
            </Grid>
        </>
    );
};

export const OnboardingPageWrapper = memo(component);
