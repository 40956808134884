import React from 'react';
import {
    goTo,
    SectionWithTitle,
    WeChipsList,
    WeEditable,
    WeFundingList,
} from '@weco/ui';
import { useMyProjectData } from '../store/useMyProjectData';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import isEqual from 'lodash/isEqual';
import { Box, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { FundingFormControl } from '../formControls/FundingFormControl';
import { objectToTypeValue } from '../../../helpers/utils';
import { AutocompleteFormControl } from '../formControls';

export const ResourcesTabPage = ({ baseUrl, projectId }) => {
    const {
        item: myProject,
        updateItem: updateProject,
        constants,
    } = useMyProjectData(projectId);
    const classes = useTabStyles();

    const save = (newMyProject) => {
        if (isEqual(newMyProject, myProject)) {
            return Promise.resolve();
        } else {
            return updateProject(newMyProject);
        }
    };

    return (
        <Box className={classes.tabContent}>
            <Formik initialValues={myProject} onSubmit={save}>
                {({ handleSubmit, values, errors }) => {
                    return (
                        <>
                            <Grid container>
                                <Grid item xs={12} className={classes.section}>
                                    <WeEditable
                                        fieldId="SAVED_RESOURCES_REQUIRED_PROJECT"
                                        hasError={!!errors.investments}
                                        onSave={handleSubmit}
                                        classes={{ editBox: classes.editBox }}
                                        editableComponent={
                                            <AutocompleteFormControl
                                                name="investments"
                                                label="Investment"
                                                options={constants?.investments}
                                            />
                                        }
                                    >
                                        <SectionWithTitle
                                            title="Investment"
                                            isEmpty={isEmpty(
                                                values.investments,
                                            )}
                                            placeholder="You’re investing your time and love into this project, let someone invest something else too."
                                        >
                                            <WeChipsList
                                                chipsGroupName="investments"
                                                items={values.investments}
                                            />
                                        </SectionWithTitle>
                                    </WeEditable>
                                </Grid>
                                <Grid item xs={12} className={classes.section}>
                                    <WeEditable
                                        fieldId="SAVED_RESOURCES_OFFERED_PROJECT"
                                        hasError={!!errors.compensations}
                                        onSave={handleSubmit}
                                        classes={{ editBox: classes.editBox }}
                                        editableComponent={
                                            <AutocompleteFormControl
                                                name="compensations"
                                                label="Compensation"
                                                options={constants?.investments}
                                            />
                                        }
                                    >
                                        <SectionWithTitle
                                            title="Compensation"
                                            isEmpty={isEmpty(
                                                values.compensations,
                                            )}
                                            placeholder="How will you compensate your teammates for the value they add to your project?"
                                        >
                                            <WeChipsList
                                                chipsGroupName="compensations"
                                                items={values.compensations}
                                            />
                                        </SectionWithTitle>
                                    </WeEditable>
                                </Grid>
                                <Grid item xs={12} className={classes.section}>
                                    <WeEditable
                                        fieldId="SAVED_RESOURCES_CAMPAIGNS_PROJECT"
                                        hasError={!!errors.fundings}
                                        onSave={handleSubmit}
                                        classes={{ editBox: classes.editBox }}
                                        editableComponent={
                                            <FundingFormControl
                                                name="fundings"
                                                label="Funding"
                                                options={constants?.fundings}
                                                helpIcon={{ showed: false }}
                                            />
                                        }
                                    >
                                        <SectionWithTitle
                                            title="Funding"
                                            isEmpty={isEmpty(values.fundings)}
                                            placeholder="Every project can use a little kickstart. Add your crowdfunding link(s) here."
                                        >
                                            <WeFundingList
                                                onClick={(uri) => goTo(uri)}
                                                items={objectToTypeValue(
                                                    values.fundings,
                                                )}
                                            />
                                        </SectionWithTitle>
                                    </WeEditable>
                                </Grid>
                            </Grid>
                        </>
                    );
                }}
            </Formik>
        </Box>
    );
};
