export const WE_CLICKS = {
    ENTRY_LOGOUT: 'ENTRY_LOGOUT',
    CLOSE_ADVANCE_SEARCH: 'CLOSE_ADVANCE_SEARCH',
    ENTRY_NEWPROJECT_POPUP: 'ENTRY_NEWPROJECT_POPUP',
    RESET_ADVANCE_SEARCH: 'RESET_ADVANCE_SEARCH',
    SAVED_ADVANCE_SEARCH: 'SAVED_ADVANCE_SEARCH',
    SUCCESS_ADVANCE_SEARCH: 'SUCCESS_ADVANCE_SEARCH',
    AVATAR_PROFILE_CREATING: 'AVATAR_PROFILE_CREATING',
    AVATAR_PROJECT_CREATING: 'AVATAR_PROJECT_CREATING',
    PROFILE_SAVED: 'PROFILE_SAVED',
    PERSON_PROFILE_POSITION_ADD: 'PERSON_PROFILE_POSITION_ADDED',
    CREATE_PROJECT_NAME_CREATE: 'CREATE_PROJECT_NAME_CREATE',
    CREATE_PROJECT_NAME_CANCEL: 'CREATE_PROJECT_NAME_CANCEL',
    CREATE_NEW_PROJECT_TEAM: 'CREATE_NEW_PROJECT_TEAM',
    SKILLS_SEARCH_PROJECTS: 'SKILLS_SEARCH_PROJECTS',
    TEST_ENTRY_SKILLS_STARTED: 'TEST_ENTRY_SKILLS_STARTED',

    LOGIN_GOOGLE: 'AUTH_GOOGLE_LOGIN',
    LOGIN_LINKEDIN: 'AUTH_LINKEDIN_LOGIN',
    LOGIN_FACEBOOK: 'AUTH_FACEBOOK_LOGIN',
    LOGIN_EMAIL: 'AUTH_EMAIL_LOGIN',
    SIGNUP_GOOGLE: 'AUTH_GOOGLE_SIGNUP',
    SIGNUP_LINKEDIN: 'AUTH_LINKEDIN_SIGNUP',
    SIGNUP_FACEBOOK: 'AUTH_FACEBOOK_SIGNUP',
    SIGNUP_EMAIL: 'AUTH_EMAIL_SIGNUP',
    CONFIRM_SIGNUP: 'AUTH_CONFIRM_SIGNUP',
    COOL_UP: 'COOL_UP',
    COOL_DOWN: 'COOL_DOWN',
};
