import { APP_EVENTS } from '../../../../../apps/client/src/app_events';

export const getSocialNetworkIds = ({
    type,
    networkName,
    own,
    activeProjectId,
    shareId,
}) => {
    if (type === 'project') {
        if (own) {
            if (activeProjectId === shareId) {
                return APP_EVENTS.SHARED_OWN_ACTIVE_PROJECT + networkName;
            } else {
                return APP_EVENTS.SHARED_OWN_PROJECT + networkName;
            }
        } else {
            return APP_EVENTS.SHARE_OTHER_PROJECT + networkName;
        }
    } else {
        if (own) {
            return APP_EVENTS.SHARED_OWN_PROFILE + networkName;
        } else {
            return APP_EVENTS.SHARE_OTHER_PERSON + networkName;
        }
    }
};
