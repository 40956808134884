import { EventDispatcher } from './EventDispatcher';
import { EventSubscriberInterface } from './EventSubscriberInterface';

export class CoreEventDispatcher extends EventDispatcher {
    constructor(subscribers?: EventSubscriberInterface[]) {
        super();
        (subscribers || []).forEach((sub) => {
            this.addSubscriber(sub);
        });
    }
}
