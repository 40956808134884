import { Inject, Injectable } from '@weco/common';
import { GraphQlBaseRepository } from '../repositories/GraphQlBaseRepository';
import { CORE_SERVICES } from '../core_services';
import { ProjectEntity, UserProfileEntity } from '../entity';
import { IProjectRepository } from '../repositories/ProjectRepository';
import { PersonRepository } from '../repositories/PersonRepository';
import { CurrentUserProvider } from '../repositories/CurrentUserProvider';

export interface MatchPercentServiceInterface {
    personToProfileMatchPercent(
        profileId: string,
        projectId: string,
    ): Promise<number>;
    matchProfilePercent(profile: UserProfileEntity | string): Promise<number>;
    matchProjectPercent(project: ProjectEntity | string): Promise<number>;
}

@Injectable()
export class MatchPercentService extends GraphQlBaseRepository
    implements MatchPercentServiceInterface {
    @Inject(CORE_SERVICES.IProjectRepository)
    private projectRepository: IProjectRepository;

    @Inject(CORE_SERVICES.PersonRepository)
    private profileRepository: PersonRepository;

    @Inject(CORE_SERVICES.ICurrentUserProvider)
    currentUser: CurrentUserProvider;

    private readonly fields = [
        {
            name: 'causes',
            maxPercent: 20,
        },
        {
            name: 'passions',
            maxPercent: 40,
        },
        {
            name: 'objectives',
            maxPercent: 40,
        },
        {
            name: 'positions',
            maxPercent: 10,
        },
        {
            name: 'resources',
            maxPercent: 10,
        },
    ];

    private readonly importantMatches = [
        ['positions'],
        ['resources'],
        ['passions', 'causes'],
    ];

    public async personToProfileMatchPercent(
        profile: UserProfileEntity | string,
        project: ProjectEntity | string,
    ): Promise<number> {
        let checkingProject = project;
        let checkingProfile = profile;

        if (!(profile instanceof UserProfileEntity)) {
            checkingProfile = await this.profileRepository.findById(profile);
        }
        if (!(project instanceof ProjectEntity)) {
            checkingProject = await this.projectRepository.getItem(project);
        }

        return this.calculatePercent(checkingProfile, checkingProject);
    }

    private async calculatePercent(
        profile: UserProfileEntity | string,
        project: ProjectEntity | string,
    ): Promise<number> {
        let percent = 0;

        this.fields.map((field) => {
            let fieldPercent = 0;
            const projectValues = project[field.name];
            const profileValues = profile[field.name];

            if (Array.isArray(projectValues) && Array.isArray(profileValues)) {
                projectValues.map((projectValue) => {
                    if (profileValues.indexOf(projectValue) > -1) {
                        fieldPercent =
                            fieldPercent > 0
                                ? fieldPercent +
                                  field.maxPercent /
                                      2 /
                                      (projectValues.length - 1)
                                : field.maxPercent / 2;
                    }
                });
            }
            percent += fieldPercent;
        });
        percent = Math.round(percent);
        return Promise.resolve(percent);
    }

    public async matchProfilePercent(profile: UserProfileEntity | string) {
        if (!profile || !this.currentUser.UserId) {
            return Promise.resolve(0);
        }

        const user = await this.profileRepository.findById(
            // why we use currentUser.UserId here, should it be profile.id instead?
            this.currentUser.UserId,
        );

        if (!user) {
            return Promise.resolve(0);
        }

        if (!user.activeProject) {
            return Promise.resolve(0);
        }

        const projectId = user.activeProject.id;

        if (!projectId) {
            return Promise.resolve(0);
        }

        return this.personToProfileMatchPercent(profile, projectId);
    }

    public async matchProjectPercent(project: ProjectEntity | string) {
        if (!project) {
            return 0;
        }

        const profileId = this.currentUser.UserId;

        if (!profileId) {
            return 0;
        }

        return this.personToProfileMatchPercent(profileId, project);
    }
}
