import React from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChildrenType } from '@weco/ui';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '13px',
        paddingTop: '2px',
        paddingLeft: '5px',
    },
    container: {
        minWidth: '270px',
        justifyContent: 'space-between',
        padding: '0 20px',
    },
    box: {
        transform: 'scale(0.8)',
        opacity: '0.4',
    },
    element: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    matched: {
        opacity: '1',
    },
}));

export interface CardFooterProps {
    icons: ChildrenType;
}

const CardFooter = React.memo(function CardFooterComponent({
    icons,
}: CardFooterProps) {
    const classes = useStyles();
    const { push } = useHistory();

    return (
        <Grid container className={classes.container}>
            {icons.map((item, index) => {
                return (
                    <Box
                        className={classNames({
                            [classes.box]: true,
                            [classes.matched]: item.isMatched,
                        })}
                        key={`${item.url + index}`}
                    >
                        <Box
                            className={classes.element}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                push(item.url);
                            }}
                        >
                            {item.icon}
                            <Typography className={classes.title}>
                                {item.label}
                            </Typography>
                        </Box>
                    </Box>
                );
            })}
        </Grid>
    );
});

CardFooter.displayName = 'CardFooter';

export { CardFooter };
