import React, { useEffect, useState } from 'react';
import {
    ReactHelpers,
    ChildrenType,
    EntityLoadingSkeleton,
    SkeletonAsyncLoader,
} from '@weco/ui';
import { Type, useInjection, ProvidersContainer } from '@weco/common';

export type StoreDataContainerProps = {
    classType: Type<any>;
    loader?: (store) => Promise<any>;
    children: ChildrenType;
    errorComponent?: ChildrenType;
    loaderComponent?: ChildrenType;
    onError?: (error: Error | any) => boolean | void;
    testPages?: boolean;
};

/**
 * @description * !!!This store is SINGLETON, so only one Data Container with that type will be allowed at once!!!
 * All `useStore` hooks called in the child component, will be return store created via this component.
 * This Data Container Wrapper instantiate Store instance (inject all dependencies via di as usually) by `classType`
 * and register that store into the DIc. Then it will be tried to call `loader` callback with that store as an argument, to initialize data
 * for that store. After this Component will be removed from the DOM, Store will be cleaned with `dispose` method (if it exists),
 * also Store will be removed from DIc.
 */
export const SingletonStoreContainer = React.memo(function ({
    classType,
    loader,
    children,
    errorComponent,
    loaderComponent,
    onError,
    ...rest
}: StoreDataContainerProps) {
    const di = useInjection<ProvidersContainer>();

    const [store, setStore] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        if (di.has(classType)) {
            setError(
                new Error(
                    `Only one Store Container instance (${
                        classType.name || classType
                    }) allowed at the same time. Check that you didn't register store into DI manually.`,
                ),
            );
            return;
        }
        di.add(classType);
        const newStore = di.get(classType);
        if (loader) {
            loader(newStore)
                .then(() => setLoading(false))
                .catch((e) => setError(e));
        } else {
            setLoading(false);
        }
        setStore(newStore);
        return () => {
            if (newStore?.dispose) {
                newStore.dispose();
            }
            di.unbind(classType);
        };
    }, []);

    return (
        <SkeletonAsyncLoader
            loading={loading}
            error={error}
            errorComponent={errorComponent}
            onError={onError}
            skeleton={<EntityLoadingSkeleton />}
        >
            {store &&
                !loading &&
                ReactHelpers.renderChildren(children, { store })}
        </SkeletonAsyncLoader>
    );
});
