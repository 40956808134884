import { CoolsStore } from '../../store/CoolsStore';
import { useCoolData } from '../../store/hooks/useCoolData';
import { CoolActions } from '../../../../../../libs/ui/src/lib/molecules/CoolActions';
import React from 'react';

export const CoolWrapper = ({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: 'Person' | 'Project';
}) => {
    const { loading, addCool, deleteCool, hasCool } = useCoolData(
        entityId,
        entityType,
    );

    return (
        <CoolActions
            loading={loading}
            addCool={addCool}
            deleteCool={deleteCool}
            hasCool={hasCool}
            entityType={entityType}
            entityId={entityId}
        />
    );
};
