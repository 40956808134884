import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import React, { memo } from 'react';
import { SpriteSvgIconButton } from '../molecules/SpriteSvgIconButton';
import { useRightSideBarItemStyles } from './RightSidebarMenuItem.styles';

interface Props {
    iconId?: string;
    count?: number;
    text?: string;
    className?: string;
}

const RightSidebarMenuItem = memo(
    ({ iconId, count, text, ...props }: Props) => {
        const classes = useRightSideBarItemStyles();
        const rootClasses = classNames(classes.infoBox, props.className);

        return (
            <Box className={rootClasses}>
                {!!iconId && (
                    <SpriteSvgIconButton
                        classes={{ root: classes.infoIcon }}
                        id={iconId}
                    />
                )}
                {!!count && (
                    <Typography className={classes.infoIcon}>
                        {count}
                    </Typography>
                )}
                {!!text && (
                    <Typography className={classes.infoText}>{text}</Typography>
                )}
            </Box>
        );
    },
);

RightSidebarMenuItem.displayName = 'RightSidebarMenuItem';

export { RightSidebarMenuItem };
