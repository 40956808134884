import { gql } from 'apollo-boost';
import debug from 'debug';
import { Injectable } from '@weco/common';
import { GraphQlBaseRepository } from './GraphQlBaseRepository';
import { CoolEntity } from '../entity/CoolEntity';
import { createCool, deleteCool } from './gqlRequests';
import { coolByEntityId, coolByOwnerIdEntityId } from './graphql/queries';

const log = debug('CoolRepository');

export interface AddCoolInterface {
    ownerId: string;
    coolOwnerId: string;
    entityType: 'Person' | 'Project';
    entityId: string;
}

export interface DeleteCoolInputInterface {
    id: string;
}

export interface HasCoolInputInterface {
    ownerId: string;
    entityType: 'Person' | 'Project';
    entityId: string;
}

export interface GetCoolsListByEntityIdInputInterface {
    entityType: 'Person' | 'Project';
    entityId: string;
    limit?: number;
    nextToken?: string;
}

export interface ICoolRepository {
    addCool(input: AddCoolInterface): Promise<CoolEntity>;
    deleteCool(input: DeleteCoolInputInterface): Promise<CoolEntity>;
    hasCool(input: HasCoolInputInterface): Promise<CoolEntity | null>;
    getCoolsListByEntityId(
        input: GetCoolsListByEntityIdInputInterface,
    ): Promise<CoolEntity[]>;
}

@Injectable()
export class CoolRepository extends GraphQlBaseRepository
    implements ICoolRepository {
    public async addCool(input: AddCoolInterface): Promise<CoolEntity> {
        return this.authorizedClient
            .mutate({
                mutation: gql(createCool),
                variables: { input },
                update: (cache) =>
                    this.invalidateAllQueryFetching([`Person`, `Project`]),
            })
            .then(({ data }) => {
                return data.createCool;
            })
            .catch((error) => {
                log(error);
                return null;
            });
    }

    public async deleteCool(
        input: DeleteCoolInputInterface,
    ): Promise<CoolEntity> {
        return this.authorizedClient
            .mutate({
                mutation: gql(deleteCool),
                variables: { input },
                update: (cache) =>
                    this.invalidateAllQueryFetching([`Person`, `Project`]),
            })
            .then(({ data }) => {
                return data.deleteCool;
            })
            .catch((error) => {
                log(error);
                return null;
            });
    }

    public async hasCool({
        ownerId,
        entityType,
        entityId,
    }: HasCoolInputInterface): Promise<CoolEntity | null> {
        return this.unAuthorizedClient
            .query({
                query: gql(coolByOwnerIdEntityId),
                variables: {
                    ownerId,
                    entityTypeEntityId: {
                        eq: {
                            entityType,
                            entityId,
                        },
                    },
                },
            })
            .then(({ data }) => {
                return data.CoolByOwnerIdEntityId.items[0] || null;
            })
            .catch((error) => {
                log(error);
                return null;
            });
    }

    public async getCoolsListByEntityId({
        entityType,
        entityId,
        nextToken,
        limit = null,
    }: GetCoolsListByEntityIdInputInterface): Promise<CoolEntity[]> {
        return this.unAuthorizedClient
            .query({
                query: gql(coolByEntityId),
                variables: {
                    entityType,
                    entityId: {
                        eq: entityId,
                    },
                    limit,
                    nextToken,
                },
            })
            .then(({ data }) => {
                return data.CoolByEntityId.items || [];
            })
            .catch((error) => {
                log(error);
                return null;
            });
    }
}
