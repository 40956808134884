import { Box, Grid, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { includesSkills, includesSkillsCount } from '@weco/common';
import { SkillSetsEntity } from '@weco/core';
import classNames from 'classnames';
import debug from 'debug';
import React, { memo, useState } from 'react';
import useToggle from 'react-use/lib/useToggle';
import { SpriteSvgIcon } from '../molecules';
import { BottomSheet } from './BottomSheet';
import { PositionsListItem } from './PositionsListItem';
import { SkillSetsOverview } from './SkillSetsOverview';

const log = debug('PositionsList');

const useListStyles = makeStyles((theme: Theme) => ({
    root: {},
    editButton: {
        margin: '0 auto',
        display: 'inline-block',
    },
    editIcon: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary[150],
        padding: '10px',
        width: '32px',
        height: '32px',
        '&.MuiIconButton-root:hover': {
            backgroundColor: theme.palette.primary[150],
        },
    },
    editIconSvg: {
        fontSize: '12px',
    },
    positionWidth: {
        maxWidth: 'calc(100% - 40px)',
    },
}));

export interface PositionsListProps {
    className?: string;
    items: SkillSetsEntity[];
    isCheckBoxDisplayed?: boolean;
    isEditButtonDisplayed?: boolean;
    matchSkillSetsWith: string[];
    /**
     * Override default behaviour with custom function.
     * Prevents opening of bottom sheet with position info.
     */
    onCardClick?: (position: SkillSetsEntity) => void;
    onVisibilitySwitch?: (position: SkillSetsEntity) => void;
    onEditButtonClick?: (position: SkillSetsEntity) => void;
    onDeleteBtnClick?: (position: SkillSetsEntity) => void;
}

const PositionsList = memo((props: PositionsListProps) => {
    const classes = useListStyles();
    const rootClasses = classNames(classes.root, props.className);

    const [isBottomSheetOpen, toggleBottomSheet] = useToggle(false);
    const [clickedSkillSet, setClickedSkillSet] = useState(
        null as SkillSetsEntity,
    );

    function openBottomSheetOrRunCallback(position: SkillSetsEntity) {
        if (props.onCardClick) {
            props.onCardClick(position);
        } else {
            setClickedSkillSet(position);
            toggleBottomSheet(true);
        }
    }
    const positions = props.items?.sort((a, b) => a.order - b.order);

    return (
        <Box className={rootClasses}>
            {positions?.map((position) => {
                /*function editPosition(
                    e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
                ) {
                    e.stopPropagation();
                    e.preventDefault();
                    props.onEditButtonClick(position);
                }*/

                const matchedSkillsCount = includesSkillsCount(
                    position,
                    props.matchSkillSetsWith,
                );
                const isPositionMatched = matchedSkillsCount > 0;

                log('isPositionMatched: ', isPositionMatched);
                return (
                    <Box
                        my={4}
                        key={position.id}
                        id="container-anchor-position"
                    >
                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            justify="space-between"
                            alignContent="space-between"
                        >
                            <Grid
                                item
                                xs
                                className={
                                    props.isEditButtonDisplayed &&
                                    classes.positionWidth
                                }
                            >
                                <PositionsListItem
                                    position={position}
                                    isPositionHighlighted={isPositionMatched}
                                    highlightedText={`Match ${matchedSkillsCount}/${
                                        position.skills?.length || 0
                                    }`}
                                    onSwitchClick={props.onVisibilitySwitch}
                                    isEditableMode={props.isCheckBoxDisplayed}
                                    onClick={() =>
                                        openBottomSheetOrRunCallback(position)
                                    }
                                    onDeleteBtnClick={props.onDeleteBtnClick}
                                    onEditButtonClick={props.onEditButtonClick}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}
            <BottomSheet isOpen={isBottomSheetOpen} onClose={toggleBottomSheet}>
                <SkillSetsOverview
                    skillSets={clickedSkillSet}
                    matchSkillSetsWith={props.matchSkillSetsWith}
                />
            </BottomSheet>
        </Box>
    );
});

PositionsList.displayName = 'PositionsList';

export { PositionsList };
