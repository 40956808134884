import { Box, Typography } from '@material-ui/core';
import { ProjectEntity, UserProfileEntity } from '@weco/core';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { memo } from 'react';
import { ProjectSkeletonPlaceholder } from '../molecules/skeletonPlaceholders';
import { AvoidBottomBarInMobileBrowsers } from './AvoidBottomBarInMobileBrowsers';
import { PeopleList } from './PeopleList';
import { ProjectsList } from './ProjectsList';
import { SkeletonAsyncLoader } from './SkeletonAsyncLoader';

export interface ConnectionsPageProps {
    isLoading: boolean;
    className?: string;
    currentUserId: string;
    projectsIAmInvitedTo: ProjectEntity[];
    peopleAppliedToMyProject: UserProfileEntity[];
}

const ListLoadingAnimation = (props: {
    isLoading: boolean;
    children: JSX.Element;
}) => {
    return (
        <SkeletonAsyncLoader
            count={4}
            loading={props.isLoading}
            skeleton={<ProjectSkeletonPlaceholder />}
        >
            {props.children}
        </SkeletonAsyncLoader>
    );
};

const ConnectionsPage = memo((props: ConnectionsPageProps) => {
    const thereAreProjects = !isEmpty(props.projectsIAmInvitedTo);
    const thereArePeople = !isEmpty(props.peopleAppliedToMyProject);

    return (
        <Box className={classNames(props.className)}>
            {(props.isLoading || thereAreProjects) && (
                <Box mt={10} px={4}>
                    <Box mb={1} mx={3}>
                        <Typography variant="h2">Projects</Typography>
                    </Box>
                    <Box mb={8} mx={3}>
                        <Typography variant="subtitle2" color="textSecondary">
                            You are invited to:
                        </Typography>
                    </Box>
                    <ListLoadingAnimation isLoading={props.isLoading}>
                        <ProjectsList
                            items={props.projectsIAmInvitedTo}
                            currentUserId={props.currentUserId}
                        />
                    </ListLoadingAnimation>
                </Box>
            )}
            {(props.isLoading || thereArePeople) && (
                <Box my={10} px={4}>
                    <Box mb={1} mx={3}>
                        <Typography variant="h2">People</Typography>
                    </Box>
                    <Box mb={8} mx={3}>
                        <Typography variant="subtitle2" color="textSecondary">
                            Who applied to your project.
                        </Typography>
                    </Box>
                    <ListLoadingAnimation isLoading={props.isLoading}>
                        <PeopleList
                            currentUserId={props.currentUserId}
                            items={props.peopleAppliedToMyProject}
                        />
                    </ListLoadingAnimation>
                </Box>
            )}
            <AvoidBottomBarInMobileBrowsers />
        </Box>
    );
});

ConnectionsPage.displayName = 'ConnectionsPage';

export { ConnectionsPage };
