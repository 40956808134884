import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { SplashScreenLoader } from '../components/molecules/SplashScreenLoader';

export const useStyles = makeStyles((theme) => ({
    tabContent: {},
}));

export const RedirectPage = () => {
    return (
        <Grid item xs={12}>
            <SplashScreenLoader />
        </Grid>
    );
};
