import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import { Provider as MobxProvider } from 'mobx-react';
import thunk from 'redux-thunk';

import { Router } from 'react-router';
import { App } from './app/app';
import { bootstrapMainModule } from './main.module';
import { SERVICES } from './app/services/services';
import { registerPagesStore } from './app/pages/stores';
import { ProvidersContainer, AppContext, COMMON_SERVICES } from '@weco/common';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider as ReduxProvider } from 'react-redux';
import {
    chatRootReducer,
    CLEAR_STORE,
    deleteUnSubscription,
    getUnSubscriptions,
} from '@weco/chat';
import { APP_EVENTS } from './app_events';
import { AuthEvent } from './app/events/AuthEvent';

bootstrapMainModule().then((ioc: ProvidersContainer) => {
    return registerPagesStore().then(() => {
        const browserHistory = createBrowserHistory();
        const rootStore = ioc.get(SERVICES.RootStore);
        const coreEventDispatcher = ioc.get(
            COMMON_SERVICES.CoreEventDispatcher,
        );
        const history = syncHistoryWithStore(browserHistory, rootStore.routing);

        const store = configureStore({
            reducer: {
                chat: chatRootReducer,
            },
            middleware: [
                ...getDefaultMiddleware({ immutableCheck: false }),
                thunk,
            ],
        });
        // TODO replace it after chat will use mobx store
        coreEventDispatcher.addListener(
            APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
            (event: AuthEvent) => {
                if (!event.subject.isAuthenticated()) {
                    store.dispatch({ type: CLEAR_STORE });
                    const unSubscriptions = getUnSubscriptions();
                    if (Object.keys(unSubscriptions).length > 0) {
                        Object.keys(unSubscriptions).forEach((key) => {
                            unSubscriptions[key]();
                            deleteUnSubscription(key);
                        });
                    }
                }
            },
            true,
        );

        ReactDOM.render(
            <ReduxProvider store={store}>
                <MobxProvider rootStore={rootStore} {...rootStore} ioc={ioc}>
                    <Router history={history}>
                        <AppContext.Provider value={{ ioc, rootStore }}>
                            <App ioc={ioc} rootStore={rootStore} />
                        </AppContext.Provider>
                    </Router>
                </MobxProvider>
            </ReduxProvider>,
            document.getElementById('root'),
        );
    });
});
