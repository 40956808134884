import {
    Injectable,
    Inject,
    getPictureUrl,
    COMMON_SERVICES,
    NoticeServiceInterface,
    CoreEventDispatcher,
    difference,
} from '@weco/common';

import { observable, action, runInAction } from 'mobx';
import {
    ProjectsService,
    PersonService,
    ProjectEntity,
    ProjectConstants,
    Picture,
    ProfileItemInterface,
    defaultPicture,
} from '@weco/core';
import { MyUserStore } from '../../../store/MyUserStore';
import debug from 'debug';
import { filled } from '../../../../../../../libs/chat/src/lib/utils/checkIfFilled';
import { APP_EVENTS } from '../../../../app_events';
import { ProfileUpdatedEvent } from '../../../events/ProfileEvents';

const log = debug('MyProjectStore');

@Injectable()
export class MyProjectStore {
    @Inject(ProjectsService)
    service: ProjectsService;

    @Inject(PersonService)
    private personService: PersonService;

    @Inject(MyUserStore)
    private myUserStore: MyUserStore;
    @Inject(COMMON_SERVICES.NoticeStore)
    private notice: NoticeServiceInterface;

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    private eventDispatcher: CoreEventDispatcher;

    @observable item: ProjectEntity;
    @observable team: ProfileItemInterface[];
    @observable constants: ProjectConstants;
    @observable computedProjectPicture: string;

    getProjectPicture = (picture: Picture) => {
        if (!this.computedProjectPicture) {
            this.computedProjectPicture = getPictureUrl(
                picture,
                defaultPicture,
                true,
            );
        }
        return this.computedProjectPicture;
    };

    @action.bound
    async loadItem(id: string, notFoundRedirect?: () => void): Promise<any> {
        const result = await this.service.loadItem(id);
        runInAction(() => {
            if (!result && notFoundRedirect) {
                notFoundRedirect();
            }
            this.computedProjectPicture = null;
            this.item = result;
        });
    }

    @action.bound
    async loadProjectConstants(): Promise<any> {
        this.constants = await this.service.getConstants();
        return Promise.resolve();
    }

    @action.bound
    async changeProjectName(name: string) {
        try {
            await this.updateItem({
                ...this.item,
                ...{ name },
            } as ProjectEntity);
            // --- because we need to update myProjects state for observable to work
            const updatedProjects = this.myUserStore.myOwnProjects.map(
                (item) => {
                    if (item.id === this.item.id) {
                        item.name = name;
                    }
                    return item;
                },
            );
            this.myUserStore.myOwnProjects = [...updatedProjects];
            // ---
        } catch (e) {
            this.notice.error(e.message || e);
        }
    }

    @action.bound
    async updateItem(item: ProjectEntity) {
        log('updateItem() called. %O', item);

        if (item.passions !== this.item.passions) {
            item.passions = item.passions.map((passion) =>
                passion?.toLowerCase(),
            );
        }

        let result;
        try {
            result = await this.service.updateItem(item);
            const updatedEvent = new ProfileUpdatedEvent(
                difference(result, this.item, {
                    maxDeep: 2,
                    ignoreFields: [
                        'createdAt',
                        'updatedAt',
                        'highlight',
                        'owner',
                        'searchMatchScore',
                    ],
                }),
                result,
            );
            if (this.item.isActivated !== result.isActivated) {
                this.eventDispatcher.dispatch(
                    result.isActivated
                        ? APP_EVENTS.USER_PROFILE_ACTIVATED
                        : APP_EVENTS.USER_PROFILE_DEACTIVATED,
                    updatedEvent,
                );
            }
            this.eventDispatcher.dispatch(
                APP_EVENTS.PROJECT_PROFILE_UPDATED,
                updatedEvent,
            );
            runInAction(() => {
                this.item = result;
                this.myUserStore.loadProfile();
            });
        } catch (e) {
            log('error occured.', e);
            this.notice.error(e.message || e);
        }
    }

    @action.bound
    async updateImg(item: ProjectEntity): Promise<void> {
        let result;
        try {
            result = await this.service.updateItem(item);
            const updatedEvent = new ProfileUpdatedEvent(
                difference(result, this.item, {
                    maxDeep: 2,
                    ignoreFields: [
                        'createdAt',
                        'updatedAt',
                        'highlight',
                        'owner',
                        'searchMatchScore',
                    ],
                }),
                result,
            );
            if (this.item.isActivated !== result.isActivated) {
                this.eventDispatcher.dispatch(
                    result.isActivated
                        ? APP_EVENTS.USER_PROFILE_ACTIVATED
                        : APP_EVENTS.USER_PROFILE_DEACTIVATED,
                    updatedEvent,
                );
            }
            this.eventDispatcher.dispatch(
                APP_EVENTS.PROJECT_PROFILE_UPDATED,
                updatedEvent,
            );
        } catch (e) {
            this.notice.error(e.message || e);
        }
        runInAction(() => {
            this.computedProjectPicture = getPictureUrl(
                result.picture,
                defaultPicture,
            );
            this.myUserStore.updateProjectPicture(item);
            this.item = result;
        });
    }

    @action.bound
    async loadTeam(id: string): Promise<ProfileItemInterface[]> {
        try {
            const result = await this.personService.getProjectTeam(id);
            runInAction(() => {
                this.team = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
