import { Box, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import debug from 'debug';
import { includes } from 'lodash';
import React, { memo } from 'react';

const log = debug('GridList');

const useStyles = makeStyles((theme: Theme) => ({
    container: {},
    itemContainer: {
        fontSize: 14,
        width: '100px',
        height: '100px',
        borderRadius: '10px',
        textAlign: 'center',
        transition: 'color 0.5s, background-color 0.5s',
        // Center the text.
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemSelected: {
        fontSize: 13,
        color: 'white',
        fontWeight: 500,
        backgroundColor: theme.palette.text.secondary,
    },
    itemNotSelected: {
        color: theme.palette.text.secondary,
        backgroundColor: '#F2F2F2',
    },
}));

interface Props {
    items: string[];
    onClick: Function;
    selectedItems: string[];
}

const gridList = (props: Props) => {
    const cx = useStyles();
    return (
        <Grid
            container
            spacing={1}
            direction="row"
            justify="space-around"
            className={cx.container}
        >
            {(props.items || []).map((i) => {
                const isSelected = includes(props.selectedItems, i);
                return (
                    <Grid item key={i} xs={4} sm={2}>
                        <Box
                            onClick={() => props.onClick(i)}
                            className={classnames({
                                [cx.itemContainer]: true,
                                [cx.itemSelected]: isSelected,
                                [cx.itemNotSelected]: !isSelected,
                            })}
                        >
                            {i}
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const GridList = memo(gridList);
