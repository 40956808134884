import * as actionTypes from './actionTypes';
import { AuthUserItemInterface } from '../../models/interfaces/AuthUserInterface';
import { ActionUidInterface } from '../../models/interfaces/ActionUidInterface';
import defaultState from './defaultState';

const authUser = (
    state: AuthUserItemInterface = defaultState,
    action: ActionUidInterface,
) => {
    switch (action.type) {
        case actionTypes.RECEIVE_USER_CREDENTIALS:
            return { loading: false, uid: action.uid, error: action.error };
        default:
            return state;
    }
};

export default authUser;
