import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const useRightSideBarItemStyles = makeStyles((theme: Theme) => ({
    infoBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        color: '#BDBDBD',
        padding: 20,
        fontSize: '18px',
        borderBottom: '2px solid #BDBDBD',
        marginTop: 24,
    },
    infoText: {
        color: '#BDBDBD',
        fontSize: '14px',
    },
    infoIcon: {
        color: '#BDBDBD',
        fontSize: '32px',
        fontWeight: 600,
        padding: 5,
    },
}));
