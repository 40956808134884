import { useControlPanelStyles } from '../../../components/molecules/ProjectProfilePage/styles/ControlPanel.styles';
import { Field, Formik } from 'formik';
import { Box, Typography, Theme } from '@material-ui/core';
import { WeEditable, WeTextField, ShareButton, SpriteSvgIcon } from '@weco/ui';
import React from 'react';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import EditableAvatarContainer from '../../../components/molecules/EditableAvatar/containers/EditableAvatarContainer';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import IconButton from '@material-ui/core/IconButton';
import { RouterPaths } from '../../../router/RouterPaths';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import ProfileHeaderBackground from '../../../../assets/images/profile-project/ProfileHeader.png';
import { useToggle } from 'react-use';
import { useCoolStyles } from '../../../components/molecules/ProjectProfilePage/styles/CoolStyles';
import { UsersCoolDrawer } from '../../../components/molecules/UsersCoolDrawer';

const useStyles = makeStyles((theme: Theme) => ({
    profileHeader: {
        background: `url(${ProfileHeaderBackground}) no-repeat center top / cover`,
    },
}));

export const MyProfileHeader = () => {
    const classes = useStyles();
    const controlPanelClasses = useControlPanelStyles();
    const coolClasses = useCoolStyles();
    const {
        profile: { id, picture, name, lastName, dream, settings, isActivated },
        changeProfileName,
    } = useMyUserProfileData();
    const { activeProject } = useMyUserData();
    const [coolPanelOpen, setCoolPanelOpen] = useToggle(false);

    const history = useHistory();

    const save = (data, { setSubmitting }) => {
        return changeProfileName(data.name, data.lastName).then(() =>
            setSubmitting(false),
        );
    };
    const validate = (values) => {
        if (!values.name || values.name === '') {
            return { name: 'Is Required' };
        }
    };

    return (
        <>
            {!isActivated && (
                <Box className={controlPanelClasses.infoBanner}>
                    Your profile
                    <span className={controlPanelClasses.infoBannerText}>
                        {' '}
                        won’t appear on search results{' '}
                    </span>
                    until you upload a profile picture and complete ‘About Me’
                    field.
                </Box>
            )}
            <Formik
                initialValues={{ name, lastName }}
                validate={validate}
                onSubmit={save}
            >
                {({ handleSubmit, values, errors }) => (
                    <Box
                        className={classNames(
                            controlPanelClasses.root,
                            classes.profileHeader,
                        )}
                        id="container-anchor-profile-header"
                    >
                        <IconButton
                            onClick={() => history.push(RouterPaths.settings)}
                            className={controlPanelClasses.settings}
                        >
                            <SpriteSvgIcon id="Profile-Settings-Light" />
                        </IconButton>
                        <EditableAvatarContainer picture={picture}>
                            <Box className={controlPanelClasses.bottomBar}>
                                <ShareButton
                                    activeProject={activeProject}
                                    type="person"
                                    iconColor="white"
                                    dropdownDirection="down"
                                    shareId={id}
                                    picture={picture}
                                    own
                                />
                                <Typography
                                    variant="h2"
                                    component="div"
                                    className={controlPanelClasses.nameEditable}
                                >
                                    <WeEditable
                                        hasError={!!errors.name}
                                        onSave={handleSubmit}
                                        classes={{
                                            root:
                                                controlPanelClasses.nameEditRoot,
                                        }}
                                        editableComponent={
                                            <Box
                                                className={
                                                    controlPanelClasses.editFieldsBox
                                                }
                                            >
                                                <Field
                                                    name="name"
                                                    component={WeTextField}
                                                />
                                                <Field
                                                    name="lastName"
                                                    component={WeTextField}
                                                />
                                            </Box>
                                        }
                                    >
                                        <Typography
                                            title={`${values.name} ${values.lastName}`}
                                            variant="h2"
                                            className={
                                                controlPanelClasses.itemText
                                            }
                                        >
                                            {values.name} {values.lastName}
                                        </Typography>
                                    </WeEditable>
                                </Typography>
                            </Box>
                        </EditableAvatarContainer>
                        <div
                            className={coolClasses.cools}
                            onClick={setCoolPanelOpen}
                        >
                            <SpriteSvgIcon id="cool-sign" />
                            <Box
                                className={classNames({
                                    [coolClasses.coolsSpan]: true,
                                    [coolClasses.coolSpanTagged]: true,
                                })}
                            >
                                {settings?.totalCounts || 0}
                            </Box>
                        </div>
                    </Box>
                )}
            </Formik>
            {coolPanelOpen && (
                <UsersCoolDrawer
                    id={id}
                    type="Person"
                    setCoolPanelOpen={setCoolPanelOpen}
                    coolPanelOpen={coolPanelOpen}
                />
            )}
        </>
    );
};
