import { useInjection } from '../ioc/hooks';
import { COMMON_SERVICES } from '../common_services';
import { EventDispatcher } from './EventDispatcher';
import { Subscription } from 'rxjs';

interface CoreEventDispatcherHook {
    dispatcher: EventDispatcher;

    dispatch(name: string, event: any);

    addListener(eventName: string, listener: (...args) => void): Subscription;
}

export const useEventDispatcher = (
    service = COMMON_SERVICES.CoreEventDispatcher,
) => {
    const coreDispatcher = useInjection<EventDispatcher>(service);
    return <CoreEventDispatcherHook>{
        dispatcher: coreDispatcher,
        dispatch: (name: string, event: any) =>
            coreDispatcher.dispatch(name, event),
        addListener: (eventName: string, listener: (...args) => void) =>
            coreDispatcher.addListener(eventName, listener),
    };
};
