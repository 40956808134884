import { Inject, Injectable } from '@weco/common';
import {
    CurrentUserProvider,
    IProfileRepository,
    ProfileItemInterface,
    TeamMember,
    SkillSetRepositoryInterface,
    IProjectRepository,
    SchoolsRepositoryInterface,
} from '../repositories';
import { PersonProjectConnection, UserProfileEntity } from '../entity';
import { CORE_SERVICES } from '../core_services';
import { coreConstants } from '../constants/coreConstants';
import { PersonConstants } from '../types';
import { MatchPercentServiceInterface } from './MatchPercentService';
import { ConnectionParamsInterface } from '../../../../../apps/client/src/app/store/hooks/useMyHappyConnectionData';

@Injectable()
export class PersonService {
    @Inject(CORE_SERVICES.PersonRepository)
    repository: IProfileRepository;

    @Inject(CORE_SERVICES.IProjectRepository)
    projectRepository: IProjectRepository;

    @Inject(CORE_SERVICES.MatchPercentServiceInterface)
    percentRepository: MatchPercentServiceInterface;

    @Inject(CORE_SERVICES.ICurrentUserProvider)
    currentUser: CurrentUserProvider;

    @Inject(CORE_SERVICES.SkillSetRepositoryInterface)
    skillSetsRepository: SkillSetRepositoryInterface;

    // @Inject(CORE_SERVICES.SchoolsRepositoryInterface)
    // schoolsRepository: SchoolsRepositoryInterface;

    findById(id: string): Promise<UserProfileEntity> {
        return this.repository.findById(id);
    }

    private async injectSkillSets(users: UserProfileEntity[]) {
        const arr: UserProfileEntity[] = await Promise.all(
            users.map(async (item) => {
                const skillSets = await this.skillSetsRepository.loadSkillSets({
                    ownerId: item.id,
                });
                item.skillSets = skillSets;
                return item;
            }),
        );

        return Promise.resolve(arr);
    }

    public async getProjectTeam(projectId): Promise<ProfileItemInterface[]> {
        let profiles = await this.projectRepository.loadProjectTeam(projectId);
        profiles = (await this.injectSkillSets(profiles)) as TeamMember[];
        return this.injectMatchPercent(profiles);
    }

    public async loadProjectPersons(
        projectId,
    ): Promise<ProfileItemInterface[]> {
        let profiles = await this.projectRepository.loadProjectPersons(
            projectId,
        );
        // profiles = (await this.injectSkillSets(profiles)) as TeamMember[];
        return this.injectMatchPercent(profiles);
    }

    private async injectMatchPercent(profiles: UserProfileEntity[]) {
        const arr: ProfileItemInterface[] = await Promise.all(
            profiles.map(async (item: TeamMember) => {
                const matchPercent = await this.percentRepository.matchProfilePercent(
                    item,
                );
                return Object.assign(new UserProfileEntity(), {
                    ...item,
                    matchPercent,
                }) as ProfileItemInterface;
            }),
        );

        return Promise.resolve(arr);
    }

    /**
     * @description Get Person info with additional details such as search settings and so on
     * @param id
     */
    findByDetailedProfileId(id): Promise<UserProfileEntity> {
        return this.repository.findById(id, { detailed: true });
    }

    public updateProfile(
        profile: UserProfileEntity,
    ): Promise<UserProfileEntity> {
        return this.repository.updateProfile(profile);
    }

    public async getConstants(): Promise<PersonConstants> {
        const { profile, project, ...rest } = coreConstants;
        // const schools = await this.schoolsRepository.getMatchList();
        return { ...rest, ...project };
    }

    public async getMatchPercent(profileId): Promise<number> {
        return this.percentRepository.matchProfilePercent(profileId);
    }

    public searchByName(q: string, exclude: string[], limit = 5): Promise<any> {
        return this.repository
            .searchByName(q, exclude, limit)
            .then((people: any) => {
                return people.map((user) => {
                    const email = user.contacts.find(
                        (contact: string) => contact.indexOf('email:') === 0,
                    );
                    return {
                        id: user.id,
                        name: user.name,
                        lastName: user.lastName,
                        email: email ? email.replace('email:', '') : '',
                    };
                });
            });
    }

    public getPersonConnections(
        personId: string,
        projectId?: string,
    ): Promise<PersonProjectConnection[]> {
        return this.repository.getPersonConnections(personId, projectId);
    }

    public async deleteAccount(id: string): Promise<any> {
        return this.repository.deleteAccount(id);
    }

    public findPersonToProjectConnection(
        params: ConnectionParamsInterface,
    ): Promise<PersonProjectConnection> {
        return this.repository.findPersonToProjectConnection(params);
    }
}
