import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Auth } from '@weco/common';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNotice } from '@weco/ui';
import { UserTokenInterface } from '../../../../../../libs/common/src/lib/auth';

const useStyles = makeStyles((theme) => ({
    successText: {
        margin: '20px 0',
        textAlign: 'center',
        color: theme.palette.common.white,
        position: 'relative',
        fontSize: 18,
    },
    resendText: {
        textAlign: 'center',
    },
    resendTextLink: {
        color: theme.palette.common.white,
        cursor: 'pointer',
        position: 'relative',
        fontSize: 15,
    },
}));

interface SentVerifyEmailPropsInterface {
    resendSignUp: (props: UserTokenInterface) => Promise<any>;
    username: string;
}

export const SentVerifyEmail = ({
    resendSignUp,
    username,
}: SentVerifyEmailPropsInterface) => {
    const classes = useStyles();
    const { error, info } = useNotice();

    return (
        <>
            <Typography
                variant="body2"
                align="center"
                className={classes.successText}
            >
                A verification email
                <br />
                has been sent.
            </Typography>
            <Typography variant="h6" className={classes.resendText}>
                <Link
                    onClick={() => {
                        resendSignUp(
                            new Auth.AmplifyResendSignUpToken(username),
                        )
                            .then(() => {
                                info('Email sent successfully');
                            })
                            .catch((e) => {
                                error(e?.message || e);
                            });
                    }}
                    variant="body2"
                    className={classes.resendTextLink}
                >
                    Resend Verification Email
                </Link>
            </Typography>
        </>
    );
};
