import firebase from 'firebase/app';
import {
    receiveRoomMessages,
    sendRoomMessages,
    errorSentRoomMessages,
} from './actionCreators';
import { updateRoomLastMessage } from '../rooms/actions';
import MessageInterface from '../../models/interfaces/MessageInterface';
import { PAGINATION_SIZE, TMP_ROOM_ID } from '../../utils/constants';
import { refToRefData, timestampToString } from '../../models/ReferenceData';
import { messageFromDocument } from '../common/actions';
import { dispatch } from 'rxjs/internal/observable/pairs';
import _ from 'lodash';

const messageFromNewDocument = (doc: any): MessageInterface => ({
    ...doc,
    id: doc.id,
    owner: refToRefData(doc.owner),
    createdAt: timestampToString(doc.createdAt),
    createdAtTime: doc.createdAt.toDate().getTime(),
});

export const fetchMessages = (
    firestore: firebase.firestore.Firestore,
    roomId: string,
    lastTime: number,
) => (dispatch: Function) => {
    firestore
        .collection(`roomMessages/${roomId}/messages`)
        .orderBy('createdAt', 'desc')
        .limit(PAGINATION_SIZE)
        .startAfter(firebase.firestore.Timestamp.fromDate(new Date(lastTime)))
        .get()
        .then((docs: firebase.firestore.QuerySnapshot) => {
            const messages: MessageInterface[] = [];
            docs.forEach(
                (
                    messageSnapshot: firebase.firestore.QueryDocumentSnapshot<
                        MessageInterface
                    >,
                ) => {
                    messages.push(messageFromDocument(messageSnapshot));
                },
            );
            dispatch(receiveRoomMessages(roomId, messages.reverse()));
        });
};

export const sendMessage = (
    firestore: firebase.firestore.Firestore,
    roomId: string,
    userId: string,
    message: string,
) => (dispatch: Function) => {
    const doc: any = {
        createdAt: firebase.firestore.Timestamp.now(),
        message,
        owner: firestore.collection('users').doc(userId),
    };
    dispatch(
        sendRoomMessages(
            roomId,
            _.omit(messageFromNewDocument(doc), [
                'unsubscribe',
            ]) as MessageInterface,
        ),
    );
    if (roomId !== TMP_ROOM_ID) {
        sendMessageToFirestore(dispatch, firestore, roomId, doc);
    }
};

export const sendMessageToFirestore = (
    dispatch: Function,
    firestore: firebase.firestore.Firestore,
    roomId: string,
    doc: any,
    retries = 0,
) => {
    firestore
        .collection(`roomMessages/${roomId}/messages`)
        .add(doc)
        .then(() => {
            dispatch(
                updateRoomLastMessage(roomId, messageFromNewDocument(doc)),
            );
        })
        .catch((err: Error) => {
            if (retries >= 5) {
                dispatch(
                    errorSentRoomMessages(
                        roomId,
                        messageFromNewDocument(doc),
                        err,
                    ),
                );
                return;
            }
            setTimeout(() => {
                sendMessageToFirestore(
                    dispatch,
                    firestore,
                    roomId,
                    doc,
                    ++retries,
                );
            }, 3000);
        });
};
