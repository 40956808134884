import React from 'react';
import MuiRadioGroup, {
    RadioGroupProps as MuiRadioGroupProps,
} from '@material-ui/core/RadioGroup';
import { FieldProps } from 'formik';

export interface WeRadioGroupProps
    extends FieldProps,
        Omit<MuiRadioGroupProps, 'name' | 'value'> {}

export const fieldToRadioGroup = ({
    field,
    ...props
}: WeRadioGroupProps): MuiRadioGroupProps => {
    return {
        ...props,
        ...field,
    };
};

export const WeRadioGroup = (props: WeRadioGroupProps) => {
    return <MuiRadioGroup {...fieldToRadioGroup(props)} />;
};
