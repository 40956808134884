import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    TextField,
    Theme,
    useMediaQuery,
} from '@material-ui/core';
import debounce from 'lodash/debounce';
import get from 'lodash/fp/get';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReferenceDataInterface } from '../models/interfaces/ReferenceDataInterface';
import { SearchUserInterface } from '../models/interfaces/UsersInterface';

export interface SearchUserDialogPropsInterface {
    loading?: boolean;
    searchUsersDialogOpen: boolean;
    handleCloseSearchUserDialog: () => void;
    searchUsers: Function;
    findUserByName: Function;
    excludedUsers: ReferenceDataInterface[];
    searchedUsers: SearchUserInterface[];
    handleUserClick: (userId: string) => void;
}

const SearchUserDialog: FC<SearchUserDialogPropsInterface> = ({
    loading,
    searchUsersDialogOpen,
    handleCloseSearchUserDialog,
    searchUsers,
    findUserByName,
    excludedUsers,
    searchedUsers,
    handleUserClick,
}: SearchUserDialogPropsInterface) => {
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );
    const currentUserId:string = useSelector(get('chat.authUser.uid'));
    // @ts-ignore
    const debouncedUsearsSearch = debounce(searchUsers, 500);

    excludedUsers.push({
        id: currentUserId,
    });

    if (loading) return null;
    return (
        <Dialog
            fullScreen={isMobileScreen}
            aria-labelledby="form-dialog-title"
            open={searchUsersDialogOpen || false}
            onClose={handleCloseSearchUserDialog}
        >
            <DialogContent>
                <>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="+ Add user name"
                        type="Username"
                        fullWidth
                        onChange={(event) => {
                            debouncedUsearsSearch(
                                findUserByName,
                                event.target.value,
                                excludedUsers.map(get('id')),
                            );
                        }}
                    />
                    <List>
                        {searchedUsers
                            .filter((user) => {
                                return !excludedUsers
                                    .map(get('id'))
                                    .includes(user.id);
                            })
                            .map((user) => {
                                return (
                                    <ListItem
                                        key={user.id}
                                        alignItems="flex-start"
                                        onClick={() => handleUserClick(user.id)}
                                    >
                                        <ListItemText
                                            secondary={user.email}
                                            primary={`${user.name} ${user.lastName}`}
                                        />
                                    </ListItem>
                                );
                            })}
                    </List>
                </>
            </DialogContent>
            <DialogActions>
                <Button
                    fullWidth
                    color="primary"
                    disabled={loading}
                    onClick={handleCloseSearchUserDialog}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SearchUserDialog;
