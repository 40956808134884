import React from 'react';
import {
    Button,
    createStyles,
    TextareaAutosize,
    Theme,
} from '@material-ui/core';
import classNames from 'classnames';
import { CreateMessagePropsInterface } from '../models/interfaces/CreateMessageInterface';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '10px 20px',
            display: 'flex',
        },
        input: {
            height: '44px !important',
            borderRadius: 19,
            lineHeight: '20px',
            fontSize: 20,
            padding: 12,
            color: theme.palette.text.primary,
            display: 'block',
            width: '100%',
            resize: 'none',
            outline: 'none',
            '&::placeholder': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontSize: '16px',
            },
        },
        submit: {
            background: theme.palette.primary.main,
            borderRadius: '50%',
            width: '35px',
            height: '35px',
            minWidth: '35px',
            padding: '5px',
            cursor: 'pointer',
            marginTop: '4px',
            marginLeft: '20px',
            color: '#fff !important',
            lineHeight: '40px',
        },
        disabled: {
            background: '#e0e0e0',
        },
    }),
);

const CreateMessage = ({
    firestore,
    currentUser,
    activeRoom,
    send,
}: CreateMessagePropsInterface) => {
    const classes = useStyles();
    const [message, setMessage] = React.useState('');
    const submit = () => {
        if (message.trim().length > 0) {
            send(firestore, activeRoom, currentUser.id, message);
            setMessage('');
        }
    };

    if (!activeRoom) {
        return null;
    } else
        return (
            <form onSubmit={submit} className={classes.container}>
                <TextareaAutosize
                    rowsMax={4}
                    value={message}
                    placeholder="Type a message here"
                    onChange={(event: any) => {
                        setMessage(event.target.value);
                    }}
                    onKeyDown={(event: any) => {
                        if (!event.shiftKey && event.keyCode === 13) {
                            event.preventDefault();
                            submit();
                        }
                    }}
                    className={classes.input}
                />
                <div
                    onClick={submit}
                    className={classNames({
                        [classes.submit]: true,
                        [classes.disabled]: message.length === 0,
                    })}
                >
                    <NavigateNextIcon />
                </div>
            </form>
        );
};

export default CreateMessage;
