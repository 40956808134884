import React, { Context } from 'react';
import { Type } from '../types/interfaces';

export interface StoreContainerContextInterface {
    stores: Map<Type, any>;
}

export const StoreContainerContext: Context<StoreContainerContextInterface> = React.createContext<
    StoreContainerContextInterface
>(undefined as any);
