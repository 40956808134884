import { Box, Button, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AdvancedSearchFilter, CoreConstantsInterface } from '@weco/core';
import { UnauthorizedRedirectOnclick } from '@weco/ui';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import useToggle from 'react-use/lib/useToggle';
import CheckCircleIcon from '../assets/icons/check-circle.svg';
import { AvoidBottomBarInMobileBrowsers } from './AvoidBottomBarInMobileBrowsers';
import { EnterNameDialog } from './EnterNameDialog';
import { RolesSearchField } from './RolesSearchField';
import { SearchField } from './SearchField';
import { WE_CLICKS } from '../../../../../apps/client/src/we_clicks';
import { RecentSearchLocalStorageKeys } from '@weco/common';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '0 15px',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 'auto',
        backgroundColor: theme.palette.primary.contrastText,
    },
    buttonBox: {
        padding: '16px 0',
        textAlign: 'center',
    },
}));

export interface AdvancedSearchProps {
    isUpdated: boolean;
    onSubmit: () => void;
    isProjectsTab?: boolean;
    filter: AdvancedSearchFilter;
    isSavedSearchUpdateMode: boolean;
    constants: CoreConstantsInterface;
    onSave: (searchName: string) => void;
    setUpdated: (param: boolean) => void;
    onUpdate: (searchName: string) => void;
    filterChange: (
        filter: AdvancedSearchFilter,
        isProjectTab?: boolean,
    ) => void;
    onSavedSearchClick: (search: AdvancedSearchFilter) => {};
}

const AdvancedSearch = ({
    filter,
    onSave,
    isUpdated,
    isSavedSearchUpdateMode,
    isProjectsTab,
    onSubmit,
    ...props
}: AdvancedSearchProps) => {
    const classes = useStyles();
    const [isDialogOpen, toggleDialog] = useToggle(false);

    const isFilterEmpty = isEmpty(Object.entries(filter));

    function handleItemChange(key: string, value: string[]): void {
        const newValues = filter;
        newValues[key] = value;
        props.filterChange({ ...newValues }, isProjectsTab);
        props.setUpdated(false);
    }

    useEffect(() => {
        const bufferAdvancedFilter = JSON.parse(
            localStorage.getItem(
                isProjectsTab
                    ? RecentSearchLocalStorageKeys.bufferAdvancedFilterProject
                    : RecentSearchLocalStorageKeys.bufferAdvancedFilterPeople,
            ),
        );
        if (bufferAdvancedFilter) {
            props.filterChange(bufferAdvancedFilter);
        } else {
            props.filterChange(new AdvancedSearchFilter());
        }
    }, []);

    return (
        <Box>
            <EnterNameDialog
                onSubmit={onSave}
                isOpen={isDialogOpen}
                toggleIsOpen={toggleDialog}
            />
            <Box className={classes.root}>
                <Grid container>
                    {[
                        'causes',
                        'passions',
                        'objectives',
                        'languages',
                        'compensations',
                    ].map((field) => {
                        const title =
                            isProjectsTab && field === 'passions'
                                ? 'tags'
                                : field;
                        // "Compensations" used to be called "investements".
                        const options =
                            field === 'compensations'
                                ? props.constants.investments
                                : props.constants[field];

                        return (
                            <SearchField
                                field={field}
                                key={field}
                                title={title}
                                options={options}
                                selected={filter[field]}
                                onChange={handleItemChange}
                                isOpenField={field === 'passions'}
                            />
                        );
                    })}
                    <RolesSearchField
                        onChange={handleItemChange}
                        selected={filter?.roles}
                        typeOfPositionsToUse={
                            isProjectsTab ? 'project' : 'person'
                        }
                    />
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        className={classes.buttonBox}
                    >
                        <Grid item xs={6}>
                            <UnauthorizedRedirectOnclick>
                                <Button
                                    variant="text"
                                    color="default"
                                    onClick={() => {
                                        props.setUpdated(true);
                                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                        isSavedSearchUpdateMode
                                            ? props.onUpdate(filter.name)
                                            : toggleDialog();
                                    }}
                                    disabled={isFilterEmpty || isUpdated}
                                >
                                    {isSavedSearchUpdateMode
                                        ? 'Update'
                                        : 'Save'}
                                </Button>
                            </UnauthorizedRedirectOnclick>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                color="primary"
                                onClick={onSubmit}
                                id={WE_CLICKS.SAVED_ADVANCE_SEARCH}
                                startIcon={<img src={CheckCircleIcon} alt="" />}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <AvoidBottomBarInMobileBrowsers />
            </Box>
        </Box>
    );
};

export { AdvancedSearch };
