import { Injectable, Inject } from '@weco/common';
import { observable, action } from 'mobx';
import { UserProfileEntity, PeopleService, SearchFilter } from '@weco/core';
import { MainPageFilter } from '../../../../helpers/CommonTypes';

@Injectable()
export class PeopleMainPageStore {
    private readonly MAIN_PAGE_ITEMS_COUNT: number = 6;

    @Inject(PeopleService)
    service: PeopleService;

    @observable items: UserProfileEntity[] = [];

    filters: MainPageFilter[];

    @observable activeFilterValue = '1';

    @action.bound
    changeFilter(newValue: string) {
        this.activeFilterValue = newValue;
    }

    @action
    async getList(): Promise<any> {
        const filter = new SearchFilter();
        const data = await this.service.search({
            filter,
            limit: this.MAIN_PAGE_ITEMS_COUNT,
        });
        this.items = data.people;
    }
}
