import { Grid } from '@material-ui/core';
import { ProjectEntity } from '@weco/core';
import {
    InviteCollaborationActions,
    InviteCollaborationActionsProps,
    NavTabs,
    SpriteSvgIcon,
    UnauthorizedRedirectOnclick,
    RequiredInfoBanner,
} from '@weco/ui';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { PersonRolesContainer } from '../../../store/containers/PersonRolesContainer';
import { useCurrentAuthData } from '../../../store/hooks/useCurrentAuthData';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import { useProjectProfileContainerStyles } from '../../me/styles/MePage.styles';
import { InfoTabPage } from './InfoTabPage';
import { ResourcesTabPage } from './ResourcesTabPage';
import { SkillsTabPage } from './SkillsTabPage';
import { PersonProfileContainer } from './store/PersonProfileContainer';
import {
    PersonProfileData,
    usePersonProfileData,
} from './store/usePersonProfileData';
import { UserProfileHeader } from './UserProfileHeader';
import { RouterPaths } from '../../../router/RouterPaths';
import { ProjectRolesContainer } from '../../../store/containers/ProjectRolesContainer';
import { useProjectRolesData } from '../../../store/hooks/useProjectRolesData';
import filter from 'lodash/filter';
import { CoolWrapper } from '../../../components/molecules/CoolWrapper';

export const ProfilePage = () => {
    const {
        params: { id: userId },
    } = useRouteMatch<{ id: string }>();
    const { activeProject } = useMyUserData();
    const baseUrl = `${ProfilePage.URL}/${userId}`;
    const classes = useProjectProfileContainerStyles();
    const viewer = useMyUserData();
    const { isAuthenticated } = useCurrentAuthData();

    const incompleteProject = () => {
        return !activeProject?.picture || !activeProject?.dream;
    };

    return (
        <ProjectRolesContainer projectId={activeProject?.id}>
            <PersonProfileContainer itemId={userId}>
                <PersonRolesContainer personId={userId}>
                    {({ store }) => (
                        <Grid container>
                            {isAuthenticated &&
                                incompleteProject() &&
                                viewer?.activeProject && (
                                    <RequiredInfoBanner
                                        text="Please complete your active project with a Photo and Project Mission."
                                        link={`${RouterPaths.myProject}/${activeProject?.id}`}
                                        linkText="Complete Project"
                                    />
                                )}
                            <Grid item xs={12}>
                                <UserProfileHeader
                                    isAuthenticated={isAuthenticated}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.userActionButtons}
                            >
                                <UnauthorizedRedirectOnclick>
                                    <InviteCollaborationActionsWrapper
                                        activeProject={viewer?.activeProject}
                                        incompleteProject={incompleteProject()}
                                        isAuthenticated={isAuthenticated}
                                    />
                                </UnauthorizedRedirectOnclick>
                                <UnauthorizedRedirectOnclick>
                                    <CoolWrapper
                                        entityId={userId}
                                        entityType="Person"
                                    />
                                </UnauthorizedRedirectOnclick>
                            </Grid>
                            <Grid
                                className={classes.root}
                                item
                                xs={12}
                                id="tabs-layout"
                            >
                                <NavTabs
                                    tabs={[
                                        {
                                            label: 'Bio',
                                            to: `${baseUrl}`,
                                            component: InfoTabPage,
                                            icon: (
                                                <SpriteSvgIcon id="bio-primary" />
                                            ),
                                        },
                                        {
                                            label: 'Skills',
                                            icon: (
                                                <SpriteSvgIcon id="skill-team-primary" />
                                            ),
                                            to: `${baseUrl}/skills/`,
                                            component: SkillsTabPage,
                                            componentProps: {
                                                baseUrl: `${baseUrl}/skills/`,
                                                personId: userId,
                                            },
                                        },
                                        {
                                            label: 'Resources',
                                            icon: (
                                                <SpriteSvgIcon id="resources-primary" />
                                            ),
                                            to: `${baseUrl}/resources`,
                                            component: ResourcesTabPage,
                                            componentProps: {
                                                baseUrl: `${baseUrl}/resources`,
                                            },
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    )}
                </PersonRolesContainer>
            </PersonProfileContainer>
        </ProjectRolesContainer>
    );
};

const InviteCollaborationActionsWrapper = ({
    activeProject,
    ...props
}: Partial<InviteCollaborationActionsProps>) => {
    const store: PersonProfileData = usePersonProfileData();
    const projectRolesStore = useProjectRolesData(activeProject?.id);
    let projectsActiveRoles = [];
    if (activeProject) {
        projectsActiveRoles = filter(
            projectRolesStore.allAddedRoles,
            'isSelected',
        );
    }

    return (
        <InviteCollaborationActions
            {...props}
            setPersonProjectConnection={store.setPersonProjectConnection}
            personProjectConnection={store.personProjectConnection}
            inviteToActiveProject={store.inviteToActiveProject}
            declineApplication={store.declineApplication}
            acceptApplication={store.acceptApplication}
            removeProjectMember={store.removeProjectMember}
            recallInvitation={store.recallInvitation}
            isApplicant={store.isApplicant()}
            isMember={store.isMember()}
            ableToInvite={store.ableToInvite()}
            invited={store.invited()}
            isLoadingStoreData={store.loading}
            activeProject={activeProject || new ProjectEntity()}
            projectsActiveRoles={projectsActiveRoles}
        />
    );
};

ProfilePage.URL = '/profile/person';
