import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import { SkillEntity, SkillSetsEntity } from '@weco/core';

import { Field, FieldArray, Formik } from 'formik';
import React, { memo } from 'react';
import { WeEditable } from '../molecules/WeEditable';
import { BottomSheetButtons } from './BottomSheetButtons';
import { SearchSkillField } from './SearchSkillField';
import { WeTextField } from '../forms/WeTextField';
import { AnalyticsEvent, useAnalytics } from '@weco/common';
import { APP_EVENTS } from '../../../../../apps/client/src/app_events';
import Divider from '@material-ui/core/Divider';
import { WeMultilineText } from '../molecules';
import { WeChipsInput } from '../forms/WeChipsInput';

const useStyles = makeStyles((theme) => ({
    skillsDrawer: {
        width: '100%',
        // not the best approach, but nothing else worked for mobile safari
        // TODO: we may need to add more cases here
        [theme.breakpoints.down(780)]: {
            maxWidth: '670px',
        },
        [theme.breakpoints.down(380)]: {
            maxWidth: '270px',
        },
    },
    chipsRoot: {
        display: 'flex',
        // this wrap now works in combination with theme.breakpoints in skillsDrawer style
        flexWrap: 'wrap',
        '& > *': {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    chip: {
        color: '#bdbdbd',
        backgroundColor: '#F2F2F2',
        borderRadius: '4px',
    },
    title: {
        width: 'calc(100% - 10px)',
        wordBreak: 'break-word',
        fontSize: '33px',
        fontWeight: 800,
        lineHeight: '37px',
        color: theme.palette.text.primary,
        marginBottom: '22px',
    },
    description: {
        width: 'calc(100% - 50px)',
        wordBreak: 'break-word',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'justify',
        color: theme.palette.text.secondary,
        marginBottom: '45px',
    },
    skillsTitle: {
        fontWeight: 700,
        fontSize: '22px',
        marginBottom: '20px',
    },
    editBox: {
        width: 'calc(100% - 70px)',
    },
}));

const component = ({
    data,
    autocompleteOptions,
    onSave,
    onDeleteBtnClick,
}: {
    data: SkillSetsEntity;
    autocompleteOptions: SkillEntity[];
    onSave(item: SkillSetsEntity, saveAndClose: boolean);
    onDeleteBtnClick();
}) => {
    const cx = useStyles();
    const { event } = useAnalytics();

    const getSubmitHandler = ({ saveAndClose }) => (data) => {
        return onSave(data, saveAndClose);
    };

    //Check is it attach to project or person. Default role has ownerId = projectId = '-1'.
    const canDelete = data.ownerId != data.projectId && data.id;

    return (
        <Formik
            initialValues={data}
            validate={({ name }) => {
                const errors: Partial<{
                    name: string;
                }> = {};
                if (!name.trim()) errors.name = 'Required';
                return errors;
            }}
            onSubmit={getSubmitHandler({ saveAndClose: false })}
        >
            {({ handleSubmit, values, errors, setFieldValue }) => (
                <Box className={cx.skillsDrawer}>
                    <Box my={1} id="container-anchor-role-name">
                        <WeEditable
                            fieldId="_POSITION_SAVED_NAME"
                            hasError={!!errors.name}
                            onSave={handleSubmit}
                            editableComponent={
                                <Box className={cx.editBox}>
                                    <Field
                                        name="name"
                                        component={WeTextField}
                                        multiline
                                    />
                                </Box>
                            }
                        >
                            <Typography component="div" className={cx.title}>
                                <WeMultilineText
                                    text={values.name || 'Name is empty'}
                                />
                            </Typography>
                        </WeEditable>
                    </Box>
                    <Divider />
                    <Box my={1} id="container-anchor-role-description">
                        <WeEditable
                            fieldId="_POSITION_SAVED_DESCRIPTION"
                            hasError={!!errors.description}
                            onSave={handleSubmit}
                            editableComponent={
                                <Box className={cx.editBox}>
                                    <Field
                                        name="description"
                                        component={WeTextField}
                                        multiline
                                    />
                                </Box>
                            }
                        >
                            <Typography
                                component="div"
                                className={cx.description}
                            >
                                <WeMultilineText
                                    text={
                                        values.description || 'No Description'
                                    }
                                />
                            </Typography>
                        </WeEditable>
                    </Box>
                    <Box my={1} id="container-anchor-skills-required">
                        <Typography className={cx.skillsTitle}>
                            Skills Required
                        </Typography>

                        <WeChipsInput
                            lowerCase
                            autoFocus
                            value={values.skills.map((i) => i.value)}
                            freeSolo
                            onChange={(chips) => {
                                const skills = chips.map((i) => {
                                    return new SkillEntity(
                                        i,
                                        data.department.name,
                                    );
                                });
                                setFieldValue('skills', skills);
                            }}
                            suggestions={autocompleteOptions.map(
                                (i) => i.value,
                            )}
                        />
                    </Box>
                    <Box my={1}>
                        <BottomSheetButtons
                            defaultRole={data.isDefault}
                            isLeftButtonDisabled={!canDelete}
                            onLeftButtonClick={onDeleteBtnClick}
                            onRightButtonClick={() =>
                                getSubmitHandler({ saveAndClose: true })(values)
                            }
                        />
                    </Box>
                </Box>
            )}
        </Formik>
    );
};
export const RoleCard = memo(component);
