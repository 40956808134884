import React, { useEffect } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ThemeProvider } from '@material-ui/styles';
import clsx from 'clsx';
import { StepButton } from '@material-ui/core';
// import SteppersActiveAnimation from '../../../assets/images/SteppersActiveAnimation.gif';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../../router/RouterPaths';
import { Check } from '@material-ui/icons';
import { StepperIcon } from './StepperIcon';
import { FirstStepIcon } from './FirstStepIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const StepperTheme = createMuiTheme({
    overrides: {
        MuiStepper: {
            root: {
                backgroundColor: 'transparent',
            },
        },
        MuiStepIcon: {
            root: {
                fontSize: '38px',
                color: '#bdbdbd',
                width: '100px',
                height: '100px',
            },
            active: {
                color: '#ff0000',
            },
            text: {
                fontSize: '16px',
                fontWeight: 700,
            },
        },
        MuiStepLabel: {
            alternativeLabel: {
                color: '#ffffff',
                fontSize: '12px',
                '&$active': {
                    color: '#ffffff',
                    fontWeight: 700,
                },
                '&$completed': {
                    color: '#ffffff',
                    fontWeight: 700,
                },
            },
        },
        MuiStepConnector: {
            alternativeLabel: {
                color: '#ffffff',
                top: '19px',
            },
            lineHorizontal: {
                borderTopStyle: 'dashed',
            },
        },
    },
});

export interface HeaderStepperProps {
    firstStepCompleted: boolean;
    secondStepCompleted: boolean;
}

/**
 * 1. Первый шаг активен всегда;
 * 2. Второй активен только тогда, когда первый completed
 * 3. Третий активен только тогда, когда первый и второй completed
 */
const HeaderStepper = ({
    firstStepCompleted,
    secondStepCompleted,
}: HeaderStepperProps) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        if (firstStepCompleted) {
            setActiveStep(1);
        }
        if (firstStepCompleted && secondStepCompleted) {
            setActiveStep(2);
        }
    }, [firstStepCompleted, secondStepCompleted]);

    const { push } = useHistory();
    const goTo = (path) => {
        return () => push(path);
    };

    return (
        <div className={classes.root}>
            <ThemeProvider theme={StepperTheme}>
                <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                    <Step key={0}>
                        <StepButton
                            onClick={goTo(RouterPaths.myProfile)}
                            completed={firstStepCompleted}
                        >
                            <StepLabel StepIconComponent={FirstStepIcon}>
                                Let’s Finish
                                <br />
                                your Profile.
                            </StepLabel>
                        </StepButton>
                    </Step>
                    <Step key={1}>
                        <StepButton
                            onClick={goTo(RouterPaths.myProfileSkillsProjects)}
                            completed={secondStepCompleted}
                        >
                            <StepLabel StepIconComponent={StepperIcon}>
                                Let’s Create
                                <br />
                                or Apply to a<br />
                                Project.
                            </StepLabel>
                        </StepButton>
                    </Step>
                    <Step key={2}>
                        <StepButton onClick={goTo(RouterPaths.search.root)}>
                            <StepLabel StepIconComponent={StepperIcon}>
                                Start to
                                <br />
                                Connect.
                            </StepLabel>
                        </StepButton>
                    </Step>
                </Stepper>
            </ThemeProvider>
        </div>
    );
};

export default HeaderStepper;
