import React from 'react';
import { PersonRolesStore } from '../PersonRolesStore';
import { StoreContainer } from './StoreContainer';
import { EntityLoadingSkeleton, TabLoadingSkeleton } from '@weco/ui';

export interface PersonRolesContainerProps {
    children: any;
    personId: string;
    searchPage?: boolean;
    testPages?: boolean;
}

export const PersonRolesContainer = ({
    children,
    personId,
    searchPage,
    testPages,
}: PersonRolesContainerProps) => {
    const loader = async (store: PersonRolesStore) => {
        store.personId = personId;
        await Promise.all([store.loadDepartments(), store.loadDefaultsRoles()]);
        return store.loadRoles();
    };

    return (
        <StoreContainer
            children={children}
            classType={PersonRolesStore}
            loader={loader}
            loaderComponent={
                searchPage ? <TabLoadingSkeleton /> : <EntityLoadingSkeleton />
            }
            testPages={testPages}
        />
    );
};
