import { observable, action, runInAction } from 'mobx';
import { Inject, Injectable } from '@weco/common';
import data from './data';
import {
    ResultEntity,
    TestAnswerEntity,
    TestResultEntity,
    TestService,
} from '@weco/core';
import { SERVICES } from '../../../services/services';
import { AppStore } from '../../../store/AppStore';
import { getResultsByAnswers } from './calculationPoints';
import { QuizService } from '@weco/quiz';
import { RootStore } from '../../../store/RootStore';
import { RouterPaths } from '../../../router/RouterPaths';

@Injectable()
export class QuizStore {
    @observable questions: any[];
    @observable activePage: number;
    @observable answers: TestAnswerEntity[][];
    @observable test: TestResultEntity;
    @observable showContinueQuestion: boolean;

    @Inject(SERVICES.AppStore)
    private appStore: AppStore;

    @Inject(SERVICES.RootStore)
    private rootStore: RootStore;

    @Inject(QuizService)
    quizService: QuizService;

    @Inject(TestService)
    testService: TestService;

    @action.bound
    async reset() {
        const checking = await this.testService.loadMyLastTestResults({
            status: 'IN_PROGRESS',
        });

        if (checking) {
            runInAction(() => {
                this.showContinueQuestion = true;
            });
            await this.loadExisting(checking);
        } else {
            const finished = await this.testService.loadMyLastTestResults({
                status: 'FINISHED',
            });
            if (finished) {
                await this.loadExisting(finished);
                runInAction(() => {
                    this.rootStore.routing.push(RouterPaths.quizFinish);
                });
            } else {
                await this.startNew();
            }
        }
    }

    @action.bound
    async startNew() {
        const arr = [];
        data.question_sections.forEach((i) => {
            arr.push(
                i.lines.map((q) => {
                    const answer = new TestAnswerEntity();
                    answer['questionId'] = q.id;
                    answer['question'] = q.question;
                    answer['option'] = 0;
                    return answer;
                }),
            );
        });

        const test = new TestResultEntity();
        test.type = 'DEPARTMENTS_SCORE';
        test.answers = arr;
        test.ownerId = this.appStore.currentUser?.id;
        test.results = [];
        test.status = 'IN_PROGRESS';

        const createdTest = await this.createResult(test);

        runInAction(() => {
            this.questions = data.question_sections;
            this.activePage = 1;
            this.answers = arr;
            this.test = createdTest;
        });
    }

    @action.bound
    handleStartNew() {
        this.deleteResult({ id: this.test.id }).then(() => {
            this.startNew().then(() => {
                runInAction(() => {
                    this.showContinueQuestion = false;
                });
            });
        });
    }

    @action.bound
    async handleTryAgain() {
        await this.startNew();
        this.rootStore.routing.push(RouterPaths.quiz);
    }

    @action.bound
    handleContinue() {
        runInAction(() => {
            this.showContinueQuestion = false;
        });
    }

    @action.bound
    onClose() {
        runInAction(() => {
            this.questions = null;
        });
        this.rootStore.routing.push(RouterPaths.myProfileSkills);
    }

    @action.bound
    loadExisting(test) {
        let page = 1;
        test.answers.forEach((section, i) => {
            section.forEach((answer) => {
                if (answer.option !== 0) {
                    page = i + 1;
                    return;
                }
            });
            return;
        });

        runInAction(() => {
            this.questions = data.question_sections;
            this.activePage = page;
            this.answers = test.answers;
            this.test = test;
        });
    }

    @action.bound
    async calculateResult() {
        const results: ResultEntity[] = getResultsByAnswers(this.answers);
        const testToCreate: TestResultEntity = {
            ...this.test,
            results: results,
            status: 'FINISHED',
        };

        const checking = await this.testService.loadMyLastTestResults({
            status: 'FINISHED',
        });
        if (checking) {
            await this.deleteResult({ id: checking.id });
        }

        await this.updateResult(testToCreate);

        runInAction(() => {
            this.test = testToCreate;
        });
    }

    @action.bound
    async finish() {
        runInAction(() => {
            this.questions = null;
        });
    }

    @action.bound
    async createResult(input: TestResultEntity): Promise<any> {
        return new Promise((resolve, reject) => {
            this.quizService
                .createPassedTest(input)
                .then((res) => {
                    resolve(res || []);
                })
                .catch((err: Error) => {
                    reject(err);
                });
        });
    }

    @action.bound
    async updateResult(input: TestResultEntity): Promise<any> {
        return new Promise((resolve, reject) => {
            this.quizService
                .updatePassedTest(input)
                .then((res) => {
                    resolve(res || []);
                })
                .catch((err: Error) => {
                    reject(err);
                });
        });
    }

    @action.bound
    async deleteResult(input: { id: string }): Promise<any> {
        return new Promise((resolve, reject) => {
            this.quizService
                .deleteTest(input)
                .then((res) => {
                    resolve(res || []);
                })
                .catch((err: Error) => {
                    reject(err);
                });
        });
    }

    @action.bound
    setAnswer(key: number, value: number, section: number): void {
        const answers = this.answers.map((a) => a);
        answers[section][key]['option'] = value;

        const updatedTest = { ...this.test, answers: answers };

        this.updateResult(updatedTest);

        runInAction(() => {
            this.answers = answers;
            this.test = updatedTest;
        });
    }

    @action.bound nextPage(): void {
        runInAction(() => {
            if (this.activePage < this.questions.length) {
                this.activePage++;
            }
        });
    }

    @action.bound prevPage(): void {
        runInAction(() => {
            if (this.activePage > 1) {
                this.activePage--;
            }
        });
    }
}
