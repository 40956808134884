import React from 'react';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { renderChildren } from '../helpers/renderChildren';
import { Box, Button, IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { weEditableStyles } from './WeEditable.styles';
import { SpriteSvgIconButton } from './SpriteSvgIconButton';
import { WE_CLICKS } from '../../../../../apps/client/src/we_clicks';

interface WeEditableClasses {
    root?: string;
    childBox?: string;
    editBox?: string;
    editIcon?: string;
    editIconSvg?: string;
    saveIcon?: string;
}

export type WeEditableProps = {
    isEditable?: boolean;
    hasError?: boolean;
    editableComponent: any;
    children: any;
    onSave?: () => void;
    fieldId?: string;
    isFormValid?: boolean;
    handleSaveWithIcon?: boolean;
    classes?: WeEditableClasses;
};

export const WeEditable = ({
    isEditable = false,
    hasError = false,
    editableComponent,
    children,
    onSave,
    fieldId,
    isFormValid = true,
    handleSaveWithIcon,
    ...props
}: WeEditableProps) => {
    const classes = weEditableStyles();

    const [isEditMode, setEditMode] = useState(isEditable);
    useMemo(() => {
        if (hasError) {
            return setEditMode(true);
        }
    }, [hasError, isEditMode]);

    const handleSave = () => {
        if (isFormValid) {
            onSave();
            setEditMode(!isEditMode);
        }
    };

    const handleOpenEditComponent = (e) => {
        if (isFormValid && !isEditMode) {
            setEditMode(!isEditMode);
        }
    };

    return (
        <Box
            className={classNames(props.classes?.root, classes.root)}
            onClick={(e) => handleOpenEditComponent(e)}
        >
            <Box
                className={classNames(
                    props.classes?.childBox,
                    classes.childBox,
                )}
            >
                {!isEditMode && renderChildren(children, { ...props })}
                {isEditMode && renderChildren(editableComponent, { ...props })}
            </Box>
            <Box
                className={classNames(props.classes?.editBox, classes.editBox)}
            >
                {(!isEditMode || !onSave) && (
                    <SpriteSvgIconButton
                        id="editIcon"
                        classes={{
                            root: classNames(
                                props.classes?.editIcon,
                                classes.editIcon,
                            ),
                            svg: classNames(
                                props.classes?.editIconSvg,
                                classes.editIconSvg,
                            ),
                        }}
                        onClick={() => setEditMode(!isEditMode)}
                    />
                )}
                {isEditMode && onSave && (
                    <>
                        {handleSaveWithIcon ? (
                            <IconButton
                                id={WE_CLICKS.PROFILE_SAVED + fieldId}
                                className={classNames(
                                    props.classes?.saveIcon,
                                    classes.saveIcon,
                                )}
                                aria-label="save"
                                onClick={handleSave}
                            >
                                <SaveIcon />
                            </IconButton>
                        ) : (
                            <Button
                                id={WE_CLICKS.PROFILE_SAVED + fieldId}
                                className={classNames(
                                    props.classes?.saveIcon,
                                    classes.saveButton,
                                )}
                                aria-label="save"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};
