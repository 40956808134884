import classNames from 'classnames';
import React, { memo } from 'react';
import {
    Theme,
    Typography,
} from '@material-ui/core';
// @ts-ignore
import MuiAccordion from '@material-ui/core/ExpansionPanel';
// @ts-ignore
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
// @ts-ignore
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(({ palette }: Theme) => ({
    root: {
        borderRadius: '6px',
        color: palette.text.primary,
        backgroundColor: palette.primary['50'],
        border: '1px solid ' + palette.primary.main,
    },
    label: {},
}));

interface AccordionProps {
    label: string;
    children: JSX.Element;
    rightComponent?: JSX.Element;
    className?: string;
}

const Accordion = memo((props: AccordionProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return (
        <MuiAccordion className={rootClasses}>
            <AccordionSummary
                expandIcon={props.rightComponent || <ExpandMoreIcon />}
                // TODO aria
                id="panel1a-header"
                aria-controls="panel1a-content"
            >
                <Typography variant="subtitle2" className={classes.label}>
                    {props.label}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </MuiAccordion>
    );
});

Accordion.displayName = 'Accordion';

export { Accordion };
