import { Inject, Injectable } from '@weco/common';
import { SchoolsRepository } from '../repositories/SchoolsRepository';
import { CORE_SERVICES } from './../core_services';
import { SchoolEntity } from '../entity';

@Injectable()
export class SchoolsService {
    @Inject(CORE_SERVICES.SchoolsRepositoryInterface)
    private repository: SchoolsRepository;

    public async getList(limit?: number): Promise<SchoolEntity[]> {
        return this.repository.getMatchList(limit);
    }
}
