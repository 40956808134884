import debug from 'debug';
import classNames from 'classnames';
import React, { memo } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'formik';
import { FormGroup } from '@material-ui/core';
import { SectionWithTitle } from './SectionWithTitle';
import { WeChipsSelect } from '../forms/WeChipsSelect';

const log = debug('StaticChipsFormControl');

const useStyles = makeStyles((theme: Theme) => ({ root: {} }));

interface Props {
    name?: string;
    label?: string;
    helpIcon?: {};
    className?: string;
    options: ({ label: string; value: string } | string)[];
}

const StaticChipsFormControl = memo(
    ({ options, name, label, helpIcon, ...props }: Props) => {
        console.log('Staic chips contol');
        const classes = useStyles();
        const rootClasses = classNames(classes.root, props.className);

        // Remove negative values from the array.
        options = options.filter((option) => {
            console.assert(
                Boolean(option),
                `Negative value was saved in "causes" array.\nWe need to find out why.`,
            );
            return !!option;
        });
        log('options: %O', options);

        return (
            <FormGroup className={rootClasses}>
                <SectionWithTitle title={label} helpIcon={helpIcon}>
                    <Field
                        label={label}
                        name={name}
                        component={WeChipsSelect}
                        options={options.map((i) => ({
                            label: (i as any).label || i,
                            value: (i as any).value || i,
                        }))}
                    />
                </SectionWithTitle>
            </FormGroup>
        );
    },
);

StaticChipsFormControl.displayName = 'StaticChipsFormControl';

export { StaticChipsFormControl };
