import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import { OnAnyEventHandler } from '@weco/ui';

interface FundingSvgIconProps {
    style?: any;
    onClick?: OnAnyEventHandler;
    funding?: string;
    item: any;
}

export const FundingSvgIcon = (props: FundingSvgIconProps) => {
    const { item, style = {}, onClick } = props;
    if (!item.value) return null;
    return (
        <IconButton style={style} onClick={() => onClick(item.value)}>
            <SvgIcon>
                <use
                    href={`../assets/icons/fundings/svg/${
                        props.funding || 'sprite'
                    }.svg#${item.type}`}
                />
            </SvgIcon>
        </IconButton>
    );
};
