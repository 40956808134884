import { Box, Button, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FolderIcon from '@material-ui/icons/Folder';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/styles';
import { SearchEntity, AdvancedSearchFilter } from '@weco/core';
import classNames from 'classnames';
import map from 'lodash/map';
import React, { memo } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    root: { width: '100%' },
    closeIcon: {},
    searchElement: {
        width: '100%',
        display: 'flex',
        paddingLeft: '20px',
        paddingRight: '20px',
        color: theme.palette.text.secondary,
    },
}));

interface Props {
    className?: string;
    items: SearchEntity[];
    onDelete: (search: SearchEntity) => void;
    onClick: (search: AdvancedSearchFilter) => void;
}

const SearchesList = memo((props: Props) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return (
        <Box className={rootClasses}>
            {map(props.items, (search, index) => {
                return (
                    <Button
                        key={index}
                        color="secondary"
                        className={classes.searchElement}
                        onClick={() => props.onClick(search)}
                    >
                        {search.type === 'person' ? (
                            <PersonIcon />
                        ) : (
                            <FolderIcon />
                        )}
                        <Box ml={2}>{search.name}</Box>
                        <CloseIcon
                            className={classes.closeIcon}
                            style={{ marginLeft: 'auto' }}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.onDelete(search);
                            }}
                        />
                    </Button>
                );
            })}
        </Box>
    );
});

SearchesList.displayName = 'SearchesList';

export { SearchesList };
