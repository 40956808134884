import React from 'react';
import SearchPeopleContent from './SearchPeopleContent';
import { ProfilePeopleContainer } from './ProfilePeopleContainer';

const SearchPeopleTab = () => {
    return (
        <ProfilePeopleContainer>
            <SearchPeopleContent />
        </ProfilePeopleContainer>
    );
};
export default SearchPeopleTab;
