import React, { memo } from 'react';
import { Box, Icon, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ProfileIcon } from '../molecules';
import { OnAnyEventHandler } from '../helpers/CommonTypes';
import { defaultPicture, Picture } from '@weco/core';
import { getPictureUrl } from '@weco/common';
import LightBulbIcon from '@material-ui/icons/EmojiObjects';
import classNames from 'classnames';
import { MatchPercentage } from './MatchPercentage';

const useStyles = makeStyles(({ palette }: Theme) => ({
    root: {},
    avatarBox: {
        width: '120px',
        height: '120px',
        borderRadius: '62px',
        overflow: 'hidden',
        position: 'relative',
    },
    projectOwnerIconContainer: {
        fontSize: '12px',
        backgroundColor: palette.secondary.light,
    },
    projectOwnerIcon: {
        margin: '2px 0 0 3px',
    },
}));

interface Props {
    percent: number;
    picture: Picture | string;
    onClick?: OnAnyEventHandler;
}

const AvatarBox = ({ picture, ...props }: Props) => {
    const classes = useStyles();
    const placeholderImageUrl =
        typeof picture === 'string'
            ? picture
            : getPictureUrl(picture, defaultPicture);

    return (
        <Box className={classes.avatarBox} onClick={props.onClick}>
            <ProfileIcon picture={placeholderImageUrl} />
        </Box>
    );
};

export const PersonAvatarBox = memo(AvatarBox);
