import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { ChatStore } from '../ChatStore';
import { CHAT_SERVICES, ChatStoreInterface } from '@weco/chat';
import { MyUserStore } from '../MyUserStore';
import { useEffect } from 'react';

export type ChatData = Omit<ChatStoreInterface, ''>;

export const useChatData = () => {
    const store = useStore<ChatStore>(CHAT_SERVICES.ChatStore);
    const { happyConnectionsStore } = useStore<MyUserStore>(MyUserStore);

    useEffect(() => {
        if (!happyConnectionsStore?.connections) {
            happyConnectionsStore?.loadConnections();
        }
    }, []);

    return useObserver(
        () =>
            ({
                chatUser: store.chatUser,
                token: store.token,
                firestore: store.firestore,
                createRoom: store.createRoom,
                loadToken: store.loadToken,
                connect: store.connect,
                disconnect: store.disconnect,
                addUserToRoom: store.addUserToRoom,
                searchUsers: store.searchUsers,
                getSkillSetById: store.getSkillSetById,
                tmpRoom: store.tmpRoom,
            } as ChatData),
    );
};
