import React, { useState } from 'react';
import {
    Box,
    Card,
    Grid,
    Theme,
    Typography,
    GridProps,
    CircularProgress,
    IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { ChildrenType, OnAnyEventHandler } from '../helpers';
import { MatchPercentage } from './MatchPercentage';
import { ShareButtonProps, ShareButton } from './ShareButton';
import { Avatar } from './Avatar';
import { getPictureUrl } from '@weco/common';
import {
    defaultPicture,
    Picture,
    ProjectEntity,
    UserProfileEntity,
} from '@weco/core';
import ForumIcon from '@material-ui/icons/Forum';
import { GoToChatButton, UnauthorizedRedirectOnclick } from '@weco/ui';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        cursor: 'pointer',
        paddingTop: '63px',
    },
    cardRoot: {
        height: '260px',
        width: '310px',
        padding: '30px 20px',
        margin: '0 auto',
        background: theme.palette.common.white,
        boxShadow: '0px 13px 16px rgba(0, 0, 0, 0.05)',
        borderRadius: '6px',
        // Is needed to avoid ShareButton elements cropping.
        overflow: 'visible',
    },
    headerWrapper: {
        height: '30px',
    },
    avatarGrid: {
        maxHeight: '50px',
    },
    avatarBox: {
        width: '135px',
        height: '135px',
        textAlign: 'center',
        transform: 'translate(0, -92px)',
    },
    // TODO delete this class?
    favoriteButton: {
        textAlign: 'center',
    },
    contentContainer: {},
    title: {
        marginTop: 30,
        marginBottom: 5,
        color: theme.palette.primary[700],
    },
    subtitle: {
        marginBottom: 15,
        color: theme.palette.primary[500],
    },
    description: {
        lineHeight: '19px',
        maxHeight: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.secondary[500],
    },
    shareButtonWrapper: {
        overflow: 'visible',
    },
    shareButton: {
        marginTop: '-251px',
    },
    invisibleElement: {
        opacity: 0,
        height: '10px',
    },
    chatIcon: {
        color: '#ddd',
        paddingTop: '18px',
    },
}));

export interface ProfileCardDetailedProps {
    className?: string;
    avatar: ChildrenType;
    onClick?: OnAnyEventHandler;
    matchPercent?: number;
    title: string;
    subTitle?: string;
    description?: string;
    chips?: string[];
    isFavorite?: boolean;
    isMatchPercentHidden?: boolean;
    picture: Picture | string;
    type: 'project' | 'person';
    shareButtonProps: ShareButtonProps;
    isAuthenticated: boolean;
    displayShareButton?: boolean;
    /**
     * TODO remove this prop.
     * @deprecated
     */
    openChat: () => Promise<any>;
    activeProject: ProjectEntity;
    isMyProfile: boolean;
    personIdToOpenChatWith: string;
}

const ProfileCardDetailed = React.memo(function ProfileCardDetailedComponent({
    onClick,
    matchPercent,
    title,
    subTitle,
    description = '',
    chips,
    isFavorite,
    avatar,
    isAuthenticated,
    displayShareButton,
    openChat,
    activeProject,
    isMyProfile,
    ...props
}: ProfileCardDetailedProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    let justifyContent = (description?.length > 50
        ? 'space-around'
        : 'center') as GridProps['justify'];

    if (!description) justifyContent = 'flex-start';

    const isDescriptionHuge = description?.length > 157;
    const doesDescriptionHasOneLine = description?.length < 50;
    const isPercentVisible = !props.isMatchPercentHidden && !!matchPercent;

    return (
        <Box
            onClick={onClick}
            className={classNames(classes.root, props.className)}
        >
            <Card className={classes.cardRoot}>
                <Grid
                    container
                    justify="space-around"
                    className={classes.headerWrapper}
                >
                    <Grid item xs={2}>
                        {/* NOTE: this is a placeholder to keep
                            element width consistent even though
                            "position: abosulute" is active.
                        . */}
                        <MatchPercentage
                            number={0}
                            className={classes.invisibleElement}
                        />
                        {isPercentVisible && (
                            <MatchPercentage number={matchPercent} />
                        )}
                    </Grid>
                    <Grid item xs={8} className={classes.avatarGrid}>
                        <Box className={classes.avatarBox}>
                            <Avatar
                                picture={getPictureUrl(
                                    props.picture,
                                    defaultPicture,
                                )}
                                shape={
                                    props.type === 'person' ? 'round' : 'square'
                                }
                                withBorder
                                percentagePlacement="none"
                                size="large"
                                withShadow
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={2} className={classes.shareButtonWrapper}>
                        {isAuthenticated &&
                        !isMyProfile &&
                        !displayShareButton ? (
                            <UnauthorizedRedirectOnclick>
                                <GoToChatButton
                                    person={
                                        {
                                            id: props.personIdToOpenChatWith,
                                            name: title,
                                            lastName: '',
                                            picture: props.picture,
                                        } as UserProfileEntity
                                    }
                                    className={classes.chatIcon}
                                />
                            </UnauthorizedRedirectOnclick>
                        ) : (
                            <ShareButton
                                activeProject={activeProject}
                                iconColor="gray"
                                {...props.shareButtonProps}
                                className={classes.shareButton}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    justify={justifyContent}
                    className={classes.contentContainer}
                >
                    <Grid item>
                        <Typography
                            align="center"
                            variant="h4"
                            className={classes.title}
                            gutterBottom={doesDescriptionHasOneLine}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    {subTitle && (
                        <Grid item>
                            <Typography
                                align="center"
                                variant="body1"
                                className={classes.subtitle}
                            >
                                {subTitle || ''}
                            </Typography>
                        </Grid>
                    )}
                    {description && (
                        <Grid item>
                            <Typography
                                align="center"
                                variant="body1"
                                color="textSecondary"
                                className={classes.description}
                            >
                                {isDescriptionHuge
                                    ? description.slice(0, 110) + '...'
                                    : description}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Box>
    );
});

ProfileCardDetailed.displayName = 'ProfileCardDetailed';

export { ProfileCardDetailed };
