import ApolloClient from 'apollo-boost';
import { gql } from 'apollo-boost';
import {
    chatGetToken,
    chatCreateRoom,
    chatAddUserToRoom,
} from './gqMutations/chat';
import firebase from 'firebase/app';
import { Exceptions } from '@weco/common';
import { CreateRoomVariablesInterface } from '@weco/chat';
import { UserProfileEntity } from '@weco/core';

export interface AddUserToRoomVariablesInterface {
    roomId: string;
    userId: string;
}

export interface ChatConnectionInterface {
    apolloClient: ApolloClient<any>;
    firebaseClient: firebase.app.App;
}

export class ChatService {
    private client: ApolloClient<any>;
    firebaseClient: firebase.app.App;

    constructor(connection: ChatConnectionInterface) {
        this.client = connection.apolloClient;
        this.firebaseClient = connection.firebaseClient;
    }

    async openConnection(): Promise<{
        user: firebase.auth.UserCredential;
        token: any;
    }> {
        const token = await this.getToken();
        if (!token) {
            throw new Exceptions.InvalidCredentialsException(
                'Unable to open connection',
            );
        }
        const user = await this.firebaseClient
            .auth()
            .signInWithCustomToken(token);
        return { user, token };
    }

    closeConnection(): Promise<void> {
        return this.firebaseClient.auth().signOut();
    }

    /**
     * @return Promise<string> with token for firebase.signInWithToken()
     */
    getToken(): Promise<string | void> {
        return this.client
            .mutate({ mutation: gql(chatGetToken) })
            .then(({ data }: any) => data.chatGetToken);
    }

    /**
     * @return Promise<string> with roomId
     */
    createRoom(
        input: CreateRoomVariablesInterface,
        currentUserId: string,
        profile: UserProfileEntity,
    ): Promise<string | void> {
        return this.client
            .mutate({ mutation: gql(chatCreateRoom), variables: { input } })
            .then(({ data }: any) => data.chatCreateRoom);
    }

    addUserToRoom(
        input: AddUserToRoomVariablesInterface,
    ): Promise<string | void> {
        return this.client
            .mutate({ mutation: gql(chatAddUserToRoom), variables: { input } })
            .then(({ data }: any) => data.chatAddUserToRoom);
    }
}
