import isEmpty from 'lodash/isEmpty';
import debug from 'debug';

const log = debug('makeSearchRequest');

const makeTermFilter = ({ field, term, weight }) => {
    return {
        filter: {
            term: {
                [field]: term,
            },
        },
        weight,
    };
};

const makePhrasePrefixFilter = ({ field, prefix, weight }) => ({
    filter: {
        prefix: {
            [field]: prefix,
        },
    },
    weight,
});

const makeMatch = ({ field, value }) => {
    if (Array.isArray(value)) {
        return {
            terms: {
                [field]: value,
            },
        };
    }
    return {
        match: {
            [field]: value,
        },
    };
};

const makeSearchQuery = ({ query, profileType = 'person' }): any => {
    if (isEmpty(query)) {
        return {};
    }

    const additionalSearchableFiendNames = [
        'dream',
        'causes',
        'departments',
        'skills',
        // 'industries',
        'contacts',
        'school',
        'location',
        'compensations',
        'languages',
        'objectives',
        'passions',
        'fundings',
        'wishList',
        'investments',
        'tradings',
        'stores',
    ];

    //boost operator ^ to make one term more relevant than another. default value 1.
    const additionalFieldsBoostKoef = '^1.2';
    const additionalSearchableFiends = additionalSearchableFiendNames.map(
        (fieldName) => fieldName + additionalFieldsBoostKoef,
    );
    return {
        should: [
            {
                query_string: {
                    boost: 110,
                    allow_leading_wildcard: false,
                    analyzer: 'standard',
                    fields: ['name^1.5', 'lastName^1.5'].concat(
                        additionalSearchableFiends,
                    ),
                    query: query
                        .replace('/', '\\/')
                        .trim()
                        .split(' ')
                        .map((s) => {
                            const strim = s.trim();
                            if (strim.includes(':')) return `"${strim}*"`;
                            return `${strim}*`;
                        })
                        .join(' '),
                },
            },
            {
                query_string: {
                    allow_leading_wildcard: true,
                    analyzer: 'standard',
                    fields:
                        profileType === 'person'
                            ? ['name^1.5', 'lastName^1.5'].concat(
                                  additionalSearchableFiends,
                              )
                            : ['name^1.5'].concat(additionalSearchableFiends),
                    query: query
                        .replace('/', '\\/')
                        .split(' ')
                        .map((s) => {
                            const strim = s.trim();
                            if (strim.includes(':')) return `"${strim}*"`;
                            return `*${strim}*`;
                        })
                        .join(' '),
                },
            },
        ],
    };
};

const makeFilters = (filters) => {
    if (!filters || filters.length === 0) return {};

    return {
        must_not: [...filters],
    };
};

const makeIncludeFilters = (filters, { hasRequiredFields = true }) => {
    const result = { must: [], must_not: [] };
    if (filters && filters.length === 0) {
        result.must = [...filters];
    }

    if (hasRequiredFields) {
        result.must.push({
            exists: {
                field: 'picture',
            },
        });
        result.must.push({
            exists: {
                field: 'dream',
            },
        });
        result.must_not.push({
            term: {
                'picture.keyword': '',
            },
        });
        result.must_not.push({
            term: {
                'dream.keyword': '',
            },
        });
    }
    return result;
};

export const AllDepartments = [
    'ideas',
    'strategy',
    'marketing',
    'finance',
    'execution',
    'itweb',
    'legal',
    'hr',
];

interface SearchRequestParams {
    departments: any;
    skills: any;
    // industries: any;
    query: any;
    exclude: any;
    causes?: any;
    passions?: any;
    languages?: any;
    locations?: any;
    objectives?: any;
    compensations?: any;
    include?: any;
    excludeNotFilledItem: boolean;
    location?: {
        lat: number;
        lon: number;
    };
}

const scores = {
    causes: 15,
    passions: 15,
    objectives: 10,
    locations: 10,
    languages: 10,
    departments: 10,
    skills: 10,
    // industries: 10,
    location: 10,
};

export const makeSearchRequest = ({
    departments,
    skills,
    // industries,
    query,
    exclude,
    causes,
    passions,
    languages,
    locations,
    objectives,
    include,
    compensations,
    excludeNotFilledItem,
    location,
}: SearchRequestParams) => {
    log('makeSearchRequest() is called.');
    log('are skills empty', isEmpty(skills));

    let scoreFunctions = [];
    if (causes && causes.length > 0) {
        scoreFunctions = [
            ...scoreFunctions,
            ...causes.map((item, i, arr) =>
                makeTermFilter({
                    field: 'causes.keyword',
                    term: `${item}`,
                    weight: ((arr.length / scores.causes) * 100).toFixed(1),
                }),
            ),
        ];
    }

    if (passions && passions.length > 0) {
        scoreFunctions = [
            ...scoreFunctions,
            ...passions.map((item, i, arr) =>
                makeTermFilter({
                    field: 'passions.keyword',
                    term: `${item}`,
                    weight: ((arr.length / scores.passions) * 100).toFixed(2),
                }),
            ),
        ];
    }

    if (objectives && objectives.length > 0) {
        scoreFunctions = [
            ...scoreFunctions,
            ...objectives.map((item, i, arr) =>
                makeTermFilter({
                    field: 'objectives.keyword',
                    term: `${item}`,
                    weight: ((arr.length / scores.objectives) * 100).toFixed(2),
                }),
            ),
        ];
    }

    if (locations && locations.length > 0) {
        scoreFunctions = [
            ...scoreFunctions,
            ...locations.map((item, i, arr) =>
                makeTermFilter({
                    field: 'locations.keyword',
                    term: `${item}`,
                    weight: ((arr.length / scores.locations) * 100).toFixed(2),
                }),
            ),
        ];
    }

    if (languages && languages.length > 0) {
        scoreFunctions = [
            ...scoreFunctions,
            ...languages.map((item, i, arr) =>
                makeTermFilter({
                    field: 'languages.keyword',
                    term: `${item}`,
                    weight: ((arr.length / scores.languages) * 100).toFixed(2),
                }),
            ),
        ];
    }

    if (!isEmpty(skills) && departments) {
        log('skills: ', skills);

        scoreFunctions = [
            ...scoreFunctions,
            ...departments.map((item, i, arr) => {
                const skillsFilter = makePhrasePrefixFilter({
                    field: 'skills.keyword',
                    prefix: `${item}:`,
                    weight: ((arr.length / scores.departments) * 10).toFixed(2),
                });

                log('skillsFilter: ', skillsFilter);

                return skillsFilter;
            }),
        ];
    }

    if (!isEmpty(skills)) {
        const depts = isEmpty(departments) ? AllDepartments : departments;
        log('skills: ', skills);

        scoreFunctions = [
            ...scoreFunctions,
            ...skills.reduce(
                (items, item) => [
                    ...items,
                    ...depts.map((dept, i, arr) => {
                        const termFilter = makeTermFilter({
                            field: 'skills.keyword',
                            term: `${item}`,
                            weight: (
                                (arr.length / scores.skills) *
                                100
                            ).toFixed(2),
                        });
                        log('termFilter: ', termFilter);
                        return termFilter;
                    }),
                ],
                [],
            ),
        ];
    }

    // if (industries && industries.length > 0) {
    //     scoreFunctions = [
    //         ...scoreFunctions,
    //         ...industries.map((item, i, arr) =>
    //             makeTermFilter({
    //                 field: 'industries.keyword',
    //                 term: `${item}`,
    //                 weight: ((arr.length / scores.industries) * 100).toFixed(2),
    //             }),
    //         ),
    //     ];
    // }

    if (location) {
        scoreFunctions.push({
            filter: {
                geo_distance: {
                    distance: '200km',
                    'location.location': location,
                },
            },
            weight: scores.location,
        });
    }

    if (compensations && compensations.length > 0) {
        scoreFunctions = [
            ...scoreFunctions,
            ...compensations.map((item, i, arr) =>
                makeTermFilter({
                    field: 'compensations.keyword',
                    term: `${item}`,
                    weight: ((arr.length / scores.objectives) * 100).toFixed(2),
                }),
            ),
        ];
    }

    // now process query string
    const searchQuery = makeSearchQuery({ query });
    let filter = makeFilters(
        (exclude || []).map(({ field, value }) => makeMatch({ field, value })),
    );
    if (include || excludeNotFilledItem) {
        filter = makeIncludeFilters(
            include?.map(({ field, value }) => makeMatch({ field, value })) ||
                [],
            { hasRequiredFields: excludeNotFilledItem },
        ) as any;
    }

    const searchRequest = {
        bool: {
            ...searchQuery,
            ...filter,
        },
    };

    // extra weights for dream and picture
    searchRequest.bool.should = [
        ...(searchRequest.bool.should || []),
        {
            function_score: {
                score_mode: 'sum',
                min_score: 0,
                functions: [],
            },
        },
    ];

    if (scoreFunctions.length > 0) {
        const index = searchRequest.bool.should.findIndex((obj) =>
            obj.hasOwnProperty('function_score'),
        );
        searchRequest.bool.should[index].function_score.functions = [
            ...searchRequest.bool.should[index].function_score.functions,
            ...scoreFunctions,
        ];
        searchRequest.bool.should[index].function_score.query = {
            bool: {
                should: scoreFunctions.map((i) => {
                    const field = Object.keys(i.filter.term)[0];
                    const value = i.filter.term[field];
                    return makeMatch({ field, value });
                }),
            },
        };
    }

    if (Object.keys(searchRequest.bool).length === 0) {
        searchRequest.bool.should = [
            {
                match_all: {},
            } as any,
        ];
    }

    log('finally returning request: ', searchRequest);
    return searchRequest;
};

export default makeSearchRequest;
