import debug from 'debug';
import { toJS } from 'mobx';
import get from 'lodash/fp/get';
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { ConnectionsPage } from '@weco/ui';
import { useChatData } from '../../store/hooks/useChatData';
import { useMyUserData } from '../../store/hooks/useMyUserData';

const componentName = 'ConnectionsPageContainer';
const log = debug(componentName);

const ConnectionsPageContainer = memo(() => {
    const {
        currentUserId,
        userConnections,
        areConnectionsLoading: isLoading,
    } = useMyUserData();

    const peopleAppliedToMyProject = userConnections
        .filter((connection) => {
            // Should not try to apply to my own project.
            if (connection.personId === currentUserId) return false;
            return connection.roles.includes('applicant');
        })
        .map(get('person'));
    const projectsIAmInvitedTo = userConnections
        .filter((connection) => {
            return (
                connection.personId === currentUserId &&
                connection.roles.includes('invitee')
            );
        })
        .map(get('project'));

    log('Is loading: ', isLoading);
    log('My profile id: ', currentUserId);
    log('All userConnections: %O', toJS(userConnections));
    log('projectsIAmInvitedTo: %O', toJS(projectsIAmInvitedTo));
    log('peopleIInvitedToProjects: %O', toJS(peopleAppliedToMyProject));

    return (
        <ConnectionsPage
            {...{
                isLoading,
                currentUserId,
                projectsIAmInvitedTo,
                peopleAppliedToMyProject,
            } as any}
        />
    );
});

ConnectionsPageContainer.displayName = componentName;

export { ConnectionsPageContainer };
