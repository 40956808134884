import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { FunctionComponent, memo } from 'react';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import { OnboardingProgressBar } from './OnboardingProgressBar';
import classnames from 'classnames';
import { localStorageIsRedirectionRequiredKey } from '../../../../../apps/client/src/app/pages/onboarding/onboardingConstants';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        margin: '0 auto',
    },
    closeButton: {
        padding: '12px 0',
    },
    closeButtonIcon: {
        fontSize: '33px',
        color: '#707070',
    },
    closeButtonHidden: {
        opacity: 0,
    },
}));

interface props {
    progressPercentage: number;
    isCloseButtonHidden?: boolean;
}

const component: FunctionComponent<props> = (props) => {
    const cx = useStyles();
    const history = useHistory();
    return (
        <Grid item xs={12} md={4} className={cx.container}>
            <Grid container item justify="flex-end">
                <IconButton
                    className={classnames({
                        [cx.closeButton]: true,
                        [cx.closeButtonHidden]: props.isCloseButtonHidden,
                    })}
                    onClick={
                        props.isCloseButtonHidden
                            ? undefined
                            : () => {
                                  // TLDR: Prevent redirection infinite loop.
                                  localStorage.removeItem(
                                      localStorageIsRedirectionRequiredKey,
                                  );
                                  history.push(RouterPaths.dashboard);
                              }
                    }
                >
                    <CloseIcon className={cx.closeButtonIcon} />
                </IconButton>
            </Grid>
            <Box pt={1} pb={8} px={2}>
                <OnboardingProgressBar percentage={props.progressPercentage} />
            </Box>
        </Grid>
    );
};

export const OnboardingPageHeader = memo(component);
