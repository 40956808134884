import { List, ListItem, ListItemText } from '@material-ui/core';
import { SpriteSvgIconButton } from '@weco/ui';
import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../../../router/RouterPaths';
import { useCurrentAuthData } from '../../../../store/hooks/useCurrentAuthData';
import { environment } from '../../../../../environments/environment';
import { WE_CLICKS } from '../../../../../we_clicks';

export interface LeftSidebarMenuListProps {
    classes: any;
    toggle?: () => void;
    className?: string;
}

export const LeftSidebarMenuList = ({
    classes,
    toggle,
    className,
}: LeftSidebarMenuListProps) => {
    const { push } = useHistory();
    const { signout } = useCurrentAuthData();

    function goTo(path) {
        return () => {
            toggle && toggle();
            push(path);
        };
    }

    return (
        <List className={classNames(classes.list, className)}>
            {/* <ListItem */}
            {/*    button */}
            {/*    onClick={goTo(RouterPaths.favorites)} */}
            {/*    style={{ marginTop: 15 }} */}
            {/* > */}
            {/*    <SpriteSvgIconButton */}
            {/*        classes={{ */}
            {/*            root: classes.listButton, */}
            {/*            svg: classes.buttonSvg, */}
            {/*        }} */}
            {/*        id="MenuIcon-Favorites" */}
            {/*    /> */}
            {/*    <ListItemText */}
            {/*        disableTypography */}
            {/*        className={classes.listItemText} */}
            {/*    > */}
            {/*        Favorites */}
            {/*    </ListItemText> */}
            {/* </ListItem> */}
            <ListItem
                button
                onClick={goTo(RouterPaths.settings)}
                id="container-anchor-settings"
            >
                <SpriteSvgIconButton
                    classes={{
                        root: classes.listButton,
                        svg: classes.buttonSvg,
                    }}
                    id="MenuIcon-Settings"
                />
                <ListItemText
                    disableTypography
                    className={classes.listItemText}
                >
                    Settings
                </ListItemText>
            </ListItem>
            {/* <ListItem button onClick={goTo(RouterPaths.help)}> */}
            {/*    <SpriteSvgIconButton */}
            {/*        classes={{ */}
            {/*            root: classes.listButton, */}
            {/*            svg: classes.buttonSvg, */}
            {/*        }} */}
            {/*        id="MenuIcon-Help" */}
            {/*    /> */}
            {/*    <ListItemText */}
            {/*        disableTypography */}
            {/*        className={classes.listItemText} */}
            {/*    > */}
            {/*        Help */}
            {/*    </ListItemText> */}
            {/* </ListItem> */}
            <ListItem
                button
                onClick={() => {
                    window.location.href = environment.landingPage;
                }}
                style={{ marginBottom: 15 }}
                id="container-anchor-about"
            >
                <SpriteSvgIconButton
                    classes={{
                        root: classes.listButton,
                        svg: classes.buttonSvg,
                    }}
                    id="MenuIcon-About"
                />
                <ListItemText
                    disableTypography
                    className={classes.listItemText}
                >
                    About
                </ListItemText>
            </ListItem>
            <ListItem
                button
                onClick={() =>
                    signout().then(
                        environment.production
                            ? () =>
                                  (window.location.href =
                                      environment.landingPageLogout)
                            : goTo(RouterPaths.dashboard),
                    )
                }
                className={classes.logoutListItem}
                id="container-anchor-logout"
            >
                <SpriteSvgIconButton
                    classes={{
                        root: classes.listButton,
                        svg: classes.buttonSvg,
                    }}
                    id="MenuIcon-Logout"
                />
                <ListItemText
                    id={WE_CLICKS.ENTRY_LOGOUT}
                    disableTypography
                    className={classes.listItemText}
                >
                    Logout
                </ListItemText>
            </ListItem>
        </List>
    );
};
