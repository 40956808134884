import { TMP_ROOM_ID } from './../../utils/constants';
import { combineReducers } from 'redux';
import * as actionTypes from './actionTypes';
import { RoomsListState } from '../../models/interfaces/RoomsInterfaces';
import { ActionListInterface } from '../../models/interfaces/ActionListInterface';
import { RoomInterface } from '../../models/interfaces/RoomInterface';
import { defaultListState } from './defaultState';
import { ROOM_TYPE_PEOPLE } from '../../utils/constants';

const list = (
    state: RoomsListState = defaultListState,
    action: ActionListInterface,
) => {
    switch (action.type) {
        case actionTypes.REQUEST_ROOMS:
            const newRooms: { [key: string]: RoomInterface } = {};
            action.items.forEach((id: string) => {
                newRooms[id] = null;
            });
            return { ...state, items: { ...(state.items || {}), ...newRooms } };
        case actionTypes.RECEIVE_LIST_ROOM:
            const items = {
                ...state.items,
                ...{ [action.item.id]: action.item },
            };
            let sortItems = [];
            const sortedItems = {};
            Object.keys(items).forEach((key: string) => {
                sortItems.push({
                    key,
                    lastMessageTimeTime:
                        items[key]?.lastMessageTimeTime || null,
                });
            });
            sortItems
                .sort((a, b) => {
                    if (a.lastMessageTimeTime === b.lastMessageTimeTime) {
                        return 0;
                    }
                    return a.lastMessageTimeTime < b.lastMessageTimeTime
                        ? 1
                        : -1;
                })
                .forEach((i) => {
                    sortedItems[i.key] = items[i.key];
                });
            return {
                ...state,
                items: sortedItems,
                loading: false,
            };
        case actionTypes.RECEIVE_LIST_ROOM_DELETED:
            const newState = { ...state };
            if (newState.items[action.item.id]) {
                delete newState.items[action.item.id];
            }
            if (newState.activeRoom === action.item.id) {
                newState.activeRoom = null;
            }
            return newState;
        case actionTypes.RECEIVE_LIST_ROOMS:
            return { ...state, loading: false };
        case actionTypes.SET_ACTIVE_ROOM:
            return {
                ...state,
                activeRoom: action.item,
            };
        case actionTypes.SET_TMP_ROOM:
            return {
                ...state,
                items: {
                    ...{ [action.item.id]: action.item },
                    ...state.items,
                },
                activeRoom: action.item.id,
                filterType: ROOM_TYPE_PEOPLE,
            };
        case actionTypes.DELETE_TMP_ROOM:
            const itemsWithTmpRoom = state.items;
            if (itemsWithTmpRoom[TMP_ROOM_ID]) {
                delete itemsWithTmpRoom[TMP_ROOM_ID];
            }
            return {
                ...state,
                items: itemsWithTmpRoom,
            };
        case actionTypes.SET_START_ROOM_CREATION:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.UPDATE_ROOM_LAST_MESSAGE:
            const updateLastMessageRoom: any = state.items[action.roomId] || {};
            updateLastMessageRoom.lastMessage = action.item.message;
            updateLastMessageRoom.lastMessageTime = action.item.createdAt;
            updateLastMessageRoom.lastMessageTimeTime =
                action.item.createdAtTime;
            return {
                ...state,
                items: {
                    ...state.items,
                    ...{ [action.roomId]: updateLastMessageRoom },
                },
            };
        case actionTypes.SET_FILTER_TYPE:
            return { ...state, filterType: action.filterType };
        case actionTypes.SET_DATA_LOADING:
            return { ...state, dataLoading: action.dataLoading };
        default:
            return state;
    }
};

const rooms = combineReducers({
    list,
});

export default rooms;
