const typographyStyles = {
    h1: {
        fontFamily: 'Ubuntu',
        fontSize: '36px',
        fontWeight: 400,
    },
    h2: {
        fontFamily: 'Ubuntu',
        fontSize: '24px',
        fontWeight: 500,
    },
    h3: {
        fontFamily: 'Ubuntu',
        fontSize: '21px',
        fontWeight: 500,
    },
    h4: {
        fontFamily: 'Ubuntu',
        fontSize: '18px',
        fontWeight: 500,
    },
    h5: {
        fontFamily: 'Ubuntu',
        fontSize: '18px',
        fontWeight: 400,
    },
    h6: {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 400,
    },
    body1: {
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        fontWeight: 400,
    },
    body2: {
        fontFamily: 'Ubuntu',
        fontSize: '12px',
        fontWeight: 400,
    },
    button: {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 400,
    },
    caption: {
        fontFamily: 'Ubuntu',
        fontSize: '18px',
        fontWeight: 400,
    },
};

export default typographyStyles;
