import classNames from 'classnames';
import React, { memo } from 'react';
import { useToggle } from 'react-use';
import { BottomSheet } from './BottomSheet';
import { BigNumberCard } from './BigNumberCard';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, GridProps, Theme } from '@material-ui/core';
import { ProjectEntity, SkillSetsEntity, UserProfileEntity } from '@weco/core';
import { AnimatedArrowsWithAdditionalText } from './AnimatedArrowsWithAdditionalText';
import { PositionsListSeparatedByDepartments } from './PositionsListSeparatedByDepartments';
import { findIndex } from 'lodash';
import { PeopleList } from './PeopleList';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    actionButtonStickyWrapper: {
        '& button': {
            position: 'fixed',
            bottom: '20px',
        },
    },
    cardBox: {
        minWidth: '150px',
    },
}));

export interface ProjectTeamOverviewProps {
    isLoading: boolean;
    className?: string;
    project: ProjectEntity;
    currentUserId: string;
    team: UserProfileEntity[];
    roles: SkillSetsEntity[];
    connectButton: JSX.Element;
    matchSkillSetsWith: string[];
}

const numberCardGridSize = {
    xs: 6,
    md: 6,
    item: true,
} as GridProps;

const ProjectTeamOverview = memo((props: ProjectTeamOverviewProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    const [isTeamPanelOpen, toggleTeamPanel] = useToggle(false);
    const [isRolesPanelOpen, toggleRolesPanel] = useToggle(false);

    const numberOfMembers = props.team?.length || 0;
    const numberOfRoles = props.roles?.length || 0;

    // make sure owner is first in the list
    props.team?.unshift(
        props.team?.splice(
            findIndex(props.team, { id: props.project?.owner.id }),
            1,
        )[0],
    );

    return (
        <Box className={rootClasses}>
            <Grid container spacing={6} justify="center">
                <Grid {...numberCardGridSize} className={classes.cardBox}>
                    <BigNumberCard
                        title="Required Roles"
                        number={numberOfRoles}
                        isLoading={props.isLoading}
                        onClick={toggleRolesPanel}
                    />
                </Grid>
                <Grid {...numberCardGridSize} className={classes.cardBox}>
                    <BigNumberCard
                        title="Team"
                        number={numberOfMembers}
                        onClick={toggleTeamPanel}
                        isLoading={props.isLoading}
                    />
                </Grid>
            </Grid>
            <BottomSheet
                title="Roles"
                isOpen={isRolesPanelOpen}
                onClose={toggleRolesPanel}
            >
                <Box>
                    {numberOfRoles > 0 && (
                        <PositionsListSeparatedByDepartments
                            items={props.roles}
                            matchSkillSetsWith={props.matchSkillSetsWith}
                        />
                    )}
                    {numberOfRoles <= 0 && (
                        <Box>
                            <AnimatedArrowsWithAdditionalText
                                text={
                                    <div>
                                        {props.project?.name} has no positions
                                        yet.
                                        <br />
                                        Connect and be the first to join.
                                    </div>
                                }
                            />
                            <Box className={classes.actionButtonStickyWrapper}>
                                {props.connectButton}
                            </Box>
                        </Box>
                    )}
                </Box>
            </BottomSheet>
            <BottomSheet
                title="Team"
                isOpen={isTeamPanelOpen}
                onClose={toggleTeamPanel}
            >
                <Box>
                    {numberOfMembers > 0 && (
                        <PeopleList
                            skillsSection
                            items={props.team}
                            currentUserId={props.currentUserId}
                        />
                    )}
                    {numberOfMembers <= 0 && (
                        <Box>
                            <AnimatedArrowsWithAdditionalText
                                text={
                                    <div>
                                        {props.project?.name} has no positions
                                        yet.
                                        <br />
                                        They might not realize they need you.
                                        <br />
                                        Contact them.
                                    </div>
                                }
                            />
                        </Box>
                    )}
                    <Box className={classes.actionButtonStickyWrapper}>
                        {props.connectButton}
                    </Box>
                </Box>
            </BottomSheet>
        </Box>
    );
});

ProjectTeamOverview.displayName = 'ProjectTeamOverview';

export { ProjectTeamOverview };
