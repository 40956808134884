import React from 'react';
import {
    DialogTitle,
    Dialog,
    makeStyles,
    DialogContent,
    TextField,
    DialogActions,
    Button,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { WE_CLICKS } from '../../../we_clicks';

export type AddProjectDialogProps = {
    open: boolean;
    actions: {
        onAdd(projectName: string);
        onClose();
    };
};
const useStyles = makeStyles(() => ({
    root: {},
}));

const AddProjectDialogView: React.FC<AddProjectDialogProps> = ({
    open,
    actions,
}: AddProjectDialogProps) => {
    const [name, setName] = React.useState('');
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleAdd = () => {
        actions.onAdd(name);
        setName('');
    };
    const onClose = () => {
        setName('');
        actions.onClose();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">New Project</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Project Name"
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        id={WE_CLICKS.CREATE_PROJECT_NAME_CANCEL}
                        onClick={onClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        id={WE_CLICKS.CREATE_PROJECT_NAME_CREATE}
                        onClick={handleAdd}
                        color="primary"
                        disabled={isEmpty(name.trim())}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default AddProjectDialogView;
