export const WeWarning = {
    50: '#FFF8E6',
    100: '#FFF0CC',
    200: '#FFE099',
    300: '#FFD166',
    400: '#FFC133',
    500: '#FFB200',
    600: '#BF8600',
    700: '#805900',
    800: '#402D00',
    900: '#1A1200',
    A100: '#FFF0CC',
    A200: '#FFD166',
    A400: '#FFB200',
    A700: '#402D00',
};
