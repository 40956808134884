import React from 'react';
import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    TextField,
    Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme: Theme) => ({
    searchBox: {
        width: '100%',
        padding: 10,
        backgroundColor: '#C8C8C8',
    },
    search: {
        backgroundColor: '#fff',
        width: '100% ',
        minHeight: 30,
        borderRadius: '6px',
        border: 0,
    },
    searchInput: {
        fontSize: '20px',
        color: '#bdbdbd',
        padding: '13px',
        border: 0,

        borderRadius: '6px',
    },
    expansionSvg: {
        fontSize: 38,
        color: '#BDBDBD',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: 'calc(100% - 15px)',
        fontSize: '20px',
        color: '#BDBDBD',
    },
}));

const NotificationsHeader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.searchBox}>
            <FormControl
                className={clsx(
                    classes.margin,
                    classes.textField,
                    classes.search,
                )}
                variant="outlined"
            >
                <OutlinedInput
                    inputProps={{ className: classes.searchInput }}
                    color={'secondary'}
                    type={'text'}
                    labelWidth={0}
                    placeholder={'Search'}
                    style={{ paddingRight: 5 }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => console.log('next')}
                                edge="end"
                            >
                                <ChevronRightIcon
                                    className={classes.expansionSvg}
                                />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    );
};

export default NotificationsHeader;
