export const createPassedTestResult = /* GraphQL */ `
    mutation CreatePassedTestResult($input: CreatePassedTestResultInput!) {
        createPassedTestResult(input: $input) {
            id
            type
            answers {
                questionId
                question
                option
            }
            ownerId
            results {
                points
                department
            }
            status
        }
    }
`;

export const updatePassedTestResult = /* GraphQL */ `
    mutation UpdatePassedTestResult($input: UpdatePassedTestResultInput!) {
        updatePassedTestResult(input: $input) {
            id
            answers {
                questionId
                question
                option
            }
            results {
                points
                department
            }
            status
        }
    }
`;

export const deletePassedTestResult = /* GraphQL */ `
    mutation DeletePassedTestResult($input: DeletePassedTestResultInput!) {
        deletePassedTestResult(input: $input) {
            id
        }
    }
`;
