import { Box, Button, Grid, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { WeSimplePaginatedList, WeChipsList } from '../molecules';
import { WeChipsInput } from '../forms';
import isEmpty from 'lodash/isEmpty';
import { AutocompleteFormControl } from '../../../../../apps/client/src/app/pages/me/formControls';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    chip: {
        borderRadius: '4px',
        margin: 5,
    },
    saveButton: {
        padding: '0px 5px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info[50],
        borderRadius: '5px',
        border: 0,
        '&:focus': {
            backgroundColor: theme.palette.info[50],
        },
        '&:hover': {
            backgroundColor: theme.palette.info[50],
        },
    },
    inputBox: {
        marginBottom: '18px',
    },
    header: {
        padding: 0,
        borderBottom: '1px solid #ddd',
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: 600,
        padding: '12px 5px',
        textTransform: 'capitalize',
    },

    headerButton: {
        marginLeft: 'auto',
    },
}));

interface FilterItemEditProps {
    title: string;
    selected: string[];
    options: string[];
    isOpenField?: boolean;
    children?: JSX.Element;
    handleCancel: () => void;
    onChange: (key: string, value: string[]) => void;
}

const SearchFieldEdit = ({
    title,
    selected,
    options,
    isOpenField,
    handleCancel,
    onChange,
    ...props
}: FilterItemEditProps) => {
    const classes = useStyles();
    const [values, setValues] = useState(selected || []);

    useEffect(() => {
        setValues(selected || []);
    }, [selected]);

    useEffect(() => {
        if (selected?.length !== values?.length) {
            handleSave(true);
        }
    }, [values]);

    // const handleChange = (v): void => {
    //     const index = values?.indexOf(v.label);
    //     if (index > -1) {
    //         values.splice(index, 1);
    //     } else {
    //         values.push(v.label);
    //     }
    //     setValues([...values]);
    // };

    const handleChangeOpenField = (v): void => {
        setValues(v);
    };

    const handleSave = (justSave?: boolean): void => {
        let filterProperty = title;
        switch (title) {
            case 'tags':
                filterProperty = 'passions';
                break;
            case 'roles':
                filterProperty = 'skills';
                break;
        }

        onChange(filterProperty, values);
        if (!justSave) {
            handleCancel();
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.root}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        className={!isOpenField && classes.inputBox}
                    >
                        {props.children}
                        {title.toLowerCase() !== 'roles' && (
                            <WeChipsInput
                                name={title}
                                value={values}
                                onChange={handleChangeOpenField}
                                suggestions={options}
                                freeSolo
                                isOpenField={isOpenField}
                                disableCloseOnSelect={true}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Button
                                className={classes.saveButton}
                                onClick={() => handleSave(false)}
                            >
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

SearchFieldEdit.displayName = 'SearchFieldEdit';

export { SearchFieldEdit };
