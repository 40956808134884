import lngs from 'language-list';

const arr = [
    { language: 'Ukrainian', code: 'uk' },
    { language: 'Nested Egg', code: 'neg' },
    { language: 'Sign Language', code: 'sgl' },
];

const allLanguages = lngs()
    .getData()
    .concat(arr)
    .map((language) => language.language)
    .sort();

export default allLanguages;
