import React from 'react';
import { SecondaryNavTabs } from '@weco/ui';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PersonalTabPage from './TabPages/PersonalTabPage';
import ProjectsTabPage from './TabPages/ProjectsTabPage';
import NotificationsHeader from './NotificationsHeader';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export const NotificationsPage = () => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <NotificationsHeader />
            </Grid>
            <Grid className={classes.root} item xs={12}>
                <SecondaryNavTabs
                    tabs={[
                        {
                            label: 'Personal',
                            to: `${NotificationsPage.URL}`,
                            component: PersonalTabPage,
                        },
                        {
                            label: 'Projects',
                            to: `${NotificationsPage.URL}/projects`,
                            component: ProjectsTabPage,
                            componentProps: `${NotificationsPage.URL}/projects`,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};

NotificationsPage.URL = '/notifications';
