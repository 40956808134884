import firebase, { firestore } from 'firebase/app';
import { connect } from 'react-redux';
import Chat from '../components/Chat';
import {
    setActiveRoom,
    setTmpRoom,
    replaceTmpRoomToExists,
    deleteRoom,
    fetchRooms,
} from '../store/rooms/actions';
import * as actions from '../store/rooms/actionCreators';
import { fetchCurrentUser } from '../store/users/actions';
import { receiveUserCredentials } from '../store/authUser/actionCreators';
import { RoomInterface, TmpRoomInterface, UserRoomInterface } from '@weco/chat';
import MessageInterface from '../models/interfaces/MessageInterface';

const mapStateToProps = (state: any) => ({
    user:
        state.chat?.authUser?.uid &&
        state.chat?.users?.list?.items[state.chat.authUser.uid]
            ? state.chat.users.list.items[state.chat.authUser.uid]
            : null,
    activeRoom: state.chat?.rooms?.list?.activeRoom,
    subscribedRoomsIds: state.chat?.roomMessages?.subscribedRoomsIds,
    rooms: state.chat?.rooms?.list?.items,
    roomsMessages: state.chat?.roomMessages?.messages,
    error: state.chat?.authUser?.error,
    loading: state.chat?.authUser?.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCurrentUser: (firestore: firebase.firestore.Firestore, id: string) =>
        dispatch(fetchCurrentUser(firestore, id)),
    receiveUserCredentials: (id: string, error: Error) =>
        dispatch(receiveUserCredentials(id, error)),
    setActiveRoom: (
        firestore: firebase.firestore.Firestore,
        id: string,
        subscribe: boolean,
    ) => dispatch(setActiveRoom(firestore, id, subscribe)),
    setActiveRoomAndTab: (
        firestore: firebase.firestore.Firestore,
        filterType: string,
        id: string,
        subscribe: boolean,
    ) => {
        dispatch(setActiveRoom(firestore, id, subscribe));
        dispatch(actions.setFilterType(filterType));
    },
    setActiveTmpRoomAndTab: (tmpRoom: TmpRoomInterface) => {
        dispatch(setTmpRoom(tmpRoom));
    },
    replaceTmpRoomToExists: (
        firestore: firebase.firestore.Firestore,
        rooms: { [key: string]: RoomInterface },
        roomsMessages: { [key: string]: MessageInterface[] },
        roomId: string,
    ) => {
        dispatch(
            replaceTmpRoomToExists(firestore, rooms, roomsMessages, roomId),
        );
    },
    deleteTmpRoom: (
        firestore: firebase.firestore.Firestore,
        roomId: string,
    ) => {
        dispatch(deleteRoom(firestore, roomId));
    },
    fetchRooms: (
        firestore: firebase.firestore.Firestore,
        rooms: UserRoomInterface[],
    ) => dispatch(fetchRooms(firestore, rooms)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
