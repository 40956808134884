import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@weco/common';
import {
    SkeletonAsyncLoader,
    ReactHelpers,
    useAsync,
    ProjectSkeletonPlaceholder,
} from '@weco/ui';
import { MainPageFilter } from '../../../helpers/CommonTypes';
import { PeopleMainPageStore } from './store/PeopleMainPageStore';

export interface PeopleListContainerProps {
    children: any;
    refresh?: boolean;
    numberOfItemsDuringLoading?: number;
    filters: MainPageFilter[];
}

export const PeopleListContainer = observer(
    ({
        children,
        refresh,
        filters,
        numberOfItemsDuringLoading: skeletonItemsCount = 2,
    }: PeopleListContainerProps) => {
        const store = useStore<PeopleMainPageStore>(PeopleMainPageStore);
        store.filters = filters;
        const requestResults = useAsync(() => {
            if (!refresh || !store.items) {
                return store.getList();
            }
        });

        return (
            <SkeletonAsyncLoader
                {...requestResults}
                count={skeletonItemsCount}
                skeleton={<ProjectSkeletonPlaceholder />}
            >
                {!requestResults.loading &&
                    ReactHelpers.renderChildren(children)}
            </SkeletonAsyncLoader>
        );
    },
);
