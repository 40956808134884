import React from 'react';
import parse from 'html-react-parser';
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Badge,
    Typography,
    Theme,
    createStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { RoomPropsInterface } from '../models/interfaces/RoomInterfaces';
import RoomAvatar from './RoomAvatar';
import { ROOM_TYPE_PEOPLE } from '../utils/constants';
import { ReferenceDataInterface } from '../models/interfaces/ReferenceDataInterface';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            padding: '15px 5px',
            borderTop: `1px solid #e0e0e0`,
            '&:first-child': {
                paddingTop: 10,
                borderTop: 'none',
            },
        },
        avatar: {
            minWidth: 50,
        },
        name: {
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '16px',
        },
        timeWrapper: {
            marginLeft: 10,
            minWidth: 'fit-content',
            color: theme.palette.grey.A200,
            fontSize: '12px',
        },
        timeIcon: {
            width: 20,
            height: 'auto',
        },
        lastMessage: {
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.grey.A200,
            fontSize: '14px',
        },
    }),
);

const toStringMessage = (message: any) => {
    {
        /* NOTE: I have no idea what this code does.
        This is a hotfix to resolve 500 error after
        room.lastMessage became an object instead of string.
    */
    }
    if (typeof message === 'object') {
        switch (message.class) {
            case 'ProjectInvite':
                return 'Action required';
            // can we find out is message type is invitation or application ?
            // they both comes with class 'ProjectInvite',
            // I think we need to separate them, but i have no idea how
            // case 'ProjectApply' :
            //     return 'New Application';
            default:
                return message.class;
        }
    }
    return parse(message) || '';
};

const Room = ({
    room,
    currentUser,
    users,
    unreadCount,
    isActive,
    setActiveRoom,
    onAvatarClick,
}: RoomPropsInterface) => {
    const classes = useStyles();
    let name = '';
    if (room.type === ROOM_TYPE_PEOPLE) {
        const member: ReferenceDataInterface = room.members.find(
            (member: ReferenceDataInterface) => member.id !== currentUser.id,
        );
        if (member) {
            if (users[member.id]) {
                name = users[member.id].name;
            }
        }
    } else {
        name = room.name;
    }

    const primary = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
            }}
        >
            <Typography align="left" className={classes.name}>
                {name}
            </Typography>
            <Typography
                align="right"
                variant="h5"
                className={classes.timeWrapper}
            >
                {room.lastMessageTime}
            </Typography>
        </div>
    );

    return (
        <ListItem
            button
            onClick={() => {
                setActiveRoom(room.id);
            }}
            selected={isActive}
            className={classes.listItem}
        >
            <ListItemAvatar className={classes.avatar}>
                <Badge badgeContent={unreadCount} color="primary">
                    <RoomAvatar
                        room={room}
                        currentUser={currentUser}
                        users={users}
                        disableIconClick
                        onAvatarClick={onAvatarClick}
                    />
                </Badge>
            </ListItemAvatar>
            <ListItemText
                primary={primary}
                secondary={
                    <span className={classes.lastMessage}>
                        {toStringMessage(room.lastMessage)}
                    </span>
                }
            />
        </ListItem>
    );
};

export default Room;
