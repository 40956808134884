import { gql } from 'apollo-boost';
import { Transformers, Injectable, addUpdatedAt } from '@weco/common';
import { DepartmentEntity, SkillSetsEntity, SkillEntity } from '../entity';
import { GraphQlBaseRepository } from './GraphQlBaseRepository';

import _ from 'lodash';
import {
    departmentSkillsByRefCount,
    listDepartments,
    listSkillSets,
    getSkillSet,
} from './graphql/queries';
import {
    createSkillSet,
    deleteSkillSet,
    projectPersonsConnectionLightweight,
    updateSkillSet,
} from './gqlRequests';
import { environment } from 'apps/client/src/environments/environment';
import get from 'lodash/fp/get';

export interface SkillSetsFilter {
    departmentId?: string;
    ownerId?: string;
    projectId?: string;
}

export interface SkillSetRepositoryInterface {
    loadSkillsListForSuggest(departmentName: string): Promise<SkillEntity[]>;
    addSkillSets(item: SkillSetsEntity): Promise<SkillSetsEntity>;
    loadDepartments(): Promise<DepartmentEntity[]>;
    loadSkillSets(filter?: SkillSetsFilter): Promise<SkillSetsEntity[]>;
    deleteSkillSets(id: string): Promise<SkillSetsEntity>;
    updateSkillSets(item: SkillSetsEntity): Promise<SkillSetsEntity>;
    getSkillSetById(id: number): Promise<SkillSetsEntity>;
}
@Injectable()
export class SkillSetRepository extends GraphQlBaseRepository
    implements SkillSetRepositoryInterface {
    static readonly MIN_SUGGEST_REF_COUNT = 1;

    public async loadSkillsListForSuggest(
        departmentName: string = '',
    ): Promise<SkillEntity[]> {
        departmentName = departmentName;
        return this.authorizedClient
            .query({
                query: gql(departmentSkillsByRefCount),
                variables: {
                    departmentName: departmentName,
                    refCount: { ge: SkillSetRepository.MIN_SUGGEST_REF_COUNT },
                },
            })
            .then((data) => data.data.departmentSkillsByRefCount.items)
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<SkillEntity>(
                            SkillEntity,
                        ),
                    ),
                ),
            );
    }

    public async addSkillSets(item: SkillSetsEntity): Promise<SkillSetsEntity> {
        const mutation = gql(createSkillSet);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    input: {
                        ..._.omit(
                            new Transformers.ToPlainTransformer().transform(
                                item,
                            ),
                            ['owner', '__typename'],
                        ),
                    },
                },
                update: () => this.invalidateAllQueryFetching('listSkillSets'),
            })
            .then((result) => result.data.createSkillSet)
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer<SkillSetsEntity>(
                        SkillSetsEntity,
                    ),
                ),
            )
            .then((result: SkillSetsEntity) => {
                result.department = result.department ?? item.department;
                result.project = result.project ?? item.project;
                return result;
            });
    }

    public async loadDepartments(): Promise<DepartmentEntity[]> {
        return this.unAuthorizedClient
            .query({ query: gql(listDepartments) })
            .then((data) => {
                let result = data.data.listDepartments.items;
                return result;
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<DepartmentEntity>(
                            DepartmentEntity,
                        ),
                    ),
                ),
            );
    }

    static readonly EMPTY_ID = environment.defaults.emptyId;

    public async loadSkillSets(
        filter?: SkillSetsFilter,
    ): Promise<SkillSetsEntity[]> {
        const buildQueryFilter = (filter: SkillSetsFilter) => {
            if (
                !filter ||
                (!filter.departmentId && !filter.ownerId && !filter.projectId)
            )
                return {};

            const result = {
                filter: {},
                limit: environment.defaults.queries.APPSYNC_INT_MAX,
            };

            if (filter.departmentId) {
                result.filter['departmentId'] = { eq: filter.departmentId };
            }

            if (filter.ownerId) {
                result.filter['ownerId'] = { eq: filter.ownerId };
            }

            if (filter.projectId) {
                result.filter['projectId'] = { eq: filter.projectId };
            }

            return result;
        };
        // todo: this is crap and hotfix. AppSync don't respect the limit
        const loadData = async (nextToken?: string) =>
            this.unAuthorizedClient
                .query({
                    query: gql(listSkillSets),
                    variables: { ...buildQueryFilter(filter), nextToken },
                    // fetchResults: true,
                })
                .then((data) => {
                    let result = data.data.listSkillSets.items;
                    if (data.data.listSkillSets.nextToken) {
                        return loadData(data.data.listSkillSets.nextToken)
                            .then((r) => [].concat(result, r))
                            .catch(() => result);
                    }
                    return result;
                });

        return loadData().then(
            Transformers.promisePipeTransform(
                new Transformers.CollectionTransformer(
                    new Transformers.ToTypeTransformer<SkillSetsEntity>(
                        SkillSetsEntity,
                    ),
                ),
            ),
        );
    }

    public async deleteSkillSets(id: string): Promise<SkillSetsEntity> {
        const mutation = gql(deleteSkillSet);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    input: { id },
                },
                //TODO: check right way to invalidate cache / add all affected queries
                update: () => this.invalidateAllQueryFetching('listSkillSets'),
            })
            .then((result) => result.data.deleteSkillSet)
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer<SkillSetsEntity>(
                        SkillSetsEntity,
                    ),
                ),
            );
    }

    public async updateSkillSets(
        item: SkillSetsEntity,
    ): Promise<SkillSetsEntity> {
        const mutation = gql(updateSkillSet);
        const variables = {
            input: {
                ..._.omit(
                    addUpdatedAt(
                        new Transformers.ToPlainTransformer().transform(item),
                    ),
                    ['owner', '__typename'],
                ),
            },
        };

        return this.authorizedClient
            .mutate({
                mutation,
                variables,
                update: () => this.invalidateAllQueryFetching('listSkillSets'),
            })
            .then(get('data.updateSkillSet'))
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer<SkillSetsEntity>(
                        SkillSetsEntity,
                    ),
                ),
            );
    }

    public async initData(): Promise<SkillSetsEntity[]> {
        throw new Error('Use only once to load init data.');
        /* const departments = await this.loadDepartments();
         const skillSetsList = defaultSkillSets.reduce<SkillSetsEntity[]>(
             (acc, item) => {
                 const dep = departments.find(
                     (dep) =>
                         dep.name.toLowerCase() === item.department.toLowerCase()
                 );
                 if (!dep) {
                     return acc;
                 }
                 const newSkillSets = new SkillSetsEntity();

                 newSkillSets.departmentId = dep.id;
                 newSkillSets.id = null;
                 newSkillSets.name = item.name;
                 newSkillSets.description = item.desc;
                 newSkillSets.ownerId = environment.defaults.emptyId;
                 newSkillSets.projectId = environment.defaults.emptyId;
                 newSkillSets.skills = item.skills.split(',').map((skill) => {
                     const newSkill = new SkillEntity();
                     newSkill.departmentName = dep.name;
                     newSkill.refCount = 300;
                     newSkill.value = skill.trim().toLowerCase();
                     newSkill.id = `${newSkill.departmentName}:${newSkill.value}`;
                     return newSkill;
                 });
                 acc.push(newSkillSets);
                 return acc;
             },
             []
         );
         //skillSetsList.forEach(async (item) => {await this.addSkillSets(item)});
         debugger;
         return skillSetsList;*/
    }

    public async getSkillSetById(id: number): Promise<SkillSetsEntity> {
        return this.unAuthorizedClient
            .query({
                query: gql(getSkillSet),
                variables: {
                    id,
                },
                fetchPolicy: 'network-only',
            })
            .then((results) => {
                return results.data.getSkillSet || null;
            });
    }
}
