import classNames from 'classnames';
import React, { memo } from 'react';
import { Box, Theme, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({ root: {} }));

interface Props {
    className?: string;
}

const ThemeDemonstration = memo((props: Props) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return (
        <Box className={rootClasses}>
            <Typography variant="h2">NOTE: WIP.</Typography>
            <Typography>Primary button.</Typography>
            <Button>Accept</Button>
            <Typography>Secondary button.</Typography>
            <Button color="secondary">Reject</Button>
            <Typography>"Default" color.</Typography>
            <Button color="default">Delete</Button>
            <Typography>Disabled state.</Typography>
            <Button disabled>Disabled</Button>
        </Box>
    );
});

ThemeDemonstration.displayName = 'ThemeDemonstration';

export { ThemeDemonstration };
