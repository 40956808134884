import classNames from 'classnames';
import React, { memo, useState } from 'react';
import { Box } from '@material-ui/core';
import { SkillSetsEntity, getDepartmentDisplayName } from '@weco/core';
import { BoxList } from './BoxList';
import { SpriteSvgIconButton } from '../molecules/SpriteSvgIconButton';
import { DepartmentSection } from './DepartmentsDrawer';
import { departmentsDrawerStyles } from './DepartmentsDrawerStyles';

class ToggleState {
    type: string;
    value: boolean;
}

interface DepartmentListProps {
    sections: DepartmentSection[];
    isPersonPage?: boolean;
    onItemClick?(item: SkillSetsEntity);
}

const Component = ({
    sections,
    isPersonPage,
    onItemClick,
}: DepartmentListProps) => {
    const cx = departmentsDrawerStyles();
    const [sectionsToggleState, setSectionsToggleState] = useState<
        ToggleState[]
    >(sections.map((section) => ({ type: section.name, value: false })));
    const [] = useState<{
        userId: string;
        loading: boolean;
    }>({ userId: null, loading: false });

    const toggleSection = (sectionTitle) => {
        const updated = sectionsToggleState.map((item: ToggleState) => {
            if (item.type === sectionTitle) {
                item.value = !item.value;
            }
            return item;
        });
        setSectionsToggleState(updated);
    };

    const sectionOpened = (section: DepartmentSection) => {
        return sectionsToggleState.find((state) => state.type === section.name)
            .value;
    };

    return (
        <Box className={cx.customDrawer}>
            {sections.map((section) => {
                return (
                    <Box className={cx.customDrawerElement} key={section.name}>
                        <Box
                            className={cx.title}
                            onClick={() => toggleSection(section.name)}
                        >
                            <Box
                                className={
                                    cx.layoutToBreakChildStylesInheritance
                                }
                            >
                                <Box className={cx.sectionName}>
                                    <SpriteSvgIconButton
                                        id={
                                            section.name
                                                ? 'MainNavIcon-' + section.name
                                                : 'MainNavIcon-Notifications'
                                        }
                                    />
                                    {getDepartmentDisplayName(section.name)} (
                                    {section.items?.length})
                                </Box>
                                <Box>
                                    {isPersonPage &&
                                        `${section.department.testPercent}%`}
                                </Box>
                                <Box mt="10px">
                                    <Box
                                        className={classNames({
                                            [cx.chevron]: true,
                                            [cx.rotated]: sectionOpened(
                                                section,
                                            ),
                                        })}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            className={classNames({
                                [cx.opened]: sectionOpened(section),
                                [cx.closed]: !sectionOpened(section),
                            })}
                        >
                            <BoxList
                                items={section.items}
                                onItemClick={onItemClick}
                            />
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
export const DepartmentsDrawerView = memo(Component);
