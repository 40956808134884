import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import useTheme from '@material-ui/core/styles/useTheme';

type Color = 'primary' | 'white' | 'gray';

export interface MinimalisticFooterProps {
    textColor?: Color;
}

function getTextColor({ color, theme }: { color: Color; theme: Theme }) {
    switch (color) {
        case 'white':
            return theme.palette.common.white;
        case 'gray':
            return theme.palette.text.secondary;
        default:
            return theme.palette.primary.main;
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: '78px 0 44px 0',
    },
}));

export const MinimalisticFooter = ({
    textColor = 'gray',
}: MinimalisticFooterProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const color = getTextColor({ color: textColor, theme });

    return (
        <Typography
            className={classes.root}
            variant="body1"
            align="center"
            style={{ color }}
        >
            Copyright © {new Date().getFullYear()} - All rights reserved
        </Typography>
    );
};
