import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { MyUserStore } from '../MyUserStore';
import { ProjectEntity, UserProfileEntity } from '@weco/core';

export interface MyUserProjectsData {
    myOwnProjects: ProjectEntity[];
    loadProjects: () => Promise<ProjectEntity[]>;
    activeProjectId: string;
    activeProject: ProjectEntity;
    changeMyActiveProject: (projectId: string) => Promise<void>;
    addNewProject: (name: string) => Promise<{ id: string; name: string }>;
    deleteProject: (id: string) => Promise<any>;
    profile: UserProfileEntity;
}

export const useMyUserProjectsData = () => {
    const store = useStore<MyUserStore>(MyUserStore);

    return useObserver(
        () =>
            ({
                profile: store.profile,
                myOwnProjects: store.myOwnProjects,
                activeProject: store.activeProject,
                addNewProject: store.addNewProject,
                deleteProject: store.deleteProject,
                loadProjects: store.loadMyOwnProjects,
                activeProjectId: store.activeProjectId,
                changeMyActiveProject: store.changeMyActiveProject,
            } as MyUserProjectsData),
    );
};
