import React from 'react';
import { ChildrenType } from '../helpers';

export type AlertBorderProps = {
    children?: ChildrenType;
};

export const AlertBorder = ({ children }: AlertBorderProps) => {
    return <div style={{ border: '2px solid red' }}>{children}</div>;
};
