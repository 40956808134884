import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import HeaderStepperContainer from './HeaderStepperContainer';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        background: 'linear-gradient(72.09deg, #A98BFF -46.49%, #2D2962 82.5%)',
        // transform: 'matrix(1, 0, 0, -1, 0, 0)',
        color: '#ffffff',
        textAlign: 'center',
        padding: '30px',
        [theme.breakpoints.up('md')]: {
            padding: '61px 135px',
        },
    },
    welcomeTitle: {
        fontSize: '21px',
        fontWeight: 700,
    },
    welcomeDesc: {
        fontSize: '16px',
        fontWeight: 400,
    },
}));

const HeaderSteps = ({ name }: { name: string }) => {
    const classes = useStyles();

    if (!name) return null;
    return (
        <Box className={classes.root} id="container-anchor-stepper">
            <Typography component="div" className={classes.welcomeTitle}>
                {name ? `Welcome ${name}!` : <Skeleton animation="wave" />}
            </Typography>
            <Typography className={classes.welcomeDesc}>
                Let’s quickly finish setting you up
            </Typography>
            <HeaderStepperContainer />
        </Box>
    );
};
export default HeaderSteps;
