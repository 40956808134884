import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { CoolsStore } from '../CoolsStore';
import { SERVICES } from '../../services/services';
import { useMemo, useState } from 'react';

export const useCoolData = (
    entityId?: string,
    entityType?: 'Person' | 'Project',
) => {
    const store = useStore<CoolsStore>(SERVICES.CoolsStore);
    const [loading, setLoading] = useState(false);

    const doLoadInitData = async () => {
        setLoading(true);
        try {
            await store.getCoolsListByEntityId({
                entityType,
                entityId,
            });
            setLoading(false);
        } catch (e) {
            console.log('error', e);
            setLoading(false);
        }
    };

    useMemo(doLoadInitData, []);

    return useObserver(() => ({
        loading,
        cools: store.cools,
        addCool: store.addCool,
        deleteCool: store.deleteCool,
        hasCool: store.hasCool,
    }));
};
