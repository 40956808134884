import { Box, Button, CircularProgress } from '@material-ui/core';
import { ProjectEntity, SkillSetsEntity } from '@weco/core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { collaborationActionStyles } from './CollaborationAction.styles';
import useToggle from 'react-use/lib/useToggle';
import { APP_EVENTS } from '../../../../../apps/client/src/app_events';
import { ApplyToProjectDialog } from '../components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { RequiredInfoBanner } from '../components/RequiredInfoBanner';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { environment } from '../../../../../apps/client/src/environments/environment';
import { ConfirmDialog } from '@weco/ui';

export interface InviteCollaborationActionsProps {
    incompleteProject?: boolean;
    isApplicant: boolean;
    isMember: boolean;
    ableToInvite: boolean;
    invited: boolean;
    isLoadingStoreData: boolean;
    declineApplication: () => void;
    acceptApplication: () => void;
    removeProjectMember: () => void;
    inviteToActiveProject: () => void;
    recallInvitation: () => void;
    activeProject: ProjectEntity;
    isAuthenticated?: boolean;
    projectsActiveRoles?: SkillSetsEntity[];
    setPersonProjectConnection: (param) => void;
    personProjectConnection: {
        personId: string;
        projectId: string;
        roles: string[];
    };
}

export const InviteCollaborationActions = ({
    isApplicant,
    isMember,
    ableToInvite,
    invited,
    declineApplication,
    acceptApplication,
    removeProjectMember,
    inviteToActiveProject,
    recallInvitation,
    activeProject,
    isLoadingStoreData,
    isAuthenticated = true,
    projectsActiveRoles,
    incompleteProject,
    setPersonProjectConnection,
    personProjectConnection,
}: InviteCollaborationActionsProps) => {
    const classes = collaborationActionStyles();
    const [loading, toggleLoading] = useToggle(isLoadingStoreData);
    const [openApplyToProjectDialog, setOpenApplyToProjectDialog] = useState(
        false,
    );
    const [open, setOpen] = useState(false);
    const [disconnectOpenState, setDisconnectOpenState] = useState(false);

    useEffect(() => {
        toggleLoading(isLoadingStoreData);
    }, [isLoadingStoreData]);

    const runAction = async (action, params?) => {
        toggleLoading(true);
        return action(params).catch(console.error).finally(toggleLoading);
    };

    const runConnect = async (action, params?) => {
        const connection = { roles: [], ...personProjectConnection };
        connection.roles.push('invitee');
        setPersonProjectConnection(connection);
        return action(params).catch(console.error);
    };

    const canConnect =
        !isAuthenticated || (ableToInvite && activeProject && activeProject.id);

    const handleClose = () => {
        setOpen(false);
    };

    const onConnectClick = () => {
        if (incompleteProject) {
            setOpen(true);
            return;
        }
        if (environment.isRolesEnabled && projectsActiveRoles.length) {
            setOpenApplyToProjectDialog(true);
        } else {
            runConnect(inviteToActiveProject);
        }
    };

    const onConnectedClick = (action) => {
        const connection = { ...personProjectConnection };
        connection.roles = [];
        setPersonProjectConnection(connection);
        return action().catch(console.error);
    };

    return (
        <Box
            className={classNames({
                [classes.root]: true,
            })}
        >
            <Box className={classes.action}>
                <Box className={classes.actionButtonBox}>
                    {isApplicant && isAuthenticated && (
                        <>
                            <Button
                                id={APP_EVENTS.PROJECT_ACCEPT}
                                disabled={loading}
                                className={classes.actionButton}
                                onClick={() => runAction(acceptApplication)}
                            >
                                Accept
                            </Button>

                            <Button
                                id={APP_EVENTS.USER_REJECT}
                                disabled={loading}
                                className={classNames(
                                    classes.actionButton,
                                    classes.rejectButton,
                                )}
                                variant="outlined"
                                onClick={() => runAction(declineApplication)}
                            >
                                Reject
                            </Button>
                        </>
                    )}
                    {isMember && isAuthenticated && (
                        <Button
                            disabled={loading}
                            className={classes.actionButton}
                            onClick={() => runAction(removeProjectMember)}
                        >
                            Remove
                        </Button>
                    )}
                    {canConnect && (
                        <Button
                            id={APP_EVENTS.INVITE_TO_PROJECT}
                            disabled={loading}
                            className={classNames({
                                [classes.actionButton]: true,
                                [classes.connectAction]: true,
                            })}
                            onClick={() => onConnectClick()}
                        >
                            Connect
                        </Button>
                    )}
                    {invited && isAuthenticated && (
                        <Button
                            id={APP_EVENTS.USER_RECALL}
                            disabled={loading}
                            className={classes.actionButton}
                            onClick={() => setDisconnectOpenState(true)}
                        >
                            Connected
                        </Button>
                    )}
                </Box>
            </Box>
            <ApplyToProjectDialog
                isOpen={openApplyToProjectDialog}
                onSubmit={(role) => {
                    setOpenApplyToProjectDialog(false);
                    return runConnect(inviteToActiveProject, role.id);
                }}
                onDialogToggle={() => {
                    setOpenApplyToProjectDialog(false);
                }}
                departments={(projectsActiveRoles || []).map(
                    (role) => role.department,
                )}
                roles={projectsActiveRoles || []}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <RequiredInfoBanner
                        text="Please complete your active project with a Photo and Project Mission."
                        link={`${RouterPaths.myProject}/${activeProject?.id}`}
                        linkText="Complete Project"
                    />
                </DialogContent>
            </Dialog>
            <ConfirmDialog
                callback={() => onConnectedClick(recallInvitation)}
                openState={disconnectOpenState}
                onClose={() => setDisconnectOpenState(false)}
            />
        </Box>
    );
};
