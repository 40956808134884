import {
    Avatar,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import { getPictureUrl } from '@weco/common';
import { defaultPicture, Picture } from '@weco/core';
import {
    ChildrenType,
    ClassNameType,
    OnAnyEventHandler,
    ReactHelpers,
} from '@weco/ui';
import React from 'react';

export type ProjectListItemLinkProps = {
    label?: string;
    className?: ClassNameType;
    onClick?: OnAnyEventHandler;
    children?: ChildrenType;
    picture?: Picture;
};

export const ProjectListItemLink = ({
    label,
    className,
    onClick,
    children,
    picture,
}: ProjectListItemLinkProps) => {
    return (
        <li>
            <Grid container wrap="nowrap">
                <Grid item xs={9}>
                    <ListItem
                        button={true}
                        onClick={onClick}
                        style={{ paddingLeft: 0 }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                style={{ width: 28, height: 28 }}
                                src={getPictureUrl(
                                    picture,
                                    defaultPicture,
                                    true,
                                )}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            classes={{ primary: className }}
                            primary={label}
                        />
                    </ListItem>
                </Grid>
                <Grid
                    item
                    xs={3}
                    container
                    justify="flex-end"
                    alignItems={'center'}
                    style={{ padding: '16px 0' }}
                >
                    {ReactHelpers.renderChildren(children)}
                </Grid>
            </Grid>
        </li>
    );
};
