import React from 'react';
import { ProjectRolesStore } from '../ProjectRolesStore';
import { StoreContainer } from './StoreContainer';
import { EntityLoadingSkeleton, TabLoadingSkeleton } from '@weco/ui';

export interface ProjectRolesContainerProps {
    children: any;
    projectId: string;
    searchPage?: boolean;
}

export const ProjectRolesContainer = ({
    children,
    projectId,
    searchPage,
}: ProjectRolesContainerProps) => {
    const loader = async (store: ProjectRolesStore) => {
        store.projectId = projectId;
        await Promise.all([store.loadDepartments(), store.loadDefaultsRoles()]);
        return store.loadRoles();
    };
    return (
        <StoreContainer
            children={children}
            classType={ProjectRolesStore}
            loader={loader}
            loaderComponent={
                searchPage ? <TabLoadingSkeleton /> : <EntityLoadingSkeleton />
            }
        />
    );
};
