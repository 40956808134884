import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useLeftSideBarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // color: '#ffffff',
            padding: 40,
        },
        buttonBox: {
            textAlign: 'center',
        },
        button: {
            color: '#242D59',
            margin: '7px 0',
            padding: '10px',
            position: 'relative',
            top: '-4px',
        },
        buttonSvg: {
            fontSize: 33,
        },
        topMenu: {
            margin: '0px 0px 17px 0px',
        },
        logoImage: {
            width: '145px',
            height: 'auto',
            top: '6px',
            position: 'relative',
            right: '-5px',
        },
        logoImageBeta: {
            width: '185px',
            height: 'auto',
            top: '6px',
            position: 'relative',
            right: '-5px',
        },
        logoIcon: {
            margin: '0 auto',
            display: 'block',
            padding: '10px',
        },
        buttonLink: {
            paddingTop: 2,
            fontSize: 14,
        },
        name: {
            margin: 30,
            color: theme.palette.secondary.dark,
            '@media (max-width: 1117px)': {
                fontSize: '14px',
            },
            wordBreak: 'break-word',
        },
        moreButton: {
            fontFamily: 'Ubuntu',
        },
        dataToolbar: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingLeft: '0px',
        },
    }),
);

export const useLeftSideBarProjectListStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSvg: {
            fontSize: 33,
        },
        expansionButton: {
            color: '#ddd',
            padding: '0',
            marginRight: '12px',
        },
        moreButton: {
            fontFamily: 'Ubuntu',
        },
        expansionRoot: {
            width: '100%',
            marginTop: 10,
        },
        expansionPanel: {
            background: 'none',
            borderTop: `2px solid ${theme.palette.secondary.main}`,
            borderBottom: `2px solid ${theme.palette.secondary.main}`,
            borderRadius: '0!important',
            borderLeft: 'none',
            borderRight: 'none',
            padding: '0 !important',
            margin: '0 !important',
            boxShadow: 'none',
        },
        projectsText: {
            lineHeight: '3.5rem',
            margin: '0',
        },
        projectList: {
            padding: '0',
            width: '100%',
        },
        projectListRoot: {
            width: '100%',
            padding: '0',
        },
        expansionSvg: {
            fontSize: 38,
            color: '#ddd',
        },
        expansionContent: {
            padding: '0 !important',
            margin: '30 !important',
        },
        addIcon: {
            fontSize: '2.8rem',
            marginLeft: '2px',
            color: '#ddd',
        },
        createProjectText: {
            marginLeft: '8px',
            fontFamily: 'Ubuntu',
            fontSize: '17px',
        },
    }),
);

export const useLeftSideBarListStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSvg: {
            fontSize: 33,
            '&.MuiSvgIcon-root': {
                color: 'transparent',
            },
        },
        list: {
            margin: 0,
            padding: '10px 0',
        },
        listButton: {
            color: '#ddd',
            padding: '0 4px',
            marginRight: '12px',
        },
        addButton: {
            fontSize: '18px',
            marginTop: 30,
            marginBottom: 15,
            padding: 8,
            color: theme.palette.info.main,
            backgroundColor: 'transparent',
            border: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        logoutListItem: {
            borderTop: `2px solid ${theme.palette.secondary.main}`,
            paddingTop: 15,
        },
        listItemText: {
            fontSize: '18px',
            color: theme.palette.text.primary,
            fontWeight: 400,
            marginTop: 0,
            wordBreak: 'break-word',
        },
    }),
);
