import {
    Box,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';

const StyledDrawer = withStyles((theme) => ({
    root: {},
    paper: {
        borderRadius: '20px 20px 0 0',
        [theme.breakpoints.up('md')]: {
            marginLeft: '15%',
            marginRight: '15%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '25%',
            marginRight: '25%',
        },
    },
}))(Drawer);

const useStyles = makeStyles((theme) => ({
    root: {},
    closeIcon: {
        padding: '10px',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '40px',
        height: '40px',
    },
    contentContainer: {
        overflowY: 'scroll',
        height: '650px',
        padding: '40px 30px 30px 30px',
    },
    withoutSpacing: {
        marginTop: 0,
        padding: 30,
    },
    drawerView: {
        overflow: 'hidden',
    },
}));

export const BottomSheet = (props: {
    title?: string;
    isOpen: boolean;
    className?: string;
    onClose: () => void;
    children: JSX.Element;
}) => {
    const cx = useStyles();
    return (
        <StyledDrawer
            anchor="bottom"
            open={props.isOpen}
            onClose={props.onClose}
            className={classNames(props.className, cx.drawerView)}
        >
            <Grid container>
                <Grid item xs={12}>
                    {props.title && (
                        <Box>
                            <Box textAlign="left" m="38px 32px">
                                <Typography variant="h2" component="p">
                                    {props.title}
                                </Typography>
                            </Box>
                            <Divider />
                            <Box textAlign="right">
                                <IconButton
                                    className={cx.closeIcon}
                                    onClick={props.onClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={classNames(cx.contentContainer, {
                        [cx.withoutSpacing]: !props.title,
                    })}
                >
                    {!props.title && (
                        <IconButton
                            className={cx.closeIcon}
                            onClick={props.onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                    {props.children}
                    <Box m={8} />
                </Grid>
            </Grid>
        </StyledDrawer>
    );
};
