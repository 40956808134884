import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const leftDrawerWidth = '420px';
export const rightDrawerWidth = 22;

export const useMainLayoutStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('xl')]: {
                marginLeft: '15%',
                marginRight: '15%',
            },
        },
        contentDesktop: {
            width: '100%',
            minWidth: '300px',
            minHeight: '100vh',
            overflow: 'hidden',
            flexGrow: 1,
            paddingTop: 66,
            paddingBottom: 20,
            boxShadow: 'inset 0px 13px 16px rgba(0, 0, 0, 0.05)',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentMobile: {
            maxWidth: '100%',
            overflow: 'hidden',
            minHeight: '100vh',
            flexGrow: 1,
            paddingTop: 66,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        contentChat: {
            paddingBottom: 0,
        },
    }),
);
