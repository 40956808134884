import React from 'react';
import { makeStyles, Box, SvgIcon, CardMedia } from '@material-ui/core';
import InfinityLoaderBlue from '../assets/infinity-loading-icon-blue.svg';
import InfinityLoaderWhite from '../assets/infinity-loading-icon-white.svg';

const useLoaderStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        width: '100vw',
        height: '100vh',
    },
    backdrop: {
        backgroundColor: '#000000',
        opacity: '0.5',
        zIndex: 1300,
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
    },
    center: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

interface props {
    hideBackdrop?: boolean;
}

export const InfinityLoader = (props: props) => {
    const { hideBackdrop } = props;

    const classes = useLoaderStyles();

    const backdropClass = hideBackdrop ? classes.root : classes.backdrop;

    return (
        <Box className={backdropClass}>
            <Box className={classes.center}>
                <CardMedia src={InfinityLoaderWhite} component="img" />
            </Box>
        </Box>
    );
};
