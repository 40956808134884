import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { ProfileProjectsTabStore } from './ProfileProjectsTabStore';

export const useProfileProjectsData = () => {
    const store = useStore<ProfileProjectsTabStore>(ProfileProjectsTabStore);

    return useObserver(() => ({
        items: store.items,
        loadAllMyProjects: store.loadAllMyProjects,
        currentUserId: store.currentUser?.UserId,
        created: store.created,
        joined: store.joined,
        invited: store.invited,
        applied: store.applied,
        invitedPeople: store.invitedPeople,
        appliedPeople: store.appliedPeople,
    }));
};
