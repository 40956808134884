export const sections = {
    ui: 'ui and layout/',
    inputs: 'inputs/',
    people: 'people/',
    projects: 'projects/',
    schools: 'schools/',
    common: 'common/',
    pages: 'pages/',
    search: 'search/',
    unsorted: 'unsorted/',
    todo: 'TODO/',
    redesignWIP: 'redesign WIP/',
    departmentsAndPositions: 'departments and roles/',
};
