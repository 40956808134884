import {
    COMMON_SERVICES,
    CoreEventDispatcher,
    Event,
    Inject,
    Injectable,
} from '@weco/common';
import { action, observable, runInAction } from 'mobx';
import { CoolEntity } from '../../../../../libs/core/src/lib/entity/CoolEntity';
import { CoolService } from '../../../../../libs/core/src/lib/services/CoolService';
import {
    AddCoolInterface,
    DeleteCoolInputInterface,
    GetCoolsListByEntityIdInputInterface,
    HasCoolInputInterface,
} from '../../../../../libs/core/src/lib/repositories/CoolRepository';
import { APP_EVENTS } from '../../app_events';

@Injectable()
export class CoolsStore {
    @Inject(CoolService)
    private service: CoolService;

    @observable loading = false;

    @observable
    cools: any[];

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    private eventDispatcher: CoreEventDispatcher;

    @action.bound
    async addCool(variables: AddCoolInterface): Promise<CoolEntity> {
        return this.service.addCool(variables).then(async (data) => {
            await this.getCoolsListByEntityId({
                entityType: data.entityType,
                entityId: data.entityId,
            });
            this.eventDispatcher.dispatch(
                APP_EVENTS.COOL_UP,
                new Event(variables),
            );
            return data;
        });
    }

    @action.bound
    async deleteCool(variables: DeleteCoolInputInterface): Promise<CoolEntity> {
        return this.service.deleteCool(variables).then(async (data) => {
            await this.getCoolsListByEntityId({
                entityType: data.entityType,
                entityId: data.entityId,
            });
            this.eventDispatcher.dispatch(
                APP_EVENTS.COOL_DOWN,
                new Event(variables),
            );
            return data;
        });
    }

    @action.bound
    async hasCool(
        variables: HasCoolInputInterface,
    ): Promise<CoolEntity | null> {
        return this.service.hasCool(variables);
    }

    @action.bound
    async getCoolsListByEntityId(
        input: GetCoolsListByEntityIdInputInterface,
    ): Promise<any> {
        const result = await this.service.getCoolsListByEntityId(input);
        runInAction(() => {
            this.cools = result;
            console.log(this.cools);
        });
    }
}
