import {
    Box,
    Card,
    CardContent,
    Theme,
    Typography,
    Grid,
    CardActions,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import classNames from 'classnames';
import { ChildrenType, OnAnyEventHandler } from '../helpers';
import { renderChildren } from '../helpers/renderChildren';
import { SkillSetsEntity } from '@weco/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: '340px',
        minWidth: '230px',
        height: '100%',
    },
    cardRoot: {
        height: '100%',
        padding: '20px',
        background: theme.palette.common.white,
        boxShadow: '0px 13px 16px rgba(0, 0, 0, 0.05)',
        borderRadius: '6px',
    },
    title: {
        color: theme.palette.secondary[700],
        marginBottom: '5px',
    },
    name: {
        color: theme.palette.secondary[500],
        marginTop: '10px',
        marginBottom: '10px',
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export interface RecallActionProps {
    className?: string;
    avatar: ChildrenType;
    handleRecall: OnAnyEventHandler;
    userName?: string;
    projectName?: string;
    connectType?: string;
    skillSet?: SkillSetsEntity;
}

export const RecallAction = React.memo(function RecallActionComponent({
    avatar,
    className,
    userName,
    projectName,
    connectType,
    handleRecall,
    skillSet,
}: RecallActionProps) {
    const classes = useStyles();

    return (
        <Box className={classNames(classes.root, className)}>
            <Card className={classes.cardRoot}>
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid item xs={6}>
                        {renderChildren(avatar)}
                    </Grid>
                </Grid>
                <CardContent>
                    {connectType === 'person' && (
                        <Typography
                            variant="h6"
                            align="center"
                            className={classes.name}
                        >
                            You invited <strong>{userName}</strong> to join
                        </Typography>
                    )}
                    {connectType === 'project' && (
                        <Typography
                            variant="h6"
                            align="center"
                            className={classes.name}
                        >
                            You applied to project -
                        </Typography>
                    )}
                    <Typography
                        variant="h2"
                        className={classes.title}
                        align="center"
                    >
                        {projectName} {skillSet ? `(${skillSet.name})` : ''}
                    </Typography>
                </CardContent>
                <CardActions className={classes.buttonBox}>
                    <Button color="primary" onClick={handleRecall}>
                        Recall{' '}
                        {connectType === 'project'
                            ? 'Application'
                            : 'Invitation'}
                    </Button>
                </CardActions>
            </Card>
        </Box>
    );
});
