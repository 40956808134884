import React from 'react'
import { ScrollOnTop } from "@weco/ui";

export const TermsPage = () => {
    return (
        <>
            <ScrollOnTop />
            <h1>TERMS OF USE OF WECO MOBILE APP</h1>

            <h2> 1. TERMS OF USE </h2>
            By downloading, browsing, accessing or using the WeCo mobile application (“Mobile Application”), you agree
            to be bound by these Terms and Conditions of Use. We ultimately reserve the right to amend, change or modify
            these terms and conditions. If you do not agree with any of these Terms of Use, you must immediately
            discontinue your use to the Mobile Application. Any continued use of the WeCo Mobile Application will
            constitute acceptance of these Terms of Use, as may be amended from time to time.

            <h2>2. DEFINITIONS</h2>
            In these Terms of Use, the following capitalised terms shall have the following meanings, except where the
            context otherwise requires:<br/><br/>
            "Account" means an account created by a User on the WeCo Mobile Application as part of
            Registration.<br/><br/>
            "Merchant" refers to any entity whose products or Services can be purchased and/or redeemed (as the case may
            be) via the WeCo Mobile Application Marketplace.<br/><br/>
            "Privacy Policy" means the privacy policy set out in Clause 14 of these Terms of Use.<br/><br/>
            "Redeem" means to redeem a Merchant’s products or Services on these Terms of Use and<br/><br/>
            "Redemption" means the act of redeeming such products or Services.<br/><br/>
            "Register" means to create an Account on the WeCo Mobile Application and "Registration" means the act of
            creating such an Account.<br/><br/>
            "Services" means all the services provided by via the Mobile Application to Users by Merchants or Members,
            and "Service" means any one of them,<br/><br/>
            "Users" means users of the WeCo Mobile Application, including you and "User" means any one of
            them.<br/><br/>

            <h2>3. GENERAL ISSUES ABOUT THE WECO MOBILE APPLICATION AND THE SERVICES</h2>

            3.1 Applicability of terms and conditions: The use of any Services and/or the Mobile Application are subject
            to these Terms and Conditions of Use.<br/><br/>

            3.2 Location: The WeCo Mobile Application, the Services are intended solely for use by Users who access the
            Mobile Application. We make no representation that the Services (or any goods or services) are available or
            otherwise suitable to all locations. Notwithstanding the above, if you access the WeCo Mobile Application,
            use the Services, you do so on your own initiative and are responsible for the consequences and for
            compliance with all applicable laws.<br/><br/>

            3.3 Scope: The WeCo Mobile Application, the Services and are for your personal or project use
            only.<br/><br/>
            3.4 Prevention on use: We reserve the right to prevent you using the Mobile Application and the Service (or
            any part of them) and to prevent you from making any false or misleading statements.<br/><br/>

            3.5 Equipment and Networks: You acknowledge that the terms of agreement with your respective mobile network
            provider ("Mobile Provider") will continue to apply when using the Mobile Application. As a result, you may
            be charged by the Mobile Provider for access to network connection services for the duration of the
            connection while accessing the Mobile Application or any such third party charges as may arise. You accept
            responsibility for any such charges that arise.<br/><br/>

            3.6 Permission to use Mobile Application: If you are not the bill payer for the mobile telephone or handheld
            device being used to access the Mobile Application, you will be assumed to have received permission from the
            bill payer for using the Mobile Application.<br/><br/>

            3.7 License to Use Material: By submitting any text or images (including photographs) (“Material”) via the
            Application, you represent that you are the owner of the Material, or have proper authorization from the
            owner of the Material to use, reproduce and distribute it. You hereby grant to WeCo a worldwide,
            royalty-free, non-exclusive license to use the Material to promote any products or services.<br/><br/>

            <h2>4. WECO PROJECTS</h2>

            4.1 Need for registration: You must Register to create Projects or join Projects from the Mobile
            Application.<br/><br/>

            4.2 Application of these Terms and Conditions of Use: By Creating any Projects, you acknowledge that the
            Project is subject to these Terms and Conditions of Use.<br/><br/>

            4.3 Project: Any attempted creation of a Project not consistent with these Terms and Conditions of Use may
            be disallowed and removed.<br/><br/>

            <h2>5. LOCATION ALERTS AND NOTIFICATIONS</h2>

            5.1 You agree to receive pre-programmed notifications (“Location Alerts”) on the Mobile Application from
            Project creators, Merchants or Members if you have turned on locational services on your mobile telephone or
            other handheld devices (as the case may be).<br/><br/>

            <h2>6. MEMBER OBLIGATIONS</h2>

            6.1 Member terms: You agree to (and shall) abide by the terms and conditions as may be amended from time to
            time.<br/><br/>

            6.2 Accurate information: You warrant that all information provided on Registration and contained as part of
            your Account is true, complete and accurate and that you will promptly inform us of any changes to such
            information by updating the information in your Account.<br/><br/>

            6.3 Content on the Mobile Application and Service: It is your responsibility to ensure that any Products,
            Services, Compensation or Housing or other information available through the Mobile Application or the
            Services meet specific requirements before offering such.<br/><br/>

            6.4 Prohibitions in relation to usage of Services or Mobile Application: Without limitation, you undertake
            not to use or permit anyone else to use the Services or Mobile Application:<br/><br/>

            6.4.1 to send or receive any material which is not civil or tasteful<br/><br/>

            6.4.2 to send or receive any material which is threatening, grossly offensive, of an indecent, obscene or
            menacing character, blasphemous or defamatory of any person, in contempt of court or in breach of
            confidence, copyright, rights of personality, publicity or privacy or any other third party
            rights;<br/><br/>

            6.4.3 to send or receive any material for which you have not obtained all necessary licences and/or
            approvals (from us or third parties); or which constitutes or encourages conduct that would be considered a
            criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the
            rights of any third party in any country in the world;<br/><br/>

            6.4.4 to send or receive any material which is technically harmful (including computer viruses, logic bombs,
            Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful
            data);<br/><br/>

            6.4.5 to cause annoyance, inconvenience or needless anxiety;<br/><br/>

            6.4.6 to intercept or attempt to intercept any communications transmitted by way of a telecommunications
            system;<br/><br/>

            6.4.7 for a purpose other than which we have designed them or intended them to be used;<br/><br/>

            6.4.8 for any fraudulent purpose;<br/><br/>

            6.4.9 other than in conformance with accepted Internet and Mobile practices and practices of any connected
            networks;<br/><br/>

            6.4.10 in any way which is calculated to incite hatred against any ethnic, religious or any other minority
            or is otherwise calculated to adversely affect any individual, group or entity; or<br/><br/>

            6.4.11 in such a way as to, or commit any act that would or does, impose an unreasonable or
            disproportionately large load on our infrastructure.<br/><br/>

            6.5 Prohibitions in relation to usage of Services, Mobile Application: Without limitation, you further
            undertake not to or permit anyone else to:<br/><br/>

            6.5.1 resell any unusable products in our Marketplace or;<br/><br/>

            6.5.2 furnish false data including false names, addresses and contact details and fraudulently use
            credit/debit card numbers;<br/><br/>

            6.5.3 attempt to circumvent our security or network including to access data not intended for you, log into
            a server or account you are not expressly authorised to access, or probe the security of other networks
            (such as running a port scan);<br/><br/>

            6.5.4 execute any form of network monitoring which will intercept data not intended for you;<br/><br/>

            6.5.5 enter into fraudulent interactions or transactions with us or a Merchant (including interacting or
            transacting purportedly on behalf of a third party where you have no authority to bind that third party or
            you are pretending to be a third party);<br/><br/>

            6.5.6 extract data from or hack into the Mobile Application;<br/><br/>

            6.5.7 use the Services or Mobile Application in breach of these Terms and Conditions of Use;<br/><br/>

            6.5.8 engage in any unlawful activity in connection with the use of the Mobile Application or the Services;
            or<br/><br/>

            6.5.9 engage in any conduct which, in our exclusive reasonable opinion, restricts or inhibits any other
            customer from properly using or enjoying the Mobile Application or Services.<br/><br/>


            <h2>7. RULES ABOUT USE OF THE SERVICE AND THE MOBILE APPLICATION</h2>

            7.1 We will use reasonable endeavours to correct any errors or omissions as soon as practicable after being
            notified of them. However, we do not guarantee that the Services or the Mobile Application will be free of
            faults, and we do not accept liability for any such faults, errors or omissions. In the event of any such
            error, fault or omission, you should report it by contacting us at wecohelp.com<br/><br/>

            7.2 We do not warrant that your use of the Services or the Mobile Application will be uninterrupted and we
            do not warrant that any information (or messages) transmitted via the Services or the Mobile Application
            will be transmitted accurately, reliably, in a timely manner or at all. Notwithstanding that we will try to
            allow uninterrupted access to the Services and the Mobile Application, access to the Services and the Mobile
            Application may be suspended, restricted or terminated at any time.<br/><br/>

            7.3 We do not give any warranty that the Services and the Mobile Application are free from viruses or
            anything else which may have a harmful effect on any technology.<br/><br/>


            7.4 We reserve the right to change, modify, substitute, suspend or remove without notice any information or
            Services on the Mobile Application from time to time. Your access to the Mobile Application and/or the
            Services may also be occasionally restricted to allow for repairs, maintenance or the introduction of new
            facilities or services. We will attempt to restore such access as soon as we reasonably can. For the
            avoidance of doubt, we reserve the right to withdraw any information or Services from the Mobile Application
            at any time.<br/><br/>
            7.5 We reserve the right to block access to and/or to edit or remove any material which in our reasonable
            opinion may give rise to a breach of these Terms and Conditions of Use.<br/><br/>

            <h2>8. SUSPENSION AND TERMINATION</h2>

            8.1 If you use (or anyone other than you, with your permission uses) the Mobile Application, any Services in
            contravention of these Terms and Conditions of Use, we may suspend your use of the Services and/or Mobile
            Application.<br/><br/>

            8.2 If we suspend the Services or Mobile Application, we may refuse to restore the Services or Mobile
            Application for your use until we receive an assurance from you, in a form we deem acceptable, that there
            will be no further breach of the provisions of these Terms and Conditions of Use.<br/><br/>

            8.3 WeCo shall fully co-operate with any law enforcement authorities or court order requesting or directing
            us to disclose the identity or locate anyone in breach of these Terms and Conditions of Use.<br/><br/>

            8.4 Without limitation to anything else in this Clause 8, we shall be entitled immediately or at any time
            (in whole or in part) to: (a) suspend the Services and/or Mobile Application; (b) suspend your use of the
            Services and/or Mobile Application; and/or (c) suspend the use of the Services and/or Mobile Application for
            persons we believe to be connected (in whatever manner) to you, if:<br/><br/>

            8.4.1 you commit any breach of these Terms and Conditions of Use;<br/><br/>

            8.4.2 we suspect, on reasonable grounds, that you have, might or will commit a breach of these Terms and
            Conditions of Use; or<br/><br/>

            8.4.3 we suspect, on reasonable grounds, that you may have committed or be committing any fraud against us
            or any person.<br/><br/>

            8.5 Our rights under this Clause 8 shall not prejudice any other right or remedy we may have in respect of
            any breach or any rights, obligations or liabilities accrued prior to termination.<br/><br/>

            <h2>9. DISCLAIMER AND EXCLUSION OF LIABILITY</h2>

            9.1 The Mobile Application, the Services, the information on the Mobile Application and use of all related
            facilities are provided on an "as is, as available" basis without any warranties whether express or implied.<br/><br/>

            9.2 To the fullest extent permitted by applicable law, we disclaim all representations and warranties
            relating to the Mobile Application and its contents, including in relation to any inaccuracies or omissions
            in the Mobile Application, warranties of merchantability, quality, fitness for a particular purpose,
            accuracy, availability, non-infringement or implied warranties from course of dealing or usage of
            trade.<br/><br/>

            9.3 We do not warrant that the Mobile Application will always be accessible, uninterrupted, timely, secure,
            error free or free from computer virus or other invasive or damaging code or that the Mobile Application
            will not be affected by any acts of God or other force majeure events, including inability to obtain or
            shortage of necessary materials, equipment facilities, power or telecommunications, lack of
            telecommunications equipment or facilities and failure of information technology or telecommunications
            equipment or facilities.<br/><br/>

            9.4 While we may use reasonable efforts to include accurate and up-to-date information on the Mobile
            Application, we make no warranties or representations as to its accuracy, timeliness or
            completeness.<br/><br/>

            9.5 We shall not be liable for any acts or omissions of any third parties howsoever caused, and for any
            direct, indirect, incidental, special, consequential or punitive damages, howsoever caused, resulting from
            or in connection with the mobile application and the services offered in the mobile application, your access
            to, use of or inability to use the mobile application or the services offered in the mobile application,
            reliance on or downloading from the mobile application and/or services, or any delays, inaccuracies in the
            information or in its transmission including but not limited to damages for loss of business or profits,
            use, data or other intangible, even if we have been advised of the possibility of such damages.<br/><br/>

            9.6 We shall not be liable in contract, tort (including negligence or breach of statutory duty) or otherwise
            whatever the cause thereof, for any indirect, consequential, collateral, special or incidental loss or
            damage suffered or incurred by you in connection with the Mobile Application and these Terms and Conditions
            of Use. For the purposes of these Terms and Conditions of Use, indirect or consequential loss or damage
            includes, without limitation, loss of revenue, profits, anticipated savings or business, loss of data or
            goodwill, loss of use or value of any equipment including software, claims of third parties, and all
            associated and incidental costs and expenses.<br/><br/>

            9.7 The above exclusions and limitations apply only to the extent permitted by law. None of your statutory
            rights as a consumer that cannot be excluded or limited are affected.<br/><br/>

            9.8 Notwithstanding our efforts to ensure that our system is secure, you acknowledge that all electronic
            data transfers are potentially susceptible to interception by others. We cannot, and do not, warrant that
            data transfers pursuant to the Mobile Application, or electronic mail transmitted to and from us, will not
            be monitored or read by others.<br/><br/>

            <h2>10. INDEMNITY</h2>

            You agree to indemnify and keep us indemnified against any claim, action, suit or proceeding brought or
            threatened to be brought against us which is caused by or arising out of (a) your use of the Services, (b)
            any other party’s use of the Services using your user ID, verification PIN and/or any identifier number
            allocated by WeCo, and/or (c) your breach of any of these Terms and Conditions of Use, and to pay us
            damages, costs and interest in connection with such claim, defense, action, suit or proceeding.<br/><br/>

            <h2>11. INTELLECTUAL PROPERTY RIGHTS</h2>

            11.1 All editorial content, information, photographs, illustrations, artwork and other graphic materials,
            and names, logos and trade marks on the Mobile Application are protected by copyright laws and/or other laws
            and/or international treaties, and belong to us and/or our suppliers, Members or Merchants as the case may
            be. These works, logos, graphics, sounds or images may not be copied, reproduced, retransmitted,
            distributed, disseminated, sold, published, broadcasted or circulated whether in whole or in part, unless
            expressly permitted by us and/or our suppliers, as the case may be.<br/><br/>

            11.2 Nothing contained on the Mobile Application should be construed as granting by implication, estoppel,
            or otherwise, any license or right to use any trademark displayed on the Mobile Application without our
            written permission. Misuse of any trademarks or any other content displayed on the Mobile Application is
            prohibited.<br/><br/>

            11.3 We will not hesitate to take legal action against any unauthorized usage of our trade- marks, name or
            symbols to preserve and protect its rights in the matter. All rights not expressly granted herein are
            reserved. Other product and company names mentioned herein may also be the trade-marks of their respective
            owners.<br/><br/>

            <h2>12. AMENDMENTS</h2>

            12.1 We may periodically make changes to the contents of the Mobile Application, including to the
            descriptions and prices of goods and services advertised, at any time and without notice. We assume no
            liability or responsibility for any errors or omissions in the content of the Mobile Application.<br/><br/>

            12.2 We reserve the right to amend these Terms and Conditions of Use from time to time without notice. The
            revised Terms and Conditions of Use will be posted on the Mobile Application and shall take effect from the
            date of such posting. You are advised to review these terms and conditions periodically as they are binding
            upon you.<br/><br/>

            <h2>13. APPLICABLE LAW AND JURISDICTION</h2>

            13.1 The Mobile Application can be accessed from all countries around the world where the local technology
            permits. As each of these places have differing laws, by accessing the Mobile Application both you and we
            agree that the laws of the US specifically in the State of Florida, without regard to the conflicts of laws
            principles thereof, will apply to all matters relating to the use of the Mobile Application.<br/><br/>

            13.2 You accept and agree that both you and we shall submit to the exclusive jurisdiction of the courts of
            the United States, State of Florida in respect of any dispute arising out of and/or in connection with these
            Terms and Conditions of Use.<br/><br/>


            <h2>14. PRIVACY POLICY</h2>

            14.1 Access to the Mobile Application and use of the Services offered on the Mobile Application by WeCo is
            subject to this Privacy Policy. By accessing the Mobile Application and by continuing to use the Services
            offered, you are deemed to have accepted this Privacy Policy, and in particular, you are deemed to have
            consented to our use and disclosure of your personal information in the manner prescribed in this Privacy
            Policy. We reserve the right to amend this Privacy Policy from time to time. If you disagree with any part
            of this Privacy Policy, you must immediately discontinue your access to the Mobile Application and your use
            of the Services.<br/><br/>

            14.2 As part of the normal operation of our Services, we collect, use and, in some cases, disclose
            information about you to third parties. Accordingly, we have developed this Privacy Policy in order for you
            to understand how we collect, use, communicate and disclose and make use of your personal information when
            you use the Services on the Mobile Application:<br/><br/>

            (a) Before or at the time of collecting personal information, we will identify the purposes for which
            information is being collected.<br/><br/>
            (b) We will collect and use of personal information solely with the objective of fulfilling those purposes
            specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned
            or as required by law.<br/><br/>
            (c) We will only retain personal information as long as necessary for the fulfillment of those
            purposes.<br/><br/>
            (d) We will collect personal information by lawful and fair means and, where appropriate, with the knowledge
            or consent of the individual concerned.<br/><br/>
            (e) Personal information should be relevant to the purposes for which it is to be used, and, to the extent
            necessary for those purposes, should be accurate, complete, and up-to-date.<br/><br/>
            (f) We will protect personal information by reasonable security safeguards against loss or theft, as well as
            unauthorized access, disclosure, copying, use or modification.<br/><br/>
            (g) We are committed to conducting our business in accordance with these principles in order to ensure that
            the confidentiality of personal information is protected and maintained.<br/><br/>
        </>
    );
};

TermsPage.URL = '/terms';
