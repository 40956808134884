import { Field, Formik } from 'formik';
import { FormGroup } from '@material-ui/core';
import React from 'react';
import { WeChipsInput, SectionWithTitle } from '@weco/ui';

export interface DynamicChipsFormControlProps {
    name?: string;
    label?: string;
    helpIcon?: {};
    lowerCase?: boolean;
    isOpenField?: boolean;
}

export const DynamicChipsFormControl = ({
    name,
    label,
    helpIcon,
    lowerCase,
    isOpenField,
}: DynamicChipsFormControlProps) => {
    return (
        <FormGroup>
            <SectionWithTitle title={label} helpIcon={helpIcon}>
                <Field
                    label={label}
                    name={name}
                    component={WeChipsInput}
                    lowerCase={lowerCase}
                    freeSolo
                    {...{ isOpenField }}
                />
            </SectionWithTitle>
        </FormGroup>
    );
};
