import { gql } from 'apollo-boost';
import { Transformers, Injectable } from '@weco/common';
import { TestResultEntity } from '../entity';
import { GraphQlBaseRepository } from './GraphQlBaseRepository';

import { listPassedTestResults } from './graphql/queries';

export interface PassedTestFilter {
    ownerId?: string;
    status?: string;
}

export interface PassedTestRepositoryInterface {
    loadPassedTests(filter?: PassedTestFilter): Promise<TestResultEntity[]>;
}
@Injectable()
export class PassedTestRepository extends GraphQlBaseRepository
    implements PassedTestRepositoryInterface {
    public async loadPassedTests(
        filter?: PassedTestFilter,
    ): Promise<TestResultEntity[]> {
        const buildQueryFilter = (filter: PassedTestFilter) => {
            if (!filter || !filter.ownerId) return {};

            const result = {
                filter: {},
            };

            if (filter.ownerId) {
                result.filter['ownerId'] = { eq: filter.ownerId };
            }

            if (filter.status) {
                result.filter['status'] = { eq: filter.status };
            }

            return result;
        };

        return this.unAuthorizedClient
            .query({
                query: gql(listPassedTestResults),
                fetchPolicy: 'no-cache',
                variables: {
                    ...buildQueryFilter({
                        ownerId: this.currentUserProvider.UserId,
                        ...filter,
                    }),
                },
            })
            .then((data) => {
                const result = data.data.listPassedTestResults.items;
                return result;
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<TestResultEntity>(
                            TestResultEntity,
                        ),
                    ),
                ),
            );
    }
}
