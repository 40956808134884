import { observable, action } from 'mobx';
import { Injectable } from '@weco/common';
import xor from 'lodash/xor';
import { SchoolEntity, AccountTypes } from '@weco/core';
/**
 * This store is needed because onboarding
 * is basically a multipage form.
 * And most reliable way to store form data
 * across multiple pages is to place it inside a store.
 */
@Injectable()
export default class OnboardingStore {
    @observable selectedSchool: SchoolEntity = null;
    @observable selectedCauses = [];
    @observable selectedPassions = [];
    @observable selectedAccountType?: AccountTypes;
    @observable selectedGraduationYear?: number;

    @action reset() {
        this.selectedCauses = [];
        this.selectedPassions = [];
        this.selectedSchool = undefined;
        this.selectedAccountType = undefined;
        this.selectedGraduationYear = undefined;
    }

    @action toggleSelectedPassion = (passion) => {
        this.selectedPassions = xor(this.selectedPassions, [passion]);
    };

    @action toggleSelectedCause = (matter) => {
        this.selectedCauses = xor(this.selectedCauses, [matter]);
    };
}
