import React from 'react';
import { Button, Theme, Typography, Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';

export type NotEnoughCredentialsProps = {
    to?: string;
    label?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 300,
    },
    label: {
        color: theme.palette.common.white,
        margin: 40,
        marginTop: 120,
        fontWeight: 400,
    },
    button: {
        width: '300px',
        height: '48px',
        borderRadius: '24px',
        fontSize: '18px',
        color: theme.palette.primary.main,
        margin: 0,
        marginTop: 70,
        fontWeight: 500,
    },
}));

export const NotEnoughCredentials = ({
    label,
    to,
}: NotEnoughCredentialsProps) => {
    const classes = useStyles();
    const { push } = useHistory();

    function goTo(path) {
        return () => push(path);
    }

    return (
        <Box className={classes.root}>
            <Typography variant="h2" align="center" className={classes.label}>
                You need to have a WeCo account.
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={goTo(to || '/')}
            >
                {label || 'Home'}
            </Button>
        </Box>
    );
};
