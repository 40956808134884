export const WeInfo = {
    50: '#F2F0FF',
    100: '#E3E1FF',
    200: '#C7C3FF',
    300: '#ABA4FF',
    400: '#8F86FF',
    500: '#7368FF',
    600: '#564EBF',
    700: '#3A3480',
    800: '#1D1A40',
    900: '#0C0A1A',
    A100: '#E3E1FF',
    A200: '#ABA4FF',
    A400: '#7368FF',
    A700: '#1D1A40',
};
