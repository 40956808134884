type ValidationResult = string | undefined;

export class FormikValidators {
    static validateShortString(text = ''): ValidationResult {
        if (!text.trim()) {
            return 'Required';
        }
        if (text.trim().length > 50) {
            return 'Too many characters';
        }
    }

    static validateEmail(email: string): ValidationResult {
        // https://stackoverflow.com/a/46181/4380989
        const isProperEmailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email.trim()) {
            return 'Required';
        } else if (!isProperEmailRegExp.test(String(email).toLowerCase())) {
            return 'Invalid email address';
        }
    }

    static validatePassword(password: string): ValidationResult {
        const minCharacters = 8;

        if (password.includes(' ')) {
            return 'Whitespaces are not allowed';
        } else if (!password.trim()) {
            return 'Required';
        } else if (password.trim().length < minCharacters) {
            return 'Password is too short';
        }
    }
}
