import React from 'react';
import { WeChipsList } from '../molecules';
import { FieldInputProps, FormikProps } from 'formik';
import debug from 'debug';
import { WeChipItem } from '../molecules/WeChip';

const log = debug('WeChipsSelect');

export interface WeChipsSelectProps {
    options: WeChipItem[];
    onChange?: (data: any) => void;
    onBlur?: (data: any) => void;
    name?: string;
    value?: any[];
    field?: FieldInputProps<any>;
    form?: FormikProps<any>;
}

export const WeChipsSelect = ({
    options,
    onChange,
    onBlur,
    name,
    value,
    form,
    field,
}: WeChipsSelectProps) => {
    function doChange(v) {
        if (!onChange && !form && !field) {
            return;
        }

        const curName = name || field?.name;
        const curValue = [].concat(value || field?.value).filter(Boolean);
        const curIndex = curValue.indexOf(v.value);
        const callOnChange =
            !onChange && curName
                ? (data) => form.setFieldValue(curName, data)
                : onChange;
        if (curIndex === -1) {
            callOnChange([].concat(curValue, v.value));
        } else {
            curValue.splice(curIndex, 1);
            callOnChange(curValue);
        }
        log('curName: ', curName);
        log('curValue: ', curValue);
        log('curIndex: ', curIndex);
    }

    return (
        <WeChipsList
            onClick={doChange}
            items={(options || []).reduce((acc, item) => {
                const current = []
                    .concat(value || field?.value)
                    .find((v) => v === item.value || v === item);
                acc.push({
                    ...item,
                    isActive: !!current,
                });
                return acc;
            }, [])}
        />
    );
};
