import {
    AuthAnalyticsEvent,
    EventSubscriberInterface,
    Inject,
    Injectable,
} from '@weco/common';
import { APP_EVENTS } from '../../../app_events';
import { MyUserStore } from '../../store/MyUserStore';
import di from '../di';
import { AuthEvent } from '../../events/AuthEvent';
import { SERVICES } from '../services';

@Injectable()
export class ApplicationEventSubscriber implements EventSubscriberInterface {
    subscribedEvents: Map<string | RegExp, (event: any) => void> = new Map();
    @Inject(SERVICES.Sentry)
    sentry: any;

    constructor() {
        this.subscribedEvents.set(
            APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
            this.cleanup,
        );
        this.subscribedEvents.set(
            APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
            this.identify,
        );
    }

    cleanup = async (event: AuthEvent) => {
        const store = di.get<MyUserStore>(MyUserStore);
        if (!store) {
            return;
        }
        if (
            event.subject.isAuthenticated() &&
            event.subject.getUser().id !== store.profile?.id
        ) {
            await store.cleanup();
            await store.load();
        }
    };

    private identify = (event: AuthEvent) => {
        if (event.subject.isAuthenticated()) {
            this.sentry.configureScope((scope) => {
                scope.setUser({
                    id: event.subject.getUser().id,
                    email:
                        event.subject.getUser().email ||
                        event.subject.getUsername(),
                    name: event.subject.getUsername(),
                });
            });
        }
    };
}
