import { dispatch } from 'rxjs/internal/observable/pairs';
import _ from 'lodash';
import { TMP_ROOM_ID } from './../../utils/constants';
import * as actions from './actionCreators';
import { receiveListUser } from '../users/actionCreators';
import firebase from 'firebase/app';
import { UserRoomInterface } from '../../models/interfaces/UserRoomInterface';
import {
    RoomInterface,
    TmpRoomInterface,
} from '../../models/interfaces/RoomInterface';
import {
    fetchUser,
    updateUserRooms as userUpdateUserRooms,
} from '../users/actions';
import MessageInterface from '../../models/interfaces/MessageInterface';
import UserInterface from '../../models/interfaces/UserInterface';
import { refToRefData, timestampToString } from '../../models/ReferenceData';
import { CreateRoomVariablesInterface } from '../../models/interfaces/CreateRoomVariablesInterface';
import { CreateRoomInterface } from '../../models/interfaces/CreateRoomInterface';
import { subscribeRoomMessages } from '../common/actions';
import {
    setUnSubscription,
    deleteUnSubscription,
    existsUnSubscriptions,
    getUnSubscription,
} from './unSubscriptions';
import { UserProfileEntity } from '@weco/core';
import {
    deleteRoomMessages,
    sendRoomMessages,
} from '../roomMessages/actionCreators';
import { sendMessageToFirestore } from '../roomMessages/actions';

const roomFromDocument = (
    doc: firebase.firestore.QueryDocumentSnapshot<any>,
): RoomInterface =>
    doc.exists
        ? {
              ...doc.data(),
              id: doc.id,
              members: doc.data().members
                  ? doc
                        .data()
                        .members.map((member: any) => refToRefData(member))
                  : [],
              owner: refToRefData(doc.data().owner),
              lastMessageTime: timestampToString(doc.data().lastMessageTime),
              lastMessageTimeTime: doc
                  .data()
                  .lastMessageTime.toDate()
                  .getTime(),
          }
        : {};

export const fetchRooms = (
    firestore: firebase.firestore.Firestore,
    userRooms: UserRoomInterface[],
) => (dispatch: Function) => {
    dispatch(actions.setDataLoading(true));
    dispatch(actions.requestRooms(userRooms));
    const promises = userRooms.map(
        (userRoom: UserRoomInterface) =>
            new Promise((resolve: Function) => {
                const unsubscribe = firestore
                    .collection('rooms')
                    .doc(userRoom.room.id)
                    .onSnapshot(
                        (
                            doc: firebase.firestore.DocumentSnapshot<
                                RoomInterface
                            >,
                        ) => {
                            if (doc.exists) {
                                dispatch(
                                    actions.receiveRoom(roomFromDocument(doc)),
                                );
                            } else {
                                dispatch(
                                    actions.receiveRoomDeleted(
                                        roomFromDocument(doc),
                                    ),
                                );
                            }
                            if (!existsUnSubscriptions(doc.id)) {
                                setUnSubscription(doc.id, unsubscribe);
                            }
                            resolve({ ...roomFromDocument(doc), unsubscribe });
                        },
                    );
            }),
    );

    // Small optimisation for user fetching
    return Promise.all(promises).then((rooms: RoomInterface[]) => {
        const users: any = {};
        rooms.forEach((room: RoomInterface) => {
            (room.members || []).forEach(
                (member: firebase.firestore.DocumentReference) => {
                    users[member.id] = true;
                },
            );
        });
        Object.keys(users).forEach((id: string) => {
            dispatch(fetchUser(firestore, id));
        });
        dispatch(actions.receiveRooms());
        dispatch(actions.setDataLoading(false));
    });
};

export const setActiveRoom = (
    firestore: firebase.firestore.Firestore,
    roomId: string,
    subscribe: boolean,
) => (dispatch: Function) => {
    dispatch(actions.setActiveRoom(roomId));
    if (subscribe) {
        dispatch(subscribeRoomMessages(firestore, roomId));
    }
};

export const setTmpRoom = (tmpRoom: TmpRoomInterface) => (
    dispatch: Function,
) => {
    setUnSubscription(TMP_ROOM_ID, tmpRoom.room.unsubscribe);
    dispatch(receiveListUser(tmpRoom.user));
    dispatch(
        actions.setTmpRoom(
            _.omit(tmpRoom.room, ['unsubscribe']) as RoomInterface,
        ),
    );
};

export const replaceTmpRoomToExists = (
    firestore: firebase.firestore.Firestore,
    rooms: { [key: string]: RoomInterface },
    roomsMessages: { [key: string]: MessageInterface[] },
    roomId: string,
) => (dispatch: Function) => {
    dispatch(setActiveRoom(firestore, roomId, true));
    if (roomsMessages[TMP_ROOM_ID]) {
        roomsMessages[TMP_ROOM_ID].forEach((message: MessageInterface) => {
            dispatch(sendRoomMessages(roomId, message));
            sendMessageToFirestore(dispatch, firestore, roomId, {
                createdAt: firebase.firestore.Timestamp.fromMillis(
                    message.createdAtTime,
                ),
                message: message.message,
                owner: firestore.collection('users').doc(message.owner.id),
            });
        });
    }
};

export const updateRoomLastMessage = (
    roomId: string,
    message: MessageInterface,
) => (dispatch: Function) => {
    dispatch(actions.updateRoomLastMessage(roomId, message));
};

export const updateUserRooms = (
    firestore: firebase.firestore.Firestore,
    userId: string,
    rooms: UserRoomInterface[],
) => (dispatch: Function) => {
    firestore
        .collection('users')
        .doc(userId)
        .update({
            rooms,
        })
        .then();
};

export const renameRoom = (
    firestore: firebase.firestore.Firestore,
    roomId: string,
    name: string,
) => (dispatch: Function) => {
    firestore
        .collection('rooms')
        .doc(roomId)
        .update({
            name,
        })
        .then();
};

export const deleteRoom = (
    firestore: firebase.firestore.Firestore,
    roomId: string,
) => (dispatch: Function) => {
    dispatch(actions.receiveRoomDeleted({ id: roomId }));
    dispatch(deleteRoomMessages(roomId));
    if (roomId !== TMP_ROOM_ID) {
        firestore.collection('rooms').doc(roomId).delete().then();
    }
};

export const leaveRoom = (
    firestore: firebase.firestore.Firestore,
    user: UserInterface,
    room: RoomInterface,
) => (dispatch: Function) => {
    if (existsUnSubscriptions(room.id)) {
        getUnSubscription(room.id)();
        deleteUnSubscription(room.id);
    }
    dispatch(
        userUpdateUserRooms(
            user.id,
            user.rooms.filter(
                (userRoom: UserRoomInterface) => userRoom.room.id !== room.id,
            ),
        ),
    );
    dispatch(actions.receiveRoomDeleted({ id: room.id }));
    firestore
        .collection('users')
        .doc(user.id)
        .update({
            rooms: user.rooms.filter(
                (userRoom: UserRoomInterface) => userRoom.room.id !== room.id,
            ),
        })
        .then();
};

export const setFilterType = (filterType: string) => (dispatch: Function) => {
    dispatch(actions.setFilterType(filterType));
};

export const setDataLoading = (loading: boolean) => (dispatch: Function) => {
    dispatch(actions.setDataLoading(loading));
};

export const createPeopleRoom = (
    firestore: firebase.firestore.Firestore,
    createRoom: CreateRoomInterface,
    input: CreateRoomVariablesInterface,
    currentUserId: string,
    profile: UserProfileEntity,
) => (dispatch: Function) => {
    dispatch(actions.startRoomCreation());
    createRoom(input, currentUserId, profile)
        .then((roomId: string) => {
            setTimeout(() => {
                dispatch(actions.setActiveRoom(roomId));
                dispatch(subscribeRoomMessages(firestore, roomId));
            }, 1000);
        })
        .catch((err: Error) => {
            console.error(err);
        });
};
