import chatRootReducer from './lib/store/reducer';
import { ChatContextProvider } from './lib/providers/ChatContextProvider';
import { ChatInformerContainer } from './lib/containers/ChatInformerContainer';
import authUserDefaultState from './lib/store/authUser/defaultState';
import usersDefaultState from './lib/store/users/defaultState';
import roomsDefaultState from './lib/store/rooms/defaultState';
import roomMessagesDefaultState from './lib/store/roomMessages/defaultState';
import * as widgets from './lib/models/widgets';

export {
    chatRootReducer,
    ChatContextProvider,
    ChatInformerContainer,
    authUserDefaultState,
    usersDefaultState,
    roomsDefaultState,
    roomMessagesDefaultState,
    widgets,
};
export * from './lib/utils/constants';
export * from './lib/store/actionTypes';
export * from './lib/store/rooms/unSubscriptions';
export * from './lib/models/interfaces';

// TODO: do we need to export everything from 'lib'?
export * from './lib';
