import React from 'react';
import {
    goTo,
    Hidden,
    SectionWithTitle,
    WeChipsList,
    WeFundingList,
} from '@weco/ui';
import { Box, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { usePersonProfileData } from './store/usePersonProfileData';
import { useMyUserProjectsData } from '../../../store/hooks/useMyUserProjectsData';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import { objectToTypeValue, toChipItem } from '../../../helpers/utils';

export const ResourcesTabPage = () => {
    const { profile } = usePersonProfileData();
    const { activeProject } = useMyUserProjectsData();
    const classes = useTabStyles();
    const showEmptyPlaceHolder =
        isEmpty(profile.compensations) &&
        isEmpty(profile.investments) &&
        isEmpty(profile.fundings);

    return (
        <Box className={classes.tabContent}>
            <Grid container>
                <Hidden when={!showEmptyPlaceHolder}>
                    <Box
                        my={4}
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <Typography variant="h5" align="center">
                            This profile is not connected to resources
                        </Typography>
                    </Box>
                </Hidden>
                <Hidden when={isEmpty(profile.compensations)}>
                    <Grid item xs={12} className={classes.section}>
                        <SectionWithTitle title="Compensation">
                            <WeChipsList
                                chipsGroupName="compensations"
                                items={toChipItem(
                                    profile.compensations,
                                    activeProject?.investments,
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.investments)}>
                    <Grid item xs={12} className={classes.section}>
                        <SectionWithTitle title="Investment">
                            <WeChipsList
                                chipsGroupName="investments"
                                items={toChipItem(
                                    profile.investments,
                                    activeProject?.compensations,
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.fundings)}>
                    <Grid item xs={12} className={classes.section}>
                        <SectionWithTitle title="Funding">
                            <WeFundingList
                                onClick={(uri) => goTo(uri)}
                                items={objectToTypeValue(profile.fundings)}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    );
};
