import React from 'react';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { Field } from 'formik';
import Radio from '@material-ui/core/Radio/Radio';
import { WeRadioGroup, SectionWithTitle } from '@weco/ui';

export interface StageFormControlProps {
    name?: string;
    label?: string;
    options: any[];
}

export const StageFormControl = ({
    options,
    label,
    name,
}: StageFormControlProps) => {
    return (
        <FormControl>
            <SectionWithTitle title={label} />
            <Field component={WeRadioGroup} name={name}>
                {options.map((item, index) => (
                    <FormControlLabel
                        key={index}
                        value={item}
                        control={<Radio />}
                        label={item}
                    />
                ))}
            </Field>
        </FormControl>
    );
};
