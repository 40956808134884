import React from 'react';
import { Redirect, Switch } from 'react-router';
import { WeRoute } from '../../router/WeRoute';
import { Finish } from './finish';
import { Quiz } from './quiz';

export const QuizPage = () => {
    return (
        <Switch>
            <WeRoute path={`${QuizPage.URL}/app`}>
                <Quiz />
            </WeRoute>
            <WeRoute path={`${QuizPage.URL}/finish`}>
                <Finish />
            </WeRoute>
            <WeRoute path={`${QuizPage.URL}/`} exact>
                <Redirect to={`${QuizPage.URL}/app`} />
            </WeRoute>
            <WeRoute path="/">
                <Redirect to="/dashboard" />
            </WeRoute>
        </Switch>
    );
};

QuizPage.URL = '/quiz';
