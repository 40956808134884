import { useMemo } from 'react';
import { useStore } from '@weco/common';
import { useObserver } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { MyProjectStore } from './MyProjectStore';
import { RouterPaths } from '../../../router/RouterPaths';

export const useMyProjectData = (id = null) => {
    const store = useStore<MyProjectStore>(MyProjectStore);
    const history = useHistory();

    // TODO: make these updates only in one place.
    // See MyProjectContainer, we need to load item
    // in that way because container component does not
    // properly unmount when we change URL.
    const loadInitData = async () => {
        if ((!id && !store.item.id) || id === store.item.id) {
            return;
        }
        try {
            await store.loadItem(id || store.item.id, () =>
                history.push({
                    state: 'My Project',
                    pathname: `${RouterPaths.notFound}`,
                }),
            );
            await store.loadTeam(id || store.item.id);
        } catch (e) {
            console.log('error', e);
        }
    };

    useMemo(loadInitData, [store.item.id, id]);
    return useObserver(() => ({
        item: store.item,
        team: store.team,
        constants: store.constants,
        updateItem: store.updateItem,
        updateImg: store.updateImg,
        loadItem: store.loadItem,
        changeProjectName: store.changeProjectName,
        getProjectPicture: store.getProjectPicture,
        loadProjectDefaults: store.loadProjectConstants,
        loadProjectConstants: store.loadProjectConstants,
    }));
};
