import React from 'react';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
//TODO: move to UI lib
export const DeleteButton = (props) => {
    return (
        <Button {...props}>
            {'delete '}
            <DeleteIcon />
        </Button>
    );
};
