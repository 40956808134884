import classNames from 'classnames';
import { useToggle } from 'react-use';
import React, { memo, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme } from '@material-ui/core';
/* ---------------------------------- icons --------------------------------- */
import HrActiveIcon from '../assets/icons/departments/hr--active.svg';
import { SpriteSvgIconButton } from '../molecules/SpriteSvgIconButton';
import IdeasActiveIcon from '../assets/icons/departments/ideas--active.svg';
import ItWebActiveIcon from '../assets/icons/departments/itweb--active.svg';
import LegalActiveIcon from '../assets/icons/departments/legal--active.svg';
import FinanceActiveIcon from '../assets/icons/departments/finance--active.svg';
import StrategyActiveIcon from '../assets/icons/departments/strategy--active.svg';
import MarketingActiveIcon from '../assets/icons/departments/marketing--active.svg';
import ExecutionActiveIcon from '../assets/icons/departments/execution--active.svg';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '50px',
        height: '50px',
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        transition: 'opacity 0.5s',
    },
    icon: {
        top: '50%',
        margin: '0',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    defaultState: {
        opacity: 0.3,
    },
    animatedState: {
        opacity: 1,
    },
    iconSlightlyEnlarged: {
        transform: 'scale(1.4)',
    },
    iconColor: {
        color: theme.palette.common.white,
    },
}));

export interface WecoDepartmentsNavigationIconProps {
    isActive: boolean;
    department: string;
    className?: string;
    isMatched: boolean;
    isSelected: boolean;
}

const activeIcon = {
    hr: HrActiveIcon,
    ideas: IdeasActiveIcon,
    itweb: ItWebActiveIcon,
    legal: LegalActiveIcon,
    finance: FinanceActiveIcon,
    strategy: StrategyActiveIcon,
    marketing: MarketingActiveIcon,
    execution: ExecutionActiveIcon,
};

const WecoDepartmentsNavigationIcon = memo(
    ({ department, ...props }: WecoDepartmentsNavigationIconProps) => {
        const [isAnimating, toggleAnimation] = useToggle(false);

        const classes = useStyles();
        const iconWrapperClasses = classNames(props.className, classes.root, {
            [classes.animatedState]: isAnimating,
            [classes.defaultState]: !props.isSelected && !props.isActive,
            /**
             * NOTE: this is a special case where "execution"
             * icon was slighly smaller compared to other icons.
             * Solution is to enlarge it via css.
             */
            [classes.iconSlightlyEnlarged]:
                department === 'execution' && !props.isActive,
        });

        // NOTE: Change classes to trigger animated transition effect.
        useEffect(() => {
            let interval;
            if (props.isMatched) {
                interval = setInterval(toggleAnimation, 1000);
            }
            return () => clearInterval(interval);
        }, [props.isMatched, toggleAnimation]);

        if (props.isActive) {
            return (
                <Box className={iconWrapperClasses}>
                    <img
                        className={classes.icon}
                        src={activeIcon[department]}
                        alt={`${department} department is active icon.`}
                    />
                </Box>
            );
        }

        return (
            <Box className={iconWrapperClasses}>
                <SpriteSvgIconButton
                    className={classes.icon}
                    id={`${department}-department`}
                />
            </Box>
        );
    },
);

WecoDepartmentsNavigationIcon.displayName = 'WecoDepartmentsNavigationIcon';

export { WecoDepartmentsNavigationIcon };
