import firebase from 'firebase/app';
import MessageInterface from '../../models/interfaces/MessageInterface';
import { PAGINATION_SIZE } from '../../utils/constants';
import {
    receiveRoomMessage,
    receiveUpdateRoomMessage,
    receiveDeleteRoomMessage,
    subscribedRoomMessages,
} from '../roomMessages/actionCreators';
import { refToRefData, timestampToString } from '../../models/ReferenceData';

/**
 * NOTE: the whole reason for the existence of this file
 * is to avoid "circular dependency" errors.
 */

export const messageFromDocument = (
    doc: firebase.firestore.QueryDocumentSnapshot<any>,
): MessageInterface => ({
    ...doc.data(),
    id: doc.id,
    owner: refToRefData(doc.data().owner),
    createdAt: timestampToString(doc.data().createdAt),
    createdAtTime: doc.data().createdAt.toDate().getTime(),
});

export const subscribeRoomMessages = (
    firestore: firebase.firestore.Firestore,
    roomId: string,
) => (dispatch: Function) => {
    firestore
        .collection(`roomMessages/${roomId}/messages`)
        .orderBy('createdAt', 'desc')
        .limit(PAGINATION_SIZE)
        .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
            snapshot
                .docChanges()
                .forEach(
                    (
                        change: firebase.firestore.DocumentChange<
                            MessageInterface
                        >,
                    ) => {
                        if (change.type === 'added') {
                            dispatch(
                                receiveRoomMessage(
                                    roomId,
                                    messageFromDocument(change.doc),
                                ),
                            );
                        }
                        if (change.type === 'modified') {
                            dispatch(
                                receiveUpdateRoomMessage(
                                    roomId,
                                    messageFromDocument(change.doc),
                                ),
                            );
                        }
                        if (change.type === 'removed' && !change.doc.exists) {
                            dispatch(
                                receiveDeleteRoomMessage(
                                    roomId,
                                    messageFromDocument(change.doc),
                                ),
                            );
                        }
                    },
                );
        });
    dispatch(subscribedRoomMessages(roomId));
};
