import React, { useCallback, useState } from 'react';
import { AsyncLoader, ReactHelpers, useAsync } from '@weco/ui';
import { useQuizData } from './useQuizData';
import { Redirect } from 'react-router';
import { usePersonRolesData } from '../../../store/hooks/usePersonRolesData';
import { useProjectRolesData } from '../../../store/hooks/useProjectRolesData';

export interface DataContainerProps {
    children: any;
}

export const DataContainer = ({ children }: DataContainerProps) => {
    const store = useQuizData();

    const result = useAsync(() => {
        if (!store.questions) {
            return Promise.all([store.reset()]).then(() => true);
        }
        return Promise.resolve(true);
    });
    return (
        <AsyncLoader {...result}>
            {!(result.loading || store.loading) &&
                ReactHelpers.renderChildren(children, store)}
        </AsyncLoader>
    );
};

export const AnswerCalculateContainer = ({ children }: DataContainerProps) => {
    const store = useQuizData();
    const positionsStore = usePersonRolesData();
    const [loading, setLoading] = useState(false);
    const doLoadInitData = () => {
        setLoading(true);
        store
            .calculateResult()
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    useCallback(doLoadInitData, []);

    const result = useAsync(() => {
        if (store.test) {
            if (!(store.test.results.length > 0)) {
                return Promise.all([store.calculateResult()]).then(() => true);
            }
            return Promise.resolve(true);
        }
        return Promise.resolve(true);
    });

    return (
        <AsyncLoader {...result}>
            {store.test ? (
                <>
                    {!(
                        result.loading ||
                        store.loading ||
                        loading ||
                        !store.test.results
                    ) &&
                        ReactHelpers.renderChildren(children, {
                            ...store,
                            ...positionsStore,
                        })}
                </>
            ) : (
                <Redirect to={'/quiz'} />
            )}
        </AsyncLoader>
    );
};
