import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useAppFooterStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerDrawer: {
            padding: 10,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.primary.main,
        },
        footerDrawerPaper: {
            zIndex: 5,
            display: 'flex',
            fontSize: '16px',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
        },
    }),
);
