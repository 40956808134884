export const WePrimary = {
    50: '#EBEAF0',
    55: '#F2F1FF',
    100: '#D5D4E0',
    150: '#D7D3E8',
    200: '#ABA9C1',
    300: '#817FA1',
    400: '#575482',
    450: '#544F94',
    475: '#6354A7',
    480: '#755DD8',
    500: '#2D2963',
    550: '#2C2861',
    600: '#221F4A',
    700: '#171532',
    800: '#0B0A19',
    900: '#05040A',
    A100: '#ABA9C1',
    A200: '#575482',
    A400: '#2D2963',
    A700: '#171532',
};
