import React, { memo, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    text: {
        // TODO change to theme.color.secondary or somehting
        color: '#8E8E8E',
        fontSize: '22px',
        textAlign: 'center',
        fontWeight: 400,
    },
}));

const PageSubtitle: FunctionComponent<{ children: string }> = (props) => {
    const cx = useStyles();
    return <div className={cx.text}>{props.children}</div>;
};

export const OnboardingPageSubtitle = memo(PageSubtitle);
