import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import classnames from 'classnames';
import React, { FunctionComponent, memo } from 'react';
import { INamed } from '@weco/core';
import EditIcon from '@material-ui/icons/Edit';

// NOTE: there is almost identical component Called GridList

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '500px',
            marginLeft: '20px',
        },
    },
    grid: {
        minWidth: '105px',
        [theme.breakpoints.up('md')]: {
            marginLeft: '10px',
        },
    },
    itemContainer: {
        fontSize: 14,
        width: '100px',
        height: '65px',
        borderRadius: '10px !important',
        textAlign: 'center',
        transition: 'color 0.5s, background-color 0.5s',
        backgroundColor: '#FFF',
        border: '1px solid #8E8E8E',
        color: '#8E8E8E',
    },
    itemText: {
        display: 'flex',

        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    itemSelected: {
        fontSize: 13,
        fontWeight: 500,
        backgroundColor: '#F2F1FF',
        color: theme.palette.text.primary,
    },
    itemNotSelected: {
        color: '#8E8E8E',
    },
    box: {
        cursor: 'pointer',
    },
    editIcon: {
        width: '8px',
        height: '8px',
        float: 'right',
        margin: '5px',
        marginBottom: '0',
    },
    editBox: {
        width: '100%',
    },
    truncateText: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        marginRight: '3px',
        wordBreak: 'break-word',
        lineHeight: '1.5em',
        maxHeight: '3em',
    },
}));

interface BoxListProps {
    items: INamed[];
    onItemClick?(item: INamed);
}

const component: FunctionComponent<BoxListProps> = function PassionsSelector({
    items,
    onItemClick,
}) {
    const cx = useStyles();

    return (
        <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            className={cx.container}
        >
            {items?.map((item, index) => {
                return (
                    item && (
                        <Grid
                            item
                            key={index}
                            xs={4}
                            sm={2}
                            className={cx.grid}
                        >
                            <Box
                                onClick={() => {
                                    onItemClick(item);
                                }}
                                className={classnames(
                                    {
                                        [cx.itemContainer]: true,
                                        [cx.itemSelected]: item?.isSelected,
                                    },
                                    cx.box,
                                )}
                                color="text.primary"
                            >
                                {!item?.isDefault && (
                                    <EditIcon className={cx.editIcon} />
                                )}
                                <Box
                                    className={cx.itemText}
                                    alignItems={
                                        !item?.isDefault
                                            ? 'flex-start'
                                            : 'center'
                                    }
                                >
                                    <Typography
                                        title={item.name}
                                        className={cx.truncateText}
                                        variant="body2"
                                    >
                                        {item.name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    )
                );
            })}
        </Grid>
    );
};

export const BoxList = memo(component);
