import React from 'react';
import { ProfileProjectsTabStore } from './ProfileProjectsTabStore';
import { StoreContainer } from 'apps/client/src/app/store/containers/StoreContainer';
import { TabLoadingSkeleton } from '@weco/ui';

export interface ProfileProjectsContainerProps {
    children: any;
    currentProjectId?: string;
}

export const ProfileProjectsContainer = ({
    children,
}: ProfileProjectsContainerProps) => {
    const loader = async (store: ProfileProjectsTabStore) => {
        await store.loadAllMyProjects();
        // await store.searchAllPeopleForConnections(currentProjectId);
        return store.loadAllMyProjectsConnections();
    };
    return (
        <StoreContainer
            children={children}
            classType={ProfileProjectsTabStore}
            loader={loader}
            loaderComponent={<TabLoadingSkeleton />}
        />
    );
};
