const allCauses = [
    'Aesthetic Environment',
    'Animal Rights',
    'Clean Environment',
    'Collaboration',
    'Educational Reform',
    'Effective Politics',
    'Ethics / Morality',
    'Healthcare Reform',
    'Healthy Nutrition',
    'Human Rights',
    'Improved Lifestyle',
    'Meaningful Art / Entertainment',
    'Peace',
    'Prosperity',
    'Public Health',
    'Public Safety',
    'World Exploration',
];

export default allCauses;
