import { UserTokenInterface } from '../../common';

export class AmplifyForgotPasswordSubmitToken implements UserTokenInterface {
    constructor(
        public username: string,
        public code: string,
        public password: string,
    ) {}

    isAuthenticated() {
        return false;
    }

    hasRole(name = '*') {
        return false;
    }

    getUsername() {
        return this.username;
    }

    getUser() {
        return undefined;
    }
}
