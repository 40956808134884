import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { SearchPageStore } from '../SearchPageStore';

export const useSearchProjectsData = () => {
    const store = useStore<SearchPageStore>(SearchPageStore);

    return useObserver(() => ({
        items: store.projects,
        search: store.searchProjects,
        projectLimit: store.projectLimit,
        step: store.defaultLimit,
        projectTotal: store.projectTotal,
        loading: store.loading,
        myUserStore: store.myUserStore,
        setAdvancedSearchFilter: store.setAdvancedSearchFilter,
    }));
};
