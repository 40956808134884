import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { PeopleMainPageStore } from './PeopleMainPageStore';

export const usePeopleData = () => {
    const store = useStore<PeopleMainPageStore>(PeopleMainPageStore);

    return useObserver(() => ({
        items: store.items,
        activeFilterValue: store.activeFilterValue,
        filters: store.filters,
        changeFilter: store.changeFilter,
    }));
};
