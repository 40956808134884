export class AnalyticsEvent {
    constructor(
        public name: string,
        public payload?: any,
        public options?: { [providenName: string]: any },
    ) {}
}

export class AuthAnalyticsEvent {
    constructor(
        public name: string,
        public userId: string | null,
        public payload?: any,
    ) {}
}
