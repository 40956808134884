import classNames from 'classnames';
import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { matchedSections, ProjectEntity, UserProfileEntity } from '@weco/core';
import { SearchResultItem } from './SearchResultItem';
import { useHistory } from 'react-router';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { SpriteSvgIcon } from '../molecules';
import { CardFooter } from './CardFooter';
import { environment } from 'apps/client/src/environments/environment';
import { intersection } from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        cursor: 'pointer',
    },
}));

export interface PeopleListCompressedProps {
    className?: string;
    currentUserId: string;
    people: UserProfileEntity[];
    activeProject?: ProjectEntity;
    ownerId?: string;
}

const PeopleListCompressed = memo((props: PeopleListCompressedProps) => {
    const history = useHistory();
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    const goToPerson = (person) => {
        if (props.currentUserId === person.id) {
            return `${RouterPaths.myProfile}/${person.id}`;
        }
        return `${RouterPaths.profilePage}/${person.id}`;
    };

    const isMatchedItem = (score?: number) => {
        return score && score > environment.defaults.elasticNotMatchScore;
    };

    const getRoute = (personId) => {
        return props.currentUserId === personId
            ? RouterPaths.myProfile
            : `${RouterPaths.profilePage}/${personId}`;
    };

    return (
        <Box className={rootClasses}>
            {props.people?.map((person, index) => {
                const isMe = person.id === props.currentUserId;
                const isProjectOwner = person.id === props.ownerId;
                const matched = matchedSections(person.highlight);
                return (
                    <Box key={index} id={person.id}>
                        <SearchResultItem
                            type="person"
                            withShareButton
                            favoriteIsHidden={isMe}
                            itemId={person.id}
                            isAuthenticated={!!props.currentUserId}
                            link={goToPerson(person)}
                            title={`${person.name} ${person.lastName}`}
                            description={person.dream}
                            chips={person.causes}
                            avatarProps={{
                                size: 'normal',
                                shape: 'round',
                                picture: person.picture,
                                percent: person.matchPercent,
                                percentagePlacement: isMe ? 'none' : undefined,
                            }}
                            personIdToOpenChatWith={person.id}
                            activeProject={props.activeProject}
                            isProjectOwner={isProjectOwner}
                            isMatched={isMatchedItem(person.searchMatchScore)}
                            cardFooter={
                                <CardFooter
                                    icons={[
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="card-about-cards-footer" />
                                            ),
                                            label: 'Bio',
                                            url: `${getRoute(person.id)}`,
                                            isMatched: matched.isBioMatched,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="skill-team-card-footer" />
                                            ),
                                            label: 'Skills',
                                            url: `${getRoute(
                                                person.id,
                                            )}/skills`,
                                            isMatched: matched.isSkillsMatched,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="resources-card-footer" />
                                            ),
                                            label: 'Resources',
                                            url: `${getRoute(
                                                person.id,
                                            )}/resources`,
                                            isMatched:
                                                matched.isResourcesMatched,
                                        },
                                    ]}
                                />
                            }
                        />
                    </Box>
                );
            })}
        </Box>
    );
});

export { PeopleListCompressed };
