import { Inject, Injectable } from '@weco/common';
import { ApolloClient } from 'apollo-boost';
import { CurrentUserProviderInterface } from './CurrentUserProvider';
import { CORE_SERVICES } from '../core_services';

@Injectable()
export abstract class GraphQlBaseRepository {
    @Inject(CORE_SERVICES.ICurrentUserProvider)
    protected currentUserProvider: CurrentUserProviderInterface;

    @Inject(CORE_SERVICES.ApolloAuthorizedClient)
    protected authorizedClient: ApolloClient<any>;

    @Inject(CORE_SERVICES.ApolloUnAuthorizedClient)
    protected unAuthorizedClient: ApolloClient<any>;

    protected invalidateAllQueryFetching = async (keys: string[] | string) => {
        if (!keys) throw new Error('No parameter');
        const invalidate = (queryName) => {
            const { cache } = this.unAuthorizedClient as any;
            Object.keys(cache.data.data).forEach(
                (key) => key.search(queryName) !== -1 && cache.data.delete(key),
            );
        };
        [].concat(keys).forEach((key) => invalidate(key));
    };
}
