import React from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

interface SpriteSvgIconProps {
    id: string;
    style?: any;
    classes?: any;
    sprite?: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        color: 'transparent',
    },
}));

export const SpriteSvgIcon = ({
    id,
    classes,
    style = {},
    ...props
}: SpriteSvgIconProps) => {
    const cx = useStyles();

    return (
        <SvgIcon
            style={style}
            className={classNames(props.className, cx.icon, classes?.svg)}
        >
            <use href={`../assets/${props.sprite || 'sprite'}.svg#${id}`} />
        </SvgIcon>
    );
};
