import React from 'react';

function isClassComponent(component) {
    return (
        typeof component === 'function' &&
        !!component.prototype?.isReactComponent
    );
}

function isFunctionComponent(component) {
    return (
        typeof component === 'function' &&
        String(component).includes('return React.createElement')
    );
}

function isReactComponent(component) {
    return isClassComponent(component) || isFunctionComponent(component);
}

function isElement(element) {
    return React.isValidElement(element);
}

function isDOMTypeElement(element) {
    return isElement(element) && typeof element.type === 'string';
}

function isCompositeTypeElement(element) {
    return isElement(element) && typeof element.type === 'function';
}

export const renderChildren = (children, props = {}) => {
    if (!children) return null;
    if (typeof children === 'function')
        return children(isReactComponent(children) ? undefined : props);
    if (typeof children === 'string') return <>{children}</>;
    if (children instanceof Array) {
        return children.map((child, index) =>
            React.cloneElement(
                child,
                isReactComponent(children)
                    ? undefined
                    : { key: index, ...props },
            ),
        );
    }
    return React.cloneElement(
        children,
        isReactComponent(children) ? undefined : props,
    );
};
