import { Box } from '@material-ui/core';
import { useStore } from '@weco/common';
import { GridList } from '@weco/ui';
import debug from 'debug';
import xor from 'lodash/xor';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import OnboardingNavigationButtons from '../components/OnboardingNavigationButtons';
import { OnboardingPageWrapper } from '../components/OnboardingPageWrapper';
import { OnboardingSearchCauses } from '../components/OnboardingSearchCauses';
import OnboardingStore from '../OnboardingStore';
import { coreConstants } from '@weco/core';
import { flow, shuffle, take, get } from 'lodash/fp';

const log = debug('SelectCausesStep');

const fewRandomCauses = flow(get('causes'), shuffle, take(6))(coreConstants);

const SelectCausesStep = observer(() => {
    const store = useStore<OnboardingStore>(OnboardingStore);
    const customCausesInStore = store.selectedCauses.filter(
        (selectedCause) => !fewRandomCauses.includes(selectedCause),
    );
    const [customUsersCauses, setCustomCauses] = useState<string[]>(
        customCausesInStore,
    );
    const { name, lastName } = useMyUserProfileData();

    function toggleCustomCause(cause) {
        setCustomCauses(xor(customUsersCauses, [cause]));
    }

    return (
        <OnboardingPageWrapper
            title={name ? 'Welcome!' : `Welcome ${name} ${lastName}!`}
            subtitle="Which causes matter to you?"
            bottomChildren={
                <OnboardingNavigationButtons
                    isSubmitDisabled={!store.selectedCauses.length}
                    isBackButtonVisible={false} //NOTE: need to remove this line when YearSelectionStep will be opened.
                />
            }
        >
            <>
                <Box mb={4}>
                    <GridList
                        selectedItems={store.selectedCauses}
                        items={fewRandomCauses.concat(customUsersCauses)}
                        onClick={(clickedCause) => {
                            if (!fewRandomCauses.includes(clickedCause)) {
                                toggleCustomCause(clickedCause);
                            }
                            store.toggleSelectedCause(clickedCause);
                        }}
                    />
                </Box>
                <Box mb={4} mx={4}>
                    <OnboardingSearchCauses
                        onSubmit={(cause = '') => {
                            if (cause.trim()) {
                                toggleCustomCause(cause);
                                store.toggleSelectedCause(cause);
                            }
                        }}
                    />
                </Box>
            </>
        </OnboardingPageWrapper>
    );
});

export { SelectCausesStep };

export default SelectCausesStep;
