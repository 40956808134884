import React, { memo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { Box, IconButton, Theme } from '@material-ui/core';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const useStyles = makeStyles(({ palette }: Theme) => ({
    root: {
        color: palette.primary.contrastText,
    },
    isNotFavorite: {
        color: palette.secondary[400],
    },
    isFavorite: {
        color: palette.error[300],
    },
}));

export interface FavoriteIconButtonProps {
    onClick?: (e) => void;
    isFavorite: boolean;
    className?: string;
}

const FavoriteButton = (props: FavoriteIconButtonProps) => {
    const classes = useStyles();

    const iconClasses = props.isFavorite
        ? classes.isFavorite
        : classes.isNotFavorite;

    return (
        <Box className={classNames(classes.root, props.className)}>
            <IconButton onClick={props.onClick} className={iconClasses}>
                {props.isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
        </Box>
    );
};

const FavoriteIconButton = memo(FavoriteButton);

FavoriteIconButton.displayName = 'FavoriteIconButton';

export { FavoriteIconButton };
