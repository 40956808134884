import React from 'react';
import { Box, Theme } from '@material-ui/core';
import UploadImage from '../UploadImage';
import { ReactHelpers, Avatar } from '@weco/ui';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        maxHeight: '120px',
    },
    avatarBox: {
        position: 'relative',
    },
    uploadImageBox: {
        position: 'absolute',
        top: '-7px',
        left: '102px',
    },
}));

const EditableProjectAvatarContainer = ({ picture, children, projectId }) => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.avatarBox}>
                    <Avatar
                        withBorder
                        withShadow
                        size="large"
                        shape="square"
                        picture={picture}
                        addUniqueVersionIdToURL
                        percentagePlacement="none"
                    />
                    <Box className={classes.uploadImageBox}>
                        <UploadImage type="project" />
                    </Box>
                </Box>
            </Box>
            {ReactHelpers.renderChildren(children)}
        </>
    );
};
export default EditableProjectAvatarContainer;
