import React from 'react';
import { Box, Card, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChildrenType, OnAnyEventHandler } from '../helpers';
import { MatchPercentage } from './MatchPercentage';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        cursor: 'pointer',
    },
    cardRoot: {
        height: '340px',
        width: '330px',
        padding: '30px 20px',
        margin: '0 auto',
        background: theme.palette.common.white,
        boxShadow: '0px 13px 16px rgba(0, 0, 0, 0.05)',
        borderRadius: '6px',
        overflow: 'visible',
    },
    avatarBox: {
        textAlign: 'center',
    },
    title: {
        textAlign: 'center',
        width: '295px',
        marginTop: 30,
        marginBottom: 15,
        color: theme.palette.primary[700],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    description: {
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        color: theme.palette.secondary[600],
    },
    topRightButtonWrapper: {
        overflow: 'visible',
        marginTop: '-6px',
    },
    cardBottom: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '160px',
    },
    textDecoration: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export interface CardBaseProps {
    avatar: ChildrenType;
    topRightIcon: ChildrenType;
    footer: ChildrenType;
    link?: string;
    matchPercent?: number;
    title: string;
    description?: string;
    isMatchPercentHidden?: boolean;
}

const CardBase = React.memo(function CardBaseComponent({
    avatar,
    topRightIcon,
    footer,
    link,
    matchPercent,
    title,
    description = '',
    isMatchPercentHidden,
}: CardBaseProps) {
    const classes = useStyles();
    const isPercentVisible = !isMatchPercentHidden && !!matchPercent;

    return (
        <Link to={link} className={classes.textDecoration}>
            <Box className={classes.root}>
                <Card className={classes.cardRoot}>
                    <Grid container justify="space-around">
                        <Grid item xs={2}>
                            {isPercentVisible && (
                                <MatchPercentage number={matchPercent} />
                            )}
                        </Grid>
                        <Grid item xs={8}>
                            <Box className={classes.avatarBox}>{avatar}</Box>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            className={classes.topRightButtonWrapper}
                        >
                            {topRightIcon}
                        </Grid>
                    </Grid>
                    <Box className={classes.cardBottom}>
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    className={classes.title}
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            {description && (
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        className={classes.description}
                                    >
                                        {description}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item container>
                            {footer}
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Link>
    );
});

CardBase.displayName = 'CardBase';

export { CardBase };
