import React from 'react';
import { AsyncHookResult } from '../hooks';
import { ReactHelpers } from '../helpers';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export type SkeletonAsyncLoaderProps = AsyncHookResult & {
    skeleton: React.ReactElement;
    count?: number;
    onError?: (error: Error | any) => boolean | void;
    children?: React.ReactElement;
    errorComponent?: React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
    >;
};

export const SkeletonAsyncLoader = ({
    loading,
    error,
    value,
    onError,
    errorComponent,
    children,
    skeleton,
    count,
}: SkeletonAsyncLoaderProps) => {
    if (error) {
        if (onError && onError(error)) {
            return null;
        }
        if (errorComponent) {
            return ReactHelpers.renderChildren(errorComponent, { error });
        }
        return <div className="error"> {error.message || error} </div>;
    }

    if (loading) {
        return (
            <Grid container spacing={2} justify="space-between">
                {[...Array(count || 1).keys()].map((index) => (
                    <React.Fragment key={index}>
                        {ReactHelpers.renderChildren(skeleton)}
                    </React.Fragment>
                ))}
            </Grid>
        );
    }
    return ReactHelpers.renderChildren(children, { value });
};
