import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ReactHelpers } from '../helpers';

interface WeSimplePaginatedListProps {
    options: any[];
    pageLimit?: number;
    children: any;
}

export const WeSimplePaginatedList = ({
    options,
    pageLimit = 10,
    children,
}: WeSimplePaginatedListProps) => {
    const [page, setPage] = useState(1);
    const [showed, setShowed] = useState((options || []).slice(0, pageLimit));

    useEffect(() => {
        setShowed(
            (options || []).slice((page - 1) * pageLimit, page * pageLimit),
        );
    }, [page, pageLimit]);

    const next = () => {
        setPage(page + 1);
    };

    const prev = () => {
        setPage(page - 1);
    };

    return (
        <>
            {ReactHelpers.renderChildren(children, { showed: showed || [] })}
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    {page > 1 && (
                        <IconButton onClick={prev}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    {page * pageLimit < (options || []).length && (
                        <IconButton onClick={next}>
                            <ArrowForwardIcon />
                        </IconButton>
                    )}
                </Box>
            </Grid>
        </>
    );
};
