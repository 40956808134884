import { withStyles } from '@material-ui/styles';
import { Switch, Theme } from '@material-ui/core';

export const ColoredSwitch = withStyles((theme: Theme) => ({
    root: {
        width: 40,
        height: 20,
        padding: 0,
        boxShadow: 'none',
    },
    thumb: {
        width: 20,
        height: 20,
        padding: 0,
        boxShadow: 'none',
    },
    switchBase: {
        color: theme.palette.secondary.light,
        '&$checked': {
            color: theme.palette.info.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.info.main,
            opacity: 0.38,
            border: 0,
        },
        padding: 0,
    },
    checked: {},
    track: {
        opacity: 1,
        borderRadius: '10px',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.secondary.light}`,
    },
}))(Switch);
