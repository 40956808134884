import React from 'react';
import MuiToggleButtonGroup, {
    ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@material-ui/lab/ToggleButtonGroup';
import { FieldProps } from 'formik';

export interface WeToggleButtonGroupProps
    extends FieldProps,
        Omit<MuiToggleButtonGroupProps, 'name' | 'value' | 'error'> {
    type?: string;
}

export const fieldToToggleButtonGroup = ({
    field,
    type,
    onChange,
    onBlur,
    form,
    ...props
}: WeToggleButtonGroupProps): MuiToggleButtonGroupProps => {
    const {
        onChange: _onChange,
        onBlur: _onBlur,
        ...fieldSubselection
    } = field;

    return {
        onBlur: onBlur
            ? onBlur
            : (event: React.FocusEvent<unknown>) => {
                  field.onBlur(event ?? field.name);
              },
        onChange: onChange
            ? onChange
            : (_event: React.MouseEvent<HTMLElement>, newValue: unknown) => {
                  form.setFieldValue(field.name, newValue);
              },
        ...fieldSubselection,
        ...props,
    };
};

export const WeToggleButtonGroup = (props: WeToggleButtonGroupProps) => {
    return <MuiToggleButtonGroup {...fieldToToggleButtonGroup(props)} />;
};
