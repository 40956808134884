import React, { memo } from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    avatar: {
        maxHeight: '100%',
        width: '100%',
        height: '100%',
    },
    count: {
        fontSize: '14px',
        color: theme.palette.text.secondary,
        position: 'relative',
        top: '3px',
    },
}));

export type ProfileIconProps = {
    picture: string;
    text?: string;
};

const component = ({ picture, text }: ProfileIconProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box
                className={classes.avatar}
                style={{
                    backgroundImage: `url(${picture})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'center',
                    backgroundPosition: 'center',
                    OBackgroundSize: 'cover',
                    MozBackgroundSize: 'cover',
                    WebkitBackgroundSize: 'cover',
                    cursor: 'pointer',
                }}
            >
                {text && (
                    <Typography className={classes.count}>{text}</Typography>
                )}
            </Box>
        </Box>
    );
};

export const ProfileIcon = memo(component);
