import { Inject, Injectable } from '@weco/common';
import { CORE_SERVICES } from '../core_services';
import { CoolEntity } from '../entity/CoolEntity';
import {
    AddCoolInterface,
    DeleteCoolInputInterface,
    GetCoolsListByEntityIdInputInterface,
    HasCoolInputInterface,
    ICoolRepository,
} from '../repositories/CoolRepository';

@Injectable()
export class CoolService {
    @Inject(CORE_SERVICES.ICoolRepository)
    private repository: ICoolRepository;

    public async addCool(input: AddCoolInterface): Promise<CoolEntity> {
        return this.repository.addCool(input);
    }

    public async deleteCool(
        input: DeleteCoolInputInterface,
    ): Promise<CoolEntity> {
        return this.repository.deleteCool(input);
    }

    public async hasCool(
        input: HasCoolInputInterface,
    ): Promise<CoolEntity | null> {
        return this.repository.hasCool(input);
    }

    public async getCoolsListByEntityId(
        input: GetCoolsListByEntityIdInputInterface,
    ): Promise<CoolEntity[]> {
        return this.repository.getCoolsListByEntityId(input);
    }
}
