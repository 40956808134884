import React from 'react';
import {
    DateTimePicker as MuiDateTimePicker,
    DateTimePickerProps as MuiDateTimePickerProps,
} from '@material-ui/pickers';
import { FieldProps, getIn } from 'formik';
import { createErrorHandler } from './errorHandler';

export interface WeDateTimePickerProps
    extends FieldProps,
        Omit<MuiDateTimePickerProps, 'name' | 'value' | 'error'> {}

export const fieldToDateTimePicker = ({
    field,
    form: { isSubmitting, touched, errors, setFieldValue, setFieldError },
    disabled,
    ...props
}: WeDateTimePickerProps): MuiDateTimePickerProps => {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled != undefined ? disabled : isSubmitting,
        onChange(date) {
            props.onChange
                ? props.onChange(date)
                : setFieldValue(field.name, date);
        },
        onError: createErrorHandler(fieldError, field.name, setFieldError),
    };
};

export const WeDateTimePicker = ({
    children,
    ...props
}: WeDateTimePickerProps) => {
    return (
        <MuiDateTimePicker {...fieldToDateTimePicker(props)}>
            {' '}
            {children}{' '}
        </MuiDateTimePicker>
    );
};
