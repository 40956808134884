import classNames from 'classnames';
import React, { memo } from 'react';
import {
    Box,
    Card,
    Grid,
    Theme,
    Divider,
    Typography,
    ButtonBase,
    CardContent,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { SpriteSvgIcon } from '../molecules';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'block',
        margin: '0 auto',
        textAlign: 'center',
        boxShadow:
            '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    },
    cardContent: {
        width: '150px',
        height: '140px',
        padding: '17px 9px !important',
    },
    cardGrid: {
        height: '100%',
    },
    numberContainer: {
        margin: 'auto',
    },
    numberSkeleton: {
        margin: '0 auto',
    },
    number: {
        padding: '10px',
        fontWeight: 500,
        fontSize: '40px',
        lineHeight: '20px',
        verticalAlign: 'middle',
        color: theme.palette.text.secondary,
    },
    card: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    plus: {
        marginTop: '15px',
    },
}));

export interface BigNumberCardProps {
    title: string;
    number: number;
    subtitle?: string;
    className?: string;
    isLoading?: boolean;
    isThisMyEntity?: boolean;
    onClick: () => void;
}

const BigNumberCard = memo(
    ({ isLoading, isThisMyEntity, number, ...props }: BigNumberCardProps) => {
        const classes = useStyles();
        const rootClasses = classNames(classes.root, props.className);

        const addSignShouldBeShown = () => {
            return (
                props.title === 'Roles' ||
                props.title === 'Projects' ||
                props.title === 'Team'
            );
        };

        const text = () => {
            let text = '';
            if (props.title === 'Roles') {
                text = 'Add';
            }
            if (props.title === 'Projects') {
                text = 'Create or Join';
            }
            if (props.title === 'Team') {
                text = 'Search';
            }
            return text;
        };

        return (
            <ButtonBase
                disabled={isLoading}
                className={rootClasses}
                onClick={props.onClick}
            >
                <Card>
                    <CardContent className={classes.cardContent}>
                        <Grid
                            item
                            container
                            direction="row"
                            justify="flex-start"
                            className={classes.cardGrid}
                        >
                            <Grid item xs={12}>
                                <Typography component="div" variant="h5">
                                    {isLoading ? <Skeleton /> : props.title}
                                </Typography>
                                <Typography variant="body2" component="div">
                                    {isLoading ? <Skeleton /> : props.subtitle}
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.numberContainer}
                            >
                                {isLoading ? (
                                    <Skeleton
                                        width={50}
                                        height={70}
                                        className={classes.numberSkeleton}
                                    />
                                ) : !number &&
                                  isThisMyEntity &&
                                  addSignShouldBeShown() ? (
                                    <Grid
                                        item
                                        container
                                        className={classes.card}
                                    >
                                        <Typography>{text()}</Typography>
                                        <SpriteSvgIcon
                                            className={classes.plus}
                                            id="plus-in-circle"
                                        />
                                    </Grid>
                                ) : (
                                    <Box className={classes.number}>
                                        {number}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </ButtonBase>
        );
    },
);

BigNumberCard.displayName = 'BigNumberCard';

export { BigNumberCard };
