import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import {
    HappyConnection2Interface,
    HappyConnectionInterface,
    HappyConnectionType,
    PersonProjectConnection,
} from '@weco/core';
import { MyUserStore } from '../MyUserStore';

export type ConnectionsMap = {
    [key: string]: any;
};

export interface RequestConnectionInterface {
    (params: ConnectionInterface): Promise<any>;
}

export interface MyHappyConnectionData {
    connection?: HappyConnection2Interface;
    connectionsMap?: ConnectionsMap;
    happyConnection?: HappyConnectionInterface;
    connections?: PersonProjectConnection[];
    requestConnection?: RequestConnectionInterface;
    chatConnectionLoadings?: string[];
}

export interface ConnectionParamsInterface {
    projectId: string;
    personId: string;
}

export interface ConnectionInterface extends ConnectionParamsInterface {
    requestOwnerId: string;
    id?: string;
}

export const useMyHappyConnectionData = (
    params: ConnectionInterface,
): MyHappyConnectionData => {
    const { happyConnectionsStore } = useStore<MyUserStore>(MyUserStore);
    return useObserver(() => {
        const key = JSON.stringify(params);
        return {
            connection: happyConnectionsStore?.connectionsMap[key],
            requestConnection: happyConnectionsStore?.requestConnection,
            chatConnectionLoadings:
                happyConnectionsStore?.chatConnectionLoadings,
        } as MyHappyConnectionData;
    });
};
