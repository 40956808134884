export interface DepartmentResultItem {
    name: string;
    title: string;
    description: string;
}

export const getDepartmentTestResultInfoByName = (
    name: string,
): DepartmentResultItem => {
    let info = null;
    resultData.forEach((resultInfo) => {
        if (resultInfo.name === name) {
            info = resultInfo;
            return;
        }
    });
    return info;
};

export const resultData: DepartmentResultItem[] = [
    {
        name: 'strategy',
        title: 'You’re a born Visionary! ',
        description:
            '<span style="font-family: Arial; font-size: 14px;"><span style="font-weight: bold;">Similar personalities: </span>Socrates, Albert Einstein, Steve Jobs</span><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">People like yourself move the human race forward. You see things clear and can easily put any idea in motion. Your</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"> </span><span style="font-size: 14.6667px; font-family: Arial; font-weight: 700; vertical-align: baseline; white-space: pre-wrap;">Strategy</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"> can be the most unique and powerful. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;">Activities may include:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857;"> leadership, communication, and “big picture” planning</span></p>',
    },
    {
        name: 'marketing',
        title: 'You’re a born Connector!',
        description:
            '<span id="docs-internal-guid-feed893e-f2ae-76c4-e2b7-7affd0d1f174"><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;">Similar personalities:</span> Seth Godin, Guy Kawasaki, Salvador Dali</span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">You can make people throw buckets of ice on their heads, and even make them feel motivated to do that. Your </span><span style="font-size: 14.6667px; font-family: Arial; font-weight: 700; vertical-align: baseline; white-space: pre-wrap;">Customers</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"> can be the most happy and satisfied. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;">Activities may include:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857;"> public relations, promotion, and creativity.</span></p></span>',
    },
    {
        name: 'legal',
        title: 'You’re a born Legalizer!',
        description:
            '<span id="docs-internal-guid-feed893e-f2b0-71ca-75e3-87c42f0ea632"><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14px;"><span style="font-weight: bold;">Similar personalities:</span> Abraham Lincoln, Mahatma Gandhi, Nelson Mandela</span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14px;"><br></span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;"></span></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">Al Kapone would\'ve probably avoided jail-time if you would’ve been his attorney. Your fantastic attention to details will keep the </span><span style="font-size: 14.6667px; font-family: Arial; font-weight: 700; vertical-align: baseline; white-space: pre-wrap;">Documents</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"> most clean and organized. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;">Activities may include:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857;"> negotiating, counseling, and legal protection. </span></p></span>',
    },
    {
        name: 'execution',
        title: 'You’re a born Creator!',
        description:
            '<span id="docs-internal-guid-feed893e-f2b3-a7fd-083a-338d2fb6d9bb"><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;">Similar personalities:</span> Henry Ford, Chuck Taylor II, Elon Musk</span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">You are the jewel of every project, the problem solver, the ambassador of the vision and the guru of getting things done. With you, any </span><span style="font-size: 14.6667px; font-family: Arial; font-weight: 700; vertical-align: baseline; white-space: pre-wrap;">Product</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"> </span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">can be the most timely and supreme. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;">Activities may include:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857;"> service, administration, and optimization.</span></p></span>',
    },
    {
        name: 'finance',
        title: 'You’re a born Treasurer!',
        description:
            '<span id="docs-internal-guid-feed893e-f2b6-12d0-1ef0-d0c3e60e4c8d"><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;">Similar personalities:</span> Peter Thiel, Warren Buffett, Mark Cuban</span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">It\'s time to put your brilliant mind to work and calculate how project could generate, at least, 1 dollar more than it spends. Your </span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;">Numbers</span> </span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">can be the most accurate and organized. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;">Activities may include:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857;"> budgeting, forecasting, and bookkeeping.</span></p></span>',
    },
    {
        name: 'itweb',
        title: 'You’re a born Techie!',
        description:
            '<span id="docs-internal-guid-feed893e-f2b9-0c15-6672-f950e91c8ea9"><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;">Similar personalities:</span> Steve Wozniak, Gabe Newell, Evan Spiegel</span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">You can easily learn a new computer language, find an effective solution and deliver cutting-edge experiences. With you, </span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap; font-weight: bold;">IT</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"> department can be the most stable and innovative. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;">Activities may include:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857;"> coding, administrating, and security.</span></p></span>',
    },
    {
        name: 'hr',
        title: 'You’re a born Mentor! ',
        description:
            '<span id="docs-internal-guid-feed893e-f2ba-aac3-8224-9ef0fa0eed46"><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;">Similar personalities:</span> King David, Abraham Maslow, Osho.</span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">You care about people and have a special talent for bringing out the best in others by inspiring them to learn, grow, and produce. With you, any </span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap; font-weight: bold;">Team</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"> can be the most happy and productive. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857; font-weight: bold;">Activities may include:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.42857;"> hiring, team-building, and mentorship.</span></p></span>',
    },
    {
        name: 'ideas',
        title: 'You’re a born Inventor!',
        description:
            '<span id="docs-internal-guid-feed893e-f2a8-972a-7a90-da17a221997a"><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;">You have no respect for the status quo. Your </span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;">Idea</span> can be the most creative and relevant. </span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.38; vertical-align: baseline; font-weight: bold;">Similar personalities:</span><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.38; vertical-align: baseline;"> Leonardo da Vinci, Albert Einstein, Nikola Tesla</span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-family: Arial; font-size: 14.6667px; white-space: pre-wrap; line-height: 1.38; vertical-align: baseline;"><br></span></p><p dir="ltr" style="line-height: 1.38; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap; font-weight: bold;">Activities</span><span style="font-size: 14.6667px; font-family: Arial; vertical-align: baseline; white-space: pre-wrap;"><span style="font-weight: bold;"> include:</span> ideation, brainstorming, and analysis</span></p></span>',
    },
];
