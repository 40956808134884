import { Field } from 'formik';
import React, { FunctionComponent, memo } from 'react';
import { SchoolEntity } from '@weco/core';
import { WeAutocomplete } from '../forms/WeAutocomplete';
import { TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';

export const schoolPickerValues = [
    'Dwight School Dubai',
    'Dwight School London',
    'Dwight School NY',
    'Dwight School Seoul',
    'Dwight School Shangai',
    'York Prep',
];

const Component: FunctionComponent<{
    name: string;
    values: SchoolEntity[];
    defaultValue?: SchoolEntity;
}> = (props) => {
    return (
        <Field
            name={props.name || 'school'}
            component={WeAutocomplete}
            options={props.values}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option) => option.id}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...(params as any)}
                    label="Select School"
                    disabled={false}
                />
            )}
        />
    );
};

const SchoolPickerForm = memo(Component);

SchoolPickerForm.displayName = 'SchoolPicker';

export { SchoolPickerForm };
