import React from 'react';
import {
    goTo,
    SectionWithTitle,
    WeChipsList,
    WeEditable,
    WeFundingList,
} from '@weco/ui';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import isEqual from 'lodash/isEqual';
import { Box, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { FundingFormControl } from '../formControls/FundingFormControl';
import { objectToTypeValue } from '../../../helpers/utils';
import { AutocompleteFormControl } from '../formControls';

export const ResourcesTabPage = () => {
    const { profile, updateProfile, constants } = useMyUserProfileData();
    const classes = useTabStyles();

    const save = (newProfile) => {
        if (isEqual(newProfile, profile)) {
            return Promise.resolve();
        } else {
            return updateProfile(newProfile);
        }
    };

    return (
        <Box
            className={classes.tabContent}
            id="container-anchor-profile-resource-required-compensations"
        >
            <Formik initialValues={profile} onSubmit={save}>
                {({ handleSubmit, values, errors }) => {
                    return (
                        <>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.section}
                                    id="container-anchor-my-profile-compensations"
                                >
                                    <WeEditable
                                        fieldId="COMPENSATIONS"
                                        hasError={!!errors.compensations}
                                        onSave={handleSubmit}
                                        classes={{ editBox: classes.editBox }}
                                        editableComponent={
                                            <AutocompleteFormControl
                                                name="compensations"
                                                label="Compensation"
                                                options={constants?.investments}
                                            />
                                        }
                                    >
                                        <SectionWithTitle
                                            title="Compensation"
                                            isEmpty={isEmpty(
                                                values.compensations,
                                            )}
                                            placeholder="Your value is priceless but maybe you could name a way to get paid for."
                                        >
                                            <WeChipsList
                                                chipsGroupName="compensations"
                                                items={values.compensations}
                                            />
                                        </SectionWithTitle>
                                    </WeEditable>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.section}
                                    id="container-anchor-my-profile-investments"
                                >
                                    <WeEditable
                                        fieldId="INVESTMENTS"
                                        hasError={!!errors.investments}
                                        onSave={handleSubmit}
                                        classes={{ editBox: classes.editBox }}
                                        editableComponent={
                                            <AutocompleteFormControl
                                                name="investments"
                                                label="Investment"
                                                options={constants?.investments}
                                            />
                                        }
                                    >
                                        <SectionWithTitle
                                            title="Investment"
                                            isEmpty={isEmpty(
                                                values.investments,
                                            )}
                                            placeholder={
                                                <div>
                                                    How can you support others’
                                                    dreams?
                                                    <br />
                                                    Remember, what goes around
                                                    comes back in multiples.
                                                </div>
                                            }
                                        >
                                            <WeChipsList
                                                chipsGroupName="investments"
                                                items={values.investments}
                                            />
                                        </SectionWithTitle>
                                    </WeEditable>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.section}
                                    id="container-anchor-my-profile-funding"
                                >
                                    <WeEditable
                                        fieldId="FUNDINGS"
                                        hasError={!!errors.fundings}
                                        onSave={handleSubmit}
                                        classes={{ editBox: classes.editBox }}
                                        editableComponent={
                                            <FundingFormControl
                                                name="fundings"
                                                label="Funding"
                                                options={constants?.fundings}
                                                helpIcon={{ showed: false }}
                                            />
                                        }
                                    >
                                        <SectionWithTitle
                                            title="Funding"
                                            isEmpty={isEmpty(values.fundings)}
                                            placeholder="WeCrowdfund each other! Tell us where we can support you."
                                        >
                                            <WeFundingList
                                                onClick={(uri) => goTo(uri)}
                                                items={objectToTypeValue(
                                                    values.fundings,
                                                )}
                                            />
                                        </SectionWithTitle>
                                    </WeEditable>
                                </Grid>
                            </Grid>
                        </>
                    );
                }}
            </Formik>
        </Box>
    );
};
