import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNotice } from './useNotice';
import { autorun } from 'mobx';

let displayed = [];

export const Notifier = () => {
    const { notifications, hide } = useNotice();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        autorun(() => {
            // sync manually closed snackbars
            displayed.forEach((id) => {
                if (!notifications.find((n) => n.key === id)) {
                    removeDisplayed(id);
                    closeSnackbar(id);
                }
            });

            notifications.forEach(({ key, content, options = {} }) => {
                // do nothing if snackbar is already displayed
                if (displayed.includes(key)) return;

                // display snackbar using notistack
                enqueueSnackbar(content, {
                    key,
                    ...options,
                    onExited: (event, myKey) => {
                        // remove this snackbar from store
                        hide(myKey);
                        removeDisplayed(myKey);
                    },
                });

                // keep track of snackbars that we've displayed
                storeDisplayed(key);
            });
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, hide]);
    return null;
};
