import React from 'react';
import { EntityLoadingSkeleton } from '@weco/ui';
import { MyProjectStore } from './MyProjectStore';
import { StoreContainer } from '../../../store/containers/StoreContainer';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../../router/RouterPaths';

export interface MyProjectContainerProps {
    children: any;
    itemId: string;
}

export const MyProjectContainer = ({
    children,
    itemId,
}: MyProjectContainerProps) => {
    const history = useHistory();
    const loader = async (store: MyProjectStore) => {
        await store.loadProjectConstants();
        return await store.loadItem(itemId, () =>
            history.push({
                pathname: `${RouterPaths.notFound}`,
                state: 'Project',
            }),
        );
    };
    return (
        <StoreContainer
            classType={MyProjectStore}
            loader={loader}
            loaderComponent={<EntityLoadingSkeleton />}
        >
            {children}
        </StoreContainer>
    );
};
