/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const matchProjects = /* GraphQL */ `
    query MatchProjects(
        $filter: ProfileMatchInput
        $query: String
        $nextToken: Int
        $limit: Int
    ) {
        matchProjects(
            filter: $filter
            query: $query
            nextToken: $nextToken
            limit: $limit
        ) {
            items {
                id
                location {
                    name
                    googlePlaceId
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                owner {
                    id
                    name
                    searchMatchScore
                }
            }
            nextToken
            total
        }
    }
`;
export const matchPeople = /* GraphQL */ `
    query MatchPeople(
        $filter: ProfileMatchInput
        $query: String
        $nextToken: Int
        $limit: Int
    ) {
        matchPeople(
            filter: $filter
            query: $query
            nextToken: $nextToken
            limit: $limit
        ) {
            items {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    searchMatchScore
                }
            }
            nextToken
            total
        }
    }
`;
export const getSkill = /* GraphQL */ `
    query GetSkill($id: ID!) {
        getSkill(id: $id) {
            id
            departmentName
            value
            refCount
            createdAt
            updatedAt
        }
    }
`;
export const listSkills = /* GraphQL */ `
    query ListSkills(
        $id: ID
        $filter: ModelSkillFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSkills(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                departmentName
                value
                refCount
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getNotification = /* GraphQL */ `
    query GetNotification(
        $receipientId: ID!
        $subjectType: subjectTypeEnum!
        $subjectId: String!
        $createdAt: AWSDateTime!
    ) {
        getNotification(
            receipientId: $receipientId
            subjectType: $subjectType
            subjectId: $subjectId
            createdAt: $createdAt
        ) {
            receipientId
            subjectType
            subjectId
            status
            type
            parameters {
                name
                value
            }
            createdAt
            updatedAt
        }
    }
`;
export const listNotifications = /* GraphQL */ `
    query ListNotifications(
        $receipientId: ID
        $subjectTypeSubjectIdCreatedAt: ModelNotificationPrimaryCompositeKeyConditionInput
        $filter: ModelNotificationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listNotifications(
            receipientId: $receipientId
            subjectTypeSubjectIdCreatedAt: $subjectTypeSubjectIdCreatedAt
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                receipientId
                subjectType
                subjectId
                status
                type
                parameters {
                    name
                    value
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const departmentSkillsByValue = /* GraphQL */ `
    query DepartmentSkillsByValue(
        $departmentName: String
        $value: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSkillFilterInput
        $limit: Int
        $nextToken: String
    ) {
        departmentSkillsByValue(
            departmentName: $departmentName
            value: $value
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                departmentName
                value
                refCount
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const departmentSkillsByRefCount = /* GraphQL */ `
    query DepartmentSkillsByRefCount(
        $departmentName: String
        $refCount: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelSkillFilterInput
        $limit: Int
        $nextToken: String
    ) {
        departmentSkillsByRefCount(
            departmentName: $departmentName
            refCount: $refCount
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                departmentName
                value
                refCount
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const searchSkills = /* GraphQL */ `
    query SearchSkills(
        $filter: SearchableSkillFilterInput
        $sort: SearchableSkillSortInput
        $limit: Int
        $nextToken: String
    ) {
        searchSkills(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                departmentName
                value
                refCount
                createdAt
                updatedAt
            }
            nextToken
            total
        }
    }
`;
export const listPersons = /* GraphQL */ `
    query ListPersons(
        $filter: ModelPersonFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPersons(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
export const getPerson = /* GraphQL */ `
    query GetPerson($id: ID!) {
        getPerson(id: $id) {
            id
            name
            lastName
            alias
            url
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            coverImage {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            dream
            departments
            skills
            industries
            contacts
            socialMedia
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            settings {
                canInvite
                badges
                privacy {
                    profileVisibility
                    contactsVisibility
                }
                searches {
                    name
                    type
                    query
                    skills
                    departments
                    causes
                    industries
                    compensation
                    location
                    languages
                    passions
                    compensations
                    objectives
                    stages
                    fundings
                }
                appLanguage
                totalCounts
            }
            causes
            compensations
            languages
            objectives
            passions
            fundings
            wishList
            investments
            tradings
            stores {
                label
                link
            }
            graduationYear
            hasFinishedOnboarding
            accountType
            createdAt
            updatedAt
            searchMatchScore
            highlight {
                name
                dream
                skills
                lastName
                alias
                url
                departments
                industries
                contacts
                socialMedia
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores
            }
            activeProject {
                id
                location {
                    name
                    googlePlaceId
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            school {
                id
                name
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                location {
                    name
                    googlePlaceId
                }
                createdAt
                updatedAt
            }
        }
    }
`;
export const searchPersons = /* GraphQL */ `
    query SearchPersons(
        $filter: SearchablePersonFilterInput
        $sort: SearchablePersonSortInput
        $limit: Int
        $nextToken: String
    ) {
        searchPersons(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            nextToken
            total
        }
    }
`;
export const listProjects = /* GraphQL */ `
    query ListProjects(
        $filter: ModelProjectFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                location {
                    name
                    googlePlaceId
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
export const getProject = /* GraphQL */ `
    query GetProject($id: ID!) {
        getProject(id: $id) {
            id
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            name
            alias
            url
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            dream
            departments
            skills
            causes
            industries
            contacts
            socialMedia
            settings {
                privacy {
                    profileVisibility
                    contactsVisibility
                }
                totalCounts
            }
            stage
            status
            compensations
            languages
            objectives
            passions
            fundings
            wishList
            investments
            tradings
            stores {
                label
                link
            }
            createdAt
            updatedAt
            searchMatchScore
            highlight {
                name
                dream
                skills
                lastName
                alias
                url
                departments
                industries
                contacts
                socialMedia
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores
            }
            owner {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            school {
                id
                name
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                location {
                    name
                    googlePlaceId
                }
                createdAt
                updatedAt
            }
        }
    }
`;
export const projectByName = /* GraphQL */ `
    query ProjectByName(
        $name: String
        $sortDirection: ModelSortDirection
        $filter: ModelProjectFilterInput
        $limit: Int
        $nextToken: String
    ) {
        projectByName(
            name: $name
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                location {
                    name
                    googlePlaceId
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
export const searchProjects = /* GraphQL */ `
    query SearchProjects(
        $filter: SearchableProjectFilterInput
        $sort: SearchableProjectSortInput
        $limit: Int
        $nextToken: String
    ) {
        searchProjects(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                location {
                    name
                    googlePlaceId
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            nextToken
            total
        }
    }
`;
export const listDepartments = /* GraphQL */ `
    query ListDepartments(
        $filter: ModelDepartmentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                isSelected
                description
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getDepartment = /* GraphQL */ `
    query GetDepartment($id: ID!) {
        getDepartment(id: $id) {
            id
            name
            isSelected
            description
            createdAt
            updatedAt
        }
    }
`;
export const searchDepartments = /* GraphQL */ `
    query SearchDepartments(
        $filter: SearchableDepartmentFilterInput
        $sort: SearchableDepartmentSortInput
        $limit: Int
        $nextToken: String
    ) {
        searchDepartments(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                isSelected
                description
                createdAt
                updatedAt
            }
            nextToken
            total
        }
    }
`;
export const getPersonProjectConnection = /* GraphQL */ `
    query GetPersonProjectConnection($personId: String!, $projectId: String!) {
        getPersonProjectConnection(personId: $personId, projectId: $projectId) {
            personId
            projectId
            roles
            skillSets {
                roleId
                status
            }
            createdAt
            updatedAt
            person {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            project {
                id
                location {
                    name
                    googlePlaceId
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;
export const listPersonProjectConnections = /* GraphQL */ `
    query ListPersonProjectConnections(
        $personId: String
        $projectId: ModelStringKeyConditionInput
        $filter: ModelPersonProjectConnectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listPersonProjectConnections(
            personId: $personId
            projectId: $projectId
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                personId
                projectId
                roles
                skillSets {
                    roleId
                    status
                }
                createdAt
                updatedAt
                person {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                project {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
            }
            nextToken
        }
    }
`;
export const projectPersonsConnection = /* GraphQL */ `
    query ProjectPersonsConnection(
        $projectId: String
        $personId: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelPersonProjectConnectionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ProjectPersonsConnection(
            projectId: $projectId
            personId: $personId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                personId
                projectId
                roles
                skillSets {
                    roleId
                    status
                }
                createdAt
                updatedAt
                person {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                project {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
            }
            nextToken
        }
    }
`;
export const searchPersonProjectConnections = /* GraphQL */ `
    query SearchPersonProjectConnections(
        $filter: SearchablePersonProjectConnectionFilterInput
        $sort: SearchablePersonProjectConnectionSortInput
        $limit: Int
        $nextToken: String
    ) {
        searchPersonProjectConnections(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                personId
                projectId
                roles
                skillSets {
                    roleId
                    status
                }
                createdAt
                updatedAt
                person {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                project {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
            }
            nextToken
            total
        }
    }
`;
export const getNotificationCounter = /* GraphQL */ `
    query GetNotificationCounter(
        $receipientId: ID!
        $subjectType: subjectTypeEnum!
        $subjectId: String!
    ) {
        getNotificationCounter(
            receipientId: $receipientId
            subjectType: $subjectType
            subjectId: $subjectId
        ) {
            receipientId
            subjectType
            subjectId
            count
            createdAt
            updatedAt
        }
    }
`;
export const listNotificationCounters = /* GraphQL */ `
    query ListNotificationCounters(
        $receipientId: ID
        $subjectTypeSubjectId: ModelNotificationCounterPrimaryCompositeKeyConditionInput
        $filter: ModelNotificationCounterFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listNotificationCounters(
            receipientId: $receipientId
            subjectTypeSubjectId: $subjectTypeSubjectId
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getDictionary = /* GraphQL */ `
    query GetDictionary($id: ID!) {
        getDictionary(id: $id) {
            id
            type
            label
            value
            score
            createdAt
            updatedAt
        }
    }
`;
export const listDictionarys = /* GraphQL */ `
    query ListDictionarys(
        $id: ID
        $filter: ModelDictionaryFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDictionarys(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                type
                label
                value
                score
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const dictionaryByScore = /* GraphQL */ `
    query DictionaryByScore(
        $type: String
        $score: ModelIntKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDictionaryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        dictionaryByScore(
            type: $type
            score: $score
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                type
                label
                value
                score
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const searchDictionarys = /* GraphQL */ `
    query SearchDictionarys(
        $filter: SearchableDictionaryFilterInput
        $sort: SearchableDictionarySortInput
        $limit: Int
        $nextToken: String
    ) {
        searchDictionarys(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                type
                label
                value
                score
                createdAt
                updatedAt
            }
            nextToken
            total
        }
    }
`;
export const getSkillSet = /* GraphQL */ `
    query GetSkillSet($id: ID!) {
        getSkillSet(id: $id) {
            id
            departmentId
            skills
            name
            description
            ownerId
            projectId
            isSelected
            createdAt
            updatedAt
            owner {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            project {
                id
                location {
                    name
                    googlePlaceId
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            department {
                id
                name
                isSelected
                description
                createdAt
                updatedAt
            }
        }
    }
`;
export const listSkillSets = /* GraphQL */ `
    query ListSkillSets(
        $filter: ModelSkillSetFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listSkillSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                departmentId
                skills
                name
                description
                ownerId
                projectId
                isSelected
                createdAt
                updatedAt
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                project {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                department {
                    id
                    name
                    isSelected
                    description
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
export const skillSetByDepartment = /* GraphQL */ `
    query SkillSetByDepartment(
        $departmentId: ID
        $sortDirection: ModelSortDirection
        $filter: ModelSkillSetFilterInput
        $limit: Int
        $nextToken: String
    ) {
        skillSetByDepartment(
            departmentId: $departmentId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                departmentId
                skills
                name
                description
                ownerId
                projectId
                isSelected
                createdAt
                updatedAt
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                project {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                department {
                    id
                    name
                    isSelected
                    description
                    createdAt
                    updatedAt
                }
            }
            nextToken
        }
    }
`;
export const listSchools = /* GraphQL */ `
    query ListSchools(
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                location {
                    name
                    googlePlaceId
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const getSchool = /* GraphQL */ `
    query GetSchool($id: ID!) {
        getSchool(id: $id) {
            id
            name
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            createdAt
            updatedAt
        }
    }
`;
export const getPassedTestResult = /* GraphQL */ `
    query GetPassedTestResult($id: ID!) {
        getPassedTestResult(id: $id) {
            id
            type
            answers {
                questionId
                question
                option
            }
            ownerId
            results {
                points
                department
            }
            status
            createdAt
            finishedAt
            updatedAt
            owner {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;
export const listPassedTestResults = /* GraphQL */ `
    query ListPassedTestResults(
        $filter: ModelPassedTestResultFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listPassedTestResults(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                type
                answers {
                    questionId
                    question
                    option
                }
                ownerId
                results {
                    points
                    department
                }
                status
                createdAt
                finishedAt
                updatedAt
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    accountType
                    createdAt
                    updatedAt
                    searchMatchScore
                }
            }
            nextToken
        }
    }
`;

export const getCool = /* GraphQL */ `
    query GetCool($id: ID!) {
        getCool(id: $id) {
            id
            ownerId
            entityType
            entityId
            createdAt
            updatedAt
            owner {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                    totalCounts
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                accountType
                createdAt
                updatedAt
                searchMatchScore
                highlight {
                    name
                    dream
                    skills
                    lastName
                    alias
                    url
                    departments
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    stores
                }
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    searchMatchScore
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;

export const listCools = /* GraphQL */ `
    query ListCools(
        $filter: ModelCoolFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listCools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                ownerId
                entityType
                entityId
                createdAt
                updatedAt
                owner {
                    id
                    name
                    lastName
                    url
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`;

export const coolByEntityId = /* GraphQL */ `
    query CoolByEntityId(
        $entityType: String
        $entityId: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCoolFilterInput
        $limit: Int
        $nextToken: String
    ) {
        CoolByEntityId(
            entityType: $entityType
            entityId: $entityId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                ownerId
                entityType
                entityId
                createdAt
                updatedAt
                owner {
                    id
                    name
                    lastName
                    url
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`;

export const coolByOwnerIdEntityId = /* GraphQL */ `
    query CoolByOwnerIdEntityId(
        $ownerId: ID
        $entityTypeEntityId: ModelCoolCoolByOwnerIdEntityIdCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelCoolFilterInput
        $limit: Int
        $nextToken: String
    ) {
        CoolByOwnerIdEntityId(
            ownerId: $ownerId
            entityTypeEntityId: $entityTypeEntityId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                ownerId
                entityType
                entityId
                createdAt
                updatedAt
                owner {
                    id
                    name
                    lastName
                    url
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`;
