import { Box, Grid, Typography } from '@material-ui/core';
import { Field, Formik, FormikProps } from 'formik';
import React from 'react';
import { objectToTypeValue } from '../../../helpers/utils';
import { ContactsFormControl } from '../formControls/ContactsFormControl';
import { AutocompleteFormControl } from '../formControls/AutocompleteFormControl';
import { SocialMediaFormControl } from '../formControls/SocialMediaFormControl';
import { useScrollTopHelper, WeChip, WeMultilineText } from '@weco/ui';
import { useMyProjectInfoStyles } from './MyProjectInfo.styles';

import {
    WeEditable,
    WeTextField,
    WeChipsList,
    WeContactList,
    WeSocialMediaList,
    SectionWithTitle,
    LocationAutoComplete,
    WeRadioGroup,
} from '@weco/ui';
import { Link, useLocation } from 'react-router-dom';
import { ProjectEntity } from '@weco/core';
import isEmpty from 'lodash/isEmpty';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { DynamicChipsFormControl } from '../formControls/DynamicChipsFormControl';
import { getValidationResults } from '@weco/common';
import debug from 'debug';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import { RouterPaths } from '../../../router/RouterPaths';
import { useMyProjectData } from '../store/useMyProjectData';

const log = debug('MyProjectInfo');

interface Props {
    projectId: string;
}

export const MyProjectInfo = ({ projectId }: Props) => {
    const cx = useMyProjectInfoStyles();
    const tabClasses = useTabStyles();
    const { state } = useLocation() as any;
    const fromBanner = state?.fromBanner;
    const {
        constants,
        item: myProject,
        updateItem: updateProject,
    } = useMyProjectData(projectId);

    if (fromBanner) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollTopHelper();
    }

    return (
        <Box className={tabClasses.tabContent}>
            <Formik
                enableReinitialize
                onSubmit={updateProject}
                validate={getValidationResults}
                initialValues={myProject}
            >
                {({
                    handleSubmit,
                    values,
                    errors,
                    isValid,
                }: FormikProps<ProjectEntity>) => {
                    log('form values: %O', values);
                    return (
                        <Grid container>
                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    hasError={!!errors.dream}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <SectionWithTitle title="Project Mission">
                                            <Field
                                                name="dream"
                                                component={WeTextField}
                                                multiline
                                            />
                                        </SectionWithTitle>
                                    }
                                >
                                    <SectionWithTitle
                                        title="Project Mission"
                                        isEmpty={!values.dream}
                                        placeholder="In a few words, inspire great people to join your project."
                                    >
                                        <Typography
                                            component="div"
                                            className={cx.infoText}
                                        >
                                            <WeMultilineText
                                                text={values.dream}
                                            />
                                        </Typography>
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    hasError={!!errors.causes}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <AutocompleteFormControl
                                            name="causes"
                                            label="Causes"
                                            options={constants.causes}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Causes"
                                        isEmpty={isEmpty(values.causes)}
                                        placeholder="What are the grand purposes that unite your team?"
                                    >
                                        <WeChipsList items={values.causes} />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    hasError={!!errors.passions}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <DynamicChipsFormControl
                                            name="passions"
                                            label="Tags"
                                            lowerCase
                                            isOpenField
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Tags"
                                        isEmpty={isEmpty(values.passions)}
                                        placeholder="Hobbies or activities related to the project. These could be your dream team magnets."
                                    >
                                        <WeChipsList items={values.passions} />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    hasError={!!errors.objectives}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <AutocompleteFormControl
                                            name="objectives"
                                            label="Objectives"
                                            options={constants.objectives}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Objectives"
                                        isEmpty={isEmpty(values.objectives)}
                                        placeholder="Describe the type of connections needed for this project to become an amazing reality!"
                                    >
                                        <WeChipsList
                                            items={values.objectives}
                                        />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    hasError={!!errors.status}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <FormControl>
                                            <SectionWithTitle title="Status">
                                                <Field
                                                    name="status"
                                                    component={WeRadioGroup}
                                                >
                                                    {constants.statuses.map(
                                                        (item) => (
                                                            <FormControlLabel
                                                                key={item}
                                                                value={item}
                                                                label={item}
                                                                control={
                                                                    <Radio />
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                </Field>
                                            </SectionWithTitle>
                                        </FormControl>
                                    }
                                >
                                    <SectionWithTitle
                                        title="Status"
                                        isEmpty={isEmpty(values.status)}
                                        placeholder="How far along is your project? Are we starting, finishing or in the middle of something?"
                                    >
                                        <Typography className={cx.infoText}>
                                            {values.status}
                                        </Typography>
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>
                            {/* NOTE: hidden until Dwight school collaboration will be established. */}
                            {/*<Grid item xs={12} className={tabClasses.section}>*/}
                            {/*    <WeEditable*/}
                            {/*        hasError={!!errors.school}*/}
                            {/*        onSave={handleSubmit}*/}
                            {/*        editableComponent={*/}
                            {/*            <SectionWithTitle*/}
                            {/*                title="Schools"*/}
                            {/*                children={*/}
                            {/*                    <SchoolPickerForm*/}
                            {/*                        name="school"*/}
                            {/*                        values={*/}
                            {/*                            props.constants.schools*/}
                            {/*                        }*/}
                            {/*                    />*/}
                            {/*                }*/}
                            {/*            />*/}
                            {/*        }*/}
                            {/*    >*/}
                            {/*        <SectionWithTitle*/}
                            {/*            title="Schools"*/}
                            {/*            isEmpty={isEmpty(values.school)}*/}
                            {/*        />*/}
                            {/*        <WeChipsList*/}
                            {/*            items={[values.school?.name]}*/}
                            {/*        />*/}
                            {/*    </WeEditable>*/}
                            {/*</Grid>*/}

                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    // TODO does this even work?
                                    hasError={!!errors.location}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <SectionWithTitle
                                            title="Location"
                                            children={
                                                <LocationAutoComplete
                                                    currentSelection={
                                                        values?.location
                                                    }
                                                />
                                            }
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Location"
                                        isEmpty={isEmpty(values.location)}
                                        placeholder="Do you plan on working remotely or do you have a physical location?"
                                    >
                                        <Typography className={cx.infoText}>
                                            {myProject?.location?.name}
                                        </Typography>
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>
                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    hasError={!!errors.contacts}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <AutocompleteFormControl
                                            label="Languages"
                                            name="languages"
                                            options={constants.languages}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Languages"
                                        isEmpty={isEmpty(values.languages)}
                                        placeholder="We are only a team if we can understand each other. Name the languages you speak."
                                    >
                                        <WeChipsList
                                            chipItemComponent={({
                                                item,
                                                index,
                                            }) => (
                                                <Link
                                                    className={
                                                        tabClasses.chipLink
                                                    }
                                                    key={index}
                                                    to={{
                                                        pathname:
                                                            RouterPaths.search
                                                                .projects,
                                                        state: {
                                                            searchTag: item,
                                                        },
                                                    }}
                                                >
                                                    <WeChip item={item} />
                                                </Link>
                                            )}
                                            items={values.languages}
                                        />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>
                            <Grid item xs={12} className={tabClasses.section}>
                                <WeEditable
                                    hasError={!!errors.contacts}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <ContactsFormControl
                                            name="contacts"
                                            label="Contacts"
                                            options={constants.contacts}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Contacts"
                                        isEmpty={isEmpty(values.contacts)}
                                    >
                                        <WeContactList
                                            onClick={console.log}
                                            items={objectToTypeValue(
                                                values.contacts,
                                            )}
                                        />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid item xs={12}>
                                <WeEditable
                                    hasError={!!errors.socialMedia}
                                    isFormValid={isValid}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <SocialMediaFormControl
                                            name="socialMedia"
                                            label="Social Media"
                                            options={constants.socialMedia}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Social Media"
                                        isEmpty={isEmpty(values.socialMedia)}
                                        placeholder="Tell us if your project is somewhere else on the internet. We promise we won’t get jealous."
                                    >
                                        <WeSocialMediaList
                                            onClick={console.log}
                                            items={objectToTypeValue(
                                                values.socialMedia,
                                            )}
                                        />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </Box>
    );
};
