import React, { memo, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';
import MainLayout from '../components/layout/MainLayout';
import MinimalisticLayout from '../components/layout/MinimalisticLayout';
import { ConfirmSignUpPage } from '../pages/auth/ConfirmSignUpPage';
import { LoginPage } from '../pages/auth/LoginPage';
import { SignUpPage } from '../pages/auth/SignUpPage';
import { ChatPage } from '../pages/chat/ChatPage';
import { FavoritesPage } from '../pages/favorites/FavoritesPage';
import { HelpPage } from '../pages/help/HelpPage';
import { MainPage } from '../pages/main/MainPage';
import { MePage } from '../pages/me/MePage';
import { NotificationsPage } from '../pages/notifications/NotificationsPage';
import { OnboardingPage } from '../pages/onboarding/OnboardingPage';
import { ProfilePage } from '../pages/profile/person/ProfilePage';
import { ProjectPage } from '../pages/profile/project/ProjectPage';
import { SearchPage } from '../pages/search/SearchPage';
import { SettingsPage } from '../pages/settings/SettingsPage';
import { RouterPaths } from './RouterPaths';
import { WeRoute } from './WeRoute';
import { useCurrentAuthData } from '../store/hooks/useCurrentAuthData';
import { AuthCallbackPage } from '../pages/authCallback/AuthCallbackPage';
import { QuizPage } from '../pages/quiz/QuizPage';
import { OurPhilosophyPage } from '../pages/about/OurPhilosophyPage';
import { NotFoundPage } from './NotFoundPage';
import { CurrentUserProfileContainer } from '../store/containers/CurrentUserProfileContainer';
import { SearchContainer } from '../pages/search/SearchContainer';
import { AdvancedSearchPageContainer } from '../pages/advancedSearch/AdvancedSearchPageContainer';
import { ConnectionsPageContainer } from '../pages/connections/ConnectionsPageContainer';
import UnauthenticatedLayout from '../components/layout/UnauthenticatedLayout';
import AuthorizationLayout from '../components/layout/AuthorizationLayout';
import { ForgotPasswordPage } from '../pages/auth/ForgotPasswordPage';
import { RedirectPage } from '../pages/RedirectPage';
import { environment } from '../../environments/environment';
import { useQuery } from '../hooks/useQuery';
import { useNotice } from '@weco/ui';
import { TermsPage } from '../pages/TermsPage';

export const AppRoutes = memo(({}) => {
    const { token } = useCurrentAuthData();
    const { search } = useLocation();
    const query = useQuery();
    const { error } = useNotice();

    const defaultLayout = token.isAuthenticated()
        ? MainLayout
        : // : BasicLayout;
          UnauthenticatedLayout;

    const defaultProps: any = {
        path: '/',
    };
    useEffect(() => {
        if (query.has('error_description')) {
            error(query.get('error_description'));
        } else {
            if (!token.getUser()?.id) {
                defaultProps.render = () => {
                    window.location.href = environment.landingPageLogout;
                    return '';
                };
            }
        }
    }, []);

    return (
        <Switch key={token.getUser()?.id || 'public'}>
            <WeRoute layout={MinimalisticLayout} path={RouterPaths.redirect}>
                <RedirectPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={RouterPaths.notFound}
            >
                <NotFoundPage />
            </WeRoute>

            {/*
                PUBLIC ROUTES
            */}
            <WeRoute path={TermsPage.URL} layout={defaultLayout}>
                <TermsPage />
            </WeRoute>
            <WeRoute path={OurPhilosophyPage.URL} layout={defaultLayout}>
                <OurPhilosophyPage />
            </WeRoute>
            <WeRoute path={`${ProfilePage.URL}/:id`} layout={defaultLayout}>
                <ProfilePage />
            </WeRoute>
            <WeRoute
                path={`${RouterPaths.projectPage}/:id`}
                layout={defaultLayout}
            >
                <ProjectPage />
            </WeRoute>
            <WeRoute path={LoginPage.URL} layout={AuthorizationLayout}>
                <LoginPage />
            </WeRoute>
            <WeRoute path={ForgotPasswordPage.URL} layout={AuthorizationLayout}>
                <ForgotPasswordPage />
            </WeRoute>
            <WeRoute path={SignUpPage.URL}>
                <SignUpPage />
            </WeRoute>
            <WeRoute
                path={RouterPaths.confirmCallBack}
                layout={MinimalisticLayout}
            >
                <ConfirmSignUpPage />
            </WeRoute>
            <WeRoute exact path={RouterPaths.onboarding}>
                <Redirect to={`${RouterPaths.onboarding}/0`} />
            </WeRoute>
            <WeRoute
                layout={MinimalisticLayout}
                path={`${RouterPaths.onboarding}/:step`}
            >
                <CurrentUserProfileContainer>
                    <OnboardingPage />
                </CurrentUserProfileContainer>
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MinimalisticLayout}
                path={RouterPaths.quiz}
            >
                <CurrentUserProfileContainer>
                    <QuizPage />
                </CurrentUserProfileContainer>
            </WeRoute>
            <WeRoute
                path={RouterPaths.authCallBack}
                layout={MinimalisticLayout}
            >
                <AuthCallbackPage />
            </WeRoute>

            <WeRoute
                layout={defaultLayout}
                path={RouterPaths.advancedSearch.root}
            >
                <SearchContainer>
                    <AdvancedSearchPageContainer />
                </SearchContainer>
            </WeRoute>
            <WeRoute layout={defaultLayout} path={RouterPaths.search.root}>
                <SearchContainer>
                    <SearchPage />
                </SearchContainer>
            </WeRoute>

            {/*
                AUTHENTICATED ROUTES
            */}
            <WeRoute isAuthenticated layout={MainLayout} path={RouterPaths.me}>
                <MePage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                path={`${ProfilePage.URL}/:id`}
                layout={MainLayout}
            >
                <ProfilePage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                path={`${RouterPaths.projectPage}/:id`}
                layout={MainLayout}
            >
                <ProjectPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={RouterPaths.dashboard}
            >
                <MainPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={`${ChatPage.URL}/:type/:id`}
            >
                <ChatPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={RouterPaths.chat.root}
            >
                <ChatPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={FavoritesPage.URL}
            >
                <FavoritesPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={SettingsPage.URL}
            >
                <SettingsPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={RouterPaths.help}
            >
                <HelpPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={NotificationsPage.URL}
            >
                <NotificationsPage />
            </WeRoute>
            <WeRoute
                isAuthenticated
                layout={MainLayout}
                path={RouterPaths.connections.root}
            >
                <ConnectionsPageContainer />
            </WeRoute>

            {/*
                DEFAULT ROUTE
            */}
            <WeRoute {...defaultProps}>
                <Redirect to={`/dashboard${search}`} />
            </WeRoute>
        </Switch>
    );
});
