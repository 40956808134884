import React, { useState } from 'react';
import { Button, Grid, IconButton, Theme, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { RouterPaths } from '../../router/RouterPaths';
import { DeleteDialogView, useScrollTopHelper } from '@weco/ui';
import { useHistory } from 'react-router';
import { useMyUserData } from '../../store/hooks/useMyUserData';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import { APP_EVENTS } from '../../../app_events';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export const useStyles = makeStyles((theme: Theme) => ({
    content: {
        marginTop: '40px',
        padding: '0 10%',
    },
    button: {
        background: 'linear-gradient(86.51deg, #544F94 13.51%, #755DD8 72.7%)',
        borderRadius: '10px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 500,
        width: '100%',
    },
}));

export const SettingsPage = () => {
    const cx = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const history = useHistory();
    const { profile, deleteAccount } = useMyUserData();
    const { signout } = useCurrentAuthData();

    useScrollTopHelper();

    const processDeletion = (id: string) => {
        return deleteAccount(id).then(() => signout());
    };

    return (
        <Box className={cx.content}>
            <Grid item xs={12}>
                <IconButton
                    onClick={() => history.push(RouterPaths.myProfile)}
                    edge="start"
                >
                    <ArrowBackIosIcon />
                    <Typography variant="h2">Settings</Typography>
                </IconButton>
                <Box>
                    <Button
                        id={APP_EVENTS.DELETE_PROFILE}
                        variant="contained"
                        className={cx.button}
                        onClick={() => {
                            setOpenDialog(true);
                        }}
                    >
                        Delete Account
                    </Button>
                </Box>
                <DeleteDialogView
                    itemName="Account"
                    id={profile.id}
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    deleteAction={processDeletion}
                    history={history}
                    resultPath={RouterPaths.dashboard}
                />
            </Grid>
        </Box>
    );
};

SettingsPage.URL = '/settings';
