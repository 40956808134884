import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getPictureUrl } from '@weco/common';
import { defaultPicture, SchoolEntity } from '@weco/core';
import React, { memo } from 'react';
import { Avatar } from './Avatar';
import { SchoolCard } from './SchoolCard';

const useStyles = makeStyles((theme: Theme) => ({}));

interface Props {
    items: SchoolEntity[];
    isAuthenticated?: boolean;
}

const ListSchools = ({ items, isAuthenticated }: Props) => {
    return (
        <Grid container spacing={2} justify="center">
            {items.map((item, index) => {
                const { name, picture, location } = item;

                return (
                    <Grid key={index} item xs={12} sm={6} md={4}>
                        <SchoolCard
                            isAuthenticated={isAuthenticated}
                            title={name}
                            subTitle={'New York'}
                            avatar={
                                <Avatar
                                    isAuthenticated={isAuthenticated}
                                    picture={getPictureUrl(
                                        picture,
                                        defaultPicture,
                                    )}
                                    shape="round"
                                    percentagePlacement="none"
                                    size="xs-small"
                                />
                            }
                            matchPercent={88}
                            isFavorite
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const SchoolsList = memo(ListSchools);
