import React from 'react';
import { CardMedia, Grid, IconButton, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FullLogo from '../../../../../assets/images/full-logo-beta.svg';
import AvatarIcon from '../../../molecules/AvatarIcon';
import {
    useLeftSideBarStyles,
    useLeftSideBarListStyles,
    useLeftSideBarProjectListStyles,
} from './styles/LeftSideBar.styles';
import { LeftSidebarMenuList } from '../components/LeftSidebarMenuList';
import MyProjectsView from '../../../myProjects/MyProjectsView';
import { useMyUserProfileData } from '../../../../store/hooks/useMyUserProfileData';
import { useHistory } from 'react-router';
import { SpriteSvgIconButton, AvoidBottomBarInMobileBrowsers } from '@weco/ui';
import { RouterPaths } from '../../../../router/RouterPaths';
import { MePage } from '../../../../pages/me/MePage';

export interface LeftSideBarProps {
    toggle: () => void;
}

const LeftSideBar = ({ toggle }: LeftSideBarProps) => {
    const { push } = useHistory();
    const classes = useLeftSideBarStyles();
    const listClasses = useLeftSideBarListStyles();
    const projectListClasses = useLeftSideBarProjectListStyles();

    const { profile, name, lastName } = useMyUserProfileData();

    function closeSidebarAndRedirectTo(path) {
        return () => {
            toggle();
            push(path);
        };
    }

    return (
        <Box className={classes.root}>
            <Grid container className={classes.topMenu}>
                <Grid item xs={2} />
                <Grid item xs={8} className={classes.buttonBox}>
                    <IconButton style={{ padding: 20 }}>
                        <CardMedia
                            src={FullLogo}
                            component="img"
                            className={classes.logoImage}
                        />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    xs={2}
                    onClick={toggle}
                    className={classes.buttonBox}
                >
                    <SpriteSvgIconButton
                        id="MenuIcon-Close"
                        classes={{
                            root: classes.button,
                            svg: classes.buttonSvg,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className="p-20">
                <Grid
                    item
                    xs={12}
                    style={{ overflow: 'hidden', padding: '5px 0' }}
                >
                    <AvatarIcon
                        sizes="x-large"
                        picture={profile?.picture}
                        style={{ margin: '0 auto' }}
                        onClick={closeSidebarAndRedirectTo(RouterPaths.me)}
                    />
                </Grid>
                <Grid
                    xs={12}
                    item
                    container
                    justify="center"
                    alignItems="center"
                >
                    <Typography
                        variant="h2"
                        className={classes.name}
                        onClick={closeSidebarAndRedirectTo(RouterPaths.me)}
                    >
                        {name} {lastName}
                    </Typography>
                </Grid>
            </Grid>

            <div
                className={projectListClasses.expansionRoot}
                id="container-anchor-projects-list"
            >
                <MyProjectsView
                    toggle={toggle}
                    classes={{ ...projectListClasses, ...listClasses }}
                />
            </div>
            <LeftSidebarMenuList
                toggle={toggle}
                classes={listClasses}
                className={classes.menuList}
            />
            <AvoidBottomBarInMobileBrowsers />
        </Box>
    );
};

export default LeftSideBar;
