import { Box, Grid, Typography } from '@material-ui/core';
import {
    // SchoolsList,
    ProjectsList,
    PeopleList,
} from '@weco/ui';
import React from 'react';
import { MainPageFilter } from '../../helpers/CommonTypes';
import { useMainPageStyles } from './MainPage.styles';
import { PeopleListContainer } from './People/PeopleListContainer';
import { ProjectListContainer } from './Projects/ProjectListContainer';
// import { SchoolsListContainer } from './Schools/SchoolsListContainer';
// import { useSchoolsData } from './Schools/store/useSchoolsData';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../../router/RouterPaths';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '../../hooks/useQuery';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import HeaderSteps from './HeaderStepper/HeaderSteps';
import { useMyUserProfileData } from '../../store/hooks/useMyUserProfileData';
import { useProjectData } from './Projects/store/useProjectData';
import { usePeopleData } from './People/store/usePeopleData';
import { useChatData } from '../../store/hooks/useChatData';
import { openChat } from '../../helpers/utils';

export const MainPage = () => {
    const classes = useMainPageStyles();
    // const { items: schools } = useSchoolsData();
    const { items: projects } = useProjectData();
    const { items: people } = usePeopleData();
    const { isAuthenticated, user } = useCurrentAuthData();
    const {
        profile,
        userConnections,
        areConnectionsLoading,
    } = useMyUserProfileData();
    const { currentUserId } = useMyUserProfileData();
    const { createRoom } = useChatData();
    const filters: MainPageFilter[] = [
        { label: 'Best Match', value: '1' },
        { label: 'Trending', value: '2' },
        { label: 'Near you', value: '3' },
        { label: 'Look for investment', value: '4' },
    ];

    const history = useHistory();
    const { search } = useLocation();
    const query = useQuery();

    // This is a workaround to fix redirection after social auth.
    // NOTE: see OnboardingSingUpStep for explanation.
    // const isRedirectionRequired = localStorage.getItem(
    //     localStorageIsRedirectionRequiredKey
    // );

    if (query.has('userPoolId')) {
        history.push({
            search,
            pathname: `${RouterPaths.confirmCallBack}`,
        });
        return <>Redirects...</>;
    }

    const userHasAtLeastOneTeammate = () => {
        let foundOne = false;
        userConnections.forEach((item) => {
            if (
                (item.personId === currentUserId &&
                    item.roles.indexOf('member') > -1 &&
                    item.roles.indexOf('owner') < 0) ||
                (item.personId !== currentUserId &&
                    item.roles.indexOf('member') > -1)
            ) {
                foundOne = true;
            }
        });
        return foundOne;
    };

    // if (isRedirectionRequired === 'true') {
    //     localStorage.removeItem(localStorageIsRedirectionRequiredKey);
    //     history.push({
    //         pathname: `${RouterPaths.authCallBack}`,
    //         search: `${search}`,
    //     });
    // }

    // if (isAuthenticated && profile && !profile.hasFinishedOnboarding) {
    //     history.replace(`${RouterPaths.onboarding}/${1}`);
    // }

    return (
        <>
            {!areConnectionsLoading && !userHasAtLeastOneTeammate() && (
                <HeaderSteps name={profile?.name} />
            )}
            <Box className={classes.root}>
                <Box>
                    <TitleWithLinkToSearchPage
                        title="Projects"
                        href={RouterPaths.search.projects}
                    />
                    <Box mt={2}>
                        <ProjectListContainer
                            filters={filters}
                            numberOfItemsDuringLoading={6}
                        >
                            <ProjectsList
                                items={projects}
                                currentUserId={user?.id}
                            />
                        </ProjectListContainer>
                    </Box>
                </Box>
                <TitleWithLinkToSearchPage
                    title="People"
                    href={RouterPaths.search.people}
                />
                <Box mt={2}>
                    <PeopleListContainer
                        filters={filters}
                        numberOfItemsDuringLoading={6}
                    >
                        {() => (
                            <PeopleList
                                items={people}
                                currentUserId={user?.id}
                            />
                        )}
                    </PeopleListContainer>
                </Box>
                {/* NOTE: hidden until Dwight school collaboration will be established. */}
                {/* <SectionTitle
                    hideSeeAllLink
                    title="Schools"
                    href={RouterPaths.search.schools}
                /> */}
                {/* <Box mt={2}>
                    <SchoolsListContainer>
                        {() => <SchoolsList items={schools} />}
                    </SchoolsListContainer>
                </Box> */}
            </Box>
        </>
    );
};

function TitleWithLinkToSearchPage(props: {
    title: string;
    href: string;
    hideSeeAllLink?: boolean;
}) {
    const classes = useMainPageStyles();
    return (
        <Grid
            item
            xs={12}
            container
            alignItems="flex-end"
            justify="space-between"
            className={classes.sectionTitle}
        >
            <Typography display="inline" variant="h2" className={classes.title}>
                {props.title}
            </Typography>
            {!props.hideSeeAllLink && (
                <Typography display="inline" align="right" variant="subtitle2">
                    <Link to={props.href} className={classes.seeAllLink}>
                        See all
                    </Link>
                </Typography>
            )}
        </Grid>
    );
}
