import _, { filter, isArray, isEqual, isObject, transform, uniq } from 'lodash';
import uniqid from 'uniqid';
import get from 'lodash/fp/get';
import { SkillSetsEntity } from '@weco/core';

export function getPictureUrl(
    picture: { bucket; identity; key; region; visibility } | string,
    defaultPicture = '',
    addUniqueIdAtTheEnd = false,
): string {
    if (!picture) {
        return defaultPicture;
    }
    if (typeof picture === 'string') return picture;

    const { bucket, identity, key, region, visibility } = picture;
    const uniqueIdOrNothing = addUniqueIdAtTheEnd ? `?v=${uniqid()}` : '';
    return encodeURI(
        `https://${bucket}.S3.${region}.amazonaws.com/${visibility}/${identity}/${key}${uniqueIdOrNothing}`,
    );
}

export function _value(value: any, path: string, defaultValue?: any) {
    return _.at(value, [path])[0] || defaultValue;
}

export function isClass(fn) {
    const { toString } = Function.prototype;

    function fnBody(fn) {
        return toString
            .call(fn)
            .replace(/^[^{]*{\s*/, '')
            .replace(/\s*}[^}]*$/, '');
    }

    if (typeof fn !== 'function') {
        return false;
    }

    if (/^class[\s{]/.test(toString.call(fn))) {
        return true;
    }

    const body = fnBody(fn);
    return (
        /classCallCheck\(/.test(body) ||
        /TypeError\("Cannot call a class as a function"\)/.test(body)
    );
}

/**
 *  Check item contains skill from matchList
 *  matchList: array of skill id.
 */
export function includesSkills(
    item: SkillSetsEntity,
    matchList: string[],
): boolean {
    // for some reason this code was added in commit 41943a96605883d5623a0bc289d7085521b1a82a
    // ticket we-711, commented it for now and updated match cause, as it was incorrect
    // search for --matching_print-- tag to correct behavior if required
    // const matchValues =
    //     matchList?.map((i) => {
    //         return i.split(':')[1];
    //     }) || [];
    return (
        !!item.skills?.find((match) => matchList?.includes(match.id)) || false
    );
}

/**
 *  Check item contains skill from matchList and return count of it
 *  matchList: array of skill id.
 */
export function includesSkillsCount(
    item: SkillSetsEntity,
    matchList: string[],
): number {
    // search for --matching_print-- tag to correct behavior if required
    // const matchValues =
    //     matchList?.map((i) => {
    //         return i.split(':')[1];
    //     }) || [];
    return (
        item.skills?.filter((match) => matchList?.includes(match.id)).length ||
        0
    );
}

export function findMatchedDepartments(
    roles: SkillSetsEntity[],
    skills: string[],
): string[] {
    const matchedDepartments = roles
        ?.filter((item) => includesSkills(item, skills))
        .map(get('department.name'));
    const uniqueMatchedDepartments = uniq(matchedDepartments);

    return uniqueMatchedDepartments;
}

export function findActiveDepartments(roles: SkillSetsEntity[]): string[] {
    const matchedDepartments = filter(roles, 'isSelected').map(
        get('department.name'),
    );
    const uniqueMatchedDepartments = uniq(matchedDepartments);

    return uniqueMatchedDepartments;
}

export function difference(
    origObj,
    newObj,
    options = { maxDeep: 2, ignoreFields: ['createdAt', 'updatedAt'] },
) {
    let currentDeepLevel = 0;
    function changes(newObj, origObj) {
        if (currentDeepLevel >= (options?.maxDeep || 2)) {
            return undefined;
        }
        currentDeepLevel++;
        let arrayIndexCounter = 0;
        return transform(
            newObj,
            function (result, value, key) {
                if (
                    !origObj.hasOwnProperty(key) ||
                    (options.ignoreFields || []).indexOf(key as any) !== -1
                ) {
                    return undefined;
                }
                if (!isEqual(value, origObj[key])) {
                    let resultKey: any = isArray(origObj)
                        ? arrayIndexCounter++
                        : key;
                    result[resultKey] =
                        isObject(value) && isObject(origObj[key])
                            ? changes(value, origObj[key])
                            : value;
                }
            },
            {},
        );
    }
    const ch = changes(newObj, origObj);
    Object.keys(ch).forEach((key) => {
        if (ch[key] === undefined) {
            delete ch[key];
        }
    });
    return ch;
}

export function addUpdatedAt(object: any) {
    return { ...object, updatedAt: new Date().toISOString() };
}
