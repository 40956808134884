import React from 'react';
import {
    List,
    Button,
    ListItem,
    ListItemText,
    Theme,
    createStyles,
    withStyles,
} from '@material-ui/core';
import {
    RoomMessagesPropsInterface,
    RoomMessagesStateInterface,
} from '../models/interfaces/RoomMessagesInterfaces';
import MessageInterface from '../models/interfaces/MessageInterface';
import Message from './Message';
import { formatDate, formatTime } from '../utils/dateTime';
import { UserRoomInterface } from '../models/interfaces/UserRoomInterface';
import { PAGINATION_SIZE } from '../utils/constants';

const styles: any = (theme: Theme) =>
    createStyles({
        list: {
            overflowY: 'scroll',
        },
        date: {
            textAlign: 'center',
            position: 'relative',
            '&:before': {
                content: '""',
                display: 'block',
                height: 0,
                borderBottom: '1px solid',
                borderColor: theme.palette.grey.A200,
                position: 'absolute',
                top: '22px',
                left: 0,
                width: '100%',
            },
        },
        dateSpan: {
            display: 'inline-block',
            background: '#fff',
            position: 'relative',
            padding: '0 10px',
        },
        time: {
            textAlign: 'center',
        },
        refWrapper: {
            height: 0,
            padding: 0,
            margin: 0,
        },
        emptyChat: {
            display: 'flex',
            justifyContent: 'center',
        },
    });

class RoomMessages extends React.Component<
    RoomMessagesPropsInterface,
    RoomMessagesStateInterface
> {
    private messagesEnd: any = React.createRef();
    private lastMessageDate: string = null;
    private lastMessageTime: string = null;
    private lastMessageCreatedAt: number = null;
    private scrollTimeout: any = null;

    constructor(props: RoomMessagesPropsInterface) {
        super(props);
        this.loadMore = this.loadMore.bind(this);
    }

    shouldComponentUpdate(nextProps: RoomMessagesPropsInterface): boolean {
        if (
            (nextProps.room && nextProps.updatedRoomId === nextProps.room.id) ||
            !this.props.room ||
            !nextProps.room ||
            this.props.room.id !== nextProps.room.id
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps: RoomMessagesPropsInterface) {
        this.lastMessageDate = null;
        this.lastMessageTime = null;
        if (
            this.props.room &&
            this.props.currentUser &&
            this.props.currentUser.rooms &&
            this.props.currentUser.rooms.length
        ) {
            const userRoom = this.props.currentUser.rooms.find(
                (userRoom) => userRoom.room.id === this.props.room.id,
            );
            if (userRoom && userRoom.unreadCount > 0) {
                const rooms = (this.props.currentUser.rooms || []).map(
                    (userRoom: UserRoomInterface) => {
                        if (userRoom.room.id === this.props.room.id) {
                            userRoom.unreadCount = 0;
                        }
                        return userRoom;
                    },
                );
                this.props.updateUserRooms(
                    this.props.firestore,
                    this.props.currentUser.id,
                    rooms,
                );
            }
        }
        if (
            this.props.roomsMessages[this.props.room.id] &&
            this.props.roomsMessages[this.props.room.id][
                this.props.roomsMessages[this.props.room.id].length - 1
            ].createdAtTime > this.lastMessageCreatedAt
        ) {
            this.scrollToBottom();
        }
        this.lastMessageCreatedAt = this.props.roomsMessages[this.props.room.id]
            ? this.props.roomsMessages[this.props.room.id][
                  this.props.roomsMessages[this.props.room.id].length - 1
              ].createdAtTime
            : 0;
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    loadMore() {
        this.props.fetchMessages(
            this.props.firestore,
            this.props.room.id,
            this.props.roomsMessages[this.props.room.id][0].createdAtTime,
        );
    }

    getDateItem(message: MessageInterface) {
        let dateItem = null;
        const date = formatDate(new Date(message.createdAtTime));
        if (date !== this.lastMessageDate) {
            this.lastMessageDate = date;
            dateItem = (
                <ListItem
                    key={this.lastMessageDate}
                    className={this.props.classes.date}
                >
                    <ListItemText
                        secondary={
                            <span className={this.props.classes.dateSpan}>
                                {this.lastMessageDate}
                            </span>
                        }
                    />
                </ListItem>
            );
        }

        return dateItem;
    }

    getTimeItem(message: MessageInterface) {
        let timeItem = null;
        const time = formatTime(new Date(message.createdAtTime));
        if (time !== this.lastMessageTime) {
            this.lastMessageTime = time;
            timeItem = (
                <ListItem
                    key={this.lastMessageTime}
                    className={this.props.classes.time}
                >
                    <ListItemText secondary={this.lastMessageTime} />
                </ListItem>
            );
        }

        return timeItem;
    }

    scrollToBottom() {
        if (this.messagesEnd.current) {
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = setTimeout(() => {
                this.messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
            }, 200);
        }
    }

    render() {
        return (
            <>
                {
                    <List component="nav" className={this.props.classes.list}>
                        {this.props.roomsMessages &&
                            this.props.room &&
                            this.props.roomsMessages[this.props.room.id] &&
                            this.props.loadedRoomsIds.indexOf(
                                this.props.room.id,
                            ) === -1 &&
                            this.props.roomsMessages[this.props.room.id]
                                .length >= PAGINATION_SIZE && (
                                <ListItem key="load-more">
                                    <ListItemText>
                                        <Button
                                            fullWidth
                                            onClick={this.loadMore}
                                        >
                                            Load preview messages
                                        </Button>
                                    </ListItemText>
                                </ListItem>
                            )}
                        {this.props.roomsMessages &&
                            this.props.roomsMessages[this.props.room.id] &&
                            this.props.roomsMessages[this.props.room.id].map(
                                (message: MessageInterface, i: number) => {
                                    const sameTime =
                                        formatTime(
                                            new Date(message.createdAtTime),
                                        ) === this.lastMessageTime;
                                    return (
                                        <div key={message.id}>
                                            {this.getDateItem(message)}
                                            {this.getTimeItem(message)}
                                            <Message
                                                key={`${i}${message.owner.id}${message.createdAtTime}`}
                                                currentUser={
                                                    this.props.currentUser
                                                }
                                                users={this.props.users}
                                                room={this.props.room}
                                                message={message}
                                                sending={!message.id}
                                                sameTime={sameTime}
                                                onAvatarClick={
                                                    this.props.onAvatarClick
                                                }
                                                getSkillSetById={
                                                    this.props.getSkillSetById
                                                }
                                            />
                                        </div>
                                    );
                                },
                            )}
                        <ListItem
                            key="end"
                            className={this.props.classes.refWrapper}
                        >
                            <ListItemText>
                                <div ref={this.messagesEnd} />
                            </ListItemText>
                        </ListItem>
                    </List>
                }
            </>
        );
    }
}

export default withStyles(styles)(RoomMessages);
