import { makeStyles } from '@material-ui/core/styles';

export const useMainPageStyles = makeStyles((theme) => ({
    title: {
        fontSize: '32px',
    },
    root: {
        marginTop: 0,
        padding: '0 40px',
    },
    videoBox: {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    seeAllLink: {
        zIndex: 1,
        fontWeight: 400,
        fontSize: '16px',
        position: 'relative',
        paddingBottom: '12px',
        textDecoration: 'none',
        display: 'inline-block',
        color: theme.palette.text.primary,
    },
    sectionTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
    },
}));
