import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const weEditableStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        cursor: 'pointer',
    },
    childBox: {
        flexGrow: 1,
        maxWidth: '100%',
    },
    editBox: {
        position: 'absolute',
        right: 0,
    },
    infoIcon: {
        color: theme.palette.text.primary,
        marginBottom: 15,
        marginLeft: 15,
        fontSize: '18px',
        fontWeight: 300,
    },
    editIcon: {
        marginLeft: 'auto',
        color: theme.palette.text.primary,
        backgroundColor: 'rgba(215,211,232, 0.4)',
        padding: '10px',
    },
    editIconSvg: {
        fontSize: '12px',
    },
    saveIcon: {
        padding: 5,
        color: theme.palette.text.secondary,
    },
    saveButton: {
        padding: '0px 5px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info[50],
        borderRadius: '5px',
        border: 0,
        '&:focus': {
            backgroundColor: theme.palette.info[50],
        },
        '&:hover': {
            backgroundColor: theme.palette.info[50],
        },
    },
}));
