import debug from 'debug';
import React, { memo } from 'react';
import { Box, Theme, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PersonAvatarBox } from './PersonAvatarBox';
import { getPictureUrl } from '@weco/common';
import { defaultPicture, UserProfileEntity } from '@weco/core';
import { useHistory } from 'react-router';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { MatchPercentage } from './MatchPercentage';
import { WeChip } from '../molecules';

const log = debug('PersonCardWithSkillSets');

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    personBox: {
        marginTop: 50,
        boxShadow: `0px 1px 7px #dcd9d9;`,
        padding: '10px',
        borderRadius: '5px',
        position: 'relative',
    },
    avatarBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    ownerTag: {
        alignSelf: 'center',
        color: theme.palette.text.primary,
    },
    personInfoBox: {
        width: 'calc(100% - 125px)',
        marginLeft: '7px',
    },
    personName: {
        fontSize: '16px',
        fontWeight: 700,
        color: theme.palette.text.disabled,
        wordWrap: 'break-word',
        width: 'calc(100% - 60px)',
    },
    personChipBox: {
        marginTop: 15,
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    percentIcon: {
        top: '15px',
        right: '15px',
        width: 30,
        height: 30,
        padding: 0,
        minWidth: 30,
        borderRadius: '50%',
        position: 'absolute',
    },
}));

interface Props {
    item: UserProfileEntity;
    isProjectOwner?: boolean;
    currentUserId?: string;
    isAuthenticated?: boolean;
}

const PersonCard = ({
    item,
    isProjectOwner,
    currentUserId,
    isAuthenticated,
}: Props) => {
    const classes = useStyles();
    const { push } = useHistory();
    const goTo = (path) => {
        push(path);
    };

    return (
        <Box className={classes.personBox}>
            {(!isProjectOwner || currentUserId !== item.id) &&
                isAuthenticated !== false && (
                    <MatchPercentage
                        number={item.matchPercent}
                        className={classes.percentIcon}
                    />
                )}
            <Grid container>
                <Box
                    className={classes.avatarBox}
                    onClick={(e) => {
                        goTo(`${RouterPaths.profilePage}/${item.id}`);
                    }}
                >
                    <PersonAvatarBox
                        percent={item.matchPercent}
                        picture={getPictureUrl(item.picture, defaultPicture)}
                    />
                    {isProjectOwner && (
                        <Typography className={classes.ownerTag}>
                            Owner
                        </Typography>
                    )}
                </Box>
                <Box className={classes.personInfoBox}>
                    <Typography className={classes.personName}>
                        {item.name} {item.lastName}
                    </Typography>
                    {item.skillSets?.length > 0 && (
                        <Box className={classes.personChipBox}>
                            <WeChip item={item.skillSets[0].name} />
                            {item.skillSets.length !== 1 && (
                                <WeChip
                                    item={`+${item.skillSets.length - 1}`}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Grid>
        </Box>
    );
};

export const PersonCardWithSkillSets = memo(PersonCard);
