import {
    Picture,
    ProjectEntity,
    SchoolEntity,
    SkillSetsEntity,
    UserProfileEntity,
    coreConstants,
    DepartmentEntityWithTestResultPercent,
} from '@weco/core';
import shuffle from 'lodash/shuffle';
import { PersonProjectConnection, AllDepartments } from '@weco/core';
import uniqid from 'uniqid';
import { random } from 'faker';
import { departmentDescriptionsForProject } from '../components/DepartmentsRouting';

const causes = coreConstants.causes;
const passions = ['Acting', 'Basketball', 'Cooking', 'Music'];
export const schools: SchoolEntity[] = [
    {
        name: 'Dwight New York',
        id: 'dwight-new-york',
        location: 'New York, USA',
    },
    {
        name: 'Dwight London',
        id: 'dwight-london',
        location: 'London, Great Britain',
    },
    {
        name: 'Dwight Seoul',
        id: 'dwight-seoul',
        location: 'Seoul, South Korean',
    },
    {
        name: 'Dwight Shanghai',
        id: 'dwight-shanghai',
        location: 'Shanghai, China',
    },
    {
        name: 'Dwight Dubai',
        id: 'dwight-dubai',
        location: 'Dubai, United Arab Emirates',
    },
    { name: 'Dwight Online', id: 'dwight-online', location: 'Homeschool' },
];
// NOTE: Pick images here: https://picsum.photos/images
const picture = 'https://picsum.photos/id/1027/200/300';

export const skillSetsList: SkillSetsEntity[] = [
    {
        id: '1',
        name: 'Designer',
        description:
            'Graphic designers are visual communicators. They communicate ideas to inspire, inform, or captivate consumers through both physical and virtual art forms that include images, words, or graphics.',
        department: {
            id: '123',
            name: 'hr',
        },
        skills: [
            {
                id: '1233',
                refCount: 123,
                value: 'Design',
                departmentName: 'hr',
            },
            {
                id: '12333',
                refCount: 123,
                departmentName: 'hr',
                value: 'Collaboration',
            },
        ],
    },
    {
        id: '2',
        name: 'Developer',
        isSelected: true,
        department: {
            id: '123',
            name: 'itweb',
        },
    },
    {
        id: '5',
        name: 'Architect',
        isSelected: true,
        department: {
            id: '123',
            name: 'hr',
        },
    },
    {
        id: '3',
        name: 'CTO',
        department: {
            id: '123',
            name: 'itweb',
        },
    },
    {
        id: '4',
        name: 'CEO',
        department: {
            id: '123',
            name: 'hr',
        },
    },
];

// NOTE: unfinished. There are much more values available.
export const userMock = Object.assign(new UserProfileEntity(), {
    causes,
    passions,
    picture: (picture as unknown) as Picture,
    id: '123',
    name: 'Julia',
    lastName: 'Frants',
    dream:
        'My long-term goal is to have  Live-Time Closed Captioning System (LTCCS) become readily accessible to anyone with a hearing disability.',
    objectives: ['First', 'Second', 'Third'],
    matchPercent: 40,
    settings: {
        searches: [
            { name: 'My person search', type: 'person', passions },
            { name: 'My project search', type: 'project', passions },
        ],
    },
    skillSets: skillSetsList,
});

export const emptyProject: ProjectEntity = Object.assign(new ProjectEntity(), {
    id: '123',
    name: 'Project name',
    passions: [],
    causes: [],
    objectives: [],
    stage: 'First',
    status: 'Launched',
    languages: [],
    fundings: {},
    tradings: [],
    wishList: [],
    investments: [],
    compensations: [],
    contacts: {},
    socialMedia: {},
    team: [],
    skills: [],
    owner: userMock,
    matchPercent: 40,
});

// NOTE: if anyone can add relatable info please do so.
export const detailedProject: ProjectEntity = Object.assign(
    new ProjectEntity(),
    {
        causes,
        passions,
        picture: (picture as unknown) as Picture,
        id: '123',
        name: 'Project name',
        stage: 'First',
        status: 'Launched',
        dream: 'To be the best of the best',
        objectives: ['First', 'Second', 'Third'],
        languages: ['english', 'russian'],
        // TODO find out possible values
        fundings: { first: 'first', second: 'second' },
        // TODO find out possible values
        tradings: ['first', 'second'],
        wishList: ['First', 'Second'],
        investments: ['first', 'second'],
        compensations: [],
        contacts: { first: 'Mikhail', second: 'Nikita' },
        socialMedia: { facebook: '123', twitter: '123' },
        school: shuffle(schools)[0],
        team: [userMock, userMock],
        skills: [],
        owner: userMock,
        matchPercent: 40,
        skillSets: skillSetsList,
    },
);

export const projectPersonConnection: PersonProjectConnection = {
    person: userMock,
    status: 'MEMBER',
    personId: 'personId',
    projectId: 'projectId',
    project: detailedProject,
    roles: ['member', 'owner'],
};

export const loremIpsum = 'Lorem ipsum dolor sit amet consectetur adipiscing elit eget ac sapien, interdum vulputate sagittis vel mollis porttitor semper lobortis convallis fames, nisi'.repeat(
    10,
);

export const departmentsMock = AllDepartments.map(
    (departmentName) =>
        ({
            id: uniqid(),
            name: departmentName,
            testPercent: random.number(100),
            description: departmentDescriptionsForProject[departmentName],
        } as DepartmentEntityWithTestResultPercent),
);
