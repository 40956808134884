import { Button, Theme } from '@material-ui/core';
import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { makeStyles } from '@material-ui/core/styles';

const confirmDialogStyles = makeStyles((theme: Theme) => ({
    text: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '24px',
        fontWeight: 500,
        color: theme.palette.primary[500],
    },
}));

export interface ConfirmDialogProps {
    callback: () => void;
    onClose: () => void;
    openState: boolean;
}

export const ConfirmDialog = ({
    callback,
    onClose,
    openState,
}: ConfirmDialogProps) => {
    const classes = confirmDialogStyles();

    const handleClose = () => {
        onClose();
    };

    const proceed = () => {
        callback();
        onClose();
    };

    return (
        <Dialog
            open={openState}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent className={classes.text}>
                Are you sure?
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={proceed} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
