import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { ProjectEntity, UserProfileEntity } from '@weco/core';
import classNames from 'classnames';
import React, { memo } from 'react';
import {
    ChildrenType,
    GoToChatButton,
    UnauthorizedRedirectOnclick,
} from '@weco/ui';
import { Avatar, AvatarProps } from './Avatar';
import { ShareButton } from './ShareButton';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        margin: '20px auto 0',
        cursor: 'pointer',
        background: 'white',
        boxShadow: '0px 1px 5px #d8d8d8',
        borderRadius: '7px',
        padding: '20px',
        height: '210px',
        [theme.breakpoints.up('sm')]: {
            width: '575px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    matched: {
        backgroundColor: theme.palette.primary[55],
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    avatarContainer: {
        marginRight: '20px',
    },
    infoContainer: {
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 155px)',
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(100% - 160px)',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: theme.palette.primary[700],
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    description: {
        [theme.breakpoints.down('xs')]: {
            maxHeight: '105px',
        },
        color: theme.palette.secondary[600],
        marginTop: 0,
        overflowWrap: 'break-word',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 5,
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.up('sm')]: {
            '-webkit-line-clamp': 4,
        },
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px',
        [theme.breakpoints.up('sm')]: {
            marginTop: '15px',
        },
    },
    icon: {
        marginTop: '-10px',
    },
    chatIcon: {
        color: '#ddd',
        padding: '0',
    },
    ownerTag: {
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: '5px',
        color: theme.palette.text.primary,
    },
    footer: {
        marginBottom: '-5px',
        [theme.breakpoints.up('sm')]: {
            marginBottom: '-10px',
        },
    },
    textDecoration: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export interface SearchResultItemProps {
    title: string;
    itemId: string;
    chips: string[];
    description: string;
    type?: 'person' | 'project';
    avatarProps: AvatarProps;
    /**
     * @deprecated
     */
    favoriteIsHidden?: boolean;
    isAuthenticated?: boolean;
    personIdToOpenChatWith: string;
    // NOTE: this is going to be removed when we find out how to use stores in storybook.
    activeProject: ProjectEntity;
    /**
     * TODO remove this prop.
     * @deprecated
     */
    isProjectOwner?: boolean;
    cardFooter: ChildrenType;
    isMatched?: boolean;
    withShareButton?: boolean;
    link?: string;
}

const SearchResultItem = memo(
    ({
        description,
        activeProject,
        isProjectOwner,
        cardFooter,
        isMatched,
        link,
        ...props
    }: SearchResultItemProps) => {
        const classes = useStyles();
        const theme = useTheme();
        const isScreenWide = useMediaQuery(theme.breakpoints.up('sm'));
        const percentagePlacement =
            props.avatarProps?.percentagePlacement || 'bottom-center';

        return (
            <Link to={link} className={classes.textDecoration}>
                <Box
                    className={classNames({
                        [classes.root]: true,
                        [classes.matched]: isMatched,
                    })}
                >
                    <Box className={classes.cardContainer}>
                        <Grid item container>
                            <Box
                                display="inline-block"
                                className={classes.avatarContainer}
                            >
                                <Avatar
                                    {...props.avatarProps}
                                    isAuthenticated={props.isAuthenticated}
                                    percentagePlacement={percentagePlacement}
                                />
                                {isProjectOwner && (
                                    <Typography className={classes.ownerTag}>
                                        Owner
                                    </Typography>
                                )}
                            </Box>
                            <Box className={classes.infoContainer}>
                                <Box className={classes.cardHeader}>
                                    <Typography
                                        className={classes.title}
                                        variant={isScreenWide ? 'h2' : 'h4'}
                                    >
                                        {props.title}
                                    </Typography>
                                    <Box className={classes.icon}>
                                        {props.isAuthenticated &&
                                        !props.withShareButton ? (
                                            <UnauthorizedRedirectOnclick>
                                                <GoToChatButton
                                                    person={
                                                        {
                                                            id:
                                                                props.personIdToOpenChatWith,
                                                            name: props.title,
                                                            lastName: '',
                                                            picture:
                                                                props
                                                                    .avatarProps
                                                                    .picture,
                                                        } as UserProfileEntity
                                                    }
                                                    className={classes.chatIcon}
                                                />
                                            </UnauthorizedRedirectOnclick>
                                        ) : (
                                            <ShareButton
                                                iconColor="gray"
                                                picture={
                                                    props.avatarProps.picture
                                                }
                                                shareId={props.itemId}
                                                type={props.type}
                                                copyTooltipPlacement="bottom"
                                                dropdownDirection="down"
                                                activeProject={activeProject}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <Box className={classes.description}>
                                    <Typography variant="body1">
                                        {description}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item container className={classes.footer}>
                            {cardFooter}
                        </Grid>
                    </Box>
                </Box>
            </Link>
        );
    },
);

SearchResultItem.displayName = 'SearchResultItem';

export { SearchResultItem };
