import { Injectable } from '@weco/common';
import { action, observable, runInAction, autorun } from 'mobx';
import { SkillSetsEntity } from '@weco/core';
import cloneDeep from 'lodash/cloneDeep';
import { environment } from '../../environments/environment';
import { RolesBaseStore, RolesStoreInterface } from './RolesBaseStore';

export interface ProjectRolesStoreInterface extends RolesStoreInterface {}

@Injectable()
export class ProjectRolesStore extends RolesBaseStore
    implements ProjectRolesStoreInterface {
    @observable projectId: string;

    constructor() {
        super();
    }

    @action.bound
    async loadRoles(departmentId?: string) {
        this.isLoadingStoreData = true;
        try {
            const result = this.projectId
                ? await this.service.loadSkillSets({
                      departmentId: departmentId,
                      projectId: this.projectId,
                      ownerId: environment.defaults.emptyId,
                  })
                : [];

            runInAction(() => {
                this.isLoadingStoreData = false;
                this.injectIsDefault(result);
                this.allAddedRoles = result;
            });
        } catch (e) {
            console.log(e);
            this.isLoadingStoreData = false;
        }
    }

    @action.bound
    async addRole(item: SkillSetsEntity): Promise<SkillSetsEntity> {
        const newItem: SkillSetsEntity = cloneDeep(item);
        newItem.projectId = this.projectId;
        return super.addRole(newItem);
    }
}
