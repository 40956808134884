import React from 'react';
import MuiSwitch, {
    SwitchProps as MuiSwitchProps,
} from '@material-ui/core/Switch';
import { FieldProps } from 'formik';

export interface WeSwitchProps
    extends FieldProps,
        Omit<
            MuiSwitchProps,
            | 'checked'
            | 'name'
            | 'value'
            | 'defaultChecked'
            | 'form'
            // Excluded for conflict with Field name
            | 'type'
        > {
    type?: string;
}

export const fieldToSwitch = ({
    disabled,
    field,
    form: { isSubmitting },
    type,
    ...props
}: WeSwitchProps): MuiSwitchProps => {
    return {
        disabled: disabled ?? isSubmitting,
        ...props,
        ...field,
    };
};

export const WeSwitch = (props: WeSwitchProps) => {
    return <MuiSwitch {...fieldToSwitch(props)} />;
};
