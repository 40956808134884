import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import useToggle from 'react-use/lib/useToggle';
import { collaborationActionStyles } from './CollaborationAction.styles';
import { APP_EVENTS } from '../../../../../apps/client/src/app_events';
import { ApplyToProjectDialog } from '../components';
import { SkillSetsEntity } from '@weco/core';
import Dialog from '@material-ui/core/Dialog';
import useTheme from '@material-ui/core/styles/useTheme';
import DialogContent from '@material-ui/core/DialogContent';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import { RequiredInfoBanner } from '../components/RequiredInfoBanner';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { environment } from '../../../../../apps/client/src/environments/environment';
import { ConfirmDialog } from '@weco/ui';

export interface ApplyCollarobrationActionsProps {
    incompleteProfile?: boolean;
    projectId?: string;
    className?: string;
    isAuthenticated?: boolean;
    isLoadingStoreData: boolean;
    isEnvitee: () => boolean;
    isMember: () => boolean;
    ableToApply: () => boolean;
    applied: () => boolean;
    acceptInvitation(): Promise<any>;
    declineInvitation(): Promise<any>;
    leaveProject(): Promise<any>;
    applyToProject(): Promise<any>;
    recallApplication(): Promise<any>;
    projectsActiveRoles?: SkillSetsEntity[];
    setPersonProjectConnection: (param) => void;
    personProjectConnection: {
        personId: string;
        projectId: string;
        roles: string[];
    };
}

export const ApplyCollaborationActions = ({
    isAuthenticated = true,
    declineInvitation,
    acceptInvitation,
    leaveProject,
    applyToProject,
    recallApplication,
    isEnvitee,
    isMember,
    ableToApply,
    isLoadingStoreData,
    applied,
    projectsActiveRoles,
    incompleteProfile,
    setPersonProjectConnection,
    personProjectConnection,
}: ApplyCollarobrationActionsProps) => {
    const classes = collaborationActionStyles();
    const [loading, toggleLoading] = useToggle(isLoadingStoreData);
    const [openApplyToProjectDialog, setOpenApplyToProjectDialog] = useState(
        false,
    );
    const [open, setOpen] = useState(false);
    const [disconnectOpenState, setDisconnectOpenState] = useState(false);

    useEffect(() => {
        toggleLoading(isLoadingStoreData);
    }, [isLoadingStoreData, toggleLoading]);

    const runAction = async (action, params?) => {
        toggleLoading(true);
        return action(params).catch(console.error).finally(toggleLoading);
    };

    const runConnect = async (action, params?) => {
        const connection = { roles: [], ...personProjectConnection };
        connection.roles.push('applicant');
        setPersonProjectConnection(connection);
        return action(params).catch(console.error);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onConnectClick = () => {
        if (incompleteProfile) {
            setOpen(true);
            return;
        }
        if (environment.isRolesEnabled && projectsActiveRoles.length) {
            setOpenApplyToProjectDialog(true);
        } else {
            runConnect(applyToProject);
        }
    };

    const onConnectedClick = (action) => {
        const connection = { ...personProjectConnection };
        connection.roles = [];
        setPersonProjectConnection(connection);
        return action().catch(console.error);
    };

    return (
        <Box
            className={classNames({
                [classes.root]: true,
            })}
        >
            <Box className={classes.action}>
                <Box className={classes.actionButtonBox}>
                    {isEnvitee() && isAuthenticated && (
                        <>
                            <Button
                                id={APP_EVENTS.PROFILE_ACCEPT}
                                disabled={loading}
                                className={classes.actionButton}
                                onClick={() => runAction(acceptInvitation)}
                            >
                                Accept
                            </Button>
                            <Button
                                id={APP_EVENTS.PROJECT_REJECT}
                                disabled={loading}
                                variant="outlined"
                                className={classNames(
                                    classes.actionButton,
                                    classes.rejectButton,
                                )}
                                onClick={() => runAction(declineInvitation)}
                            >
                                Reject
                            </Button>
                        </>
                    )}
                    {isMember() && isAuthenticated && (
                        <Button
                            disabled={loading}
                            className={classes.actionButton}
                            onClick={() => runAction(leaveProject)}
                        >
                            Leave
                        </Button>
                    )}
                    {(!isAuthenticated || ableToApply()) && (
                        <Button
                            id={APP_EVENTS.APPLY_TO_PROJECT}
                            disabled={loading}
                            className={classNames({
                                [classes.actionButton]: true,
                                [classes.connectAction]: true,
                            })}
                            onClick={() => onConnectClick()}
                        >
                            Connect
                        </Button>
                    )}
                    {applied() && isAuthenticated && (
                        <Button
                            id={APP_EVENTS.PROJECT_RECALL}
                            disabled={loading}
                            className={classes.actionButton}
                            onClick={() => setDisconnectOpenState(true)}
                        >
                            Connected
                        </Button>
                    )}
                </Box>
            </Box>
            <ApplyToProjectDialog
                isOpen={openApplyToProjectDialog}
                onSubmit={(role) => {
                    setOpenApplyToProjectDialog(false);
                    return runConnect(applyToProject, role.id);
                }}
                onDialogToggle={() => {
                    setOpenApplyToProjectDialog(false);
                }}
                departments={(projectsActiveRoles || []).map(
                    (role) => role.department,
                )}
                roles={projectsActiveRoles || []}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <RequiredInfoBanner
                        text="Please complete your profile with a Photo and About Me."
                        link={RouterPaths.myProfile}
                        linkText="Complete Profile"
                    />
                </DialogContent>
            </Dialog>
            <ConfirmDialog
                callback={() => onConnectedClick(recallApplication)}
                openState={disconnectOpenState}
                onClose={() => setDisconnectOpenState(false)}
            />
        </Box>
    );
};
