import React from 'react';
import { SearchProjectsContainer } from './SearchProjectsContainer';
import ProfileProjectsTabContent from './SearchProjectsContent';

const SearchProjectsTab = () => {
    return (
        <SearchProjectsContainer>
            <ProfileProjectsTabContent />
        </SearchProjectsContainer>
    );
};
export default SearchProjectsTab;
