import { Button, Grid, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useHistory, useParams } from 'react-router';
import { RouterPaths } from '../../../router/RouterPaths';
import { IOnboardingPageURLParms } from '../OnboardingPage';
import { AvoidBottomBarInMobileBrowsers } from '@weco/ui';

const useStyles = makeStyles((theme) => ({
    previousButton: {},
    nextButton: {},
}));

/**
 * TODO: remove this component completely.
 * Use NavigationButtons instead.
 * @deprecated
 */

const OnboardingNavigationButtons = memo(
    ({
        isBackButtonVisible = true,
        isSubmitDisabled,
        submitButtonText,
        onSubmit,
    }: {
        /**
         * Override submit function.
         */
        onSubmit?: () => void;
        submitButtonText?: string;
        isSubmitDisabled?: boolean;
        isBackButtonVisible?: boolean;
    }) => {
        const cx = useStyles();
        const history = useHistory();
        const params = useParams<IOnboardingPageURLParms>();
        const currentStep = Number(params.step);

        function goBack() {
            history.push(RouterPaths.onboarding + '/' + (currentStep - 1));
        }

        function goForward() {
            history.push(RouterPaths.onboarding + '/' + (currentStep + 1));
        }

        return (
            <Grid container justify="center" spacing={1}>
                <Grid item>
                    {isBackButtonVisible ? (
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={goBack}
                            className={classNames(cx.previousButton)}
                        >
                            Previous
                        </Button>
                    ) : null}
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onSubmit ? onSubmit : goForward}
                        disabled={isSubmitDisabled}
                        className={classNames(cx.nextButton)}
                    >
                        {submitButtonText || 'Next'}
                    </Button>
                </Grid>
                <AvoidBottomBarInMobileBrowsers />
            </Grid>
        );
    },
);

OnboardingNavigationButtons.displayName = 'OnboardingNavigationButtons';

export { OnboardingNavigationButtons };

export default OnboardingNavigationButtons;
