import * as actionTypes from './actionTypes';
import UserInterface from '../../models/interfaces/UserInterface';
import { UserRoomInterface } from '../../models/interfaces/UserRoomInterface';
import { SearchUserInterface } from '../../models/interfaces/UsersInterface';

export function receiveListUser(user: UserInterface) {
    return {
        type: actionTypes.RECEIVE_LIST_USER,
        user,
    };
}

export function updateUserRooms(
    userId: string,
    userRooms: UserRoomInterface[],
) {
    return {
        type: actionTypes.UPDATE_USER_ROOMS,
        userId,
        userRooms,
    };
}

export function receiveSearchUsers(users: SearchUserInterface[]) {
    return {
        type: actionTypes.RECEIVE_SEARCHED_USERS,
        users,
    };
}

export function clearSearchUsers() {
    return {
        type: actionTypes.CLEAR_SEARCHED_USERS,
    };
}
