import { Injectable, Inject } from '@weco/common';
import { ProjectEntity, ProjectsService } from '@weco/core';
import { observable, action } from 'mobx';
import { MainPageFilter } from '../../../../helpers/CommonTypes';

@Injectable()
export class ProjectsMainPageStore {
    private readonly MAIN_PAGE_ITEMS_COUNT: number = 6;

    @Inject(ProjectsService)
    service: ProjectsService;

    @observable items: ProjectEntity[];

    filters: MainPageFilter[];

    @observable activeFilterValue = '1';

    @action.bound
    changeFilter(newValue: string) {
        this.activeFilterValue = newValue;
    }

    @action
    async getList(): Promise<any> {
        const data = await this.service.search({
            limit: this.MAIN_PAGE_ITEMS_COUNT,
        });
        this.items = data.projects;
    }
}
