import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { SearchPageStore } from './SearchPageStore';

export const useSearchData = () => {
    const store = useStore<SearchPageStore>(SearchPageStore);

    return useObserver(() => ({
        query: store.query,
        constants: store.constants,
        searchPeople: store.searchPeople,
        resetPeopleLimit: store.resetPeopleLimit,
        searchProjects: store.searchProjects,
        resetProjectLimit: store.resetProjectLimit,
        advancedFilter: store.advancedFilter,
        setAdvancedSearchFilter: store.setAdvancedSearchFilter,
        updatedSearchSettings: store.updatedSearchSettings,
        setUpdatedSearchSettings: store.setUpdatedSearchSettings,
        setLoading: store.setLoading,
    }));
};
