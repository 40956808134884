import * as actionTypes from './actionTypes';
import {
    RoomMessagesActionList,
    RoomMessagesListState,
} from '../../models/interfaces/RoomMessagesInterfaces';
import MessageInterface from '../../models/interfaces/MessageInterface';
import defaultState from './defaultState';
import { PAGINATION_SIZE } from '../../utils/constants';

const roomMessages = (
    state: RoomMessagesListState = defaultState,
    action: RoomMessagesActionList,
) => {
    switch (action.type) {
        case actionTypes.SUBSCRIBED_ROOM_MESSAGES:
            return {
                ...state,
                subscribedRoomsIds: [
                    ...state.subscribedRoomsIds,
                    action.roomId,
                ],
            };
        case actionTypes.RECEIVE_NEW_ROOM_MESSAGE:
            const roomMessages = state.messages[action.roomId] || [];
            const itemExistsIndex = roomMessages.findIndex(
                (message: MessageInterface) =>
                    message.id === action.message.id ||
                    (message.message === action.message.message &&
                        message.createdAtTime ===
                            action.message.createdAtTime &&
                        message.owner.id === action.message.owner.id),
            );
            if (itemExistsIndex === -1) {
                roomMessages.push(action.message);
            } else {
                roomMessages[itemExistsIndex] = action.message;
            }
            return {
                ...state,
                messages: {
                    ...state.messages,
                    ...{
                        [action.roomId]: roomMessages.sort(
                            (
                                m1: MessageInterface,
                                m2: MessageInterface,
                            ): any => {
                                return m1.createdAtTime - m2.createdAtTime;
                            },
                        ),
                    },
                },
                updatedRoomId: action.roomId,
            };
        case actionTypes.RECEIVE_UPDATE_ROOM_MESSAGE:
            const updateRoomMessages = (
                state.messages[action.roomId] || []
            ).map((message: MessageInterface) =>
                message.id === action.message.id ? action.message : message,
            );
            return {
                ...state,
                messages: {
                    ...state.messages,
                    ...{ [action.roomId]: updateRoomMessages },
                },
                updatedRoomId: action.roomId,
            };
        case actionTypes.RECEIVE_DELETE_ROOM_MESSAGE:
            const deleteRoomMessages = (
                state.messages[action.roomId] || []
            ).filter(
                (message: MessageInterface) => message.id !== action.message.id,
            );
            return {
                ...state,
                messages: {
                    ...state.messages,
                    ...{ [action.roomId]: deleteRoomMessages },
                },
                updatedRoomId: action.roomId,
            };
        case actionTypes.RECEIVE_ROOM_MESSAGES:
            let currentRoomMessages = state.messages[action.roomId] || [];
            currentRoomMessages = [...action.messages, ...currentRoomMessages];
            const newState = {
                ...state,
                messages: {
                    ...state.messages,
                    ...{
                        [action.roomId]: currentRoomMessages.sort(
                            (
                                m1: MessageInterface,
                                m2: MessageInterface,
                            ): any => {
                                return m1.createdAtTime - m2.createdAtTime;
                            },
                        ),
                    },
                },
                updatedRoomId: action.roomId,
            };
            if (
                action.messages.length === 0 ||
                action.messages.length < PAGINATION_SIZE
            ) {
                newState.loadedRoomsIds.push(action.roomId);
            }
            return newState;
        case actionTypes.REQUEST_SEND_MESSAGE:
            const roomSendingMessages = state.messages[action.roomId] || [];
            roomSendingMessages.push(action.message);
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [action.roomId]: roomSendingMessages,
                },
                updatedRoomId: action.roomId,
            };
        case actionTypes.RECEIVE_SEND_ERROR_MESSAGE:
            const roomErrorMessages = (state.messages[action.roomId] || []).map(
                (message: MessageInterface) => {
                    if (
                        message.message === action.message.message &&
                        message.createdAtTime ===
                            action.message.createdAtTime &&
                        message.owner.id === action.message.owner.id
                    ) {
                        message.error = action.error;
                    }
                    return message;
                },
            );
            return {
                ...state,
                messages: {
                    ...state.messages,
                    [action.roomId]: roomErrorMessages,
                },
            };
        case actionTypes.DELETE_ROOM_MESSAGES:
            const messagesWithDeleteRoom = state.messages;
            if (messagesWithDeleteRoom[action.roomId]) {
                delete messagesWithDeleteRoom[action.roomId];
            }
            return {
                ...state,
                messages: messagesWithDeleteRoom,
            };
        default:
            return state;
    }
};

export default roomMessages;
