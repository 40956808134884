import React from 'react';
import {
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Menu,
    MenuItem,
    TextField,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {
    RoomHeaderPropsInterface,
    RoomHeaderStateInterface,
} from '../models/interfaces/RoomHeaderInterface';
import RoomAvatar from './RoomAvatar';
import { ROOM_TYPE_PEOPLE, ROOM_TYPE_PROJECTS } from '../utils/constants';
import { getRoomName } from '../utils/room';
import SearchUserDialog from './SearchUserDialog';

const styles: any = (theme: Theme) =>
    createStyles({
        container: {
            marginBottom: 15,
            display: 'flex',
            paddingBottom: 10,
        },
        headerCenter: {
            display: 'flex',
            overflow: 'hidden',
        },
        roomIcon: {
            margin: '0 auto',
        },
        roomNameBox: {
            marginLeft: '10px',
            paddingTop: '7px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        roomName: {
            fontSize: 14,
            color: theme.palette.common.black,
            marginTop: 5,
            fontWeight: 'normal',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        goBack: {
            cursor: 'pointer',
            width: 45,
            height: 'auto',
            color: theme.palette.primary.main,
        },
        moreIcon: {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            width: 40,
            height: 'auto',
        },
        menu: {
            '& ul': {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
        menuItem: {
            padding: '10px 10px',
            margin: 0,
        },
    });

class RoomHeader extends React.Component<
    RoomHeaderPropsInterface,
    RoomHeaderStateInterface
> {
    constructor(props: RoomHeaderPropsInterface) {
        super(props);
        this.state = {
            menuOpen: false,
            anchorEl: null,
            renameDialogOpen: false,
            newRoomName: '',
            searchUsersDialogOpen: false,
        };
        this.handleOpenMenu = this.handleOpenMenu.bind(this);
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
        this.renderRenameDialog = this.renderRenameDialog.bind(this);
        this.handleOpenRenameDialog = this.handleOpenRenameDialog.bind(this);
        this.handleCloseRenameDialog = this.handleCloseRenameDialog.bind(this);
        this.handleUpdateNewRoomName = this.handleUpdateNewRoomName.bind(this);
        this.handleCloseSearchUserDialog = this.handleCloseSearchUserDialog.bind(
            this,
        );
        this.handleOpenSearchUserDialog = this.handleOpenSearchUserDialog.bind(
            this,
        );
        this.handleAddUserToRoom = this.handleAddUserToRoom.bind(this);
    }

    handleOpenMenu(event: any) {
        this.setState({ menuOpen: true, anchorEl: event.target });
    }

    handleCloseMenu() {
        this.setState({ menuOpen: false, anchorEl: null });
    }

    handleOpenRenameDialog(event: any) {
        this.setState({
            menuOpen: false,
            renameDialogOpen: true,
            newRoomName: this.props.room.name,
        });
    }

    handleCloseRenameDialog() {
        this.setState({ renameDialogOpen: false, newRoomName: '' });
        this.handleCloseMenu();
    }

    handleOpenSearchUserDialog(event: any) {
        this.setState({ menuOpen: false, searchUsersDialogOpen: true });
    }

    handleCloseSearchUserDialog() {
        this.setState({ searchUsersDialogOpen: false });
    }

    handleUpdateNewRoomName(newRoomName: string) {
        this.setState({ newRoomName });
    }

    handleAddUserToRoom(userId: string) {
        this.props.addUserToRoom({ roomId: this.props.room.id, userId });
        this.handleCloseSearchUserDialog();
    }

    renderRenameDialog() {
        return (
            <Dialog
                open={this.state.renameDialogOpen}
                onClose={this.handleCloseRenameDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Rename room</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="email"
                        fullWidth
                        onChange={(event: any) => {
                            this.handleUpdateNewRoomName(event.target.value);
                        }}
                        value={this.state.newRoomName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleCloseRenameDialog}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.renameRoom(
                                this.props.firestore,
                                this.props.room.id,
                                this.state.newRoomName,
                            );
                            this.handleCloseRenameDialog();
                        }}
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return (
            <>
                {this.props.room && (
                    <Grid className={this.props.classes.container}>
                        <Box>
                            <NavigateBeforeIcon
                                onClick={() => {
                                    this.props.setActiveRoom(
                                        this.props.firestore,
                                        null,
                                    );
                                }}
                                className={this.props.classes.goBack}
                            />
                        </Box>
                        <Grid
                            item
                            xs={12}
                            className={this.props.classes.headerCenter}
                        >
                            <Box component="div" display="flex">
                                <RoomAvatar
                                    currentUser={this.props.currentUser}
                                    users={this.props.users}
                                    room={this.props.room}
                                    className={this.props.classes.roomIcon}
                                    disableIconClick={false}
                                    onAvatarClick={this.props.onAvatarClick}
                                />
                            </Box>
                            <Box
                                component="div"
                                className={this.props.classes.roomNameBox}
                            >
                                <Typography
                                    gutterBottom
                                    variant="h1"
                                    component="h1"
                                    className={this.props.classes.roomName}
                                >
                                    {getRoomName(
                                        this.props.room,
                                        this.props.users,
                                        this.props.currentUser,
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                        <Box>
                            <MoreVertIcon
                                onClick={this.handleOpenMenu}
                                className={this.props.classes.moreIcon}
                            />
                            <Menu
                                className={this.props.classes.menu}
                                id="room-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={this.state.menuOpen}
                                onClose={this.handleCloseMenu}
                            >
                                {this.props.room.type === ROOM_TYPE_PROJECTS &&
                                    this.props.room.owner.id ===
                                        this.props.currentUser.id && (
                                        <MenuItem
                                            className={
                                                this.props.classes.menuItem
                                            }
                                            onClick={
                                                this.handleOpenRenameDialog
                                            }
                                        >
                                            Rename
                                        </MenuItem>
                                    )}
                                {this.props.room.owner.id !==
                                    this.props.currentUser.id &&
                                    this.props.room.type !==
                                        ROOM_TYPE_PEOPLE && (
                                        <MenuItem
                                            className={
                                                this.props.classes.menuItem
                                            }
                                            onClick={() => {
                                                this.props.leaveRoom(
                                                    this.props.firestore,
                                                    this.props.currentUser,
                                                    this.props.room,
                                                );
                                            }}
                                        >
                                            Leave
                                        </MenuItem>
                                    )}
                                {this.props.room.type === ROOM_TYPE_PEOPLE && (
                                    <MenuItem
                                        className={this.props.classes.menuItem}
                                        onClick={() => {
                                            this.props.deleteRoom(
                                                this.props.firestore,
                                                this.props.room.id,
                                            );
                                        }}
                                    >
                                        Delete
                                    </MenuItem>
                                )}
                            </Menu>
                        </Box>
                    </Grid>
                )}
                {this.renderRenameDialog()}
                {this.props.room && (
                    <SearchUserDialog
                        searchUsersDialogOpen={this.state.searchUsersDialogOpen}
                        handleCloseSearchUserDialog={
                            this.handleCloseSearchUserDialog
                        }
                        searchUsers={this.props.searchUsers}
                        findUserByName={this.props.findUserByName}
                        excludedUsers={this.props.room.members}
                        searchedUsers={this.props.searchUsersList}
                        handleUserClick={this.handleAddUserToRoom}
                    />
                )}
            </>
        );
    }
}

export default withStyles(styles)(RoomHeader);
