import { COMMON_SERVICES, useStore } from '@weco/common';
import { useObserver } from 'mobx-react';
import { NoticeStore } from './NoticeStore';

export const useNotice = (): NoticeStore => {
    const store = useStore<NoticeStore>(COMMON_SERVICES.NoticeStore);
    return useObserver(() => ({
        notifications: store.notifications,
        hide: store.hide,
        error: store.error,
        warn: store.warn,
        success: store.success,
        info: store.info,
        show: store.show,
    }));
};
