import { Expose, Exclude } from 'class-transformer';

@Exclude()
export class Picture {
    @Expose()
    bucket: string;
    @Expose()
    identity: string;
    @Expose()
    key: string;
    @Expose()
    mimeType: string;
    @Expose()
    region: string;
    @Expose()
    updatedAt: number;
    @Expose()
    visibility: string;
}
