import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useTabStyles = makeStyles((theme: Theme) => ({
    tabContent: {
        marginTop: 0,
        padding: '0 12px',
        paddingBottom: '25px',
    },
    section: {
        borderBottom: '1px solid #e0e0e0',
    },
    chipLink: {
        textDecoration: 'none',
    },
    editBox: {
        marginTop: 10,
    },
    formControlWidth: {
        width: '90%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
}));

export const useTabContentStyles = makeStyles((theme: Theme) => ({
    tabContent: {
        padding: '0 15px',
    },
    loadMore: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0 0 0',
    },
    percentIcon: {
        position: 'absolute',
        top: 5,
        left: 5,
        backgroundColor: 'green',
        color: '#fff',
        borderRadius: '50%',
        fontSize: '12px',
        padding: 0,
        minWidth: 30,
        width: 30,
        height: 30,
    },
    mainTabContent: {
        padding: '0px',
    },
    infoItem: {
        marginTop: 30,
    },
    infoHeading: {
        display: 'flex',
        color: theme.palette.text.primary,
        fontWeight: 500,
        alignItems: 'center',
    },
    infoIcon: {
        color: theme.palette.text.secondary,
        marginBottom: 15,
        marginLeft: 15,
        fontSize: '18px',
        fontWeight: 300,
    },
    editIcon: {
        marginLeft: 'auto',
        color: '#E9E9E9',
    },
    infoText: {
        marginTop: 10,
        fontSize: '16px',
        color: theme.palette.text.secondary,
        marginRight: 15,
    },
    selectRoot: {
        background: 'none',
        width: '100%',
        margin: '0 15px',
        color: theme.palette.text.secondary,
        '&:before': {
            border: 'none',
        },
        '&:after': {
            border: 'none',
        },
        '&:focus': {
            background: 'none',
        },
    },
    select: {
        background: 'none',

        border: 'none',
        '&:before': {
            border: 'none',
            background: 'none',
        },
        '&:after': {
            border: 'none',
            background: 'none',
        },
        '&:focus': {
            background: 'none',
            border: 'none',
        },
    },
    checkBoxLabel: {
        color: theme.palette.text.secondary,
        fontSize: '16px',
        margin: '10px 0',
    },
    chip: {
        color: theme.palette.text.secondary,
        backgroundColor: '#F2F2F2',
        borderRadius: '4px',
        margin: '5px 10px 5px 0px',
    },
    infoDescription: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        fontStyle: 'italic',
        margin: '5px 0',
    },
    infoSkills: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    secondaryTabs: {
        padding: '20px 0',
    },
    positions: {
        display: 'none',
        padding: '0',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    positionBox: {
        textAlign: 'center',
        margin: 5,
    },
    secondaryTab: {
        color: theme.palette.text.secondary,
        fontSize: '20px',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    passionSvg: {
        fontSize: '18px',
        color: '#fff',
    },
    passionSvgActive: {
        color: '#fff',
    },
    passionButton: {
        padding: 4,
        margin: 0,
        borderRadius: '50%',
        backgroundColor: '#E0E0E0',
    },
    passionButtonActive: {
        backgroundColor: theme.palette.text.secondary,
    },
    addTextBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    addText: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        fontSize: '16px',
        margin: 10,
        textTransform: 'capitalize',
    },
    passionLabel: {
        fontSize: '10px',
        color: '#E0E0E0',
        padding: 0,
        margin: 0,
    },
    passionLabelActive: {
        color: theme.palette.text.secondary,
    },
    secondaryTabActive: {
        color: theme.palette.text.primary,
        fontSize: '20px',
        fontWeight: 700,
        textTransform: 'capitalize',
    },
    passionCardBox: {
        padding: 5,
    },
    passionCard: {
        width: '100px',
        height: '90px',
        backgroundColor: '#F2F2F2',
        color: '#8E8E8E',
        padding: 0,
        lineHeight: '90px',
        position: 'relative',
        fontSize: '14px',
        fontWeight: 400,
        margin: '0 auto',
        textAlign: 'center',
        borderRadius: '8px',
    },
    passionCardText: {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: 'normal',
    },
    radioBox: {
        position: 'absolute',
        lineHeight: 'normal',
        right: 5,
        top: 5,
    },
    radio: {
        padding: 0,
    },
    projectBox: {
        marginTop: 50,
    },
    avatarBox: {
        width: '120px',
        height: '120px',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
    },
    projectInfoBox: {
        width: 'calc(100% - 125px)',
        marginLeft: '7px',
    },
    projectName: {
        color: '#8E8E8E',
        fontSize: '16px',
        fontWeight: 700,
    },
    projectDescription: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        textAlign: 'justify',
        marginTop: 12,
        lineHeight: '16px',
    },
    projectChipBox: {
        marginTop: 15,
    },
    projectTeamBox: {
        display: 'flex',
    },
    projectTeamText: {
        color: '#8E8E8E',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '30px',
        margin: '0 10px',
    },
    teamAvatar: {
        width: 30,
        height: 30,
    },
    tab: {
        color: theme.palette.text.secondary,
        fontSize: '20px',
        fontWeight: 700,
        '&.Mui-selected': {
            color: theme.palette.text.primary,
            fontSize: '20px',
            fontWeight: 700,
            textTransform: 'capitalize',
        },
        '&.MuiButtonBase-root': {
            fontWeight: 700,
            textTransform: 'capitalize',
        },
    },
    loadingBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '15px 0 0 0',
    },
}));
