import { intersection } from 'lodash';
import { coreConstants } from './constants/coreConstants';

export function getAllowedInvestments(data: string[]): string[] {
    return data?.filter((i) => coreConstants.investments.includes(i));
}

export function getDepartmentDisplayName(departmentName: string): string {
    const mapping = {
        execution: 'Operations',
        finance: 'Finance',
        marketing: 'Marketing',
        itweb: 'Technology',
        hr: 'Humans',
        strategy: 'Strategy',
        legal: 'Legal',
        ideas: 'Ideation',
    };
    return mapping[departmentName] || departmentName;
}
export function matchedSections(highlightedPoperies: string[]) {
    const bioFields = [
        'name',
        'lastName',
        'bio',
        'dream',
        'causes',
        'objectives',
        'passions',
        'socialMedia',
        'contacts',
        'languages',
    ];
    const skillsFields = ['skills'];
    const resourcesFields = ['investments', 'compensations', 'fundings'];
    return {
        isBioMatched: intersection(bioFields, highlightedPoperies).length > 0,
        isSkillsMatched:
            intersection(skillsFields, highlightedPoperies).length > 0,
        isResourcesMatched:
            intersection(resourcesFields, highlightedPoperies).length > 0,
    };
}

export const calcFilledPercentage = (
    object,
    ignoreFields: string[] = ['createdAt', 'updatedAt'],
): number => {
    let filledCount = 0;
    let totalCount = 0;
    for (const key in object) {
        if (ignoreFields.indexOf(key) !== -1) {
            continue;
        }
        totalCount++;
        // eslint-disable-next-line no-prototype-builtins
        if (object.hasOwnProperty(key) && !!object[key]) {
            filledCount++;
        }
    }
    return Math.round((filledCount / totalCount) * 100);
};
