import { Box } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/styles';
import { getPictureUrl } from '@weco/common';
import { UserProfileEntity } from '@weco/core';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from './Avatar';

export interface PeopleAvatarListProps {
    limitAvatarTo?: number;
    people: UserProfileEntity[];
}

const PeopleAvatarList = memo(({ people, ...props }: PeopleAvatarListProps) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <StyledAvatarGroup max={props.limitAvatarTo || 3}>
            {people?.map((person) => (
                <Avatar
                    shape="round"
                    size="xs-small"
                    key={person.id}
                    className={classes.avatar}
                    picture={getPictureUrl(person.picture)}
                    alt={`${person.name} ${person.lastName}`}
                    onClick={() =>
                        history.push(`${RouterPaths.profilePage}/${person.id}`)
                    }
                />
            ))}
        </StyledAvatarGroup>
    );
});

PeopleAvatarList.displayName = 'PeopleAvatarList';

const avatarDimensions = {
    width: '30px',
    height: '30px',
};

const useStyles = makeStyles({ avatar: avatarDimensions });

const StyledAvatarGroup = withStyles({
    avatar: {
        border: 'none',
        fontSize: '14px',
        ...avatarDimensions,
    },
})(AvatarGroup);

export { PeopleAvatarList };
