import { BaseUserToken } from '../../common';

export class SignUpUsernamePasswordToken extends BaseUserToken<{
    username: string;
}> {
    constructor(
        public username,
        public password,
        public attributes: {
            firstName: string;
            lastName: string;
            [name: string]: string;
        },
    ) {
        super();
        this.user = {
            username,
        };
    }
}
