import { IsNotEmpty } from 'class-validator';

export class LocationEntity {
    @IsNotEmpty()
    name: string;
    @IsNotEmpty()
    googlePlaceId: string;
    location?: {
        lat: number;
        lon: number;
    };
}
