import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import {
    Button,
    CardMedia,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    SvgIcon,
    Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { SignUpFormFormData, useNotice, WeTextField } from '@weco/ui';
import {
    Auth,
    COMMON_SERVICES,
    CoreEventDispatcher,
    FormikValidators,
    useInjection,
} from '@weco/common';
import { useHistory, useLocation } from 'react-router';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import { RouterPaths } from '../../router/RouterPaths';
import { makeStyles } from '@material-ui/core/styles';
import LoginBackground from '../../../assets/images/login-background.png';
import FullLogo from '../../../assets/images/full-logo.svg';
import { localStorageIsRedirectionRequiredKey } from '../onboarding/onboardingConstants';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/social/g-google.svg';
import { CognitoHostedUIIdentityProvider as SocialMediaType } from '@aws-amplify/auth';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/social/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/social/linkedin.svg';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Link from '@material-ui/core/Link';
import { MinimalisticFooter } from '../../components/layout/particules/MinimalisticFooter';
import { WE_CLICKS } from '../../../we_clicks';
import { SentVerifyEmail } from './SentVerifyEmail';
import { useQuery } from '../../hooks/useQuery';

const FORM_LAYOUT = 'form';
const SENT_LAYOUT = 'sent';

const useStyles = makeStyles((theme) => ({
    rootBackground: {
        background: `linear-gradient(180deg, rgba(26, 24, 133, 0.8) 27.08%, rgba(19, 29, 119, 0) 100%), url(${LoginBackground}) no-repeat center top / cover`,
        width: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
        position: 'fixed',
    },
    root: {
        height: '100%',
        overflowY: 'scroll',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0 0 0',
    },
    formContainer: {
        width: '300px',
    },
    button: {
        width: '300px',
        height: '48px',
        borderRadius: '24px',
        marginBottom: 20,
        fontSize: '14px',
        '&:disabled': {
            color: theme.palette.common.white,
        },
    },
    buttonText: {
        width: '100%',
        textAlign: 'initial',
        paddingLeft: '15px',
    },
    svgIcon: {
        marginRight: 10,
        width: '24px',
        height: '24px',
    },
    fieldBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    signInBox: {
        display: 'flex',
        flexDirection: 'column',
        '& p': {
            zIndex: 0,
        },
    },
    signInText: {
        color: theme.palette.common.white,
        zIndex: 9,
        margin: 40,
        fontWeight: 400,
    },
    logoBox: {
        padding: '20px',
    },
    logoImage: {
        width: '182px',
        height: 'auto',
    },
    field: {
        marginTop: '15px',
        marginBottom: '22px',
    },
    label: {
        color: `${theme.palette.common.white}!important`,
        fontSize: '18px',
        top: '-4px !important',
    },
    input: {
        fontSize: '18px',
        padding: '15px 20px',
        color: theme.palette.common.white,
        backgroundColor: 'rgba(255,255,255, 0.2)',
        borderRadius: '8px',
        WebkitBoxShadow: '0 0 0 1000px transparent inset !important',
        background:
            '-webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%)',
        transition:
            'background-color 5000s ease-in-out 0s, color 5000s, padding 5000s, font-size 5000s',
    },
    text: {
        margin: '20px 0',
        textAlign: 'center',
        color: theme.palette.common.white,
    },
    successText: {
        margin: '20px 0',
        textAlign: 'center',
        color: theme.palette.common.white,
        position: 'relative',
        fontSize: 18,
    },
    resendText: {
        textAlign: 'center',
    },
    resendTextLink: {
        color: theme.palette.common.white,
        cursor: 'pointer',
        position: 'relative',
        fontSize: 15,
    },
    loginLink: {
        color: theme.palette.common.white,
        fontWeight: 400,
    },
}));

export const SignUpPage = () => {
    const classes = useStyles();
    const query = useQuery();
    const { push } = useHistory();
    const { state } = useLocation() as any;
    const isUnauthorizedRedirect = state?.isUnauthorizedRedirect;
    const { authenticate, signup, resendSignUp } = useCurrentAuthData();
    const { error } = useNotice();
    const [layout, setLayout] = useState(FORM_LAYOUT);
    const [username, setUsername] = useState(null);
    const [emailFormView, setEmailFormView] = useState<boolean>(false);
    useEffect(() => {
        if (query.has('error_description')) {
            error(query.get('error_description'));
        }
    }, []);

    useEffect(() => {
        if (emailFormView) {
            document.getElementById('email-registration-form').scrollIntoView();
        }
    }, [emailFormView]);

    const signUpViaSocialMedia = async (socialMediaType: SocialMediaType) => {
        localStorage.setItem(localStorageIsRedirectionRequiredKey, 'true');
        await signup(
            new Auth.AmplifyFederatedSignInToken(socialMediaType),
        ).catch((e) => {
            console.error(e);
        });
    };

    const signUpValidation = (values) => {
        const errors: Partial<SignUpFormFormData> = {};
        if (!values.firstName.trim()) {
            errors.firstName = 'Required';
        }
        if (!values.lastName.trim()) {
            errors.lastName = 'Required';
        }
        if (!values.email.trim()) {
            errors.email = 'Required';
        }
        if (!values.password.trim()) {
            errors.password = 'Required';
        }
        if (!values.confirmPassword.trim()) {
            errors.confirmPassword = 'Required';
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = 'Passwords should match';
        }
        return errors;
    };

    const signUpWithEmail = (values, { setSubmitting }) => {
        localStorage.setItem(localStorageIsRedirectionRequiredKey, 'true');
        return signup(
            new Auth.SignUpUsernamePasswordToken(
                values.email,
                values.password,
                {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                },
            ),
        )
            .then(() => {
                setLayout(SENT_LAYOUT);
                setUsername(values.email);
            })
            .catch((e) => {
                console.error(e);
                error(e?.message || e);
                setSubmitting(false);
                return Promise.reject(e);
            });
    };

    let title;
    if (layout === FORM_LAYOUT) {
        title = isUnauthorizedRedirect
            ? 'You need to have a WeCo account'
            : 'Create WeCo account';
    } else {
        title = 'New Account Created!';
    }

    const continueWithEmail = () => {
        setEmailFormView(true);
    };

    return (
        <>
            <Box className={classes.rootBackground} />
            <Grid item className={classes.root}>
                <Grid item sm={12} className={classes.container}>
                    <IconButton
                        className={classes.logoBox}
                        onClick={() => push(RouterPaths.dashboard)}
                    >
                        <CardMedia
                            src={FullLogo}
                            component="img"
                            className={classes.logoImage}
                        />
                    </IconButton>
                    <Typography
                        variant="h2"
                        align="center"
                        className={classes.signInText}
                    >
                        {title}
                    </Typography>
                </Grid>
                {layout === FORM_LAYOUT && (
                    <>
                        <Grid item container justify="center">
                            <Grid item sm={12}>
                                <Box className={classes.fieldBox}>
                                    <Button
                                        id={WE_CLICKS.SIGNUP_GOOGLE}
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon
                                                className={classes.svgIcon}
                                            >
                                                <GoogleIcon />
                                            </SvgIcon>
                                        }
                                        className={classes.button}
                                        onClick={() =>
                                            signUpViaSocialMedia(
                                                SocialMediaType.Google,
                                            )
                                        }
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                        >
                                            Continue with Google
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box className={classes.fieldBox}>
                                    <Button
                                        id={WE_CLICKS.SIGNUP_FACEBOOK}
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon
                                                className={classes.svgIcon}
                                            >
                                                <FacebookIcon />
                                            </SvgIcon>
                                        }
                                        className={classes.button}
                                        onClick={() =>
                                            signUpViaSocialMedia(
                                                SocialMediaType.Facebook,
                                            )
                                        }
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                        >
                                            Continue with Facebook
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box className={classes.fieldBox}>
                                    <Button
                                        id={WE_CLICKS.SIGNUP_LINKEDIN}
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon
                                                className={classes.svgIcon}
                                            >
                                                <LinkedinIcon />
                                            </SvgIcon>
                                        }
                                        className={classes.button}
                                        onClick={() =>
                                            signUpViaSocialMedia(
                                                'LinkedIn' as SocialMediaType,
                                            )
                                        }
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                        >
                                            Continue with LinkedIn
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box
                                    className={classes.fieldBox}
                                    id="email-registration-form"
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon
                                                className={classes.svgIcon}
                                            >
                                                <MailOutlineIcon />
                                            </SvgIcon>
                                        }
                                        className={classes.button}
                                        onClick={continueWithEmail}
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                        >
                                            Continue with Email
                                        </Typography>
                                    </Button>
                                </Box>
                                {emailFormView && (
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: '',
                                            confirmPassword: '',
                                            firstName: '',
                                            lastName: '',
                                        }}
                                        validate={signUpValidation}
                                        onSubmit={signUpWithEmail}
                                    >
                                        {({
                                            submitForm,
                                            isSubmitting,
                                            isValid,
                                        }) => (
                                            <Box textAlign="center">
                                                <FormControl
                                                    className={
                                                        classes.formContainer
                                                    }
                                                >
                                                    <Field
                                                        name="firstName"
                                                        label="First Name"
                                                        type="text"
                                                        variant="outlined"
                                                        required
                                                        className={
                                                            classes.field
                                                        }
                                                        InputProps={{
                                                            classes: {
                                                                input:
                                                                    classes.input,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            className:
                                                                classes.label,
                                                        }}
                                                        component={WeTextField}
                                                    />
                                                    <Field
                                                        name="lastName"
                                                        label="Last Name"
                                                        type="text"
                                                        variant="outlined"
                                                        required
                                                        className={
                                                            classes.field
                                                        }
                                                        InputProps={{
                                                            classes: {
                                                                input:
                                                                    classes.input,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            className:
                                                                classes.label,
                                                        }}
                                                        component={WeTextField}
                                                    />
                                                    <Field
                                                        name="email"
                                                        label="Email"
                                                        type="email"
                                                        variant="outlined"
                                                        required
                                                        className={
                                                            classes.field
                                                        }
                                                        InputProps={{
                                                            classes: {
                                                                input:
                                                                    classes.input,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            className:
                                                                classes.label,
                                                        }}
                                                        component={WeTextField}
                                                        validate={
                                                            FormikValidators.validateEmail
                                                        }
                                                    />
                                                    <Field
                                                        name="password"
                                                        type="password"
                                                        label="Password"
                                                        variant="outlined"
                                                        required
                                                        className={
                                                            classes.field
                                                        }
                                                        InputProps={{
                                                            classes: {
                                                                input:
                                                                    classes.input,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            className:
                                                                classes.label,
                                                        }}
                                                        component={WeTextField}
                                                        validate={
                                                            FormikValidators.validatePassword
                                                        }
                                                    />
                                                    <Field
                                                        name="confirmPassword"
                                                        type="password"
                                                        label="Confirm Password"
                                                        variant="outlined"
                                                        required
                                                        className={
                                                            classes.field
                                                        }
                                                        InputProps={{
                                                            classes: {
                                                                input:
                                                                    classes.input,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            className:
                                                                classes.label,
                                                        }}
                                                        component={WeTextField}
                                                    />
                                                    {isSubmitting && (
                                                        <LinearProgress />
                                                    )}
                                                </FormControl>
                                                <br />
                                                <Button
                                                    id={WE_CLICKS.SIGNUP_EMAIL}
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classes.button}
                                                    disabled={
                                                        isSubmitting || !isValid
                                                    }
                                                    onClick={submitForm}
                                                >
                                                    Create WeCo account
                                                </Button>
                                            </Box>
                                        )}
                                    </Formik>
                                )}
                                <Box className={classes.signInBox}>
                                    <Typography
                                        paragraph
                                        variant="caption"
                                        align="center"
                                    >
                                        <Link
                                            href={RouterPaths.login}
                                            className={classes.loginLink}
                                        >
                                            Sign in
                                        </Link>
                                    </Typography>
                                    <MinimalisticFooter textColor="white" />
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
                {layout === SENT_LAYOUT && (
                    <SentVerifyEmail
                        resendSignUp={resendSignUp}
                        username={username}
                    />
                )}
            </Grid>
        </>
    );
};

SignUpPage.URL = '/signup';
