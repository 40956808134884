import { Box, Grid, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    tabs: {
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
    },
    tabsLoading: {
        margin: '10px 10px',
        width: '100%',
        height: '100%',
    },
}));

export const TabLoadingSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12}>
            <Box className={classes.tabs}>
                <Skeleton variant="rect" className={classes.tabsLoading} />
            </Box>
        </Grid>
    );
};
