import React, { Context } from 'react';
import { ProvidersContainer } from './ProvidersContainer';

export interface AppContextInterface {
    ioc: ProvidersContainer;
    rootStore: any;
}

export const AppContext: Context<AppContextInterface> = React.createContext<
    AppContextInterface
>({ ioc: null, rootStore: null });
