import classNames from 'classnames';
import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { matchedSections, ProjectEntity } from '@weco/core';
import { SearchResultItem } from './SearchResultItem';
import { useHistory } from 'react-router';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { CardFooter } from './CardFooter';
import { SpriteSvgIcon } from '../molecules';
import { environment } from 'apps/client/src/environments/environment';

const useStyles = makeStyles(() => ({
    root: {},
}));

export interface ProjectsListCompressedProps {
    currentUserId: string;
    projects: ProjectEntity[];
    className?: string;
    // NOTE: this is going to be removed when we find out how to use stores in storybook.
    openChat?: (params: {
        projectId: string;
        projectOwnerId: string;
    }) => Promise<void>;
    activeProject?: ProjectEntity;
}

const ProjectsListCompressed = memo((props: ProjectsListCompressedProps) => {
    const history = useHistory();
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    const goToProject = (project) => {
        if (props.currentUserId === project.owner?.id) {
            return `${RouterPaths.myProject}/${project.id}`;
        }
        return `${RouterPaths.projectPage}/${project.id}`;
    };

    const isMatchedItem = (score?: number) => {
        return score && score > environment.defaults.elasticNotMatchScore;
    };

    const getRoute = (ownerId) => {
        return props.currentUserId === ownerId
            ? RouterPaths.myProject
            : RouterPaths.projectPage;
    };

    return (
        <Box className={rootClasses}>
            {props.projects?.map((project, index) => {
                const isProjectOwner =
                    project.owner?.id === props.currentUserId;

                const matched = matchedSections(project.highlight);
                return (
                    <Box key={index} id={project.id}>
                        <SearchResultItem
                            type="project"
                            title={project.name}
                            withShareButton
                            chips={project.causes}
                            itemId={project.id}
                            description={project.dream}
                            favoriteIsHidden={isProjectOwner}
                            link={goToProject(project)}
                            avatarProps={{
                                isProjectOwner,
                                size: 'normal',
                                shape: 'square',
                                picture: project.picture,
                                percent: project.matchPercent,
                            }}
                            personIdToOpenChatWith={project.owner?.id}
                            isAuthenticated={!!props.currentUserId}
                            activeProject={props.activeProject}
                            isMatched={isMatchedItem(project.searchMatchScore)}
                            cardFooter={
                                <CardFooter
                                    icons={[
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="card-about-cards-footer" />
                                            ),
                                            label: 'About',
                                            url: `${getRoute(
                                                project.owner.id,
                                            )}/${project.id}`,
                                            isMatched: matched.isBioMatched,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="skill-team-card-footer" />
                                            ),
                                            label: 'Team',
                                            url: `${getRoute(
                                                project.owner.id,
                                            )}/${project.id}/team`,
                                            isMatched: matched.isSkillsMatched,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="resources-card-footer" />
                                            ),
                                            label: 'Resources',
                                            url: `${getRoute(
                                                project.owner.id,
                                            )}/${project.id}/resources`,
                                            isMatched:
                                                matched.isResourcesMatched,
                                        },
                                    ]}
                                />
                            }
                        />
                    </Box>
                );
            })}
        </Box>
    );
});

ProjectsListCompressed.displayName = 'ProjectsListCompressed';

export { ProjectsListCompressed };
