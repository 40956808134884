import {
    Box,
    Card,
    CardContent,
    Grid,
    Theme,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AvatarLoadingAnimation } from './AvatarLoadingAnimation';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
    },
    cardRoot: {
        height: '100%',
        padding: '20px',
        borderRadius: '6px',
        background: theme.palette.common.white,
        boxShadow: '0px 13px 16px rgba(0, 0, 0, 0.05)',
    },
    avatarBox: {
        width: '120px',
        height: '120px',
        margin: '0 auto',
    },
    tabs: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    tabsLoading: {
        margin: '10px 10px',
        width: '100%',
        height: '100%',
    },
}));

export const EntityLoadingSkeleton = () => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12}>
            <Box className={classes.root}>
                <Card className={classes.cardRoot}>
                    <Box m={1} />
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                    >
                        <Grid item xs={12}>
                            <AvatarLoadingAnimation />
                        </Grid>
                    </Grid>
                    <CardContent>
                        <Typography variant="subtitle1" align="center">
                            <Skeleton animation="wave" />
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
            <Box className={classes.tabs}>
                <Skeleton variant="rect" className={classes.tabsLoading} />
            </Box>
        </Grid>
    );
};
