import React, { memo } from 'react';
import { Box, Grid, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ProjectEntity } from '@weco/core';
import { useHistory } from 'react-router';
import { ProjectAvatarBox } from './ProjectAvatarBox';
import { toJS } from 'mobx';
import { MatchPercentage } from './MatchPercentage';
import { WeChip } from '../molecules';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    projectBox: {
        marginTop: 50,
        boxShadow: `0px 1px 7px #dcd9d9;`,
        padding: '10px',
        borderRadius: '5px',
        position: 'relative',
    },
    avatarBox: {
        width: '120px',
        height: '120px',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
    },
    projectInfoBox: {
        width: 'calc(100% - 125px)',
        marginLeft: '7px',
    },
    projectName: {
        color: '#8E8E8E',
        fontSize: '16px',
        fontWeight: 700,
        wordWrap: 'break-word',
        width: 'calc(100% - 60px)',
    },
    projectDescription: {
        color: theme.palette.text.secondary,
        fontSize: '14px',
        textAlign: 'justify',
        marginTop: 12,
        lineHeight: '16px',
    },
    projectChipBox: {
        marginTop: 15,
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    projectTeamBox: {
        display: 'flex',
    },
    projectTeamText: {
        color: '#8E8E8E',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '30px',
        margin: '0 10px',
    },
    teamAvatar: {
        width: 30,
        height: 30,
    },
    percentIcon: {
        top: '15px',
        right: '15px',
        width: 30,
        height: 30,
        padding: 0,
        minWidth: 30,
        borderRadius: '50%',
        position: 'absolute',
    },
}));

interface Props {
    item: ProjectEntity;
    projectPageURL: string;
    profilePageURL: string;
    isProjectOwner: boolean;
}

const projectCardWithSkillSets = ({
    item,
    isProjectOwner,
    projectPageURL: ProjectPageURL,
    profilePageURL: ProfilePageURL,
}: Props) => {
    const classes = useStyles();
    const { push } = useHistory();
    const goTo = (path) => {
        push(path);
    };
    const team = item.team?.filter((member) => member.id !== item.owner?.id);
    const owner = toJS(
        item.team?.find((member) => member.id === item.owner?.id),
    );

    return (
        <Box className={classes.projectBox}>
            {!isProjectOwner && (
                <MatchPercentage
                    number={item.matchPercent}
                    className={classes.percentIcon}
                />
            )}
            <Grid container>
                <Box
                    className={classes.avatarBox}
                    onClick={(e) => {
                        goTo(`${ProjectPageURL}/${item.id}`);
                    }}
                >
                    <ProjectAvatarBox picture={item.picture} />
                </Box>
                <Box className={classes.projectInfoBox}>
                    <Typography className={classes.projectName}>
                        {item.name}
                    </Typography>
                    {item.skillSets?.length > 0 && (
                        <Box className={classes.projectChipBox}>
                            <WeChip item={item.skillSets[0].name} />
                            {item.skillSets.length !== 1 && (
                                <WeChip
                                    item={`+${item.skillSets.length - 1}`}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Grid>
        </Box>
    );
};

export const ProjectCardWithSkillSets = memo(projectCardWithSkillSets);
