import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useBenefitComponentStyles = makeStyles((theme: Theme) =>
    createStyles({
        joinButton: {
            background:
                'linear-gradient(86.51deg, #544F94 13.51%, #755DD8 72.7%)',
            borderRadius: '10px',
            color: theme.palette.background.default,
            fontSize: '16px',
            fontWeight: 500,
            width: '80%',
            margin: '20px',
            padding: '10px',
        },
        infoText: {
            padding: '0px 15%',
            textAlign: 'center',
            '& h5': {
                margin: 10,
            },
        },
        joinImage: {
            width: '90%',
            minWidth: '193px',
            minHeight: '195px',
            margin: '0 auto',
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '-5px',
            fontSize: '32px',
        },
    }),
);
