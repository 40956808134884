import { Box, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import debug from 'debug';
import React, { memo } from 'react';

const log = debug('BottomSheetButtons');

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        paddingLeft: '5px',
        paddingRight: '5px',
        margin: '0 10px',
        width: '130px',
    },
    buttonsLayout: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
}));

interface Props {
    defaultRole?: boolean;
    isLeftButtonDisabled?: boolean;
    isRightButtonDisabled?: boolean;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
}

const BottomButtons = (props: Props) => {
    const cx = useStyles();
    return (
        <Box className={cx.buttonsLayout}>
            <Button
                color="default"
                disabled={props.isLeftButtonDisabled}
                onClick={() => props.onLeftButtonClick()}
                className={classNames(cx.button)}
            >
                {!props.defaultRole ? 'Delete' : 'Deactivate'}
            </Button>
            <Button
                disabled={props.isRightButtonDisabled}
                onClick={() => props.onRightButtonClick()}
                className={classNames(cx.button)}
            >
                Save
            </Button>
        </Box>
    );
};

export const BottomSheetButtons = memo(BottomButtons);
