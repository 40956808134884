import { UserTokenInterface } from '../../common';

export class AmplifyForgotPasswordToken implements UserTokenInterface {
    constructor(public username: string) {}

    isAuthenticated() {
        return false;
    }

    hasRole(name = '*') {
        return false;
    }

    getUsername() {
        return this.username;
    }

    getUser() {
        return undefined;
    }
}
