import React from 'react';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import HeaderStepper from './HeaderStepper';
import isEmpty from 'lodash/isEmpty';
import { defaultPicture } from '@weco/core';

export default function HeaderStepperContainer() {
    const {
        profile,
        getProfilePicture,
        userConnections,
    } = useMyUserProfileData();

    const picture = getProfilePicture(profile.picture);
    const isPictureLoaded =
        picture !== defaultPicture || profile.picture !== null ? true : false;

    const createdProjects = userConnections?.filter((item) =>
        item.roles.includes('owner'),
    );
    const isProjectCreated = !isEmpty(createdProjects);

    const joinedProjects = userConnections?.filter(
        (item) =>
            item.roles.includes('member') && !item.roles.includes('owner'),
    );
    const isJoinedToProject = !isEmpty(joinedProjects);

    const firstStepCompleted = isPictureLoaded ? true : false;
    const secondStepCompleted =
        isProjectCreated || isJoinedToProject ? true : false;

    return (
        <HeaderStepper
            firstStepCompleted={firstStepCompleted}
            secondStepCompleted={secondStepCompleted}
        />
    );
}
