import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useToolbarContentStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 0,
        },
        toolbarButton: {
            marginRight: '10px',
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
            border: 0,
            paddingLeft: 21,
            paddingRight: 21,
            '&:hover': {
                background: 'transparent',
            },
            '&:focus': {
                background: 'transparent',
            },
        },
        toolbarButtonHighlighted: {
            background: 'linear-gradient(0deg, #7368FF, #7368FF)',
            borderRadius: '10px',
            paddingLeft: 21,
            paddingRight: 21,
            border: 0,
        },
        toolbarJoinAction: {
            backgroundColor: '#ffcc00',
            color: theme.palette.primary[500],
            '&:active': {
                color: theme.palette.common.white,
            },
            '&:hover': {
                color: theme.palette.common.white,
            },
        },
    }),
);
