import React from 'react';
import { Button, makeStyles, Box, Dialog } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field } from 'formik';
import { WeTextField } from '../forms/WeTextField';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
    },
    title: {
        textTransform: 'capitalize',
    },
    buttonBox: {
        margin: '20px 0 20px 0',
        textAlign: 'center',
    },
    textStyles: {
        color: theme.palette.text.secondary,
        fontWeight: 400,
    },
    button: {
        paddingLeft: '40px',
        paddingRight: '40px',
        margin: '0 10px',
        width: '118px',
    },
}));

export const DeleteDialogView = ({
    itemName,
    openDialog,
    id,
    setOpenDialog,
    deleteAction,
    history,
    resultPath,
}) => {
    const classes = useStyles();

    const disabledCheck = (values, isValid) => {
        if (values.delete === '') return true;
        return !isValid;
    };

    const onClose = () => setOpenDialog(false);

    const onDelete = async () => {
        setOpenDialog(false);
        try {
            await deleteAction(id);
            history.push(resultPath);
        } catch (e) {
            console.log('deletion failed', e);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={openDialog} onClose={onClose}>
                <Box
                    style={{
                        paddingTop: 16,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingBottom: 16,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h5" className={classes.title}>
                        {itemName} Delete
                    </Typography>
                    <Typography variant="body1" className={classes.textStyles}>
                        Are you sure you want to delete your {itemName}, please
                        confirm deletion by type 'DELETE' in field?
                    </Typography>
                    <Formik initialValues={{ delete: '' }} onSubmit={onDelete}>
                        {({ handleSubmit, values, isValid }) => (
                            <Form>
                                <Field
                                    type="text"
                                    name="delete"
                                    color="primary"
                                    component={WeTextField}
                                    validate={(val) =>
                                        val === 'DELETE'
                                            ? undefined
                                            : 'Type DELETE here'
                                    }
                                    placeholder={'type here'}
                                />
                                <Box className={classes.buttonBox}>
                                    <Button
                                        className={classes.button}
                                        color="default"
                                        onClick={onClose}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        className={classes.button}
                                        disabled={disabledCheck(
                                            values,
                                            isValid,
                                        )}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        DELETE
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                    <Typography variant="body1" className={classes.textStyles}>
                        Please note that after this recovery will be impossible.
                    </Typography>
                </Box>
            </Dialog>
        </div>
    );
};
