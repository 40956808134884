export enum ConnectionStatus {
    NONE = 'NONE',
    INVITEE = 'INVITEE',
    APPLICANT = 'APPLICANT',
    CONNECTED = 'CONNECTED',
}

export enum HappyConnectionType {
    PERSON_TO_PROJECT = 'PERSON_TO_PROJECT',
    PROJECT_TO_PERSON = 'PROJECT_TO_PERSON',
}

export interface HappyConnectionInterface {
    type: HappyConnectionType;
    from: string;
    to: string;
    status: ConnectionStatus;
    payload: any;

    connect(): Promise<HappyConnectionInterface>;

    decline(): Promise<HappyConnectionInterface>;
}

export interface HappyConnection2Interface {
    projectId: string;
    personId: string;
    active: boolean;
    canHandleInvite: boolean;
    canHandleApplication: boolean;
    canRecallApplication: boolean;
    canRecallInvitation: boolean;
    data: any;
    connect?: () => Promise<any>;
    decline?: () => Promise<any>;
    recall?: () => Promise<any>;
}
