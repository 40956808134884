import { Box, Button, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TestAnswerEntity } from '@weco/core';
import { OnboardingProgressBar, ReactHelpers } from '@weco/ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSectionStyles } from './styles/quizStyles';

interface QuizPageWrapperProps {
    children: any;
    questions?: any[];
    answers?: TestAnswerEntity[][];
    activePage?: number;
    prevPage?: any;
    nextPage?: any;
    onClose?: () => void;
}

export const QuizPageWrapper = ({
    children,
    answers,
    questions,
    activePage,
    prevPage,
    nextPage,
    onClose,
}: QuizPageWrapperProps) => {
    const history = useHistory();
    const classes = useSectionStyles();
    const isLastPage = activePage >= questions.length;
    const [isDisabledNext, setDisabled] = useState(true);

    const pagePercents = [1, 13, 25, 38, 50, 63, 75, 88, 100];

    useEffect(() => {
        setDisabled(false);
        answers[activePage - 1].forEach((answ) => {
            if (answ.option === 0) {
                setDisabled(true);
                return;
            }
        });
    }, [answers, activePage]);

    return (
        <Box className={classes.root}>
            <Grid container justify="center">
                <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="column"
                        className={classes.content}
                    >
                        <Box>
                            <Box display="flex">
                                <IconButton
                                    className={classes.closeButton}
                                    onClick={() => {
                                        onClose && onClose();
                                    }}
                                >
                                    <CloseIcon
                                        className={classes.closeButtonIcon}
                                    />
                                </IconButton>
                            </Box>
                            <Box className={classes.contentBox}>
                                <OnboardingProgressBar
                                    percentage={pagePercents[activePage - 1]}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.contentBox}>
                            {ReactHelpers.renderChildren(children)}
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            className={classes.buttonBox}
                        >
                            {activePage > 1 && (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={prevPage}
                                    className={classes.backButton}
                                >
                                    Previous
                                </Button>
                            )}
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={
                                    isLastPage
                                        ? () => history.push('/quiz/finish')
                                        : nextPage
                                }
                                disabled={isDisabledNext}
                                className={classes.nextButton}
                            >
                                {isLastPage ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
