import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    default: {
        color: theme.palette.primary.contrastText,
    },
    selected: {
        color: theme.palette.secondary.main,
    },
    image: {
        maxWidth: '50px',
    },
}));

interface TopBarButtonProps {
    onClick?: (event) => void;
    selected?: boolean;
    icon: string;
}

export const TopBarButton = ({
    onClick,
    selected,
    icon,
}: TopBarButtonProps) => {
    const classes = useStyles();
    return (
        <IconButton
            style={{ padding: 12, marginRight: 15 }}
            className={selected ? classes.selected : classes.default}
            onClick={onClick}
        >
            <CardMedia
                src={icon}
                component="img"
                style={{ width: '58px', height: '32px' }}
            />
        </IconButton>
    );
};
