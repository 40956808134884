import React from 'react';
import MuiSwitch from '@material-ui/core/Switch';
import FormControlLabel, {
    FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import { FieldProps } from 'formik';
import { fieldToSwitch, WeSwitchProps } from './WeSwitch';

export interface WeSwitchPropsWithLabelProps extends FieldProps, WeSwitchProps {
    Label: Omit<
        MuiFormControlLabelProps,
        'checked' | 'name' | 'value' | 'control'
    >;
}

export function WeSwitchWithLabel<Val = unknown>({
    Label,
    ...props
}: WeSwitchPropsWithLabelProps) {
    return (
        <FormControlLabel
            control={<MuiSwitch {...fieldToSwitch(props)} />}
            {...Label}
        />
    );
}
