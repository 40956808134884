export const WeSuccess = {
    50: '#ECF4ED',
    100: '#D7E8D9',
    200: '#B0D1B3',
    300: '#88BA8E',
    400: '#61A368',
    500: '#398C42',
    600: '#2B6932',
    700: '#1D4621',
    800: '#0E2311',
    900: '#060E07',
    A100: '#D7E8D9',
    A200: '#88BA8E',
    A400: '#398C42',
    A700: '#0E2311',
};
