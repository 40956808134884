import classNames from 'classnames';
import React, { memo } from 'react';
import {
    Box,
    Theme,
    Grid,
    IconButton,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    backIconContainer: {
        alignSelf: 'center',
    },
    backIcon: {
        fontSize: 38,
        color: '#fff',
    },
    appBarMobile: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 11,
        backgroundColor: theme.palette.primary.main,
        padding: '10px 15px',
        color: theme.palette.text.secondary,
        fontSize: '20px',
        '& .Mui-selected': {
            color: theme.palette.text.primary,
            fontSize: '20px',
            textTransform: 'capitalize',
        },
        '& .MuiButtonBase-root': {
            textTransform: 'capitalize',
        },
        '& .MuiTabs-root': {
            backgroundColor: '#f3f3f3',
            color: '#8E8E8E',
        },
        '& .MuiTab-wrapper': {
            fontWeight: 700,
        },
    },
    appBarContainer: {
        height: 50,
    },
}));

interface NavBarWithBackButtonProps {
    /**
     * Component to be displayed to the right of back button.
     */
    children?: JSX.Element;
    className?: string;
}

const NavBarWithBackButton = memo((props: NavBarWithBackButtonProps) => {
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const isDesktopMode = useMediaQuery(theme.breakpoints.up('md'));
    const rootClasses = classNames(classes.root, props.className);

    return (
        <Box className={rootClasses}>
            <Grid
                item
                container
                className={classNames(!isDesktopMode && classes.appBarMobile)}
            >
                <Grid container className={classes.appBarContainer}>
                    <Grid item xs={1} className={classes.backIconContainer}>
                        <IconButton
                            edge="start"
                            onClick={history.goBack}
                            style={{ padding: '10px 0' }}
                        >
                            <ChevronLeftIcon className={classes.backIcon} />
                        </IconButton>
                    </Grid>
                    <Grid
                        container
                        item
                        justify="center"
                        alignItems="center"
                        xs={10}
                    >
                        {props.children}
                    </Grid>
                    {/* NOTE: this is needed to keep symmetry.
                        This will allow prevous block to be exactly at the center.
                     */}
                    <Grid item xs={1}>
                        <div />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
});

NavBarWithBackButton.displayName = 'NavBarWithBackButton';

export { NavBarWithBackButton };
