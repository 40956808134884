import debug from 'debug';
import classNames from 'classnames';
import React, { memo, ReactComponentElement } from 'react';
import { Theme, IconButton, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SpriteSvgIconButton } from '../molecules/SpriteSvgIconButton';

const log = debug('TopBarIcon');

const useStyles = makeStyles((theme: Theme) => ({
    default: {
        color: theme.palette.primary.contrastText,
    },
    selected: {
        color: theme.palette.secondary.main,
    },
    image: {
        maxWidth: '30px',
    },
    svgButton: {
        padding: 10,
        margin: 0,
    },
    avatarButton: {
        padding: 6,
        margin: 0,
    },
}));

interface TopBarIconProps {
    onClick?: (event) => void;
    selected?: boolean;
    isSvg?: boolean;
    svgId?: string;
    children?: ReactComponentElement<any>;
    style?: any;
}

const TopBarIcon = memo(
    ({
        onClick,
        selected,
        isSvg,
        svgId,
        children,
        ...props
    }: TopBarIconProps) => {
        const classes = useStyles();
        const selectedClass = selected ? classes.selected : classes.default;
        log('selectedClass: ', selectedClass);

        return (
            <>
                {isSvg && svgId ? (
                    <SpriteSvgIconButton
                        {...props}
                        aria-label="d"
                        classes={{
                            root: classNames(classes.svgButton, selectedClass),
                        }}
                        onClick={onClick}
                        id={svgId}
                    />
                ) : (
                    <IconButton
                        {...props}
                        aria-label="d"
                        onClick={onClick}
                        className={classNames(
                            classes.avatarButton,
                            selectedClass,
                        )}
                    >
                        {isSvg ? <SvgIcon>{children}</SvgIcon> : children}
                    </IconButton>
                )}
            </>
        );
    },
);

TopBarIcon.displayName = 'TopBarIcon';

export { TopBarIcon };
