import React, { useState, useEffect } from 'react';
import debug from 'debug';
import {
    Container,
    Typography,
    Box,
    Button,
} from '@material-ui/core';
// @ts-ignore
import Accordion from '@material-ui/core/ExpansionPanel';
// @ts-ignore
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
// @ts-ignore
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import { useFinishStyles } from './styles/quizStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import {
    TestResultEntity,
    getDepartmentDisplayName,
    SkillEntity,
    SkillSetsEntity,
    DepartmentEntity,
} from '@weco/core';
import parse from 'html-react-parser';
import { getDepartmentTestResultInfoByName } from '../../../../../apps/client/src/app/pages/quiz/store/resultData';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import { BoxList, BottomSheet, RoleCard } from '@weco/ui';
import { uniqBy } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { useStore } from '@weco/common';
import { MyUserStore } from '../../../../../apps/client/src/app/store/MyUserStore';

const log = debug('FinishComponent');

/* eslint-disable-next-line */
export interface FinishComponentProps {
    onFinish: () => Promise<any>;
    test: TestResultEntity;
    handleTryAgain: () => Promise<any>;
    selectedSkills: string[];
    defaultRoles: SkillSetsEntity[];
    allAddedRoles: SkillSetsEntity[];
    addRole(item: SkillSetsEntity): Promise<void>;
    updateRole(item: SkillSetsEntity): Promise<void>;
    deleteRole(item: SkillSetsEntity): Promise<void>;
    loadAutocompleteSkillsOptions(
        departmentName: string,
    ): Promise<SkillEntity[]>;
}

export const FinishComponent = ({
    handleTryAgain,
    onFinish,
    test,
    defaultRoles,
    allAddedRoles,
    addRole,
    updateRole,
    deleteRole,
    loadAutocompleteSkillsOptions,
}: FinishComponentProps) => {
    const classes = useFinishStyles();
    const myUserStore = useStore<MyUserStore>(MyUserStore);
    const { push } = useHistory();
    const goTo = (path) => {
        push(path);
    };

    const addedPositionsNames = allAddedRoles.map((i) => i.name);
    allAddedRoles.forEach((i) => (i.isSelected = true));

    const departments: DepartmentEntity[] = uniqBy(
        defaultRoles.map((i) => i.department),
        'id',
    );

    test.results.forEach((i) => {
        i.defaultPositions = []
            .concat(
                allAddedRoles.filter(
                    (pos) => pos.department?.name === i.department,
                ),
            )
            .concat(
                defaultRoles?.filter(
                    (pos) =>
                        pos.department.name === i.department &&
                        !addedPositionsNames.includes(pos.name),
                ),
            );
    });

    const [isSkillSetsOpen, setIsSkillSetsOpen] = useState(false);
    const [selectedSkillSets, setSelectedSkillSets] = useState<SkillSetsEntity>(
        null,
    );
    const [skillsAutocompleteOptions, setSkillsAutocompleteOptions] = useState<
        SkillEntity[]
    >([]);
    const toggleBottomSheet = () => {
        setIsSkillSetsOpen(!isSkillSetsOpen);
    };
    useEffect(() => {
        if (!isSkillSetsOpen) {
            setSkillsAutocompleteOptions([]);
        } else {
            loadAutocompleteSkillsOptions(
                selectedSkillSets?.department?.name,
            ).then(setSkillsAutocompleteOptions);
        }
    }, [isSkillSetsOpen]);

    const onItemClick = (item: SkillSetsEntity) => {
        if (!item.isSelected) {
            return addRole(item);
        }
        setSelectedSkillSets(item);
        setIsSkillSetsOpen(true);
    };

    const onSave = (data: SkillSetsEntity, saveAndClose = false) => {
        saveAndClose && setIsSkillSetsOpen(false);
        updateRole(data);
    };

    const finish = () => {
        onFinish();
        myUserStore.profile.hasFinishedOnboarding = true;
        myUserStore
            .updateProfile(myUserStore.profile)
            .then(() => {
                goTo(RouterPaths.myProfileSkills);
            })
            .catch((e) => {
                log('Error occured:', e);
            });
    };

    return (
        <React.Fragment>
            <Container maxWidth="md" style={{ padding: 10 }}>
                <Typography align="center" variant="h4">
                    {test.results.length > 0 &&
                        getDepartmentTestResultInfoByName(
                            test.results[0].department,
                        ).title}
                </Typography>
                {test.results.map((result, i) => {
                    const info = getDepartmentTestResultInfoByName(
                        result.department,
                    );
                    return (
                        <Accordion
                            key={i}
                            defaultExpanded={i === 0}
                            className={classes.accordionPaper}
                        >
                            <AccordionSummary
                                classes={{
                                    root: clsx(
                                        classes.accordion,
                                        classes[result.department],
                                    ),
                                    content: classes.accordionContent,
                                    expandIcon: classes.expandIcon,
                                }}
                                expandIcon={
                                    <ExpandMoreIcon
                                        className={classes.expand}
                                    />
                                }
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Box
                                    width={1}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        className={classes.accordionHeading}
                                    >
                                        {getDepartmentDisplayName(
                                            result.department,
                                        )}
                                    </Typography>
                                    <Typography
                                        className={classes.accordionHeading}
                                    >
                                        {Math.floor(
                                            (result.points /
                                                test.results[0].points) *
                                                100,
                                        )}
                                        %
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                <Box width={'100%'}>
                                    <Typography
                                        color="textSecondary"
                                        className={classes.detailsText}
                                    >
                                        {parse(info.description)}
                                    </Typography>
                                    <Box className={classes.positionsContainer}>
                                        <BoxList
                                            items={result.defaultPositions}
                                            onItemClick={onItemClick}
                                        />
                                        <Box className={classes.actionBox}>
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                onClick={() => {
                                                    const newPosition = new SkillSetsEntity();
                                                    const department = departments.find(
                                                        (item) =>
                                                            item.name ===
                                                            result.department,
                                                    );
                                                    newPosition.departmentId =
                                                        department.id;
                                                    newPosition.department = department;
                                                    newPosition.name =
                                                        'New Role';
                                                    onItemClick(newPosition);
                                                }}
                                                startIcon={<AddIcon />}
                                            >
                                                Add Role
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
                <Box m={7} />
                <Box className={classes.bottomActionBox}>
                    <Button
                        className={classes.bottomAction}
                        onClick={handleTryAgain}
                    >
                        Retry
                    </Button>
                    <Button
                        className={classes.bottomAction}
                        onClick={() => finish()}
                    >
                        Finish
                    </Button>
                </Box>

                <BottomSheet
                    isOpen={isSkillSetsOpen}
                    onClose={toggleBottomSheet}
                >
                    <Box>
                        <RoleCard
                            data={selectedSkillSets}
                            onSave={onSave}
                            autocompleteOptions={skillsAutocompleteOptions}
                            onDeleteBtnClick={() => {
                                deleteRole(selectedSkillSets);
                                setIsSkillSetsOpen(false);
                            }}
                        />
                    </Box>
                </BottomSheet>
            </Container>
        </React.Fragment>
    );
};
