import {
    Box,
    createStyles,
    Grid,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { Alert } from '@material-ui/lab';
/**
 * NOTE: import only the parts you need.
 * DO NOT use "import * as firebase from 'firebase'"
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { useEffect } from 'react';
import ChatRoomWrapper from './ChatRoomWrapper';
import RoomsContainer from '../containers/RoomsContainer';
import { CreateRoomInterface } from '../models/interfaces/CreateRoomInterface';
import {
    RoomInterface,
    TmpRoomInterface,
} from '../models/interfaces/RoomInterface';
import UserInterface from '../models/interfaces/UserInterface';
import Loader from './Loader';
import {
    ROOM_TYPE_PEOPLE,
    OnAvatarClickInterface,
    UserRoomInterface,
} from '@weco/chat';
import { SkillSetsEntity } from '@weco/core';
import MessageInterface from '../models/interfaces/MessageInterface';
import { TMP_ROOM_ID } from '../utils/constants';
import { assignProjectMemberToRole } from 'libs/core/src/lib/repositories/gqlRequests';

const styles = (theme: Theme) =>
    createStyles({
        wrapper: {
            color: theme.palette.text.primary,
            height: '100%',
            background: theme.palette.common.white,
            padding: '0 6%',
        },
        loaderContainer: {
            height: 'calc(100% - 70px)',
        },
        roomsList: {
            paddingTop: 35,
        },
    });

export interface ChatProps extends WithStyles {
    token: string | null;
    chatTitle: string;
    findUserByName: Function;
    addUserToRoom: Function;
    setActiveRoom: (
        firestore: firebase.firestore.Firestore,
        id: string,
        subscribe: boolean,
    ) => void;
    setActiveRoomAndTab: (
        firestore: firebase.firestore.Firestore,
        filterType: string,
        id: string,
        subscribe: boolean,
    ) => void;
    subscribedRoomsIds: string[];
    createRoom: CreateRoomInterface;
    activeRoom: string;
    user: UserInterface;
    error: Error;
    loading: boolean;
    rooms: { [key: string]: RoomInterface };
    mbOpenRoom?: {
        id: string;
        type: string;
    };
    chatUserId: string;
    fetchCurrentUser: (
        firestore: firebase.firestore.Firestore,
        id: string,
    ) => void;
    receiveUserCredentials: (id: string, error: Error) => void;
    firestore: firebase.firestore.Firestore;
    onAvatarClick: OnAvatarClickInterface;
    getSkillSetById(id: number): Promise<SkillSetsEntity>;
    tmpRoom: TmpRoomInterface;
    roomsMessages: { [key: string]: MessageInterface[] };
    setActiveTmpRoomAndTab: (tmpRoom: TmpRoomInterface) => void;
    replaceTmpRoomToExists: (
        firestore: firebase.firestore.Firestore,
        rooms: { [key: string]: RoomInterface },
        roomsMessages: { [key: string]: MessageInterface[] },
        roomId: string,
    ) => void;
    deleteTmpRoom: (
        firestore: firebase.firestore.Firestore,
        roomId: string,
    ) => void;
    fetchRooms: (
        firestore: firebase.firestore.Firestore,
        rooms: UserRoomInterface[],
    ) => void;
}

const Chat = (props: ChatProps) => {
    const {
        chatUserId,
        fetchCurrentUser,
        receiveUserCredentials,
        subscribedRoomsIds,
        firestore,
        setActiveRoomAndTab,
        mbOpenRoom,
        loading,
        user,
        error,
        classes,
        onAvatarClick,
        getSkillSetById,
        tmpRoom,
        setActiveTmpRoomAndTab,
    } = props;

    const previousTmpRooms = useRef(null);
    const fetchingNewRoom = useRef(false);

    useEffect(() => {
        if (chatUserId) {
            fetchCurrentUser(firestore, chatUserId);
            receiveUserCredentials(chatUserId, null);
        }
    }, [chatUserId]);

    useEffect(() => {
        if (
            !tmpRoom &&
            !props.rooms[props.token] &&
            props.activeRoom === TMP_ROOM_ID &&
            props.token &&
            !fetchingNewRoom.current
        ) {
            fetchingNewRoom.current = true;
            props.fetchRooms(props.firestore, [
                {
                    room: {
                        id: props.token,
                    },
                    unreadCount: 0,
                },
            ]);
        } else if (
            !tmpRoom &&
            previousTmpRooms.current &&
            previousTmpRooms.current.room.id == TMP_ROOM_ID &&
            props.rooms[props.token] &&
            fetchingNewRoom.current
        ) {
            fetchingNewRoom.current = false;
            props.replaceTmpRoomToExists(
                firestore,
                props.rooms,
                props.roomsMessages,
                props.token,
            );
        } else if (
            !tmpRoom &&
            props.rooms[props.token] &&
            props.activeRoom === props.token &&
            props.rooms[TMP_ROOM_ID] &&
            !fetchingNewRoom.current
        ) {
            props.deleteTmpRoom(props.firestore, TMP_ROOM_ID);
            previousTmpRooms.current = null;
        }
    }, [props.rooms, props.token, props.activeRoom, tmpRoom]);

    useEffect(() => {
        if (tmpRoom) {
            const roomExists = Object.values(props.rooms).find(
                (room) =>
                    room.members.findIndex(
                        (member) => member.id === tmpRoom.user.id,
                    ) !== -1,
            );
            if (roomExists) {
                setActiveRoomAndTab(
                    firestore,
                    tmpRoom.room.type || ROOM_TYPE_PEOPLE,
                    roomExists.id,
                    subscribedRoomsIds.indexOf(roomExists.id) === -1,
                );
            } else {
                setActiveTmpRoomAndTab(tmpRoom);
                previousTmpRooms.current = tmpRoom;
            }
        } else if (mbOpenRoom && mbOpenRoom.id !== TMP_ROOM_ID) {
            setActiveRoomAndTab(
                firestore,
                mbOpenRoom.type || ROOM_TYPE_PEOPLE,
                mbOpenRoom.id || null,
                subscribedRoomsIds.indexOf(mbOpenRoom.id) === -1,
            );
        }
    }, [mbOpenRoom.id, mbOpenRoom.type, tmpRoom]);

    const roomNotSelectedOrRoomNotFetched =
        !props.activeRoom ||
        (props.activeRoom && !props.rooms[props.activeRoom]);

    if (loading || (!user && !error)) {
        return (
            <Box className={props.classes.wrapper}>
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    className={classes.loaderContainer}
                >
                    <Loader />
                </Grid>
            </Box>
        );
    }

    return (
        <Box className={classes.wrapper}>
            {error && <Alert severity="error">{error.message}</Alert>}
            {roomNotSelectedOrRoomNotFetched ? (
                <Box className={classes.roomsList}>
                    <RoomsContainer
                        firestore={firestore}
                        chatTitle={props.chatTitle}
                        createRoom={props.createRoom}
                        findUserByName={props.findUserByName}
                        onAvatarClick={props.onAvatarClick}
                    />
                </Box>
            ) : (
                <ChatRoomWrapper
                    firestore={firestore}
                    addUserToRoom={props.addUserToRoom}
                    findUserByName={props.findUserByName}
                    onAvatarClick={onAvatarClick}
                    getSkillSetById={getSkillSetById}
                />
            )}
        </Box>
    );
};

export default withStyles(styles)(Chat);
