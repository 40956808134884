import { useState } from 'react';
import React from 'react';

export const ChatContext = React.createContext(undefined as any);

export interface ChatContextInterface {
    firebase?: any;
    setFirebase: (firebase: any) => void;
}

export const ChatContextProvider = ({ children }: any) => {
    const [context, setContext] = useState<ChatContextInterface>({
        firebase: null,
        setFirebase: (firebase: any) => {
            context.firebase = firebase;
            setContext(context);
        },
    } as ChatContextInterface);

    return (
        <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
    );
};
