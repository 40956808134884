import React from 'react';
import {
    RoomsFilterInterface,
    RoomUnreadInterface,
} from '../models/interfaces/RoomInterface';
import { Badge, Box, Button, createStyles, Theme } from '@material-ui/core';
import { ROOM_TYPE_PEOPLE, ROOM_TYPE_PROJECTS } from '../utils/constants';
import { makeStyles } from '@material-ui/core/styles';

import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '20px 0',
        },
        tab: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            textTransform: 'capitalize',
            margin: '0 40px',
            border: 0,
            borderRadius: 0,
            padding: '3px 10px',
            fontSize: '18px',
            fontWeight: 400,
            borderBottom: `3px solid ${theme.palette.common.white}`,
            '&:hover': {
                fontWeight: 500,
                backgroundColor: theme.palette.common.white,
                boxShadow: '0 0 black',
            },
            '&:focus': {
                fontWeight: 500,
                backgroundColor: theme.palette.common.white,
                boxShadow: '0 0 black',
                borderBottom: `3px solid ${theme.palette.primary[200]}`,
            },
        },
        activeTab: {
            fontWeight: 500,
            boxShadow: '0 0 black',
            borderBottom: `3px solid ${theme.palette.primary[200]}`,
        },
    }),
);

export const RoomsFilter = ({
    currentUser,
    rooms,
    type,
    setType,
}: RoomsFilterInterface) => {
    const classes = useStyles();
    const types: string[] = [ROOM_TYPE_PROJECTS, ROOM_TYPE_PEOPLE];
    const unreadCounts: RoomUnreadInterface = {
        [ROOM_TYPE_PEOPLE]: 0,
        [ROOM_TYPE_PROJECTS]: 0,
    };
    if (currentUser && currentUser.rooms && currentUser.rooms.length) {
        const roomsUnReads: RoomUnreadInterface = {};
        currentUser.rooms.forEach((userRoom) => {
            roomsUnReads[userRoom.room.id] = userRoom.unreadCount;
        });
        Object.keys(rooms).forEach((roomId: string) => {
            if (
                rooms[roomId] &&
                types.indexOf(rooms[roomId].type) !== -1 &&
                Object.prototype.hasOwnProperty.call(roomsUnReads, roomId)
            ) {
                unreadCounts[rooms[roomId].type] += roomsUnReads[roomId];
            }
        });
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.root}
        >
            {types.map((curType: string) => (
                <Button
                    key={curType}
                    onClick={() => {
                        setType(curType);
                    }}
                    className={classNames({
                        [classes.tab]: true,
                        [classes.activeTab]: type === curType,
                    })}
                >
                    <Badge badgeContent={unreadCounts[curType]} color="primary">
                        {/*note for chat refactoring:
                        labels shouldn't be a part of logic in other places*/}
                        {curType === 'projects' ? 'teams' : curType}
                    </Badge>
                </Button>
            ))}
        </Box>
    );
};
