import React from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import { OnAnyEventHandler } from '../helpers';
import { SpriteSvgIcon } from './SpriteSvgIcon';

interface SpriteSvgIconButtonProps {
    id: string;
    style?: any;
    classes?: any;
    onClick?: OnAnyEventHandler;
    sprite?: string;
    className?: string;
}

export const SpriteSvgIconButton = (props: SpriteSvgIconButtonProps) => {
    const { id, classes, style = {}, onClick } = props;
    return (
        <IconButton
            style={style}
            onClick={onClick}
            className={classNames(classes?.root, props.className)}
        >
            <SpriteSvgIcon classes={classes} id={id} />
        </IconButton>
    );
};
