import React from 'react';
import { useUnauthorizedDrawerStyles } from './styles/UnauthorizedDrawer.styles';
import Create from '../../../../assets/images/about/benefit-join2.png';
import Join from '../../../../assets/images/about/benefit-build2.png';
import { RouterPaths } from '../../../router/RouterPaths';
import { Box, Typography, CardMedia, Button } from '@material-ui/core';
import classNames from 'classnames';
import { useHistory } from 'react-router';

export interface UnauthorizedDrawerProps {
    type: 'founders' | 'everyone';
}

const benefitTexts = {
    founders: [
        'Start your project with a budget of zero.',
        'Describe your project requirements.',
        'Be matched with potential teammates.',
        'Create as many projects as you want.',
    ],
    everyone: [
        'Showcase your skills, interests and passions.',
        'Be matched with the best opportunities.',
        'Join as many projects as you can handle.',
        'Be compensated for the value you create.',
    ],
};

export const UnauthorizedDrawer = ({ type }: UnauthorizedDrawerProps) => {
    const isForEveryOne = type === 'everyone';
    const classes = useUnauthorizedDrawerStyles();
    const history = useHistory();

    function goTo(path) {
        history.push(path);
    }

    return (
        <Box className={classes.drawer}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection="column"
                className={classNames(classes.drawerPaper, classes.sticky)}
            ></Box>
        </Box>
    );
};
