import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useLeftSideBarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 5,
            backgroundColor: theme.palette.primary[500],
        },
        buttonBox: {
            textAlign: 'center',
        },
        button: {
            margin: '7px 0',
            padding: '10px',
            position: 'relative',
        },
        buttonSvg: {
            fontSize: 33,
            padding: 0,
        },
        topMenu: {
            margin: '0px 0px 17px 0px',
        },
        logoImage: {
            width: '113px',
            height: 'auto',
        },
        logoImageBeta: {
            width: '185px',
            height: 'auto',
            top: '6px',
            position: 'relative',
            right: '-5px',
        },
        logoIcon: {
            margin: '0 auto',
            display: 'block',
            padding: '10px',
        },
        buttonLink: {
            paddingTop: 2,
            fontSize: 14,
        },
        name: {
            marginTop: 5,
            fontStyle: 'normal',
            fontSize: '18px',
            textAlign: 'center',
            color: theme.palette.common.white,
        },
        dataToolbar: {
            flex: 1,
            justifyContent: 'flex-start',
            paddingLeft: '0px',
        },
        menuList: {
            // NOTE: bottom action bar in mobile safari browser prevent
            // user to click bottom list items.
            // https://wecoapp.atlassian.net/browse/WE-178?atlOrigin=eyJpIjoiYmIzMDVmZTM0YzY3NGM2MThlMTBlNTVmYjI5ODg4YTEiLCJwIjoiaiJ9
            marginBottom: '40px',
        },
    }),
);

export const useLeftSideBarProjectListStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSvg: {
            fontSize: 33,
            padding: 0,
        },
        expansionButton: {
            color: 'rgba(255,255,255,.5)',
            padding: '0',
            marginRight: '12px',
        },
        moreButton: {
            fontFamily: 'Ubuntu',
        },
        expansionRoot: {
            width: '100%',
            marginTop: 20,
        },
        expansionPanel: {
            background: 'none',
            borderTop: `1px solid ${theme.palette.primary[600]}`,
            borderBottom: `1px solid ${theme.palette.primary[600]}`,
            borderRadius: '0!important',
            borderLeft: 'none',
            borderRight: 'none',
            padding: '0 !important',
            margin: '38px 20px 0 20px !important',
            boxShadow: 'none',
            paddingBottom: 10,
        },
        projectsText: {
            lineHeight: '3.5rem',
            margin: '0',
            color: '#fff',
        },
        projectList: {
            padding: '0',
            width: '100%',
        },
        projectListRoot: {
            width: '100%',
            padding: '18px 0',
        },
        expansionSvg: {
            fontSize: 38,
            color: '#fff',
        },
        expansionContent: {
            padding: '0 !important',
            margin: '30 !important',
        },
        addIcon: {
            fontSize: '2.8rem',
            marginLeft: '2px',
            color: '#81879d',
        },
        createProjectText: {
            marginLeft: '8px',
            fontFamily: 'Ubuntu',
            fontSize: '17px',
        },
    }),
);

export const useLeftSideBarListStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSvg: {
            fontSize: 33,
            padding: 0,
            '&.MuiSvgIcon-root': {
                color: 'transparent',
            },
        },
        list: {
            margin: '0 20px',
        },
        logoutListItem: {
            borderTop: `1px solid ${theme.palette.primary[600]}`,
            paddingTop: 15,
        },
        listButton: {
            color: 'rgba(255,255,255,.5)',
            padding: '0 4px',
            marginRight: '12px',
        },
        listItemText: {
            fontSize: '18px',
            color: theme.palette.common.white,
            fontWeight: 300,
            marginTop: 0,
        },
        addButton: {
            fontSize: '18px',
            marginTop: 0,
            marginBottom: 15,
            padding: 8,
            color: theme.palette.info.main,
            backgroundColor: 'transparent',
            border: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    }),
);
