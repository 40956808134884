import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { OnAnyEventHandler } from '../helpers/CommonTypes';
import { ProfileIcon } from './ProfileIcon';
import { defaultPicture, Picture } from '@weco/core';
import { getPictureUrl } from '@weco/common';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'relative',
    },
    container: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    avatar: {
        maxHeight: '100%',
        width: '100%',
        height: '100%',
    },

    small: {
        width: 24,
        height: 24,
    },
    middle: {
        width: 28,
        height: 28,
    },
    normal: {
        width: 32,
        height: 32,
    },
    'x-large': {
        width: 169,
        height: 169,
    },
    'desktop-large': {
        width: 120,
        height: 120,
    },
    large: {
        width: 64,
        height: 64,
    },
    closerAvatar: {
        marginRight: '-10px',
    },
    noCloserAvatar: {
        marginLeft: '10px',
    },
}));

export type AvatarIconProps = {
    picture?: Picture | string;
    onClick?: OnAnyEventHandler;
    size?: AvatarSize;
    text?: string;
    avatarPosition?: boolean;
};
export enum AvatarSize {
    small = 'small',
    middle = 'middle',
    normal = 'normal',
    large = 'large',
    desktopLarge = 'desktop-large',
    xLarge = 'x-large',
}
const component = ({
    picture,
    onClick,
    size,
    text,
    avatarPosition,
}: AvatarIconProps) => {
    const classes = useStyles();
    const iconSizeClass = size ? classes[size] : classes.normal;
    const closerAvatarClass = avatarPosition ? classes.closerAvatar : null;
    const noCloserAvatarClass = text ? classes.noCloserAvatar : null;
    const placeholderImageUrl =
        typeof picture === 'string'
            ? picture
            : getPictureUrl(picture, defaultPicture);

    return (
        <Box
            className={[
                classes.root,
                closerAvatarClass,
                noCloserAvatarClass,
                iconSizeClass,
            ].join(' ')}
            style={{
                fontSize:
                    iconSizeClass === 'normal' || iconSizeClass === 'large'
                        ? '15px'
                        : iconSizeClass === 'x-large'
                        ? '32px'
                        : '12px',
            }}
            onClick={onClick}
        >
            <ProfileIcon
                picture={placeholderImageUrl as string}
                text={text as string}
            />
        </Box>
    );
};

export const AvatarIcon = memo(component);
