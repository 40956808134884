import { DepartmentEntity } from './DepartmentEntity';
import { Exclude, Transform, TransformationType } from 'class-transformer';
import { ProjectEntity } from './ProjectEntity';
import { SkillEntity } from './SkillEntity';
import { UserProfileEntity } from './UserProfileEntity';
import debug from 'debug';
import { isBoolean } from 'lodash';

const log = debug('SkillSetsEntity');

const toClass = (value) =>
    [].concat(value || []).reduce((result, item) => {
        if (item && item.includes(':')) {
            const [departmentName, value] = item.split(':');
            result.push({
                id: item,
                departmentName: departmentName,
                value: value,
            });
        }
        return result;
    }, []);

const toPlain = (value) => {
    log('toPlain() is called.');
    const tmp = [].concat(value || []).map((item) => {
        return item.id.toLowerCase();
    });
    log('data to be returned.: ', tmp);
    return tmp;
};
export interface INamed {
    name?: string;
    isDefault?: boolean;
    isSelected?: boolean;
}
export class SkillSetsEntity implements INamed {
    id?: string;
    departmentId?: string;
    name?: string;
    description?: string = '';
    ownerId?: string;

    @Exclude({ toPlainOnly: true })
    owner?: UserProfileEntity;

    projectId?: string;
    @Exclude({ toPlainOnly: true })
    project?: ProjectEntity;
    @Exclude({ toPlainOnly: true })
    department?: DepartmentEntity;

    @Transform((value, obj, type) =>
        type === TransformationType.CLASS_TO_PLAIN
            ? toPlain(value)
            : toClass(value),
    )
    skills?: SkillEntity[] = [];
    @Exclude()
    isDefault?: boolean;

    // Hack to setup isSelected to true for position which was add earlier.
    @Transform((value) => (isBoolean(value) ? value : true))
    isSelected?: boolean;
    @Exclude()
    @Transform((value) => value || 0)
    order?: number = 0;
}
