import { makeStyles, Theme } from '@material-ui/core';

export const collaborationActionStyles = makeStyles((theme: Theme) => ({
    root: {
        left: 0,
        bottom: 0,
        zIndex: 10,
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.only('md')]: {
            left: '200px',
        },
    },
    actionRootNotInViewPort: {
        position: 'fixed',
    },
    actionRootInViewPort: {
        position: 'static',
    },
    action: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    actionButtonBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    actionButton: {
        textTransform: 'uppercase',
        width: '120px',
        fontWeight: 500,
        background: `linear-gradient(101.02deg, ${theme.palette.primary[475]} -0.1%, ${theme.palette.primary[550]} 118.38%), linear-gradient(86.51deg, ${theme.palette.primary[450]} 13.51%, ${theme.palette.primary[480]} 72.7%)`,
        '&:focus': {
            background: `linear-gradient(180deg, ${theme.palette.primary[475]} 0%, ${theme.palette.primary[550]} 100%)`,
        },
        '&:hover': {
            background: `linear-gradient(180deg, ${theme.palette.primary[475]} 0%, ${theme.palette.primary[550]} 100%)`,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
        border: 'none',
    },
    connectAction: {
        background: '#ffc540',
        color: '#2e2962',
        border: 'none',
        '&:hover': {
            background: '#ffc540',
        },
        '&:focus': {
            background: '#ffc540',
        },
    },
    rejectButton: {
        background: theme.palette.common.white,
        color: theme.palette.text.secondary,
    },
    spacing: {
        width: '100%',
        padding: '40px',
        [theme.breakpoints.up('md')]: {
            padding: 0,
        },
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.grey[500],
        zIndex: 1,
    },
}));
