export const WeError = {
    50: '#FBE8E8',
    100: '#F6CFCF',
    200: '#ED9F9F',
    300: '#E47070',
    400: '#DB4040',
    500: '#D21010',
    600: '#9E0C0C',
    700: '#690808',
    800: '#350404',
    900: '#150202',
    A100: '#F6CFCF',
    A200: '#E47070',
    A400: '#D21010',
    A700: '#350404',
};
