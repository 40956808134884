import React from 'react';
import { AsyncHookResult } from '../hooks';
import { ReactHelpers } from '../helpers';
import { InfinityLoader } from './InfinityLoader';
import { CircleLoader } from './CircleLoader';

export type AsyncLoaderProps = AsyncHookResult & {
    onError?: (error: Error | any) => boolean | void;
    children?: React.ReactElement;
    loader?: any;
    errorComponent?: React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
    >;
};

export const AsyncLoader = ({
    loading,
    error,
    value,
    onError,
    errorComponent,
    children,
    loader,
}: AsyncLoaderProps) => {
    if (error) {
        if (onError && onError(error)) {
            return null;
        }
        if (errorComponent) {
            return ReactHelpers.renderChildren(errorComponent, { error });
        }
        return <div className="error"> {error.message || error} </div>;
    }
    if (loading) {
        return (
            <>
                {loader && ReactHelpers.renderChildren(loader)}
                {/*{!loader && <InfinityLoader />}  NOTE: hidden for now but maybe we will use this again later.*/}
                {!loader && <CircleLoader />}
            </>
        );
    }
    return ReactHelpers.renderChildren(children, { value });
};
