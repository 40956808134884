import { Injectable, Transformers } from '@weco/common';
import { GraphQlBaseRepository } from './GraphQlBaseRepository';
import { config } from './config';
import { createSchool } from './gqlRequests';
import _ from 'lodash';
import { gql } from 'apollo-boost';
import { listSchools } from './graphql/queries';
import { SchoolEntity } from '../entity/SchoolEntity';

export interface SchoolsRepositoryInterface {
    getMatchList(limit?: number): Promise<SchoolEntity[]>;
}

@Injectable()
export class SchoolsRepository extends GraphQlBaseRepository
    implements SchoolsRepositoryInterface {
    async getMatchList(
        limit = config.defaults.queries.limit,
    ): Promise<SchoolEntity[]> {
        return this.unAuthorizedClient
            .query({ query: gql(listSchools) })
            .then((data) => {
                let result = data.data.listSchools.items;
                return result;
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<SchoolEntity>(
                            SchoolEntity,
                        ),
                    ),
                ),
            );
    }

    public async addSchool(item: SchoolEntity): Promise<SchoolEntity> {
        const mutation = gql(createSchool);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    input: {
                        ..._.omit(
                            new Transformers.ToPlainTransformer().transform(
                                item,
                            ),
                            ['owner', '__typename', 'location'],
                        ),
                    },
                },
                update: () => this.invalidateAllQueryFetching('listSkillSets'),
            })
            .then((result) => result.data.createSkillSet)
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer<SchoolEntity>(
                        SchoolEntity,
                    ),
                ),
            );
    }
}
