import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PhoneIcon from '@material-ui/icons/Smartphone';
import ErrorIcon from '@material-ui/icons/Error';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import SkypeIcon from 'mdi-material-ui/Skype';
import HangoutsIcon from 'mdi-material-ui/GoogleHangouts';
import Soundcloud from 'mdi-material-ui/Soundcloud';
import { SpriteSvgIcon } from './SpriteSvgIcon';

const useStyles = makeStyles((theme) => ({}));

export type IconTypes =
    | 'email'
    | 'phone'
    | 'skype'
    | 'telegram'
    | 'whatsapp'
    | 'hangouts'
    | 'googlevoice'
    | 'website'
    | 'instagram'
    | 'linkedin'
    | 'youtube'
    | 'facebook'
    | 'soundcloud'
    | undefined;

export interface IconByTypeProps {
    type: IconTypes;
}

export const IconByType = ({ type }: IconByTypeProps) => {
    const classes = useStyles();
    const IconsMap = {
        email: {
            icon: SpriteSvgIcon,
            props: { id: 'Profile-Contacts-Mail', color: 'secondary' },
        },
        phone: { icon: PhoneIcon, props: { color: 'primary' } },
        skype: { icon: SkypeIcon, props: { style: { color: '#00aff0' } } },
        telegram: {
            icon: TelegramIcon,
            props: { style: { color: '#0088CC' } },
        },
        whatsapp: {
            icon: WhatsAppIcon,
            props: { style: { color: '#43d854' } },
        },
        hangouts: { icon: HangoutsIcon },
        googlevoice: { icon: HangoutsIcon, props: { color: 'primary' } },
        website: { icon: LanguageIcon, props: { color: 'primary' } },
        instagram: {
            icon: InstagramIcon,
            props: { style: { color: '#e11894' } },
        },
        linkedin: {
            icon: LinkedInIcon,
            props: { style: { color: '#006cac' } },
        },
        youtube: { icon: YouTubeIcon, props: { style: { color: '#ff0004' } } },
        facebook: {
            icon: FacebookIcon,
            props: { style: { color: '#3b5998' } },
        },
        soundcloud: {
            icon: Soundcloud,
            props: { style: { color: '#f76811' } },
        },
    };

    const getIcon = (contactType) => {
        const icon = IconsMap[contactType];
        if (!icon) return { icon: ErrorIcon, props: { color: 'action' } };
        if (!icon.props) icon.props = {};
        return icon;
    };
    const iconByType = getIcon(type);
    return <iconByType.icon {...iconByType.props} fontSize="large" />;
};
