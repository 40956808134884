import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { useMemo, useState } from 'react';
import {
    PersonProfileStore,
    PersonProfileStoreInterface,
} from './PersonProfileStore';

export type PersonProfileData = Omit<PersonProfileStoreInterface, ''> & {
    loading: boolean;
};
type PersonProjectsData = Pick<
    PersonProfileStoreInterface,
    'projects' | 'loadProjects'
> & {
    loading: boolean;
};

export const usePersonProfileData = (): PersonProfileData => {
    const store = useStore<PersonProfileStoreInterface>(PersonProfileStore);
    const [loading, setLoading] = useState(false);

    const doLoadInitData = async () => {
        if (!store.profile || !store.profile.id) {
            return;
        }
        setLoading(true);
        try {
            await store.loadPersonProjectConnection();
            await store.loadMatchPercent();
            setLoading(false);
        } catch (e) {
            console.log('error', e);
            setLoading(false);
        }
    };

    useMemo(doLoadInitData, [store.profile && store.profile.id]);
    return useObserver(
        () =>
            ({
                ...store,
                loading,
                personProjectConnection: store.personProjectConnection,
                setPersonProjectConnection: store.setPersonProjectConnection,
                inviteToActiveProject: store.inviteToActiveProject,
                declineApplication: store.declineApplication,
                acceptApplication: store.acceptApplication,
                removeProjectMember: store.removeProjectMember,
                recallInvitation: store.recallInvitation,
                isApplicant: store.isApplicant,
                isMember: store.isMember,
                ableToInvite: store.ableToInvite,
                invited: store.invited,
                getProfilePicture: store.getProfilePicture,
            } as PersonProfileData),
    );
};

export const usePersonProjectsData = (id = null): PersonProjectsData => {
    const store = useStore<PersonProfileStore>(PersonProfileStore);
    const [loading, setLoading] = useState(false);
    const doLoadInitData = () => {
        if (!id && !store.profile.id) {
            return;
        }
        setLoading(true);
        store
            .loadProjects(id || store.profile.id)
            .catch(console.error)
            .finally(() => setLoading(false));
    };

    useMemo(doLoadInitData, [id, store.profile.id]);

    return useObserver(
        () =>
            ({
                projects: store.projects,
                loadProjects: store.loadProjects,
                loading: loading,
                matchPercent: store.matchPercent,
            } as PersonProjectsData),
    );
};
