import { UserTokenInterface } from '../../common';

export interface CognitoUserSession {
    idToken: {
        jwtToken: string;
        payload: {
            sub: string;
            email_verified: boolean;
            given_name: string;
            family_name: string;
            email: string;
            auth_time: number;
            exp: number;
        };
    };
    refreshToken: {
        token: string;
    };
    accessToken: {
        jwtToken: string;
        payload: any;
    };
}

export interface AmplifyUser {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
}

export class AmplifySessionToken implements UserTokenInterface {
    idToken: string;
    refreshToken: string;
    accessToken: string;
    isVerified: boolean;
    user: AmplifyUser;
    constructor(session: CognitoUserSession) {
        this.idToken = session.idToken.jwtToken;
        this.accessToken = session.accessToken.jwtToken;
        this.refreshToken = session.refreshToken.token;
        this.isVerified = session.idToken.payload.email_verified;
        this.user = {
            id: session.idToken.payload.sub,
            email: session.idToken.payload.email,
            firstName: session.idToken.payload.given_name,
            lastName: session.idToken.payload.family_name,
        };
    }

    isAuthenticated() {
        return !!this.user;
    }

    hasRole(name = '*') {
        return true;
    }

    getUsername() {
        try {
            return `${this.user.firstName} ${this.user.lastName}`;
        } catch (e) {
            return 'Unknown';
        }
    }

    getUser(): AmplifyUser {
        return this.user;
    }
}
