import React from 'react';

import { AsyncLoader, ReactHelpers, useAsync } from '@weco/ui';
import { useMyUserData } from '../hooks/useMyUserData';
import { SplashScreenLoader } from '../../components/molecules/SplashScreenLoader';

export const CurrentUserProfileContainer = ({ children }) => {
    const store = useMyUserData();
    const result = useAsync(() => {
        if (!store.profile) {
            return Promise.all([store.load()]).then(() => true);
        }
        return Promise.resolve(true);
    });
    return (
        <AsyncLoader {...result} loader={SplashScreenLoader}>
            {!result.loading && ReactHelpers.renderChildren(children, store)}
        </AsyncLoader>
    );
};
