import { ROOM_TYPE_PROJECTS } from '@weco/chat';

export const defaultListState = {
    loading: true,
    dataLoading: false,
    items: {},
    activeRoom: null,
    filterType: ROOM_TYPE_PROJECTS,
};

const defaultState = {
    list: defaultListState,
};

export default defaultState;
