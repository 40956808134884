import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Theme, Typography } from '@material-ui/core';
import ProfileAvatar from '../../../components/molecules/ProfileAvatar';
import { DeleteButton } from '@weco/ui';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 5,
        display: 'flex',
    },
    avatarBox: {
        width: 80,
        height: 80,
        margin: 15,
        borderRadius: '5px',
        overflow: 'hidden',
    },
    contentBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(100% - 104px)',
        fontSize: '14px',
    },
    contentText: {
        fontSize: '14px',
        color: '#bdbdbd',
    },
    deleteButton: {
        marginLeft: 'auto',
        textTransform: 'lowercase',
        margin: '0 15px',
        color: '#bdbdbd',
    },
    contentBoldText: {
        fontWeight: 500,
        display: 'inline',
    },
}));

const PersonNotificationItem = ({ profile, project, text }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            style={{ padding: '10px 0', borderBottom: '1px solid #F3F3F3' }}
        >
            <Grid item xs={12}>
                <Box className={classes.root}>
                    <Box className={classes.avatarBox}>
                        <ProfileAvatar picture={profile.picture} />
                    </Box>
                    <Box className={classes.contentBox}>
                        <Typography className={classes.contentText}>
                            <Typography
                                color={'secondary'}
                                className={classes.contentBoldText}
                            >
                                {`'${profile.name}' `}
                            </Typography>
                            {text}
                            <Typography
                                color={'secondary'}
                                className={classes.contentBoldText}
                            >
                                {` '${project.name}' `}
                            </Typography>
                            .
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
                <DeleteButton
                    color={'secondary'}
                    className={classes.deleteButton}
                    onClick={() => console.log('delete click')}
                />
            </Grid>
        </Grid>
    );
};

export default PersonNotificationItem;
