import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { useMemo, useState } from 'react';
import {
    ProjectProfileStore,
    ProjectProfileStoreInterface,
} from './ProjectProfileStore';
import { useMyUserProfileData } from '../../../../store/hooks/useMyUserProfileData';

export type ProjectProfileData = Omit<ProjectProfileStoreInterface, ''> & {
    loading: boolean;
};

export const useProjectProfileData = (id = null): ProjectProfileData => {
    const store = useStore<ProjectProfileStoreInterface>(ProjectProfileStore);
    const { profile } = useMyUserProfileData();
    const [loading, setLoading] = useState(false);

    const doLoadInitData = async () => {
        if (!id && !store.project.id) {
            return;
        }
        setLoading(true);
        try {
            await store.loadPersonProjectConnection(profile);
            await store.loadTeam(id || store.project.id);
            await store.loadMatchPercent();
            setLoading(false);
        } catch (e) {
            console.log('error', e);
            setLoading(false);
        }
    };

    useMemo(doLoadInitData, [id, store.project && store.project.id]);
    return useObserver(
        () =>
            ({
                ...store,
                loading,
                personProjectConnection: store.personProjectConnection,
                setPersonProjectConnection: store.setPersonProjectConnection,
                applyToProject: store.applyToProject,
                declineInvitation: store.declineInvitation,
                acceptInvitation: store.acceptInvitation,
                leaveProject: store.leaveProject,
                recallApplication: store.recallApplication,
                isEnvitee: store.isEnvitee,
                getProjectPicture: store.getProjectPicture,
                isMember: store.isMember,
            } as ProjectProfileData),
    );
};
