import { FormControl } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import debug from 'debug';
import { Field } from 'formik';
import { identity } from 'lodash';
import throttle from 'lodash/throttle';
import React, { useState } from 'react';
import { LocationEntity } from '../../../../core/src/lib/entity/LocationEntity';
import { WeAutocomplete } from './WeAutocomplete';

/**
 * NOTE: most of the code in this file is a copypaste from
 * https://material-ui.com/components/autocomplete/#google-maps-place
 */

const log = debug('LocationAutoComplete');

function loadScript(src: string, position: HTMLElement | null, id: string) {
    log('Injecting google maps sdk into <head>.');
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const geocoderService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    hidden: {
        display: 'none',
    },
}));

export interface PlaceTypeLocation {
    lat: number;
    lon: number;
}

export interface PlaceType {
    description: string;
    place_id: string;
    location?: PlaceTypeLocation;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}

function convertGooglePlaceToLocationEntity(
    place: PlaceType,
): LocationEntity | undefined {
    if (!place) return undefined;
    else
        return {
            name: place.description,
            googlePlaceId: place.place_id,
            location: place.location,
        };
}

interface Props {
    autofocus?: boolean;
    // TODO remove do we need this prop?
    currentSelection?: LocationEntity;
}

const remoteLocation = {
    description: 'Remote',
    name: 'Remote',
    location: { lat: 0, lon: 0 },
    googlePlaceId: '',
    place_id: '',
    structured_formatting: null,
};

function LocationAutoComplete(props: Props) {
    const classes = useStyles();
    const loaded = React.useRef(false);
    const [inputValue, setInputValue] = useState('');
    const [isFirstRequestSent, setIsFirstRequestSent] = useState(false);
    const [options, setOptions] = useState<LocationEntity[]>([
        convertGooglePlaceToLocationEntity(remoteLocation),
    ]);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyBaNrvw08ySwXb8hfE2ZB7SzpwxmM9f9rY&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle(
                (
                    request: { input: string; types?: string[] },
                    callback: (results?: PlaceType[]) => void,
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback,
                    );
                },
                0, // QA requested to remove throttle.
            ),
        [],
    );

    const fetchGeocode = React.useMemo(
        () => (
            request: { placeId: string },
            callback: (results?: any) => void,
        ) => {
            (geocoderService.current as any).geocode(request, callback);
        },
        [],
    );

    const fetchPlaceTypeLocations = (
        results?: PlaceType[],
    ): Promise<PlaceTypeLocation>[] => {
        return (results || []).map(
            (resultItem: PlaceType) =>
                new Promise((resolve) => {
                    fetchGeocode({ placeId: resultItem.place_id }, (res) => {
                        let location = null;
                        if (res && res.length > 0 && res[0].geometry) {
                            location = {
                                lat: res[0].geometry.location.lat(),
                                lon: res[0].geometry.location.lng(),
                            };
                        }
                        resolve(location);
                    });
                }),
        );
    };

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!geocoderService.current && (window as any).google) {
            geocoderService.current = new (window as any).google.maps.Geocoder();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions([convertGooglePlaceToLocationEntity(remoteLocation)]);
            return undefined;
        }

        if (!isFirstRequestSent) {
            setIsFirstRequestSent(true);
        }

        fetch({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                Promise.all(fetchPlaceTypeLocations(results)).then(
                    (locations: any[]) => {
                        let newOptions: PlaceType[] = [remoteLocation];

                        locations.forEach((location: any, i: number) => {
                            results[i].location = location;
                        });

                        if (results) {
                            newOptions = [...newOptions, ...results];
                        }

                        // TODO refactor
                        const payload = newOptions.map(
                            convertGooglePlaceToLocationEntity,
                        );

                        setOptions(payload);
                    },
                );
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch, isFirstRequestSent]);

    /**
     * NOTE: there was a bug when on first
     * input click "nothing found" were visible.
     * Also when input was empty.
     * Also when default value is provided
     * and request were not made yet.
     */
    function shouldNoOptionsLabelBeHidden() {
        return !inputValue.trim() || !isFirstRequestSent;
    }

    return (
        <FormControl fullWidth>
            <Field
                name="location"
                autoFocus={props.autofocus}
                component={WeAutocomplete}
                noOptionsText="Nothing found"
                classes={{
                    noOptions: shouldNoOptionsLabelBeHidden() && classes.hidden,
                }}
                getOptionLabel={(option) => option?.name || ''}
                filterOptions={identity}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                onInputChange={(event, newInputValue) => {
                    log(newInputValue, 'newUWIU');
                    setInputValue(newInputValue);
                }}
                renderInput={(params: AutocompleteRenderInputParams) => {
                    return (
                        <TextField
                            {...params}
                            autoFocus={props.autofocus}
                            label="Add a location"
                            variant="standard"
                            fullWidth
                        />
                    );
                }}
                renderOption={(option) => {
                    return (
                        <Grid container alignItems="center" key={option.name}>
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                <span style={{ fontWeight: 400 }}>
                                    {option.name}
                                </span>
                            </Grid>
                        </Grid>
                    );
                }}
            />
        </FormControl>
    );
}

export { LocationAutoComplete };
