import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useCoolStyles = makeStyles((theme: Theme) => ({
    cools: {
        position: 'absolute',
        right: '10px',
        top: '28px',
        color: '#fff',
        display: 'flex',
        cursor: 'pointer',
    },
    coolsSpan: {
        background: '#857eef',
        fontSize: 9,
        borderRadius: 5,
        padding: '1px 4px',
        height: '15px',
        marginTop: '4px',
        marginLeft: '6px',
    },
    coolSpanTagged: {
        background: 'linear-gradient(0.25turn, #FF679B, #FF8960)',
    },
    coolButton: {
        background: '#857eef',
        border: 'none',
        height: '100%',
    },
    coolSign: {
        marginLeft: '-23px',
        transform: 'scale(0.8)',
    },
    coolWord: {
        marginLeft: '20px',
        transform: 'scale(2.6)',
    },
    staticCools: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px 0',
    },
    staticCoolsCount: {
        marginLeft: '-10px',
        zIndex: 1,
        marginTop: '-5px',
        background: '#FF679B',
        fontSize: 9,
        borderRadius: 5,
        padding: '1px 4px',
        height: '15px',
    },
    coolUsersElement: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '24px',
        fontWeight: 500,
        cursor: 'pointer',
        marginBottom: '15px',
    },
    fitImgMargin: {
        marginTop: '23px',
    },
    userInfo: {
        display: 'flex',
    },
    userName: {
        marginLeft: '20px',
    },
}));
