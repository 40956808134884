import _ from 'lodash';

export function randomString(min, max): string {
    let iterator;
    const length = Math.floor(Math.random() * (max - min + 1)) + min;
    const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';

    for (iterator = 0; iterator < length; iterator += 1) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

export function getParameters(
    url = window.location.href,
): { [name: string]: string } {
    const result = {};
    url.replace(/(^\?)/, '')
        .split('&')
        .forEach((n) => {
            const keyValue = n.split('=');
            result[keyValue[0].replace(/[^\w\s]/gi, '')] = decodeURIComponent(
                keyValue[1],
            );
        });
    return result;
}

export function objectToQueryString(params: any, prefix?): string {
    const query = Object.keys(params).map((k) => {
        let key = k;
        let value = params[key];

        if (!value && (value === null || value === undefined || isNaN(value))) {
            value = '';
        }

        switch (params.constructor) {
            case Array:
                key = `${prefix}[]`;
                break;
            case Object:
                key = prefix ? `${prefix}[${key}]` : key;
                break;
        }

        if (typeof value === 'object') {
            return objectToQueryString(value, key); // for nested objects
        }

        return `${key}=${encodeURIComponent(value)}`;
    });

    return query.join('&');
}

export function fulltextFilter<T = any>(items: T[], searchTerm?: string): T[] {
    return items.filter((item) => {
        if (!searchTerm) {
            return true;
        }
        return _.values(item).reduce((prev, curr) => {
            return (
                prev ||
                (!!curr &&
                    curr
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()))
            );
        }, false);
    });
}

export function difference(base, obj2) {
    function changes(object, baseObj) {
        return _.transform(object, (result, value, key) => {
            if (!_.isEqual(value, baseObj[key])) {
                result[key] =
                    _.isObject(value) && _.isObject(baseObj[key])
                        ? changes(value, baseObj[key])
                        : value;
            }
        });
    }

    return changes(obj2, base);
}
