import Axios, { AxiosInstance } from 'axios';
import {
    HttpException,
    InvalidCredentialsException,
    NotFoundException,
    ValidationException,
} from '../exceptions/exceptions';

function wrapExceptions(e: HttpException) {
    switch (Number(e.status)) {
        case 403:
        case 401: {
            return new InvalidCredentialsException(e.message, e);
        }
        case 422: {
            return ValidationException.fromHttpException(e);
        }
        case 404: {
            return new NotFoundException(e.message, e);
        }
        default: {
            return e;
        }
    }
}

export interface BaseHttpOptions {
    baseURL?: string;
    url?: string;
    method?: string;
    data?: any;
    headers?: any;
    params?: any;
    responseType?: string;
    transformRequest?: (data: any, headers?: any) => any;
    transformResponse?: (data: any, headers?: any) => any;
}

export class BaseHttp {
    private client: AxiosInstance;

    constructor(protected globalOptions: BaseHttpOptions = { baseURL: '/' }) {
        this.client = Axios.create(globalOptions as any);
    }

    /**
     *
     * @param config
     * @return {Promise}
     */
    request(config: BaseHttpOptions = {}) {
        // const cfg = Object.assign({}, config, {
        //     headers: Object.assign(config.headers || {}, this.globalOptions.headers || {}),
        //     params: Object.assign(config.params || {}, this.globalOptions.params || {}),
        //     transformRequest: [].concat(config.transformRequest || [], this.globalOptions.transformRequest || []),
        //     transformResponse: [].concat(config.transformResponse || [], this.globalOptions.transformResponse || [])
        // });

        return this.client
            .request(config as any)
            .then((response) => response.data)
            .catch((e) => {
                let status = e.response.status;
                let message = e.response.statusText || e.message;
                if (e.response.data) {
                    message = e.response.data.message
                        ? e.response.data.message.message ||
                          e.response.data.message.error
                        : message;
                    status = e.response.data.status || status;
                }
                return Promise.reject(
                    wrapExceptions(new HttpException(status, message, e)),
                );
            });
    }

    /**
     * @param url
     * @param config
     * @return {Promise}
     */
    get(url, config: BaseHttpOptions = {}) {
        return this.request({ method: 'GET', url, ...config });
    }

    /**
     * @param url
     * @param config
     * @return {Promise}
     */
    delete(url, config: BaseHttpOptions = {}) {
        return this.request({ method: 'DELETE', url, ...config });
    }

    /**
     * @param url
     * @param data
     * @param config
     * @return {Promise}
     */
    post(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'POST', url, ...config, data });
    }

    /**
     * @param url
     * @param data
     * @param config
     * @return {Promise}
     */
    put(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'PUT', url, ...config, data });
    }

    /**
     * @param url
     * @param data
     * @param config
     * @return {Promise}
     */
    patch(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'PATCH', url, ...config, data });
    }
}
