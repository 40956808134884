import { EventInterface } from '@weco/common';
import { ProjectEntity, UserProfileEntity } from '@weco/core';

export class ProfileUpdatedEvent implements EventInterface {
    subject: string[];
    constructor(
        public updatedFields: { [name: string]: any },
        public profile: ProjectEntity | UserProfileEntity,
    ) {
        this.subject = Object.keys(updatedFields);
    }
}
