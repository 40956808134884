import React from 'react';
import { FormControl, TextField, Theme } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { Field } from 'formik';
import { WeAutocomplete, SectionWithTitle, SpriteSvgIcon } from '@weco/ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export interface AutocompleteFormControlProps {
    name?: string;
    label?: string;
    options: any[];
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: '150px',
        '& .MuiInputAdornment-positionStart': {
            // marginRight: '-29px',
            transform: 'scale(0.7)',
            position: 'absolute',
            marginTop: '20px',
            marginLeft: '4px',
        },
    },
    dropdownIcons: {
        marginRight: '5px',
        transform: 'scale(0.7)',
    },
    editableChipTags: {
        width: 'fit-content',
    },
}));

export const AutocompleteFormControl = ({
    options,
    name,
    label,
}: AutocompleteFormControlProps) => {
    const classes = useStyles();
    return (
        <FormControl fullWidth={true}>
            <SectionWithTitle title={label}>
                <Field
                    className={classes.root}
                    name={name}
                    component={WeAutocomplete}
                    options={options}
                    multiple
                    disableCloseOnSelect={true}
                    renderOption={(option) => {
                        return (
                            <>
                                {(name === 'compensations' ||
                                    name === 'investments') && (
                                    <SpriteSvgIcon
                                        id={`${option}`}
                                        className={classes.dropdownIcons}
                                    />
                                )}
                                {option}
                            </>
                        );
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                        if (
                            params.InputProps &&
                            params.InputProps.startAdornment
                        ) {
                            // eslint-disable-next-line no-param-reassign
                            params.InputProps.startAdornment = [
                                // @ts-ignore
                                ...params.InputProps.startAdornment,
                            ].map((item) => {
                                return (
                                    <Box className={classes.editableChipTags}>
                                        <InputAdornment position="start">
                                            <SpriteSvgIcon
                                                id={`${
                                                    (item as any)?.props?.label
                                                }`}
                                            />
                                        </InputAdornment>
                                        {item}
                                    </Box>
                                );
                            });
                        }
                        return (
                            <TextField {...(params as any)} disabled={false} />
                        );
                    }}
                />
            </SectionWithTitle>
        </FormControl>
    );
};
