import React, { useEffect, memo, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import classNames from 'classnames';
import { useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { useMainLayoutStyle } from './styles/MainLayout.styles';
import { AppFooter } from './particules/AppFooter';
import { LeftSidebar } from './particules/LeftSidebar';
import { TopBar } from './particules/TopBar';
import { CurrentUserProfileContainer } from '../../store/containers/CurrentUserProfileContainer';
import debug from 'debug';
import { RouterPaths } from '../../router/RouterPaths';
import get from 'lodash/get';
import useToggle from 'react-use/lib/useToggle';

const log = debug('MainLayout');

export interface MainLayoutProps {
    children: JSX.Element;
}

const MainLayout = memo((props: MainLayoutProps) => {
    const theme = useTheme();
    const classes = useMainLayoutStyle();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [isSidebarOpen, toggleSidebar] = useToggle(!!isDesktop);
    const isChat = get(window, 'location.pathname', '').includes(
        RouterPaths.chat.root,
    );

    useEffect(() => {
        toggleSidebar(!!isDesktop);
    }, [isDesktop, toggleSidebar]);

    log('isOpen', isSidebarOpen);
    log('isDekstopMode', isDesktop);
    log('isChat', isChat);

    return (
        <CurrentUserProfileContainer>
            {({ activeProjectId, addNewProject }) => {
                return (
                    <>
                        <div className={classes.root}>
                            <CssBaseline />
                            <TopBar
                                desktopMode={isDesktop}
                                toggle={toggleSidebar}
                            />
                            <LeftSidebar
                                desktopMode={isDesktop}
                                open={isSidebarOpen}
                                toggle={toggleSidebar}
                            />
                            <main
                                className={classNames({
                                    [classes.contentChat]: isChat,
                                    [classes.contentMobile]: !isDesktop,
                                    [classes.contentDesktop]: !!isDesktop,
                                })}
                            >
                                {props.children}
                            </main>
                        </div>
                        <AppFooter desktopMode={isDesktop} />
                    </>
                );
            }}
        </CurrentUserProfileContainer>
    );
});

export default MainLayout;
