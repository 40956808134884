import { Transformers } from '@weco/common';

const toClass = (highlight) => {
    return !!highlight
        ? Object.keys(highlight).reduce((acc, key) => {
              if (!!highlight[key]) {
                  acc.push(key);
              }
              return acc;
          }, [])
        : [];
};

const toPlain = (value) => {
    throw new Error('Not support');
};

export class HighlightPropertyTransformer extends Transformers.ClassPropertyTransformer {
    constructor() {
        super(toClass, toPlain);
    }
}
