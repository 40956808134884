import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useLoginPageStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 300,
    },
    signInText: {
        color: theme.palette.common.white,
        margin: 40,
        fontWeight: 400,
    },
    alertContainer: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
}));
