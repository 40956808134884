import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) => {
    return {
        infoBanner: {
            width: '100%',
            color: theme.palette.info[700],
            fontWeight: 500,
            textAlign: 'center',
            padding: '5px 15px',
        },
        infoBannerText: {
            fontWeight: 600,
        },
        viewLink: {
            textDecoration: 'underline',
            color: theme.palette.info[500],
            cursor: 'pointer',
        },
    };
});

export interface RequiredInfoBannerProps {
    text: string;
    link: string;
    linkText: string;
}

const RequiredInfoBanner = memo(
    ({ text, link, linkText }: RequiredInfoBannerProps) => {
        const classes = useStyles();
        const history = useHistory();

        return (
            <Box className={classes.infoBanner}>
                {text}
                <Box
                    className={classes.viewLink}
                    onClick={() =>
                        history.push(link, {
                            pathname: link,
                            fromBanner: true,
                        })
                    }
                >
                    {linkText}
                </Box>
            </Box>
        );
    },
);

RequiredInfoBanner.displayName = 'ActionButton';

export { RequiredInfoBanner };
