export class SkillEntity {
    id?: string;
    value?: string;
    departmentName?: string;
    refCount?: number;
    constructor(value?: string, departmentName?: string) {
        this.value = value;
        this.departmentName = departmentName;
        this.id = `${this.departmentName}:${this.value}`;
    }
}
