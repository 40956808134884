import React from 'react';
import { ChildrenType } from '../helpers';
import { memo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
    },
    videoWrapper: {
        display: 'block',
        overflow: 'hidden',
        paddingBottom: '57%',
        position: 'relative',
        width: '100%',
        height: 0,
        margin: '20px 20px',
    },
    fullWidth: {
        margin: 0,
    },
    iframe: {
        // TODO: remove this comment?
        // NOTE: this was copypasted from previous
        // project. Seems like this is the only way to
        // make ifrmae go full width.
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100% !important',
        height: '100% !important',
    },
});

type VimeoProps = {
    videoId: number;
    className?: string;
    fullWidth?: boolean;
};

const Vimeo = memo((props: VimeoProps) => {
    const cx = useStyles();
    return (
        <Box
            className={classNames({
                [cx.videoWrapper]: true,
                [cx.fullWidth]: props.fullWidth,
            })}
        >
            <iframe
                allowFullScreen
                title="vimeo"
                frameBorder="0"
                allow="autoplay; fullscreen"
                className={classNames(cx.iframe, props.className)}
                src={`https://player.vimeo.com/video/${props.videoId}?title=0&byline=0&portrait=0&controls=1`}
            />
        </Box>
    );
});

export { Vimeo };
