import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useLeftSidebarStyles = makeStyles((theme: Theme) =>
    createStyles({
        leftDrawer: {
            flexShrink: 0,
            [theme.breakpoints.up('md')]: {
                minWidth: '400px',
                maxWidth: '400px',
                background: theme.palette.primary[50],
            },
        },
        main: {
            flexShrink: 0,
            [theme.breakpoints.up('md')]: {
                minWidth: '400px',
                maxWidth: '400px',
            },
        },
        joinButton: {
            background:
                'linear-gradient(86.51deg, #544F94 13.51%, #755DD8 72.7%)',
            borderRadius: '10px',
            color: theme.palette.background.default,
            fontSize: '16px',
            fontWeight: 500,
            width: '80%',
            margin: '20px',
            padding: '10px',
        },
        infoText: {
            padding: 60,
            textAlign: 'center',
            '& h5': {
                margin: 10,
            },
        },
        joinImage: {
            width: '45%',
            margin: 45,
        },
        leftDrawerPaper: {
            minHeight: '100vh',
            width: '100%',
            border: 0,
            zIndex: 15,
            background: theme.palette.primary.main,
            [theme.breakpoints.up('md')]: {
                marginTop: '66px',
                background: 'transparent',
                zIndex: 5,
            },
        },
    }),
);
