import React from 'react';
import debug from 'debug';
import { observer } from 'mobx-react';
import { useStore } from '@weco/common';
import {
    SkeletonAsyncLoader,
    ReactHelpers,
    useAsync,
    ProjectSkeletonPlaceholder,
} from '@weco/ui';
import { ProjectsMainPageStore } from './store/ProjectsMainPageStore';
import { MainPageFilter } from '../../../helpers/CommonTypes';

const log = debug('ProjectListContainer');

export interface ProjectsListContainerProps {
    children: any;
    refresh?: boolean;
    filters: MainPageFilter[];
    numberOfItemsDuringLoading?: number;
}

export const ProjectListContainer = observer(
    ({
        children,
        refresh,
        filters,
        numberOfItemsDuringLoading: skeletonItemsCount = 2,
    }: ProjectsListContainerProps) => {
        const store = useStore<ProjectsMainPageStore>(ProjectsMainPageStore);
        store.filters = filters;
        const requestResults = useAsync(() => {
            if (!refresh || !store.items) {
                return store.getList();
            }
        });

        log('requestResults: ', requestResults);
        log('skeletonItemsCount: ', skeletonItemsCount);

        return (
            <SkeletonAsyncLoader
                {...requestResults}
                count={skeletonItemsCount}
                skeleton={<ProjectSkeletonPlaceholder />}
            >
                {!requestResults.loading &&
                    ReactHelpers.renderChildren(children)}
            </SkeletonAsyncLoader>
        );
    },
);
