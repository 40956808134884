import React from 'react';
import {
    Typography,
    Grid,
    CardMedia,
    SvgIcon,
    useTheme,
    useMediaQuery,
    CssBaseline,
    IconButton,
} from '@material-ui/core';
import { aboutPageStyles } from './styles/AboutPage.styles';
import { KnowMoreForm } from './components/KnowMoreForm';
import logoSchema from '../../../assets/images/about/logoSchema.png';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/social/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/social/linkedin.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/social/instagram.svg';
import WecoLogo from '../../../assets/images/nav-ico.svg';
import { BenefitComponent } from './components/BenefitComponent';
import { ScrollOnTop } from '@weco/ui';

// import { WhatWouldYouChooseSection } from './components/WhatWouldYouChooseSection';

const OurPhilosophySection = () => {
    const classes = aboutPageStyles();
    const theme = useTheme();
    const desktopMode = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Grid
            container
            alignItems="center"
            direction="column"
            className={classes.chooseContainer}
        >
            {!desktopMode && (
                <>
                    <BenefitComponent type="founders" />
                    <BenefitComponent type="everyone" />
                </>
            )}

            <Typography variant="h1" className={classes.sectionHeader}>
                Our Philosophy
            </Typography>

            <Grid
                container
                justify="center"
                className={classes.imagesContainer}
            >
                <Grid
                    item
                    md={7}
                    xs={12}
                    container
                    justify="center"
                    alignItems="center"
                >
                    <CardMedia
                        src={logoSchema}
                        className={classes.philosophyImage}
                        component="img"
                    />
                </Grid>
                <Grid item md={5} xs={12} container alignItems="center">
                    <Typography
                        variant="h5"
                        align={desktopMode ? 'left' : 'center'}
                        style={{ width: '100%' }}
                    >
                        <p style={{ margin: 0 }}> Life is short.</p>
                        <br />
                        you want to do what you love,
                        <br />
                        with people who inspire you,
                        <br />
                        creating value with purpose,
                        <br />
                        which allows you to pay bills,
                        <br />
                        to maintain work/life balance.
                        <br />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const KnowMore = () => {
    const classes = aboutPageStyles();
    return (
        <Grid item container justify="center" direction="column">
            <Grid item>
                <Typography
                    variant="h1"
                    className={classes.sectionHeader}
                    align="center"
                >
                    Want to know more?
                </Typography>
                <Typography variant="h4" align="center">
                    We would love to answer any questions.
                </Typography>
            </Grid>
            <Grid item style={{ padding: '70px 50px' }}>
                <KnowMoreForm />
            </Grid>
        </Grid>
    );
};

const FollowUs = () => {
    const classes = aboutPageStyles();
    return (
        <Grid item container justify="center" direction="column">
            <Grid item>
                <Typography
                    variant="h1"
                    className={classes.sectionHeader}
                    align="center"
                >
                    Follow Us!
                </Typography>
            </Grid>
            <Grid item style={{ padding: 20 }}>
                <Grid item container justify="center">
                    <CardMedia
                        src={WecoLogo}
                        component="img"
                        className={classes.logoImage}
                    />
                </Grid>

                <Typography
                    variant="body1"
                    className={classes.and}
                    align="center"
                >
                    and
                </Typography>

                <Grid item container justify="center">
                    <IconButton
                        href={'https://www.linkedin.com/company/wecoonline/'}
                        target={'_blank'}
                    >
                        <SvgIcon className={classes.svgIcon}>
                            <LinkedinIcon />
                        </SvgIcon>
                    </IconButton>
                    <IconButton
                        href={'https://www.facebook.com/weco.online'}
                        target={'_blank'}
                    >
                        <SvgIcon className={classes.svgIcon}>
                            <FacebookIcon />
                        </SvgIcon>
                    </IconButton>
                    <IconButton
                        href={'https://www.instagram.com/weco.online'}
                        target={'_blank'}
                    >
                        <SvgIcon className={classes.svgIcon}>
                            <InstagramIcon />
                        </SvgIcon>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const OurPhilosophyPage = () => {
    return (
        <>
            <ScrollOnTop />
            <Grid container direction="column">
                <Grid item>
                    <OurPhilosophySection />
                </Grid>
                <Grid item>
                    <KnowMore />
                </Grid>
                <Grid item>
                    <FollowUs />
                </Grid>
            </Grid>
        </>
    );
};

OurPhilosophyPage.URL = '/philosophy';
