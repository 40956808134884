import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions(): { width: number; height: number } {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(),
    );

    useEffect(() => {
        const handleResize = debounce(
            () => {
                setWindowDimensions(getWindowDimensions());
            },
            300,
            { maxWait: 1000 },
        );

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
