import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
    AllDepartments,
    departmentColors,
    getDepartmentDisplayName,
} from '@weco/core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { PositionsList, PositionsListProps } from './PositionsList';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    title: {
        // TODO rework this.
        color: '#464646', // not theme color
    },
}));

const PositionsListSeparatedByDepartments = memo(
    (props: PositionsListProps) => {
        const classes = useStyles();
        const rootClasses = classNames(classes.root, props.className);

        return (
            <Box className={rootClasses}>
                {AllDepartments.map((departmentName) => {
                    const positionsOfThisDepartment = props.items
                        ?.filter((i) => i.department?.name === departmentName)
                        ?.sort((a, b) => a.order - b.order);
                    const bottomLineCSS = {
                        height: '3px',
                        marginTop: '20px',
                        backgroundColor: departmentColors[departmentName],
                    };

                    if (isEmpty(positionsOfThisDepartment)) return null;
                    return (
                        <Box key={departmentName}>
                            <Typography variant="h2" className={classes.title}>
                                {getDepartmentDisplayName(departmentName)}
                            </Typography>
                            <Box style={bottomLineCSS} />
                            <PositionsList
                                {...props}
                                items={positionsOfThisDepartment}
                            />
                        </Box>
                    );
                })}
            </Box>
        );
    },
);

PositionsListSeparatedByDepartments.displayName =
    'PositionsListSeparatedByDepartments';

export { PositionsListSeparatedByDepartments };
