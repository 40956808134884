import { addUpdatedAt, Injectable, Transformers } from '@weco/common';
import { GraphQlBaseRepository } from './GraphQlBaseRepository';
import { gql } from 'apollo-boost';
import { PersonProjectConnection, UserProfileEntity } from '../entity';
import { matchPeople } from './graphql/queries';
import {
    updatePerson,
    getPerson,
    personProjectConnectionsLightweight,
    projectPersonsConnectionLightweight,
    deletePerson,
} from './gqlRequests';
import { getAllowedInvestments } from '../utils';
import { ConnectionParamsInterface } from '../../../../../apps/client/src/app/store/hooks/useMyHappyConnectionData';
import get from 'lodash/fp/get';

export interface IProfileRepository {
    updateProfile(item: Partial<UserProfileEntity>): Promise<UserProfileEntity>;

    findById(
        id: string,
        options?: { detailed: boolean },
    ): Promise<UserProfileEntity>;

    searchByName(
        q: string,
        exclude: string[],
        limit: number,
    ): Promise<UserProfileEntity[]>;

    getPersonConnections(
        personId: string,
        projectId?: string,
    ): Promise<PersonProjectConnection[]>;

    deleteAccount(id: string): Promise<any>;

    findPersonToProjectConnection({
        projectId,
        personId,
    }: ConnectionParamsInterface): Promise<PersonProjectConnection>;
}

export class TeamMember extends UserProfileEntity {
    roles: string[];
}
// todo: WTF??? Interface from class???
export interface ProfileItemInterface extends TeamMember {
    matchPercent: number;
}

@Injectable()
export class PersonRepository extends GraphQlBaseRepository
    implements IProfileRepository {
    findById(
        id: string,
        options = { detailed: false },
    ): Promise<UserProfileEntity> {
        return this.unAuthorizedClient
            .query({
                query: gql(getPerson),
                variables: { id, detailed: !!options?.detailed },
            })
            .then((data) => data.data.getPerson)
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer(UserProfileEntity),
                ),
            )
            .then((item: UserProfileEntity) => {
                // Workaround: clear bad data. Logic changed now that fields can contains only predefined values.
                item.investments = getAllowedInvestments(item.investments);
                item.compensations = getAllowedInvestments(item.compensations);
                return item;
            })
            .catch((err) => {
                console.log('err', err);
                return null;
            });
    }

    updateProfile(item: UserProfileEntity): Promise<UserProfileEntity> {
        const input = addUpdatedAt({
            ...new Transformers.ToPlainTransformer().transform(item, {
                excludePrefixes: ['__'],
                exclude: ['owner', 'activeProject', 'school'],
            }),
        });
        const mutation = gql(updatePerson);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: { input },
            })
            .then(get('data.updatePerson.id'))
            .then((id) => this.findById(id as any));
    }

    public searchByName(
        q: string,
        exclude: string[],
        limit = 5,
    ): Promise<UserProfileEntity[]> {
        const req: any = {
            bool: {
                should: [
                    {
                        multi_match: {
                            query: q,
                            fields: ['name', 'lastName'],
                            type: 'phrase_prefix',
                        },
                    },
                    {
                        multi_match: {
                            query: `email:${q}`,
                            type: 'phrase_prefix',
                            fields: ['contacts'],
                        },
                    },
                ],
            },
        };
        if (exclude.length) {
            req.bool.must_not = [
                {
                    ids: {
                        values: exclude,
                    },
                },
            ];
        }
        const variables = { query: JSON.stringify(req), limit };

        return this.unAuthorizedClient
            .query({ variables, query: gql(matchPeople) })
            .then(({ data }) => data.matchPeople.items)
            .catch(console.error);
    }

    public async getPersonConnections(
        personId,
        projectId?,
    ): Promise<PersonProjectConnection[]> {
        // todo we should have one query which will respond with all connections
        const queriesToRun = [
            this.unAuthorizedClient.query({
                query: gql(personProjectConnectionsLightweight),
                variables: { personId },
                fetchPolicy: 'network-only',
            }),
        ];
        if (projectId) {
            queriesToRun.push(
                this.unAuthorizedClient.query({
                    query: gql(projectPersonsConnectionLightweight),
                    variables: { projectId },
                    fetchPolicy: 'network-only',
                }),
            );
        }
        return Promise.all(queriesToRun)
            .then((result) => {
                const personItems =
                    result[0]?.data?.listPersonProjectConnections?.items || [];
                const projectItems =
                    result[1]?.data?.ProjectPersonsConnection?.items || [];
                return [].concat(
                    personItems,
                    projectItems.filter(
                        (prjCon) =>
                            !personItems.find(
                                (perCon) => perCon.personId === prjCon.personId,
                            ),
                    ),
                );
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<
                            PersonProjectConnection
                        >(PersonProjectConnection),
                    ),
                ),
            )
            .catch(console.error);
    }

    public async deleteAccount(id: string): Promise<any> {
        const mutation = gql(deletePerson);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    input: {
                        id,
                    },
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching([
                        'listPersonProjectConnections',
                        id,
                        this.currentUserProvider.UserId,
                    ]),
            })
            .then((result) => {
                console.log('deletion successful', result);
            })
            .catch((err) => {
                console.log('deletion error', err);
            });
    }

    public async findPersonToProjectConnection({
        projectId,
        personId,
    }: ConnectionParamsInterface): Promise<PersonProjectConnection> {
        return this.unAuthorizedClient
            .query({
                query: gql(projectPersonsConnectionLightweight),
                variables: {
                    personId: { eq: personId },
                    projectId: projectId,
                },
                fetchPolicy: 'network-only',
            })
            .then((results) => {
                return results.data.ProjectPersonsConnection.items[0] || null;
            });
    }
}
