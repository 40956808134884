import { Injectable, Inject } from '@weco/common';
import { observable, action, runInAction } from 'mobx';
import {
    ProjectEntity,
    ProjectsService,
    CurrentUserProvider,
    CORE_SERVICES,
    PeopleService,
    SearchFilter,
    PersonService,
} from '@weco/core';
import { MyUserStore } from '../../../../../store/MyUserStore';

@Injectable()
export class ProfileProjectsTabStore {
    @Inject(ProjectsService)
    service: ProjectsService;

    @Inject(PeopleService)
    peopleService: PeopleService;

    @Inject(PersonService)
    personService: PersonService;

    @Inject(MyUserStore)
    myUserStore: MyUserStore;

    @Inject(CORE_SERVICES.ICurrentUserProvider)
    currentUser: CurrentUserProvider;

    @observable items: ProjectEntity[];
    @observable created;
    @observable joined;
    @observable applied;
    @observable invited;
    @observable appliedPeople;
    @observable invitedPeople;

    @action.bound
    async loadAllMyProjects(): Promise<any> {
        const result = await this.service.loadProjectsByPersonId(
            this.currentUser.UserId,
        );
        runInAction(() => {
            this.items = result;
        });
    }

    @action.bound
    async loadAllMyProjectsConnections(): Promise<any> {
        const connections = await this.personService.getPersonConnections(
            this.currentUser.UserId,
        );
        const filtered = connections.filter((item) => item.roles.length !== 0);
        runInAction(() => {
            this.created = {
                title: 'Created Projects',
                items: filtered.filter((item) => item.roles.includes('owner')),
            };
            this.joined = {
                title: 'Joined Projects',
                items: filtered.filter(
                    (item) =>
                        item.roles.includes('member') &&
                        !item.roles.includes('owner'),
                ),
            };
            this.applied = {
                title: 'Applied',
                items: filtered.filter((item) =>
                    item.roles.includes('applicant'),
                ),
            };
            this.invited = {
                title: 'Invited',
                items: filtered.filter((item) =>
                    item.roles.includes('invitee'),
                ),
            };
        });
    }
}
