import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useUnauthorizedDrawerStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            flexShrink: 0,
            background: theme.palette.primary.main[50],
            [theme.breakpoints.up('md')]: {
                minWidth: '284px',
                width: '23%',
                maxWidth: '380px',
                background: theme.palette.primary[50],
            },
        },
        drawerPaper: {
            minHeight: '100vh',
            width: '100%',
            border: 0,
            zIndex: 15,
            [theme.breakpoints.up('md')]: {
                paddingTop: '66px',
                zIndex: 5,
            },
        },
        sticky: {
            position: 'sticky',
            top: 0,
        },
        joinButton: {
            background:
                'linear-gradient(86.51deg, #544F94 13.51%, #755DD8 72.7%)',
            borderRadius: '10px',
            color: theme.palette.background.default,
            fontSize: '16px',
            fontWeight: 500,
            width: '80%',
            margin: '20px',
            padding: '10px',
        },
        infoText: {
            padding: '0px 15%',
            textAlign: 'center',
            '& h5': {
                margin: 10,
            },
        },
        joinImage: {
            width: '45%',
        },
        title: {
            marginTop: 25,
            '@media screen and ( min-height: 800px )': {
                marginTop: 60,
            },
        },
    }),
);
