import {
    Box,
    Grid,
    Theme,
    Typography,
    Button,
    IconButton,
    Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
    AdvancedSearchFilter,
    CoreConstantsInterface,
    SearchEntity,
} from '@weco/core';
import classNames from 'classnames';
import { isEmpty, get } from 'lodash';
import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { NavTabs } from './NavTabs';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import CloseIcon from '@material-ui/icons/Close';
import { SearchesList } from './SearchesList';
import { AdvancedSearch, AdvancedSearchProps } from './AdvancedSearch';
import { NavBarWithBackButton } from './NavBarWithBackButton';
import { Accordion } from './Accordion';
import { useHistory } from 'react-router';
import AdvancedSearchIcon from '../assets/icons/advanced-search.svg';
import { WE_CLICKS } from '../../../../../apps/client/src/we_clicks';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    headerTitle: {
        marginTop: '8px',
    },
    resetButton: {
        marginLeft: 'auto',
    },
    navbar: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    savedSearchesWrapper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
    navBarIcon: {
        display: 'inline',
        marginRight: '20px',
        verticalAlign: 'middle',
    },
    navBarTitle: {
        fontWeight: 500,
        color: theme.palette.common.white,
    },
}));

interface AdvancedSearchPageProps {
    className?: string;
    savedSearches: SearchEntity[];
    updatedSearchSettings: boolean;
    constants: CoreConstantsInterface;
    advancedFilter: AdvancedSearchFilter;
    onReset: () => void;
    onSearch: () => void;
    onSave: (searchName: string) => Promise<void>;
    onUpdate: (searchName: string) => Promise<void>;
    onChange: (search: AdvancedSearchFilter) => void;
    onDelete: (search: SearchEntity) => Promise<void>;
    setUpdatedSearchSettings: (param: boolean) => void;
    onSavedSearchClick: (search: AdvancedSearchFilter) => void;
}

const AdvancedSearchPage = memo((props: AdvancedSearchPageProps) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const baseUrl = RouterPaths.advancedSearch.root;
    const isProjectTab = !location.pathname.includes('people');
    const rootClasses = classNames(classes.root, props.className);
    const savedSearchesFilteredByTab = props.savedSearches?.filter(
        ({ type }) => type === (isProjectTab ? 'project' : 'person'),
    );
    const isSavedSearchActive = Boolean(get(props.advancedFilter, 'name'));
    const advanceSearchProps = ({
        onSave: props.onSave,
        onUpdate: props.onUpdate,
        onSubmit: props.onSearch,
        constants: props.constants,
        filter: props.advancedFilter,
        filterChange: props.onChange,
        isSavedSearchUpdateMode: isSavedSearchActive,
        isUpdated: props.updatedSearchSettings,
        setUpdated: props.setUpdatedSearchSettings,
        onSavedSearchClick: props.onSavedSearchClick,
    } as unknown) as AdvancedSearchProps;

    const redirectToSpecificSearchTab = () => {
        props.onSearch();
        history.push(
            isProjectTab
                ? RouterPaths.search.projects
                : RouterPaths.search.people,
        );
    };

    return (
        <Box className={rootClasses}>
            <Hidden smDown>
                <Grid
                    container
                    item
                    justify="space-between"
                    xs={12}
                    id="container-anchor-advanced-search-header"
                >
                    <Grid item>
                        <Typography variant="h1">Advanced Search</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            id={WE_CLICKS.CLOSE_ADVANCE_SEARCH}
                            onClick={redirectToSpecificSearchTab}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Hidden>
            <NavBarWithBackButton className={classes.navbar}>
                <Box>
                    <Box className={classes.navBarIcon}>
                        <img
                            src={AdvancedSearchIcon}
                            alt="Advanced search icon."
                        />
                    </Box>
                    <Typography
                        component="h1"
                        variant="h3"
                        display="inline"
                        className={classes.navBarTitle}
                    >
                        Advanced Search
                    </Typography>
                </Box>
            </NavBarWithBackButton>

            {!isEmpty(props.savedSearches) && (
                <Box
                    className={classes.savedSearchesWrapper}
                    id="container-anchor-saved-settings"
                >
                    <Accordion label="Saved settings">
                        <SearchesList
                            onDelete={props.onDelete}
                            onClick={props.onSavedSearchClick}
                            items={savedSearchesFilteredByTab}
                        />
                    </Accordion>
                </Box>
            )}

            <Grid item xs={12}>
                <Box display="flex" mb={3} mx={2}>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.headerTitle}
                    >
                        Filter search
                    </Typography>
                    <Button
                        id={WE_CLICKS.RESET_ADVANCE_SEARCH}
                        variant="text"
                        color="primary"
                        onClick={props.onReset}
                        className={classes.resetButton}
                    >
                        RESET
                    </Button>
                </Box>
            </Grid>

            <NavTabs
                searchPage
                tabs={[
                    {
                        label: 'Projects',
                        to: `${baseUrl}`,
                        component: (
                            <AdvancedSearch
                                isProjectsTab
                                {...advanceSearchProps}
                            />
                        ),
                    },
                    {
                        label: 'People',
                        to: `${baseUrl}/people`,
                        component: (
                            <AdvancedSearch
                                isProjectsTab={false}
                                {...advanceSearchProps}
                            />
                        ),
                    },
                ]}
            />
        </Box>
    );
});

export { AdvancedSearchPage };
