const unSubscriptions: any = {};

export const getUnSubscriptions = () => unSubscriptions;

export const getUnSubscription = (key: string) => unSubscriptions[key];

export const setUnSubscription = (key: string, value: any) => {
    unSubscriptions[key] = value;
};

export const deleteUnSubscription = (key: string) => {
    if (unSubscriptions[key]) {
        delete unSubscriptions[key];
    }
};

export const existsUnSubscriptions = (key: string) => !!unSubscriptions[key];
