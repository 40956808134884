export const WeSecondary = {
    50: '#F4F4F4',
    100: '#E8E8E8',
    200: '#D2D2D2',
    300: '#BBBBBB',
    400: '#A5A5A5',
    500: '#8E8E8E',
    600: '#6B6B6B',
    650: '#464646',
    700: '#474747',
    800: '#242424',
    900: '#0E0E0E',
    A100: '#E8E8E8',
    A200: '#BBBBBB',
    A400: '#8E8E8E',
    A700: '#0E0E0E',
    A800: '#8B8E9E',
};
