import {
    Hidden,
    WeChipsList,
    WeContactList,
    SectionWithTitle,
    WeSocialMediaList,
    WeMultilineText,
    WeChip,
    UnauthorizedRedirectOnclick,
} from '@weco/ui';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTypographyStyles } from '../../../theme/styles';
import { usePersonProfileData } from './store/usePersonProfileData';
import { objectToTypeValue, toChipItem } from '../../../helpers/utils';
import { useMyUserProjectsData } from '../../../store/hooks/useMyUserProjectsData';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../../../router/RouterPaths';

export const InfoTabPage = () => {
    const tabClasses = useTabStyles();
    const typographyClasses = useTypographyStyles();

    const { profile } = usePersonProfileData();
    const { activeProject } = useMyUserProjectsData();

    return (
        <Box className={tabClasses.tabContent}>
            <Grid container>
                <Hidden when={!profile.dream}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="About Me">
                            <Typography
                                component="div"
                                className={typographyClasses.info}
                            >
                                <WeMultilineText text={profile.dream} />
                            </Typography>
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.causes)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Causes">
                            <WeChipsList
                                items={toChipItem(
                                    (profile && profile.causes) || [],
                                    (activeProject && activeProject.causes) ||
                                        [],
                                )}
                                chipItemComponent={({ item, index }) => (
                                    <Link
                                        className={tabClasses.chipLink}
                                        key={index}
                                        to={{
                                            pathname: RouterPaths.search.people,
                                            state: {
                                                searchTag:
                                                    typeof item !== 'string'
                                                        ? item.value
                                                        : item,
                                            },
                                        }}
                                    >
                                        <WeChip item={item} />
                                    </Link>
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.passions)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Passions">
                            <WeChipsList
                                items={toChipItem(
                                    (profile && profile.passions) || [],
                                    (activeProject && activeProject.passions) ||
                                        [],
                                )}
                                chipItemComponent={({ item, index }) => (
                                    <Link
                                        className={tabClasses.chipLink}
                                        key={index}
                                        to={{
                                            pathname: RouterPaths.search.people,
                                            state: {
                                                searchTag:
                                                    typeof item !== 'string'
                                                        ? item.value
                                                        : item,
                                            },
                                        }}
                                    >
                                        <WeChip item={item} />
                                    </Link>
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.objectives)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle
                            title="Objectives"
                            sectionHiddenWhenEmpty
                            isEmpty={isEmpty(profile.objectives)}
                        >
                            <WeChipsList
                                items={toChipItem(
                                    (profile && profile.objectives) || [],
                                    (activeProject &&
                                        activeProject.objectives) ||
                                        [],
                                )}
                                chipItemComponent={({ item, index }) => (
                                    <Link
                                        className={tabClasses.chipLink}
                                        key={index}
                                        to={{
                                            pathname: RouterPaths.search.people,
                                            state: {
                                                searchTag:
                                                    typeof item !== 'string'
                                                        ? item.value
                                                        : item,
                                            },
                                        }}
                                    >
                                        <WeChip item={item} />
                                    </Link>
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.location)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Location">
                            <Typography className={typographyClasses.info}>
                                {profile.location?.name}
                            </Typography>
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                {/* NOTE: hidden until Dwight school collaboration will be established. */}
                {/* <Hidden when={isEmpty(profile.school)}> */}
                {/*<Grid item xs={12} className={tabClasses.section}>*/}
                {/*    <SectionWithTitle*/}
                {/*        title="School"*/}
                {/*    >*/}
                {/*        <WeChipsList items={[profile.school?.name]} />*/}
                {/*    </SectionWithTitle>*/}
                {/*</Grid>*/}
                {/* </Hidden> */}
                <Hidden when={isEmpty(profile.languages)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle
                            title="Languages"
                            sectionHiddenWhenEmpty
                            isEmpty={isEmpty(profile.languages)}
                        >
                            <WeChipsList
                                items={toChipItem(
                                    (profile && profile.languages) || [],
                                    (activeProject &&
                                        activeProject.languages) ||
                                        [],
                                )}
                                chipItemComponent={({ item, index }) => (
                                    <Link
                                        className={tabClasses.chipLink}
                                        key={index}
                                        to={{
                                            pathname: RouterPaths.search.people,
                                            state: {
                                                searchTag:
                                                    typeof item !== 'string'
                                                        ? item.value
                                                        : item,
                                            },
                                        }}
                                    >
                                        <WeChip item={item} />
                                    </Link>
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.contacts)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Contacts">
                            <UnauthorizedRedirectOnclick>
                                <WeContactList
                                    items={objectToTypeValue(profile.contacts)}
                                />
                            </UnauthorizedRedirectOnclick>
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(profile.socialMedia)}>
                    <Grid item xs={12}>
                        <SectionWithTitle title="Social Media">
                            <WeSocialMediaList
                                items={objectToTypeValue(profile.socialMedia)}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    );
};
