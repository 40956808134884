import { combineReducers } from 'redux';
import rooms from './rooms/reducer';
import authUser from './authUser/reducer';
import users from './users/reducer';
import roomMessages from './roomMessages/reducer';
import { CLEAR_STORE } from '@weco/chat';
import authUserDefaultState from './authUser/defaultState';
import usersDefaultState from './users/defaultState';
import roomsDefaultState from './rooms/defaultState';
import roomMessagesDefaultState from './roomMessages/defaultState';

const chatRootReducer = combineReducers({
    authUser,
    users,
    rooms,
    roomMessages,
});

const rootReducer = (state, action) => {
    if (action.type === CLEAR_STORE) {
        return {
            authUser: authUserDefaultState,
            users: usersDefaultState,
            rooms: roomsDefaultState,
            roomMessages: roomMessagesDefaultState,
        };
    }

    return chatRootReducer(state, action);
};

export default rootReducer;
