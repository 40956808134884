import { combineReducers } from 'redux';
import {
    ActionUserInterface,
    ActionUserSearchInterface,
    UsersListState,
    UsersSearchStateInterface,
} from '../../models/interfaces/UsersInterface';
import * as actionTypes from './actionTypes';

const list = (
    state: UsersListState = { items: {} },
    action: ActionUserInterface,
) => {
    switch (action.type) {
        case actionTypes.RECEIVE_LIST_USER:
            return {
                ...state,
                items: { ...state.items, [action.user.id]: action.user },
            };
        case actionTypes.UPDATE_USER_ROOMS:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.userId]: {
                        ...state.items[action.userId],
                        rooms: action.userRooms,
                    },
                },
            };
        default:
            return state;
    }
};

const search = (
    state: UsersSearchStateInterface = { items: [] },
    action: ActionUserSearchInterface,
) => {
    switch (action.type) {
        case actionTypes.RECEIVE_SEARCHED_USERS:
            return { ...state, items: action.users };
        case actionTypes.CLEAR_SEARCHED_USERS:
            return { ...state, items: [] };
        default:
            return state;
    }
};

const users = combineReducers({
    search,
    list,
});

export default users;
