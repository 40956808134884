import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useTypographyStyles = makeStyles((theme: Theme) => ({
    heading: {
        display: 'flex',
        color: theme.palette.text.primary,
        fontWeight: 500,
        alignItems: 'center',
    },
    info: {
        marginTop: 10,
        fontSize: '16px',
        color: theme.palette.text.secondary[650],
        marginRight: 15,
        width: '100%',
        overflow: 'hidden',
        overflowWrap: 'break-word',
    },
    infoRow: {
        margin: 0,
        minHeight: 10,
    },
}));
