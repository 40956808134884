import { gql } from 'apollo-boost';
import { Transformers, Injectable } from '@weco/common';
import { UserProfileEntity, SearchParams } from '../entity';
import makeSearchRequest from './makeSearchRequest';
import { GraphQlBaseRepository } from './GraphQlBaseRepository';
import { AdvancedSearchFilter, SearchFilter } from './SearchFilter';
import { matchPeople } from './graphql/queries';
import { config } from './config';
import debug from 'debug';
import { toJS } from 'mobx';

const log = debug('PeopleRepository');

export interface IPeopleRepository {
    getMatchList({}: SearchParams): Promise<{
        people: UserProfileEntity[];
        total: number;
    }>;
}

@Injectable()
export class PeopleRepository extends GraphQlBaseRepository
    implements IPeopleRepository {
    public async getMatchList({
        filter,
        advancedFilter,
        limit = config.defaults.queries.limit,
    }: SearchParams): Promise<{ people: UserProfileEntity[]; total: number }> {
        const defaultFilter: SearchFilter = {
            query: '',
            skills: [],
            include: [],
            exclude: [],
            causes: [],
            // industries: [],
            departments: [],
            excludeNotFilledItem: true,
            shouldFilterEmptyPicture: true,
        };

        const defaultAdvancedFilter: AdvancedSearchFilter = {
            skills: [],
            causes: [],
            passions: [],
            languages: [],
            locations: [],
            objectives: [],
        };

        const req = makeSearchRequest({
            ...defaultFilter,
            ...defaultAdvancedFilter,
            ...filter,
            ...advancedFilter,
        });
        const variables = { query: JSON.stringify(req), limit };

        log('advancedFilter argument: %O', toJS(advancedFilter));
        log('query variables: ', req);

        return this.unAuthorizedClient
            .query({
                query: gql(matchPeople),
                variables,
                fetchPolicy: 'network-only',
            })
            .then(({ data }) => {
                return {
                    people: data.matchPeople.items.map(
                        Transformers.promisePipeTransform(
                            new Transformers.ToTypeTransformer<
                                UserProfileEntity
                            >(UserProfileEntity),
                        ),
                    ),
                    total: data.matchPeople.total,
                };
            })
            .catch((error) => {
                console.log(error);
                return { people: [], total: 0 };
            });
    }
}
