import React, { memo } from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router';
import { useCurrentAuthData } from '../store/hooks/useCurrentAuthData';
import { ComingSoon, ReactHelpers } from '@weco/ui';
import { COMMON_SERVICES, EventDispatcher, useInjection } from '@weco/common';
import { RouterPaths } from './RouterPaths';
import { environment } from '../../environments/environment';

export interface WeRouteProps extends RouteProps {
    layout?: any;
    isAuthenticated?: boolean;
    analyticsEvent?: string;
}

const WeRoute = memo(
    ({ layout, children, analyticsEvent, ...props }: WeRouteProps) => {
        const history = useHistory();
        const location = useLocation();
        const { token } = useCurrentAuthData();
        const eventDispatcher = useInjection<EventDispatcher>(
            COMMON_SERVICES.CoreEventDispatcher,
        );

        if (props.isAuthenticated && !token.isAuthenticated()) {
            if (!environment.production) {
                history.push(RouterPaths.signup);
            } else {
                history.push(RouterPaths.redirect);
                window.location.href = `${
                    environment.prodLoginPage
                }?${location.search.replace('?', '')}`;
            }
        }

        const LayoutCmp = layout || React.Fragment;
        if (!props.render) {
            props.render = (p) => {
                if (analyticsEvent) {
                    eventDispatcher.dispatch(analyticsEvent, {
                        type: 'PageView',
                    });
                }
                return (
                    <LayoutCmp>
                        {ReactHelpers.renderChildren(children, p) || (
                            <ComingSoon />
                        )}
                    </LayoutCmp>
                );
            };
        }
        return <Route {...props} />;
    },
);

WeRoute.displayName = 'WeRoute';

export { WeRoute };
