export function loadScript(url: string, async: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
        let node = undefined;
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; ++i) {
            // Check if script is already there in html
            if (
                scripts[i].getAttribute('src') != null &&
                scripts[i].getAttribute('src').includes(url)
            ) {
                isFound = true;
                resolve();
            }
        }

        if (!isFound) {
            const dynamicScript = url;
            node = document.createElement('script');
            node.src = dynamicScript;
            node.type = 'text/javascript';
            node.async = async;
            node.charset = 'utf-8';
            node.onload = () => {
                resolve();
            };
            node.onerror = (error: any) => reject(error);
            document.getElementsByTagName('head')[0].appendChild(node);
            return node;
        }
        return node;
    });
}
