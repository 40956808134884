import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel, {
    FormControlLabelProps as MuiFormControlLabelProps,
} from '@material-ui/core/FormControlLabel';
import { FieldProps } from 'formik';
import { WeCheckboxProps, fieldToCheckbox } from './WeCheckbox';

export interface WeCheckboxWithLabelProps extends FieldProps, WeCheckboxProps {
    label: Omit<
        MuiFormControlLabelProps,
        'checked' | 'name' | 'value' | 'control'
    >;
}

export function WeCheckboxWithLabel<Val = unknown>({
    label,
    ...props
}: WeCheckboxWithLabelProps) {
    return (
        <FormControlLabel
            control={<MuiCheckbox {...fieldToCheckbox(props)} />}
            {...label}
        />
    );
}
