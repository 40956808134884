import {
    Button,
    FormControl,
    LinearProgress,
    Box,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, memo } from 'react';
import { WeTextField } from '../forms/WeTextField';
import { FormikValidators } from '../../../../common/src/lib/validators/FormikValidators';

const StyledTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#F3F3F3',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#F3F3F3',
        },
    },
})(WeTextField);

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        width: '300px',
    },
    button: {
        width: '300px',
        height: '48px',
        borderRadius: '24px',
        fontSize: '18px',
        color: theme.palette.primary.main,
        margin: 0,
        marginTop: 70,
        fontWeight: 500,
        '&:disabled': {
            color: 'white',
        },
    },
    field: {
        marginTop: '15px',
        marginBottom: '22px',
    },
    label: {
        color: `${theme.palette.common.white}!important`,
        fontSize: '18px',
        top: '-4px !important',
    },
    input: {
        fontSize: '18px',
        padding: '15px 20px',
        color: theme.palette.common.white,
        backgroundColor: 'rgba(255,255,255, 0.2)',
        borderRadius: '8px',
        WebkitBoxShadow: '0 0 0 1000px transparent inset !important',
        background:
            '-webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%)',
        transition:
            'background-color 5000s ease-in-out 0s, color 5000s, padding 5000s, font-size 5000s',
    },
    text: {
        margin: '20px 0',
        textAlign: 'center',
        color: theme.palette.common.white,
    },
}));

export interface SignUpFormFormData {
    email: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
}

export const SignUpForm: FunctionComponent<{
    onSubmit: (data: SignUpFormFormData) => Promise<any>;
}> = React.memo(function ({ onSubmit }) {
    const classes = useStyles();

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                confirmPassword: '',
                firstName: '',
                lastName: '',
            }}
            validate={(values) => {
                const errors: Partial<SignUpFormFormData> = {};
                if (!values.firstName.trim()) {
                    errors.firstName = 'Required';
                }

                if (!values.lastName.trim()) {
                    errors.lastName = 'Required';
                }

                if (!values.email.trim()) {
                    errors.email = 'Required';
                }

                if (!values.password.trim()) {
                    errors.password = 'Required';
                }

                if (!values.confirmPassword.trim()) {
                    errors.confirmPassword = 'Required';
                }

                if (values.password !== values.confirmPassword) {
                    errors.confirmPassword = 'Passwords should match';
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values)
                    .then(() => {
                        setSubmitting(false);
                    })
                    .catch((e) => {
                        setSubmitting(false);
                    });
            }}
        >
            {({ submitForm, isSubmitting, isValid }) => (
                <Box textAlign="center">
                    <FormControl className={classes.buttonContainer}>
                        <Field
                            name="firstName"
                            label="First Name"
                            type="text"
                            variant="outlined"
                            required
                            className={classes.field}
                            InputProps={{
                                classes: { input: classes.input },
                            }}
                            InputLabelProps={{
                                className: classes.label,
                            }}
                            component={WeTextField}
                        />
                        <Field
                            name="lastName"
                            label="Last Name"
                            type="text"
                            variant="outlined"
                            required
                            className={classes.field}
                            InputProps={{
                                classes: { input: classes.input },
                            }}
                            InputLabelProps={{
                                className: classes.label,
                            }}
                            component={WeTextField}
                        />
                        <Field
                            name="email"
                            label="Email"
                            type="email"
                            variant="outlined"
                            required
                            className={classes.field}
                            InputProps={{
                                classes: { input: classes.input },
                            }}
                            InputLabelProps={{
                                className: classes.label,
                            }}
                            component={WeTextField}
                            validate={FormikValidators.validateEmail}
                        />
                        <Field
                            name="password"
                            type="password"
                            label="Password"
                            variant="outlined"
                            required
                            className={classes.field}
                            InputProps={{
                                classes: { input: classes.input },
                            }}
                            InputLabelProps={{
                                className: classes.label,
                            }}
                            component={WeTextField}
                            validate={FormikValidators.validatePassword}
                        />
                        <Field
                            name="confirmPassword"
                            type="password"
                            label="Confirm Password"
                            variant="outlined"
                            required
                            className={classes.field}
                            InputProps={{
                                classes: { input: classes.input },
                            }}
                            InputLabelProps={{
                                className: classes.label,
                            }}
                            component={WeTextField}
                        />
                        {isSubmitting && <LinearProgress />}
                    </FormControl>
                    <br />
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        disabled={isSubmitting || !isValid}
                        onClick={submitForm}
                    >
                        Create Account
                    </Button>
                    <Typography className={classes.text}>or</Typography>
                </Box>
            )}
        </Formik>
    );
});
