import { useMyUserData } from 'apps/client/src/app/store/hooks/useMyUserData';
import { usePersonRolesData } from 'apps/client/src/app/store/hooks/usePersonRolesData';
import { useProjectRolesData } from 'apps/client/src/app/store/hooks/useProjectRolesData';
import debug from 'debug';
import { Formik } from 'formik';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import React, { memo, useEffect, useState } from 'react';
import { WeChipsInput } from '../forms/WeChipsInput';
import { SearchFieldData } from './SearchFieldData';
import { SearchFieldEdit } from './SearchFieldEdit';
import { SearchFieldViewOrEdit } from './SearchFieldViewOrEdit';

const log = debug('RolesSearchField');

export interface RolesSearchFieldProps {
    typeOfPositionsToUse: 'person' | 'project';
    selected?: string[];
    onChange: (key: string, value: string[]) => void;
}

const RolesSearchField = memo((props: RolesSearchFieldProps) => {
    const [selectedChips, setChips] = useState([]);

    const { activeProjectId } = useMyUserData();
    const myRoles = usePersonRolesData().allRoles;
    const myProjectsRoles = useProjectRolesData(activeProjectId).allRoles;
    const namesOfMyRoles = map(get('name'))(myRoles);
    const namesOfMYProjectsRoles = map(get('name'))(myProjectsRoles);
    const shouldProjectRolesBeUsed = props.typeOfPositionsToUse === 'project';

    log('selectedRoles: ', selectedChips);
    log('shouldUseProject: ', shouldProjectRolesBeUsed);
    log('myPositions: %O', myRoles);
    log('myProjectsPositions: %O', myProjectsRoles);
    log('namesMyPersonsPositions: ', namesOfMYProjectsRoles);
    log('namesMyProjectsPositions: ', namesOfMYProjectsRoles);

    useEffect(() => {
        setChips(props.selected || []);
    }, [props.selected]);

    function setSelectedSkills(roleNames: string[]): void {
        const listOfSkillIds = filter(
            shouldProjectRolesBeUsed ? myProjectsRoles : myRoles,
            (position) => {
                return roleNames.includes(position.name.toLowerCase());
            },
        )
            .map(get('skills'))
            .map(map(get('id')));
        const skillsOfSelectedRoles = flatten(listOfSkillIds);

        log('handleChange is called. ', roleNames);
        log('skills of selected roles: ', skillsOfSelectedRoles);

        setChips(roleNames);
        props.onChange('skills', skillsOfSelectedRoles);
        props.onChange('roles', roleNames);
    }

    return (
        <Formik initialValues={{}} onSubmit={() => {}}>
            <SearchFieldViewOrEdit title="Roles" field="roles">
                {({ editing, handleCancel }) => (
                    <div>
                        {!editing ? (
                            <SearchFieldData values={selectedChips} />
                        ) : (
                            <SearchFieldEdit
                                title="Roles"
                                options={[]}
                                selected={props?.selected || []}
                                onChange={props.onChange}
                                handleCancel={handleCancel}
                            >
                                <WeChipsInput
                                    lowerCase
                                    autoFocus
                                    value={selectedChips}
                                    onChange={setSelectedSkills}
                                    suggestions={
                                        shouldProjectRolesBeUsed
                                            ? namesOfMyRoles
                                            : namesOfMYProjectsRoles
                                    }
                                />
                            </SearchFieldEdit>
                        )}
                    </div>
                )}
            </SearchFieldViewOrEdit>
        </Formik>
    );
});

RolesSearchField.displayName = 'RolesSearchField';

export { RolesSearchField };
