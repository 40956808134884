import {
    COMMON_SERVICES,
    CoreEventDispatcher,
    Inject,
    Injectable,
    getPictureUrl,
} from '@weco/common';
import {
    PersonService,
    ProjectEntity,
    ProjectItemInterface,
    ProjectsService,
    UserProfileEntity,
    defaultPicture,
} from '@weco/core';
import { action, observable, runInAction } from 'mobx';
import { APP_EVENTS } from '../../../../../app_events';
import { MyUserStore } from '../../../../store/MyUserStore';
import { Event } from '@weco/common';
import { Subscription } from 'rxjs';

export interface PersonProfileStoreInterface {
    profile: UserProfileEntity;
    projects: ProjectItemInterface[];
    matchPercent: number;
    isApplicant: () => boolean;
    isMember: () => boolean;
    ableToInvite: () => boolean;
    invited: () => boolean;
    personProjectConnection: {
        personId: string;
        projectId: string;
        roles: string[];
    };
    loadProjects(id: string): Promise<ProjectEntity[]>;
    loadItem(
        id: string,
        notFoundRedirect: () => void,
    ): Promise<UserProfileEntity>;
    loadPersonProjectConnection(): Promise<any>;
    loadMatchPercent(): Promise<any>;
    inviteToActiveProject(roleId?: string): Promise<any>;
    declineApplication(): Promise<any>;
    acceptApplication(): Promise<any>;
    removeProjectMember(): Promise<any>;
    recallInvitation(): Promise<any>;
    getProfilePicture(): string;
    setPersonProjectConnection(connection): void;
}

@Injectable()
export class PersonProfileStore implements PersonProfileStoreInterface {
    @Inject(PersonService)
    private personService: PersonService;

    @Inject(ProjectsService)
    private projectsService: ProjectsService;

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    private eventDispatcher: CoreEventDispatcher;

    @Inject(MyUserStore)
    private myUserStore: MyUserStore;

    @observable profile: UserProfileEntity;
    @observable projects: ProjectItemInterface[] = [];
    @observable matchPercent: number;
    @observable computedProfilePicture: string;

    @observable personProjectConnection: {
        personId: string;
        projectId: string;
        roles: string[];
    };
    private subscriptions: Subscription[];

    constructor() {
        setTimeout(() => {
            this.subscriptions = [
                this.eventDispatcher.addListener(
                    APP_EVENTS.COOL_UP,
                    this.refreshCurrentData,
                ),
                this.eventDispatcher.addListener(
                    APP_EVENTS.COOL_DOWN,
                    this.refreshCurrentData,
                ),
            ];
        });
    }

    refreshCurrentData = () => {
        if (this.profile?.id) {
            setTimeout(() => {
                this.loadItem(this.profile.id);
            }, 500);
        }
    };

    getProfilePicture = () => {
        if (!this.computedProfilePicture) {
            this.computedProfilePicture = getPictureUrl(
                this.profile.picture,
                defaultPicture,
            );
        }
        return this.computedProfilePicture;
    };

    isApplicant = () => {
        return (
            this.personProjectConnection &&
            this.personProjectConnection.roles.includes('applicant')
        );
    };

    isMember = () => {
        return (
            this.personProjectConnection &&
            this.personProjectConnection.roles.includes('member')
        );
    };

    ableToInvite = () => {
        if (!this.personProjectConnection) {
            return true;
        }
        return (
            this.personProjectConnection &&
            !['applicant', 'member', 'invitee'].some((p) =>
                this.personProjectConnection.roles.includes(p),
            )
        );
    };

    invited = () => {
        return (
            this.personProjectConnection &&
            this.personProjectConnection.roles.includes('invitee')
        );
    };

    @action.bound
    async loadItem(
        id: string,
        notFoundRedirect?: () => void,
    ): Promise<UserProfileEntity> {
        try {
            const result = await this.personService.findById(id);
            runInAction(() => {
                if (!result) {
                    notFoundRedirect();
                }
                this.profile = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    async loadMatchPercent(): Promise<number> {
        try {
            const result = await this.personService.getMatchPercent(
                this.profile?.id,
            );
            runInAction(() => {
                this.matchPercent = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    async loadProjects(id: string): Promise<ProjectItemInterface[]> {
        try {
            const result = await this.projectsService.getPersonProjectsList(id);
            runInAction(() => {
                this.projects = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    async loadPersonProjectConnection(): Promise<any> {
        if (
            !this.myUserStore.profile ||
            !this.myUserStore.profile.activeProject
        ) {
            return;
        }

        try {
            const result = await this.projectsService.getPersonProjectConnection(
                this.profile.id,
                this.myUserStore.profile.activeProject.id,
            );
            runInAction(() => {
                this.personProjectConnection = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    setPersonProjectConnection(connection): void {
        this.personProjectConnection = connection;
    }

    @action.bound
    async inviteToActiveProject(roleId?: string): Promise<any> {
        await this.projectsService.inviteToProject(
            this.profile.id,
            this.myUserStore.profile.activeProject.id,
            roleId,
        );
        this.eventDispatcher.dispatch(
            APP_EVENTS.INVITE_TO_PROJECT,
            new Event({
                userId: this.profile.id,
                userName: this.profile.name,
                projectName: this.myUserStore.profile.activeProject.name,
                projectId: this.myUserStore.profile.activeProject.id,
                email: this.profile.contacts && this.profile.contacts.email,
            }),
        );
        return this.loadPersonProjectConnection();
    }

    @action.bound
    async declineApplication(): Promise<any> {
        await this.projectsService.declineApplication(
            this.profile.id,
            this.myUserStore.profile.activeProject.id,
        );
        this.eventDispatcher.dispatch(
            APP_EVENTS.USER_REJECT,
            new Event({
                userName: this.profile.name,
                userId: this.profile.id,
                email: this.profile.contacts && this.profile.contacts.email,
            }),
        );
        return this.loadPersonProjectConnection();
    }

    @action.bound
    async acceptApplication(): Promise<any> {
        await this.projectsService.acceptApplication(
            this.profile.id,
            this.myUserStore.profile.activeProject.id,
        );
        this.eventDispatcher.dispatch(
            APP_EVENTS.PROJECT_ACCEPT,
            new Event({
                userId: this.profile.id,
                userName: this.profile.name,
                email: this.profile.contacts && this.profile.contacts.email,
            }),
        );
        return this.loadPersonProjectConnection();
    }

    @action.bound
    async removeProjectMember(): Promise<any> {
        await this.projectsService.removeProjectMember(
            this.profile.id,
            this.myUserStore.profile.activeProject.id,
        );
        return this.loadPersonProjectConnection();
    }

    @action.bound
    async recallInvitation(): Promise<any> {
        await this.projectsService.recallInvitation(
            this.profile.id,
            this.myUserStore.profile.activeProject.id,
        );
        this.eventDispatcher.dispatch(
            APP_EVENTS.USER_RECALL,
            new Event({
                userName: this.profile.name,
                userId: this.profile.id,
            }),
        );
        return this.loadPersonProjectConnection();
    }

    dispose = () => {
        this.subscriptions?.forEach((s) => {
            s.unsubscribe();
        });
    };
}
