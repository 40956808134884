import React, { useState } from 'react';
import { Link, matchPath, Route, Switch, useLocation } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import { renderChildren } from '../helpers/renderChildren';
import { ComingSoon } from '../molecules';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        fontWeight: 400,
        '&$selected': {
            fontWeight: 500,
        },
    },
    floatingMenu: {
        position: 'fixed',
        top: '30px',
        background: 'white',
        zIndex: 9,
        boxShadow: '0 2px 1px -2px grey',
    },
    navTabsContent: {
        padding: 0,
        marginTop: 0,
        paddingTop: '15px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },

        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    indicator: {
        height: '3px',
        display: 'flex',
        justifyContent: 'center',
        '& > span': {
            maxWidth: 90,
            width: '100%',
            background: theme.palette.primary[200],
        },
    },
    stickyTabsContent: {
        marginTop: '100px',
    },
}));

const useTabStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        marginBottom: 0,
        fontWeight: 500,
    },
}));

export const NavTabsId = 'NavTabs';

export function scrollToNavTabs(): void {
    const tabs = document.getElementById(NavTabsId);
    if (tabs) {
        const offset =
            tabs.getBoundingClientRect().top + window.pageYOffset - 33;
        window.scrollTo({ top: offset, behavior: 'smooth' });
    }
}

export type NavTabsProps = {
    classes?: any;
    id?: string;
    tabs: {
        id?: string;
        label: string | React.ReactNode;
        to: string;
        path?: string;
        icon?: JSX.Element;
        componentProps?: any;
        component?: any;
    }[];
    searchPage?: boolean;
};

export const NavTabs = ({ tabs, ...props }: NavTabsProps) => {
    const cls = useStyles();
    const location = useLocation();
    const tabClasses = useTabStyles();
    const [stickyTabs, setStickyTabs] = useState(false);
    const isMobileScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );

    /* TODO: Hack. Switch choose first matched Route. so both match /lorem and /lorem/id. using exact and strict did not help. */
    const sortedTabsByLongestPath = []
        .concat(tabs)
        .sort((b, a) => (a.path || a.to).length - (b.path || b.to).length);
    const activeTabItem =
        sortedTabsByLongestPath.find(({ path, to, ...rest }) => {
            return !!matchPath(location.pathname, {
                path: path || to,
                ...rest,
            });
        }) || {};

    return (
        <div className={cls.root}>
            <VisibilitySensor partialVisibility onChange={setStickyTabs}>
                <div style={{ opacity: 0, height: '1px' }}>
                    This is a placeholder for visibility sensor.
                </div>
            </VisibilitySensor>
            <Box
                className={
                    !stickyTabs && !props.searchPage ? cls.floatingMenu : ''
                }
                style={{
                    width: isMobileScreen
                        ? '100%'
                        : `${
                              document.getElementById('tabs-layout')
                                  ?.clientWidth
                          }px`,
                }}
            >
                <Tabs
                    variant="fullWidth"
                    id={props.id || NavTabsId}
                    value={activeTabItem.path || activeTabItem.to}
                    TabIndicatorProps={{ children: <span /> }}
                    classes={{
                        root: classNames(cls.root, props.classes?.root),
                        flexContainer: props.classes?.flexContainer,
                        indicator: classNames(
                            cls.indicator,
                            props.classes?.indicator,
                        ),
                    }}
                >
                    {tabs.map(({ path, to, label, icon, id }, index) => (
                        <Tab
                            to={to}
                            replace
                            key={index}
                            icon={icon}
                            component={Link}
                            value={path || to}
                            classes={tabClasses}
                            className={props.classes?.tab}
                            id={id || `helphero-anchor-tab-${index}`}
                            label={<span className="tab-label">{label}</span>}
                        />
                    ))}
                </Tabs>
            </Box>
            <div
                className={classNames({
                    [cls.navTabsContent]: true,
                    [cls.stickyTabsContent]: !stickyTabs && !props.searchPage,
                })}
            >
                <Switch>
                    {sortedTabsByLongestPath.map(
                        (
                            { path, to, component, componentProps, ...rest },
                            index,
                        ) => (
                            <Route
                                key={index}
                                path={path || to}
                                {...rest}
                                render={() => (
                                    <Box>
                                        {() =>
                                            renderChildren(
                                                component || ComingSoon,
                                                componentProps,
                                            )
                                        }
                                    </Box>
                                )}
                            />
                        ),
                    )}
                </Switch>
            </div>
        </div>
    );
};
