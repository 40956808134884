import {
    Box,
    Button,
    makeStyles,
    Typography,
    Dialog,
    withStyles,
} from '@material-ui/core';
import React, { memo } from 'react';

/*
 NOTE: similar buttons are used in OnboardingNavigationButtons.
 Meaning some button shaer styles.
 Probably all buttons require only first character to be uppercased,
 but MUI defaults to uppercasing entire text.
*/
// TODO either hack MainTheme or create a reusable component.
const StyledButton = withStyles({
    root: {
        color: 'white',
        fontWeight: 500,
        fontSize: '17px',
        padding: '10px 25px',
        textTransform: 'none',
        backgroundColor: '#C8C8C8',
    },
})(Button);

const useStyles = makeStyles((theme) => ({
    childrenContainer: {
        backgroundColor: 'white',
        margin: '27px 70px',
    },
    title: {
        marginBottom: '42px',
        color: theme.palette.text.primary,
    },
    subtitle: {
        marginBottom: '42px',
        fontSize: '19px',
        lineHeight: '22px',
        color: theme.palette.text.secondary,
    },
    noRoundCorners: {
        borderRadius: 0,
    },
}));

export const EmailWasSentDialog = memo(function EmailWasSentDialog({
    isOpen,
    onClose,
    onResendButtonClicked,
}: {
    isOpen: boolean;
    onClose: () => void;
    onResendButtonClicked: () => void;
}) {
    const cx = useStyles();
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-label="Email is sent dialog."
            classes={{ paper: cx.noRoundCorners }}
        >
            <Box textAlign="center" className={cx.childrenContainer}>
                <Typography className={cx.title} variant="h4">
                    You got email!
                </Typography>
                <Typography className={cx.subtitle}>
                    Please go to your mail and verify your account.
                </Typography>
                <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={onResendButtonClicked}
                >
                    Resend Email
                </StyledButton>
            </Box>
        </Dialog>
    );
});
