import React from 'react';
import MuiCheckbox, {
    CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox';
import { FieldProps } from 'formik';
import { withStyles } from '@material-ui/core';

export interface WeCheckboxProps
    extends FieldProps,
        Omit<
            MuiCheckboxProps,
            | 'name'
            | 'value'
            | 'error'
            | 'form'
            | 'checked'
            | 'defaultChecked'
            // Excluded for conflict with Field name
            | 'type'
        > {
    type?: string;
}

export const fieldToCheckbox = ({
    disabled,
    field,
    form: { isSubmitting },
    type,
    ...props
}: WeCheckboxProps): MuiCheckboxProps => {
    const indeterminate = !Array.isArray(field.value) && field.value == null;

    return {
        disabled: disabled || isSubmitting,
        indeterminate,
        ...props,
        ...field,
    };
};

export const WeCheckbox = (props: WeCheckboxProps) => {
    return withStyles({
        root: {
            color: '#D6D6D6',
            padding: 4,
            '&$checked': {
                color: '#8e8e8e',
            },
        },
        checked: {},
    })((restProps) => (
        <MuiCheckbox
            color="default"
            {...fieldToCheckbox({ ...restProps, ...props })}
        />
    ));
};
