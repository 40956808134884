import React from 'react';
import ChatContainer from './containers/ChatContainer';
import { ChatContainerChildProps } from './models/interfaces/ChatPropsInterfaceInterface';

export const Chat = (props: ChatContainerChildProps) => {
    return (
        <ChatContainer
            token={props.token}
            chatUserId={props.chatUserId}
            firestore={props.firestore}
            chatTitle={'Chat'}
            findUserByName={props.searchUsers}
            addUserToRoom={props.addUserToRoom}
            createRoom={props.createRoom}
            mbOpenRoom={props.mbOpenRoom}
            onAvatarClick={props.onAvatarClick}
            getSkillSetById={props.getSkillSetById}
            tmpRoom={props.tmpRoom}
        />
    );
};

export default Chat;
