import { TextField, Zoom } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import {
    DepartmentEntity,
    getDepartmentDisplayName,
    SkillSetsEntity,
} from '@weco/core';
import { Field, Formik } from 'formik';
import { identity } from 'lodash/fp';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/isEmpty';
import React, { memo } from 'react';
import { WeAutocomplete } from '../forms/WeAutocomplete';

export interface ApplyToProjectDialogProps {
    isOpen: boolean;
    roles: SkillSetsEntity[];
    departments: DepartmentEntity[];
    onSubmit: (role: SkillSetsEntity) => void;
    onDialogToggle: (isOpen?: boolean) => void;
}

interface FormValues {
    role: SkillSetsEntity;
    department: DepartmentEntity;
}

const ApplyToProjectDialog = memo(
    ({ departments = [], roles = [], ...props }: ApplyToProjectDialogProps) => {
        return (
            <Formik
                validate={validateForm}
                initialValues={
                    {
                        role: {},
                        department: {},
                    } as FormValues
                }
                onSubmit={({ role }) => {
                    props.onSubmit(role);
                    props.onDialogToggle();
                }}
            >
                {({ submitForm, resetForm, errors, values }) => {
                    const rolesOfSelectedDepartment = roles.filter(
                        (role) =>
                            role.department?.name === values.department?.name,
                    );

                    function resetFormAndCloseDialog() {
                        resetForm();
                        props.onDialogToggle();
                    }

                    return (
                        <Dialog
                            open={props.isOpen}
                            onClose={resetFormAndCloseDialog}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">
                                Apply to a project or to a role
                            </DialogTitle>
                            <DialogContent>
                                <Field
                                    name="department"
                                    options={departments}
                                    component={WeAutocomplete}
                                    getOptionSelected={identity}
                                    getOptionLabel={(department) =>
                                        getDepartmentDisplayName(
                                            department.name,
                                        )
                                    }
                                    renderInput={(
                                        params: AutocompleteRenderInputParams,
                                    ) => (
                                        <TextField
                                            {...(params as any)}
                                            error={!!errors.department}
                                            helperText={errors.department}
                                            label="Select Department (optional)"
                                        />
                                    )}
                                />
                                <Zoom in={!!values.department?.name}>
                                    <Field
                                        name="role"
                                        component={WeAutocomplete}
                                        getOptionLabel={get('name')}
                                        getOptionSelected={identity}
                                        options={rolesOfSelectedDepartment}
                                        renderInput={(
                                            params: AutocompleteRenderInputParams,
                                        ) => (
                                            <TextField
                                                {...(params as any)}
                                                label="Select Role"
                                                error={!!errors.role}
                                                helperText={errors.role}
                                            />
                                        )}
                                    />
                                </Zoom>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    onClick={resetFormAndCloseDialog}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    onClick={submitForm}
                                    disabled={!isEmpty(errors)}
                                >
                                    Apply
                                </Button>
                            </DialogActions>
                        </Dialog>
                    );
                }}
            </Formik>
        );
    },
);

function validateForm(values: FormValues) {
    const { department, role } = values;
    const errors: Partial<{
        role: string;
        department: string;
    }> = {};

    if (department.name && !role.name) {
        errors.role = 'Required.';
    }

    return errors;
}

ApplyToProjectDialog.displayName = 'ApplyToProjectDialog';

export { ApplyToProjectDialog };
