import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { WeLanguageChip, WeChipItem } from './WeLanguageChip';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export interface WeChipsListProps {
    items: (WeChipItem | string)[];
    onClick?: (item: any) => void;
    name?: string;
    chipSize?: string;
}

export const WeChipsLanguageList = ({
    items,
    onClick,
    chipSize,
}: WeChipsListProps) => {
    if (items === null) return null;
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {[]
                .concat(items || [])
                .filter((v) => !!v)
                .map((v, i) => {
                    if (
                        !v ||
                        (v && typeof v !== 'string' && v.label === undefined)
                    ) {
                        console.error('WeChipList label empty');
                        return null;
                    }
                    return (
                        <WeLanguageChip
                            item={v}
                            onClick={onClick}
                            key={i}
                            chipSize={chipSize}
                        />
                    );
                })}
        </Box>
    );
};
