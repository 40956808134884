import {
    AnalyticsEvent,
    AnalyticsService,
    AuthAnalyticsEvent,
    COMMON_SERVICES,
    EventSubscriberInterface,
    Inject,
    Injectable,
} from '@weco/common';
import { APP_EVENTS } from '../../../app_events';
import { AuthEvent } from '../../events/AuthEvent';
import { matchPath } from 'react-router';
import { namedRoutes } from '../../router/namedRoutes';
import { MixpanelProvider } from '../../../../../../libs/common/src/lib/analytics/providers/MixpanelProvider';
import { ProfileUpdatedEvent } from '../../events/ProfileEvents';
import * as Inflected from 'inflected';

@Injectable()
export class AnalyticsEventSubscriber implements EventSubscriberInterface {
    subscribedEvents: Map<string | RegExp, (event: any) => void> = new Map();
    mutedEvents = [
        APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
        APP_EVENTS.APP_ON_ROUTE_CHANGED,
    ];

    @Inject(COMMON_SERVICES.AnalyticsService)
    private analytics: AnalyticsService;

    constructor() {
        this.subscribedEvents.set(RegExp('.*'), this.proxyAll);
        this.subscribedEvents.set(
            APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
            this.identify,
        );
        this.subscribedEvents.set(
            APP_EVENTS.APP_ON_ROUTE_CHANGED,
            this.pageView,
        );
        this.subscribedEvents.set(
            APP_EVENTS.USER_PROFILE_UPDATED,
            this.userProfileUpdated,
        );
        this.subscribedEvents.set(
            APP_EVENTS.PROJECT_PROFILE_UPDATED,
            this.userProjectUpdated,
        );
        this.subscribedEvents.set(APP_EVENTS.AUTH_SIGNUP, this.onUserSignUp);
        this.subscribedEvents.set(APP_EVENTS.AUTH_SIGNUP, this.onUserSignUp);
    }

    private userProjectUpdated = (event: ProfileUpdatedEvent) => {};

    private userProfileUpdated = (event: ProfileUpdatedEvent) => {
        const mixpanel: MixpanelProvider = this.analytics.getProvider(
            MixpanelProvider,
        ) as MixpanelProvider;
        mixpanel.setProfile({
            isActivated: event.profile.isActivated,
            filledPercentage: event.profile.filledPercentage,
        });
    };

    private proxyAll = ({ event, name }) => {
        if (this.mutedEvents.indexOf(name) !== -1) {
            return;
        }
        this.analytics.event(new AnalyticsEvent(name, event.subject || event));
    };

    private pageView = (event) => {
        const route = namedRoutes.find((r) => {
            return !!matchPath(event.subject.pathname, { path: r.path });
        });
        const dynamicName = Inflected.camelize(
            event.subject.pathname
                .split('/')
                .reduce((acc, val) => {
                    if (val && val.length < 10) {
                        acc.push(val);
                    }
                    return acc;
                }, [])
                .join('_'),
        );
        this.analytics.pageView(
            event.subject.pathname,
            route?.name || dynamicName,
        );
    };

    private onUserSignUp = (event: AuthEvent) => {
        const mixpanel: MixpanelProvider = this.analytics.getProvider(
            MixpanelProvider,
        ) as MixpanelProvider;
        if (mixpanel) {
            if (event.subject.isAuthenticated()) {
                mixpanel.alias(event.subject.getUser().id);
                mixpanel.setProfile({ signupAt: new Date().toISOString() });
            }
        }
    };

    private identify = (event: AuthEvent) => {
        if (event.subject.isAuthenticated()) {
            this.analytics.identify(
                new AuthAnalyticsEvent(
                    'AUTH_USER_IDENTIFY',
                    event.subject.getUser().id,
                    event.subject.getUser(),
                ),
            );
        } else {
            this.analytics.identify(
                new AuthAnalyticsEvent('AUTH_USER_IDENTIFY', null, null),
            );
        }
    };
}
