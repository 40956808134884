import size from 'lodash/size';
import classNames from 'classnames';
import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import { getPictureUrl } from '@weco/common';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { defaultPicture, ProjectEntity, UserProfileEntity } from '@weco/core';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { Avatar } from './Avatar';
import { CardBase } from './CardBase';
import { ShareButton } from './ShareButton';
import { CardFooter } from './CardFooter';
import { SpriteSvgIcon, UnauthorizedRedirectOnclick } from '../molecules';
import { GoToChatButton } from './GoToChatButton';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

interface PeopleListProps {
    currentUserId?: string;
    items: UserProfileEntity[];
    className?: string;
    skillsSection?: boolean;
}

const PeopleList = memo(({ items, ...props }: PeopleListProps) => {
    const classes = useStyles();
    const { push } = useHistory();
    const rootClasses = classNames(classes.root, props.className, 'PeopleList');

    if (isEmpty(items)) {
        return null;
    }

    return (
        <Grid
            container
            spacing={2}
            className={rootClasses}
            justify="space-around"
        >
            {items?.map((person, index) => {
                const { id, picture } = person;
                const isGuestUserOrMyOwnProfile =
                    !props.currentUserId || id === props.currentUserId;

                const getRoute = () => {
                    return props.currentUserId === person.id
                        ? RouterPaths.myProfile
                        : RouterPaths.profilePage;
                };

                return (
                    <Grid key={index} item>
                        <CardBase
                            title={`${person.name} ${person.lastName || ''}`}
                            avatar={
                                <Avatar
                                    picture={getPictureUrl(
                                        picture,
                                        defaultPicture,
                                    )}
                                    shape="round"
                                    withBorder
                                    percentagePlacement="none"
                                    size="normal"
                                    withShadow
                                />
                            }
                            topRightIcon={
                                props.skillsSection ? (
                                    <UnauthorizedRedirectOnclick>
                                        <GoToChatButton person={person} />
                                    </UnauthorizedRedirectOnclick>
                                ) : (
                                    <ShareButton
                                        iconColor="gray"
                                        picture={picture}
                                        shareId={id}
                                        type="person"
                                        copyTooltipPlacement="bottom"
                                        dropdownDirection="down"
                                    />
                                )
                            }
                            footer={
                                <CardFooter
                                    icons={[
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="card-about-cards-footer" />
                                            ),
                                            label: 'Bio',
                                            url: `${getRoute()}/${person.id}`,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="skill-team-card-footer" />
                                            ),
                                            label: 'Skills',
                                            url: `${getRoute()}/${
                                                person.id
                                            }/skills`,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="resources-card-footer" />
                                            ),
                                            label: 'Resources',
                                            url: `${getRoute()}/${
                                                person.id
                                            }/resources`,
                                        },
                                    ]}
                                />
                            }
                            description={person.dream}
                            matchPercent={person.matchPercent}
                            isMatchPercentHidden={isGuestUserOrMyOwnProfile}
                            link={`${getRoute()}/${id}`}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
});

PeopleList.displayName = 'PeopleList';

export { PeopleList };
