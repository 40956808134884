import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import LoginBackground from '../../../../assets/images/login-background.png';

export const useAuthorizationLayoutStyles = makeStyles((theme: Theme) => ({
    root: {
        background: `linear-gradient(180deg, rgba(26, 24, 133, 0.8) 27.08%, rgba(19, 29, 119, 0) 100%), url(${LoginBackground}) no-repeat center top / cover`,
        width: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
        position: 'fixed',
        overflowY: 'scroll',
    },
    svgIcon: {
        marginRight: 10,
        width: '24px',
        height: '24px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0 0 0',
        [theme.breakpoints.up('md')]: {
            padding: '60px 0 0 0',
        },
    },
    main: {
        display: 'flex',
        justifyContent: 'center',
    },
    text: {
        margin: '20px 0',
        textAlign: 'center',
        color: theme.palette.common.white,
    },
    fieldBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        width: '300px',
        height: '48px',
        borderRadius: '24px',
        marginBottom: 20,
        fontSize: '14px',
    },
    copyRight: {
        color: theme.palette.common.white,
        fontSize: '13px',
        marginTop: 40,
    },
    logoImage: {
        width: '182px',
        height: 'auto',
    },
    signInText: {
        color: theme.palette.common.white,
        margin: 40,
        fontWeight: 400,
    },
    alertContainer: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    signUpLink: {
        color: theme.palette.common.white,
        fontWeight: 400,
    },
    loading: {
        marginRight: 10,
    },
    buttonText: {
        width: '100%',
        textAlign: 'initial',
        paddingLeft: '15px',
    },
}));
