import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import DotedShape from '../../../../assets/images/about/Doted-Shape.png';
import Ellipse from '../../../../assets/images/about/Ellipse.png';

export const aboutPageStyles = makeStyles((theme: Theme) => ({
    quoteContainer: {
        color: 'white',
        height: '195px',
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '31px',
        backgroundColor: theme.palette.primary.main,
        marginBottom: '50px',
    },
    videoBox: {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    svgIcon: {
        minWidth: 29,
        minHeight: 29,
    },
    sectionHeader: {
        textAlign: 'center',
        marginTop: 70,
        color: theme.palette.secondary[650],
    },
    disableOutline: {
        border: '0px!important',
        borderColor: 'transparent!important',
    },
    investText: {
        marginBottom: 111,
        textAlign: 'center',
    },
    createTitle: {
        textAlign: 'right',
    },
    circlesRoot: {
        maxWidth: '375px',
        margin: '0 auto',
    },
    underText: {
        padding: '39px 0',
    },
    smallText: {
        fontSize: '16px',
    },
    imagesContainer: {
        marginTop: '80px',
        backgroundImage: `url(${DotedShape})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '-5% 50%',
    },
    teamBg: {
        backgroundImage: `url(${DotedShape})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '99% 50%',
        backgroundSize: '30%',
    },
    createImage: {
        width: '100%',
        position: 'relative',
        left: '20px',
        zIndex: 1,
    },
    philosophyText: {
        lineHeight: '43px',
    },
    philosophyImage: {
        maxWidth: '363px',
        width: '100%',
    },
    contactForm: {
        marginTop: 50,
        padding: '50px 0',
        backgroundColor: '#EDF3FF', //there are no any color like this in theme
    },
    contactFormBox: {
        width: '100%',
        maxWidth: 304,
    },
    joinButton: {
        background: 'linear-gradient(86.51deg, #544F94 13.51%, #755DD8 72.7%)',
        borderRadius: '10px',
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 500,
        width: '100%',
        margin: '20px 0',
        padding: '10px',
    },
    joinImage: {
        width: '100%',
        position: 'relative',
        left: '-20px',
        zIndex: 2,
    },
    investImage: {
        width: '100%',
        position: 'relative',
        top: '-50px',
        zIndex: 0,
    },
    blueBlockText: {
        // fontSize: '28px',
        textAlign: 'center',
    },
    blueBlockTextSmall: {
        fontSize: '22px',
    },
    logoImage: {
        width: '90px',
        height: '48.91px',
        margin: 0,
        marginTop: 30,
        marginBottom: 10,
    },
    chooseIcon: {
        height: '100px',
        marginBottom: '10px',
    },
    and: {
        color: theme.palette.common.black,
    },
    chooseContainer: {
        marginBottom: '50px',
    },
    dotedShapeImage: {
        position: 'relative',
        width: '171px',
        height: '124px',
    },
    greyBackground: {
        backgroundColor: '#f3f3f3',
        height: '100%',
        boxShadow: 'inset 0px -30px 10px #666666',
        backgroundImage: `url(${Ellipse})`,
        backgroundPosition: '50% -1450px',
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        paddingTop: '200px',
    },
    button: {
        fontSize: '22px',
        fontWeight: 400,
        margin: '40px 0 20px',
        padding: '6px 40px',
        color: '#ffffff',
    },
    textSmall: {
        fontSize: '14px',
    },
}));
