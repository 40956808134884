import { validateSync, ValidationError } from 'class-validator';

export const getValidationResults = (values) => {
    const result: object = {};
    const errors: ValidationError[] = validateSync(values);
    errors.forEach((error: ValidationError): void => {
        if (error.children.length > 0) {
            result[error.property] = [];
            error.children.forEach((child) => {
                result[error.property][child.property] = {};
                child.children.forEach((next) => {
                    result[error.property][child.property][next.property] =
                        next.constraints[Object.keys(next.constraints)[0]];
                });
            });
        } else {
            result[error.property] =
                error.constraints[Object.keys(error.constraints)[0]];
        }
    });
    return result;
};
