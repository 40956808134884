import firebase from 'firebase/app';
import { ReferenceDataInterface } from './interfaces/ReferenceDataInterface';
import { formatDateTime } from '../utils/dateTime';

export class ReferenceData implements ReferenceDataInterface {
    id: string;

    constructor({ id }: any) {
        this.id = id;
    }
}

export const refToRefData = (ref: any): ReferenceDataInterface => ({
    id: ref.id,
});

export const timestampToString = (
    timestamp: firebase.firestore.Timestamp,
): string => formatDateTime(timestamp.toDate());
