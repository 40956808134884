import {
    Box,
    Card,
    CardContent,
    Theme,
    Typography,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { FavoriteIconButton } from './FavoriteIconButton';
import { MatchPercentage } from './MatchPercentage';
import classNames from 'classnames';

import { ChildrenType, OnAnyEventHandler } from '../helpers';
import { renderChildren } from '../helpers/renderChildren';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '210px',
        minWidth: '180px',
        position: 'relative',
    },
    cardRoot: {
        height: '180px',
        padding: '10px',
        background: theme.palette.common.white,
        boxShadow: '0px 13px 16px rgba(0, 0, 0, 0.05)',
        borderRadius: '6px',
    },
    contentRoot: {
        padding: '16px 0',
    },
    avatarBox: {
        padding: '0 5px 0px',
        marginBottom: '20px',
    },
    matchPercentage: {
        position: 'relative',
        top: '3px',
        left: '15px',
    },
    favoriteButton: {
        position: 'relative',
        top: '-15px',
        textAlign: 'right',
    },
    title: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '1.75',
        color: theme.palette.secondary[650],
    },
    subTitle: {
        fontSize: '14px',
        color: '#ACACAC',
    },
}));

export interface SchoolCardProps {
    className?: string;
    avatar: ChildrenType;
    onClick?: OnAnyEventHandler;
    matchPercent?: number;
    title: string;
    subTitle?: string;
    description?: string;
    chips?: string[];
    isFavorite?: boolean;
    isAuthenticated?: boolean;
}

export const SchoolCard = React.memo(function SchoolCardComponent({
    avatar,
    className,
    onClick,
    matchPercent,
    title,
    subTitle,
    description,
    chips,
    isFavorite,
    isAuthenticated,
}: SchoolCardProps) {
    const classes = useStyles();

    return (
        <Box className={classNames(classes.root, className)} onClick={onClick}>
            <Card className={classes.cardRoot}>
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid item xs={4}>
                        {matchPercent && isAuthenticated && (
                            <MatchPercentage
                                number={matchPercent}
                                className={classes.matchPercentage}
                            />
                        )}
                    </Grid>
                    <Grid item xs={4} className={classes.avatarBox}>
                        {renderChildren(avatar)}
                    </Grid>
                    <Grid item xs={4}>
                        {/* {isFavorite !== undefined && (
                            <FavoriteIconButton
                                onClick={() => {
                                    '';
                                }}
                                isFavorite={isFavorite}
                                className={classes.favoriteButton}
                            />
                        )} */}
                    </Grid>
                </Grid>
                <CardContent className={classes.contentRoot}>
                    <Typography
                        variant="subtitle1"
                        className={classes.title}
                        align="center"
                    >
                        {title}
                    </Typography>
                    {subTitle && (
                        <Typography
                            variant="subtitle1"
                            align="center"
                            className={classes.subTitle}
                        >
                            {subTitle}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
});
