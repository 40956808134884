import size from 'lodash/size';
import { Avatar } from './Avatar';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { defaultPicture, ProjectEntity } from '@weco/core';
import { getPictureUrl } from '@weco/common';
import { Theme, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { CardFooter } from './CardFooter';
import { CardBase } from './CardBase';
import { ShareButton } from './ShareButton';
import { SpriteSvgIcon, UnauthorizedRedirectOnclick } from '../molecules';
import { GoToChatButton } from './GoToChatButton';

const useStyles = makeStyles((theme: Theme) => ({ root: {} }));

interface ProjectsListProps {
    currentUserId: string;
    items: ProjectEntity[];
    className?: string;
    skillsSection?: boolean;
    viewerProjectsIds?: string[];
}

const ProjectsList = memo((props: ProjectsListProps) => {
    const classes = useStyles();
    const history = useHistory();

    const rootClasses = classNames(
        classes.root,
        props.className,
        'ProjectsList',
    );

    return (
        <Grid
            container
            spacing={2}
            justify="space-around"
            className={rootClasses}
        >
            {props.items?.map((project) => {
                const {
                    id,
                    name,
                    owner,
                    picture,
                    dream,
                    matchPercent,
                } = project;
                const isGuestUserOrMyOwnProjet =
                    !props.currentUserId || owner.id === props.currentUserId;

                const getRoute = () => {
                    return props.currentUserId === project.owner.id
                        ? RouterPaths.myProject
                        : RouterPaths.projectPage;
                };

                return (
                    <Grid key={id} item>
                        <CardBase
                            title={name}
                            avatar={
                                <Avatar
                                    withBorder
                                    withShadow
                                    size="normal"
                                    shape="square"
                                    percentagePlacement="none"
                                    picture={getPictureUrl(
                                        picture,
                                        defaultPicture,
                                    )}
                                />
                            }
                            topRightIcon={
                                props.skillsSection ? (
                                    <UnauthorizedRedirectOnclick>
                                        <GoToChatButton
                                            projectId={id}
                                            isMember={
                                                props.viewerProjectsIds
                                                    ? props.viewerProjectsIds.includes(
                                                          id,
                                                      )
                                                    : false
                                            }
                                            person={owner}
                                        />
                                    </UnauthorizedRedirectOnclick>
                                ) : (
                                    <ShareButton
                                        iconColor="gray"
                                        picture={picture}
                                        shareId={id}
                                        type="project"
                                        copyTooltipPlacement="bottom"
                                        dropdownDirection="down"
                                    />
                                )
                            }
                            footer={
                                <CardFooter
                                    icons={[
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="card-about-cards-footer" />
                                            ),
                                            label: 'About',
                                            url: `${getRoute()}/${project.id}`,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="skill-team-card-footer" />
                                            ),
                                            label: 'Team',
                                            url: `${getRoute()}/${
                                                project.id
                                            }/team`,
                                        },
                                        {
                                            icon: (
                                                <SpriteSvgIcon id="resources-card-footer" />
                                            ),
                                            label: 'Resources',
                                            url: `${getRoute()}/${
                                                project.id
                                            }/resources`,
                                        },
                                    ]}
                                />
                            }
                            description={dream}
                            matchPercent={matchPercent}
                            isMatchPercentHidden={isGuestUserOrMyOwnProjet}
                            link={`${getRoute()}/${id}`}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
});

ProjectsList.displayName = 'ProjectsList';

export { ProjectsList };
