import React from 'react';
import { Avatar } from '@material-ui/core';
import {
    ChatAvatarProps,
    RoomAvatarPropsInterface,
} from '../models/interfaces/RoomAvatarInterface';
import {
    DEFAULT_GROUP_IMAGE,
    DEFAULT_PROFILE_IMAGE,
    ROOM_TYPE_PROJECTS,
} from '../utils/constants';
import { ReferenceDataInterface } from '../models/interfaces/ReferenceDataInterface';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { OnAvatarClickParametersInterface } from '@weco/chat';

const RoomAvatar = ({
    room,
    currentUser,
    users,
    className,
    disableIconClick,
    onAvatarClick,
}: RoomAvatarPropsInterface) => {
    const props: ChatAvatarProps = {
        alt: '',
        src: DEFAULT_PROFILE_IMAGE,
        className,
    };
    let onAvatarClickParams: OnAvatarClickParametersInterface = {
        type: RouterPaths.projectPage,
        id: room.id,
    };
    switch (room.type) {
        case ROOM_TYPE_PROJECTS:
            props.alt = room.name;
            props.src = room.image || DEFAULT_GROUP_IMAGE;
            break;
        default:
            const member: ReferenceDataInterface = room.members.find(
                (member: ReferenceDataInterface) =>
                    member.id !== currentUser.id,
            );
            if (member) {
                if (users[member.id]) {
                    props.alt = users[member.id].name;
                    props.src = users[member.id].image || DEFAULT_PROFILE_IMAGE;
                    onAvatarClickParams = {
                        type: RouterPaths.profilePage,
                        id: users[member.id].id,
                    };
                }
            }
            break;
    }
    if (!disableIconClick) {
        props['onClick'] = () => {
            onAvatarClick(onAvatarClickParams);
        };
    }

    return (
        <>
            <Avatar {...props} style={{ cursor: 'pointer' }} />
        </>
    );
};

export default RoomAvatar;
