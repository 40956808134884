import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useProjectProfileContainerStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '50vh',
        backgroundColor: theme.palette.common.white,
    },
    tabs: {
        backgroundColor: theme.palette.common.white,
    },
    tab: {
        '&.Mui-selected': {
            backgroundColor: 'rgba(200,200,200,0.35)',
        },
    },
    infoBanner: {
        width: '100%',
        background: '#F2F0FE',
        color: theme.palette.info[700],
        fontWeight: 500,
        textAlign: 'center',
        padding: '5px 15px',
    },
    infoBannerText: {
        fontWeight: 600,
    },
    viewLink: {
        textDecoration: 'underline',
        color: theme.palette.info[500],
        cursor: 'pointer',
    },
    userActionButtons: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '10px 0',
    },
}));
