import React from 'react';
import { Button, Grid, Theme, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    searchElement: {
        display: 'flex',
        width: '100%',
        paddingLeft: '20px',
        paddingRight: '20px',
        color: theme.palette.text.secondary,
    },
}));

export interface WeRecentSearchProps {
    lastSearches: string[];
    storageKey: string;
    setSearches: (arr: string[]) => void;
    handleSearch: (query: string) => void;
}

export const WeRecentSearches = ({
    lastSearches,
    setSearches,
    storageKey,
    handleSearch,
}: WeRecentSearchProps) => {
    const classes = useStyles();

    const deleteSearchItem = (index) => {
        lastSearches.splice(index, 1);
        setSearches([...lastSearches]);
        localStorage.setItem(storageKey, JSON.stringify(lastSearches));
    };

    const handleDelete = (e, index) => {
        e.stopPropagation();
        deleteSearchItem(index);
    };

    return (
        <Box mt={2}>
            {lastSearches.map((item, i) => (
                <Button
                    key={i}
                    color="secondary"
                    className={classes.searchElement}
                    onClick={() => handleSearch(item)}
                >
                    <SearchIcon />
                    <Box ml={2}>{item}</Box>
                    <CloseIcon
                        style={{ marginLeft: 'auto' }}
                        onClick={(e) => handleDelete(e, i)}
                    />
                </Button>
            ))}
        </Box>
    );
};
