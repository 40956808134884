import { RouterPaths } from './RouterPaths';

export const namedRoutes = [
    // {
    //     name: 'ProjectView',
    //     path: RouterPaths.projectPage,
    // },
    // {
    //     name: 'ProfileView',
    //     path: RouterPaths.profilePage,
    // },
    // {
    //     name: 'MyProfileView',
    //     path: RouterPaths.myProfile,
    // },
    // {
    //     name: 'MyProjectView',
    //     path: RouterPaths.myProject,
    // },
    // {
    //     name: 'SearchPeople',
    //     path: RouterPaths.search.people,
    // },
    // {
    //     name: 'SearchProject',
    //     path: RouterPaths.search.root,
    // },
    // {
    //     name: 'Chat',
    //     path: RouterPaths.chat.root,
    // },
    // {
    //     name: 'Dashboard',
    //     path: RouterPaths.dashboard,
    // },
    // {
    //     name: 'Home',
    //     path: RouterPaths.home,
    // },
    // {
    //     name: 'Settings',
    //     path: RouterPaths.settings,
    // },
    // {
    //     name: 'Signup',
    //     path: RouterPaths.signup,
    // },
    // {
    //     name: 'Signin',
    //     path: RouterPaths.login,
    // },
    // {
    //     name: 'Quiz',
    //     path: RouterPaths.quiz,
    // },
];
