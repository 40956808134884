import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OnboardingPageHeader } from '@weco/ui';
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import SignUpStep from './steps/OnboardingSignUpStep';
// import YearSelectorStep from './steps/OnboardingYearSelectorStep';
import SelectCausesStep from './steps/SelectCausesStep';
import SelectPassionsStep from './steps/SelectPassionsStep';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import debug from 'debug';
import CelebrationStep from './steps/CelebrationStep';
import { APP_EVENTS } from '../../../app_events';
import { AnalyticsEvent, useAnalytics } from '@weco/common';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';

const log = debug('OnboardingPage');

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh',
    },
}));

export interface IOnboardingPageURLParms {
    step: string;
}

export const OnboardingPage = () => {
    const cx = useStyles();

    const history = useHistory();
    const params = useParams<IOnboardingPageURLParms>();
    const currentStep = Number(params.step);

    const { event } = useAnalytics();
    const { isAuthenticated } = useCurrentAuthData();

    const onboardingSteps = [
        <SignUpStep />,
        // <YearSelectorStep />, NOTE: hidden until Dwight school collaboration will be established.
        <SelectCausesStep />,
        <SelectPassionsStep />,
        <CelebrationStep />,
    ];
    const isHeaderVisible = currentStep !== onboardingSteps.length - 1;

    log('CurrentStep is: ', currentStep);
    log('Should header be visible: ', isHeaderVisible);

    useEffect(() => {
        event(new AnalyticsEvent(APP_EVENTS.ONBOARDING_STARTED));
    }, []);

    useEffect(() => {
        if (currentStep !== 0 && !isAuthenticated) {
            history.push({
                pathname: RouterPaths.signup,
                state: { isUnauthorizedRedirect: true },
            });
        }
    }, [currentStep, isAuthenticated]);

    return (
        <Grid
            container
            direction="column"
            alignContent="center"
            justify="space-between"
            className={cx.container}
        >
            {isHeaderVisible && (
                <OnboardingPageHeader
                    isCloseButtonHidden={isAuthenticated}
                    progressPercentage={
                        // [1, 25, 50, 75]  NOTE: hidden until Dwight school collaboration will be established.
                        [1, 35, 70][currentStep]
                    }
                />
            )}
            {onboardingSteps[currentStep]}
        </Grid>
    );
};
