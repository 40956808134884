import { Box, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import { WeChipsList } from '../molecules/WeChipsList';

const useStyles = makeStyles((theme: Theme) => ({
    infoDescription: {
        fontSize: '12px',
        fontStyle: 'italic',
        color: theme.palette.text.secondary,
    },
}));

interface SearchFieldDataProps {
    values: string[];
    title?: string;
}

const SearchFieldData = ({ values, title }: SearchFieldDataProps) => {
    const classes = useStyles();
    return (
        <Box display="flex">
            {isEmpty(values) ? (
                <Typography className={classes.infoDescription}>
                    This field is empty
                </Typography>
            ) : (
                <WeChipsList items={values} chipsGroupName={title} />
            )}
        </Box>
    );
};

SearchFieldData.displayName = 'SearchFieldData';

export { SearchFieldData };
