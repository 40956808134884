export class MailChimpEvent {
    constructor(
        public listId: string,
        public subscriberHash: string,
        public type: string,
    ) {
        this.listId = listId;
        this.subscriberHash = subscriberHash;
        this.type = type;
    }
}
