import React from 'react';

interface WidgetMessageErrorPropsInterface {
    message: string;
}

const WidgetMessageError = ({ message }: WidgetMessageErrorPropsInterface) => (
    <>{message}</>
);

export default WidgetMessageError;
