import React from 'react';
import MuiAutocomplete, {
    AutocompleteProps as MuiAutocompleteProps,
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
} from '@material-ui/lab/Autocomplete';
import { FieldProps } from 'formik';
import { Value } from '@material-ui/lab/useAutocomplete';

export interface WeAutocompleteProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>
    extends FieldProps,
        Omit<
            MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
            'name' | 'value' | 'defaultValue'
        > {
    type?: string;
}

export const fieldToAutocomplete = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>({
    disabled,
    field,
    form: { isSubmitting, setFieldValue, handleSubmit },
    type,
    onChange,
    onBlur,
    ...props
}: WeAutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
>): MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> => {
    const {
        value: _value,
        onChange: _onChange,
        onBlur: _onBlur,
        multiple: _multiple,
        ...fieldSubselection
    } = field;

    return {
        ...props,
        onBlur: onBlur
            ? onBlur
            : (event: React.FocusEvent<unknown>) => {
                  field.onBlur(event && field.name);
              },
        onChange: onChange
            ? onChange
            : (
                  _event: React.ChangeEvent<{}>,
                  value: Value<T, Multiple, DisableClearable, FreeSolo>,
                  _reason: AutocompleteChangeReason,
                  _details?: AutocompleteChangeDetails<T>,
              ) => {
                  setFieldValue(field.name, value);
                  handleSubmit();
              },
        disabled: disabled || isSubmitting,
        loading: isSubmitting,
        ...fieldSubselection,
        value: props.multiple
            ? [].concat(_value || []).filter((v) => !!v)
            : _value,
    };
};

export const WeAutocomplete = <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
>(
    props: WeAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
    return <MuiAutocomplete {...fieldToAutocomplete(props)} />;
};
