import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AllDepartments } from '@weco/core';
import classNames from 'classnames';
import debug from 'debug';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import DepartmentsImageDefault from '../assets/weco-departments-navigation.svg';
import { scrollToNavTabs } from './NavTabs';
import { WecoDepartmentsNavigationIcon } from './WecoDepartmentsNavigationIcon';

const log = debug('WecoDepartmentsNavigation');
// TODO: remove this line.
debug.enable('WecoDepartmentsNavigation');

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: '0 auto',
        marginTop: '20px',
        position: 'relative',
    },
    image: {
        margin: '0 auto',
        display: 'block',
    },
    imageContainer: {
        display: 'block',
        margin: '0 auto',
    },
    iconsContainer: {
        display: 'block',
        minHeight: '100px',
        position: 'absolute',
        minWidth: '100% !important',
    },
    iconWrapper: {
        width: '50px',
        margin: 'auto',
        height: '50px',
        display: 'table',
        textAlign: 'center',
        position: 'relative',
        verticalAlign: 'middle',
    },
    invisibleElement: {
        opacity: 0,
        zIndex: -1,
    },
}));

const iconPositionStyles = makeStyles((theme: Theme) => ({
    ideas: {
        transform: 'translate(-62px, 0px)',
    },
    strategy: {
        transform: 'translate(-151px, 23px)',
    },
    marketing: {
        transform: 'translate(-87px, 40px)',
    },
    finance: {
        transform: 'translate(-12px, -56px)',
    },
    execution: {
        transform: 'translate(37px, -186px)',
    },
    itweb: {
        transform: 'translate(124px, -239px)',
    },
    legal: {
        transform: 'translate(146px, -210px)',
    },
    hr: {
        transform: 'translate(60px, -214px)',
    },
}));

export interface WecoDepartmentsNavigationProps {
    className?: string;
    allIconsAreActive?: boolean;
    matchedDepartments: string[];
    departmentToHighglight?: string;
    departmentsWithSelectedRoles?: string[];
}

const componentName = 'WecoDepartmentsNavigation';

const WecoDepartmentsNavigation = memo(
    ({ departmentToHighglight, ...props }: WecoDepartmentsNavigationProps) => {
        const classes = useStyles();
        const iconPosition = iconPositionStyles();
        const rootClasses = classNames(
            classes.root,
            props.className,
            componentName,
        );

        return (
            <div
                className={rootClasses}
                id="container-anchor-departments-navigation"
            >
                <Box>
                    <Box className={classes.iconsContainer}>
                        {AllDepartments.map((department) => {
                            const isDepartmentSelected =
                                department === departmentToHighglight;
                            const doesDepartMentHasSelectedRoles = props.departmentsWithSelectedRoles?.includes(
                                department,
                            );
                            const isDepartmentMatched = props.matchedDepartments?.includes(
                                department,
                            );

                            return (
                                <Link
                                    replace
                                    to={department}
                                    key={department}
                                    onClick={scrollToNavTabs}
                                    className={classNames(
                                        classes.iconWrapper,
                                        iconPosition[department],
                                    )}
                                >
                                    <WecoDepartmentsNavigationIcon
                                        department={department}
                                        isMatched={isDepartmentMatched}
                                        isActive={
                                            isDepartmentSelected ||
                                            props.allIconsAreActive
                                        }
                                        isSelected={
                                            !isDepartmentMatched &&
                                            doesDepartMentHasSelectedRoles
                                        }
                                    />
                                </Link>
                            );
                        })}
                    </Box>
                </Box>
                <Box className={classes.imageContainer}>
                    <img
                        alt="WeCo departments."
                        className={classes.image}
                        src={`${DepartmentsImageDefault}`}
                    />
                </Box>
            </div>
        );
    },
);

WecoDepartmentsNavigation.displayName = componentName;

export { WecoDepartmentsNavigation };
