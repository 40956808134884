import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { ProjectsMainPageStore } from './ProjectsMainPageStore';

export const useProjectData = () => {
    const store = useStore<ProjectsMainPageStore>(ProjectsMainPageStore);

    return useObserver(() => ({
        items: store.items,
        activeFilterValue: store.activeFilterValue,
        filters: store.filters,
        changeFilter: store.changeFilter,
    }));
};
