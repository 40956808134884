import React, { useContext, useEffect, useState } from 'react';
import { AsyncLoader, SkeletonAsyncLoader } from '@weco/ui';
import { StoreDataContainerProps } from './SingletonStoreContainer';
import {
    useInjection,
    ProvidersContainer,
    StoreContainerContextInterface,
    StoreContainerContext,
} from '@weco/common';

/**
 * @description You can have several store containers of that `classType` is the App.
 * All `useStore` hooks called in the child components, will be return store created via this component.
 * This Data Container Wrapper instantiate Store (inject all dependencies via di as usually) by `classType`
 * but DO NOT REGISTER THAT STORE INTO THE DIc. Then it will be tried to call `loader` callback with that store as an argument, to initialize data
 * for that store. After this Component will be removed from the DOM, Store will be cleaned with `dispose` method (if it exists).
 */
export const StoreContainer = (props: StoreDataContainerProps) => {
    return (
        <OptionalContextWrapper>
            <StoreContainerWrapper {...props} />
        </OptionalContextWrapper>
    );
};

const StoreContainerWrapper = ({
    classType,
    loader,
    children,
    errorComponent,
    loaderComponent,
    onError,
    testPages,
}: StoreDataContainerProps) => {
    // throw new Error('Not works properly, use SingletonStoreContainer');
    const di = useInjection<ProvidersContainer>();
    const context = useContext<StoreContainerContextInterface>(
        StoreContainerContext,
    );
    const stores = context?.stores;
    const [store, setStore] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        if (stores.has(classType)) {
            setStore(stores.get(classType));
            setLoading(false);
            console &&
                console.warn(
                    `Store Container for ${
                        classType.name || classType
                    } already exist somewhere above. We reuse that instance`,
                );
            return;
        }

        if (di.has(classType)) {
            throw new Error(
                `Don't register ${classType?.name} store in DI if you use 'StoreContainer'`,
            );
        }

        // call load\dispose only on first\root component

        const newStore = di.resolve(classType);
        stores.set(classType, newStore);
        setStore(newStore);
        if (loader) {
            loader(newStore)
                .then(() => setLoading(false))
                .catch((e) => setError(e));
        } else {
            setLoading(false);
        }
        return () => {
            if (newStore?.dispose) {
                newStore.dispose();
            }
            stores.delete(classType);
        };
    }, []);

    return (
        <>
            {testPages ? (
                <AsyncLoader
                    loading={loading}
                    error={error}
                    errorComponent={errorComponent}
                    onError={onError}
                >
                    {store && !loading && children}
                </AsyncLoader>
            ) : (
                <SkeletonAsyncLoader
                    loading={loading}
                    error={error}
                    errorComponent={errorComponent}
                    onError={onError}
                    skeleton={loaderComponent}
                >
                    {store && !loading && children}
                </SkeletonAsyncLoader>
            )}
        </>
    );
};

const OptionalContextWrapper = React.memo(function ({ children }) {
    const existedContext = useContext(StoreContainerContext);
    const [stores] = useState(new Map());

    if (existedContext) {
        return <>{children}</>;
    }
    return (
        <StoreContainerContext.Provider
            value={{ stores: stores }}
            children={children}
        />
    );
});
