const rgba = (r, g, b, a) => ({ r, g, b, a });

export const rgbaString = ({ r, g, b, a }) =>
    `rgba(${r}, ${g}, ${b}, ${a})!important`;

const rgbaAdd = (rgbaVal, addVal) => ({
    r: rgbaVal.r + (addVal.r || 0),
    g: rgbaVal.g + (addVal.g || 0),
    b: rgbaVal.b + (addVal.b || 0),
    a: rgbaVal.a + (addVal.a || 0),
});

export const colors = {
    ideas: rgba(221, 51, 51, 1),
    strategy: rgba(255, 152, 0, 1),
    marketing: rgba(255, 204, 2, 1),
    finance: rgba(117, 171, 55, 1),
    execution: rgba(3, 169, 244, 1),
    itweb: rgba(26, 62, 181, 1),
    legal: rgba(143, 65, 216, 1),
    hr: rgba(202, 25, 211, 1),
    desaturate: rgba(171, 171, 171, 1),
};

export const makeThemeColors = (theme) => {
    function augmentMainColor(color: any) {
        return theme.palette.augmentColor({ main: rgbaString(color) });
    }

    return {
        ideas: augmentMainColor(colors.ideas),
        strategy: augmentMainColor(colors.strategy),
        marketing: augmentMainColor(colors.marketing),
        execution: augmentMainColor(colors.execution),
        itweb: augmentMainColor(colors.itweb),
        finance: augmentMainColor(colors.finance),
        legal: augmentMainColor(colors.legal),
        hr: augmentMainColor(colors.hr),
        inactive: augmentMainColor(colors.desaturate),
    };
};

export const getThemeDepartmentColors = (theme) => ({
    ...theme,
    palette: {
        ...theme.palette,
        departments: makeThemeColors(theme),
    },
});

const makeDepartmentColors = (color, attr) => ({
    [attr]: rgbaString(color),
    '&:hover': {
        [attr]: rgbaString(rgbaAdd(color, { a: -0.3 })),
    },
    '&:active': {
        [attr]: rgbaString(rgbaAdd(color, { a: -0.3 })),
    },
    '&:focus': {
        [attr]: rgbaString(rgbaAdd(color, { a: -0.3 })),
    },
});

const makeDepartmentOutlinedColors = (color, attr) => ({
    border: `2px solid ${rgbaString(color)}`,
    [attr]: rgbaString(colors.desaturate),
});

const makeSingleDepartmentColor = (color, attr) => ({
    [attr]: rgbaString(color),
    '&:hover': {
        [attr]: rgbaString(color),
    },
    '&:active': {
        [attr]: rgbaString(color),
    },
    '&:focus': {
        [attr]: rgbaString(color),
    },
});

const makeDepartmentOutlinedColor = (color, attr) => ({
    border: `2px solid ${rgbaString(color)}`,
    [attr]: rgbaString(colors.desaturate),
});

export const DepartmentColors = ({
    variant = 'single',
    attr = 'backgroundColor',
}) =>
    variant === 'single'
        ? {
              ideas: makeSingleDepartmentColor(colors.ideas, attr),
              strategy: makeSingleDepartmentColor(colors.strategy, attr),
              marketing: makeSingleDepartmentColor(colors.marketing, attr),
              execution: makeSingleDepartmentColor(colors.execution, attr),
              itweb: makeSingleDepartmentColor(colors.itweb, attr),
              finance: makeSingleDepartmentColor(colors.finance, attr),
              legal: makeSingleDepartmentColor(colors.legal, attr),
              hr: makeSingleDepartmentColor(colors.hr, attr),
              desaturate: makeSingleDepartmentColor(colors.desaturate, attr),
          }
        : {
              ideas: makeDepartmentColors(colors.ideas, attr),
              strategy: makeDepartmentColors(colors.strategy, attr),
              marketing: makeDepartmentColors(colors.marketing, attr),
              execution: makeDepartmentColors(colors.execution, attr),
              itweb: makeDepartmentColors(colors.itweb, attr),
              finance: makeDepartmentColors(colors.finance, attr),
              legal: makeDepartmentColors(colors.legal, attr),
              hr: makeDepartmentColors(colors.hr, attr),
              desaturate: makeDepartmentColors(colors.desaturate, attr),
          };

export const DepartmentColorsOutlined = ({
    variant = 'single',
    attr = 'backgroundColor',
}) =>
    variant === 'single'
        ? {
              ideas: makeDepartmentOutlinedColor(colors.ideas, attr),
              strategy: makeDepartmentOutlinedColor(colors.strategy, attr),
              marketing: makeDepartmentOutlinedColor(colors.marketing, attr),
              execution: makeDepartmentOutlinedColor(colors.execution, attr),
              itweb: makeDepartmentOutlinedColor(colors.itweb, attr),
              finance: makeDepartmentOutlinedColor(colors.finance, attr),
              legal: makeDepartmentOutlinedColor(colors.legal, attr),
              hr: makeDepartmentOutlinedColor(colors.hr, attr),
              desaturate: makeDepartmentOutlinedColor(colors.desaturate, attr),
          }
        : {
              ideas: makeDepartmentOutlinedColors(colors.ideas, attr),
              strategy: makeDepartmentOutlinedColors(colors.strategy, attr),
              marketing: makeDepartmentOutlinedColors(colors.marketing, attr),
              execution: makeDepartmentOutlinedColors(colors.execution, attr),
              itweb: makeDepartmentOutlinedColors(colors.itweb, attr),
              finance: makeDepartmentOutlinedColors(colors.finance, attr),
              legal: makeDepartmentOutlinedColors(colors.legal, attr),
              hr: makeDepartmentOutlinedColors(colors.hr, attr),
              desaturate: makeDepartmentOutlinedColors(colors.desaturate, attr),
          };
