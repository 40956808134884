import * as actionTypes from './actionTypes';
import { UserRoomInterface } from '../../models/interfaces/UserRoomInterface';
import MessageInterface from '../../models/interfaces/MessageInterface';
import { RoomInterface } from '../../models/interfaces/RoomInterface';

export function requestRooms(userRooms: UserRoomInterface[]) {
    return {
        type: actionTypes.REQUEST_ROOMS,
        items: userRooms
            ? userRooms.map((userRoom: UserRoomInterface) => userRoom.room.id)
            : [],
    };
}

export function receiveRoom(item: RoomInterface) {
    return {
        type: actionTypes.RECEIVE_LIST_ROOM,
        item,
    };
}

export function receiveRoomDeleted(res: any) {
    return {
        type: actionTypes.RECEIVE_LIST_ROOM_DELETED,
        item: { id: res.id },
    };
}

export function receiveRooms() {
    return {
        type: actionTypes.RECEIVE_LIST_ROOMS,
    };
}

export function setActiveRoom(id: string) {
    return {
        type: actionTypes.SET_ACTIVE_ROOM,
        item: id,
    };
}

export function setTmpRoom(room: RoomInterface) {
    return {
        type: actionTypes.SET_TMP_ROOM,
        item: room,
    };
}

export function deleteTmpRoom() {
    return {
        type: actionTypes.DELETE_TMP_ROOM,
    };
}

export function startRoomCreation() {
    return {
        type: actionTypes.SET_START_ROOM_CREATION,
    };
}

export function updateRoomLastMessage(
    roomId: string,
    message: MessageInterface,
) {
    return {
        type: actionTypes.UPDATE_ROOM_LAST_MESSAGE,
        roomId,
        item: message,
    };
}

export function setFilterType(filterType: string) {
    return {
        type: actionTypes.SET_FILTER_TYPE,
        filterType,
    };
}

export function setDataLoading(loading: boolean) {
    return {
        type: actionTypes.SET_DATA_LOADING,
        dataLoading: loading,
    };
}
