import {
    BottomSheet,
    RoleCard,
    DepartmentsRouting,
    MyProjectTeamOverview,
    scrollToNavTabs,
} from '@weco/ui';
import debug from 'debug';
import { toJS } from 'mobx';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import useToggle from 'react-use/lib/useToggle';
import React, { useEffect, useState } from 'react';
import { APP_EVENTS } from '../../../../app_events';
import {
    SkillEntity,
    SkillSetsEntity,
    DepartmentEntityWithTestResultPercent,
} from '@weco/core';
import { RouterPaths } from '../../../router/RouterPaths';
import { AnalyticsEvent, useAnalytics } from '@weco/common';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import { usePersonRolesData } from '../../../store/hooks/usePersonRolesData';
import { useProjectRolesData } from '../../../store/hooks/useProjectRolesData';
import { useProjectProfileData } from '../../profile/project/store/useProjectProfileData';
import { cloneDeep } from 'lodash';

const log = debug('TeamTabPage');

export const TeamTabPage = ({ projectId }) => {
    /* ----------------------------- Hooks/services ---------------------------- */
    const history = useHistory();
    const { event } = useAnalytics();
    /* ---------------------------------- Stores. ---------------------------------- */
    const {
        project,
        team,
        invitedPersons,
        appliedPersons,
    } = useProjectProfileData(projectId);

    const myPersonRoles = usePersonRolesData();
    const {
        addRole,
        updateRole,
        deleteRole,
        defaultRoles: defaultRoles,
        allAddedRoles: allAddedRoles,
        allRoles: allRoles,
        isThereFinishedTest,
        loadAutocompleteSkillsOptions,
        ...projectRolesData
    } = useProjectRolesData(projectId);
    const { areConnectionsLoading, currentUserId, profile } = useMyUserData();
    const isDataLoading =
        areConnectionsLoading ||
        myPersonRoles.isLoadingStoreData ||
        projectRolesData.isLoadingStoreData;
    /* --------------------------------- States --------------------------------- */
    const [isBottomSheetOpen, toggleBottomSheet] = useToggle(false);
    const [selectedRole, setSelectedRole] = useState(null as SkillSetsEntity);
    const [skillsAutocompleteOptions, setSkillsAutocompleteOptions] = useState(
        [] as SkillEntity[],
    );

    const activeRoles = allAddedRoles.filter((i) => i.isSelected);
    const addedRoleNames = allAddedRoles.map((i) => i.name);
    const rolesForSuggest = defaultRoles
        .filter((i) => !addedRoleNames.includes(i.name))
        .concat(allAddedRoles.filter((i) => !i.isSelected));

    log('isDataLoading: ', isDataLoading);
    log('project: %O', toJS(project));
    log('isBottomSheetOpen: ', isBottomSheetOpen);
    log('selectedRole: %O', selectedRole);
    log('myPersonRoles: %O', toJS(myPersonRoles));
    log('projectRolesData: %O', toJS(projectRolesData));

    useEffect(() => {
        if (!isBottomSheetOpen) {
            setSkillsAutocompleteOptions([]);
        } else {
            const departmentName = selectedRole?.department?.name;
            loadAutocompleteSkillsOptions(departmentName).then(
                setSkillsAutocompleteOptions,
            );
        }
    }, [isBottomSheetOpen]);

    function showRoleEditingPanel(skill: SkillSetsEntity) {
        console.log('onSkillSetItemClick()');

        setSelectedRole(skill);
        toggleBottomSheet(true);
    }

    function update(data: SkillSetsEntity, bottomSheetShouldClose?: boolean) {
        log('updatePosition() is called: %O', data);
        log('shouldCloseBottomSheet: ', bottomSheetShouldClose);
        event(
            new AnalyticsEvent(APP_EVENTS.USER_PROFILE_POSITION_SAVED, {
                department: data.department.name,
            }),
        );
        if (data.id) {
            updateRole(data);
        } else {
            addRole(data).then((i) => (data.id = i.id));
        }
        if (bottomSheetShouldClose) {
            toggleBottomSheet();
        }
    }

    function add(department: DepartmentEntityWithTestResultPercent) {
        const newRole = new SkillSetsEntity();
        newRole.name = 'New Role';
        newRole.projectId = projectId;
        newRole.department = department;
        newRole.departmentId = department.id;

        showRoleEditingPanel(newRole);
    }

    function toggleRoleVisibility(skill: SkillSetsEntity) {
        log('Toggling position visibility.');
        const item = skill.isDefault ? cloneDeep(skill) : skill;
        item.isSelected = !item.isSelected;
        updateRole(item);
    }

    function goToSearchPage() {
        history.push(RouterPaths.search.people);
    }

    return (
        <Box>
            <DepartmentsRouting
                profile={profile}
                areRolesEditable
                onAddRole={add}
                roles={activeRoles}
                rolesForSuggest={rolesForSuggest}
                typeofDescriptionToUse="project"
                initialPage={
                    <MyProjectTeamOverview
                        isLoading={isDataLoading}
                        project={project}
                        team={team || []}
                        roles={activeRoles}
                        currentUserId={currentUserId}
                        actionButtonLabel="Find your team"
                        onActionButtonClick={goToSearchPage}
                        onCheckBoxClick={toggleRoleVisibility}
                        onEditButtonClick={showRoleEditingPanel}
                        peopleAppliedToProject={appliedPersons}
                        peopleInvitedToProject={invitedPersons}
                        matchSkillSetsWith={[]}
                        onDeleteBtnClick={deleteRole}
                    />
                }
                onVisibilitySwitch={toggleRoleVisibility}
                departments={projectRolesData.departments}
                onEditRoleButtonClick={showRoleEditingPanel}
                onDeleteBtnClick={deleteRole}
            />
            <BottomSheet isOpen={isBottomSheetOpen} onClose={toggleBottomSheet}>
                <RoleCard
                    onSave={update}
                    data={selectedRole}
                    autocompleteOptions={skillsAutocompleteOptions}
                    onDeleteBtnClick={() => {
                        deleteRole(selectedRole);
                        toggleBottomSheet(false);
                    }}
                />
            </BottomSheet>
        </Box>
    );
};
