import React from 'react';
import {
    KeyboardTimePicker as MuiKeyboardTimePicker,
    KeyboardTimePickerProps as MuiKeyboardTimePickerProps,
} from '@material-ui/pickers';
import { FieldProps, getIn } from 'formik';
import { createErrorHandler } from './errorHandler';

export interface WeKeyboardTimePickerProps
    extends FieldProps,
        Omit<MuiKeyboardTimePickerProps, 'name' | 'value' | 'error'> {}

export const fieldToKeyboardTimePicker = ({
    disabled,
    field,
    form: { isSubmitting, touched, errors, setFieldValue, setFieldError },
    ...props
}: WeKeyboardTimePickerProps): MuiKeyboardTimePickerProps => {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled != undefined ? disabled : isSubmitting,
        onChange(date) {
            setFieldValue(field.name, date);
        },
        onError: createErrorHandler(fieldError, field.name, setFieldError),
    };
};

export const WeKeyboardTimePicker = ({
    children,
    ...props
}: WeKeyboardTimePickerProps) => {
    return (
        <MuiKeyboardTimePicker {...fieldToKeyboardTimePicker(props)}>
            {children}
        </MuiKeyboardTimePicker>
    );
};
