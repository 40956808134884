const allObjectives = [
    'Friends',
    'Partners',
    'Investors',
    'Clients',
    'Employees',
    'Openings (aka Job)',
    'Mentors',
    'Globetrotters',
];

export default allObjectives;
