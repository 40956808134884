import { UserProfileEntity } from './UserProfileEntity';
import { SkillEntity } from './SkillEntity';
import { SkillSetsEntity } from './SkillSetsEntity';

export class TestResultEntity {
    id?: string;
    type: 'DEPARTMENTS_SCORE' | 'ANOTHER';
    answers: TestAnswerEntity[][];
    ownerId: string;
    owner?: UserProfileEntity;
    results: ResultEntity[];
    status: 'IN_PROGRESS' | 'FINISHED';
    //finishedAt?: Date;
    createdAt?: Date;
    updatedAt?: Date;
}

export class TestAnswerEntity {
    questionId: number;
    question?: string;
    option: number;
}

export class ResultEntity {
    department: string;
    defaultPositions: SkillSetsEntity[];
    points: number;
}
