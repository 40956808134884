import Toolbar from '@material-ui/core/Toolbar';
import { ChatInformerContainer } from '@weco/chat';
import {
    Avatar,
    CompareLocationContainer,
    NotificationsIndicator,
    SpriteSvgIcon,
    TopBarIcon,
} from '@weco/ui';
import React from 'react';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../../../router/RouterPaths';
import { useChatData } from '../../../../store/hooks/useChatData';
import { useCurrentAuthData } from '../../../../store/hooks/useCurrentAuthData';
import { useMyUserData } from '../../../../store/hooks/useMyUserData';
import { TopBarButton } from '../../../molecules/TopBarButton';
import { useToolbarContentStyles } from './styles/ToolbarContent.styles';
import { Box, Button } from '@material-ui/core';
import { environment } from '../../../../../environments/environment';

export interface ToolbarContentProps {
    handleDrawerLeftOpen: () => void;
}

const ToolbarContent = ({ handleDrawerLeftOpen }: ToolbarContentProps) => {
    const { push } = useHistory();
    const classes = useToolbarContentStyles();
    const { profile } = useMyUserData();
    const { chatUser, firestore } = useChatData();
    const { isAuthenticated } = useCurrentAuthData();

    function goTo(path) {
        return () => push(path);
    }

    const goToLanding = () => (window.location.href = environment.landingPage);

    return (
        <Toolbar className={classes.toolbar}>
            <TopBarButton
                selected
                icon="/assets/images/nav-ico.svg"
                onClick={() =>
                    isAuthenticated
                        ? goTo(RouterPaths.dashboard)()
                        : goToLanding()
                }
            />
            {/* NOTE: fast solution for empty place in flex list */}
            <div />
            {isAuthenticated ? (
                <>
                    <ChatInformerContainer
                        firestore={firestore}
                        chatUserId={chatUser?.user?.uid}
                    >
                        {(props: any) => (
                            <CompareLocationContainer
                                route={RouterPaths.chat.root}
                            >
                                {({ isMatch }) => (
                                    <NotificationsIndicator
                                        isChecked={isMatch}
                                        unreadCount={props.unreadCount}
                                        onClick={goTo(RouterPaths.chat.root)}
                                    />
                                )}
                            </CompareLocationContainer>
                        )}
                    </ChatInformerContainer>
                    <Box id="container-anchor-topbar-search">
                        <TopBarIcon
                            isSvg
                            onClick={goTo(RouterPaths.search.root)}
                        >
                            <SpriteSvgIcon id="MainMenu-Search" />
                        </TopBarIcon>
                    </Box>
                    <Box id="container-anchor-topbar-profile">
                        <Avatar
                            size="mini"
                            shape="round"
                            addUniqueVersionIdToURL
                            percentagePlacement="none"
                            picture={profile?.picture}
                            onClick={() => {
                                goTo(RouterPaths.me);
                                handleDrawerLeftOpen();
                            }}
                        />
                    </Box>
                </>
            ) : (
                <Box id="container-anchor-topbar-search" display="flex">
                    <TopBarIcon isSvg onClick={goTo(RouterPaths.search.root)}>
                        <SpriteSvgIcon id="MainMenu-Search" />
                    </TopBarIcon>

                    <Button
                        className={classes.toolbarButton}
                        onClick={goTo(RouterPaths.login)}
                    >
                        Login
                    </Button>
                    <Button
                        className={classes.toolbarJoinAction}
                        onClick={goTo(RouterPaths.signup)}
                    >
                        Join!
                    </Button>
                </Box>
            )}
        </Toolbar>
    );
};

export default ToolbarContent;
