import { _value } from '../helpers/utils';
import _ from 'lodash';

import { InvalidConfigurationException } from '../exceptions/exceptions';

export class ConfigService {
    constructor(private config: any) {}

    get<T = any>(name: string, defaultValue?: string): T {
        // if (!this.has(name) && !defaultValue) {
        //     throw new InvalidConfigurationException(
        //         `Unable to find configuration for "${name}"`,
        //     );
        // }
        return (_value(this.config, name, defaultValue) as unknown) as T;
    }

    has(name): boolean {
        return _value(this.config, name, null) !== null;
    }

    add(name, config): boolean {
        if (this.has(name)) {
            throw new InvalidConfigurationException(
                `Configuration "${name}" already exist`,
            );
        }
        return _.set(this.config, name, config);
    }
}
