import React, { memo } from 'react';

export interface HiddenProps {
    when: unknown;
    children: JSX.Element | string;
}

const Hidden = memo((props: HiddenProps) => {
    return props.when ? null : (props.children as JSX.Element);
});

Hidden.displayName = 'Hidden';

export { Hidden };
