import { Box, Grid, IconButton, Theme, Typography } from '@material-ui/core';
import React, { useState, memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { ReactHelpers } from '../helpers';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: 16,
    },
    title: {
        padding: '5px 0',
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'capitalize',
    },
    editButton: {
        marginLeft: 'auto',
    },
    editIcon: {
        fontSize: '18px',
        color: '#ddd',
    },
    content: {
        borderBottom: '1px solid #EAEAEA',
        paddingBottom: 16,
    },
}));

interface SearchFieldViewOrEditProps {
    field?: string;
    title: string;
    children: any;
}

const SearchFieldViewOrEdit = memo(
    ({ field, title, children }: SearchFieldViewOrEditProps) => {
        const classes = useStyles();
        const [editing, setEditing] = useState(false);

        const handleEdit = (): void => {
            setEditing(true);
        };

        const handleCancel = (): void => {
            setEditing(false);
        };

        return (
            <Grid
                item
                xs={12}
                className={classes.root}
                id={`container-anchor-advanced-search-${field}`}
                onClick={!editing ? handleEdit : () => {}}
            >
                <Grid item xs={12}>
                    <Box display="flex">
                        <Typography color="primary" className={classes.title}>
                            {title}
                        </Typography>
                        <IconButton
                            className={classes.editButton}
                            onClick={editing ? handleCancel : () => {}}
                        >
                            {!editing ? (
                                <EditIcon className={classes.editIcon} />
                            ) : (
                                <CloseIcon className={classes.editIcon} />
                            )}
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.content}>
                    {ReactHelpers.renderChildren(children, {
                        editing,
                        handleCancel,
                    })}
                </Grid>
            </Grid>
        );
    },
);

SearchFieldViewOrEdit.displayName = 'SearchFieldViewOrEdit';

export { SearchFieldViewOrEdit };
