import React from 'react';
import { Box, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useSplashScreenLoaderStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fdfdfd',
        // backgroundColor: theme.palette.primary.main,
        width: '100vw',
        height: '100vh',
    },
    center: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
    },
    infinityLoaderStyle: {
        position: 'fixed',
        top: '150px',
    },
    logoFull: {
        width: '100%',
    },
}));
export const SplashScreenLoader = () => {
    const classes = useSplashScreenLoaderStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.center}>
                <CardMedia
                    src="/assets/weco-loader.gif"
                    component="img"
                    className={classes.logoFull}
                />
            </Box>
            <Box className={classes.infinityLoaderStyle}>
                {/*<InfinityLoader hideBackdrop />  NOTE: hidden for now but maybe we will use this again later.*/}
                {/*<CircleLoader hideBackdrop />*/}
            </Box>
        </Box>
    );
};
