import React from 'react';
import {
    PersonProfileStore,
    PersonProfileStoreInterface,
} from './PersonProfileStore';
import { SingletonStoreContainer } from 'apps/client/src/app/store/containers/SingletonStoreContainer';
import { RouterPaths } from '../../../../router/RouterPaths';
import { useHistory } from 'react-router';

export interface PersonProfileContainerProps {
    children: any;
    itemId: string;
}

export const PersonProfileContainer = ({
    children,
    itemId,
}: PersonProfileContainerProps) => {
    const history = useHistory();
    const loader = async (store: PersonProfileStoreInterface) => {
        const item = await store.loadItem(itemId, () =>
            history.push({
                pathname: `${RouterPaths.notFound}`,
                state: 'Profile',
            }),
        );
        // await store.loadMatchPercent();
        return item;
    };
    return (
        <SingletonStoreContainer classType={PersonProfileStore} loader={loader}>
            {children}
        </SingletonStoreContainer>
    );
};
