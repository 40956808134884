export const chatGetToken = /* GraphQL */ `
    mutation ChatGetToken {
        chatGetToken
    }
`;

export const chatCreateRoom = /* GraphQL */ `
    mutation ChatCreateRoom($input: ChatCreateRoomInput) {
        chatCreateRoom(input: $input)
    }
`;

export const chatAddUserToRoom = /* GraphQL */ `
    mutation ChatAddUserToRoom($input: ChatAddUserToRoomInput) {
        chatAddUserToRoom(input: $input)
    }
`;
