import classNames from 'classnames';
import React, { memo } from 'react';
import { BottomSheet } from './BottomSheet';
import useToggle from 'react-use/lib/useToggle';
import { BigNumberCard } from './BigNumberCard';
import { PositionsList } from './PositionsList';
import { makeStyles } from '@material-ui/styles';
import { ProjectEntity, SkillSetsEntity } from '@weco/core';
import { Box, Grid, Theme, Typography } from '@material-ui/core';
import { ProjectsListCompressed } from './ProjectsListCompressed';

// TODO RENAME THIS COMPONENT.

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    bottomSheetTitle: { fontWeight: 300, margin: '20px 30px' },
}));

export interface TeamProjectDashboardProps {
    currentUserId: string;
    positions: SkillSetsEntity[];
    projects: ProjectEntity[];
    matchSkillSetsWith: string[];
    className?: string;
}

const TeamProjectDashboard = memo((props: TeamProjectDashboardProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    const [isProjectsPanelOpen, toggleProjectsPanel] = useToggle(false);
    const [isPositionsPanelOpen, togglePositionsPanel] = useToggle(false);

    const numberOfProjects = props.projects?.length || 0;
    const numberOfPositions = props.positions?.length || 0;

    return (
        <Box className={rootClasses}>
            <Grid container spacing={6} justify="center">
                <Grid item>
                    <BigNumberCard
                        title="Roles"
                        number={numberOfPositions}
                        onClick={togglePositionsPanel}
                    />
                </Grid>
                <Grid item>
                    <BigNumberCard
                        title="Projects"
                        number={numberOfProjects}
                        onClick={toggleProjectsPanel}
                    />
                </Grid>
            </Grid>

            <BottomSheet
                title="Roles"
                isOpen={isPositionsPanelOpen}
                onClose={togglePositionsPanel}
            >
                <PositionsList
                    items={props.positions}
                    matchSkillSetsWith={props.matchSkillSetsWith}
                />
            </BottomSheet>
            <BottomSheet
                title="Projects"
                isOpen={isProjectsPanelOpen}
                onClose={toggleProjectsPanel}
            >
                <Box>
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.bottomSheetTitle}
                    >
                        Created
                    </Typography>
                    <ProjectsListCompressed
                        projects={props.projects}
                        currentUserId={props.currentUserId}
                    />
                    <Typography
                        variant="h3"
                        color="secondary"
                        className={classes.bottomSheetTitle}
                    >
                        Joined
                    </Typography>
                    <ProjectsListCompressed
                        projects={props.projects}
                        currentUserId={props.currentUserId}
                    />
                </Box>
            </BottomSheet>
        </Box>
    );
});

TeamProjectDashboard.displayName = 'TeamProjectDashboard';

export { TeamProjectDashboard };
