import React, { useRef, useState } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditorControls from './EditorControls';
import UploadButton from './UploadButton';
import { Storage } from '@aws-amplify/storage';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { generatePictureData, squeezeImage } from './utils';
import { useMyProjectData } from '../../../pages/me/store/useMyProjectData';

const useStyles = makeStyles((theme: Theme) => ({
    fileBox: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: '#333333d6',
        zIndex: 1000,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileBoxInner: {
        backgroundColor: '#fff',
        borderRadius: 5,
        padding: 20,
    },
    imageButton: {
        color: '#ffffff',
        padding: '8px',
        cursor: 'pointer',
    },
}));

const UploadImage = ({ type }) => {
    let editableData = null;
    let updateData = null;

    if (type === 'project') {
        const { item, updateImg } = useMyProjectData();
        editableData = item;
        updateData = updateImg;
    } else {
        const { profile, updateImg } = useMyUserProfileData();
        editableData = profile;
        updateData = updateImg;
    }

    const classes = useStyles();
    const [file, setFile] = useState(null);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [rotate, setRotate] = useState(0);
    const [scale, setScale] = useState(1);
    const [loader, setLoader] = useState(false);
    const [width] = useState(1100);
    const [height] = useState(1200);
    let imageEditor = useRef(null);

    const handleScaleChange = (event, newValue) => {
        setScale(newValue);
    };

    const handlePositionChange = (newValue) => {
        setPosition(newValue);
    };

    const handleCancel = () => {
        setFile(null);
        setPosition({ x: 0.5, y: 0.5 });
        setRotate(0);
        setScale(1);
    };

    const handlePreviewImage = (event) => {
        if (imageEditor) {
            setLoader(true);
            // @ts-ignore
            const canvas = imageEditor?.getImageScaledToCanvas()?.toDataURL();
            fetch(canvas)
                .then((res) => res.blob())
                .then(async (blob) => {
                    await handleSaveAvatar(blob);
                    await setLoader(false);
                    handleCancel();
                });
        }
    };

    const handleRotateChange = (event) => {
        setRotate(rotate + 90);
    };

    const handleInputChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
    };

    const setEditorRef = (editor) => {
        imageEditor = editor;
    };

    const handleSaveAvatar = async (imageFile) => {
        const key =
            type === 'person'
                ? 'assets/avatar.png'
                : `project/${editableData.id}/assets/avatar.png`;
        const pictureData = await generatePictureData(key);
        const squeezedFile = await squeezeImage(imageFile);

        await Storage.put(key, squeezedFile, {
            level: 'protected',
            cacheControl: 'public, max-age=86400',
        });

        await updateData({ ...editableData, picture: pictureData });
    };

    return (
        <>
            <UploadButton handleInputChange={handleInputChange} />
            {file && (
                <Box className={classes.fileBox}>
                    <Box className={classes.fileBoxInner}>
                        <>
                            <AvatarEditor
                                ref={setEditorRef}
                                style={{
                                    cursor: 'grab',
                                    width: 290,
                                    height: 310,
                                }}
                                image={file}
                                width={width}
                                height={height}
                                border={0}
                                borderRadius={0}
                                color={[0, 0, 0, 0]} // RGBA
                                scale={scale}
                                rotate={rotate}
                                position={position}
                                onPositionChange={handlePositionChange}
                            />
                            {loader ? (
                                <Box>
                                    <CircularProgress color="secondary" />
                                </Box>
                            ) : (
                                <EditorControls
                                    scale={scale}
                                    handleCancel={handleCancel}
                                    handleRotateChange={handleRotateChange}
                                    handlePreviewImage={handlePreviewImage}
                                    handleScaleChange={handleScaleChange}
                                    type={type}
                                />
                            )}
                        </>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default UploadImage;
