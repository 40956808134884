import { AuthProviderInterface } from '../../common';
import { DummyUserToken } from './DummyUserToken';
import { AnonymousUserToken } from '../../AnonymousUserToken';
import { DummyUsernamePasswordToken } from './DummyUsernamePasswordToken';
import { InvalidCredentialsException } from '../../../exceptions/exceptions';

const STORAGE_KEY = 'DUMMY_AUTH_PROVIDER';
export class DummyAuthProvider implements AuthProviderInterface {
    constructor(
        public usersList: { username: string; password: string }[],
        private storage: Storage = localStorage,
    ) {}

    authenticate(token): Promise<DummyUserToken | AnonymousUserToken> {
        if (token instanceof DummyUserToken) {
            return Promise.resolve(token);
        }
        if (token instanceof DummyUsernamePasswordToken) {
            return new Promise<DummyUserToken | AnonymousUserToken>(
                (resolve, reject) => {
                    setTimeout(() => {
                        if (
                            this.usersList.find(
                                (creds) =>
                                    creds.username === token.username &&
                                    creds.password === token.password,
                            )
                        ) {
                            const userData = {
                                username: token.username,
                                roles: ['ADMIN', 'USER'],
                                payload: {
                                    signedId: new Date().toLocaleDateString(),
                                },
                            };
                            this.storage.setItem(
                                STORAGE_KEY,
                                JSON.stringify(userData),
                            );
                            resolve(new DummyUserToken(userData));
                        } else {
                            this.storage.removeItem(STORAGE_KEY);
                            reject(
                                new InvalidCredentialsException(
                                    `Invalid username or password`,
                                ),
                            );
                        }
                    }, 3000);
                },
            );
        }
    }

    logout(token?): Promise<AnonymousUserToken> {
        this.storage.removeItem(STORAGE_KEY);
        return Promise.resolve(new AnonymousUserToken());
    }

    restore(): Promise<DummyUserToken | null> {
        const userData = JSON.parse(this.storage.getItem(STORAGE_KEY));
        if (!userData) {
            return Promise.reject(null);
        }
        return Promise.resolve(new DummyUserToken(userData));
    }

    support(token) {
        return (
            token instanceof DummyUsernamePasswordToken ||
            token instanceof DummyUserToken
        );
    }
}
