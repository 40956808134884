import { makeStyles, SvgIcon, Theme, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Picture, ProjectEntity } from '@weco/core';
import { useMyUserData } from 'apps/client/src/app/store/hooks/useMyUserData';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import delay from 'lodash/delay';
import React, { memo, ReactElement, useState } from 'react';
import {
    ChildButton,
    FloatingMenu,
    MainButton,
} from 'react-floating-button-menu';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import useToggle from 'react-use/lib/useToggle';
import { ReactComponent as SharePrimaryIcon } from '../assets/icons/share-primary.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';
import { getSocialNetworkIds } from '../helpers/getSocialNetworkIds';
import { Directions } from 'react-floating-button-menu';

const useStyles = makeStyles(({ palette }: Theme) => ({
    icon: {
        color: palette.common.white,
    },
    unoutlined: {
        outline: 'none',
    },
    copyButton: {
        backgroundColor: palette.common.white,
        padding: 8,
    },
    iconLeft: {
        marginLeft: '0',
        //.kKezsa - class name from external library applied for shared buttons on open state.
        // '& .kKezsa': {
        // zIndex: 14,
        // },
        '& ul li': {
            zIndex: 1,
        },
    },
    buttonWrapperRoot: {
        position: 'relative',
        width: 40,
        height: 40,
    },
    directionUp: {
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    directionDown: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
}));

type IconColor = 'primary' | 'white' | 'gray';

export interface ShareButtonProps {
    type: string;
    shareId: string;
    className?: string;
    own?: boolean;
    picture: Picture | string;
    iconColor?: IconColor;
    dropdownDirection?: Directions | string;
    copyTooltipPlacement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    /**
     * TODO: remove this.
     * @deprecated
     */
    activeProject?: ProjectEntity;
}

function getIconColor({ color, theme }: { color: IconColor; theme: Theme }) {
    switch (color) {
        case 'white':
            return theme.palette.common.white;
        case 'gray':
            return theme.palette.text.secondary;
        default:
            return theme.palette.primary.main;
    }
}

const ShareButton = memo(
    ({
        type,
        shareId,
        picture,
        own,
        iconColor,
        dropdownDirection = Directions.Up,
        copyTooltipPlacement,
        ...props
    }: ShareButtonProps) => {
        const theme = useTheme();
        const classes = useStyles();
        const color = getIconColor({ color: iconColor, theme });
        const rootClasses = classNames(classes.iconLeft, props.className);

        const { activeProject } = useMyUserData();

        const [isExpanded, toggleIsExpanded] = useToggle(false);
        const [openCopyTooltip, setOpenCopyTooltip] = useState(false);

        const shareUrl = `${window.location.origin}/profile/${type}/${shareId}`;

        const shareStubs = {
            person: {
                own:
                    'I have created my profile on WeCo. If someone is looking for a great teammate, we may be a match. Check it out.',
                other:
                    'I thought you may be interested in this person as a teammate',
            },
            project: {
                own: `I am building a team for my project - ${activeProject?.name}. Check it out and forward to someone who may be interested.`,
                other: 'I thought you may be interested in this project',
            },
        };

        const getShareStub = (type, isOwn) => {
            return type ? shareStubs[type][isOwn ? 'own' : 'other'] : ' ';
        };

        const toggleDropdownList = (event) => {
            event.preventDefault();
            event.stopPropagation();
            toggleIsExpanded();
        };

        const handleTooltipClose = () => {
            setOpenCopyTooltip(false);
        };

        const onCopy = () => {
            copy(shareUrl);
            setOpenCopyTooltip(true);
            delay(() => setOpenCopyTooltip(false), 500);
        };
        const MainButtonTSErrorTypeFixComponent = (props:any) => (<MainButton {...props}/>);
        return (
            <Box className={classes.buttonWrapperRoot}>
                <Box
                    className={
                        dropdownDirection === 'up'
                            ? classes.directionUp
                            : classes.directionDown
                    }
                >
                    <Box className={rootClasses}>
                        <FloatingMenu
                            spacing={8}
                            slideSpeed={500}
                            isOpen={isExpanded}
                            direction={dropdownDirection as any}
                        >
                            <MainButtonTSErrorTypeFixComponent
                                iconResting={
                                    <IconButton style={{ color }}>
                                        <SvgIcon>
                                            {!iconColor ||
                                            iconColor === 'primary' ? (
                                                <SharePrimaryIcon />
                                            ) : (
                                                <ShareIcon />
                                            )}
                                            {/*
                                                I can't find another way to dynamically change the color of these icons,
                                                these icons have a static attribute stroke="#color" which I cannot change in react,
                                                if You know another way please let me know
                                            */}
                                        </SvgIcon>
                                    </IconButton>
                                }
                                iconActive={
                                    <IconButton style={{ color }}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                                background={theme.palette.common.white}
                                color={theme.palette.common.white}
                                style={{ boxShadow: 'none' }}
                                onClick={toggleDropdownList}
                                size={40}
                            />
                            <ChildButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                icon={
                                    <Tooltip
                                        placement={
                                            copyTooltipPlacement || 'right'
                                        }
                                        onClose={handleTooltipClose}
                                        open={openCopyTooltip}
                                        title="Copied"
                                    >
                                        <IconButton
                                            className={classes.copyButton}
                                            onClick={onCopy}
                                        >
                                            <FileCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                size={40}
                            />
                            <ChildButton
                                onClick={(e) => e.stopPropagation()}
                                icon={
                                    <WhatsappShareButton
                                        className={classes.unoutlined}
                                        url={shareUrl}
                                        title={getShareStub(type, own)}
                                        separator={' '}
                                    >
                                        <WhatsappIcon
                                            id={getSocialNetworkIds({
                                                type,
                                                networkName: 'WHATSAPP',
                                                own,
                                                activeProjectId:
                                                    activeProject?.id,
                                                shareId,
                                            })}
                                            size={40}
                                            round
                                        />
                                    </WhatsappShareButton>
                                }
                                size={35}
                            />
                            <ChildButton
                                onClick={(e) => e.stopPropagation()}
                                icon={
                                    <EmailShareButton
                                        className={classes.unoutlined}
                                        url={shareUrl}
                                        title="WeCo"
                                        body={getShareStub(type, own)}
                                        separator={' '}
                                    >
                                        <EmailIcon
                                            id={getSocialNetworkIds({
                                                type,
                                                networkName: 'EMAIL',
                                                own,
                                                activeProjectId:
                                                    activeProject?.id,
                                                shareId,
                                            })}
                                            size={40}
                                            round
                                        />
                                    </EmailShareButton>
                                }
                                size={35}
                            />
                            <ChildButton
                                onClick={(e) => e.stopPropagation()}
                                icon={
                                    <TwitterShareButton
                                        className={classes.unoutlined}
                                        url={shareUrl}
                                        title={getShareStub(type, own)}
                                        // hashtags={['1', '21111']} // open and replace content if hashtags needed
                                    >
                                        <TwitterIcon
                                            id={getSocialNetworkIds({
                                                type,
                                                networkName: 'TWITTER',
                                                own,
                                                activeProjectId:
                                                    activeProject?.id,
                                                shareId,
                                            })}
                                            size={40}
                                            round
                                        />
                                    </TwitterShareButton>
                                }
                                size={35}
                            />
                            <ChildButton
                                onClick={(e) => e.stopPropagation()}
                                icon={
                                    <TelegramShareButton
                                        className={classes.unoutlined}
                                        url={shareUrl}
                                        title={getShareStub(type, own)}
                                        //hashtag={'#hashtag_test'} // open and replace content if hashtag needed
                                    >
                                        <TelegramIcon
                                            id={getSocialNetworkIds({
                                                type,
                                                networkName: 'TELEGRAM',
                                                own,
                                                activeProjectId:
                                                    activeProject?.id,
                                                shareId,
                                            })}
                                            size={40}
                                            round
                                        />
                                    </TelegramShareButton>
                                }
                                size={35}
                            />
                            <ChildButton
                                onClick={(e) => e.stopPropagation()}
                                icon={
                                    <FacebookShareButton
                                        className={classes.unoutlined}
                                        url={shareUrl}
                                        quote={getShareStub(type, own)}
                                        //hashtag={'#hashtag_test'} // open and replace content if hashtag needed
                                    >
                                        <FacebookIcon
                                            id={getSocialNetworkIds({
                                                type,
                                                networkName: 'FACEBOOK',
                                                own,
                                                activeProjectId:
                                                    activeProject?.id,
                                                shareId,
                                            })}
                                            size={40}
                                            round
                                        />
                                    </FacebookShareButton>
                                }
                                size={35}
                            />
                        </FloatingMenu>
                    </Box>
                </Box>
            </Box>
        );
    },
);

ShareButton.displayName = 'ShareButton';

export { ShareButton };
