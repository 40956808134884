import { action, computed, observable, reaction } from 'mobx';
import {
    Auth,
    COMMON_SERVICES,
    CoreEventDispatcher,
    Inject,
    Injectable,
    NoticeServiceInterface,
} from '@weco/common';
import { APP_EVENTS } from '../../app_events';
import { AuthEvent } from '../events/AuthEvent';
import { UserTokenInterface } from '../../../../../libs/common/src/lib/auth';

const SOCIAL_SIGNUP_LSTORAGE_KEY = 'wecoSignupSocial';

@Injectable()
export class AppStore {
    @Inject(Auth.AuthService)
    authService: Auth.AuthService;
    @Inject(COMMON_SERVICES.NoticeStore)
    private notice: NoticeServiceInterface;

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    private eventDispatcher: CoreEventDispatcher;

    constructor() {
        reaction(
            () => this.token,
            async (token) => {
                this.eventDispatcher.dispatch(
                    APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
                    new AuthEvent(token),
                );
            },
        );
    }

    @computed
    get currentUser(): any {
        return this.token.getUser();
    }

    // featureStore: FeatureStore;
    @observable token: Auth.UserTokenInterface = new Auth.AnonymousUserToken();

    @action bootstrap(): Promise<any> {
        return this.restoreUser();
    }

    @action.bound authenticate(token): Promise<any> {
        return this.authService
            .authenticate(token)
            .then((token: Auth.UserTokenInterface) => {
                this.token = token;
                return this.token;
            })
            .then((token) =>
                this.eventDispatcher.dispatch(
                    APP_EVENTS.AUTH_USER_AUTHENTICATED,
                    new AuthEvent(token),
                ),
            )
            .catch((e) => {
                console.error(e);
                return Promise.reject(e);
            });
    }

    @action.bound restoreUser(): Promise<UserTokenInterface> {
        return this.authService
            .restore()
            .then((token) => {
                this.token = token;
                const signupSocial = localStorage.getItem(
                    SOCIAL_SIGNUP_LSTORAGE_KEY,
                );
                if (signupSocial) {
                    localStorage.removeItem(SOCIAL_SIGNUP_LSTORAGE_KEY);
                    this.eventDispatcher.dispatch(
                        APP_EVENTS.AUTH_SIGNUP,
                        new AuthEvent(token),
                    );
                }
                return this.token;
            })
            .catch((token) => {
                this.token = token;
                return Promise.resolve(token);
            });
    }

    @action.bound signout(token?: Auth.UserTokenInterface): Promise<any> {
        return this.authService
            .logout(token)
            .then((token) => (this.token = token));
    }

    @action.bound signup(token?: Auth.UserTokenInterface): Promise<any> {
        if (token instanceof Auth.AmplifyFederatedSignInToken) {
            localStorage.setItem(SOCIAL_SIGNUP_LSTORAGE_KEY, token.provider);
            return this.authenticate(token);
        }
        return this.authService
            .signup(token)
            .then((t) => {
                return (this.token = t);
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject(error);
            });
    }

    @action.bound resendSignUp(token: Auth.UserTokenInterface): Promise<any> {
        return this.authService.resendSignUp(token).catch((error) => {
            console.error(error);
            return Promise.reject(error);
        });
    }

    @action.bound forgotPassword(token: Auth.UserTokenInterface): Promise<any> {
        return this.authService.forgotPassword(token).catch((error) => {
            console.error(error);
            return Promise.reject(error);
        });
    }

    @action.bound forgotPasswordSubmit(
        token: Auth.UserTokenInterface,
    ): Promise<any> {
        return this.authService.forgotPasswordSubmit(token).catch((error) => {
            console.error(error);
            return Promise.reject(error);
        });
    }
}
