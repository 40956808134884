import debug from 'debug';
import {
    Box,
    Fab,
    Grid,
    Theme,
    Divider,
    GridProps,
    Typography,
} from '@material-ui/core';
import size from 'lodash/size';
import classNames from 'classnames';
import React, { memo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import useToggle from 'react-use/lib/useToggle';
import { makeStyles } from '@material-ui/styles';
import { ProjectEntity, SkillSetsEntity } from '@weco/core';
import { BottomSheet } from './BottomSheet';
import { ActionButton } from './ActionButton';
import { CircleLoader } from './CircleLoader';
import { BigNumberCard } from './BigNumberCard';
import { AnimatedArrowsWithAdditionalText } from './AnimatedArrowsWithAdditionalText';
import AddProjectDialogView from '../../../../../apps/client/src/app/components/myProjects/addProjectDialog';
import { PositionsListSeparatedByDepartments } from './PositionsListSeparatedByDepartments';
import { UnauthorizedRedirectOnclick } from '../molecules/UnauthorizedRedirectOnclick';
import { EmptyRolesRouting } from './EmptyRolesRouting';
import { ProjectsList } from './ProjectsList';
import { WeMultilineText } from '../molecules';
import { AnimatedArrowsPointingToSomething } from './AnimatedArrowsPointingToSomething';

const componentName = 'UserSkillsOverview';
const log = debug(componentName);

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    infoFab: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.info.main,
    },
    noProjectsText: {
        fontWeight: 400,
    },
    title: {
        color: '#6C6C6C',
        fontSize: '21px',
        lineHeight: '24px',
        marginTop: theme.spacing(3),
    },
    actionButton: {
        position: 'fixed',
        bottom: '20px',
        width: '250px',
        zIndex: 9,
        left: '50%',
        transform: 'translate(-50%, 0)',
    },
    connectButtonStickyBox: {
        width: '100%',
        '& button': {
            [theme.breakpoints.only('md')]: {
                left: '-200px',
            },
            position: 'fixed',
            bottom: '20px',
        },
    },
    cardBox: {
        minWidth: '150px',
    },
}));

export interface UserSkillsOverviewProps {
    userName: string;
    isLoading: boolean;
    className?: string;
    currentUserId: string;
    actionButtonLabel?: string;
    roles: SkillSetsEntity[];
    arePositionsEditable?: boolean;
    joinedProjects: ProjectEntity[];
    createdProjects: ProjectEntity[];
    isThisMyProfileOverview: boolean;
    projectsIAppliedTo?: ProjectEntity[];
    matchSkillSetsWith: string[];
    projectsIamInvitedTo?: ProjectEntity[];
    onActionButtonClick?: () => void;
    onAddProject?: (projectName: string) => void;
    onCheckBoxClick?: (position: SkillSetsEntity) => void;
    onEditButtonClick?: (position: SkillSetsEntity) => void;
    onDeleteBtnClick?: (position: SkillSetsEntity) => void;
    customActionButton?: JSX.Element;
    // NOTE: this is going to be removed when we find out how to use stores in storybook.
    openChat?: (params: {
        projectId: string;
        projectOwnerId: string;
    }) => Promise<void>;
    activeProject?: ProjectEntity;
    viewerProjectsIds?: string[];
}

const numberCardGridSize = {
    xs: 6,
    md: 6,
    item: true,
} as GridProps;

const UserSkillsOverview = memo((props: UserSkillsOverviewProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    const numberOfSelectedRoles = size(props.roles.filter((i) => i.isSelected));
    const numberOfRoles = size(props.roles);
    const numberOfCreatedProjects = size(props.createdProjects);
    const numberOfJoinedProjects = size(props.joinedProjects);
    const numberOfProjects = numberOfJoinedProjects + numberOfCreatedProjects;
    const numberOfInvitedProjects = size(props.projectsIamInvitedTo);
    const numberOfAppliedProjects = size(props.projectsIAppliedTo);

    const [isProjectsPanelOpen, toggleProjectsPanel] = useToggle(false);
    const [isRolesPanelOpen, toggleRolesPanel] = useToggle(false);
    const [isAppliedUsersPanelOpen, toggleAppliedPeoplePanel] = useToggle(
        false,
    );
    const [isInvitedPeoplePanelOpen, toggleInvitedPeoplePanel] = useToggle(
        false,
    );
    const [isBusy, toggleBusyState] = useToggle(false);
    const [isAddProjectDialogOpen, toggleAddProjectDialog] = useToggle(false);

    const renderActionButton = () => (
        <>
            {props.customActionButton ? (
                <Box className={classes.connectButtonStickyBox}>
                    {props.customActionButton}
                </Box>
            ) : (
                <>
                    <Box m={2} />
                    {props.actionButtonLabel && props.onActionButtonClick && (
                        <ActionButton
                            className={classes.actionButton}
                            label={props.actionButtonLabel}
                            onClick={() => {
                                props.onActionButtonClick();
                                toggleAppliedPeoplePanel();
                            }}
                        />
                    )}
                </>
            )}
        </>
    );

    log('isBusy: ', isBusy);
    log('isProjectsPanelOpen: ', isProjectsPanelOpen);
    log('isRolesPanelOpen: ', isRolesPanelOpen);

    return (
        <Box className={rootClasses}>
            <Grid container spacing={3} justify="center">
                <Grid
                    {...numberCardGridSize}
                    className={classes.cardBox}
                    id="container-anchor-roles-card"
                >
                    <BigNumberCard
                        title={'My Roles'}
                        number={numberOfSelectedRoles}
                        isLoading={props.isLoading}
                        onClick={toggleRolesPanel}
                        isThisMyEntity={props.isThisMyProfileOverview}
                    />
                </Grid>
                <Grid
                    {...numberCardGridSize}
                    className={classes.cardBox}
                    id="container-anchor-projects-card"
                >
                    <BigNumberCard
                        title="Projects"
                        number={numberOfProjects}
                        isLoading={props.isLoading}
                        onClick={toggleProjectsPanel}
                        isThisMyEntity={props.isThisMyProfileOverview}
                    />
                </Grid>
                {props.isThisMyProfileOverview && (
                    <Grid
                        {...numberCardGridSize}
                        className={classes.cardBox}
                        id="container-anchor-applied-card"
                    >
                        <BigNumberCard
                            title="Applied"
                            subtitle="Find other projects"
                            isLoading={props.isLoading}
                            number={numberOfAppliedProjects}
                            onClick={toggleAppliedPeoplePanel}
                        />
                    </Grid>
                )}
                {props.isThisMyProfileOverview && (
                    <Grid
                        {...numberCardGridSize}
                        className={classes.cardBox}
                        id="container-anchor-invited-card"
                    >
                        <BigNumberCard
                            title="Invited"
                            subtitle="Find other projects"
                            isLoading={props.isLoading}
                            number={numberOfInvitedProjects}
                            onClick={toggleInvitedPeoplePanel}
                        />
                    </Grid>
                )}
            </Grid>
            <>
                <BottomSheet
                    title={`${
                        props.isThisMyProfileOverview ? 'Your' : ''
                    } Roles`}
                    isOpen={isRolesPanelOpen}
                    onClose={toggleRolesPanel}
                >
                    <Box>
                        {numberOfRoles > 0 &&
                            (numberOfSelectedRoles === 0 &&
                            props.isThisMyProfileOverview ? (
                                <EmptyRolesRouting />
                            ) : (
                                <PositionsListSeparatedByDepartments
                                    items={props.roles}
                                    onVisibilitySwitch={props.onCheckBoxClick}
                                    onEditButtonClick={props.onEditButtonClick}
                                    matchSkillSetsWith={
                                        props.matchSkillSetsWith
                                    }
                                    isCheckBoxDisplayed={
                                        props.arePositionsEditable
                                    }
                                    isEditButtonDisplayed={
                                        props.arePositionsEditable
                                    }
                                    onDeleteBtnClick={props.onDeleteBtnClick}
                                />
                            ))}
                        {numberOfRoles <= 0 && (
                            <Box>
                                <Typography
                                    align="center"
                                    variant="body1"
                                    color="textSecondary"
                                    component="div"
                                >
                                    {props.isThisMyProfileOverview ? (
                                        <EmptyRolesRouting />
                                    ) : (
                                        <div>
                                            {props.userName} has no roles yet.
                                        </div>
                                    )}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </BottomSheet>
                <BottomSheet
                    title="Projects"
                    isOpen={isProjectsPanelOpen}
                    onClose={toggleProjectsPanel}
                >
                    <Box>
                        {numberOfProjects > 0 ? (
                            <>
                                {numberOfCreatedProjects > 0 && (
                                    <Box>
                                        <Typography className={classes.title}>
                                            Created
                                        </Typography>
                                        <ProjectsList
                                            items={props.createdProjects}
                                            currentUserId={props.currentUserId}
                                            viewerProjectsIds={
                                                props.viewerProjectsIds
                                            }
                                            skillsSection
                                        />
                                    </Box>
                                )}
                                {numberOfJoinedProjects > 0 && (
                                    <Box>
                                        <Typography className={classes.title}>
                                            Joined
                                        </Typography>
                                        <ProjectsList
                                            items={props.joinedProjects}
                                            currentUserId={props.currentUserId}
                                            viewerProjectsIds={
                                                props.viewerProjectsIds
                                            }
                                            skillsSection
                                        />
                                    </Box>
                                )}
                                {renderActionButton()}
                            </>
                        ) : (
                            <>
                                {/* maybe we need to separate this component for my profile and other user profile? what do you think? */}
                                {props.isThisMyProfileOverview ? (
                                    <Box marginBottom={15}>
                                        <Box textAlign="center">
                                            <Typography
                                                variant="h2"
                                                color="secondary"
                                                className={
                                                    classes.noProjectsText
                                                }
                                            >
                                                Create
                                            </Typography>
                                            <Box my={3}>
                                                <Fab
                                                    className={classes.infoFab}
                                                    onClick={() => {
                                                        toggleAddProjectDialog();
                                                    }}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Box>
                                            <Box my={5} width="80%" mx="auto">
                                                <Divider />
                                            </Box>
                                            <Typography
                                                variant="h2"
                                                color="secondary"
                                                className={
                                                    classes.noProjectsText
                                                }
                                            >
                                                Search Projects
                                            </Typography>
                                            {props.onActionButtonClick && (
                                                <Box my={3}>
                                                    <Fab
                                                        className={
                                                            classes.infoFab
                                                        }
                                                        onClick={() => {
                                                            props.onActionButtonClick();
                                                            toggleProjectsPanel();
                                                        }}
                                                    >
                                                        <SearchIcon />
                                                    </Fab>
                                                </Box>
                                            )}
                                        </Box>
                                        <AddProjectDialogView
                                            open={isAddProjectDialogOpen}
                                            actions={{
                                                onClose: () => {
                                                    toggleAddProjectDialog(
                                                        false,
                                                    );
                                                },
                                                onAdd: (
                                                    projectName: string,
                                                ) => {
                                                    toggleBusyState(true);
                                                    toggleAddProjectDialog(
                                                        false,
                                                    );
                                                    props.onAddProject(
                                                        projectName,
                                                    );
                                                },
                                            }}
                                        />
                                        {isBusy && <CircleLoader />}
                                    </Box>
                                ) : (
                                    <>
                                        <AnimatedArrowsWithAdditionalText
                                            text={`${props.userName} hasn't joined
                                            any project. \nInvite him(her) to your project.`}
                                        />
                                        <UnauthorizedRedirectOnclick>
                                            {(!props.currentUserId ||
                                                props.activeProject) && (
                                                <>{renderActionButton()}</>
                                            )}
                                        </UnauthorizedRedirectOnclick>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </BottomSheet>
                <BottomSheet
                    title="Applied"
                    isOpen={isAppliedUsersPanelOpen}
                    onClose={toggleAppliedPeoplePanel}
                >
                    <Box>
                        {numberOfAppliedProjects > 0 ? (
                            <ProjectsList
                                items={props.projectsIAppliedTo}
                                currentUserId={props.currentUserId}
                                viewerProjectsIds={props.viewerProjectsIds}
                                skillsSection
                            />
                        ) : (
                            <>
                                <Typography
                                    align="center"
                                    variant="body1"
                                    color="textSecondary"
                                >
                                    <WeMultilineText
                                        text={
                                            "You haven't applied to any Project. \nSearch for your right match."
                                        }
                                    />
                                </Typography>
                                <AnimatedArrowsPointingToSomething />
                            </>
                        )}
                        {renderActionButton()}
                    </Box>
                </BottomSheet>
                <BottomSheet
                    title="Invited"
                    isOpen={isInvitedPeoplePanelOpen}
                    onClose={toggleInvitedPeoplePanel}
                >
                    <Box>
                        {numberOfInvitedProjects > 0 ? (
                            <ProjectsList
                                items={props.projectsIamInvitedTo}
                                currentUserId={props.currentUserId}
                                viewerProjectsIds={props.viewerProjectsIds}
                                skillsSection
                            />
                        ) : (
                            <>
                                <Typography
                                    align="center"
                                    variant="body1"
                                    color="textSecondary"
                                >
                                    <WeMultilineText
                                        text={
                                            "You haven't been invited to any projects. \nSearch for your right match."
                                        }
                                    />
                                </Typography>
                                <AnimatedArrowsPointingToSomething />
                            </>
                        )}
                        {renderActionButton()}
                    </Box>
                </BottomSheet>
            </>
        </Box>
    );
});

UserSkillsOverview.displayName = componentName;

export { UserSkillsOverview };
