import classNames from 'classnames';
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Theme, Grid, IconButton } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    isTransparent: {
        opacity: 0,
    },
}));

export interface NavigationArrowsProps {
    className?: string;
    isLeftArrowHidden?: boolean;
    onLeftArrowClick: () => void;
    onRightArrowClick: () => void;
    middleComponent?: JSX.Element;
}

const NavigationArrows = memo((props: NavigationArrowsProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return (
        <Grid container className={rootClasses} justify="space-between">
            <Grid
                className={classNames({
                    [classes.isTransparent]: props.isLeftArrowHidden,
                })}
                id="container-anchor-left-arrow"
            >
                <IconButton onClick={props.onLeftArrowClick}>
                    <ArrowBackIcon color="primary" />
                </IconButton>
            </Grid>
            <Grid>{props.middleComponent}</Grid>
            <Grid id="container-anchor-right-arrow">
                <IconButton onClick={props.onRightArrowClick}>
                    <ArrowForwardIcon color="primary" />
                </IconButton>
            </Grid>
        </Grid>
    );
});

NavigationArrows.displayName = 'NavigationArrows';

export { NavigationArrows };
