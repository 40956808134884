import debug from 'debug';
import { Subscription } from 'rxjs';
import { EventEmitter } from './EventEmitter';
import { EventSubscriberInterface } from './EventSubscriberInterface';

const log = debug('EventDispatcher');

export class EventDispatcher {
    private subscribers: Map<string | RegExp, EventEmitter<any>> = new Map<
        string | RegExp,
        EventEmitter<any>
    >();

    dispatch(name: string, event: any) {
        log(name);
        log(event);
        this.getSubscribersForEvent(name).forEach((item) => {
            if (item.selector === name) {
                item.subscriber.emit(event);
            } else {
                item.subscriber.emit({ event, name });
            }
        });
    }

    addSubscriber(subscriber: EventSubscriberInterface): Subscription[] {
        const subscriptions: Subscription[] = [];

        subscriber.subscribedEvents?.forEach(
            (callbackOrListenerName, eventName: string | RegExp) => {
                let listener = callbackOrListenerName;
                if (typeof callbackOrListenerName === 'string') {
                    listener = (subscriber as any)[callbackOrListenerName]
                        ? (...args: any[]) => {
                              return (subscriber[
                                  callbackOrListenerName
                              ] as any).apply(subscriber, args);
                          }
                        : null;
                }
                if (typeof listener !== 'function') {
                    throw Error(
                        `Unable to subscribe to ${eventName} because listener is not defined`,
                    );
                }
                subscriptions.push(this.addListener(eventName, listener));
            },
        );

        return subscriptions;
    }

    addListener(
        eventName: string | RegExp,
        listener: (...args) => void,
        emitWithLastValue = false,
    ): Subscription {
        if (!listener || !(listener instanceof Function)) {
            throw Error(
                `Unable to subscribe to ${eventName} because provided event listener is not supported`,
            );
        }
        if (!this.subscribers.has(eventName)) {
            this.subscribers.set(eventName, new EventEmitter(true));
        }

        const subscription = this.subscribers
            .get(eventName)
            .subscribe(listener);
        if (emitWithLastValue && this.subscribers.get(eventName).value) {
            listener(this.subscribers.get(eventName).value);
        }
        return subscription;
    }

    private getSubscribersForEvent(
        name: string,
    ): { selector: string | RegExp; subscriber: EventEmitter<any> }[] {
        const subscribers = [];
        if (this.subscribers.has(name)) {
            subscribers.push({
                selector: name,
                subscriber: this.subscribers.get(name),
            });
        }
        this.subscribers.forEach((subscriber, key) => {
            if (key instanceof RegExp) {
                if (key.test(name)) {
                    subscribers.push({ selector: key, subscriber });
                }
            }
        });

        return subscribers;
    }
}
