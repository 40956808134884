import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Avatar, ReactHelpers } from '@weco/ui';
import React from 'react';
import { useMyUserProfileData } from '../../../../store/hooks/useMyUserProfileData';
import UploadImage from '../UploadImage';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        maxHeight: '120px',
    },
    avatarBox: {
        position: 'relative',
    },
    uploadImageBox: {
        top: 0,
        left: '93px',
        position: 'absolute',
    },
}));

const EditableAvatarContainer = ({ picture, children }) => {
    const classes = useStyles();
    const { profile } = useMyUserProfileData();

    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.avatarBox}>
                    <Avatar
                        withBorder
                        withShadow
                        size="large"
                        shape="round"
                        addUniqueVersionIdToURL
                        percentagePlacement="none"
                        picture={profile?.picture}
                    />
                    <Box className={classes.uploadImageBox}>
                        <UploadImage type="person" />
                    </Box>
                </Box>
            </Box>
            {ReactHelpers.renderChildren(children)}
        </>
    );
};

export default EditableAvatarContainer;
