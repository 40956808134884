import { Box } from '@material-ui/core';
import { ProjectEntity } from '@weco/core';
import {
    DepartmentsRouting,
    InviteCollaborationActions,
    UnauthorizedRedirectOnclick,
    UserSkillsOverview,
} from '@weco/ui';
import debug from 'debug';
import filter from 'lodash/filter';
import React from 'react';
import { MyUserData, useMyUserData } from '../../../store/hooks/useMyUserData';
import { usePersonRolesData } from '../../../store/hooks/usePersonRolesData';
import {
    PersonProfileData,
    usePersonProfileData,
    usePersonProjectsData,
} from './store/usePersonProfileData';
import { useCurrentAuthData } from '../../../store/hooks/useCurrentAuthData';

const log = debug('SkillsTabPage');

const InviteCollaborationActionButton = () => {
    const store: PersonProfileData = usePersonProfileData();
    const { activeProject }: MyUserData = useMyUserData();
    const { isAuthenticated } = useCurrentAuthData();
    return (
        <UnauthorizedRedirectOnclick>
            <InviteCollaborationActions
                setPersonProjectConnection={store.setPersonProjectConnection}
                personProjectConnection={store.personProjectConnection}
                inviteToActiveProject={store.inviteToActiveProject}
                declineApplication={store.declineApplication}
                acceptApplication={store.acceptApplication}
                removeProjectMember={store.removeProjectMember}
                recallInvitation={store.recallInvitation}
                isApplicant={store.isApplicant()}
                isMember={store.isMember()}
                ableToInvite={store.ableToInvite()}
                invited={store.invited()}
                isLoadingStoreData={store.loading}
                activeProject={activeProject || new ProjectEntity()}
                isAuthenticated={isAuthenticated}
            />
        </UnauthorizedRedirectOnclick>
    );
};

export const SkillsTabPage = ({ baseUrl, personId }) => {
    const viewer = useMyUserData();
    const {
        projects,
        loading: arePersonsProjectsLoading,
    } = usePersonProjectsData();
    const createdProjects = projects.filter((i) => i.owner.id === personId);
    const joinedProjects = projects.filter((i) => i.owner.id !== personId);
    const viewerProjectsIds = viewer.userConnections
        .filter(
            (connection) =>
                connection.roles.includes('owner') ||
                connection.roles.includes('member'),
        )
        .map((connection) => connection.projectId);
    const personRolesStore = usePersonRolesData();
    const selectedRoles = filter(personRolesStore?.allAddedRoles, 'isSelected');
    const { profile, loading } = usePersonProfileData();
    const isDataBeingLoaded =
        loading ||
        arePersonsProjectsLoading ||
        personRolesStore.isLoadingStoreData;

    log('projects: %O', projects);
    log('person profile: %O', profile);
    log('currentUser: %O', viewer);
    log('isDataBeingLoaded: ', isDataBeingLoaded);

    return (
        <Box>
            <DepartmentsRouting
                profile={profile}
                areRolesEditable={false}
                typeofDescriptionToUse="person"
                initialPage={
                    <UserSkillsOverview
                        userName={profile?.name}
                        joinedProjects={joinedProjects}
                        createdProjects={createdProjects}
                        isLoading={isDataBeingLoaded}
                        isThisMyProfileOverview={false}
                        currentUserId={viewer.currentUserId}
                        activeProject={viewer.activeProject}
                        viewerProjectsIds={viewerProjectsIds}
                        roles={selectedRoles}
                        customActionButton={<InviteCollaborationActionButton />}
                        matchSkillSetsWith={viewer?.activeProject?.skills}
                    />
                }
                roles={selectedRoles}
                departments={personRolesStore.departments}
                matchSkillSetsWith={viewer?.activeProject?.skills}
            />
        </Box>
    );
};
