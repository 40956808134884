import React, { memo, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    text: {
        fontSize: '38px',
        lineHeight: '43px',
        textAlign: 'center',
        letterSpacing: '-0.61px',
        fontWeight: 400,
    },
}));

const title: FunctionComponent<{ children: string }> = (props) => {
    const cx = useStyles();
    return <div className={cx.text}>{props.children}</div>;
};

export const OnboardingPageTitle = memo(title);
