import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import { Avatar } from '@weco/ui';
import { LeftSidebarMenuList } from '../components/LeftSidebarMenuList';
import {
    useLeftSideBarListStyles,
    useLeftSideBarProjectListStyles,
    useLeftSideBarStyles,
} from './styles/LeftSideBar.styles';
import { useMyUserProfileData } from '../../../../store/hooks/useMyUserProfileData';
import MyProjectsView from '../../../myProjects/MyProjectsView';
import { MePage } from '../../../../pages/me/MePage';

const LeftSideBar = () => {
    const history = useHistory();

    const classes = useLeftSideBarStyles();
    const listClasses = useLeftSideBarListStyles();
    const projectListClasses = useLeftSideBarProjectListStyles();

    const { profile, name, lastName } = useMyUserProfileData();

    function goTo(path) {
        return () => history.push(path);
    }

    return (
        <Box className={classes.root} id="container-anchor-left-sidebar">
            <Grid container id="container-anchor-left-sidebar-header">
                <Grid
                    item
                    xs={12}
                    container
                    justify="center"
                    alignItems="center"
                >
                    <Avatar
                        withBorder
                        size="large"
                        shape="round"
                        addUniqueVersionIdToURL
                        percentagePlacement="none"
                        picture={profile?.picture}
                        onClick={goTo(MePage.URL)}
                    />
                </Grid>
                <Grid
                    xs={12}
                    item
                    container
                    justify="center"
                    alignItems="center"
                >
                    <Typography
                        variant="h2"
                        className={classes.name}
                        onClick={goTo(MePage.URL)}
                    >
                        {name} {lastName}
                    </Typography>
                </Grid>
            </Grid>
            <div
                className={projectListClasses.expansionRoot}
                id="container-anchor-projects-list"
            >
                <MyProjectsView
                    classes={{ ...projectListClasses, ...listClasses }}
                />
            </div>
            <LeftSidebarMenuList classes={listClasses} />
        </Box>
    );
};

export default LeftSideBar;
