import { useContext } from 'react';
import { _value, isClass } from '../../helpers';
import { RuntimeException } from '../../exceptions/exceptions';
import { AppContext, AppContextInterface } from '../AppContext';
import { StoreContainerContext } from '../StoreContainerContext';

export function useStore<T = any>(identifier?: any): T {
    const context = useContext(AppContext);
    const isStorybookEnvironment = process.env.STORYBOOK_IS_RUNNING === 'true';

    // To avoid using DI system in storybook to prevent
    // "Unable to locate RootStore" errors.
    // NOTE: to use store in storybook,
    //  return mock data in hooks instead of store data.
    if (isStorybookEnvironment) {
        return {} as T;
    }
    if (!context || !context.rootStore) {
        throw new RuntimeException(`Unable to locate RootStore`);
    }
    if (!identifier) {
        return context.rootStore as any;
    }
    // if (Array.isArray(identifier)) {
    //     const result = identifier.map((identityOrPath) => (instanciate(context, identityOrPath)));
    //     const errorIndex = result.findIndex(v => v === null);
    //     if (errorIndex !== -1) {
    //         throw new RuntimeException(`Unable to located requested store by "${identifier[errorIndex]}" path`);
    //     }
    //     return result;
    // }

    const instance = __instanciate(context, identifier);
    if (instance) {
        return instance;
    }
    throw new RuntimeException(
        `Unable to locate requested store by "${identifier}"`,
    );
}

function __instanciate(context: AppContextInterface, identityOrPath) {
    const storeContext = useContext(StoreContainerContext);

    if (typeof identityOrPath === 'string') {
        return _value(
            context.rootStore,
            identityOrPath,
            _value(context, identityOrPath, null),
        );
    }
    if (storeContext?.stores?.has(identityOrPath)) {
        return (
            storeContext.stores.get(identityOrPath).store ||
            storeContext.stores.get(identityOrPath)
        );
    }
    if (
        (isClass(identityOrPath) || typeof identityOrPath === 'symbol') &&
        context.ioc.has(identityOrPath)
    ) {
        return context.ioc.get(identityOrPath);
    }
    if (!isClass(identityOrPath) && typeof identityOrPath === 'function') {
        return identityOrPath(context.rootStore);
    }
    return null;
}
