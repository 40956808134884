import React from 'react';
import { UserRoomInterface } from '../models/interfaces/UserRoomInterface';
import { useEffect } from 'react';
import { ReactHelpers } from '@weco/ui';

const ChatInformer = (props) => {
    const {
        chatUserId,
        fetchCurrentUser,
        receiveUserCredentials,
        firestore,
        children,
    } = props;
    useEffect(() => {
        if (chatUserId) {
            fetchCurrentUser(firestore, chatUserId);
            receiveUserCredentials(chatUserId, null);
        }
    }, [chatUserId]);

    const getUnreadCount = (): number => {
        let unreadCount = 0;
        if (props?.user?.rooms?.length) {
            props.user.rooms.forEach((userRoom: UserRoomInterface) => {
                unreadCount += userRoom.unreadCount;
            });
        }
        return unreadCount === 0 ? null : unreadCount;
    };
    return (
        <>
            {ReactHelpers.renderChildren(children, {
                unreadCount: getUnreadCount(),
                ...props,
            })}
        </>
    );
};

export default ChatInformer;
