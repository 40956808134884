import React, { createRef } from 'react';
import { Field, Formik } from 'formik';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { WeTextField } from '@weco/ui';
import { aboutPageStyles } from '../styles/AboutPage.styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCurrentAuthData } from '../../../store/hooks/useCurrentAuthData';
import { environment } from '../../../../environments/environment';

interface FormData {
    name: string;
    email: string;
    phone: string;
    message: string;
    recaptcha?: string;
}

export const KnowMoreForm = () => {
    const classes = aboutPageStyles();
    const { isAuthenticated } = useCurrentAuthData();
    const theme = useTheme();
    const recaptchaRef: any = createRef();

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                phone: '',
                message: '',
                recaptcha: '',
            }}
            validate={({ name, email, phone, message, recaptcha }) => {
                const errors: Partial<{
                    name: string;
                    email: string;
                    phone: string;
                    message: string;
                    recaptcha: string;
                }> = {};
                if (!name.trim()) errors.name = 'Required';
                if (!email.trim()) errors.email = 'Required';
                if (!phone.trim()) errors.phone = 'Required';
                if (!message.trim()) errors.message = 'Required';
                return errors;
            }}
            onSubmit={async (values) => {
                if (!isAuthenticated) {
                    const captchaToken = await recaptchaRef.current.executeAsync();
                    if (!captchaToken.trim()) {
                        return;
                    }
                    values.recaptcha = captchaToken;
                }

                console.log(values, 'values');
            }}
        >
            {({ submitForm, errors }) => (
                <>
                    <Field
                        style={{
                            backgroundColor: theme.palette.common.white,
                        }}
                        name="name"
                        component={WeTextField}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.disableOutline,
                            },
                        }}
                        margin="dense"
                        label="Name"
                        fullWidth
                    />
                    <Field
                        style={{
                            backgroundColor: theme.palette.common.white,
                        }}
                        name="email"
                        component={WeTextField}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.disableOutline,
                            },
                        }}
                        margin="dense"
                        label="Email"
                        fullWidth
                    />
                    <Field
                        style={{
                            backgroundColor: theme.palette.common.white,
                        }}
                        name="phone"
                        component={WeTextField}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.disableOutline,
                            },
                        }}
                        variant="outlined"
                        margin="dense"
                        label="Phone"
                        fullWidth
                    />
                    <Field
                        style={{
                            backgroundColor: theme.palette.common.white,
                        }}
                        name="message"
                        component={WeTextField}
                        multiline
                        rows={4}
                        variant="outlined"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.disableOutline,
                            },
                        }}
                        margin="dense"
                        label="Message"
                        fullWidth
                    />

                    {!isAuthenticated && (
                        <Box>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={environment.recaptcha.siteKey}
                            />

                            {errors.recaptcha && (
                                <Typography
                                    style={{ paddingLeft: 15 }}
                                    variant="body2"
                                    color="error"
                                >
                                    {errors.recaptcha}
                                </Typography>
                            )}
                        </Box>
                    )}

                    <Button onClick={submitForm} className={classes.joinButton}>
                        Send
                    </Button>
                </>
            )}
        </Formik>
    );
};
