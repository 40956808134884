import { TransformationType } from 'class-transformer/TransformOperationExecutor';
import { TransformableInterface } from './common';

export type PropertyTransformerCallback = (data: any, obj?: any) => any;

export class ClassPropertyTransformer implements TransformableInterface {
    constructor(
        private toClass: PropertyTransformerCallback,
        private toPlain: PropertyTransformerCallback,
    ) {}

    transform = (data: any, obj: any, type: TransformationType): any => {
        return type === TransformationType.CLASS_TO_PLAIN
            ? this.toPlain(data, obj)
            : this.toClass(data, obj);
    };
}
