import { Backdrop, Box, Theme } from '@material-ui/core';
import MuiTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import isBoolean from 'lodash/isBoolean';
import React, { memo, ReactElement } from 'react';
import useToggle from 'react-use/lib/useToggle';

const StyledTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        padding: '20px',
        fontWeight: 400,
        fontSize: '14px',
        borderRadius: '2px',
        textAlign: 'center',
        boxShadow: theme.shadows[5],
        color: theme.palette.text.hint,
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            maxWidth: '300px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px',
        },
    },
    arrow: {
        color: theme.palette.common.white,
    },
}))(MuiTooltip);

const StyledBackdrop = withStyles((theme: Theme) => ({
    root: {
        zIndex: 9,
    },
}))(Backdrop);

export interface TooltipProps {
    isOpen?: boolean;
    children: ReactElement;
    text: ReactElement | string;
}

const Tooltip = memo((props: TooltipProps) => {
    const [isOpenState, toggleOpen] = useToggle(Boolean(props.isOpen));
    const isOpen = isBoolean(props.isOpen) ? props.isOpen : isOpenState;

    return (
        <Box onClick={toggleOpen}>
            <StyledBackdrop open={isOpen} />
            <StyledTooltip arrow open={isOpen} title={props.text} interactive>
                {props.children}
            </StyledTooltip>
        </Box>
    );
});

Tooltip.displayName = 'Tooltip';

export { Tooltip };
