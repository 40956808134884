import { ProjectEntity } from './ProjectEntity';
import { UserProfileEntity } from './UserProfileEntity';
import { Type } from 'class-transformer';

export class PersonProjectConnection {
    personId: string;
    projectId: string;
    // TODO add roles enum.
    roles: string[];
    @Type(() => UserProfileEntity)
    person: UserProfileEntity;
    @Type(() => ProjectEntity)
    project: ProjectEntity;
    skillSets?: { roleId: string; status: string };

    get status(): 'APPLICANT' | 'MEMBER' | 'INVITEE' | 'OWNER' | undefined {
        const roleIs = (role: string) => this.roles.includes(role);

        if (roleIs('owner')) return 'OWNER';
        if (roleIs('invitee')) return 'INVITEE';
        if (roleIs('applicant')) return 'APPLICANT';
        if (roleIs('member') && !roleIs('owner')) {
            return 'MEMBER';
        }
        return undefined;
    }
}
