import {
    Box,
    CircularProgress,
    IconButton,
    Theme,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ForumIcon from '@material-ui/icons/Forum';
import { useHistory } from 'react-router';
import { ROOM_TYPE_PROJECTS } from '@weco/chat';
import classNames from 'classnames';
import {
    Avatar,
    ShareButton,
    AvatarLoadingAnimation,
    UnauthorizedRedirectOnclick,
    SpriteSvgIcon,
} from '@weco/ui';
import { useToggle } from 'react-use';
import { current } from '@reduxjs/toolkit';
import { RouterPaths } from '../../../router/RouterPaths';
import { useProjectProfileData } from './store/useProjectProfileData';
import { useControlPanelStyles } from '../../../components/molecules/ProjectProfilePage/styles/ControlPanel.styles';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import { openChat } from '../../../helpers/utils';
import { useChatData } from '../../../store/hooks/useChatData';
import ProjectHeaderBackground from '../../../../assets/images/profile-project/ProjectHeader.png';
import { useCoolStyles } from '../../../components/molecules/ProjectProfilePage/styles/CoolStyles';
import { useCoolData } from '../../../store/hooks/useCoolData';
import { UsersCoolDrawer } from '../../../components/molecules/UsersCoolDrawer';

const useStyles = makeStyles((theme: Theme) => ({
    profileHeader: {
        background: `url(${ProjectHeaderBackground}) no-repeat center top / cover`,
    },
}));

interface UserProjectHeaderProps {
    isAuthenticated?: boolean;
}

export const UserProjectHeader = (props: UserProjectHeaderProps) => {
    const classes = useStyles();
    const coolClasses = useCoolStyles();
    const history = useHistory();
    const { createRoom } = useChatData();
    const controlPanelClasses = useControlPanelStyles();
    const {
        project: { id, picture, name, owner, settings },
        isMember,
        loading,
        matchPercent,
        getProjectPicture,
    } = useProjectProfileData();
    const { activeProject, currentUserId } = useMyUserData();
    const { cools } = useCoolData(id, 'Project');

    const [chatLoading, setChatLoading] = useState(false);
    const [coolPanelOpen, setCoolPanelOpen] = useToggle(false);

    if (currentUserId === owner.id) {
        history.push(`${RouterPaths.myProject}/${id}`);
    }

    const openChatHandler = () => {
        if (isMember()) {
            history.push(
                `${RouterPaths.chat.root}/${ROOM_TYPE_PROJECTS}/${id}`,
            );
        } else {
            setChatLoading(true);
            openChat({
                createRoom,
                history,
                id: owner.id,
                currentUserId,
                profile: owner,
            }).then(() => setChatLoading(false));
        }
    };

    return (
        <Box
            className={classNames(
                controlPanelClasses.root,
                classes.profileHeader,
            )}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {loading && <AvatarLoadingAnimation />}
                {!loading && (
                    <Avatar
                        isAuthenticated={props.isAuthenticated}
                        picture={getProjectPicture()}
                        shape="square"
                        withBorder
                        percent={matchPercent}
                        percentagePlacement="bottom-center"
                        size="large"
                        withShadow
                    />
                )}
                <div className={coolClasses.cools} onClick={setCoolPanelOpen}>
                    <SpriteSvgIcon id="cool-sign" />
                    <Box
                        className={
                            !cools?.find((p) => p.ownerId === currentUserId)
                                ? coolClasses.coolsSpan
                                : classNames({
                                      [coolClasses.coolsSpan]: true,
                                      [coolClasses.coolSpanTagged]: true,
                                  })
                        }
                    >
                        {cools?.length || 0}
                    </Box>
                </div>
            </Box>
            <Box className={controlPanelClasses.bottomBar}>
                <Box>
                    <ShareButton
                        activeProject={activeProject}
                        iconColor="white"
                        type="project"
                        dropdownDirection="down"
                        shareId={id}
                        picture={picture}
                    />
                </Box>
                <Typography variant="h2" className={controlPanelClasses.name}>
                    {name}
                </Typography>
                <Box className={controlPanelClasses.bottomControlBox}>
                    {owner.id !== currentUserId && (
                        <Box className={controlPanelClasses.bottomForumBox}>
                            <UnauthorizedRedirectOnclick>
                                <IconButton
                                    className={`${controlPanelClasses.chatIcon}`}
                                    onClick={() => {
                                        openChatHandler();
                                    }}
                                    disabled={chatLoading}
                                >
                                    {chatLoading && (
                                        <CircularProgress
                                            size={24}
                                            color="secondary"
                                        />
                                    )}
                                    {!chatLoading && <ForumIcon />}
                                </IconButton>
                            </UnauthorizedRedirectOnclick>
                        </Box>
                    )}
                </Box>
            </Box>
            {coolPanelOpen && (
                <UsersCoolDrawer
                    id={id}
                    type="Project"
                    setCoolPanelOpen={setCoolPanelOpen}
                    coolPanelOpen={coolPanelOpen}
                />
            )}
        </Box>
    );
};
