import { Chip, ChipProps } from '@material-ui/core';
import React from 'react';
import { OnAnyEventHandler } from '../helpers/CommonTypes';
import lngs from 'language-list';

const arr = [
    { language: 'Ukrainian', code: 'uk' },
    { language: 'Nested Egg', code: 'neg' },
    { language: 'Sign Language', code: 'sgl' },
];

export interface WeChipItem {
    value?: any;
    label: string;
    isActive?: boolean;
    chipProps?: ChipProps;
}

export type WeChipLanguageProps = {
    item: any | WeChipItem;
    onClick?: OnAnyEventHandler;
    chipSize?: string;
};

export const WeLanguageChip = ({
    item,
    onClick,
    chipSize,
}: WeChipLanguageProps) => {
    const renderWithFlag = (item) => {
        const languageCode = lngs()
            .getData()
            .concat(arr)
            .find((lang) => lang.language === item)?.code;
        // TODO: some languages has to be matched to a flag manually
        // therefore we hide it for all languages for now
        return (
            <div>
                {/*<span
                    className={`flag-icon flag-icon-${languageCode} flag-icon-squared`}
                    style={{ marginRight: '5px' }}
                />*/}
                <span>{item}</span>
            </div>
        );
    };

    return (
        <Chip
            label={renderWithFlag(item)}
            onClick={() => {
                !!onClick && onClick((item as any).link || item);
            }}
            size={chipSize === 'small' ? 'small' : 'medium'}
            color={(item as any)?.isActive ? 'primary' : 'default'}
            clickable={!!onClick}
            {...((item as WeChipItem)?.chipProps || {})}
        />
    );
};
