import { Box, Typography, Grid } from '@material-ui/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTabStyles } from '../../../../../apps/client/src/app/components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import { ProjectEntity, UserProfileEntity } from '@weco/core';
import {
    Hidden,
    WeChipsList,
    WeContactList,
    SectionWithTitle,
    WeSocialMediaList,
    useProjectFieldWrapperStyles,
    WeChip,
    WeMultilineText,
    UnauthorizedRedirectOnclick,
} from '@weco/ui';
import {
    toChipItem,
    objectToTypeValue,
} from 'apps/client/src/app/helpers/utils';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import { Link } from 'react-router-dom';

/**
 * NOTE: this whole component should be moved
 * to storybook. But before it is done, make sure
 * all child component are moved to storybook aswell.
 * Otherwise storybook will throw "cannot resolve 'weco/ui'" error
 * for child component.
 * Time of writing: 28.07.2020. @undeadlol1
 */

const ProjectInfo = ({
    project,
    profile,
}: {
    project: ProjectEntity;
    profile: UserProfileEntity;
}) => {
    const fieldClasses = useProjectFieldWrapperStyles();
    const tabClasses = useTabStyles();
    // const schoolName = get(project, 'school.name', ''); NOTE: hidden until Dwight school collaboration will be established.

    if (!project) {
        return null;
    }

    return (
        <Box className={tabClasses.tabContent}>
            <Grid container>
                <Hidden when={!project.dream}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Project Mission">
                            <Typography
                                component="div"
                                className={fieldClasses.infoText}
                            >
                                <WeMultilineText text={project.dream} />
                            </Typography>
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.causes)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Causes">
                            <WeChipsList
                                items={toChipItem(
                                    (project && project.causes) || [],
                                    (profile && profile.causes) || [],
                                )}
                                chipItemComponent={({ item, index }) => (
                                    <Link
                                        className={tabClasses.chipLink}
                                        key={index}
                                        to={{
                                            pathname:
                                                RouterPaths.search.projects,
                                            state: {
                                                searchTag:
                                                    typeof item !== 'string'
                                                        ? item.value
                                                        : item,
                                            },
                                        }}
                                    >
                                        <WeChip item={item} />
                                    </Link>
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.passions)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Tags">
                            <WeChipsList
                                items={toChipItem(
                                    (project && project.passions) || [],
                                    (profile && profile.passions) || [],
                                )}
                                chipItemComponent={({ item, index }) => (
                                    <Link
                                        className={tabClasses.chipLink}
                                        key={index}
                                        to={{
                                            pathname:
                                                RouterPaths.search.projects,
                                            state: {
                                                searchTag:
                                                    typeof item !== 'string'
                                                        ? item.value
                                                        : item,
                                            },
                                        }}
                                    >
                                        <WeChip item={item} />
                                    </Link>
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.objectives)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle
                            title="Objectives"
                            children={
                                <WeChipsList
                                    items={toChipItem(
                                        (project && project.objectives) || [],
                                        (profile && profile.objectives) || [],
                                    )}
                                    chipItemComponent={({ item, index }) => (
                                        <Link
                                            className={tabClasses.chipLink}
                                            key={index}
                                            to={{
                                                pathname:
                                                    RouterPaths.search.projects,
                                                state: {
                                                    searchTag:
                                                        typeof item !== 'string'
                                                            ? item.value
                                                            : item,
                                                },
                                            }}
                                        >
                                            <WeChip item={item} />
                                        </Link>
                                    )}
                                />
                            }
                        />
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.status)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title={'Status'}>
                            <Typography className={fieldClasses.infoText}>
                                {project.status}
                            </Typography>
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                {/* NOTE: hidden until Dwight school collaboration will be established. */}
                {/* <Hidden when={!schoolName}> */}
                {/*<Grid item xs={12} className={tabClasses.section}>*/}
                {/*    <SectionWithTitle title={'School'} >*/}
                {/*        <Typography className={fieldClasses.infoText}>*/}
                {/*            <WeChipsList items={[schoolName]} />*/}
                {/*        </Typography>*/}
                {/*    </SectionWithTitle>*/}
                {/*</Grid>*/}
                {/* </Hidden> */}
                <Hidden when={!get(project, 'location')}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Location">
                            <Typography className={fieldClasses.infoText}>
                                {get(project, 'location.name')}
                            </Typography>
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.languages)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle
                            title="Languages"
                            isEmpty={isEmpty(project.languages)}
                        >
                            <WeChipsList
                                items={toChipItem(
                                    (project && project.languages) || [],
                                    (profile && profile.languages) || [],
                                )}
                                chipItemComponent={({ item, index }) => (
                                    <Link
                                        className={tabClasses.chipLink}
                                        key={index}
                                        to={{
                                            pathname:
                                                RouterPaths.search.projects,
                                            state: {
                                                searchTag:
                                                    typeof item !== 'string'
                                                        ? item.value
                                                        : item,
                                            },
                                        }}
                                    >
                                        <WeChip item={item} />
                                    </Link>
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.contacts)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Contacts">
                            <UnauthorizedRedirectOnclick>
                                <WeContactList
                                    onClick={() => {}}
                                    items={objectToTypeValue(project.contacts)}
                                />
                            </UnauthorizedRedirectOnclick>
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.socialMedia)}>
                    <Grid item xs={12} className={tabClasses.section}>
                        <SectionWithTitle title="Social Media">
                            <WeSocialMediaList
                                items={objectToTypeValue(project.socialMedia)}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    );
};

export { ProjectInfo };
