// import { Inject, Injectable } from '@weco/common';
// import { SERVICES } from '../../../../../apps/client/src/app/services/services';
// import { AppStore } from '../../../../../apps/client/src/app/store/AppStore';
// export interface CurrentUserProviderInterface {
//     readonly UserId: string;
// }
//
// @Injectable()
// export class CurrentUserProvider implements CurrentUserProviderInterface {
//
//
//     @Inject(SERVICES.AppStore)
//     private appStore: AppStore;
//
//
//     public get UserId(): string {
//
//         return this.appStore.token.getUser().id;
//     }
// }

export interface CurrentUserProviderInterface {
    readonly UserId: string;
}

export class CurrentUserProvider implements CurrentUserProviderInterface {
    constructor(public callback: () => string) {}
    public get UserId(): string {
        return this.callback();
    }
}
