import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));

export const NotFoundPage = () => {
    const history = useHistory();
    console.log('Not found:', history.location.state);
    const classes = useStyles();
    // TODO: need layout for not found page to render correct view
    return (
        <div className={classes.root}>
            <Typography variant="h4" align="center" gutterBottom>
                {history.location.state} Not Found
            </Typography>
            <Typography align="center" gutterBottom>
                We are sorry, but the {history.location.state} you requested was
                not found.
            </Typography>
            <Typography align="center">
                You can Search {history.location.state} on{' '}
                <Link href="/search">
                    <strong>Search page</strong>
                </Link>
                <br />
                or
                <br />
                back to{' '}
                <Link href="/dashboard">
                    <strong>Home</strong>
                </Link>
            </Typography>
        </div>
    );
};
