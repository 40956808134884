import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import {
    ProjectRolesStore,
    ProjectRolesStoreInterface,
} from '../ProjectRolesStore';
import { useMemo, useState } from 'react';

export const useProjectRolesData = (
    id = null,
): Omit<ProjectRolesStoreInterface, ''> => {
    const store = useStore<ProjectRolesStore>(ProjectRolesStore);

    const [loading, setLoading] = useState(false);
    const doLoadInitData = async () => {
        if (!id && !store.projectId) {
            return;
        }
        setLoading(true);
        try {
            store.projectId = id;
            await Promise.all([
                store.loadDepartments(),
                store.loadDefaultsRoles(),
            ]);
            await store.loadRoles();
            setLoading(false);
        } catch (e) {
            console.log('error', e);
            setLoading(false);
        }
    };

    useMemo(doLoadInitData, [id, store.projectId]);

    return useObserver(() => ({
        isLoadingStoreData: store.isLoadingStoreData && loading,
        departments: store.departments,
        allAddedRoles: store.allAddedRoles,
        isThereFinishedTest: store.isThereFinishedTest,
        defaultRoles: store.defaultRoles,
        loadDepartments: store.loadDepartments,
        loadRoles: store.loadRoles,
        addRole: store.addRole,
        updateRole: store.updateRole,
        deleteRole: store.deleteRole,
        loadDefaultsRoles: store.loadDefaultsRoles,
        loadAutocompleteSkillsOptions: store.loadAutocompleteSkillsOptions,
        loadMyLastPassedTestResults: store.loadMyLastPassedTestResults,
        allRoles: store.allRoles,
        activeRoles: store.activeRoles,
    }));
};
