import { TypeValueInterface, SkillSetsEntity, SkillEntity } from '@weco/core';
import isEqual from 'lodash/isEqual';
import { WeChipItem } from '@weco/ui';
import { ROOM_TYPE_PEOPLE } from '@weco/chat';
import { RouterPaths } from '../router/RouterPaths';

export function objectToTypeValue(obj: any): TypeValueInterface[] {
    return Object.keys(obj || {}).map((type) => ({
        type: type,
        value: obj[type],
    }));
}

export function compareSkillSets(
    a: SkillSetsEntity,
    b: SkillSetsEntity,
): boolean {
    if (a.name !== b.name) {
        return false;
    }

    if (a.skills?.length !== b.skills?.length) {
        return false;
    }

    return isEqual(a.skills, b.skills);
}

/**
 * Check if any Skill from 'item' exists in any element inside 'matchList'.
 */
export function includesSkillSets(
    item: SkillSetsEntity,
    matchList: SkillSetsEntity[],
): boolean {
    console.warn('This function is in the process of being moved.');
    console.warn('Don\'t use this one. Import it from "@weco/common" instead.');

    const matchedSkillIds =
        matchList?.reduce((acc, item) => {
            acc = acc.concat(item.skills?.map((item) => item.id));
            return acc;
        }, []) || [];

    return (
        !!item.skills?.find((match) => matchedSkillIds.includes(match.id)) ||
        false
    );
}

/**
 *  Check if any of SkillSets from 'matchList' contains a proper Skill.
 */
export function includesSkill(
    item: SkillEntity,
    matchList: SkillSetsEntity[],
): boolean {
    console.warn('This function is in the process of being moved.');
    console.warn('Don\'t use this one. Import it from "@weco/common" instead.');

    const matchedSkillIds =
        matchList?.reduce((acc, item) => {
            acc = acc.concat(item.skills?.map((item) => item.id));
            return acc;
        }, []) || [];

    return matchedSkillIds?.includes(item.id) || false;
}

export function toChipItem(
    data: string[],
    matchWith: string[] = [],
): WeChipItem[] {
    if (!data || data.length === 0) {
        return [];
    }

    return data
        .filter((item) => !!item)
        .map((val) => {
            return {
                value: val,
                label: val,
                isActive: matchWith?.includes(val),
            };
        });
}
// TODO add deprecation warning here?
export const openChat = ({
    createRoom,
    history,
    id,
    currentUserId,
    profile,
}) => {
    console.warn('This function is deprecated and will be removed.');
    return createRoom(
        {
            type: ROOM_TYPE_PEOPLE,
            userIds: [id, currentUserId],
        },
        currentUserId,
        profile,
    ).then((roomId: string) => {
        history.push(`${RouterPaths.chat.root}/${ROOM_TYPE_PEOPLE}/${roomId}`);
    });
};
