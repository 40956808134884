import {
    Auth,
    COMMON_SERVICES,
    CoreEventDispatcher,
    Exceptions,
    getPictureUrl,
    Inject,
    Injectable,
    NoticeServiceInterface,
} from '@weco/common';
import { action, observable, runInAction } from 'mobx';
import {
    AddUserToRoomVariablesInterface,
    ChatService,
    ChatStoreInterface,
    CreateRoomVariablesInterface,
    TmpRoomInterface,
    TMP_ROOM_ID,
} from '@weco/chat';
import {
    PersonService,
    SkillSetsEntity,
    SkillsService,
    UserProfileEntity,
} from '@weco/core';
import { APP_EVENTS } from '../../app_events';
import { AuthEvent } from '../events/AuthEvent';
import firebase from 'firebase/app';
import { MyUserStore } from './MyUserStore';
import di from '../services/di';
import { formatDateTime, formatTime } from 'libs/chat/src/lib/utils/dateTime';

@Injectable()
export class ChatStore implements ChatStoreInterface {
    @Inject(ChatService)
    chatService: ChatService;

    @Inject(PersonService)
    personService: PersonService;

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    private eventDispatcher: CoreEventDispatcher;

    @Inject(COMMON_SERVICES.NoticeStore)
    private notice: NoticeServiceInterface;

    @Inject(SkillsService)
    skillsService: SkillsService;
    @observable token: any;
    @observable chatUser: firebase.auth.UserCredential;
    @observable tmpRoom: TmpRoomInterface;

    constructor() {
        setTimeout(() => {
            this.eventDispatcher.addListener(
                APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
                (event: AuthEvent) => {
                    if (event.subject.isAuthenticated()) {
                        this.connect(event.subject);
                    } else {
                        this.disconnect();
                    }
                },
                true,
            );
        });
    }

    private get myUserStore(): MyUserStore {
        return di.get<MyUserStore>(MyUserStore);
    }

    get firebase(): firebase.app.App {
        return this.chatService.firebaseClient;
    }

    get firestore(): firebase.firestore.Firestore {
        return this.firebase?.firestore();
    }

    @action.bound
    async loadToken(): Promise<any> {
        try {
            this.token = await this.chatService.getToken();
            return this.token;
        } catch (e) {
            console.error(e);
        }
    }

    @action.bound
    async connect(authToken: Auth.UserTokenInterface): Promise<any> {
        if (!authToken.isAuthenticated()) {
            throw new Exceptions.RuntimeException(
                `Chat available only for Authorized users`,
            );
        }
        try {
            const connection = await this.chatService.openConnection();
            runInAction(() => {
                this.token = connection.token;
                this.chatUser = connection.user;
            });
            return connection;
        } catch (e) {
            this.notice.error(e.message || e);
        }
    }

    @action.bound
    async createRoom(
        input: CreateRoomVariablesInterface,
        currentUserId: string,
        profile: UserProfileEntity,
    ) {
        try {
            this.tmpRoom = this.makeTmpRoom(currentUserId, profile);
            this.chatService
                .createRoom(input, currentUserId, profile)
                .then((token) => {
                    runInAction(() => {
                        this.token = token;
                        this.tmpRoom = null;
                    });
                });
            return TMP_ROOM_ID;
        } catch (e) {
            this.notice.error(e.message || e);
        }
    }

    @action.bound addUserToRoom(
        input: AddUserToRoomVariablesInterface,
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.chatService
                .addUserToRoom(input)
                .then((res) => {
                    resolve(res || []);
                })
                .catch((err: Error) => {
                    this.notice.error(err.message || err);
                    reject(err);
                });
        });
    }

    @action.bound searchUsers(q: string, exclude: string[] = []): Promise<any> {
        return new Promise((resolve, reject) => {
            this.personService.searchByName(q, exclude).then((res) => {
                resolve(res || []);
            });
        });
    }

    @action.bound disconnect(): Promise<void> {
        this.token = null;
        this.chatUser = null;
        return this.chatService.closeConnection();
    }

    @action.bound getSkillSetById(id: number): Promise<SkillSetsEntity> {
        return this.skillsService.getSkillSetById(id);
    }

    private makeTmpRoom(
        currentUserId: string,
        profile: UserProfileEntity,
    ): TmpRoomInterface {
        const userIds = [currentUserId, profile.id];
        return {
            room: {
                id: TMP_ROOM_ID,
                name: userIds.join(''),
                members: userIds.map((id) => ({
                    id,
                })),
                owner: { id: currentUserId },
                lastMessage: '',
                lastMessageTime: formatDateTime(new Date()),
                lastMessageTimeTime: formatTime(new Date()),
                type: 'people',
                image: '',
                unsubscribe: () => {},
            },
            user: {
                id: profile.id,
                name: `${profile.name} ${profile.lastName}`,
                image: getPictureUrl(profile.picture),
                rooms: [],
            },
        };
    }
}
