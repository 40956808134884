import React from 'react';

import { SearchPageStore } from './SearchPageStore';
import { StoreContainer } from '../../store/containers/StoreContainer';

export interface SearchContainerProps {
    children: any;
}

export const SearchContainer = ({ children }: SearchContainerProps) => {
    const loader = async () => {};
    return (
        <StoreContainer
            children={children}
            classType={SearchPageStore}
            loader={loader}
        />
    );
};
