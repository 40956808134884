import {
    Box,
    Card,
    CardContent,
    Grid,
    Theme,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AvatarLoadingAnimation } from './AvatarLoadingAnimation';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%',
        margin: '0 auto',
        maxWidth: '350px',
        minWidth: '230px',
    },
    cardRoot: {
        height: '100%',
        padding: '20px',
        borderRadius: '6px',
        background: theme.palette.common.white,
        boxShadow: '0px 13px 16px rgba(0, 0, 0, 0.05)',
    },
    avatarBox: {
        width: '120px',
        height: '120px',
        margin: '0 auto',
    },
}));

export const ProjectSkeletonPlaceholder = () => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6}>
            <Box className={classes.root}>
                <Card className={classes.cardRoot}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                    >
                        <Grid item xs={6}>
                            <AvatarLoadingAnimation />
                        </Grid>
                    </Grid>
                    <CardContent>
                        <Typography variant="subtitle1" align="center">
                            <Skeleton animation="wave" />
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            <Skeleton animation="wave" />
                        </Typography>
                        <Typography
                            component="p"
                            variant="body2"
                            color="textSecondary"
                            align="justify"
                        >
                            <Skeleton animation="wave" />
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
};
