import { Inject, Injectable } from '@weco/common';
import { action, autorun, computed, observable, runInAction } from 'mobx';
import {
    DepartmentEntity,
    DepartmentEntityWithTestResultPercent,
    SkillEntity,
    SkillSetsEntity,
    SkillsService,
    TestResultEntity,
    TestService,
} from '@weco/core';
import { environment } from '../../environments/environment';
import { compareSkillSets } from '../helpers/utils';
import { findIndex } from 'lodash';
import debug from 'debug';
import filter from 'lodash/filter';

const log = debug('PositionsBaseStore');

export interface RolesStoreInterface {
    isLoadingStoreData: boolean;
    isThereFinishedTest: boolean;
    departments: DepartmentEntityWithTestResultPercent[];
    allAddedRoles: SkillSetsEntity[];
    allRoles: SkillSetsEntity[];
    defaultRoles: SkillSetsEntity[];
    activeRoles: SkillSetsEntity[];
    loadDepartments(): Promise<DepartmentEntityWithTestResultPercent[]>;
    loadMyLastPassedTestResults(): Promise<TestResultEntity>;
    loadRoles(departmentId?: string): Promise<void>;
    loadDefaultsRoles(departmentId?: string): Promise<any>;
    loadAutocompleteSkillsOptions(
        departmentName?: string,
    ): Promise<SkillEntity[]>;
    addRole(item: SkillSetsEntity): Promise<SkillSetsEntity>;
    updateRole(item: SkillSetsEntity): Promise<void>;
    deleteRole(item: SkillSetsEntity): Promise<void>;
}

@Injectable()
export abstract class RolesBaseStore {
    isLoadingStoreData: boolean;
    isThereFinishedTest: boolean;

    @Inject(SkillsService)
    service: SkillsService;

    @Inject(TestService)
    testService: TestService;

    @observable departments: DepartmentEntityWithTestResultPercent[];

    @observable defaultRoles: SkillSetsEntity[];
    @observable allAddedRoles: SkillSetsEntity[];

    @observable projectId: string;
    @observable skillSets: {
        [key: string]: SkillEntity;
    } = {};

    // TODO: remove after 11/20/2020 if skills works
    // constructor() {
    //     autorun(async () => {
    //         this.isLoadingStoreData = true;
    //         try {
    //             await this.loadDefaultsRoles();
    //             await this.loadRoles();
    //             this.isLoadingStoreData = false;
    //         } catch (e) {
    //             console.error(e);
    //             this.isLoadingStoreData = false;
    //         }
    //     });
    // }

    @action.bound
    async loadDepartments(): Promise<DepartmentEntityWithTestResultPercent[]> {
        const result = await this.service.loadDepartments();

        const testResults = await this.testService.loadMyLastTestResults({
            status: 'FINISHED',
        });

        const finalResult = this.compareDepartmentsWithTestResult(
            result,
            testResults,
        );

        runInAction(() => {
            this.departments = finalResult;
            this.isThereFinishedTest = !!testResults;
        });
        return finalResult;
    }

    @action.bound
    compareDepartmentsWithTestResult(
        result: DepartmentEntity[],
        testResults: TestResultEntity,
    ): DepartmentEntityWithTestResultPercent[] {
        const finalResult: DepartmentEntityWithTestResultPercent[] = [];
        const maxPoints = testResults ? testResults.results[0]?.points : 0;

        result.forEach((dep, i) => {
            let depResult = [];
            if (testResults) {
                depResult = testResults.results.filter((result) => {
                    return result.department === dep.name;
                });
            }
            finalResult.push({
                ...dep,
                testPercent: maxPoints
                    ? Math.floor((depResult[0]?.points * 100) / maxPoints)
                    : 0,
            } as DepartmentEntityWithTestResultPercent);
        });

        return finalResult;
    }

    @action.bound
    async loadMyLastPassedTestResults(): Promise<TestResultEntity> {
        return this.testService.loadMyLastTestResults();
    }

    @action.bound
    async loadRoles(departmentId?: string) {
        throw new Error('Method not implemented.');
    }

    @action.bound
    async loadDefaultsRoles(departmentId?: string): Promise<any> {
        const result = (await this.service?.loadDefaultsSkillSets()) || [];
        result.forEach((i) => ((i.isDefault = true), (i.isSelected = false)));
        runInAction(() => {
            this.defaultRoles = result;
        });
    }

    @action.bound
    async loadAutocompleteSkillsOptions(
        departmentName?: string,
    ): Promise<SkillEntity[]> {
        const result = await this.service.loadAutocompleteSkillsOptions(
            departmentName,
        );
        return result;
    }

    protected async addRole(item: SkillSetsEntity): Promise<SkillSetsEntity> {
        if (
            item.ownerId === environment.defaults.emptyId &&
            item.projectId === environment.defaults.emptyId
        ) {
            throw new Error(
                "You can't add default Skill Sets. Please provide ownerId or projectId.",
            );
        }
        item.id = null;
        const result = await this.service.addSkillSets(item);
        runInAction(() => {
            const newList = [].concat(this.allAddedRoles, result);
            this.injectIsDefault(newList);
            this.allAddedRoles = newList;
        });
        return result;
    }

    @action.bound
    async updateRole(item: SkillSetsEntity): Promise<void> {
        log('updateSkillSets is called.');
        if (item.ownerId === item.projectId) {
            log('Adding skillset because ids match.');
            this.addRole(item);
        }
        log('Calling service function to update skillsets.');
        this.service.updateSkillSets(item).then(() => {
            log('Updating skillsets in local store.');
            const index = findIndex(this.allAddedRoles, { id: item.id });
            this.allAddedRoles[index] = item;
            this.injectIsDefault(this.allAddedRoles);
            this.allAddedRoles = [].concat(this.allAddedRoles);
        });
    }

    @action.bound
    async deleteRole(item: SkillSetsEntity): Promise<void> {
        if (!item.id) {
            return;
        }
        await this.service.deleteSkillSets(item.id);
        this.allAddedRoles = this.allAddedRoles.filter((elem) => {
            return elem.id !== item.id;
        });
    }

    protected injectIsDefault(items: SkillSetsEntity[]): void {
        items.forEach((position) => {
            const defaultItem = this.defaultRoles
                .filter(
                    (item) =>
                        item.projectId === environment.defaults.emptyId &&
                        item.ownerId === environment.defaults.emptyId,
                )
                .find(
                    (defaultPosition) => defaultPosition.name === position.name,
                );

            position.isDefault =
                defaultItem && compareSkillSets(defaultItem, position);
            if (position.isDefault) {
                position.order = defaultItem.order;
            }
        });
    }

    @computed
    get allRoles(): SkillSetsEntity[] {
        if (!this.allAddedRoles) return [];

        const addedRolesNames = this.allAddedRoles.map((i) => i.name);
        return this.allAddedRoles.concat(
            this.defaultRoles.filter(
                (pos) => !addedRolesNames.includes(pos.name),
            ),
        );
    }

    @computed
    get activeRoles(): SkillSetsEntity[] {
        return filter(this.allAddedRoles, 'isSelected');
    }

    @action.bound
    async getSkillSetById(id: number): Promise<SkillSetsEntity> {
        if (!this.skillSets[id]) {
            this.skillSets[id] = await this.service.getSkillSetById(id);
        }

        return this.skillSets[id];
    }
}
