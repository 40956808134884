import React from 'react';
import {
    KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
    KeyboardDateTimePickerProps as MuiKeyboardDateTimePickerProps,
} from '@material-ui/pickers';
import { FieldProps, getIn } from 'formik';
import { createErrorHandler } from './errorHandler';

export interface WeKeyboardDateTimePickerProps
    extends FieldProps,
        Omit<MuiKeyboardDateTimePickerProps, 'name' | 'value' | 'error'> {}

export const fieldToKeyboardDateTimePicker = ({
    disabled,
    field,
    form: { isSubmitting, touched, errors, setFieldValue, setFieldError },
    ...props
}: WeKeyboardDateTimePickerProps): MuiKeyboardDateTimePickerProps => {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled != undefined ? disabled : isSubmitting,
        onChange(date) {
            props.onChange
                ? props.onChange(date)
                : setFieldValue(field.name, date);
        },
        onError: createErrorHandler(fieldError, field.name, setFieldError),
    };
};

export const WeKeyboardDateTimePicker = ({
    children,
    ...props
}: WeKeyboardDateTimePickerProps) => {
    return (
        <MuiKeyboardDateTimePicker {...fieldToKeyboardDateTimePicker(props)}>
            {children}
        </MuiKeyboardDateTimePicker>
    );
};
