import React, { FunctionComponent, memo } from 'react';
import { Field, Form, Formik } from 'formik';
import {
    Button,
    LinearProgress,
    makeStyles,
    Theme,
    Typography,
    Box,
} from '@material-ui/core';
import { WeTextField } from '../forms';
import { FormikValidators } from '@weco/common';
import classNames from 'classnames';
import { WE_CLICKS } from '../../../../../apps/client/src/we_clicks';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 300,
    },
    formBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    field: {
        marginTop: '15px',
        marginBottom: '22px',
    },
    fieldErrored: {
        marginBottom: 0,
    },
    label: {
        color: `${theme.palette.common.white}!important`,
        fontSize: '18px',
        top: '-4px !important',
    },
    input: {
        fontSize: '18px',
        padding: '15px 20px',
        color: theme.palette.common.white,
        backgroundColor: 'rgba(255,255,255, 0.2)',
        borderRadius: '8px',
        WebkitBoxShadow: '0 0 0 1000px transparent inset !important',
        background:
            '-webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 100%)',
        transition:
            'background-color 5000s ease-in-out 0s, color 5000s, padding 5000s, font-size 5000s',
    },
    forgotText: {
        color: theme.palette.common.white,
        cursor: 'pointer',
    },
    button: {
        width: '300px',
        height: '48px',
        borderRadius: '24px',
        fontSize: '18px',
        color: theme.palette.primary.main,
        margin: 0,
        marginTop: 70,
        fontWeight: 500,
        '&:disabled': {
            color: theme.palette.common.white,
        },
        '&:focus': {
            background: 'transparent',
        },
        '&:hover': {
            background: 'transparent',
            border: 0,
        },
    },
}));

interface FormData {
    email: string;
    password: string;
}

const resetPasswordForm: FunctionComponent<{
    onSubmit: Function;
}> = (props) => {
    const classes = useStyles();

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validate={(values) => {
                const errors: Partial<FormData> = {};
                if (!values.email.trim()) {
                    errors.email = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                props.onSubmit(values.email).catch((e) => {
                    setSubmitting(false);
                });
            }}
        >
            {({ submitForm, isSubmitting, errors, touched }) => (
                <Box className={classes.formBox}>
                    <Form className={classes.container}>
                        <Field
                            component={WeTextField}
                            name="email"
                            type="email"
                            label="E-mail"
                            variant="outlined"
                            className={classNames(classes.field, {
                                [classes.fieldErrored]:
                                    !!errors.email && !!touched.email,
                            })}
                            InputProps={{
                                classes: { input: classes.input },
                            }}
                            InputLabelProps={{
                                className: classes.label,
                            }}
                            required
                            validate={FormikValidators.validateEmail}
                        />

                        {isSubmitting && <LinearProgress />}
                        <Button
                            id={WE_CLICKS.LOGIN_EMAIL}
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}
                            className={classes.button}
                            onClick={submitForm}
                        >
                            Reset Password
                        </Button>
                    </Form>
                </Box>
            )}
        </Formik>
    );
};

export const ResetPasswordForm = memo(resetPasswordForm);
