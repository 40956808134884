import React from 'react';
import { Box, FormControl } from '@material-ui/core';
import { Field } from 'formik';
import { TypeValueInterface } from '@weco/core';
import { WeTextField, SectionWithTitle } from '@weco/ui';
import { validateUrl } from '@weco/common';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
export interface SocialMediaFormControlProps {
    name: string;
    label: string;
    options: TypeValueInterface[];
}

export const SocialMediaFormControl = ({
    options,
    label,
    name,
}: SocialMediaFormControlProps) => {
    const classes = useTabStyles();
    // todo: this component should be improved, not the best approach
    return (
        <FormControl className={classes.formControlWidth}>
            <SectionWithTitle title={label}>
                <>
                    {options.map((item) => (
                        <Field
                            validate={(data) => {
                                if (!data) {
                                    return;
                                }
                                if (!validateUrl(data, item.type)) {
                                    return `Please specify correct ${item.type} url`;
                                }
                            }}
                            key={item.type}
                            label={item.type}
                            name={`${name}.${item.type}`}
                            component={WeTextField}
                        />
                    ))}
                </>
            </SectionWithTitle>
            <Box m={1} />
        </FormControl>
    );
};
