import { AppStore } from '../AppStore';
import { useObserver } from 'mobx-react';
import { Auth, useStore } from '@weco/common';

interface CurrentAuthData {
    token: Auth.UserTokenInterface;
    user: any;
    isAuthenticated: boolean;
    username: string;
    authenticate: (token) => Promise<any>;
    signout: (token?: Auth.UserTokenInterface) => Promise<any>;
    signup: (token?: Auth.UserTokenInterface) => Promise<any>;
    resendSignUp: (token?: Auth.UserTokenInterface) => Promise<any>;
    forgotPassword: (token?: Auth.UserTokenInterface) => Promise<any>;
    forgotPasswordSubmit: (token?: Auth.UserTokenInterface) => Promise<any>;
}

export const useCurrentAuthData = () => {
    const appStore = useStore<AppStore>('app');

    return useObserver(
        () =>
            ({
                token: appStore.token,
                user: appStore.token.getUser(),
                isAuthenticated: appStore.token.isAuthenticated(),
                username: appStore.token.getUsername(),
                authenticate: (token) => appStore.authenticate(token),
                signout: (token?: Auth.UserTokenInterface) =>
                    appStore.signout(token),
                signup: (token?: Auth.UserTokenInterface) =>
                    appStore.signup(token),
                resendSignUp: (token?: Auth.UserTokenInterface) =>
                    appStore.resendSignUp(token),
                forgotPassword: (token?: Auth.UserTokenInterface) =>
                    appStore.forgotPassword(token),
                forgotPasswordSubmit: (token?: Auth.UserTokenInterface) =>
                    appStore.forgotPasswordSubmit(token),
            } as CurrentAuthData),
    );
};
