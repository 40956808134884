import { connect } from 'react-redux';
import Rooms from '../components/Rooms';
import {
    fetchRooms,
    setFilterType,
    setActiveRoom,
    updateUserRooms,
    createPeopleRoom,
    setDataLoading,
} from '../store/rooms/actions';
import firebase from 'firebase/app';
import { UserRoomInterface } from '../models/interfaces/UserRoomInterface';
import { searchUsers, clearSearchUsers } from '../store/users/actions';
import { CreateRoomInterface } from '../models/interfaces/CreateRoomInterface';
import { CreateRoomVariablesInterface } from '../models/interfaces/CreateRoomVariablesInterface';
import { UserProfileEntity } from '@weco/core';

const mapStateToProps = ({ chat }: any) => ({
    currentUser:
        chat.authUser.uid && chat.users.list.items[chat.authUser.uid]
            ? chat.users.list.items[chat.authUser.uid]
            : null,
    users: chat.users.list.items,
    rooms: chat.rooms.list.items,
    activeRoom: chat.rooms.list.activeRoom,
    subscribedRoomsIds: chat.roomMessages.subscribedRoomsIds,
    filterType: chat.rooms.list.filterType,
    searchUsersList: chat.users.search.items,
    loading: chat.rooms.list.loading,
    dataLoading: chat.rooms.list.dataLoading,
    roomCreationInProgress: chat.rooms.list.roomCreationInProgress,
});

const mapDispatchToProps = (dispatch: Function) => ({
    fetchRooms: (
        firestore: firebase.firestore.Firestore,
        rooms: UserRoomInterface[],
    ) => dispatch(fetchRooms(firestore, rooms)),
    setDataLoading: (loading) => dispatch(setDataLoading(loading)),
    setActiveRoom: (
        firestore: firebase.firestore.Firestore,
        id: string,
        subscribe: boolean,
    ) => dispatch(setActiveRoom(firestore, id, subscribe)),
    updateUserRooms: (
        firestore: firebase.firestore.Firestore,
        userId: string,
        rooms: UserRoomInterface[],
    ) => dispatch(updateUserRooms(firestore, userId, rooms)),
    setFilterType: (filterType: string) => dispatch(setFilterType(filterType)),
    searchUsers: (findUserByName: Function, q: string, exclude: string[]) =>
        dispatch(searchUsers(findUserByName, q, exclude)),
    clearSearchUsers: () => dispatch(clearSearchUsers()),
    createPeopleRoom: (
        firestore: firebase.firestore.Firestore,
        createRoom: CreateRoomInterface,
        input: CreateRoomVariablesInterface,
        currentUserId: string,
        profile: UserProfileEntity,
    ) =>
        dispatch(
            createPeopleRoom(
                firestore,
                createRoom,
                input,
                currentUserId,
                profile,
            ),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
