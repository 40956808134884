import debug from 'debug';
import classNames from 'classnames';
import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/styles';
import { WeChipsList } from '../molecules/WeChipsList';
import { SkillEntity, SkillSetsEntity, UserProfileEntity } from '@weco/core';
import { Box, Divider, Grid, Theme, Typography } from '@material-ui/core';
import get from 'lodash/get';
import { WeChip } from '../molecules/WeChip';
import { PeopleAvatarList } from './PeopleAvatarList';

const log = debug('SkillSetsOverview');

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    projectDescription: {
        marginTop: 12,
    },
}));

export interface SkillSetsOverviewProps {
    className?: string;
    skillSets: SkillSetsEntity;
    /**
     * In order to highlight skill chips,
     * a comparison array must be provided.
     */
    matchSkillSetsWith: string[];
}

const SkillSetsOverview = memo(
    ({ skillSets, ...props }: SkillSetsOverviewProps) => {
        log('skillSets: ', skillSets);

        const classes = useStyles();
        const rootClasses = classNames(classes.root, props.className);
        /**
         * NOTE: this is work in progress.
         * How we save and retrieve users is not yet decided.
         */
        const users = get(skillSets, 'users', []) as UserProfileEntity[];

        return (
            <Box className={rootClasses}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box m="38px 0">
                            <Typography
                                variant="h2"
                                component="p"
                                color="textPrimary"
                            >
                                {skillSets?.name}
                            </Typography>
                            {/*<AvatarListOrOpenLabel users={users} />*/}
                            <Divider />
                        </Box>
                    </Grid>
                    <SkillSetChips
                        skills={skillSets?.skills}
                        matchSkillSetsWith={props.matchSkillSetsWith}
                    />
                    <Grid item xs={12}>
                        <Box mt="36px" mb="20px">
                            <Typography variant="h5">About Role</Typography>
                        </Box>
                        <Typography
                            variant="body1"
                            align="justify"
                            color="textSecondary"
                            className={classes.projectDescription}
                        >
                            {skillSets?.description || 'No Description'}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    },
);

function AvatarListOrOpenLabel({ users }: { users: UserProfileEntity[] }) {
    return (
        <Box my={2}>
            {isEmpty(users) ? (
                <Box width="100px">
                    <WeChip item="Open" />
                </Box>
            ) : (
                <PeopleAvatarList people={users} />
            )}
        </Box>
    );
}

const SkillSetChips = ({
    skills,
    matchSkillSetsWith,
}: {
    skills: SkillEntity[];
    matchSkillSetsWith?: string[];
}) => {
    if (isEmpty(skills)) return null;
    /* search for --matching_print-- tag to correct behavior if required */
    /* const matchValues =
        matchSkillSetsWith?.map((i) => {
            return i.split(':')[1];
        }) || []; */
    return (
        <Grid item xs={12}>
            <Box my="30px">
                <Box my="30px">
                    <Typography variant="h5">Skills Required</Typography>
                </Box>
                <WeChipsList
                    items={skills.map((skill) => ({
                        label: skill.value,
                        isActive: matchSkillSetsWith?.includes(skill.id),
                    }))}
                />
            </Box>
            <Divider />
        </Grid>
    );
};

SkillSetsOverview.displayName = 'SkillSetsOverview';

export { SkillSetsOverview };
