const defaultSkillSets = [
    {
        name: 'Chief Innovation Officer',
        department: 'ideas',
        desc:
            'CIO is the person responsible for managing the process of innovation. The one who generates new ideas but also recognizes ideas originated by other people. In this day and age, innovation is a must for any project.',
        skills:
            'Management, Ideation, Imagination, Innovation, Problem-Solving, Vision, Brainstorming, Curiosity, Research',
        order: 10,
    },
    {
        name: 'Innovator',
        department: 'ideas',
        desc:
            'The first one to develop or manufacture something. It could be a totally new solution or modification of an already established idea. Where others see a problem, Inventor sees an opportunity.',
        skills: 'Invention, Ideation, Imagination, Problem-Solving, Vision',
        order: 20,
    },
    {
        name: 'Futurist',
        department: 'ideas',
        desc:
            'A person who is ahead of their time and thinks about the future in a creative and imaginative way. What others think of as impossible, futurists already "see" it in their head, even if they can\'t fully describe that vision.',
        skills:
            'Vision, Imagination, Research, Problem-Solving, Brainstorming, Strategy, Ideation',
        order: 30,
    },
    {
        name: 'Chief Strategy Officer',
        department: 'strategy',
        desc:
            'This role is responsible for the overall vision, mission and direction of the project. They will develop short and long-term objectives, formulating strategic initiatives for the key team members that will ensure successful development.',
        skills:
            'Management, Strategy, Prioritization, Planning, Roadmap Development, Research, Scheduling, Vision',
        order: 10,
    },
    {
        name: 'Management Analyst',
        department: 'strategy',
        desc:
            'Is responsible for conducting organizational studies and evaluations, designing systems and protocols, as well as conducting simplification and measurement initiatives. They are involved in development and analysis of procedures and workflows for every department.',
        skills:
            'Management, Interviewing, Data Analysis, Organization, Research, Strategy, Planning, Problem-Solving',
        order: 20,
    },
    {
        name: 'Strategy Analyst',
        department: 'strategy',
        desc:
            'Is responsible for identifying the needs of a project and developing plans to help the Chief Strategy Officer determine the overall direction it should take to succeed.',
        skills:
            'Strategy, Interviewing, Data Analysis, Problem-Solving, Organization, Research, Planning, Forecasting',
        order: 30,
    },
    {
        name: 'Chief Marketing Officer',
        department: 'marketing',
        desc:
            'Is responsible for planning, developing, implementing and monitoring marketing objectives. It involves managing the creative part of a project and connecting it with the world.',
        skills:
            'Management, Marketing, Copywriting, Storytelling, Strategy, Market Research, Advertisement, Creative Direction',
        order: 10,
    },
    {
        name: 'Graphic Designer',
        department: 'marketing',
        desc:
            'Graphic Designers create visual concepts, using computer software or by hand, to communicate solutions that inspire, inform, and captivate consumers. They develop the overall layout and production design for applications such as advertisements, presentations, media content, and reports.',
        skills:
            'Graphic Design, UI Design, UX Design, Branding, Creativity, Illustration, Marketing, Web Design, Psychology',
        order: 20,
    },
    {
        name: 'Social Media Manager',
        department: 'marketing',
        desc:
            'Is responsible for planning, implementing, managing and monitoring social media efforts. This allows projects to increase brand awareness, communicate the latest initiatives and develop further interest in what the project has to offer.',
        skills:
            'Social Media Strategy, Copywriting, Storytelling, Market Research, Advertisement, Data Analysis, Planning, Behavioral Psychology',
        order: 30,
    },
    {
        name: 'Chief Finance Officer',
        department: 'finance',
        desc:
            'CFO is responsible for planning, implementation, managing and running all the finance activities of a project, including financial planning, budgeting, forecasting, management of financial risks, record-keeping and financial reporting.',
        skills:
            'Accounting, Bookkeeping, Financial Advising, Investment, Financial Planning, Management',
        order: 10,
    },
    {
        name: 'Fundraising Expert',
        department: 'finance',
        desc:
            "Organizes activities to raise funds or gather donations. May contribute data and reports to the design of promotional materials (by Marketing). May also raise awareness of the organization's work, goals, and financial needs.",
        skills:
            'Fundraising, Crowdfunding, Sales, Negotiating, Planning, Management',
        order: 20,
    },
    {
        name: 'Bookkeeper',
        department: 'finance',
        desc:
            'Is responsible for recording and maintaining financial transactions, such as purchases, expenses, sales, invoices, and payments.',
        skills:
            'Bookkeeping, Accounting, QuickBooks, Reconciling, Data Entry, Math',
        order: 30,
    },

    {
        name: 'Chief Operating Officer',
        department: 'execution',
        desc:
            'COO is responsible for overseeing ongoing operations and procedures. This critical role assures that the strategy is turned into a successful delivery, both in time and of the superior quality product or service.',
        skills:
            'Management,  Problem-Solving, Leadership, Critical Thinking, Logical Thinking, Project Management, Multitasking, Meticulousness, Punctuality',
        order: 10,
    },
    {
        name: 'Creator',
        department: 'execution',
        desc:
            "A person who is deeply knowledgeable and very skilled in the field of the project's nature. Should be prepared to roll up their sleeves and create the actual product or service.",
        skills:
            'Hands-on Experience, Industry Understanding, Focus, Discipline, Meticulousness, Punctuality',
        order: 20,
        tips: [
            'Think of a carpenter, driver, architect, barber, cook, coach, waiter, busboy, lecturer, dancer, artist, singer or any other people who produce products or experiences',
            'Change the role description and rename it accordingly',
            'Remove irrelevant and add relevant skills',
            'Save this Role and then create as many other roles as your project requires to work on the actual product or service',
        ],
    },
    {
        name: 'Quality Assurance Expert',
        department: 'execution',
        desc:
            'QA professionals are responsible for the inspection activities, detection of problem areas, and delivery of quality inspection reports. They ensure that all standards are met before the final product reaches the outside world.',
        skills:
            'Quality Assurance, Quality Management Systems, Quality Control, Test Automation, Continual Improvement Process, Analyzing, Testing, Critical Thinking',
        order: 30,
    },
    {
        name: 'Chief Technology Officer',
        department: 'itweb',
        desc:
            'CTO is in charge of embracing technology to automate and amplify every aspect of a project. Has to have a vast practical experience in delivering successful technological solutions.',
        skills:
            'Automation, Back End Dev, Front End Dev, Database, Programming Languages,  Web Design, Management, IT Architecture & Environments',
        order: 10,
    },
    {
        name: 'App Developer',
        department: 'itweb',
        desc:
            'App developers are usually responsible for translating software requirements into workable programming code. They code and test functional programs and applications.',
        skills:
            'IOS, Android, Mobile App, JavaScript, SQL Server, XML, Software Development, Swift',
        order: 20,
    },
    {
        name: 'Web Developer',
        department: 'itweb',
        desc:
            "Is responsible for the coding, design and layout of a website according to the project's specifications.As the role takes into consideration user experience and function, a certain level of both graphic design and computer programming is necessary.",
        skills:
            'Web Development, JavaScript, HTML, Front End Design, JQuery, PHP, Wordpress, UX Design',
        order: 30,
    },
    {
        name: 'Chief Legal Officer',
        department: 'legal',
        desc:
            'Certified expert who helps projects minimize their risks by advising on legal and regulatory issues. They are also responsible for overseeing all the legal documents that a project enters in.',
        skills:
            'General Law, Negotiation, Consulting, Company Formation, Management, Law creation, Diplomacy, Problem-Solving, Intellectual Property',
        order: 10,
    },
    {
        name: 'Attorney',
        department: 'legal',
        desc:
            'Attorneys provide legal advice, Interpret laws, rulings, and regulations, take care of legal issues and disputes, prepare and file legal documents, conduct research and analysis of legal problems and protect projects from any legal headaches.',
        skills:
            'Intellectual Property, Trademarks, Negotiation, Company Formation, Management, Problem Solving, Defense Counsel',
        order: 20,
    },
    {
        name: 'Paralegal',
        department: 'legal',
        desc:
            'Paralegals support lawyers by maintaining and drafting documents, calling on legal witnesses, organizing a legal library and more.',
        skills:
            'Legal Research, Drafting, Calendaring, Legal Documents, Legal Assistance',
        order: 30,
    },
    {
        name: 'Chief HR Officer',
        department: 'hr',
        desc:
            'This person leads all training, talent acquisition and team development activities.But above all, they take care of the team members which assures a happy and productive environment for all  involved',
        skills:
            'Conflict Resolution, Hiring, Motivation, Stress Management, Team-Building, Communication, Empathy, Diplomacy, Management',
        order: 10,
    },
    {
        name: 'Benefits specialist',
        department: 'hr',
        desc:
            'This role oversees projects compensation/reward systems and is responsible for creating benefits packages that attract potential teammates.',
        skills:
            'Benefits Administration, Insurance, Medical Benefits, Pharmacy Coverage, Eligibility, Retirement Plan, Benefit Comparisons, Payroll Deductions',
        order: 20,
    },
    {
        name: 'Talent Acquisition Specialist',
        department: 'hr',
        desc:
            'This is a human resources professional responsible for sourcing, attracting, selecting and hiring suitable talent which will thrive in the team, while contributing to the success of the project.',
        skills:
            'Research, Hiring, Firing, Motivation, Communication, Empathy, Interviewing, Negotiation',
        order: 30,
    },
] as {
    name: string;
    desc: string;
    department: string;
    skills: string;
    order: number;
    tips?: string[];
}[];

export default defaultSkillSets;
