import {
    IsNotEmpty,
    ValidationArguments,
    MinLength,
    ValidateIf,
} from 'class-validator';
import {
    UserProfileEntity,
    ProjectItemInterface,
    SearchEntity,
    calcFilledPercentage,
} from '@weco/core';
import { Exclude, Expose, Transform } from 'class-transformer';
import { Picture } from './Picture';
import { ColonArrayToObjectPropertyTransformer } from '../transformers/property/ColonArrayToObjectPropertyTransformer';
import { SchoolEntity } from './SchoolEntity';
import { StoreEntity } from './StoreEntity';
import { LocationEntity } from './LocationEntity';
import { SkillSetsEntity } from './SkillSetsEntity';
import { isEmpty } from 'lodash';
import { HighlightPropertyTransformer } from '../transformers/property/HighlightPropertyTransformer';

export class ProjectEntity implements ProjectItemInterface {
    @IsNotEmpty()
    id: string;
    name: string;
    @Expose()
    picture?: Picture;
    coverImage?: string;
    @ValidateIf((o) => !isEmpty(o.dream))
    @MinLength(40, {
        message:
            'Project mission is too short. Minimal length is $constraint1 characters',
    })
    dream?: string;
    @Expose()
    owner: UserProfileEntity;
    causes: string[];
    passions: string[];
    compensations: string[];
    objectives: string[];
    stage: string;
    status: string;
    languages: string[];
    @Transform(new ColonArrayToObjectPropertyTransformer().transform)
    fundings: { [key: string]: string };
    tradings: string[];
    wishList: string[];
    investments: string[];
    stores?: StoreEntity[];
    @Transform(new ColonArrayToObjectPropertyTransformer().transform)
    contacts: { [key: string]: string };
    @Transform(new ColonArrayToObjectPropertyTransformer().transform)
    socialMedia: { [key: string]: string };
    team: UserProfileEntity[];
    school?: SchoolEntity;
    @Expose({ toPlainOnly: true })
    get projectSchoolId(): string {
        return this.school?.id;
    }
    skills: string[];
    location?: LocationEntity;
    skillSets?: SkillSetsEntity[];
    matchPercent: number;
    searchMatchScore?: number;
    @Expose({ toPlainOnly: true })
    @Exclude({ toPlainOnly: true })
    @Transform(new HighlightPropertyTransformer().transform, {
        toClassOnly: true,
    })
    highlight?: string[];
    settings?: ProjectSettings;

    @Exclude()
    get isActivated(): boolean {
        return !!this.dream && !!this.picture;
    }
    @Exclude()
    get filledPercentage(): number {
        return calcFilledPercentage(this);
    }
}

export interface ProjectSettings {
    searches: SearchEntity[];
    totalCounts?: number;
}
