import { BaseUserToken } from './common';

export class UsernamePasswordToken extends BaseUserToken<{ username: string }> {
    constructor(public username, public password, public payload: any = {}) {
        super();
        this.user = {
            username,
        };
    }
}
