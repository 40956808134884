import classNames from 'classnames';
import React, { memo } from 'react';
import { Theme, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'formik';
import { WeSelect } from '../forms/WeSelect';
import { AccountTypes } from '@weco/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    item: {
        padding: '10px',
    },
}));

interface AccountTypeSelectProps {
    defaultValue?: AccountTypes;
    className?: string;
}

const AccountTypeSelect = memo((props: AccountTypeSelectProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return (
        <Field
            name="accountType"
            label="Account type"
            component={WeSelect}
            className={rootClasses}
            defaultValue={props.defaultValue}
            validate={(type) => (type ? '' : 'Required')}
        >
            <MenuItem value="student" className={classes.item}>
                Student
            </MenuItem>
            <MenuItem value="teacher" className={classes.item}>
                Teacher
            </MenuItem>
            <MenuItem value="other" className={classes.item}>
                Other
            </MenuItem>
        </Field>
    );
});

AccountTypeSelect.displayName = 'AccountTypeSelect';

export { AccountTypeSelect };
