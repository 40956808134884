import { Injectable } from '@weco/common';
import { action, runInAction, observable, autorun } from 'mobx';
import { SkillSetsEntity } from '@weco/core';
import cloneDeep from 'lodash/cloneDeep';
import { environment } from '../../environments/environment';
import { RolesBaseStore, RolesStoreInterface } from './RolesBaseStore';

export interface PersonRolesStoreInterface extends RolesStoreInterface {}

@Injectable()
export class PersonRolesStore extends RolesBaseStore
    implements PersonRolesStoreInterface {
    constructor() {
        super();
    }
    @observable personId: string;

    @action.bound
    async loadRoles(departmentId?: string) {
        this.isLoadingStoreData = true;
        try {
            const result = this.personId
                ? await this.service.loadSkillSets({
                      departmentId: departmentId,
                      ownerId: this.personId,
                      projectId: environment.defaults.emptyId,
                  })
                : [];

            runInAction(() => {
                this.isLoadingStoreData = false;
                this.injectIsDefault(result);
                this.allAddedRoles = result;
            });
        } catch (e) {
            console.log(e);
            this.isLoadingStoreData = false;
        }
    }

    @action.bound
    async addRole(item: SkillSetsEntity): Promise<SkillSetsEntity> {
        const newItem: SkillSetsEntity = cloneDeep(item);
        newItem.ownerId = this.personId;
        return super.addRole(newItem);
    }
}
