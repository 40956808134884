import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
    AvatarListHorizontalLoadingAnimation,
    PeopleListCompressed,
} from '@weco/ui';
import React from 'react';
import useToggle from 'react-use/lib/useToggle';
import { useTabContentStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import { useCurrentAuthData } from '../../../store/hooks/useCurrentAuthData';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import { useSearchPeopleData } from './useSearchPeopleData';

const SearchPeopleContent = () => {
    const classes = useTabContentStyles();
    const { user } = useCurrentAuthData();

    const {
        items,
        search,
        peopleLimit,
        step,
        peopleTotal,
        loading,
    } = useSearchPeopleData();
    const { activeProject } = useMyUserData();
    const [loadingMore, toggleLoadingMore] = useToggle(false);

    function loadMore() {
        toggleLoadingMore(true);
        search(null, peopleLimit + step)
            .then(() => {
                document
                    .getElementById(items[items.length - 1].id)
                    .scrollIntoView();
            })
            .catch(console.error)
            .finally(toggleLoadingMore);
    }

    return (
        <Box mt={4}>
            {loading && (
                <Box mt={4} width="100%">
                    <AvatarListHorizontalLoadingAnimation
                        count={10}
                        variant="circle"
                    />
                </Box>
            )}
            {!loading && (
                <PeopleListCompressed
                    activeProject={activeProject}
                    people={items}
                    currentUserId={user?.id}
                />
            )}
            <Box className={classes.loadMore}>
                {loadingMore && <CircularProgress size={20} />}
                {!loadingMore && peopleTotal !== items?.length && (
                    <Button color="secondary" variant="text" onClick={loadMore}>
                        See More
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default SearchPeopleContent;
