import firebase from 'firebase/app';
import { connect } from 'react-redux';
import ChatInformer from '../components/ChatInformer';
import { fetchCurrentUser } from '../store/users/actions';
import { receiveUserCredentials } from '../store/authUser/actionCreators';

const mapStateToProps = (state: any) => ({
    user:
        state.chat.authUser.uid &&
        state.chat.users.list &&
        state.chat.users.list.items &&
        state.chat.users.list.items[state.chat.authUser.uid]
            ? state.chat.users.list.items[state.chat.authUser.uid]
            : null,
    error: state.chat.authUser.error,
    loading: state.chat.authUser.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchCurrentUser: (firestore: firebase.firestore.Firestore, id: string) =>
        dispatch(fetchCurrentUser(firestore, id)),
    receiveUserCredentials: (id: string, error: Error) =>
        dispatch(receiveUserCredentials(id, error)),
});

export const ChatInformerContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChatInformer);

export default ChatInformerContainer;
