import classNames from 'classnames';
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Box, Button, Menu, MenuItem, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    menuItem: {
        minHeight: 35,
        padding: '0 20px',
        color: theme.palette.primary.main,
    },
    activeMenuItem: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
    },
    button: {
        padding: 0,
        color: 'black',
        backgroundColor: 'transparent',
    },
}));

export type PositionsFilterMenuOption = 'all' | 'matched' | 'active';

export interface PositionsFilterMenuProps {
    className?: string;
    doPositionsBelongToMe: boolean;
    onChange: (value: PositionsFilterMenuOption) => void;
}

const PositionsFilterMenu = memo((props: PositionsFilterMenuProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);

    function closeMenu() {
        setMenuEl(null);
    }

    function changeFilter(type: PositionsFilterMenuOption = 'all') {
        return () => {
            props.onChange(type);
            setMenuEl(null);
        };
    }

    return (
        <Box className={rootClasses}>
            <Button
                size="small"
                color="default"
                endIcon={<ArrowDown />}
                className={classes.button}
                onClick={({ currentTarget }) => setMenuEl(currentTarget)}
            >
                View
            </Button>
            <Menu
                autoFocus
                keepMounted
                anchorEl={menuEl}
                onClose={closeMenu}
                open={Boolean(menuEl)}
            >
                <MenuItem
                    onClick={changeFilter('all')}
                    className={classNames(classes.menuItem)}
                >
                    All
                </MenuItem>
                <MenuItem
                    className={classes.menuItem}
                    onClick={changeFilter(
                        props.doPositionsBelongToMe ? 'active' : 'matched',
                    )}
                >
                    {props.doPositionsBelongToMe ? 'Selected' : 'Matched'}
                </MenuItem>
            </Menu>
        </Box>
    );
});

PositionsFilterMenu.displayName = 'PositionsFilterMenu';

export { PositionsFilterMenu };
