import { connect } from 'react-redux';
import firebase from 'firebase/app';
import CreateMessage from '../components/CreateMessage';
import { sendMessage } from '../store/roomMessages/actions';

const mapStateToProps = (state: any) => ({
    currentUser:
        state.chat.authUser.uid &&
        state.chat.users.list.items[state.chat.authUser.uid]
            ? state.chat.users.list.items[state.chat.authUser.uid]
            : null,
    activeRoom: state.chat.rooms.list.activeRoom,
});

const mapDispatchToProps = (dispatch: Function) => ({
    send: (
        firestore: firebase.firestore.Firestore,
        roomId: string,
        userId: string,
        message: string,
    ) => dispatch(sendMessage(firestore, roomId, userId, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMessage);
