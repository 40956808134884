import { CardMedia, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    avatar: {
        background: '#FFFFFF',
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.14)',
        borderColor: '#ffffff',
        borderStyle: 'solid',
        borderWidth: '6px',
        borderRadius: '6px',
    },
    avatarNoBorder: {
        background: '#FFFFFF',
        borderRadius: '6px',
    },
}));

export interface SquareAvatarProps {
    className?: string;
    image?: string;
    title?: string;
    isBorder?: boolean;
}

export const SquareAvatar = React.memo(function SquareAvatarComponent({
    image,
    title,
    className,
    isBorder,
}: SquareAvatarProps) {
    const classes = useStyles();

    const avatarClasses = !isBorder ? classes.avatarNoBorder : classes.avatar;

    return (
        <CardMedia
            image={image}
            component="img"
            alt={title}
            title={title}
            className={classNames(className, avatarClasses)}
        />
    );
});
