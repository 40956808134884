import { Box, Theme } from '@material-ui/core';
import { AnalyticsEvent, useAnalytics } from '@weco/common';
import {
    DepartmentEntityWithTestResultPercent,
    SkillEntity,
    SkillSetsEntity,
} from '@weco/core';
import {
    ActionButton,
    BottomSheet,
    DepartmentsRouting,
    RoleCard,
    UserSkillsOverview,
} from '@weco/ui';
import debug from 'debug';
import { toJS } from 'mobx';
import { useToggle } from 'react-use';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import { APP_EVENTS } from 'apps/client/src/app_events';
import { RouterPaths } from '../../../router/RouterPaths';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import { useMyUserProjectsData } from '../../../store/hooks/useMyUserProjectsData';
import { usePersonRolesData } from '../../../store/hooks/usePersonRolesData';
import { makeStyles } from '@material-ui/styles';
import { cloneDeep } from 'lodash';
import { useProfileProjectsData } from './skillsTabs/projects/useProfileProjectsData';
import { ProfileProjectsContainer } from './skillsTabs/projects/ProfileProjectsContainer';

const log = debug('SkillsTabPage');

const useStyles = makeStyles((theme: Theme) => ({
    startTestBox: {
        width: 290,
        margin: '40px auto',
    },
}));

export const SkillsTabPage = () => {
    return (
        <ProfileProjectsContainer>
            <SkillsTabPageContent />
        </ProfileProjectsContainer>
    );
};
export const SkillsTabPageContent = () => {
    /* ----------------------------- Styles ---------------------------- */
    const classes = useStyles();
    /* ----------------------------- Hooks/services ---------------------------- */
    const history = useHistory();
    const { event } = useAnalytics();
    /* --------------------------------- Stores. --------------------------------- */
    const { myOwnProjects, addNewProject } = useMyUserProjectsData();
    const {
        defaultRoles,
        allAddedRoles,
        addRole,
        updateRole,
        deleteRole,
        loadAutocompleteSkillsOptions,
        isThereFinishedTest,
        ...personRolesData
    } = usePersonRolesData();
    const { profile, areConnectionsLoading } = useMyUserData();
    const { created, joined, applied, invited } = useProfileProjectsData();

    const extractProjects = (connections) => {
        return connections.items?.map((i) => i.project);
    };
    const projectsIAmCreated = extractProjects(created);
    const projectsIAmJoined = extractProjects(joined);
    const projectsIAmInvitedTo = extractProjects(invited);
    const projectsIAppliedTo = extractProjects(applied);
    const viewerProjectsIds = [...projectsIAmCreated, ...projectsIAmJoined].map(
        (project) => project?.id,
    );
    const isDataLoading =
        areConnectionsLoading || personRolesData.isLoadingStoreData;
    /* --------------------------------- States. --------------------------------- */
    const [isBottomSheetOpen, toggleBottomSheet] = useToggle(false);
    const [selectedRole, setSelectedRole] = useState(null as SkillSetsEntity);
    const [skillsAutocompleteOptions, setSkillsAutocompleteOptions] = useState<
        SkillEntity[]
    >([]);

    const activeRoles = allAddedRoles.filter((i) => i.isSelected);
    const addedRoleNames = allAddedRoles.map((i) => i.name);
    const rolesForSuggest = defaultRoles
        .filter((i) => !addedRoleNames.includes(i.name))
        .concat(allAddedRoles.filter((i) => !i.isSelected));

    log('my profile: %O', toJS(profile));
    log('isBottomSheetOpen: ', isBottomSheetOpen);
    log('my own projects: %O ', toJS(myOwnProjects));
    log('skillsAutocompleteOptions: %O', skillsAutocompleteOptions);

    /* --------------------------------- Effects. --------------------------------- */
    useEffect(() => {
        log('Running position autocomplete effect.');
        if (!isBottomSheetOpen) setSkillsAutocompleteOptions([]);
        else {
            const departmentName = selectedRole?.department?.name;
            loadAutocompleteSkillsOptions(departmentName).then(
                setSkillsAutocompleteOptions,
            );
        }
    }, [
        isBottomSheetOpen,
        selectedRole?.department?.name,
        setSkillsAutocompleteOptions,
        loadAutocompleteSkillsOptions,
    ]);

    /* --------------------------------- Functions. --------------------------------- */
    function update(data: SkillSetsEntity, bottomSheetShouldClose?: boolean) {
        log('updatePosition() is called: %O', data);
        log('shouldCloseBottomSheet: ', bottomSheetShouldClose);
        event(
            new AnalyticsEvent(APP_EVENTS.USER_PROFILE_POSITION_SAVED, {
                department: data.department.name,
            }),
        );
        if (data.id) {
            updateRole(data);
        } else {
            addRole(data).then((i) => (data.id = i.id));
        }
        if (bottomSheetShouldClose) {
            toggleBottomSheet();
        }
    }

    function add(department: DepartmentEntityWithTestResultPercent) {
        const newRole = new SkillSetsEntity();
        newRole.name = 'New Role';
        newRole.ownerId = profile.id;
        newRole.department = department;
        newRole.departmentId = department.id;

        showRoleEditingPanel(newRole);
    }

    function goToSearchPage() {
        history.push(RouterPaths.search.projects);
    }

    function showRoleEditingPanel(skill: SkillSetsEntity) {
        setSelectedRole(skill);
        toggleBottomSheet(true);
    }

    function toggleRoleVisibility(skill: SkillSetsEntity) {
        log('Toggling position visibility.');
        //Make copy of default role if necessary
        const item = skill.isDefault ? cloneDeep(skill) : skill;
        item.isSelected = !item.isSelected;
        updateRole(item);
    }

    function addProjectAndRedirect(projectName: string) {
        return addNewProject(projectName)
            .then(({ id }) => {
                history.push(`${RouterPaths.myProject}/${id}`);
            })
            .catch(console.error);
    }

    return (
        <Box>
            <DepartmentsRouting
                onAddRole={add}
                areRolesEditable
                typeofDescriptionToUse="person"
                profile={profile}
                initialPage={
                    <UserSkillsOverview
                        isLoading={isDataLoading}
                        matchSkillSetsWith={[]}
                        userName={profile?.name}
                        arePositionsEditable
                        isThisMyProfileOverview
                        currentUserId={profile?.id}
                        joinedProjects={projectsIAmJoined}
                        createdProjects={projectsIAmCreated}
                        actionButtonLabel="Search Other Projects"
                        onActionButtonClick={goToSearchPage}
                        onEditButtonClick={showRoleEditingPanel}
                        roles={activeRoles}
                        projectsIamInvitedTo={projectsIAmInvitedTo}
                        // TODO rename this.
                        onCheckBoxClick={toggleRoleVisibility}
                        projectsIAppliedTo={projectsIAppliedTo}
                        onAddProject={addProjectAndRedirect}
                        onDeleteBtnClick={deleteRole}
                        viewerProjectsIds={viewerProjectsIds}
                    />
                }
                onVisibilitySwitch={toggleRoleVisibility}
                departments={personRolesData.departments}
                roles={activeRoles}
                rolesForSuggest={rolesForSuggest}
                onEditRoleButtonClick={showRoleEditingPanel}
                onDeleteBtnClick={deleteRole}
            />
            <Box
                className={classes.startTestBox}
                id="container-anchor-wecompatibility-test"
            >
                <ActionButton
                    label="WeCompatibility Test"
                    onClick={() => {
                        history.push(RouterPaths.quiz);
                    }}
                />
            </Box>
            <BottomSheet isOpen={isBottomSheetOpen} onClose={toggleBottomSheet}>
                <RoleCard
                    onSave={update}
                    data={selectedRole}
                    autocompleteOptions={skillsAutocompleteOptions}
                    onDeleteBtnClick={() => {
                        deleteRole(selectedRole);
                        toggleBottomSheet(false);
                    }}
                />
            </BottomSheet>
        </Box>
    );
};
