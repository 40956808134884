import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import DesktopModeToolbar from './Desktop/ToolbarContent';
import MobileModeToolbar from './Mobile/ToolbarContent';
import { useTopBarStyles } from './styles/TopBar.styles';
import classNames from 'classnames';

export interface TopBarProps {
    desktopMode: boolean;
    toggle: () => void;
}

export const TopBar = ({ toggle, desktopMode }: TopBarProps) => {
    const classes = useTopBarStyles();
    return (
        <AppBar
            position="fixed"
            className={classNames({
                [classes.appBarDesktop]: !!desktopMode,
                [classes.appBarMobile]: !desktopMode,
            })}
        >
            {!!desktopMode && <DesktopModeToolbar />}
            {!desktopMode && (
                <MobileModeToolbar handleDrawerLeftOpen={toggle} />
            )}
        </AppBar>
    );
};
