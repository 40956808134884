import React from 'react';
import MuiSelect, {
    SelectProps as MuiSelectProps,
} from '@material-ui/core/Select';
import { FieldProps } from 'formik';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Theme, InputLabel, FormControl } from '@material-ui/core';
import generateId from 'uniqid';

export interface WeSelectProps
    extends FieldProps,
        Omit<MuiSelectProps, 'name' | 'value'> {
    label: String;
}

export const fieldToSelect = ({
    disabled,
    field,
    defaultValue,
    form: { isSubmitting },
    ...props
}: WeSelectProps): MuiSelectProps => {
    return {
        disabled: disabled || isSubmitting,
        ...props,
        ...field,
        value: field.value || defaultValue || '',
    };
};

const useClasses = makeStyles((theme: Theme) => ({
    root: {},
    select: {},
}));

const StyledSelect = withStyles({
    select: {
        '&:focus': {
            background: 'transparent',
        },
    },
})(MuiSelect);

export const WeSelect = ({ classes, ...props }: WeSelectProps) => {
    const cls = useClasses();
    // NOTE: incase of many selects rendered at the same time, generate an id randomly.
    const labelId = generateId();
    return (
        <FormControl fullWidth>
            <InputLabel id={labelId}>{props.label}</InputLabel>
            <StyledSelect
                labelId={labelId}
                className={classes?.root || cls.root}
                classes={{ select: (classes && classes.select) || cls.select }}
                {...fieldToSelect(props)}
            />
        </FormControl>
    );
};
