import {
    PersonService,
    ProjectsService,
    ConnectionStatus,
    HappyConnectionType,
    PersonProjectConnection,
    HappyConnectionInterface,
    HappyConnection2Interface,
    HappyConnectionFactoryInterface,
    MatchPercentServiceInterface,
    UserProfileEntity,
} from '@weco/core';
import { CoreEventDispatcher, Event, Exceptions } from '@weco/common';
import { MyUserStore } from './MyUserStore';
import { action, observable } from 'mobx';
import { APP_EVENTS } from '../../app_events';
import { computedFn } from 'mobx-utils';
import {
    ConnectionsMap,
    RequestConnectionInterface,
} from './hooks/useMyHappyConnectionData';
import debug from 'debug';

const log = debug('MyConnectionsStore');

export class MyConnectionsStore implements HappyConnectionFactoryInterface {
    @observable areConnectionLoading = false;
    @observable chatConnectionLoadings: string[] = [];
    @observable connectionsMap: ConnectionsMap;
    @observable connections: PersonProjectConnection[];

    constructor(
        private myUserStore: MyUserStore,
        private personService: PersonService,
        private projectsService: ProjectsService,
        private eventDispatcher: CoreEventDispatcher,
        private percentRepository: MatchPercentServiceInterface,
    ) {
        this.connectionsMap = {};
    }

    loadConnections(): Promise<PersonProjectConnection[]> {
        this.areConnectionLoading = true;

        return this.personService
            .getPersonConnections(
                this.myUserStore.currentUserId,
                this.myUserStore.activeProjectId,
            )
            .then(async (connections) => {
                this.connections = connections;
                this.areConnectionLoading = false;
                return this.connections;
            });
    }

    @action.bound
    requestConnection: RequestConnectionInterface = ({
        projectId,
        personId,
        requestOwnerId,
        id,
    }) => {
        console.log('requestConnection() is called.');
        return this.personService
            .findPersonToProjectConnection({
                projectId: projectId,
                personId: personId,
            })
            .then((connection: PersonProjectConnection) => {
                const happyConnection: HappyConnection2Interface = {
                    projectId: projectId,
                    personId: personId,
                    active: false,
                    canHandleInvite: false,
                    canHandleApplication: false,
                    canRecallApplication: false,
                    canRecallInvitation: false,
                    data: connection,
                };
                if (!connection) {
                    return;
                }
                // Applicant can recall his-self application
                if (
                    connection.roles.indexOf('applicant') !== -1 &&
                    requestOwnerId === personId
                ) {
                    happyConnection.active = true;
                    happyConnection.canRecallApplication = true;
                    happyConnection.recall = () => {
                        this.setChatConnectionRequestStarted(id);
                        return this.recallApplication(projectId)
                            .then(() => {
                                return this.requestConnection({
                                    projectId,
                                    personId,
                                    requestOwnerId,
                                });
                            })
                            .finally(() => {
                                this.setChatConnectionRequestComplete(id);
                            });
                    };
                }

                // Owner can decline and approve applicant's application
                if (
                    connection.roles.indexOf('applicant') !== -1 &&
                    requestOwnerId === connection.project?.owner?.id
                ) {
                    happyConnection.active = true;
                    happyConnection.canHandleApplication = true;
                    happyConnection.decline = () => {
                        this.setChatConnectionRequestStarted(id);
                        return this.declineApplication(projectId, personId)
                            .then(() => {
                                return this.requestConnection({
                                    projectId,
                                    personId,
                                    requestOwnerId,
                                });
                            })
                            .finally(() => {
                                this.setChatConnectionRequestComplete(id);
                            });
                    };

                    happyConnection.connect = async () => {
                        this.setChatConnectionRequestStarted(id);
                        const requiredEmail = this.connections.find(
                            (item) =>
                                item.projectId === connection.projectId &&
                                item.personId === connection.personId,
                        )?.person.contacts?.email;
                        return this.acceptApplication(
                            projectId,
                            personId,
                            requiredEmail,
                        )
                            .then(() => {
                                return this.requestConnection({
                                    projectId,
                                    personId,
                                    requestOwnerId,
                                });
                            })
                            .finally(() => {
                                this.setChatConnectionRequestComplete(id);
                            });
                    };
                }

                // Invitee can recall his-self invitation
                if (
                    connection.roles.indexOf('invitee') !== -1 &&
                    requestOwnerId === connection.project?.owner?.id
                ) {
                    happyConnection.active = true;
                    happyConnection.canRecallInvitation = true;
                    happyConnection.recall = () => {
                        this.setChatConnectionRequestStarted(id);
                        return this.recallInvitation(projectId, personId)
                            .then(() => {
                                return this.requestConnection({
                                    projectId,
                                    personId,
                                    requestOwnerId,
                                });
                            })
                            .finally(() => {
                                this.setChatConnectionRequestComplete(id);
                            });
                    };
                }

                // Invitee can decline and approve invitation
                if (
                    connection.roles.indexOf('invitee') !== -1 &&
                    requestOwnerId === personId
                ) {
                    happyConnection.active = true;
                    happyConnection.canHandleInvite = true;
                    happyConnection.decline = () => {
                        this.setChatConnectionRequestStarted(id);
                        return this.declineInvitation(projectId)
                            .then(() => {
                                return this.requestConnection({
                                    projectId,
                                    personId,
                                    requestOwnerId,
                                });
                            })
                            .finally(() => {
                                this.setChatConnectionRequestComplete(id);
                            });
                    };

                    happyConnection.connect = async () => {
                        this.setChatConnectionRequestStarted(id);
                        const owner = await this.personService.findById(
                            connection.project.owner.id,
                        );
                        return this.acceptInvitation(
                            projectId,
                            owner.contacts.email,
                        )
                            .then(() => {
                                return this.requestConnection({
                                    projectId,
                                    personId,
                                    requestOwnerId,
                                });
                            })
                            .finally(() => {
                                this.setChatConnectionRequestComplete(id);
                            });
                    };
                }

                // TODO add all conditions

                // TODO don't forget update map state after API requests (when data was changed in DB)
                this.connectionsMap[
                    JSON.stringify({ projectId, personId, requestOwnerId })
                ] = happyConnection;
            });
    };

    // const clearMap = async () => {
    //     delete this.connectionsMap[JSON.stringify({projectId, personId, requestOwnerId})];
    //     return this.requestConnection({projectId, personId, requestOwnerId});
    // };

    createConnectionFactory = computedFn(
        (
            from: string,
            to: string,
            type: HappyConnectionType,
        ): HappyConnectionInterface => {
            let status = ConnectionStatus.NONE;
            let existedConnection;
            if (type === HappyConnectionType.PERSON_TO_PROJECT) {
                existedConnection = this.connections.find(
                    (prj) => prj.projectId === to && prj.personId === from,
                );
            } else {
                existedConnection = this.connections.find(
                    (prj) => prj.personId === to && prj.projectId === from,
                );
            }

            if (existedConnection) {
                switch (existedConnection.status) {
                    case 'OWNER': {
                        throw new Exceptions.LogicException(
                            `The Owner unable to send Connection request to self`,
                        );
                    }
                    case 'MEMBER': {
                        status = ConnectionStatus.CONNECTED;
                        break;
                    }
                    case 'APPLICANT': {
                        status = ConnectionStatus.APPLICANT;
                        break;
                    }
                    case 'INVITEE': {
                        status = ConnectionStatus.INVITEE;
                        break;
                    }
                    default: {
                        status = ConnectionStatus.NONE;
                        break;
                    }
                }
            }
            const happyConnection: HappyConnectionInterface = {
                from,
                to,
                status,
                type,
                payload: {
                    person: existedConnection
                        ? existedConnection.person
                        : undefined,
                    project: existedConnection
                        ? existedConnection.project
                        : undefined,
                },
                connect:
                    type === HappyConnectionType.PERSON_TO_PROJECT
                        ? personToProjectConnectFactory(this)
                        : projectToPersonConnectFactory(this),
                decline:
                    type === HappyConnectionType.PERSON_TO_PROJECT
                        ? personToProjectDeclineFactory(this)
                        : projectToPersonDeclineFactory(this),
            };
            happyConnection.connect = happyConnection.connect.bind(
                happyConnection,
            );
            happyConnection.decline = happyConnection.decline.bind(
                happyConnection,
            );

            return happyConnection;
        },
    );

    @action.bound
    async applyToProject(projectId: string) {
        await this.projectsService.applyToProject(projectId);
        this.eventDispatcher.dispatch(
            APP_EVENTS.APPLY_TO_PROJECT,
            new Event({
                userId: this.myUserStore.profile.id,
                userName: this.myUserStore.profile.name,
                projectName: this.myUserStore.profile.activeProject.name,
                projectId: this.myUserStore.profile.activeProject.id,
                email:
                    this.myUserStore.profile.contacts &&
                    this.myUserStore.profile.contacts.email,
            }),
            // new Event(this.profile, projectId)
        );
        return this.loadConnections();
    }

    @action.bound
    async acceptInvitation(projectId: string, email: string): Promise<any> {
        await this.projectsService.acceptInvitation(projectId);
        this.eventDispatcher.dispatch(
            APP_EVENTS.PROFILE_ACCEPT,
            new Event({
                userId: this.myUserStore.profile.id,
                userName: this.myUserStore.profile.name,
                email,
            }),
        );
        return this.loadConnections();
    }

    @action.bound
    async recallApplication(projectId: string): Promise<any> {
        await this.projectsService.recallApplication(projectId);
        return this.loadConnections();
    }

    @action.bound
    async leaveProject(projectId: string): Promise<any> {
        await this.projectsService.leaveProject(projectId);
        return this.loadConnections();
    }

    @action.bound
    async declineInvitation(projectId: string): Promise<any> {
        await this.projectsService.declineInvitation(projectId);
        // this.eventDispatcher.dispatch(
        //     APP_EVENTS.PROJECT_INVITE_RECALL_REMOVE,
        //     new Event(projectName: this.project.name,
        //                 projectId: this.project.id)
        // );
        return this.loadConnections();
    }

    // ----
    @action.bound
    async inviteToProject(projectId: string, userId: string): Promise<any> {
        await this.projectsService.inviteToProject(userId, projectId);
        // this.eventDispatcher.dispatch(
        //     APP_EVENTS.INVITE_TO_PROJECT,
        //     new Event(this.profile)
        // );
        return this.loadConnections();
    }

    @action.bound
    async declineApplication(projectId: string, userId: string): Promise<any> {
        await this.projectsService.declineApplication(userId, projectId);
        return this.loadConnections();
    }

    @action.bound
    async acceptApplication(
        projectId: string,
        userId: string,
        email: string,
    ): Promise<any> {
        await this.projectsService.acceptApplication(userId, projectId);
        this.eventDispatcher.dispatch(
            APP_EVENTS.PROJECT_ACCEPT,
            new Event({
                userId: this.myUserStore.profile.id,
                userName: this.myUserStore.profile.name,
                email,
            }),
        );
        return this.loadConnections();
    }

    @action.bound
    async recallInvitation(projectId: string, userId: string): Promise<any> {
        await this.projectsService.recallInvitation(userId, projectId);
        return this.loadConnections();
    }

    @action.bound
    async removeProjectMember(projectId: string, userId: string): Promise<any> {
        await this.projectsService.removeProjectMember(userId, projectId);
        return this.loadConnections();
    }

    @action.bound
    setChatConnectionRequestStarted(id: string) {
        this.chatConnectionLoadings.push(id);
    }

    @action.bound
    setChatConnectionRequestComplete(id: string) {
        this.chatConnectionLoadings = this.chatConnectionLoadings.filter(
            (cid) => cid !== id,
        );
    }
}

//  HappyConnectionType.PERSON_TO_PROJECT
function personToProjectConnectFactory(
    self: MyConnectionsStore,
): () => Promise<HappyConnectionInterface> {
    return function () {
        if (this.status === ConnectionStatus.NONE) {
            return self.applyToProject(this.to);
        }
        if (this.status === ConnectionStatus.APPLICANT) {
            return self.acceptApplication(this.to, this.from, null);
        }
        return Promise.reject(
            new Exceptions.LogicException(`Unable to connect with Project`),
        );
    };
}

function personToProjectDeclineFactory(
    self: MyConnectionsStore,
): () => Promise<HappyConnectionInterface> {
    return function () {
        if (this.status === ConnectionStatus.APPLICANT) {
            return self.recallApplication(this.to);
        }
        if (this.status === ConnectionStatus.INVITEE) {
            return self.declineInvitation(this.to);
        }
        if (this.status === ConnectionStatus.CONNECTED) {
            return self.leaveProject(this.to);
        }
        return Promise.reject(
            new Exceptions.LogicException(`Unable to disconnect from Project`),
        );
    };
}

// HappyConnectionType.PROJECT_TO_PERSON
function projectToPersonConnectFactory(
    self: MyConnectionsStore,
): () => Promise<HappyConnectionInterface> {
    return function () {
        if (this.status === ConnectionStatus.NONE) {
            return self.inviteToProject(this.from, this.to);
        }
        if (this.status === ConnectionStatus.APPLICANT) {
            return self.acceptApplication(this.from, this.to, null);
        }
        if (this.status === ConnectionStatus.INVITEE) {
            return self.acceptInvitation(this.from, null);
        }
        return Promise.reject(
            new Exceptions.LogicException(`Unable to connect with Project`),
        );
    };
}

function projectToPersonDeclineFactory(
    self: MyConnectionsStore,
): () => Promise<HappyConnectionInterface> {
    return function () {
        if (this.status === ConnectionStatus.INVITEE) {
            return self.recallInvitation(this.from, this.to);
        }
        if (this.status === ConnectionStatus.APPLICANT) {
            return self.declineApplication(this.from, this.to);
        }
        if (this.status === ConnectionStatus.CONNECTED) {
            return self.removeProjectMember(this.from, this.to);
        }
        return Promise.reject(
            new Exceptions.LogicException(`Unable to disconnect from Project`),
        );
    };
}
