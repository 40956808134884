import {
    COMMON_SERVICES,
    EventSubscriberInterface,
    Inject,
    Injectable,
} from '@weco/common';
import { APP_EVENTS } from '../../../app_events';
import { AuthEvent } from '../../events/AuthEvent';
import { HelpHero } from 'helphero';
import { SERVICES } from '../services';
import { ProfileUpdatedEvent } from '../../events/ProfileEvents';

@Injectable()
export class HelpHeroEventSubscriber implements EventSubscriberInterface {
    subscribedEvents: Map<string | RegExp, (event: any) => void> = new Map();

    @Inject(SERVICES.HelpHero)
    helpHero: HelpHero;

    constructor() {
        this.subscribedEvents.set(
            APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
            this.identify,
        );
        this.subscribedEvents.set(
            APP_EVENTS.USER_PROFILE_UPDATED,
            this.userProfileUpdated,
        );
    }

    identify = (event: AuthEvent) => {
        if (event.subject.isAuthenticated() && event.subject.getUser().id) {
            this.helpHero.identify(event.subject.getUser().id, {
                name:
                    event.subject.getUser().username ||
                    event.subject.getUser().email,
            });
        } else {
            this.helpHero.anonymous();
        }
    };

    private userProfileUpdated = (event: ProfileUpdatedEvent) => {
        this.helpHero.update({
            isActivated: event.profile.isActivated,
            isNotActivated: !event.profile.isActivated,
            filledPercentage: event.profile.filledPercentage,
        });
    };
}
