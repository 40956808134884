import { loadScript } from '../../helpers';
import { AnalyticsProviderInterface } from '../AnalyticsProviderInterface';
import { AnalyticsEvent, AuthAnalyticsEvent } from '../AnalyticsEvent';

export class GTMProvider implements AnalyticsProviderInterface {
    private dataLayer: any[] = [];
    //https://www.googletagmanager.com/gtm.js?id=GTM-NCVNWT7&gtm_auth=2ngouPjl1tcv7e4JNQEiWQ&gtm_preview=env-17&gtm_cookies_win=x&gtm_debug=x
    //https://www.googletagmanager.com/gtm.js?id=GTM-NCVNWT7&gtm_auth=qpoolMdCnq4qgU9Gm20PEA&gtm_preview=env-8&gtm_cookies_win=x

    constructor(private settings) {}

    load(): Promise<boolean> {
        const { id, preview, auth, debug } = this.settings;
        return loadScript(
            `https://www.googletagmanager.com/gtm.js?id=${id}&gtm_auth=${auth}&gtm_preview=${preview}&gtm_cookies_win=x${
                debug ? '&gtm_debug=x' : ''
            }`,
        )
            .then(() => {
                let preLoadedEvents = [];
                if (
                    Array.isArray(this.dataLayer) &&
                    this.dataLayer.length > 0
                ) {
                    preLoadedEvents = this.dataLayer;
                }
                this.dataLayer = (window as any).dataLayer || [];
                this.dataLayer.push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js',
                });
                if (preLoadedEvents) {
                    preLoadedEvents.forEach((e) => this.dataLayer.push(e));
                }
                return true;
            })
            .catch((e) => {
                console.log('Unable to load GTM', e);
                return false;
            });
        // (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        //         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        //     'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=qpoolMdCnq4qgU9Gm20PEA&gtm_preview=env-8&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        // })(window,document,'script','dataLayer','GTM-NCVNWT7')
    }

    event = (event: AnalyticsEvent) => {
        const [category, ...act] = event.name.split('_');
        const action = []
            .concat(act)
            .filter((a) => !!a)
            .join('_')
            .toLowerCase();
        this.dataLayer.push({
            event: `WECO_${event.name}`,
            category,
            action,
            payload: event.payload,
            ...(event.options?.gtm || {}),
        });
    };

    click = (event: AnalyticsEvent) => {
        this.dataLayer.push({
            event: 'WE_CLICK',
            payload: event.payload,
            ...(event?.options?.gtm || {}),
        });
    };

    exception = (e: Error, severity: string = 'LOW'): void => {
        this.dataLayer.push({
            event: 'EXCEPTION',
            payload: { severity, message: e.message, type: e.name },
        });
    };
    identify = (event: AuthAnalyticsEvent) => {
        this.dataLayer.push({
            event: event.name,
            userId: event.userId,
            userEmail: event.payload?.email || null,
            payload: event.payload,
            lastSessionUTC: new Date().toUTCString(),
        });
    };
    pageView = (url: string) => {
        // this.dataLayer.push({
        //     event: 'PageView',
        //     payload: { url },
        // })
    };
}
