import { action, observable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import { AppStore } from './AppStore';
import { SERVICES } from '../services/services';
import {
    AnalyticsService,
    COMMON_SERVICES,
    CoreEventDispatcher,
    Event,
    Inject,
    Service,
} from '@weco/common';
import { APP_EVENTS } from '../../app_events';
import { MyUserStore } from './MyUserStore';

@Service()
export class RootStore {
    pages: { [name: string]: any } = {};
    // @Inject(name => AppStore)
    @Inject(SERVICES.AppStore)
    app: AppStore;
    @Inject(COMMON_SERVICES.AnalyticsService)
    analyticsService: AnalyticsService;
    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    eventDispatcher: CoreEventDispatcher;
    routing = new RouterStore();
    myProjectsStore: any;
    @Inject(MyUserStore)
    myUserStore: MyUserStore;

    @observable isLoaded: boolean = false;

    // constructor() {

    // autorun(() => {
    //     if (this.ui.title !== document.title) {
    //         document.title = this.ui.title;
    //     }
    // });
    // reaction(() => {
    //     return this.app.token;
    // }, this.onUserTokenChanged);
    // }

    @action bootstrap() {
        return this.analyticsService.load().then(() => {
            return this.app.bootstrap().then(() => {
                this.routing.history.listen((event) => {
                    this.eventDispatcher.dispatch(
                        APP_EVENTS.APP_ON_ROUTE_CHANGED,
                        new Event(event),
                    );
                });
                // TODO: remember everything. presumably a crash fix
                if (this.app.token.isAuthenticated()) {
                    return this.myUserStore.load().then(() => {
                        this.isLoaded = true;
                        return true;
                    });
                }
                this.isLoaded = true;
                return true;
            });
        });
    }
}
