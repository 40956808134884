import {
    Event,
    Inject,
    Exceptions,
    Injectable,
    Transformers,
    getPictureUrl,
    COMMON_SERVICES,
    CoreEventDispatcher,
    NoticeServiceInterface,
    difference,
} from '@weco/common';
import { action, computed, observable, runInAction } from 'mobx';
import {
    PersonConstants,
    PersonService,
    Picture,
    ProjectEntity,
    ProjectsService,
    UserProfileEntity,
    defaultPicture,
    CORE_SERVICES,
    MatchPercentServiceInterface,
} from '@weco/core';
import { AppStore } from './AppStore';
import { SERVICES } from '../services/services';
import { APP_EVENTS } from '../../app_events';
import { MyConnectionsStore } from './MyConnectionsStore';
import isEmpty from 'lodash/isEmpty';
import { filled } from '@weco/chat';
import { ProfileUpdatedEvent } from '../events/ProfileEvents';

@Injectable()
export class MyUserStore {
    @Inject(PersonService)
    private personService: PersonService;

    @Inject(ProjectsService)
    private projectsService: ProjectsService;
    @Inject(SERVICES.AppStore)
    private appStore: AppStore;

    @Inject(COMMON_SERVICES.NoticeStore)
    private notice: NoticeServiceInterface;

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    private eventDispatcher: CoreEventDispatcher;

    @Inject(CORE_SERVICES.MatchPercentServiceInterface)
    percentRepository: MatchPercentServiceInterface;

    @observable profile: UserProfileEntity;
    @observable constants: PersonConstants;

    @observable myOwnProjects: ProjectEntity[];
    @observable computedProfilePicture: string;

    happyConnectionsStore?: MyConnectionsStore;

    // TODO: not needed? Remove this?
    getProfilePicture = (picture: Picture) => {
        if (!this.computedProfilePicture) {
            this.computedProfilePicture = getPictureUrl(
                picture,
                defaultPicture,
                true,
            );
        }
        return this.computedProfilePicture;
    };

    @action.bound
    async loadPersonConstance() {
        if (!this.constants) {
            this.constants = await this.personService.getConstants();
        }
    }

    @computed
    get currentUserId(): string | undefined {
        return this.appStore.currentUser?.id;
    }

    @computed
    get activeProjectId(): string | undefined {
        return this.profile?.activeProject?.id;
    }

    @action.bound
    async load(): Promise<void> {
        try {
            this.happyConnectionsStore = new MyConnectionsStore(
                this,
                this.personService,
                this.projectsService,
                this.eventDispatcher,
                this.percentRepository,
            );
            const dataLoaders = [];
            dataLoaders.push(this.loadPersonConstance());
            if (this.currentUserId) {
                dataLoaders.push(this.loadProfile());
                dataLoaders.push(this.loadMyOwnProjects());
            }
            await Promise.all(dataLoaders);
        } catch (e) {
            console.error(e);
        }
    }

    @action.bound
    async loadProfile(): Promise<UserProfileEntity> {
        if (!this.currentUserId) {
            throw new Exceptions.InvalidCredentialsException(
                'Not authorized to be here',
            );
        }
        try {
            const result = await this.personService.findByDetailedProfileId(
                this.currentUserId,
            );
            runInAction(() => {
                this.computedProfilePicture = getPictureUrl(
                    result.picture,
                    defaultPicture,
                );
                this.profile = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    async loadMyOwnProjects(): Promise<ProjectEntity[]> {
        if (!this.currentUserId) {
            throw new Exceptions.InvalidCredentialsException(
                'Not authorized to be here',
            );
        }
        try {
            // const result = await this.projectsService.getMyOwnProjectsList();
            const connections = await this.happyConnectionsStore.loadConnections();
            const result = connections
                .filter((item) => {
                    return item.roles?.includes('owner');
                })
                .map((item) => item.project)
                .filter((item) => item);

            runInAction(() => {
                this.myOwnProjects = result;
            });

            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    async addNewProject(name: string): Promise<{ id: string; name: string }> {
        let result;
        try {
            result = await this.projectsService.createProject(name);
        } catch (e) {
            this.notice.error(e.message || e);
        }

        const isFirstProject = isEmpty(this.myOwnProjects);

        if (isFirstProject) {
            this.eventDispatcher.dispatch(
                APP_EVENTS.CREATE_FIRST_PROJECT,
                new Event({
                    userId: this.profile.id,
                    userName: this.profile.name,
                    projectName: result.name,
                    projectId: result.id,
                    email: this.profile.contacts && this.profile.contacts.email,
                    totalProjects: 1,
                }),
            );
            this.notice.success(
                `Congratulations! You Create Your First Project!`,
            );
        } else {
            this.notice.success(`New Project created successfully`);
            this.eventDispatcher.dispatch(APP_EVENTS.CREATE_NEW_PROJECT_AGAIN, {
                totalProjects: this.myOwnProjects.length + 1,
            });
        }
        await this.projectsService.changeMyActiveProject(result.id);
        await this.loadProfile();
        await this.loadMyOwnProjects();
        return result;
    }

    async updateActiveProject(id: string): Promise<boolean> {
        if (this.myOwnProjects.length > 0 && this.activeProjectId === id) {
            await this.changeMyActiveProject(this.myOwnProjects[0].id);
            return true;
        }
        if (this.myOwnProjects.length === 0) {
            await this.changeMyActiveProject(null);
        }
        return false;
    }

    @action.bound
    async deleteAccount(id: string): Promise<any> {
        let result;
        try {
            result = await this.personService.deleteAccount(id);
            console.log('deletion result', result);
            this.notice.success(`Account successfully deleted`);
            return true;
        } catch (e) {
            this.notice.error(`Unable to delete Account`);
            return false;
        }
    }

    @action.bound
    async deleteProject(id: string): Promise<any> {
        let result;
        try {
            result = await this.projectsService.deleteProject(id);
            this.notice.success(`Project successfully deleted`);
        } catch (e) {
            this.notice.error(`Unable to delete Project`);
        }
        await this.loadMyOwnProjects();
        await this.updateActiveProject(id);
        return result;
    }

    @action.bound
    async changeMyActiveProject(projectId: string) {
        try {
            await this.projectsService.changeMyActiveProject(projectId);
            if (this.myOwnProjects.length > 0) {
                this.notice.success(`Your active Project has been changed`);
            }
        } catch (e) {
            this.notice.error(e.message || e);
        }
        await this.loadProfile();
    }

    @computed
    get activeProject(): ProjectEntity | undefined {
        return this.profile?.activeProject;
    }

    @action.bound
    async changeProfileName(name: string, lastName: string) {
        await this.updateProfile({
            ...this.profile,
            ...{ name, lastName },
        } as UserProfileEntity);
    }

    @action.bound
    async updateProfile(profile: UserProfileEntity) {
        if (profile.passions !== this.profile.passions) {
            profile.passions = profile.passions.map((passion) =>
                passion?.toLowerCase(),
            );
        }

        let result: UserProfileEntity;
        try {
            result = await this.personService.updateProfile(
                new Transformers.TypeToTypeTransformer<UserProfileEntity>(
                    UserProfileEntity,
                ).transform(profile),
            );
            const updatedEvent = new ProfileUpdatedEvent(
                difference(this.profile, {
                    maxDeep: 2,
                    ignoreFields: ['createdAt', 'updatedAt', 'activeProject'],
                }),
                result,
            );
            if (this.profile.isActivated !== result.isActivated) {
                this.eventDispatcher.dispatch(
                    result.isActivated
                        ? APP_EVENTS.USER_PROFILE_ACTIVATED
                        : APP_EVENTS.USER_PROFILE_DEACTIVATED,
                    updatedEvent,
                );
            }
            this.eventDispatcher.dispatch(
                APP_EVENTS.USER_PROFILE_UPDATED,
                updatedEvent,
            );
        } catch (e) {
            this.notice.error(e.message || e);
        }
        runInAction(() => {
            this.profile = result;
        });
    }

    @action.bound
    async updateImg(profile: UserProfileEntity) {
        let result;
        try {
            result = await this.personService.updateProfile(
                new Transformers.TypeToTypeTransformer<UserProfileEntity>(
                    UserProfileEntity,
                ).transform(profile),
            );
            const updatedEvent = new ProfileUpdatedEvent(
                difference(this.profile, {
                    maxDeep: 2,
                    ignoreFields: ['createdAt', 'updatedAt', 'activeProject'],
                }),
                result,
            );
            if (this.profile.isActivated !== result.isActivated) {
                this.eventDispatcher.dispatch(
                    result.isActivated
                        ? APP_EVENTS.USER_PROFILE_ACTIVATED
                        : APP_EVENTS.USER_PROFILE_DEACTIVATED,
                    updatedEvent,
                );
            }
            this.eventDispatcher.dispatch(
                APP_EVENTS.USER_PROFILE_UPDATED,
                updatedEvent,
            );
        } catch (e) {
            this.notice.error(e.message || e);
        }

        runInAction(() => {
            this.computedProfilePicture = getPictureUrl(
                result.picture,
                defaultPicture,
            );
            this.profile = result;
        });
    }

    updateProjectPicture(item: ProjectEntity) {
        this.myOwnProjects = this.myOwnProjects.map((project) => {
            if (project.id === item.id) {
                project.picture = item.picture;
            }
            return project;
        });
    }

    async cleanup() {
        this.profile = null;
        this.constants = null;
        this.myOwnProjects = null;
        this.computedProfilePicture = null;
        this.happyConnectionsStore = null;
    }
}
