import {
    Box,
    CircularProgress,
    IconButton,
    Theme,
    Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ForumIcon from '@material-ui/icons/Forum';
import {
    Avatar,
    ShareButton,
    AvatarLoadingAnimation,
    UnauthorizedRedirectOnclick,
    SpriteSvgIcon,
} from '@weco/ui';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { useToggle } from 'react-use';
import { useControlPanelStyles } from '../../../components/molecules/ProjectProfilePage/styles/ControlPanel.styles';
import { usePersonProfileData } from './store/usePersonProfileData';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { useChatData } from '../../../store/hooks/useChatData';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import { openChat } from '../../../helpers/utils';
import ProfileHeaderBackground from '../../../../assets/images/profile-project/ProfileHeader.png';
import { RouterPaths } from '../../../router/RouterPaths';
import { useCoolData } from '../../../store/hooks/useCoolData';
import { useCoolStyles } from '../../../components/molecules/ProjectProfilePage/styles/CoolStyles';
import { UsersCoolDrawer } from '../../../components/molecules/UsersCoolDrawer';

const useStyles = makeStyles((theme: Theme) => ({
    profileHeader: {
        background: `url(${ProfileHeaderBackground}) no-repeat center top / cover`,
    },
}));

interface UserProfileHeaderProps {
    isAuthenticated?: boolean;
}

export const UserProfileHeader = (props: UserProfileHeaderProps) => {
    const classes = useStyles();
    const coolClasses = useCoolStyles();
    const controlPanelClasses = useControlPanelStyles();
    const {
        profile: { name, lastName, id, picture, settings },
        matchPercent,
        loading,
        getProfilePicture,
    } = usePersonProfileData();
    const { currentUserId } = useMyUserProfileData();
    const { createRoom } = useChatData();
    const { activeProject } = useMyUserData();
    const { cools } = useCoolData(id, 'Project');

    const history = useHistory();
    const [chatLoading, setChatLoading] = useState(false);
    const [coolPanelOpen, setCoolPanelOpen] = useToggle(false);

    if (currentUserId === id) {
        history.push(`${RouterPaths.myProfile}`);
    }

    return (
        <Box
            className={classNames(
                controlPanelClasses.root,
                classes.profileHeader,
            )}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {loading && <AvatarLoadingAnimation variant="circle" />}
                {!loading && (
                    <Avatar
                        isAuthenticated={props.isAuthenticated}
                        picture={getProfilePicture()}
                        shape="round"
                        withBorder
                        percent={matchPercent}
                        percentagePlacement="bottom-center"
                        size="large"
                        withShadow
                    />
                )}
                <div className={coolClasses.cools} onClick={setCoolPanelOpen}>
                    <SpriteSvgIcon id="cool-sign" />
                    <Box
                        className={
                            !cools?.find((p) => p.ownerId === currentUserId)
                                ? coolClasses.coolsSpan
                                : classNames({
                                      [coolClasses.coolsSpan]: true,
                                      [coolClasses.coolSpanTagged]: true,
                                  })
                        }
                    >
                        {cools?.length || 0}
                    </Box>
                </div>
            </Box>
            <Box className={controlPanelClasses.bottomBar}>
                <Box>
                    <ShareButton
                        activeProject={activeProject}
                        iconColor="white"
                        type="person"
                        dropdownDirection="down"
                        shareId={id}
                        picture={picture}
                    />
                </Box>
                <Typography variant="h2" className={controlPanelClasses.name}>
                    {name} {lastName}
                </Typography>
                <Box className={controlPanelClasses.bottomControlBox}>
                    <Box className={controlPanelClasses.bottomForumBox}>
                        <UnauthorizedRedirectOnclick>
                            <IconButton
                                className={controlPanelClasses.chatIcon}
                                onClick={() => {
                                    setChatLoading(true);
                                    openChat({
                                        createRoom,
                                        history,
                                        id,
                                        currentUserId,
                                        profile: {
                                            id,
                                            name,
                                            lastName,
                                            picture,
                                        },
                                    }).then(() => setChatLoading(false));
                                }}
                                disabled={chatLoading}
                            >
                                {chatLoading && (
                                    <CircularProgress
                                        size={24}
                                        color="secondary"
                                    />
                                )}
                                {!chatLoading && <ForumIcon />}
                            </IconButton>
                        </UnauthorizedRedirectOnclick>
                    </Box>
                </Box>
            </Box>
            {coolPanelOpen && (
                <UsersCoolDrawer
                    id={id}
                    type="Person"
                    setCoolPanelOpen={setCoolPanelOpen}
                    coolPanelOpen={coolPanelOpen}
                />
            )}
        </Box>
    );
};
