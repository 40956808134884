import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Theme } from '@material-ui/core';
import { SpriteSvgIcon, useNotice } from '@weco/ui';
import { makeStyles } from '@material-ui/styles';
import { CoolEntity } from '../../../../core/src/lib/entity/CoolEntity';
import {
    AddCoolInterface,
    DeleteCoolInputInterface,
    HasCoolInputInterface,
} from '../../../../core/src/lib/repositories/CoolRepository';
import { useMyUserProfileData } from '../../../../../apps/client/src/app/store/hooks/useMyUserProfileData';
import { WE_CLICKS } from '../../../../../apps/client/src/we_clicks';

export interface CoolActionsPropsInterface {
    loading: boolean;

    addCool(variables: AddCoolInterface): Promise<CoolEntity>;

    deleteCool(variables: DeleteCoolInputInterface): Promise<CoolEntity>;

    hasCool(variables: HasCoolInputInterface): Promise<CoolEntity | null>;

    entityType: 'Person' | 'Project';
    entityId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%',
        width: '120px',
        display: 'flex',
        justifyContent: 'center',
    },
    coolButton: {
        background: '#857eef',
        border: 'none',
        height: '100%',
    },
    coolSign: {
        marginLeft: '-23px',
        transform: 'scale(0.8)',
    },
    coolWord: {
        marginLeft: '20px',
        transform: 'scale(2.6)',
    },
    taggedCool: {
        background: 'linear-gradient(0.25turn, #FF679B, #FF8960)',
        border: 'none',
        height: '100%',
    },
}));

export const CoolActions = ({
    loading,
    addCool,
    deleteCool,
    hasCool,
    entityType,
    entityId,
}: CoolActionsPropsInterface) => {
    const classes = useStyles();
    const { warn } = useNotice();
    const { currentUserId } = useMyUserProfileData();
    const [cool, setCool] = useState(null);

    useEffect(() => {
        hasCool({
            ownerId: currentUserId,
            entityType,
            entityId,
        }).then((c) => {
            setCool(c);
        });
    }, []);

    return (
        <Box className={classes.root}>
            <Button
                id={!cool ? WE_CLICKS.COOL_UP : WE_CLICKS.COOL_DOWN}
                className={!cool ? classes.coolButton : classes.taggedCool}
                onClick={() => {
                    if (!cool) {
                        addCool({
                            ownerId: currentUserId,
                            coolOwnerId: currentUserId,
                            entityType,
                            entityId,
                        })
                            .then((c) => {
                                setCool(c);
                            })
                            .catch((err) => warn(err.message));
                    } else {
                        deleteCool({
                            id: cool.id,
                        })
                            .then(() => {
                                setCool(null);
                            })
                            .catch((err) => warn(err.message));
                    }
                }}
            >
                <SpriteSvgIcon id="cool-sign" className={classes.coolSign} />
                <SpriteSvgIcon id="cool-word" className={classes.coolWord} />
            </Button>
        </Box>
    );
};
