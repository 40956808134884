import debug from 'debug';

const log = debug('storybookHelpers');

// deprecated POC
export function detectStorybookEnvironment(): boolean {
    const { STORYBOOK_IS_RUNNING } = process.env;
    const booleanValue = STORYBOOK_IS_RUNNING === 'true';
    log('STORYBOOK_IS_RUNNING: ', STORYBOOK_IS_RUNNING);
    log('value to return: ', booleanValue);
    return booleanValue;
}
