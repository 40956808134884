import React from 'react';
import { IconButton, makeStyles, Theme } from '@material-ui/core';
import { SpriteSvgIcon } from '@weco/ui';

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        display: 'none',
    },
    editIcon: {
        backgroundColor: theme.palette.primary[150],
        padding: '10px',
        width: '32px',
        height: '32px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        '&.MuiIconButton-root:hover': {
            backgroundColor: theme.palette.primary[150],
        },
    },
    editIconSvg: {
        fontSize: '12px',
    },
}));

const UploadButton = ({ handleInputChange }) => {
    const classes = useStyles();
    return (
        <IconButton component="label" className={classes.editIcon}>
            <input
                accept="image/*"
                type="file"
                id="file"
                className={classes.input}
                onChange={handleInputChange}
            />
            <SpriteSvgIcon
                id="editIcon"
                classes={{ svg: classes.editIconSvg }}
            />
        </IconButton>
    );
};

export default UploadButton;
