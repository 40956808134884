import { FormControl } from '@material-ui/core';
import { TypeValueInterface } from '@weco/core';
import { WeTextField, SectionWithTitle } from '@weco/ui';
import { Field } from 'formik';
import React from 'react';
import { validateUrl } from '@weco/common';
import * as EmailValidator from 'email-validator';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';

export interface ContactsFormControlProps {
    name: string;
    label: string;
    options: TypeValueInterface[];
}

const urlValidation = ['website'];

// TODO move components in this folder to storybook.
export const ContactsFormControl = ({
    options,
    label,
    name,
}: ContactsFormControlProps) => {
    const classes = useTabStyles();
    // todo: this component should be improved, not the best approach
    const validate = (data, item) => {
        if (!data) {
            return;
        }
        if (item.type === 'email' && !EmailValidator.validate(data)) {
            return `Please specify correct ${item.type}`;
        }
        if (!validateUrl(data) && urlValidation.includes(item.type)) {
            return `Please specify correct ${item.type} url`;
        }
    };

    return (
        <FormControl className={classes.formControlWidth}>
            <SectionWithTitle title={label}>
                <>
                    {options.map((item) => (
                        <Field
                            validate={(data) => validate(data, item)}
                            key={item.type}
                            label={item.type}
                            name={`${name}.${item.type}`}
                            component={WeTextField}
                        />
                    ))}
                </>
            </SectionWithTitle>
        </FormControl>
    );
};
