import React from 'react';
import { Box } from '@material-ui/core';
import { TopBarButton } from '../../molecules/TopBarButton';
import { useAppFooterStyles } from './styles/AppFooter.styles';

export interface AppFooterProps {
    desktopMode: boolean;
}

export const AppFooter = ({ desktopMode }: AppFooterProps) => {
    const classes = useAppFooterStyles();
    if (!desktopMode) {
        return <></>;
    }

    return (
        <Box className={classes.footerDrawer}>
            <Box className={classes.footerDrawerPaper}>
                <TopBarButton
                    selected={true}
                    icon="/assets/images/nav-ico.svg"
                    onClick={() => console.log('test')}
                />
                Copyright © {new Date().getFullYear()} - All rights reserved
            </Box>
        </Box>
    );
};
