import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const WeCompatibilityPercentages = {
    low: 40,
    high: 80,
    medium: 60,
};

export interface WeCompatibilityProps {
    matchPercent: number;
}

const WeCompatibility = memo((props: WeCompatibilityProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.text}>{getText(props.matchPercent)}</Box>
        </Box>
    );
});

function getText(matchPercent: number): 'Low' | 'High' | 'Medium' {
    if (matchPercent <= WeCompatibilityPercentages.low) return 'Low';
    if (matchPercent >= WeCompatibilityPercentages.high) return 'High';
    if (matchPercent >= WeCompatibilityPercentages.medium) return 'Medium';
    return 'Low';
}

function useStyles() {
    return makeStyles((theme: Theme) => ({
        wrapper: {
            border: 'none',
            fontWeight: 500,
            fontSize: '12px',
            textAlign: 'center',
            padding: '5px 11px',
            borderRadius: '14px',
            display: 'inline-block',
            color: theme.palette.info[500],
            backgroundColor: theme.palette.info[50],
        },
        text: {
            display: 'inline-block',
        },
    }))();
}

WeCompatibility.displayName = 'WeCompatibility';

export { WeCompatibility };
