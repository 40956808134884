import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, withStyles } from '@material-ui/core';
import { SpriteSvgIcon, Avatar, BottomSheet } from '@weco/ui';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from 'react-router';
import { useCoolStyles } from './ProjectProfilePage/styles/CoolStyles';
import { useCoolData } from '../../store/hooks/useCoolData';
import { RouterPaths } from '../../router/RouterPaths';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const StyledDrawer = withStyles((theme) => ({
    root: {},
    paper: {
        borderRadius: '20px 20px 0 0',
        [theme.breakpoints.up('md')]: {
            marginLeft: '15%',
            marginRight: '15%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '25%',
            marginRight: '25%',
        },
    },
}))(Drawer);

const useStyles = makeStyles((theme) => ({
    root: {},
    closeIcon: {
        padding: '10px',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '40px',
        height: '40px',
    },
    contentContainer: {
        overflowY: 'scroll',
        height: '550px',
        padding: '40px 30px 30px 30px',
    },
    drawerView: {
        overflow: 'hidden',
    },
    drawerLayout: {
        overflow: 'hidden',
    },
}));

interface UsersCoolDrawerProps {
    id: string;
    type: 'Person' | 'Project';
    setCoolPanelOpen: () => void;
    coolPanelOpen: boolean;
}

export const UsersCoolDrawer = ({
    id,
    type,
    setCoolPanelOpen,
    coolPanelOpen,
}: UsersCoolDrawerProps) => {
    const coolClasses = useCoolStyles();
    const drawerClasses = useStyles();
    const history = useHistory();
    const { cools } = useCoolData(id, type);

    return (
        <StyledDrawer
            anchor="bottom"
            open={coolPanelOpen}
            onClose={setCoolPanelOpen}
            className={drawerClasses.drawerView}
        >
            <Grid item className={drawerClasses.drawerLayout}>
                <Box className={coolClasses.staticCools}>
                    <Button className={coolClasses.coolButton}>
                        <SpriteSvgIcon
                            id="cool-sign"
                            className={coolClasses.coolSign}
                        />
                        <SpriteSvgIcon
                            id="cool-word"
                            className={coolClasses.coolWord}
                        />
                    </Button>
                    <Box className={coolClasses.staticCoolsCount}>
                        {cools?.length || 0}
                    </Box>
                </Box>
                <Divider />
                <Box textAlign="right">
                    <IconButton
                        className={drawerClasses.closeIcon}
                        onClick={setCoolPanelOpen}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className={drawerClasses.contentContainer}>
                    {cools?.map((item) => {
                        return (
                            <Box
                                key={item.owner.id}
                                className={coolClasses.coolUsersElement}
                                onClick={() => {
                                    history.push(
                                        `${RouterPaths.profilePage}/${item.owner.id}`,
                                    );
                                    if (type === 'Person') {
                                        window.location.reload();
                                    }
                                }}
                            >
                                <Box className={coolClasses.userInfo}>
                                    <Avatar
                                        shape="round"
                                        picture={item?.owner?.picture}
                                        size="xs-small"
                                    />
                                    <Box
                                        className={classNames({
                                            [coolClasses.fitImgMargin]: true,
                                            [coolClasses.userName]: true,
                                        })}
                                    >
                                        {`${item?.owner?.name} ${item?.owner?.lastName}`}
                                    </Box>
                                </Box>
                                <ArrowForwardIcon
                                    className={coolClasses.fitImgMargin}
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Grid>
        </StyledDrawer>
    );
};
