import { Inject, Injectable } from '@weco/common';
import { CORE_SERVICES } from '../core_services';
import { TestResultEntity } from '../entity';
import { PassedTestRepositoryInterface } from '../repositories/PassedTestRepository';
import { CurrentUserProvider } from '../repositories';

@Injectable()
export class TestService {
    @Inject(CORE_SERVICES.PassedTestRepositoryInterface)
    private repository: PassedTestRepositoryInterface;

    @Inject(CORE_SERVICES.ICurrentUserProvider)
    private currentUser: CurrentUserProvider;

    public async loadMyLastTestResults(filter?): Promise<TestResultEntity> {
        const ownerId = this.currentUser.UserId;
        const results = await this.repository.loadPassedTests(
            filter ? { ...filter, ownerId } : { ownerId },
        );
        return !!results.length && results[0];
    }
}
