export const updatePerson = /* GraphQL */ `
    mutation UpdatePerson($input: UpdatePersonInput!) {
        updatePerson(input: $input) {
            id
            name
            lastName
            alias
            url
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            coverImage {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            dream
            departments
            skills
            industries
            contacts
            socialMedia
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            settings {
                canInvite
                badges
                privacy {
                    profileVisibility
                    contactsVisibility
                }
                searches {
                    name
                    type
                    query
                    skills
                    departments
                    causes
                    industries
                    compensation
                    location
                    languages
                    passions
                    compensations
                    objectives
                    stages
                    fundings
                    roles
                }
                appLanguage
            }
            causes
            compensations
            languages
            objectives
            passions
            fundings
            wishList
            investments
            tradings
            stores {
                label
                link
            }
            graduationYear
            hasFinishedOnboarding
            createdAt
            updatedAt
        }
    }
`;

export const processProjectCreation = /* GraphQL */ `
    mutation ProcessProjectCreation($input: ProcessProjectCreationInput) {
        processProjectCreation(input: $input) {
            id
            name
        }
    }
`;

export const updateProject = /* GraphQL */ `
    mutation UpdateProject($input: UpdateProjectInput!) {
        updateProject(input: $input) {
            id
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            name
            alias
            url
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            dream
            departments
            skills
            causes
            industries
            contacts
            socialMedia
            settings {
                privacy {
                    profileVisibility
                    contactsVisibility
                }
            }
            stage
            status
            compensations
            languages
            objectives
            passions
            fundings
            wishList
            investments
            tradings
            stores {
                label
                link
            }
            createdAt
            updatedAt
            owner {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                location {
                    name
                    googlePlaceId
                    location {
                        lat
                        lon
                    }
                }
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                causes
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                graduationYear
                hasFinishedOnboarding
                createdAt
                updatedAt
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                }
            }
        }
    }
`;

export const getPerson = /* GraphQL */ `
    query GetPerson($id: ID!) {
        getPerson(id: $id) {
            id
            name
            lastName
            alias
            url
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            coverImage {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            dream
            departments
            skills
            industries
            contacts
            socialMedia
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            settings {
                canInvite
                badges
                privacy {
                    profileVisibility
                    contactsVisibility
                }
                searches {
                    name
                    type
                    query
                    skills
                    departments
                    industries
                    compensation
                    location
                    languages
                    passions
                    compensations
                    objectives
                    stages
                    fundings
                    causes
                    roles
                }
                appLanguage
                totalCounts
            }
            causes
            compensations
            languages
            objectives
            passions
            fundings
            wishList
            investments
            tradings
            stores {
                label
                link
            }
            graduationYear
            hasFinishedOnboarding
            createdAt
            updatedAt
            activeProject {
                id
                location {
                    name
                    googlePlaceId
                    location {
                        lat
                        lon
                    }
                }
                name
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                causes
                industries
                contacts
                socialMedia
                stage
                status
                compensations
                languages
                objectives
                passions
                fundings
                wishList
                investments
                tradings
                stores {
                    label
                    link
                }
                createdAt
                updatedAt
                owner {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    createdAt
                    updatedAt
                }
                school {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            school {
                id
                name
                createdAt
                updatedAt
            }
        }
    }
`;
export const projectPersonsConnection = /* GraphQL */ `
    query ProjectPersonsConnection(
        $projectId: String
        $personId: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelPersonProjectConnectionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        ProjectPersonsConnection(
            projectId: $projectId
            personId: $personId
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                personId
                projectId
                roles
                createdAt
                updatedAt
                person {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    createdAt
                    updatedAt
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                }
                project {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`;

export const matchPeople2 = /* GraphQL */ `
    query MatchPeople(
        $filter: ProfileMatchInput
        $query: String
        $nextToken: Int
        $limit: Int
    ) {
        matchPeople(
            filter: $filter
            query: $query
            nextToken: $nextToken
            limit: $limit
        ) {
            items {
                id
                name
                lastName
                alias
                url
                picture {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                coverImage {
                    bucket
                    region
                    identity
                    key
                    mimeType
                    visibility
                    updatedAt
                }
                dream
                departments
                skills
                industries
                contacts
                socialMedia
                settings {
                    canInvite
                    badges
                    appLanguage
                }
                location {
                    name
                    googlePlaceId
                    location {
                        lat
                        lon
                    }
                }
                languages
                passions
                compensations
                objectives
                fundings
                createdAt
                updatedAt
                activeProject {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    stage
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    createdAt
                    updatedAt
                }
            }
            nextToken
            total
        }
    }
`;

export const createSkillSet = /* GraphQL */ `
    mutation CreateSkillSet($input: CreateSkillSetInput!) {
        createSkillSet(input: $input) {
            id
            departmentId
            skills
            name
            description
            ownerId
            projectId
            isSelected
            createdAt
            updatedAt
        }
    }
`;
export const deleteSkillSet = /* GraphQL */ `
    mutation DeleteSkillSet($input: DeleteSkillSetInput!) {
        deleteSkillSet(input: $input) {
            id
            departmentId
            skills
            name
            description
            ownerId
            projectId
            isSelected
            createdAt
            updatedAt
        }
    }
`;
export const updateSkillSet = /* GraphQL */ `
    mutation UpdateSkillSet($input: UpdateSkillSetInput!) {
        updateSkillSet(input: $input) {
            id
            departmentId
            skills
            name
            description
            ownerId
            projectId
            isSelected
            createdAt
            updatedAt
        }
    }
`;

export const personProjectConnection = /* GraphQL */ `
    query GetPersonProjectConnection($personId: String!, $projectId: String!) {
        getPersonProjectConnection(personId: $personId, projectId: $projectId) {
            personId
            projectId
            roles
        }
    }
`;

export const personProjectConnectionsLightweight = /* GraphQL */ `
    query personProjectConnectionsLightweight(
        $personId: String
        $projectId: ModelStringKeyConditionInput
    ) {
        listPersonProjectConnections(
            personId: $personId
            projectId: $projectId
        ) {
            items {
                personId
                projectId
                roles
                person {
                    id
                    name
                    lastName
                    dream
                }
                project {
                    id
                    name
                    dream
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                    owner {
                        id
                        name
                        lastName
                    }
                }
            }
        }
    }
`;

export const projectPersonsConnectionLightweight = /* GraphQL */ `
    query ProjectPersonsConnection(
        $projectId: String
        $personId: ModelStringKeyConditionInput
    ) {
        ProjectPersonsConnection(projectId: $projectId, personId: $personId) {
            items {
                personId
                projectId
                roles
                skillSets {
                    roleId
                    status
                }
                person {
                    id
                    name
                    lastName
                    dream
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                    causes
                    contacts
                }
                project {
                    id
                    name
                    owner {
                        id
                    }
                    dream
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                    causes
                }
            }
        }
    }
`;

export const listPersonProjectConnections = /* GraphQL */ `
    query ListPersonProjectConnections(
        $personId: String
        $projectId: ModelStringKeyConditionInput
        $filter: ModelPersonProjectConnectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listPersonProjectConnections(
            personId: $personId
            projectId: $projectId
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                personId
                projectId
                roles
                createdAt
                updatedAt
                person {
                    id
                    name
                    lastName
                    alias
                    url
                    dream
                    departments
                    skills
                    industries
                    contacts
                    socialMedia
                    causes
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    graduationYear
                    hasFinishedOnboarding
                    createdAt
                    updatedAt
                }
                project {
                    id
                    name
                    alias
                    url
                    dream
                    departments
                    skills
                    causes
                    industries
                    contacts
                    socialMedia
                    stage
                    status
                    compensations
                    languages
                    objectives
                    passions
                    fundings
                    wishList
                    investments
                    tradings
                    createdAt
                    updatedAt
                    picture {
                        bucket
                        region
                        identity
                        key
                        mimeType
                        visibility
                        updatedAt
                    }
                    owner {
                        id
                        name
                        lastName
                        alias
                        url
                        dream
                        departments
                        skills
                        industries
                        contacts
                        socialMedia
                        location {
                            name
                            googlePlaceId
                            location {
                                lat
                                lon
                            }
                        }
                        picture {
                            bucket
                            region
                            identity
                            key
                            mimeType
                            visibility
                            updatedAt
                        }
                        causes
                        compensations
                        languages
                        objectives
                        passions
                        fundings
                        wishList
                        investments
                        tradings
                        graduationYear
                        hasFinishedOnboarding
                        createdAt
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`;

export const deleteProject = /* GraphQL */ `
    mutation DeleteProject($input: DeleteProjectInput!) {
        deleteProject(input: $input) {
            id
        }
    }
`;

export const deletePerson = /* GraphQL */ `
    mutation DeletePerson($input: DeletePersonInput!) {
        deletePerson(input: $input) {
            id
        }
    }
`;

export const createSchool = /* GraphQL */ `
    mutation CreateSchool($input: CreateSchoolInput!) {
        createSchool(input: $input) {
            id
            name
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            createdAt
            updatedAt
        }
    }
`;
export const updateSchool = /* GraphQL */ `
    mutation UpdateSchool($input: UpdateSchoolInput!) {
        updateSchool(input: $input) {
            id
            name
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            createdAt
            updatedAt
        }
    }
`;
export const deleteSchool = /* GraphQL */ `
    mutation DeleteSchool($input: DeleteSchoolInput!) {
        deleteSchool(input: $input) {
            id
            name
            picture {
                bucket
                region
                identity
                key
                mimeType
                visibility
                updatedAt
            }
            location {
                name
                googlePlaceId
                location {
                    lat
                    lon
                }
            }
            createdAt
            updatedAt
        }
    }
`;
export const createApplication = /* GraphQL */ `
    mutation CreateApplication($projectId: String, $roleId: String) {
        createApplication(projectId: $projectId, roleId: $roleId) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const createInvitation = /* GraphQL */ `
    mutation CreateInvitation(
        $personId: String
        $projectId: String
        $roleId: String
    ) {
        createInvitation(
            personId: $personId
            projectId: $projectId
            roleId: $roleId
        ) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const recallApplication = /* GraphQL */ `
    mutation RecallApplication($projectId: String, $roleId: String) {
        recallApplication(projectId: $projectId, roleId: $roleId) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const recallInvitation = /* GraphQL */ `
    mutation RecallInvitation(
        $personId: String
        $projectId: String
        $roleId: String
    ) {
        recallInvitation(
            personId: $personId
            projectId: $projectId
            roleId: $roleId
        ) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const acceptApplication = /* GraphQL */ `
    mutation AcceptApplication(
        $personId: String
        $projectId: String
        $roleId: String
    ) {
        acceptApplication(
            personId: $personId
            projectId: $projectId
            roleId: $roleId
        ) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const acceptInvitation = /* GraphQL */ `
    mutation AcceptInvitation($projectId: String, $roleId: String) {
        acceptInvitation(projectId: $projectId, roleId: $roleId) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const declineApplication = /* GraphQL */ `
    mutation DeclineApplication(
        $personId: String
        $projectId: String
        $roleId: String
    ) {
        declineApplication(
            personId: $personId
            projectId: $projectId
            roleId: $roleId
        ) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const declineInvitation = /* GraphQL */ `
    mutation DeclineInvitation($projectId: String, $roleId: String) {
        declineInvitation(projectId: $projectId, roleId: $roleId) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const leaveProject = /* GraphQL */ `
    mutation LeaveProject($projectId: String) {
        leaveProject(projectId: $projectId) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const removeProjectMember = /* GraphQL */ `
    mutation RemoveProjectMember($personId: String, $projectId: String) {
        removeProjectMember(personId: $personId, projectId: $projectId) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const removeProjectMemberFromRole = /* GraphQL */ `
    mutation RemoveProjectMemberFromRole(
        $personId: String
        $projectId: String
        $roleId: String
    ) {
        removeProjectMemberFromRole(
            personId: $personId
            projectId: $projectId
            roleId: $roleId
        ) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;
export const assignProjectMemberToRole = /* GraphQL */ `
    mutation AssignProjectMemberToRole(
        $personId: String
        $projectId: String
        $roleId: String
    ) {
        assignProjectMemberToRole(
            personId: $personId
            projectId: $projectId
            roleId: $roleId
        ) {
            receipientId
            subjectType
            subjectId
            createdAt
            updatedAt
            status
            type
            parameters {
                name
                value
            }
            counter {
                receipientId
                subjectType
                subjectId
                count
                createdAt
                updatedAt
            }
        }
    }
`;

export const createCool = /* GraphQL */ `
    mutation CreateCool($input: CreateCoolInput!) {
        createCool(input: $input) {
            id
            ownerId
            entityType
            entityId
            createdAt
        }
    }
`;

export const deleteCool = /* GraphQL */ `
    mutation DeleteCool($input: DeleteCoolInput!) {
        deleteCool(input: $input) {
            id
            ownerId
            entityType
            entityId
            createdAt
        }
    }
`;
