import debug from 'debug';
import React, { FC } from 'react';
import filter from 'lodash/filter';
import {
    ApplyCollaborationActions,
    DepartmentsRouting,
    ProjectTeamOverview,
    UnauthorizedRedirectOnclick,
} from '@weco/ui';
import {
    ProjectProfileData,
    useProjectProfileData,
} from './store/useProjectProfileData';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { useProjectRolesData } from '../../../store/hooks/useProjectRolesData';
import { ProjectRolesContainer } from '../../../store/containers/ProjectRolesContainer';
import { useCurrentAuthData } from '../../../store/hooks/useCurrentAuthData';

const log = debug('TeamTabPage');

const ApplyCollaborationActionButton = () => {
    const { isAuthenticated } = useCurrentAuthData();
    const store: ProjectProfileData = useProjectProfileData();
    return (
        <UnauthorizedRedirectOnclick>
            <ApplyCollaborationActions
                setPersonProjectConnection={store.setPersonProjectConnection}
                personProjectConnection={store.personProjectConnection}
                applied={store.applied}
                isMember={store.isMember}
                isEnvitee={store.isEnvitee}
                ableToApply={store.ableToApply}
                leaveProject={store.leaveProject}
                isLoadingStoreData={store.loading}
                applyToProject={store.applyToProject}
                acceptInvitation={store.acceptInvitation}
                declineInvitation={store.declineInvitation}
                recallApplication={store.recallApplication}
                isAuthenticated={isAuthenticated}
            />
        </UnauthorizedRedirectOnclick>
    );
};

interface TeamTabPageProps {
    projectId: string;
}

export const TeamTabPage: FC<TeamTabPageProps> = ({
    projectId,
}: TeamTabPageProps) => {
    const { profile } = useMyUserProfileData();

    return (
        <ProjectRolesContainer projectId={projectId}>
            {() => {
                const {
                    team,
                    project,
                    loading: isProjectProfileLoading,
                } = useProjectProfileData();
                const projectRolesStore = useProjectRolesData(projectId);

                const isDataLoading =
                    isProjectProfileLoading ||
                    projectRolesStore.isLoadingStoreData;
                const projectsActiveRoles = filter(
                    projectRolesStore.allAddedRoles,
                    'isSelected',
                );
                log('project: %O', project);
                log('isDataLoading: ', isDataLoading);
                log('projectsActiveRoles: %O', projectsActiveRoles);

                return (
                    <DepartmentsRouting
                        profile={profile}
                        areRolesEditable={false}
                        typeofDescriptionToUse="project"
                        matchSkillSetsWith={profile?.skills}
                        departments={projectRolesStore.departments}
                        roles={projectsActiveRoles}
                        initialPage={
                            <ProjectTeamOverview
                                team={team}
                                project={project}
                                isLoading={isDataLoading}
                                currentUserId={profile?.id}
                                roles={projectsActiveRoles}
                                matchSkillSetsWith={profile?.skills}
                                connectButton={
                                    <ApplyCollaborationActionButton />
                                }
                            />
                        }
                    />
                );
            }}
        </ProjectRolesContainer>
    );
};
