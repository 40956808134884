import { Grid } from '@material-ui/core';
import {
    NavTabs,
    SpriteSvgIcon,
    UnauthorizedRedirectOnclick,
    ProjectInfo,
    ApplyCollaborationActions,
    ApplyCollarobrationActionsProps,
    RequiredInfoBanner,
} from '@weco/ui';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { useCurrentAuthData } from '../../../store/hooks/useCurrentAuthData';
import { useProjectProfileContainerStyles } from '../../me/styles/MePage.styles';
import { ResourcesTabPage } from './ResourcesTabPage';
import { ProjectProfileContainer } from './store/ProjectProfileContainer';
import {
    ProjectProfileData,
    useProjectProfileData,
} from './store/useProjectProfileData';
import { TeamTabPage } from './TeamTabPage';
import { UserProjectHeader } from './UserProjectHeader';
import { RouterPaths } from '../../../router/RouterPaths';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { useProjectRolesData } from '../../../store/hooks/useProjectRolesData';
import filter from 'lodash/filter';
import { ProjectRolesContainer } from '../../../store/containers/ProjectRolesContainer';
import { CoolWrapper } from '../../../components/molecules/CoolWrapper';

/**
 * NOTE: had problem with persistant "hook order" error,
 * when using "useProjectProfileData" inside ProjectPage.
 * This is the solution.
 */
const ProjectInfoWithData = () => {
    const { project } = useProjectProfileData();
    const { profile } = useMyUserProfileData();
    return <ProjectInfo project={project} profile={profile} />;
};

export const ProjectPage = () => {
    const {
        params: { id: projectId },
    } = useRouteMatch();
    const classes = useProjectProfileContainerStyles();
    const baseUrl = `${RouterPaths.projectPage}/${projectId}`;
    const { isAuthenticated } = useCurrentAuthData();
    const { profile: myProfile } = useMyUserProfileData();
    const incompleteProfile = () => {
        return !myProfile?.picture || !myProfile?.dream;
    };

    return (
        <ProjectRolesContainer projectId={projectId}>
            <ProjectProfileContainer itemId={projectId}>
                {() => (
                    <Grid>
                        {isAuthenticated && incompleteProfile() && (
                            <RequiredInfoBanner
                                text="Please complete your profile with a Photo and About Me."
                                link={RouterPaths.myProfile}
                                linkText="Complete Profile"
                            />
                        )}
                        <Grid item xs={12}>
                            <UserProjectHeader
                                isAuthenticated={isAuthenticated}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={classes.userActionButtons}
                        >
                            <UnauthorizedRedirectOnclick>
                                <ApplyCollaborationActionsWrapper
                                    isAuthenticated={isAuthenticated}
                                    incompleteProfile={incompleteProfile()}
                                    projectId={projectId}
                                />
                            </UnauthorizedRedirectOnclick>
                            <UnauthorizedRedirectOnclick>
                                <CoolWrapper
                                    entityId={projectId}
                                    entityType="Project"
                                />
                            </UnauthorizedRedirectOnclick>
                        </Grid>
                        <Grid
                            className={classes.root}
                            item
                            xs={12}
                            id="tabs-layout"
                        >
                            <NavTabs
                                tabs={[
                                    {
                                        label: 'About',
                                        icon: (
                                            <SpriteSvgIcon id="bio-primary" />
                                        ),
                                        to: `${baseUrl}`,
                                        component: ProjectInfoWithData,
                                        componentProps: { id: projectId },
                                    },
                                    {
                                        label: 'Team',
                                        icon: (
                                            <SpriteSvgIcon id="skill-team-primary" />
                                        ),
                                        to: `${baseUrl}/team/`,
                                        component: TeamTabPage,
                                        componentProps: {
                                            projectId,
                                            isAuthenticated,
                                            baseUrl: `${baseUrl}/team`,
                                        },
                                    },
                                    {
                                        label: 'Resources',
                                        icon: (
                                            <SpriteSvgIcon id="resources-primary" />
                                        ),
                                        to: `${baseUrl}/resources`,
                                        component: ResourcesTabPage,
                                        componentProps: {
                                            baseUrl: `${baseUrl}/resources`,
                                        },
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                )}
            </ProjectProfileContainer>
        </ProjectRolesContainer>
    );
};

const ApplyCollaborationActionsWrapper = (
    props: Partial<ApplyCollarobrationActionsProps>,
) => {
    const store: ProjectProfileData = useProjectProfileData();
    const projectRolesStore = useProjectRolesData(props.projectId);
    const projectsActiveRoles = filter(
        projectRolesStore.allAddedRoles,
        'isSelected',
    );
    return (
        <ApplyCollaborationActions
            {...props}
            setPersonProjectConnection={store.setPersonProjectConnection}
            personProjectConnection={store.personProjectConnection}
            declineInvitation={store.declineInvitation}
            acceptInvitation={store.acceptInvitation}
            leaveProject={store.leaveProject}
            applyToProject={store.applyToProject}
            recallApplication={store.recallApplication}
            isLoadingStoreData={store.loading}
            isEnvitee={store.isEnvitee}
            isMember={store.isMember}
            ableToApply={store.ableToApply}
            applied={store.applied}
            projectsActiveRoles={projectsActiveRoles}
        />
    );
};
