import { Box, CircularProgress } from '@material-ui/core';
import { useStore } from '@weco/common';
import { UserProfileEntity } from '@weco/core';
import { GridList } from '@weco/ui';
import debug from 'debug';
import { observer } from 'mobx-react';
import React, { memo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useToggle from 'react-use/lib/useToggle';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import OnboardingNavigationButtons from '../components/OnboardingNavigationButtons';
import { OnboardingPageWrapper } from '../components/OnboardingPageWrapper';
import OnboardingStore from '../OnboardingStore';
import { RouterPaths } from '../../../router/RouterPaths';
import { IOnboardingPageURLParms } from '../OnboardingPage';
import { OnboardingSearchCauses } from '../components/OnboardingSearchCauses';
import { xor } from 'lodash';
import { toJS } from 'mobx';
import { MyUserStore } from '../../../store/MyUserStore';

const log = debug('SelectPassionsStep');

const defaultPassions = [
    'Acting',
    'Basketball',
    'Cooking',
    'Music',
    'Tennis',
    'Videogames',
];

const SelectPasionsStep = memo(
    observer(function SelectPassionsStep() {
        const history = useHistory();
        const params = useParams<IOnboardingPageURLParms>();
        const currentStep = Number(params.step);
        const [isLoading, toggleLoading] = useToggle(false);
        const { profile, updateProfile } = useMyUserProfileData();
        const store = useStore<OnboardingStore>(OnboardingStore);
        const customPassionsInStore = store.selectedPassions.filter(
            (selectedPassion) => !defaultPassions.includes(selectedPassion),
        );
        const [customUsersPassions, setCustomPassions] = useState<string[]>(
            customPassionsInStore,
        );
        const isSubmitDisabled =
            isLoading || store.selectedPassions.length === 0;

        log('Submit is disabled:', isSubmitDisabled);
        log('Selected passions', toJS(store.selectedPassions));
        log('customUsersPassions: ', customUsersPassions);

        function toggleCustomPassion(passion) {
            setCustomPassions(xor(customUsersPassions, [passion]));
        }

        function updateProfileAndRedirect() {
            log('Submit is called.');
            toggleLoading();

            const storeData = toJS(store);
            const payload = Object.assign({}, profile, {
                hasFinishedOnboarding: true,
                school: storeData.selectedSchool,
                causes: storeData.selectedCauses,
                passions: storeData.selectedPassions,
                accountType: storeData.selectedAccountType,
                graduationYear: storeData.selectedGraduationYear,
            } as UserProfileEntity);
            log('Profile after merge: %O', payload);

            function resetStoreAndRedirect() {
                log('Profile sucessfully updated.');
                store.reset();
                history.push(`${RouterPaths.onboarding}/${currentStep + 1}`);
            }

            return updateProfile(payload)
                .then(resetStoreAndRedirect)
                .catch((e) => {
                    log('Error occured:', e);
                    toggleLoading();
                    // NOTE currently mutation returns an error yet
                    // profile is updated anyway.
                    // TODO Investigate and remove this workaround.
                    resetStoreAndRedirect();
                });
        }

        return (
            <OnboardingPageWrapper
                title={'Almost there!'}
                subtitle={'Share your Passions and get better matches.'}
                bottomChildren={
                    isLoading ? (
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <OnboardingNavigationButtons
                            onSubmit={updateProfileAndRedirect}
                            isSubmitDisabled={isSubmitDisabled}
                        />
                    )
                }
            >
                <>
                    <Box mb={4}>
                        <GridList
                            items={defaultPassions.concat(customUsersPassions)}
                            selectedItems={store.selectedPassions}
                            onClick={(passion) => {
                                store.toggleSelectedPassion(passion);
                                if (!defaultPassions.includes(passion)) {
                                    toggleCustomPassion(passion);
                                }
                            }}
                        />
                    </Box>
                    <Box mb={4} mx={4}>
                        <OnboardingSearchCauses
                            text="+ Add your passions"
                            onSubmit={(passion) => {
                                toggleCustomPassion(passion);
                                store.toggleSelectedPassion(passion);
                            }}
                        />
                    </Box>
                </>
            </OnboardingPageWrapper>
        );
    }),
);

export { SelectPasionsStep };

export default SelectPasionsStep;
