import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/styles';

interface BasicLayoutProps {
    children: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
    },
}));

const MinimalisticLayout = ({ children }: BasicLayoutProps) => {
    const classes = useStyles();
    return (
        <>
            <CssBaseline />
            <main className={classes.content}>{children}</main>
        </>
    );
};

export default React.memo(MinimalisticLayout);
