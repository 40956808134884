import React from 'react';
import { useBenefitComponentStyles } from '../styles/BenefitComponent.styles';
import Create from '../../../../assets/images/about/benefit-join2.png';
import Join from '../../../../assets/images/about/benefit-build2.png';
import { RouterPaths } from '../../../router/RouterPaths';
import { Box, Typography, CardMedia, Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';

export interface BenefitComponentProps {
    type: 'founders' | 'everyone';
}

const benefitTexts = {
    founders: [
        'Start your project with a budget of zero.',
        'Describe your project requirements.',
        'Be matched with potential teammates.',
        'Create as many projects as you want.',
    ],
    everyone: [
        'Showcase your skills, interests and passions.',
        'Be matched with the best opportunities.',
        'Join as many projects as you can handle.',
        'Be compensated for the value you create.',
    ],
};

export const BenefitComponent = ({ type }: BenefitComponentProps) => {
    const isForEveryOne = type === 'everyone';
    const classes = useBenefitComponentStyles();
    const history = useHistory();

    function goTo(path) {
        history.push(path);
    }

    return (
        <Box
            style={{ width: '100%' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="column"
        >
            <Grid container style={{ padding: '45px 0' }}>
                <Grid
                    item
                    xs={isForEveryOne ? 5 : 7}
                    container
                    justify="center"
                >
                    {isForEveryOne ? (
                        <Typography
                            variant="h1"
                            align="center"
                            className={classes.title}
                        >
                            {'Everyone'}
                        </Typography>
                    ) : (
                        <CardMedia
                            src={Create}
                            className={classes.joinImage}
                            component="img"
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={isForEveryOne ? 7 : 5}
                    container
                    justify="center"
                >
                    {isForEveryOne ? (
                        <CardMedia
                            src={Join}
                            className={classes.joinImage}
                            component="img"
                        />
                    ) : (
                        <Typography
                            variant="h1"
                            align="center"
                            className={classes.title}
                        >
                            {'Founders'}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <Box className={classes.infoText}>
                {benefitTexts[type].map((text, index) => (
                    <Typography key={index} variant="h5">
                        {text}
                    </Typography>
                ))}
            </Box>

            <Button
                onClick={() =>
                    goTo(
                        isForEveryOne
                            ? RouterPaths.search.projects
                            : RouterPaths.search.people,
                    )
                }
                className={classes.joinButton}
            >
                Browse {isForEveryOne ? 'Team' : 'People'}
            </Button>
        </Box>
    );
};
