import { Box, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    avatarBox: {
        width: '120px',
        height: '120px',
        margin: '0 auto',
    },
    skeletonBox: {
        width: '100%',
        height: '100%',
    },
}));

interface AvatarLoadingAnimationProps {
    variant?: 'rect' | 'circle';
}

export const AvatarLoadingAnimation = ({
    variant = 'rect',
}: AvatarLoadingAnimationProps) => {
    const classes = useStyles();
    return (
        <Box className={classes.avatarBox}>
            <Skeleton
                className={classes.skeletonBox}
                variant={variant}
                animation="wave"
            />
        </Box>
    );
};
