import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { useCurrentAuthData } from 'apps/client/src/app/store/hooks/useCurrentAuthData';

export interface UnauthorizedRedirectOnclickProps {
    children: React.ReactElement;
}

const UnauthorizedRedirectOnclick = memo(
    ({ children }: UnauthorizedRedirectOnclickProps) => {
        const history = useHistory();

        const { isAuthenticated } = useCurrentAuthData();

        function signUpRedirectHandler(e) {
            if (isAuthenticated) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            history.push({
                pathname: RouterPaths.signup,
                state: { isUnauthorizedRedirect: true },
            });
        }

        return <div onClickCapture={signUpRedirectHandler}>{children}</div>;
    },
);

export { UnauthorizedRedirectOnclick };
