import { Chip, ChipProps, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { OnAnyEventHandler } from '../helpers/CommonTypes';
import { SpriteSvgIcon } from './SpriteSvgIcon';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        borderColor: '#EBEBEB',
        borderRadius: '10px',
        [theme.breakpoints.only('sm')]: {
            maxWidth: '300px',
        },
        '& .MuiChip-avatar': {
            color: '#fff',
            backgroundColor: 'transparent',
        },
    },
    icons: {
        marginRight: '5px',
        transform: 'scale(0.7)',
    },
}));

export interface WeChipItem {
    value?: any;
    label: string;
    isActive?: boolean;
    chipProps?: ChipProps;
}

export type WeChipProps = {
    item: any | WeChipItem;
    onClick?: OnAnyEventHandler;
    chipSize?: string;
    chipsGroupName?: string;
};

export const WeChip = ({
    item,
    onClick,
    chipSize,
    chipsGroupName,
}: WeChipProps) => {
    const classes = useStyles();
    const label =
        (item as any)?.label === undefined
            ? item || 'Unknown'
            : (item as any).label;
    return (
        <Chip
            avatar={
                chipsGroupName === 'compensations' ||
                chipsGroupName === 'investments' ? (
                    <SpriteSvgIcon id={`${label}`} className={classes.icons} />
                ) : null
            }
            className={classes.root}
            title={label}
            label={label}
            onClick={() => {
                !!onClick && onClick((item as any).link || item);
            }}
            size={chipSize === 'small' ? 'small' : 'medium'}
            color={(item as any)?.isActive ? 'primary' : 'default'}
            clickable={!!onClick}
            {...((item as WeChipItem)?.chipProps || {})}
        />
    );
};
