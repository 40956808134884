import { Subject, Subscription } from 'rxjs';

export class EventEmitter<T = any> extends Subject<T> {
    __isAsync: boolean; // tslint:disable-line
    private _value: T;

    constructor(isAsync: boolean = false) {
        super();
        this.__isAsync = isAsync;
    }

    get value(): T | undefined {
        try {
            return this.getValue();
        } catch (e) {
            return undefined;
        }
    }

    getValue(): T {
        if (this.hasError) {
            throw this.thrownError;
        } else if (this.closed) {
            throw new Error('ObjectUnsubscribedError');
        } else {
            return this._value;
        }
    }

    emit(value?: any) {
        super.next((this._value = value));
    }

    subscribe(
        generatorOrNext?: any,
        error?: any,
        complete?: any,
    ): Subscription {
        let schedulerFn: (t: any) => any;
        let errorFn = (err: any): any => null;
        let completeFn = (): any => null;

        if (generatorOrNext && typeof generatorOrNext === 'object') {
            schedulerFn = this.__isAsync
                ? (value: any) => {
                      setTimeout(() => generatorOrNext.next(value));
                  }
                : (value: any) => {
                      generatorOrNext.next(value);
                  };

            if (generatorOrNext.error) {
                errorFn = this.__isAsync
                    ? (err) => {
                          setTimeout(() => generatorOrNext.error(err));
                      }
                    : (err) => {
                          generatorOrNext.error(err);
                      };
            }

            if (generatorOrNext.complete) {
                completeFn = this.__isAsync
                    ? () => {
                          setTimeout(() => generatorOrNext.complete());
                      }
                    : () => {
                          generatorOrNext.complete();
                      };
            }
        } else {
            schedulerFn = this.__isAsync
                ? (value: any) => {
                      setTimeout(() => generatorOrNext(value));
                  }
                : (value: any) => {
                      generatorOrNext(value);
                  };

            if (error) {
                errorFn = this.__isAsync
                    ? (err) => {
                          setTimeout(() => error(err));
                      }
                    : (err) => {
                          error(err);
                      };
            }

            if (complete) {
                completeFn = this.__isAsync
                    ? () => {
                          setTimeout(() => complete());
                      }
                    : () => {
                          complete();
                      };
            }
        }

        const sink = super.subscribe(schedulerFn, errorFn, completeFn);

        if (generatorOrNext instanceof Subscription) {
            generatorOrNext.add(sink);
        }

        return sink;
    }
}
