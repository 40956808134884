import React from 'react';
import { Grid } from '@material-ui/core';
import { TeamTabPage } from './TeamTabPage';
import { MyProjectInfo } from './MyProjectInfo';
import { NavTabs, SpriteSvgIcon } from '@weco/ui';
import { MyProjectHeader } from './MyProjectHeader';
import { ResourcesTabPage } from './ResourcesTabPage';
import { useMyProjectData } from '../store/useMyProjectData';
import { useProjectProfileContainerStyles } from '../styles/MePage.styles';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { ProjectProfileContainer } from '../../profile/project/store/ProjectProfileContainer';
import { PersonRolesContainer } from '../../../store/containers/PersonRolesContainer';
import { ProjectRolesContainer } from '../../../store/containers/ProjectRolesContainer';
import { RouterPaths } from '../../../router/RouterPaths';
import { useHistory } from 'react-router';

export const MyProjectPage = ({ baseUrl, projectId }) => {
    const classes = useProjectProfileContainerStyles();
    const history = useHistory();
    const { profile } = useMyUserProfileData();
    const { item: myProject } = useMyProjectData(projectId);

    if (myProject.owner.id !== profile.id) {
        history.replace(`${RouterPaths.projectPage}/${myProject.id}`);
    }

    return (
        <ProjectProfileContainer itemId={projectId}>
            <ProjectRolesContainer projectId={projectId}>
                <PersonRolesContainer personId={profile?.id}>
                    <Grid container>
                        <Grid item xs={12} id="tabs-layout">
                            <MyProjectHeader projectId={projectId} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={classes.root}
                            id="container-anchor-myproject-nav-tabs"
                        >
                            <NavTabs
                                tabs={[
                                    {
                                        label: 'About',
                                        icon: (
                                            <SpriteSvgIcon id="bio-primary" />
                                        ),
                                        to: `${baseUrl}`,
                                        component: (
                                            <MyProjectInfo
                                                projectId={projectId}
                                            />
                                        ),
                                    },
                                    {
                                        label: 'Team',
                                        icon: (
                                            <SpriteSvgIcon id="skill-team-primary" />
                                        ),
                                        to: `${baseUrl}/team/`,
                                        component: TeamTabPage,
                                        componentProps: {
                                            baseUrl: `${baseUrl}/team/`,
                                            projectId,
                                        },
                                    },
                                    {
                                        label: 'Resources',
                                        icon: (
                                            <SpriteSvgIcon id="resources-primary" />
                                        ),
                                        to: `${baseUrl}/resources`,
                                        component: ResourcesTabPage,
                                        componentProps: {
                                            baseUrl: `${baseUrl}/resources`,
                                            projectId,
                                        },
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </PersonRolesContainer>
            </ProjectRolesContainer>
        </ProjectProfileContainer>
    );
};
