import { FormControl, MenuItem, Box } from '@material-ui/core';
import { Field } from 'formik';
import React, { FunctionComponent, memo } from 'react';
import { WeSelect } from '../forms/WeSelect';

const graduationYearSelector: FunctionComponent<{ defaultValue?: number }> = (
    props,
) => {
    const currentYear = new Date().getFullYear();
    return (
        <FormControl fullWidth>
            <Field
                name="year"
                label="Select Year"
                component={WeSelect}
                defaultValue={props.defaultValue}
                validate={(year) => (year ? '' : 'Required')}
            >
                {Array(12)
                    .fill('')
                    .map((value, index) => {
                        const graduationYear = currentYear + index;
                        return (
                            <MenuItem key={index} value={graduationYear}>
                                <Box padding="10px">{graduationYear}</Box>
                            </MenuItem>
                        );
                    })}
            </Field>
        </FormControl>
    );
};

const GraduationYearSelector = memo(graduationYearSelector);

GraduationYearSelector.displayName = 'GraduationYearSelector';

export { GraduationYearSelector };
