import React from 'react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import classNames from 'classnames';
import { WE_CLICKS } from '../../../../we_clicks';

const useStyles = makeStyles((theme: Theme) => ({
    control: {
        padding: '0',
    },
    controlBox: {
        padding: '15px 0',
    },
    label: {
        fontFamily: 'Ubuntu',
        fontSize: 20,
        marginRight: 10,
        color: '#BDBDBD',
    },
    slider: {
        width: 120,
        padding: 5,
        color: '#BDBDBD',
    },
    rotateButton: {
        width: '100px',
        marginLeft: 20,
        padding: 0,
    },
    button: {
        paddingLeft: '40px',
        paddingRight: '40px',
        margin: '0 10px',
        width: '40%',
    },
}));

const EditorControls = ({
    scale,
    handleScaleChange,
    handleRotateChange,
    handlePreviewImage,
    handleCancel,
    type,
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.control}>
            <Box className={classes.controlBox}>
                <Typography className={classes.label}>Zoom</Typography>
                <Slider
                    className={classes.slider}
                    aria-label="custom thumb label"
                    onChange={handleScaleChange}
                    step={0.005}
                    min={0.2}
                    max={2}
                    value={scale}
                />
                <Button
                    className={classes.rotateButton}
                    onClick={handleRotateChange}
                >
                    <RotateRightIcon />
                    Rotate
                </Button>
            </Box>
            <Box>
                <Button
                    color="default"
                    className={classNames({
                        [classes.button]: true,
                    })}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    id={
                        type === 'project'
                            ? WE_CLICKS.AVATAR_PROJECT_CREATING
                            : WE_CLICKS.AVATAR_PROFILE_CREATING
                    }
                    color="primary"
                    className={classes.button}
                    onClick={handlePreviewImage}
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default EditorControls;
