import { Inject, Injectable } from '@weco/common';
import { IPeopleRepository } from '../repositories/PeopleRepository';
import { CORE_SERVICES } from '../core_services';
import { UserProfileEntity, SearchParams } from '../entity';
import { ProfileItemInterface } from '../repositories';
import { MatchPercentServiceInterface } from './MatchPercentService';
import debug from 'debug';
import { toJS } from 'mobx';

const log = debug('PeopleService');

@Injectable()
export class PeopleService {
    @Inject(CORE_SERVICES.IPeopleRepository)
    private repository: IPeopleRepository;

    @Inject(CORE_SERVICES.MatchPercentServiceInterface)
    percentRepository: MatchPercentServiceInterface;

    public async getList(
        limit?: number,
    ): Promise<{ people: UserProfileEntity[]; total: number }> {
        return this.repository.getMatchList({ limit });
    }

    public async search({
        filter,
        limit,
        advancedFilter,
    }: SearchParams): Promise<{
        total: number;
        people: ProfileItemInterface[];
    }> {
        const debugSearch = log.extend('search()');
        debugSearch('filter argument: %O', filter);
        debugSearch('advancedFilter argument: %O', toJS(advancedFilter));

        const profiles = await this.repository.getMatchList({
            filter,
            limit,
            advancedFilter,
        });
        return {
            total: profiles.total,
            people: profiles.people as any, // await this.injectMatchPercent(profiles.people),
        };
    }

    private async injectMatchPercent(profiles) {
        return Promise.all<ProfileItemInterface>(
            profiles.map(async (item) => {
                const matchPercent = await this.percentRepository.matchProfilePercent(
                    item,
                );
                const injectedPercent: ProfileItemInterface = {
                    ...item,
                    matchPercent,
                };
                return injectedPercent;
            }),
        );
    }
}
