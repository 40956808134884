export const CORE_SERVICES = {
    ApolloAuthorizedClient: Symbol.for('ApolloAuthorizedClient'),
    ApolloUnAuthorizedClient: Symbol.for('ApolloUnAuthorizedClient'),
    ICurrentUserProvider: Symbol.for('ICurrentUserProvider'),
    IProjectRepository: Symbol.for('IProjectRepository'),
    MatchPercentServiceInterface: Symbol.for('MatchPercentServiceInterface'),
    IPeopleRepository: Symbol.for('IPeopleRepository'),
    SchoolsRepositoryInterface: Symbol.for('SchoolsRepositoryInterface'),
    PersonRepository: Symbol.for('PersonRepository'),
    SkillSetRepositoryInterface: Symbol.for('SkillSetRepositoryInterface'),
    PassedTestRepositoryInterface: Symbol.for('PassedTestRepositoryInterface'),
    ICoolRepository: Symbol.for('ICoolRepository'),
};
