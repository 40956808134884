import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
// NOTE: a lot of this classes are unused. Clean up is necessary.
export const useMyProjectInfoStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '0 10%',
        marginTop: '80px',
    },
    infoItem: {
        width: '100%',
        marginTop: 30,
    },
    infoHeading: {
        display: 'flex',
        color: theme.palette.text.primary,
        fontWeight: 500,
        alignItems: 'center',
    },
    infoIcon: {
        color: '#BDBDBD',
        marginBottom: 15,
        marginLeft: 15,
        fontSize: '18px',
        fontWeight: 300,
    },
    editIcon: {
        marginLeft: 'auto',
        color: '#E9E9E9',
    },
    infoText: {
        marginTop: 10,
        fontSize: '16px',
        color: theme.palette.secondary[650],
        marginRight: 15,
        width: '100%',
        overflow: 'hidden',
        overflowWrap: 'break-word',
    },
    infoRow: {
        margin: 0,
        minHeight: 10,
    },
    infoLinearText: {
        margin: '5px 10px',
        fontSize: '16px',
        color: '#BDBDBD',
        marginRight: 15,
    },

    infoLinearDescription: {
        color: '#bdbdbd',
        fontSize: '14px',
        fontStyle: 'italic',
        margin: '5px 10px',
    },
    selectRoot: {
        background: 'none',
        width: '100%',
        margin: '0 15px',
        color: '#bdbdbd',
        '&::before': {
            color: 'transparent',
            border: 'none',
        },
        '&:after': {
            border: 'none',
        },
        '&:focus': {
            background: 'none',
        },
    },
    select: {
        background: 'none',

        border: 'none',
        '&:before': {
            border: 'none',
            background: 'none',
        },
        '&:after': {
            border: 'none',
            background: 'none',
        },
        '&:focus': {
            background: 'none',
            border: 'none',
        },
    },
    checkBoxLabel: {
        color: '#bdbdbd',
        fontSize: '16px',
        margin: '10px 0',
    },
    chip: {
        // TODO font color must be defined in palette.text.main or something.
        color: theme.palette.secondary.main,
        backgroundColor: '#F2F2F2',
        borderRadius: '4px',
        margin: '5px 10px 5px 0',
        fontSize: '12px',
    },
    infoDescription: {
        color: '#bdbdbd',
        fontSize: '14px',
        fontStyle: 'italic',
        margin: '5px 0',
    },
}));
