import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';

export const useToolbarContentStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            display: 'flex',
            padding: 0,
        },
        toolbarButton: {
            marginRight: '10px',
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
            border: 0,
            '&:hover': {
                background: 'transparent',
            },
            '&:focus': {
                background: 'transparent',
            },
        },
        toolbarButtonHighlighted: {
            marginLeft: '15px',
            background: 'linear-gradient(0deg, #7368FF, #7368FF)',
            borderRadius: '10px',
            border: 0,
        },
        logoImage: {
            width: '130px',
            height: 'auto',
        },
        toolbarFullLogo: {
            width: '25%',
        },
        toolbarFullLogoButton: {
            padding: '6px 20px',
            margin: '10px 15px 1px 15px',
        },
        toolbarSearch: {
            width: '100%',
        },
        toolbarSearchInput: {
            backgroundColor: theme.palette.common.white,
            height: 40,
        },
        toolbarNavigation: {
            marginLeft: 'auto',
            minWidth: 130,
            display: 'flex',
            padding: '0 10px',
            justifyContent: 'space-between',
        },
        toolbarJoinAction: {
            backgroundColor: '#ffcc00',
            color: theme.palette.primary[500],
            '&:active': {
                color: theme.palette.common.white,
            },
            '&:hover': {
                color: theme.palette.common.white,
            },
        },
        desktopAvatar: {
            alignSelf: 'center',
        },
    }),
);
