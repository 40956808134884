import classNames from 'classnames';
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Box,
    CardContent,
    Grid,
    IconButton,
    Theme,
    Typography,
} from '@material-ui/core';
import { SpriteSvgIcon } from '../molecules/SpriteSvgIcon';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        cursor: 'pointer',
        color: theme.palette.secondary['A800'],
    },
    cardContent: {
        // NOTE: !important is used, because
        // MUI cards always add extra padding bottom to nth:lastchild.
        // And i simply havent found quicker solution to solve this.
        padding: '12px 17px !important',
    },
    nameText: {
        display: 'inline',
        verticalAlign: 'middle',
        wordBreak: 'break-word',
        fontSize: '16px',
    },
    buttonWrapper: {
        padding: '0',
        '& .MuiSvgIcon-root': {
            width: '32px',
            height: '32px',
        },
    },
    positionWidth: {
        maxWidth: 'calc(100% - 40px)',
    },
    skillBox: {
        borderRadius: '6px',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.15)',
    },
}));

export interface AddOrSearchRolesProps {
    onAdd: () => void;
    className?: string;
}

const AddOrSearchRoles = memo((props: AddOrSearchRolesProps) => {
    const classes = useStyles();

    return (
        <Box
            my={4}
            className={classes.root}
            onClick={props.onAdd}
            id="container-anchor-add-new-role"
        >
            <Grid
                container
                spacing={1}
                alignItems="center"
                justify="space-between"
                alignContent="space-between"
            >
                <Grid item xs className={classes.positionWidth}>
                    <Box className={classes.skillBox}>
                        <CardContent className={classes.cardContent}>
                            <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                                alignContent="stretch"
                            >
                                <Grid item xs={10} container>
                                    <Typography
                                        variant="body1"
                                        className={classes.nameText}
                                    >
                                        Add New Role
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    container
                                    alignItems="center"
                                    justify="flex-end"
                                >
                                    <IconButton
                                        className={classes.buttonWrapper}
                                        onClick={props.onAdd}
                                    >
                                        <SpriteSvgIcon id="plus" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
});

AddOrSearchRoles.displayName = 'AddOrSearchRoles';

export { AddOrSearchRoles };
