import { Inject, Injectable } from '@weco/common';
import { CORE_SERVICES } from '../core_services';
import { DepartmentEntity, SkillSetsEntity, SkillEntity } from '../entity';
import {
    SkillSetRepositoryInterface,
    SkillSetsFilter,
    SkillSetRepository,
} from '../repositories/SkillSetRepository';
import { IProfileRepository } from '../repositories/PersonRepository';
import { IProjectRepository } from '../repositories/ProjectRepository';
import defaultSkillSets from '../constants/data/DefaultSkillSets';

@Injectable()
export class SkillsService {
    @Inject(CORE_SERVICES.SkillSetRepositoryInterface)
    private repository: SkillSetRepositoryInterface;

    @Inject(CORE_SERVICES.PersonRepository)
    profileRepository: IProfileRepository;

    @Inject(CORE_SERVICES.IProjectRepository)
    private projectRepository: IProjectRepository;

    //#region Public Methods
    public async loadDepartments(): Promise<DepartmentEntity[]> {
        return this.repository.loadDepartments();
    }

    public async loadSkillSets(
        filter?: SkillSetsFilter,
    ): Promise<SkillSetsEntity[]> {
        const result = await this.repository.loadSkillSets(filter);
        this.setupDefaultOrder(result);
        return result;
    }

    public async addSkillSets(item: SkillSetsEntity): Promise<SkillSetsEntity> {
        item.ownerId = item.ownerId || SkillSetRepository.EMPTY_ID;
        item.projectId = item.projectId || SkillSetRepository.EMPTY_ID;
        return this.repository.addSkillSets(item).then((result) => {
            if (SkillSetRepository.EMPTY_ID != item.ownerId) {
                this.updateUserSkills(item.ownerId);
            }

            if (SkillSetRepository.EMPTY_ID != item.projectId) {
                this.updateProjectSkills(item.projectId);
            }
            result.order = 0;
            return result;
        });
    }

    public async deleteSkillSets(id: string): Promise<SkillSetsEntity> {
        return this.repository.deleteSkillSets(id).then((result) => {
            if (SkillSetRepository.EMPTY_ID != result.ownerId) {
                this.updateUserSkills(result.ownerId);
            }

            if (SkillSetRepository.EMPTY_ID != result.projectId) {
                this.updateProjectSkills(result.projectId);
            }

            return result;
        });
    }

    public async updateSkillSets(
        item: SkillSetsEntity,
    ): Promise<SkillSetsEntity> {
        /**
         * Transform skills into skillsets (prepare data to update).
         */
        item?.skills?.forEach((skill) => {
            skill.value = skill.value.toLowerCase();
            skill.id = `${item?.department?.name}:${skill.value}`;
            skill.departmentName = item?.department?.name;
        });

        return (
            this.repository
                .updateSkillSets(item)
                /**
                 * Update store after updating skillsets.
                 */
                .then((result) => {
                    if (SkillSetRepository.EMPTY_ID != item.ownerId) {
                        this.updateUserSkills(item.ownerId);
                    }

                    if (SkillSetRepository.EMPTY_ID != item.projectId) {
                        this.updateProjectSkills(item.projectId);
                    }

                    return result;
                })
        );
    }

    public async loadDefaultsSkillSets(
        departmentId?: string,
    ): Promise<SkillSetsEntity[]> {
        const filter: SkillSetsFilter = {
            ownerId: SkillSetRepository.EMPTY_ID,
            projectId: SkillSetRepository.EMPTY_ID,
            departmentId: departmentId,
        };
        const result = await this.repository.loadSkillSets(filter);
        this.setupDefaultOrder(result);
        return result;
    }

    private setupDefaultOrder(result: SkillSetsEntity[]) {
        defaultSkillSets.forEach((defaultItem) => {
            const foundItem = result.find(
                (item) => item.name === defaultItem.name,
            );
            if (foundItem) {
                foundItem.order = defaultItem.order;
            }
        });
    }

    public async loadAutocompleteSkillsOptions(
        departmentName?: string,
    ): Promise<SkillEntity[]> {
        return this.repository
            .loadSkillsListForSuggest(departmentName)
            .then((data) =>
                data.filter(
                    (item) =>
                        !!data.find(
                            (a) =>
                                a.value?.toLowerCase() !==
                                item.value?.toLowerCase(),
                        ),
                ),
            );
    }
    //#endregion

    public async getSkillSetById(id: number): Promise<SkillSetsEntity> {
        return this.repository.getSkillSetById(id);
    }

    protected async updateUserSkills(id: string) {
        return this.loadSkillSets({
            ownerId: id,
            projectId: SkillSetRepository.EMPTY_ID,
        }).then((data) => {
            if (!data || data.length === 0) {
                return;
            }

            const owner = data[0].owner;
            owner.skills = SkillsService.extractSkills(data);

            this.profileRepository.updateProfile(owner);
        });
    }

    protected async updateProjectSkills(id: string) {
        return this.loadSkillSets({
            ownerId: SkillSetRepository.EMPTY_ID,
            projectId: id,
        }).then((data) => {
            if (!data || data.length === 0) {
                return;
            }

            const project = data[0].project;
            project.skills = SkillsService.extractSkills(data);

            this.projectRepository.updateItem(project);
        });
    }

    private static extractSkills(data: SkillSetsEntity[]) {
        const allSkillSets = data.reduce((acc, item) => {
            if (item && item.isSelected && item.skills) {
                acc.push(...item.skills);
            }
            return acc;
        }, []);

        return allSkillSets.map((item) => item.id);
    }
}
