import { Grid, makeStyles, Typography } from '@material-ui/core';
import debug from 'debug';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTimeoutFn } from 'react-use';
import CelebrationImage from '../../../../assets/images/onBoardings/half-way-done-celebration.png';
import { RouterPaths } from '../../../router/RouterPaths';
import { APP_EVENTS } from '../../../../app_events';
import { AnalyticsEvent, useAnalytics } from '@weco/common';

const log = debug('CelebrationStep');

const useCelebrationStyles = makeStyles({
    root: {
        height: '100vh',
    },
    image: {
        width: '100%',
    },
    text: {
        fontFamily: 'Ubuntu',
        lineHeight: '30px',
        padding: '0 30px',
        textAlign: 'center',
        fontSize: '26px',
        color: '#8E8E8E',
    },
});

const CelebrationStep = () => {
    const cx = useCelebrationStyles();
    const history = useHistory();
    const { event } = useAnalytics();
    useEffect(() => {
        event(new AnalyticsEvent(APP_EVENTS.ONBOARDING_FINISHED));
    }, []);
    useTimeoutFn(() => history.push(`${RouterPaths.quiz}`), 4000);

    return (
        <Grid
            container
            justify="center"
            alignContent="flex-start"
            className={cx.root}
        >
            <Grid item container justify="center">
                <Grid item lg={3}>
                    <img
                        className={cx.image}
                        src={CelebrationImage}
                        alt="Kids celebrating half way to finishing the interview"
                    />
                </Grid>
            </Grid>
            <Grid item container justify="center">
                <Grid item lg={6}>
                    <Typography className={cx.text}>
                        Now get ready for the last part, the personality test!
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export { CelebrationStep };

export default CelebrationStep;
