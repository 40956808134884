import React from 'react';
import { ReactHelpers } from '../helpers';
interface CompareLocationContainerProps {
    route: string;
    children: ({ isMatch }: { isMatch: boolean }) => JSX.Element;
}

export const CompareLocationContainer = ({
    route,
    children,
}: CompareLocationContainerProps) => {
    const isLocationMatchWith = (route): boolean => {
        return window.location.pathname.split('/')[1] === route.split('/')[1];
    };

    return (
        <>
            {ReactHelpers.renderChildren(children, {
                isMatch: isLocationMatchWith(route),
            })}
        </>
    );
};
