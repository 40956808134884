import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Box, Theme, Typography } from '@material-ui/core';
import { AvatarLoadingAnimation } from './AvatarLoadingAnimation';

const useStyles = makeStyles((theme: Theme) => ({
    listItemContainer: {
        width: '100%',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        cursor: 'pointer',
        borderBottom: '1px solid #0000001f',
        [theme.breakpoints.up('sm')]: {
            padding: '30px',
            height: '192px',
            width: '575px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
            height: '172px',
            width: '355px',
        },
    },
    avatarContainer: {
        [theme.breakpoints.up('md')]: {
            marginRight: '30px',
        },
        [theme.breakpoints.down('md')]: {
            marginRight: '20px',
        },
    },
}));

interface AvatarListHorizontalLoadingAnimationProps {
    variant?: 'rect' | 'circle';
    count?: number;
}

export const AvatarListHorizontalLoadingAnimation = ({
    variant = 'rect',
    count = 1,
}: AvatarListHorizontalLoadingAnimationProps) => {
    const classes = useStyles();
    return (
        <Box width="100%">
            {[...Array(count).keys()].map((key) => (
                <Box key={key} className={classes.listItemContainer}>
                    <Box
                        display="inline-block"
                        className={classes.avatarContainer}
                    >
                        <AvatarLoadingAnimation key={key} variant={variant} />
                    </Box>
                    <Box width="100%" py={2}>
                        <Typography variant="subtitle1" align="center">
                            <Skeleton animation="wave" />
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            <Skeleton animation="wave" />
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            <Skeleton animation="wave" />
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            <Skeleton animation="wave" />
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
