import React from 'react';
import { Switch, Redirect } from 'react-router';
import { QuizPage } from '../quiz/QuizPage';
import { MyProjectPage } from './project/MyProjectPage';
import { MyProfilePage } from './profile/MyProfilePage';
import { MyProjectContainer } from './store/MyProjectContainer';
import { WeRoute } from '../../router/WeRoute';
import { useMyUserData } from '../../store/hooks/useMyUserData';

export const MePage = () => {
    const { activeProjectId } = useMyUserData();
    return (
        <>
            <Switch>
                <WeRoute path={`${MePage.URL}/profile`}>
                    <MyProfilePage baseUrl={`${MePage.URL}/profile`} />
                </WeRoute>
                <WeRoute path={`${MePage.URL}/quiz`}>
                    <QuizPage />
                </WeRoute>
                <WeRoute path={`${MePage.URL}/project/:id`}>
                    {({ match }) => (
                        <MyProjectContainer itemId={match.params.id}>
                            <MyProjectPage
                                baseUrl={`${MePage.URL}/project/${match.params.id}`}
                                projectId={match.params.id}
                            />
                        </MyProjectContainer>
                    )}
                </WeRoute>
                <WeRoute exact path={`${MePage.URL}/project/`}>
                    <Redirect to={`${MePage.URL}/project/${activeProjectId}`} />
                </WeRoute>
                <WeRoute path="/">
                    <Redirect to={`${MePage.URL}/profile`} />
                </WeRoute>
            </Switch>
        </>
    );
};

MePage.URL = '/me';
