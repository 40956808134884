import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const useScrollTopHelper = () => {
    const history = useHistory();
    const topCoordinates = {
        top: 0,
        behavior: 'smooth',
    } as ScrollOptions;

    useEffect(() => {
        console.log('Scrolling to top.');
        window.scroll(topCoordinates);

        const unsubscribeFromHistory = history.listen(() => {
            console.log('Scrolling because listener was activated.');
            window.scroll(topCoordinates);
        });

        return unsubscribeFromHistory;
    }, []);
};
