export class DepartmentEntity {
    id: string;
    name: string;
    description?: string;
}

export type DepartmentEntityWithTestResultPercent = Omit<
    DepartmentEntity,
    ''
> & { testPercent: number };
