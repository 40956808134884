import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import useTheme from '@material-ui/core/styles/useTheme';
import { useMediaQuery } from '@material-ui/core';
import { TopBar } from './particules/TopBar';
import Box from '@material-ui/core/Box';

import classNames from 'classnames';
import { useMainLayoutStyle } from './styles/MainLayout.styles';
import { MinimalisticFooter } from './particules/MinimalisticFooter';
import { UnauthorizedDrawer } from './particules/UnauthorizedDrawer';

interface UnauthenticatedLayoutProps {
    children: JSX.Element;
}

const UnauthenticatedLayout = ({ children }: UnauthenticatedLayoutProps) => {
    const classes = useMainLayoutStyle();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box className={classes.root}>
            <CssBaseline />
            <TopBar desktopMode={isDesktop} toggle={() => {}} />

            {isDesktop && <UnauthorizedDrawer type="founders" />}

            <main
                className={classNames({
                    [classes.contentMobile]: !isDesktop,
                    [classes.contentDesktop]: !!isDesktop,
                })}
            >
                {children}
                <MinimalisticFooter />
            </main>

            {isDesktop && <UnauthorizedDrawer type="everyone" />}
        </Box>
    );
};

export default UnauthenticatedLayout;
