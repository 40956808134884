import {
    AuthProviderInterface,
    AuthProviderSignupInterface,
    UserTokenInterface,
} from '../../common';
import { SimpleUsernamePasswordToken } from './SimpleUsernamePasswordToken';
import { SimpleOAuthToken } from './SimpleOAuthToken';
import { AnonymousUserToken } from '../../AnonymousUserToken';
import { AuthUserToken } from '../../AuthUserToken';
import { BaseHttp, BaseHttpOptions } from '../../../http/BaseHttp';
import { NotSupportedException } from '../../../exceptions/exceptions';

export interface SimpleBackendAuthProviderConfig {
    login: BaseHttpOptions;
    signup?: BaseHttpOptions;
    logout?: BaseHttpOptions;
}

const STORAGE_KEY = 'SIMPLE_AUTH_PROVIDER';

export class SimpleBackendAuthProvider
    implements AuthProviderInterface, AuthProviderSignupInterface {
    constructor(
        private http: BaseHttp,
        private config: SimpleBackendAuthProviderConfig = {
            login: { url: '/auth' },
        },
        private storage: Storage = localStorage,
    ) {}

    support(token) {
        return (
            token instanceof SimpleUsernamePasswordToken ||
            token instanceof SimpleOAuthToken ||
            token instanceof AuthUserToken
        );
    }

    authenticate(token: UserTokenInterface): Promise<UserTokenInterface> {
        if (token.isAuthenticated()) {
            return Promise.resolve(token);
        }
        return this.http
            .request({
                ...this.config.login,
                ...{ data: token },
            })
            .then((result) => {
                const data =
                    typeof result === 'string' ? JSON.parse(result) : result;
                this.storage.setItem(STORAGE_KEY, JSON.stringify(data));
                return new AuthUserToken(data);
            });
    }

    logout(token?): Promise<UserTokenInterface> {
        if (this.config.logout) {
            return this.http
                .request({
                    ...this.config.logout,
                    ...{ data: token },
                })
                .then((result) => {
                    this.storage.removeItem(STORAGE_KEY);
                    return new AnonymousUserToken();
                });
        }

        this.storage.removeItem(STORAGE_KEY);
        return Promise.resolve(new AnonymousUserToken());
    }

    restore(): Promise<UserTokenInterface | null> {
        const userData = JSON.parse(this.storage.getItem(STORAGE_KEY));
        if (!userData) {
            return Promise.reject(null);
        }
        return Promise.resolve(new AuthUserToken(userData));
    }

    signup(token): Promise<UserTokenInterface> {
        if (!this.config.signup) {
            return Promise.reject(
                new NotSupportedException(`Signup is not allowed`),
            );
        }
        return this.http
            .request({
                ...this.config.login,
                ...{ data: token },
            })
            .then((result) => {
                this.storage.setItem(STORAGE_KEY, JSON.stringify(result));
                return new AuthUserToken(result);
            });
    }
}
