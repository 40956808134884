import {
    Injectable,
    Inject,
    COMMON_SERVICES,
    CoreEventDispatcher,
    RecentSearchLocalStorageKeys,
} from '@weco/common';
import { observable, action, runInAction, toJS } from 'mobx';
import {
    ProjectsService,
    CurrentUserProvider,
    CORE_SERVICES,
    SearchFilter,
    PeopleService,
    AdvancedSearchFilter,
    ProjectItemInterface,
    ProfileItemInterface,
    coreConstants,
    CoreConstantsInterface,
} from '@weco/core';
import debug from 'debug';
import { APP_EVENTS } from '../../../app_events';
import { isUndefined } from 'lodash';
import { MyUserStore } from '../../store/MyUserStore';

const log = debug('SearchPageStore');

@Injectable()
export class SearchPageStore {
    @Inject(ProjectsService)
    projectsService: ProjectsService;

    @Inject(PeopleService)
    peopleService: PeopleService;

    @Inject(MyUserStore)
    myUserStore: MyUserStore;

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    private eventDispatcher: CoreEventDispatcher;

    @Inject(CORE_SERVICES.ICurrentUserProvider)
    currentUser: CurrentUserProvider;

    @observable query = '';

    @observable constants: CoreConstantsInterface = coreConstants;

    @observable
    advancedFilter: AdvancedSearchFilter = new AdvancedSearchFilter();

    defaultLimit = 15;
    projectLimit = 15;
    peopleLimit = 15;
    peopleTotal;
    projectTotal;

    @observable
    projects: ProjectItemInterface[];

    @observable
    people: ProfileItemInterface[];

    @observable
    loading = false;

    @observable
    updatedSearchSettings = true;

    @action.bound
    setLoading(p: boolean) {
        this.loading = p;
    }

    @action.bound
    setUpdatedSearchSettings(value: boolean): void {
        this.updatedSearchSettings = value;
    }

    @action.bound
    setAdvancedSearchFilter(
        filter: AdvancedSearchFilter,
        isProjectTab?: boolean,
    ): void {
        log('setAdvancedSearchFilter() args', filter);
        this.advancedFilter = toJS(filter);
        if (isProjectTab) {
            localStorage.setItem(
                RecentSearchLocalStorageKeys.bufferAdvancedFilterProject,
                JSON.stringify(filter),
            );
        }
        if (isProjectTab === false) {
            localStorage.setItem(
                RecentSearchLocalStorageKeys.bufferAdvancedFilterPeople,
                JSON.stringify(filter),
            );
        }
    }

    @action.bound
    async searchProjects(query?: string, limit?: number): Promise<any> {
        const bufferAdvancedFilter = JSON.parse(
            localStorage.getItem(
                RecentSearchLocalStorageKeys.bufferAdvancedFilterProject,
            ),
        );
        if (bufferAdvancedFilter) {
            this.setAdvancedSearchFilter(bufferAdvancedFilter);
        }
        try {
            const filter = this.buildSearchFilter(query);
            const result = await this.projectsService.search({
                filter,
                advancedFilter: this.advancedFilter,
                limit: limit || this.projectLimit,
            });

            let searchResultsMatchPercentHappy = false;
            result.projects.forEach((project) => {
                if (project.matchPercent >= 60) {
                    searchResultsMatchPercentHappy = true;
                }
            });
            searchResultsMatchPercentHappy &&
                this.eventDispatcher.dispatch(
                    APP_EVENTS.HAPPY_SEARCH_RESULTS_PROJECTS,
                    {},
                );
            runInAction(() => {
                log('project search result', result);
                limit && (this.projectLimit = limit);
                this.projects = result.projects;
                this.projectTotal = result.total;
            });
        } catch (err) {
            console.error(err);
        }
    }

    private buildSearchFilter(query?: string) {
        const filter = new SearchFilter();
        filter.query = !isUndefined(query) ? query : this.query || '';
        !isUndefined(query) && (this.query = query);
        return filter;
    }

    @action.bound
    resetProjectLimit() {
        this.projectLimit = this.defaultLimit;
    }

    @action.bound
    async searchPeople(query?: string, limit?: number): Promise<any> {
        const bufferAdvancedFilter = JSON.parse(
            localStorage.getItem(
                RecentSearchLocalStorageKeys.bufferAdvancedFilterPeople,
            ),
        );
        if (bufferAdvancedFilter) {
            this.setAdvancedSearchFilter(bufferAdvancedFilter);
        }
        try {
            const filter = this.buildSearchFilter(query);
            const result = await this.peopleService.search({
                filter,
                advancedFilter: this.advancedFilter,
                limit: limit || this.peopleLimit,
            });
            log('searchPeople() advancedSearch: %O', toJS(this.advancedFilter));
            log('searchPeople() result: %O', result);

            let searchResultsMatchPercentHappy = false;
            result.people.forEach((person) => {
                if (person.matchPercent >= 60) {
                    searchResultsMatchPercentHappy = true;
                }
            });
            searchResultsMatchPercentHappy &&
                this.eventDispatcher.dispatch(
                    APP_EVENTS.HAPPY_SEARCH_RESULTS_PEOPLE,
                    {},
                );
            runInAction(() => {
                limit && (this.peopleLimit = limit);
                this.people = result.people;
                this.peopleTotal = result.total;
            });
        } catch (err) {
            console.error(err);
        }
    }

    @action.bound
    resetPeopleLimit() {
        this.peopleLimit = this.defaultLimit;
    }
}
