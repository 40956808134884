import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
    group: {
        color: theme.palette.primary.dark,
        flexGrow: 1,
        fontSize: '21px',
        lineHeight: '24px',
        textTransform: 'capitalize',
    },
    infoHeading: {
        display: 'flex',
        color: theme.palette.primary.main,
        fontWeight: 500,
        alignItems: 'center',
        textTransform: 'capitalize',
    },
    infoIcon: {
        color: theme.palette.text.secondary,
        marginBottom: 15,
        marginLeft: 15,
        fontSize: '18px',
        fontWeight: 300,
    },
}));

export interface WeSectionTitleIconProps {
    showed?: boolean;
    onClick?: () => void;
}

interface WeSectionTitleProps {
    title: string;
    helpIcon?: WeSectionTitleIconProps;
}

export const WeSectionTitle = ({ title, helpIcon }: WeSectionTitleProps) => {
    const classes = useStyles();
    return (
        <Typography variant="h6" className={classes.infoHeading}>
            {title}
            {helpIcon?.showed && (
                <InfoOutlinedIcon
                    onClick={helpIcon.onClick}
                    className={classes.infoIcon}
                />
            )}
        </Typography>
    );
};
