import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { SearchPageStore } from '../SearchPageStore';

export const useSearchPeopleData = () => {
    const store = useStore<SearchPageStore>(SearchPageStore);

    return useObserver(() => ({
        items: store.people,
        search: store.searchPeople,
        peopleLimit: store.peopleLimit,
        step: store.defaultLimit,
        peopleTotal: store.peopleTotal,
        loading: store.loading,
        setAdvancedSearchFilter: store.setAdvancedSearchFilter,
    }));
};
