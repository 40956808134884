import React from 'react';
import PersonNotificationItem from '../components/PersonNotificationItem';
import { useMyUserData } from '../../../store/hooks/useMyUserData';

const PersonalTabPage = () => {
    const { profile } = useMyUserData();
    return (
        <>
            <PersonNotificationItem
                profile={profile}
                project={profile}
                // specify project instead profile when useing this component
                text={'accepted your application to join '}
            />
            <PersonNotificationItem
                profile={profile}
                project={profile}
                // specify project instead profile when useing this component
                text={'accepted your application to join '}
            />
            <PersonNotificationItem
                profile={profile}
                project={profile}
                // specify project instead profile when useing this component
                text={'accepted your application to join '}
            />
            <PersonNotificationItem
                profile={profile}
                project={profile}
                // specify project instead profile when useing this component
                text={'accepted your application to join '}
            />
        </>
    );
};

export default PersonalTabPage;
