import {
    NoticeContent,
    NoticeOptions,
    NoticeServiceInterface,
} from '@weco/common';
import { action, observable } from 'mobx';
import { renderChildren } from '../helpers/renderChildren';

export class NoticeStore implements NoticeServiceInterface {
    @observable notifications: any[] = [];

    @action.bound
    error(content: NoticeContent, options: NoticeOptions = {}) {
        return this.show(content, { ...options, variant: 'error' });
    }

    @action.bound
    info(content: NoticeContent, options: NoticeOptions = {}) {
        return this.show(content, { ...options, variant: 'info' });
    }

    @action.bound
    success(content: NoticeContent, options: NoticeOptions = {}) {
        return this.show(content, { ...options, variant: 'success' });
    }

    @action.bound
    warn(content: NoticeContent, options: NoticeOptions = {}) {
        return this.show(content, { ...options, variant: 'warning' });
    }

    @action.bound
    hide(key: any) {
        this.notifications = this.notifications.filter(
            (n) => `${n.key}` !== `${key}`,
        );
    }

    @action.bound
    show(content: NoticeContent, options: NoticeOptions = {}) {
        this.notifications = [
            ...this.notifications,
            {
                key:
                    (content as any).key ||
                    new Date().getTime() + Math.random(),
                content: typeof content === 'string' ? content : null,
                options: {
                    ...options,
                    content:
                        typeof content !== 'string'
                            ? (key, message) =>
                                  renderChildren(content, { key, message })
                            : undefined,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                },
            },
        ];
    }
}
