import {
    Box,
    CardContent,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Typography,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/styles';
import { SkillSetsEntity, UserProfileEntity } from '@weco/core';
import { environment } from 'apps/client/src/environments/environment';
import classNames from 'classnames';
import get from 'lodash/get';
import React, { memo, useState } from 'react';
import { SpriteSvgIcon } from '../molecules/SpriteSvgIcon';

export interface PositionsListItemProps {
    position: SkillSetsEntity;
    isPositionHighlighted: boolean;
    highlightedText: string;
    isEditableMode?: boolean;
    onClick: (position: SkillSetsEntity) => void;
    onSwitchClick?: (position: SkillSetsEntity) => void;
    onEditButtonClick?: (position: SkillSetsEntity) => void;
    onDeleteBtnClick?: (position: SkillSetsEntity) => void;
}

const PositionsListItem = memo(
    ({
        position,
        isPositionHighlighted,
        highlightedText,
        ...props
    }: PositionsListItemProps) => {
        const classes = useListItemStyles();
        /**
         * NOTE: this is work in progress.
         * How we save and retrieve users is not yet decided.
         */
        const users = get(position, 'users', []) as UserProfileEntity[];

        const [anchorEl, setAnchorEl] = useState(null);
        const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
        const handleClose = (event) => {
            setAnchorEl(null);
            event.stopPropagation();
            event.preventDefault();
        };

        function isAddedRole(position: SkillSetsEntity): boolean {
            return !(
                (position.projectId === position.ownerId &&
                    position.projectId === environment.defaults.emptyId) ||
                !position.isSelected
            );
        }

        return (
            <Box
                boxShadow={5}
                onClick={() => props.onClick(position)}
                className={classNames(
                    classes.skillBox,
                    position.isDefault && classes.isDefault,
                    isPositionHighlighted && classes.highlightedBorder,
                )}
            >
                <Menu
                    className={classes.menu}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        className={classes.menuItem}
                        onClick={(e) => {
                            handleClose(e);
                            props.onEditButtonClick(position);
                        }}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem
                        className={classes.menuItem}
                        onClick={(e) => {
                            handleClose(e);
                            props.onSwitchClick(position);
                        }}
                    >
                        Deactivate
                    </MenuItem>
                    {!position.isDefault && (
                        <MenuItem
                            className={classes.menuItem}
                            onClick={(e) => {
                                handleClose(e);
                                props.onDeleteBtnClick(position);
                            }}
                        >
                            Delete
                        </MenuItem>
                    )}
                </Menu>
                <CardContent className={classes.cardContent}>
                    {isPositionHighlighted && (
                        <Typography className={classes.isMatchedPosition}>
                            {highlightedText}
                        </Typography>
                    )}
                    <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        alignContent="stretch"
                    >
                        <Grid xs={10}>
                            <Typography
                                variant="caption"
                                className={classes.nameText}
                            >
                                {position.name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            container
                            wrap="nowrap"
                            justify="flex-end"
                            alignItems="center"
                        >
                            {/*{isEmpty(users) ? (*/}
                            {/*    <WeChip item="Open" />*/}
                            {/*) : (*/}
                            {/*    <PeopleAvatarList people={users} />*/}
                            {/*)}*/}
                            <Box ml={1}>
                                {props.isEditableMode ? (
                                    <>
                                        {isAddedRole(position) ? (
                                            <IconButton
                                                className={
                                                    classes.buttonWrapper
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    handleMenuClick(e);
                                                }}
                                            >
                                                <SpriteSvgIcon id="menu" />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                className={
                                                    classes.buttonWrapper
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    props.onSwitchClick(
                                                        position,
                                                    );
                                                }}
                                            >
                                                <SpriteSvgIcon id="plus" />
                                            </IconButton>
                                        )}
                                    </>
                                ) : (
                                    <ArrowRightIcon
                                        color="secondary"
                                        className={classes.arrowIcon}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
        );
    },
);

const useListItemStyles = makeStyles((theme: Theme) => ({
    cardContent: {
        // NOTE: !important is used, because
        // MUI cards always add extra padding bottom to nth:lastchild.
        // And i simply havent found quicker solution to solve this.
        padding: '20px 17px !important',
    },
    nameText: {
        display: 'inline',
        verticalAlign: 'middle',
        wordBreak: 'break-word',
    },
    isMatchedPosition: {
        borderRadius: '7px',
        background: '#f2f1fe',
        marginTop: '-29px',
        height: '20px',
        fontSize: '10px',
        padding: '3px',
        width: '70px',
        marginBottom: '7px',
        textAlign: 'center',
    },
    highlightedBorder: {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
    },
    arrowIcon: {
        verticalAlign: 'middle',
    },
    isDefault: {},
    skillBox: {
        borderRadius: '10px',
        cursor: 'pointer',
    },
    buttonWrapper: {
        padding: '0',
        '& .MuiSvgIcon-root': {
            width: '32px',
            height: '32px',
        },
    },
    menu: {
        '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    menuItem: {
        padding: '10px 10px',
        margin: 0,
    },
}));

PositionsListItem.displayName = 'PositionsListItem';

export { PositionsListItem };
