export const REQUEST_ROOMS = 'REQUEST_ROOMS';
export const RECEIVE_LIST_ROOM = 'RECEIVE_LIST_ROOM';
export const RECEIVE_LIST_ROOM_DELETED = 'RECEIVE_LIST_ROOM_DELETED';
export const RECEIVE_LIST_ROOMS = 'RECEIVE_LIST_ROOMS';
export const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM';
export const SET_TMP_ROOM = 'SET_TMP_ROOM';
export const DELETE_TMP_ROOM = 'DELETE_TMP_ROOM';
export const SET_START_ROOM_CREATION = 'SET_START_ROOM_CREATION';
export const UPDATE_ROOM_LAST_MESSAGE = 'UPDATE_ROOM_LAST_MESSAGE';
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
export const SET_DATA_LOADING = 'SET_DATA_LOADING';
