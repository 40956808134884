import React from 'react';
import { Button, Container, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { QuizSectionComponent } from '@weco/quiz';
import { observer } from 'mobx-react';
import { DataContainer } from './store/DataContainer';

const useStyles = makeStyles((theme) => ({
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 0, 3),
    },
    question: {
        padding: 40,
    },
    buttonBox: {
        margin: '20px 0',
        width: '100%',
    },
    joinButton: {
        background: 'linear-gradient(86.51deg, #544F94 13.51%, #755DD8 72.7%)',
        borderRadius: '10px',
        color: theme.palette.background.default,
        fontSize: '16px',
        fontWeight: 500,
        width: '100%',
        margin: '10px',
        padding: '10px',
    },
}));

export const Quiz = observer(() => {
    const classes = useStyles();

    return (
        <div className={classes.heroContent}>
            <DataContainer>
                {({
                    questions,
                    answers,
                    activePage,
                    nextPage,
                    prevPage,
                    setAnswer,
                    showContinueQuestion,
                    handleStartNew,
                    handleContinue,
                    onClose,
                }) => (
                    <>
                        {showContinueQuestion ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                className={classes.question}
                            >
                                <Typography variant="h6">
                                    You did not complete the previous test. Do
                                    you want to continue ?
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={classes.buttonBox}
                                >
                                    <Button
                                        className={classes.joinButton}
                                        onClick={handleContinue}
                                    >
                                        Continue
                                    </Button>
                                    <Button
                                        className={classes.joinButton}
                                        onClick={handleStartNew}
                                    >
                                        Start New
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Container maxWidth="md">
                                {questions?.length > 0 && (
                                    <QuizSectionComponent
                                        onClose={onClose}
                                        questions={questions}
                                        answers={answers}
                                        activePage={activePage}
                                        nextPage={nextPage}
                                        prevPage={prevPage}
                                        setAnswer={setAnswer}
                                        sectionIndex={activePage - 1}
                                        section={
                                            questions[activePage - 1]
                                                ? questions[activePage - 1]
                                                : null
                                        }
                                    />
                                )}
                            </Container>
                        )}
                    </>
                )}
            </DataContainer>
        </div>
    );
});
