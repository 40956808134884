import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import uniqid from 'uniqid';
import classNames from 'classnames';

interface WeMultilineTextClasses {
    root?: string;
}

export type WeMultilineTextProps = {
    text: string;
    classes?: WeMultilineTextClasses;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: 0,
        minHeight: 10,
    },
}));

export const WeMultilineText = ({ text, classes }: WeMultilineTextProps) => {
    const cx = useStyles();

    return (
        <>
            {text?.split('\n').map((row) => (
                <p
                    key={uniqid()}
                    className={classNames(cx.root, classes?.root)}
                >
                    {row}
                </p>
            ))}
        </>
    );
};
