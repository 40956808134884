import { AnalyticsProviderInterface } from '../AnalyticsProviderInterface';
import { AnalyticsEvent, AuthAnalyticsEvent, loadScript } from '@weco/common';
import smartlookClient from 'smartlook-client';

export class SmartlookProvider implements AnalyticsProviderInterface {
    constructor(private settings) {}

    load(): Promise<any> {
        if (!this.settings?.appId) {
            return Promise.reject('Smartlook is not enabled');
        }
        smartlookClient.init(this.settings.appId);
        return Promise.resolve(this);
    }

    click = (event: AnalyticsEvent) => {};
    event = (event: AnalyticsEvent) => {};
    exception = (e: Error, severity: string = 'LOW'): void => {};
    identify = (event: AuthAnalyticsEvent) => {
        if (!event.userId) {
            smartlookClient.anonymize();
            return;
        }

        smartlookClient.identify(event.userId, {
            name: `${event.payload.firstName} ${event.payload.lastName}`,
            email: event.payload.email,
        });
    };
    pageView = (url: string, type?: string) => {};
}
