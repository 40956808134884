import * as mobxLogger from 'mobx-logger';
import getDecorators from 'inversify-inject-decorators';
import { environment } from '../../environments/environment';
import { ProvidersContainer } from '@weco/common';

if (!environment.production) {
    mobxLogger.enableLogging({
        action: true,
        // NOTE: disabled because information is too verbose and useless.
        // compute: true,
        // reaction: true,
        // transaction: true,
    });
}

const di: ProvidersContainer = new ProvidersContainer([]);
export const {
    lazyInject,
    lazyInjectNamed,
    lazyInjectTagged,
    lazyMultiInject,
} = getDecorators(di.container);

export default di;
