import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, LinearProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import { useQuery } from '../../hooks/useQuery';
import { AnalyticsEvent, Auth, useAnalytics } from '@weco/common';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import { WeTextField } from '@weco/ui';
import { RouterPaths } from '../../router/RouterPaths';
import Grid from '@material-ui/core/Grid';
import { WE_CLICKS } from '../../../we_clicks';
import { APP_EVENTS } from '../../../app_events';

interface FormData {
    code: string;
    username: string;
}

export const ConfirmSignUpPage = () => {
    const history = useHistory();
    const query = useQuery();
    const initValues = {
        code: query.get('code'),
        username: query.get('username'),
    };
    const { event } = useAnalytics();
    const { signup } = useCurrentAuthData();
    return (
        <Formik
            initialValues={initValues}
            validate={(values) => {
                const errors: Partial<FormData> = {};
                if (!values.code) {
                    errors.code = 'Required';
                }
                if (!values.username) {
                    errors.code = 'Required';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                signup(
                    new Auth.AmplifyConfirmAccountToken(
                        values.username,
                        values.code,
                    ),
                )
                    .then(() => {
                        setSubmitting(false);
                        event(
                            new AnalyticsEvent(APP_EVENTS.AUTH_SIGNUP, {
                                email: values.username,
                            }),
                        );
                        history.push(RouterPaths.login);
                    })
                    .catch((e: Error) => {
                        setSubmitting(false);
                        if (e.message.includes('Current status is CONFIRMED')) {
                            history.push(RouterPaths.login);
                            return;
                        }
                        alert(e);
                    });
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={8}>
                            <Typography variant="h3">
                                To Continue you need to confirm your WeCo
                                Account
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={5}>
                            <Form>
                                {!initValues.username && (
                                    <Box>
                                        <Field
                                            component={WeTextField}
                                            name="username"
                                            type="text"
                                            label="username"
                                        />
                                    </Box>
                                )}
                                {!initValues.code && (
                                    <Box>
                                        <Field
                                            component={WeTextField}
                                            type="text"
                                            label="Code"
                                            name="code"
                                        />
                                    </Box>
                                )}
                                {isSubmitting && <LinearProgress />}
                                <br />
                                <Button
                                    id={WE_CLICKS.CONFIRM_SIGNUP}
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                >
                                    Confirm
                                </Button>
                            </Form>
                        </Grid>
                    </Grid>
                </>
            )}
        </Formik>
    );
};
