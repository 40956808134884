export interface AnalyticsPayload {
    userId?: string;
    userName?: string;
    projectName?: string;
    projectId?: string;
    email?: string;
}

export interface EventInterface {
    subject: any;
}

export class Event implements EventInterface {
    subject: AnalyticsPayload;

    constructor(data?: any) {
        this.subject = data;
    }
}
