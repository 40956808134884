import { AnonymousUserToken } from '../../AnonymousUserToken';
import { AmplifyAnonymousUserToken } from './AmplifyAnonymousUserToken';
import { AmplifySessionToken } from './AmplifySessionToken';
import { AmplifyConfirmAccountToken } from './AmplifyConfirmAccountToken';
import {
    InvalidConfigurationException,
    InvalidCredentialsException,
} from '../../../exceptions/exceptions';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import {
    AuthProviderInterface,
    AuthProviderSignupInterface,
    BaseUserToken,
    UserTokenInterface,
} from '../../common';
import { UsernamePasswordToken } from '../../UsernamePasswordToken';
import { SignUpUsernamePasswordToken } from './SignUpUsernamePasswordToken';
import { AmplifyFederatedSignInToken } from './AmplifyFederatedSignInToken';
import { AmplifyResendSignUpToken } from './AmplifyResendSignUpToken';
import { AmplifyForgotPasswordSubmitToken } from './AmplifyForgotPasswordSubmitToken';
import { AmplifyForgotPasswordToken } from './AmplifyForgotPasswordToken';

export class AmplifyAuthProvider
    implements AuthProviderInterface, AuthProviderSignupInterface {
    constructor(private readonly auth: AuthClass) {}

    /**
     *
     * @param {AmplifyAnonymousUserToken | AmplifySessionToken} token
     */
    support(token) {
        return (
            token instanceof AmplifyAnonymousUserToken ||
            token instanceof AmplifySessionToken ||
            UsernamePasswordToken ||
            AmplifyConfirmAccountToken ||
            AmplifyResendSignUpToken ||
            AmplifyForgotPasswordToken ||
            AmplifyForgotPasswordSubmitToken
        );
    }

    /**
     *
     * @param {UsernamePasswordToken} token
     * @return Promise{EnterpriseUserToken|Error}
     */
    async authenticate(token): Promise<UserTokenInterface> {
        if (token instanceof AmplifySessionToken) {
            return Promise.resolve(token);
        }
        try {
            if (token instanceof UsernamePasswordToken) {
                const data = await this.auth.signIn(
                    token.username,
                    token.password,
                );
                return new AmplifySessionToken(data.signInUserSession);
            }
            if (token instanceof AmplifyFederatedSignInToken) {
                const data = await this.auth.federatedSignIn({
                    provider: token.provider,
                });

                return data
                    ? new AmplifySessionToken(data as any)
                    : new AnonymousUserToken();
            }
        } catch (e) {
            throw new InvalidCredentialsException(e.message, e);
        }
        throw new InvalidConfigurationException('Unsupported Token Type');
    }

    logout(): Promise<BaseUserToken> {
        return this.auth.signOut().then(() => new AnonymousUserToken());
    }

    restore(): Promise<AmplifySessionToken | null> {
        // only manual call of provider should check session
        return this.auth.currentSession().then((data) => {
            return new AmplifySessionToken(data as any);
        });
    }

    signup(
        token: SignUpUsernamePasswordToken | AmplifyConfirmAccountToken,
    ): Promise<UserTokenInterface> {
        // this.auth.confirmSignUp()
        if (token instanceof SignUpUsernamePasswordToken) {
            return this.auth
                .signUp({
                    username: token.username,
                    password: token.password,
                    attributes: {
                        family_name: token.attributes.lastName,
                        given_name: token.attributes.firstName,
                    },
                })
                .then((data) => {
                    return new AnonymousUserToken();
                })
                .catch((e) => Promise.reject(new Error(e.message || e)));
        }
        if (token instanceof AmplifyConfirmAccountToken) {
            return this.auth
                .confirmSignUp(token.username, token.code)
                .then((data) => {
                    return new AnonymousUserToken();
                })
                .catch((e) => Promise.reject(new Error(e.message || e)));
        }
        return Promise.reject(
            new InvalidConfigurationException('Unsupported Token Type'),
        );
    }

    resendSignUp(token: AmplifyResendSignUpToken): Promise<string> {
        return this.auth
            .resendSignUp(token.getUsername())
            .catch((e) => Promise.reject(new Error(e.message || e)));
    }

    forgotPassword(token: AmplifyForgotPasswordToken): Promise<any> {
        return this.auth
            .forgotPassword(token.getUsername())
            .catch((e) => Promise.reject(new Error(e.message || e)));
    }

    forgotPasswordSubmit(
        token: AmplifyForgotPasswordSubmitToken,
    ): Promise<void> {
        return this.auth
            .forgotPasswordSubmit(
                token.getUsername(),
                token.code,
                token.password,
            )
            .catch((e) => Promise.reject(new Error(e.message || e)));
    }
}
