import React from 'react';
import { FinishComponent } from '@weco/quiz';
import { AnswerCalculateContainer } from './store/DataContainer';
import { useMyUserProfileData } from '../../store/hooks/useMyUserProfileData';
import { PersonRolesContainer } from '../../store/containers/PersonRolesContainer';
export const Finish = () => {
    const { profile } = useMyUserProfileData();
    return (
        <PersonRolesContainer personId={profile?.id} testPages>
            <AnswerCalculateContainer>
                {(props) => <FinishComponent {...props} />}
            </AnswerCalculateContainer>
        </PersonRolesContainer>
    );
};
