import debug from 'debug';
import classNames from 'classnames';
import React, { memo } from 'react';
import { Box, Theme, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TopBarIcon } from './TopBarIcon';
import { SpriteSvgIcon } from '../molecules';

const log = debug('NotificationsIndicator');

const useStyles = makeStyles((theme: Theme) => ({ root: {} }));

interface Props {
    unreadCount: number;
    isChecked?: boolean;
    className?: string;
    onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const NotificationsIndicator = memo((props: Props) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);
    log('Notifications count:', props.unreadCount);

    return (
        <Box className={rootClasses} id="container-anchor-topbar-chat">
            <Badge color="error" badgeContent={props.unreadCount}>
                <TopBarIcon isSvg onClick={props.onClick}>
                    <SpriteSvgIcon
                        id={
                            !props.isChecked
                                ? 'MainMenu-Chat'
                                : 'MainMenu-Chat-Checked'
                        }
                    />
                </TopBarIcon>
            </Badge>
        </Box>
    );
});

NotificationsIndicator.displayName = 'NotificationsIndicator';

export { NotificationsIndicator };
