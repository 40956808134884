export default {
    question_sections: [
        {
            lines: [
                {
                    id: 1,
                    isMain: true,
                    question: 'Who are you closer to?',
                    start: 'Early Bird',
                    end: 'Night Owl',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 2,
                    question: null,
                    start: 'Neat',
                    end: 'Messy',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 3,
                    question: null,
                    start: 'Outgoing',
                    end: 'Introvert',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 4,
                    question: null,
                    start: 'Leader',
                    end: 'Follower',
                    points: [1, 2, 3, 4],
                },
            ],
        },
        {
            lines: [
                {
                    id: 5,
                    isMain: true,
                    question: 'What do you do more?',
                    start: 'Plan',
                    end: 'Improvise',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 6,
                    question: null,
                    start: 'Talk',
                    end: 'Listen',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 7,
                    question: null,
                    start: 'Play Safe',
                    end: 'Risk',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 8,
                    question: null,
                    start: 'Teach',
                    end: 'Learn',
                    points: [1, 2, 3, 4],
                },
            ],
        },
        {
            lines: [
                {
                    id: 9,
                    isMain: true,
                    question: 'How do you think and work?',
                    start: 'Heart',
                    end: 'Head',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 10,
                    question: null,
                    start: 'Concept',
                    end: 'Details',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 11,
                    question: null,
                    start: 'Team',
                    end: 'Alone',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 12,
                    question: null,
                    start: 'Email',
                    end: 'Phone',
                    points: [1, 2, 3, 4],
                },
            ],
        },
        {
            lines: [
                {
                    id: 13,
                    isMain: true,
                    question: 'How do you see yourself?',
                    start: 'Thinker',
                    end: 'Doer',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 14,
                    question: null,
                    start: 'Dreamer',
                    end: 'Realist',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 15,
                    question: null,
                    start: 'Creative',
                    end: 'Analytical',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 16,
                    question: null,
                    start: 'Famous',
                    end: 'Incognito',
                    points: [1, 2, 3, 4],
                },
            ],
        },
        {
            lines: [
                {
                    id: 17,
                    question: 'I like social networks',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 18,
                    question: 'I like strategy games',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 19,
                    question: 'I like delegating',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 20,
                    question: 'I can make a logical plan',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
            ],
        },
        {
            lines: [
                {
                    id: 21,
                    question: 'Everything is possible',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 22,
                    question: 'I have a good imagination',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 23,
                    question: 'I easily admit my mistakes',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 24,
                    question: 'I can keep a secret',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
            ],
        },
        {
            lines: [
                {
                    id: 25,
                    question: 'I like to fill out different papers',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 26,
                    question: 'My passion is contagious',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 27,
                    question: 'I am comfortable telling the truth',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 28,
                    question: 'I like to solve problems',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
            ],
        },
        {
            lines: [
                {
                    id: 29,
                    question: 'I like math and calculations!!',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 30,
                    question: 'I work better under deadlines',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 31,
                    question: 'I always stay on my budget',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
                {
                    id: 32,
                    question: 'I can organize a good party',
                    start: 'No',
                    end: 'Yes',
                    points: [1, 2, 3, 4],
                },
            ],
        },
    ],
} as any;
