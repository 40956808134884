import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
    findMatchedDepartments,
    findActiveDepartments,
    includesSkills,
} from '@weco/common';
import {
    AllDepartments,
    departmentColors,
    DepartmentEntityWithTestResultPercent,
    getDepartmentDisplayName,
    SkillSetsEntity,
    UserProfileEntity,
} from '@weco/core';
import classNames from 'classnames';
import debug from 'debug';
import last from 'lodash/last';
import { toJS } from 'mobx';
import React, { memo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { AddOrSearchRoles } from './AddOrSearchRoles';
import { NavigationArrows } from './NavigationArrows';
import { PositionsFilterMenuOption } from './PositionsFilterMenu';
import { PositionsHeader } from './PositionsHeader';
import { PositionsList } from './PositionsList';
import { WecoDepartmentsNavigation } from './WecoDepartmentsNavigation';
import { scrollToNavTabs } from './NavTabs';

const log = debug('DepartmentsRouting');

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    suggestions: {
        color: theme.palette.secondary['A800'],
    },
}));

interface DepartmentPage {
    color: string;
    departmentCode: string;
    isHeaderSimplified?: boolean;
}

const pages = AllDepartments.map((department) => ({
    departmentCode: department,
    color: departmentColors[department],
    isHeaderSimplified: false,
})) as DepartmentPage[];
// Add initial page.
pages.unshift({
    color: '#544F94',
    isHeaderSimplified: true,
    departmentCode: 'Overview',
});

export const departmentDescriptionsForProject = {
    ideas:
        'Your project must have someone constantly generating innovative ideas. The one who is not scared of thinking the unthinkable.',
    strategy:
        'Action without vision is a nightmare. Any team requires someone who sees things clearly. Who can align everyone and everything to put ideas in motion.',
    marketing:
        'Positive experience of your fans (customers, followers, readers, users) should be the focal point of your project. You need a right mix of talents to help educate and engage your audience.',
    itweb:
        'Technology is a big part of every innovative project. Find passionate geeks who will help you develop and run your website or app.',
    legal:
        'Nothing could be as expensive as a cheap attorney. Trademarks, patents, contracts, disclosures and many other important documents could minimize the legal issues all across the board.',
    hr:
        'Someone who cares has to work with your project’s most valuable asset: people. That someone will bring out the best in others by inspiring them to learn, grow, produce and feel happy.',
    execution:
        'Someone has to actually create that great meal, dance class or a book and make sure it comes in time and is of the best quality. These are the people who roll up their sleeves and make all the ideas and strategies manifest in reality.',
    finance:
        'Finance assures the viability of any serious project. Planning, filing, reporting, and calculating are among a few functions that have to be coordinated and always checked for accuracy.',
};

const departmentDescriptionsForPerson = {
    ideas:
        'Every team needs a person who constantly generates new and relevant ideas. Can this be you?',
    strategy:
        'It takes a series of steps to bring great ideas to fruition. Can you develop a solid roadmap to turn a dream into a reality?',
    marketing:
        'Are you interested to engage people (users, customers, followers, readers, visitors and fans)? Use your talents to educate them about  the value that a project offers.',
    itweb:
        'You are passionate about technology, programming, science, algorithms, and all that cool stuff.  True or False.',
    legal:
        'Are you licensed to help with contracts, disclosures, intellectual property and other important documents?',
    hr:
        'Can you put HUMAN back into HR and help create a happy and productive environment?',
    execution:
        'Are you fascinated to work on the actual solution, product or service? Use your skills and knowledge to develop something that people will touch and/or feel.',
    finance:
        'Every project needs a finance hero who will balance all the debits and credits. Is that you?',
};

export interface DepartmentsRoutingProps {
    className?: string;
    initialPage: JSX.Element;
    roles: SkillSetsEntity[];
    rolesForSuggest?: SkillSetsEntity[];
    areRolesEditable: boolean;
    /**
     * Match current users positions with displayed
     * positions to highlight the similarities.
     */
    matchSkillSetsWith?: string[];
    typeofDescriptionToUse: 'project' | 'person';
    departments: DepartmentEntityWithTestResultPercent[];
    onVisibilitySwitch?: (position: SkillSetsEntity) => void;
    onEditRoleButtonClick?: (position: SkillSetsEntity) => void;
    onDeleteBtnClick?: (position: SkillSetsEntity) => void;
    onAddRole?: (department: DepartmentEntityWithTestResultPercent) => void;
    profile: UserProfileEntity;
}

const DepartmentsRouting = memo((props: DepartmentsRoutingProps) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const rootClasses = classNames(classes.root, props.className);

    const [selectedFilterOption, setFilterOption] = useState<
        PositionsFilterMenuOption
    >('all');

    const currentPageNumber = pages.findIndex(({ departmentCode }) =>
        location.pathname.endsWith(departmentCode),
    );
    const currentPage =
        currentPageNumber === -1 ? pages[0] : pages[currentPageNumber];
    const currentDepartment = props.departments?.find(
        (department) => department.name === currentPage.departmentCode,
    );
    const rolesOfThisDepartment = props.roles?.filter((role) => {
        const roleBelongsToCurrentDepartment =
            role?.department?.name === currentPage.departmentCode;
        const isPositionMatched = includesSkills(
            role,
            props.matchSkillSetsWith,
        );

        if (selectedFilterOption === 'all') {
            return roleBelongsToCurrentDepartment;
        }
        if (selectedFilterOption === 'active') {
            return roleBelongsToCurrentDepartment && role.isSelected;
        }
        if (selectedFilterOption === 'matched') {
            return roleBelongsToCurrentDepartment && isPositionMatched;
        }
        return roleBelongsToCurrentDepartment;
    });
    const suggestionRolesForThisDepartment = props.rolesForSuggest?.filter(
        (role) => {
            const roleBelongsToCurrentDepartment =
                role?.department?.name === currentPage.departmentCode;
            return roleBelongsToCurrentDepartment;
        },
    );

    function navigateToNextPage() {
        let nextDepartmentPage = pages[currentPageNumber + 1];

        if (currentPageNumber === -1) nextDepartmentPage = pages[1];
        if (currentPageNumber >= pages.length - 1)
            nextDepartmentPage = pages[0];

        history.replace(nextDepartmentPage.departmentCode);
    }

    function navigateToPreviousPage() {
        let previousDepartmentPage = pages[currentPageNumber - 1];
        if (currentPageNumber < 1) previousDepartmentPage = last(pages);

        history.replace(previousDepartmentPage.departmentCode);
    }

    log('pages: %O', pages);
    log('currentPageNumber: ', currentPageNumber);
    log('All positions: %O', toJS(props.roles));
    log('Roles of this department: %O', rolesOfThisDepartment);
    return (
        <Box className={rootClasses}>
            <WecoDepartmentsNavigation
                departmentToHighglight={currentPage.departmentCode}
                matchedDepartments={findMatchedDepartments(
                    props.roles,
                    props.matchSkillSetsWith,
                )}
                departmentsWithSelectedRoles={findActiveDepartments(
                    props.roles,
                )}
            />
            <Box height="35px" />
            <NavigationArrows
                isLeftArrowHidden={currentPageNumber < 1}
                onRightArrowClick={() => {
                    navigateToNextPage();
                    scrollToNavTabs();
                }}
                onLeftArrowClick={() => {
                    navigateToPreviousPage();
                    scrollToNavTabs();
                }}
                middleComponent={
                    <PositionsHeader
                        profile={props.profile}
                        dividerColor={currentPage.color}
                        onFilterMenuChange={setFilterOption}
                        isOnlyTitleVisible={currentPageNumber < 1}
                        matchPercent={currentDepartment?.testPercent}
                        isFilteringMyOwnPositions={props.areRolesEditable}
                        title={getDepartmentDisplayName(
                            currentPage.departmentCode,
                        )}
                        description={
                            props.typeofDescriptionToUse === 'person'
                                ? departmentDescriptionsForPerson[
                                      currentPage.departmentCode
                                  ]
                                : departmentDescriptionsForProject[
                                      currentPage.departmentCode
                                  ]
                        }
                    />
                }
            />
            <Box mt={5} minHeight="50vh">
                {currentPageNumber < 1 ? (
                    <Box mt={10}>{props.initialPage}</Box>
                ) : (
                    <>
                        <Box>
                            <Typography variant="h2">Roles</Typography>
                            <PositionsList
                                items={rolesOfThisDepartment}
                                matchSkillSetsWith={props.matchSkillSetsWith}
                                onVisibilitySwitch={props.onVisibilitySwitch}
                                isCheckBoxDisplayed={props.areRolesEditable}
                                isEditButtonDisplayed={props.areRolesEditable}
                                onEditButtonClick={props.onEditRoleButtonClick}
                                onDeleteBtnClick={props.onDeleteBtnClick}
                            />

                            {props.areRolesEditable && (
                                <Box textAlign="center">
                                    <AddOrSearchRoles
                                        onAdd={() =>
                                            props.onAddRole(currentDepartment)
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                        {suggestionRolesForThisDepartment &&
                            suggestionRolesForThisDepartment.length > 0 && (
                                <Box className={classes.suggestions}>
                                    <Typography variant="h2">
                                        Suggestions
                                    </Typography>
                                    <PositionsList
                                        items={suggestionRolesForThisDepartment}
                                        matchSkillSetsWith={[]}
                                        onVisibilitySwitch={
                                            props.onVisibilitySwitch
                                        }
                                        isCheckBoxDisplayed={
                                            props.areRolesEditable
                                        }
                                        isEditButtonDisplayed={
                                            props.areRolesEditable
                                        }
                                        onEditButtonClick={
                                            props.onEditRoleButtonClick
                                        }
                                    />
                                </Box>
                            )}
                    </>
                )}
            </Box>
        </Box>
    );
});

DepartmentsRouting.displayName = 'DepartmentsRouting';

export { DepartmentsRouting };
