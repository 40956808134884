import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useMyUserProjectsData } from '../../store/hooks/useMyUserProjectsData';
import { useHistory } from 'react-router';
import { CircleLoader, DeleteDialogView, SpriteSvgIcon } from '@weco/ui';
import { RouterPaths } from '../../router/RouterPaths';

const useStyles = makeStyles(() => ({
    menu: {
        '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    menuItem: {
        padding: '10px 10px',
        margin: 0,
    },
    root: {
        float: 'right',
    },
    moreButton: {
        color: '#ffffff',
        cursor: 'pointer',
    },
}));

export const CoverMenu = ({ projectId }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const [openDialog, setOpenDialog] = useState(false);
    const history = useHistory();
    const { deleteProject } = useMyUserProjectsData();
    const [isBusy, setIsBusy] = useState(false);

    return (
        <>
            <Menu
                className={classes.menu}
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                        handleClose();
                        setOpenDialog(true);
                    }}
                >
                    Delete project
                </MenuItem>
            </Menu>
            <DeleteDialogView
                itemName="project"
                id={projectId}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                deleteAction={async (id) => {
                    setIsBusy(true);
                    setOpenDialog(false);
                    try {
                        await deleteProject(id);
                        setIsBusy(false);
                    } finally {
                        setIsBusy(false);
                    }
                }}
                history={history}
                resultPath={RouterPaths.myProfile}
            />
            <IconButton onClick={handleClick}>
                <SpriteSvgIcon id="Profile-Settings-Light" />
            </IconButton>
            {/*{isBusy && <InfinityLoader />}  NOTE: hidden for now but maybe we will use this again later.*/}
            {isBusy && <CircleLoader />}
        </>
    );
};
