import { ROOM_TYPE_PROJECTS } from './constants';
import { RoomInterface } from '../models/interfaces/RoomInterface';
import UserInterface, {
    UsersListInterface,
} from '../models/interfaces/UserInterface';
import { ReferenceDataInterface } from '../models/interfaces/ReferenceDataInterface';

export const getRoomName = (
    room: RoomInterface,
    users: UsersListInterface,
    currentUser: UserInterface,
): string => {
    switch (room.type) {
        case ROOM_TYPE_PROJECTS:
            return room.name;
        default:
            const member: ReferenceDataInterface = room.members.find(
                (member: ReferenceDataInterface) =>
                    member.id !== currentUser.id,
            );
            return member && users[member.id] ? users[member.id].name : '';
    }
};

export const getUserFirstLetters = (user: UserInterface) =>
    (user.name || '')
        .split(' ')
        .map((part) => part.substring(0, 1))
        .join('')
        .toUpperCase();
