import * as actionTypes from './actionTypes';
import MessageInterface from '../../models/interfaces/MessageInterface';

export function subscribedRoomMessages(roomId: string) {
    return {
        type: actionTypes.SUBSCRIBED_ROOM_MESSAGES,
        roomId,
    };
}

export function receiveRoomMessage(roomId: string, message: MessageInterface) {
    return {
        type: actionTypes.RECEIVE_NEW_ROOM_MESSAGE,
        roomId,
        message,
    };
}

export function receiveUpdateRoomMessage(
    roomId: string,
    message: MessageInterface,
) {
    return {
        type: actionTypes.RECEIVE_UPDATE_ROOM_MESSAGE,
        roomId,
        message,
    };
}

export function receiveDeleteRoomMessage(
    roomId: string,
    message: MessageInterface,
) {
    return {
        type: actionTypes.RECEIVE_DELETE_ROOM_MESSAGE,
        roomId,
        message,
    };
}

export function receiveRoomMessages(
    roomId: string,
    messages: MessageInterface[],
) {
    return {
        type: actionTypes.RECEIVE_ROOM_MESSAGES,
        roomId,
        messages,
    };
}

export function sendRoomMessages(roomId: string, message: MessageInterface) {
    return {
        type: actionTypes.REQUEST_SEND_MESSAGE,
        roomId,
        message,
    };
}

export function errorSentRoomMessages(
    roomId: string,
    message: MessageInterface,
    error: Error,
) {
    return {
        type: actionTypes.RECEIVE_SEND_ERROR_MESSAGE,
        roomId,
        message,
        error,
    };
}

export function deleteRoomMessages(roomId: string) {
    return {
        type: actionTypes.DELETE_ROOM_MESSAGES,
        roomId,
    };
}
