import { IsNotEmpty, MinLength, ValidateIf } from 'class-validator';
import { ProjectEntity } from './ProjectEntity';
import { Picture } from './Picture';
import { Transform, Exclude, Expose } from 'class-transformer';
import { ColonArrayToObjectPropertyTransformer } from '../transformers/property/ColonArrayToObjectPropertyTransformer';
import { SchoolEntity } from './SchoolEntity';
import { StoreEntity } from './StoreEntity';
import { TestResultEntity } from './TestResultEntity';
import { LocationEntity } from './LocationEntity';
import { SkillSetsEntity } from './SkillSetsEntity';
import { SearchEntity } from './SearchEntity';
import { isEmpty } from 'lodash';
import { HighlightPropertyTransformer } from '../transformers/property/HighlightPropertyTransformer';
import { calcFilledPercentage } from '../utils';

export class UserProfileEntity {
    @IsNotEmpty()
    id: string;
    name: string;
    lastName?: string;
    alias: null;
    accountType?: AccountTypes = 'other';
    url?: string;
    picture?: Picture;
    coverImage?: any;
    @ValidateIf((o) => !isEmpty(o.dream))
    @MinLength(40, {
        message:
            'About text is too short. Minimal length is $constraint1 characters',
    })
    dream?: string;
    departments?: any[];
    skills: string[];
    // industries?: string[];
    @Transform(new ColonArrayToObjectPropertyTransformer().transform)
    contacts: { [key: string]: string };
    @Transform(new ColonArrayToObjectPropertyTransformer().transform)
    socialMedia: { [key: string]: string };
    settings?: Settings;
    location?: LocationEntity;
    languages: string[];
    passions: string[] | null;
    compensations: string[];
    objectives: string[];
    @Transform(new ColonArrayToObjectPropertyTransformer().transform)
    fundings: { [key: string]: string };
    tradings: string[];
    wishList: string[];
    investments: string[];
    stores?: StoreEntity[];
    activeProject?: ProjectEntity;
    stage?: string;
    causes: string[];
    graduationYear?: number;
    hasFinishedOnboarding?: boolean;
    school?: SchoolEntity;
    @Expose({ toPlainOnly: true })
    get personSchoolId(): string {
        return this.school?.id;
    }
    owner?: any;
    skillSets?: SkillSetsEntity[];
    @Exclude({ toPlainOnly: true })
    passedTests?: TestResultEntity[];
    matchPercent: number;
    createdAt: Date;
    updatedAt: Date;
    searchMatchScore?: number;
    @Expose({ toPlainOnly: true })
    @Exclude({ toPlainOnly: true })
    @Transform(new HighlightPropertyTransformer().transform, {
        toClassOnly: true,
    })
    highlight?: string[];
    @Exclude()
    get isActivated(): boolean {
        return !!this.dream && !!this.picture;
    }
    @Exclude()
    get filledPercentage(): number {
        return calcFilledPercentage(this);
    }
}

export type AccountTypes = 'student' | 'teacher' | 'other';

export interface Settings {
    canInvite: boolean[];
    badges: string[];
    privacy: null;
    searches: SearchEntity[];
    appLanguage: null;
    totalCounts?: number;
}
