import React, { useState } from 'react';
import { List, Button, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddProjectDialogView from './addProjectDialog';
import { useMyUserProjectsData } from '../../store/hooks/useMyUserProjectsData';
import { useHistory } from 'react-router';
import { MePage } from '../../pages/me/MePage';
import { ProjectListItemLink } from './ProjectListItemLink';
import { CircleLoader, ColoredSwitch, InfinityLoader } from '@weco/ui';
import { useToggle } from 'react-use';
import { WE_CLICKS } from '../../../we_clicks';

export type MyProjectsViewProps = {
    classes: any;
    toggle?: () => void;
};

const ProjectList = ({ classes, toggle }) => {
    const {
        myOwnProjects,
        activeProjectId,
        changeMyActiveProject,
        addNewProject,
    } = useMyUserProjectsData();

    const { push } = useHistory();
    const goTo = (path) => {
        toggle && toggle();
        push(path);
    };
    const [isAddProjectDialogOpen, setIsAddProjectDialogOpen] = useState(false);

    const [isBusy, toggleBusyState] = useToggle(false);

    const handleActiveProjectChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            toggleBusyState(true);
            changeMyActiveProject(event.target.name).finally(toggleBusyState);
        }
    };

    return (
        <>
            <List
                className={classes.projectListRoot}
                id="container-anchor-left-sidebar-project-list"
            >
                {(myOwnProjects || []).map((item, index) => (
                    <ProjectListItemLink
                        className={classes.listItemText}
                        key={index}
                        label={item.name}
                        picture={item.picture}
                        onClick={() => goTo(`${MePage.URL}/project/${item.id}`)}
                    >
                        <ColoredSwitch
                            checked={activeProjectId === item.id}
                            onChange={handleActiveProjectChange}
                            color="primary"
                            disabled={isBusy}
                            name={item.id}
                            inputProps={{
                                'aria-label': 'primary checkbox',
                            }}
                        />
                    </ProjectListItemLink>
                ))}
                <Button
                    startIcon={<AddIcon />}
                    className={classes.addButton}
                    id={WE_CLICKS.ENTRY_NEWPROJECT_POPUP}
                    onClick={() => {
                        setIsAddProjectDialogOpen(!isAddProjectDialogOpen);
                    }}
                >
                    New Project
                </Button>
            </List>
            <AddProjectDialogView
                open={isAddProjectDialogOpen}
                actions={{
                    onClose: () => {
                        setIsAddProjectDialogOpen(false);
                    },
                    onAdd: async (projectName) => {
                        toggleBusyState(true);
                        setIsAddProjectDialogOpen(false);
                        try {
                            const { id } = await addNewProject(projectName);
                            toggleBusyState(false);
                            goTo(`${MePage.URL}/project/${id}`);
                        } finally {
                            toggleBusyState(false);
                        }
                    },
                }}
            />
            {/*{isBusy && <InfinityLoader />}  NOTE: hidden for now but maybe we will use this again later.*/}
            {isBusy && <CircleLoader />}
        </>
    );
};

const MyProjectsView: React.FC<MyProjectsViewProps> = ({
    toggle,
    classes,
}: MyProjectsViewProps) => {
    // next commented lines is for My Projects Accordion.
    // in new design there are no accordion, but It can be useful letter

    // const { myOwnProjects } = useMyUserProjectsData();
    // const [expanded, setExpanded] = useState(false);
    // const toggleExpand = () => {
    //     setExpanded(!expanded);
    // };

    return (
        <>
            {/*{myOwnProjects.length > 3 ? (*/}
            {/*    <Accordion*/}
            {/*        expanded={expanded}*/}
            {/*        className={classes.expansionPanel}*/}
            {/*    >*/}
            {/*        <AccordionSummary*/}
            {/*            style={{*/}
            {/*                padding: 0,*/}
            {/*            }}*/}
            {/*            onClick={toggleExpand}*/}
            {/*            expandIcon={*/}
            {/*                <ExpandMoreIcon className={classes.expansionSvg} />*/}
            {/*            }*/}
            {/*        >*/}
            {/*            <SpriteSvgIconButton*/}
            {/*                classes={{*/}
            {/*                    root: classes.expansionButton,*/}
            {/*                    svg: classes.buttonSvg,*/}
            {/*                }}*/}
            {/*                id="MenuIcon-Projects"*/}
            {/*            />*/}
            {/*            <Typography*/}
            {/*                className={classes.projectsText}*/}
            {/*                variant="h6"*/}
            {/*                color="secondary"*/}
            {/*            >*/}
            {/*                My Projects*/}
            {/*            </Typography>*/}
            {/*        </AccordionSummary>*/}
            {/*        <AccordionDetails className={classes.expansionContent}>*/}
            {/*            <ProjectList classes={classes} toggle={toggle} />*/}
            {/*        </AccordionDetails>*/}
            {/*    </Accordion>*/}
            {/*) : (*/}
            <Box className={classes.expansionPanel}>
                <ProjectList classes={classes} toggle={toggle} />
            </Box>
            {/*)}*/}
        </>
    );
};
export default MyProjectsView;
