/**
 * Enum of all available paths in applicaiton.
 *
 * NOTE: we place path strings in separate file to
 * avoid "circular dependency" warnings. Before that we placed urls
 * into page file. Like so: HomePage.URL = "/home".
 * Explanation: Lets say we have ProfilePage with ProfileAvatar
 * component inside. If ProfilePage imports ProfileAvatar and
 * ProfileAvatar imports ProfilePage to get its URL,
 * then we create a circular dependency.
 */
export const RouterPaths = {
    redirect: '/redirect',
    me: '/me',
    main: '/main',
    notificaitons: '/notifications',
    /**
     * Page where a person will be able to see
     * updates on his applications/people applied
     * to hist project.
     */
    connections: {
        root: '/connections',
        applied: '/connections/applied',
        invites: '/connections/invites',
    },
    onboarding: '/onboarding',
    quiz: '/quiz',
    quizFinish: '/quiz/finish',
    /**
     * Social providers sign up callback.
     */
    authCallBack: '/auth-callback',
    /**
     * Email confirmation callback.
     */
    confirmCallBack: '/auth/confirm-account',
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    about: '/about',
    philosophy: '/philosophy',
    projectPage: '/profile/project',
    profilePage: '/profile/person',
    search: {
        root: '/search',
        people: '/search/people',
        // schools: '/search/schools', NOTE: hidden until Dwight school collaboration will be established.
        projects: '/search/projects',
    },
    advancedSearch: {
        root: '/search/advanced',
        projects: '/search/advanced/projects',
        people: '/search/advanced/people',
    },
    myProject: '/me/project',
    myProfile: '/me/profile',
    myProfileSkillsProjects: '/me/profile/skills/projects',
    myProfileSkills: '/me/profile/skills/',
    chat: {
        root: '/chat',
        user: '/chat/people',
        project: '/chat/projects',
    },
    help: '/help',
    notFound: '/not-found',
    dashboard: '/dashboard',
    favorites: '/favorites',
    settings: '/settings',
};
