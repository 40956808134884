import { useEffect, useState, useRef } from 'react';

export type AsyncHookResult<T = any> = {
    loading: boolean;
    error?: Error;
    value?: T;
};

export function useAsync<T>(
    promiseOrFnOrValue: (() => Promise<T>) | Promise<T> | T,
): AsyncHookResult<T> {
    const [state, setState] = useState<{
        loading: boolean;
        error: Error | null;
        value: T | undefined;
    }>({
        loading: !!promiseOrFnOrValue,
        error: null,
        value: undefined,
    });
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        if (!promiseOrFnOrValue) {
            setState({
                loading: false,
                error: null,
                value: undefined,
            });
        } else {
            if (state.loading === false) {
                setState({
                    loading: true,
                    error: null,
                    value: undefined,
                });
            }
            let promise;
            if (typeof promiseOrFnOrValue === 'function') {
                promise = (promiseOrFnOrValue as Function)();
            } else {
                promise = promiseOrFnOrValue;
            }
            if (!promise.then) {
                setState({
                    loading: false,
                    error: null,
                    value: promise,
                });
            } else {
                promise
                    .then((value) => {
                        if (isMounted.current) {
                            setState({
                                loading: false,
                                error: null,
                                value,
                            });
                        }
                    })
                    .catch((error) => {
                        if (isMounted.current) {
                            setState({
                                loading: false,
                                error,
                                value: undefined,
                            });
                        }
                    });
            }
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    return state;
}
