import { Box, Divider, Theme, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/styles';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import React, { memo, ReactElement } from 'react';
import { useHistory } from 'react-router';
import { ActionButton } from './ActionButton';
import { PositionsFilterMenuOption } from './PositionsFilterMenu';
import { Tooltip } from './Tooltip';
import { WeCompatibility } from './WeCompatibility';
import { UserProfileEntity } from '@weco/core';

export interface PositionsHeaderProps {
    title: string;
    className?: string;
    description: string;
    dividerColor: string;
    matchPercent?: number;
    isOnlyTitleVisible?: boolean;
    isFilteringMyOwnPositions: boolean;
    onFilterMenuChange: (value: PositionsFilterMenuOption) => void;
    profile: UserProfileEntity;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        display: 'inline',
        padding: '0 3px 15px',
        verticalAlign: 'middle',
        borderBottom: (props: PositionsHeaderProps) => {
            return `solid 2px ${props.dividerColor}`;
        },
    },
    tooltip: {
        marginTop: '-8px',
    },
}));

const PositionsHeader = memo(
    ({ isOnlyTitleVisible, matchPercent, ...props }: PositionsHeaderProps) => {
        const classes = useStyles(props);
        const rootClasses = classNames(classes.root, props.className);

        return (
            <Box
                className={rootClasses}
                position="relative"
                id="container-anchor-position-header"
            >
                <Typography variant="h2" className={classes.title}>
                    {props.title}
                </Typography>
                <Box
                    className={classes.tooltip}
                    id="container-anchor-tooltip-icon"
                >
                    <Tooltip
                        text={
                            <DescriptionAndMatchPercent
                                matchPercent={matchPercent}
                                description={props.description}
                                profile={props.profile}
                            />
                        }
                    >
                        {isOnlyTitleVisible ? (
                            <div />
                        ) : (
                            <IconButton>
                                <InfoOutlinedIcon color="secondary" />
                            </IconButton>
                        )}
                    </Tooltip>
                </Box>
            </Box>
        );
    },
);

function DescriptionAndMatchPercent(props: {
    description: string;
    matchPercent: number | undefined;
    profile: UserProfileEntity;
}): ReactElement {
    const history = useHistory();
    return (
        <>
            {props.description || ''}
            <Box my="20px">
                <Divider />
            </Box>
            {isNumber(props.matchPercent) &&
            props.profile?.hasFinishedOnboarding ? (
                <>
                    WeCompatibility:{' '}
                    <WeCompatibility matchPercent={props.matchPercent} />
                </>
            ) : (
                <ActionButton
                    size="small"
                    label="WeCompatibility Test"
                    onClick={() => {
                        history.push(RouterPaths.quiz);
                    }}
                />
            )}
        </>
    );
}

PositionsHeader.displayName = 'PositionsHeader';

export { PositionsHeader };
