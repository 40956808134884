import { makeStyles, Theme } from '@material-ui/core/styles';
import proggressBar from '../../assets/images/Rectanglee.png';
import { DepartmentColors } from '../../../../../../apps/client/src/app/theme/DepartmentColors';

export const useFinishStyles = makeStyles((theme: Theme) => ({
    accordion: {
        color: theme.palette.primary.contrastText,
        margin: '10px 0',
        borderRadius: '10px',
        minHeight: '40px!important',
    },
    accordionContent: {
        color: theme.palette.primary.contrastText,
        margin: '0!important',
    },
    details: {
        padding: 5,
    },
    detailsText: {
        fontSize: '14px',
    },
    accordionHeading: {
        textTransform: 'uppercase',
        color: theme.palette.primary.contrastText,
    },
    accordionPaper: {
        boxShadow: 'none',
    },
    expandIcon: {
        color: theme.palette.primary.contrastText,
        padding: '4px',
    },
    expand: {
        fontSize: '40px',
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3, 0, 3),
    },
    actionBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    bottomActionBox: {
        position: 'fixed',
        display: 'flex',
        bottom: 0,
        left: 0,
        width: '100%',
        justifyContent: 'center',
        zIndex: 10,
    },
    bottomAction: {
        margin: '20px 10px',
    },
    ...DepartmentColors({
        variant: 'single',
    }),
    positionsContainer: {
        padding: '10px 0 0 0',
        [theme.breakpoints.up('md')]: {
            marginLeft: '20%',
            marginRight: '20%',
        },
    },
}));

export const useItemStyles = makeStyles((theme: Theme) => ({
    questionText: {
        padding: '20px 0',
        fontSize: '28px',
        fontWeight: 400,
    },
    questionSmallText: {
        fontSize: '15px',
        fontWeight: 400,
    },
    radioGroup: {
        background: `url(${proggressBar})  repeat-x scroll center center`,
    },
    radioBox: {
        width: '19px',
    },
    radio: {
        padding: '10px 0',
        background: '#ffffff',
        width: '100%',
    },
    icon: {
        borderRadius: '50%',
        width: 19,
        height: 19,
        // boxShadow: 'inset 0 0 0 2px rgba(16,22,26,.2), inset 0 -2px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        border: '3px solid #bdbdbd',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.primary.main,
        border: '3px solid ' + theme.palette.secondary.main,
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        'input:hover ~ &': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export const useSectionStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'fixed',
        backgroundColor: '#fff',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: '100vh',
        zIndex: 10,
        padding: 0,
    },
    content: {
        minHeight: '100vh',
        // NOTE: mobile Safari browser has footer
        // that blocks buttons.
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '130px',
        },
    },
    closeButton: {
        marginLeft: 'auto',
    },
    closeButtonIcon: {
        fontSize: '33px',
        color: theme.palette.text.secondary,
    },
    contentBox: {
        padding: '10px 50px 10px 30px',
    },
    buttonBox: {
        padding: '20px',
    },
    nextButton: {
        marginLeft: 'auto',
    },
    backButton: {
        marginRight: 'auto',
    },
}));
