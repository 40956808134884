import { Box, Grid, TextField, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { FieldInputProps, FormikProps } from 'formik';
import React, { useState } from 'react';
import { renderChildren } from '../helpers/renderChildren';
import { SpriteSvgIcon, WeChipsList } from '../molecules';
import { WeChipItem } from '../molecules/WeChip';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

export interface WeChipsInputProps {
    onChange?: (data: string[]) => void;
    onBlur?: (data: any) => void;
    name?: string;
    value?: any[];
    lowerCase?: boolean;
    field?: FieldInputProps<any>;
    form?: FormikProps<any>;
    input?: React.ReactElement<WeChipsInputControlProps>;
    suggestions?: string[];
    autoFocus?: boolean;
    freeSolo?: boolean;
    isOpenField?: boolean;
    disableCloseOnSelect?: boolean;
}

export interface WeChipsInputControlProps {
    addItem: (data: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    chipIcons: {
        marginRight: '5px',
        transform: 'scale(0.7)',
    },
    editableChipIcons: {
        marginLeft: '9px',
        zIndex: 1,
    },
    chipBoxes: {
        color: 'red',
    },
}));

export const WeChipsInput = ({
    onChange,
    onBlur,
    name,
    value,
    lowerCase,
    form,
    field,
    input,
    suggestions,
    isOpenField,
                                 disableCloseOnSelect,
    ...props
}: WeChipsInputProps) => {
    const classes = useStyles();
    const [autocompleteValue, setAutocompleteValue] = useState<any>('');
    const [error, setError] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<any>('');

    const curValue = []
        .concat(value || field?.value)
        .filter((v) => v !== undefined);

    const chips = (curValue || []).filter(Boolean).reduce((acc, item) => {
        const { label, value, chipProps, ...rest } = item || {};
        acc.push({
            label: label || item,
            value: value || item,
            chipProps: {
                ...chipProps,
                onDelete: () => removeItem(item),
            },
            ...rest,
        } as WeChipItem);
        return acc;
    }, []);

    const options =
        suggestions?.filter(
            (i) => !curValue.includes(lowerCase ? i.toLowerCase() : i),
        ) || [];

    const getCallOnChange = () => {
        const curName = name || field?.name;
        return !onChange && curName
            ? (data) => form.setFieldValue(curName, data)
            : onChange;
    };

    const addItem = (v) => {
        if (!onChange && !form && !field) {
            return;
        }
        getCallOnChange()(
            [].concat(curValue, v?.value || v).filter((v) => !!v),
        );
    };

    const removeItem = (v) => {
        const curIndex = curValue.indexOf(v);
        curValue.splice(curIndex, 1);
        getCallOnChange()(curValue);
    };

    const validate = (e) => {
        const pattern = new RegExp(/^[a-zA-Z 0-9]*$/);
        const res =
            !pattern.test(e.target.value) || e.target.value.length >= 150;
        setError(res);
        return res;
    };

    return (
        <Box>
            <WeChipsList items={chips} chipsGroupName={name} />
            {input && renderChildren(input)}
            <Grid container>
                <Grid item xs={11}>
                    {!input && (
                        <Autocomplete
                            style={{
                                width: '100%',
                                display: 'inline-block',
                            }}
                            disableCloseOnSelect={typeof disableCloseOnSelect === 'undefined' ? true : disableCloseOnSelect}
                            options={options}
                            freeSolo={props.freeSolo}
                            value={autocompleteValue}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            clearOnEscape
                            getOptionLabel={(i) => i}
                            onChange={(event: any, newValue: string | null) => {
                                addItem(
                                    lowerCase
                                        ? newValue?.toLowerCase()
                                        : newValue,
                                );
                                setInputValue('');
                                setAutocompleteValue('');
                            }}
                            renderOption={(option) => {
                                return (
                                    <>
                                        {name === 'compensations' && (
                                            <SpriteSvgIcon
                                                id={`${option}`}
                                                className={classes.chipIcons}
                                            />
                                        )}
                                        {option}
                                    </>
                                );
                            }}
                            renderInput={(
                                params: AutocompleteRenderInputParams,
                            ) => {
                                return (
                                    <TextField
                                        label={
                                            error
                                                ? 'This field should not contain special symbols or exceed 150 characters'
                                                : ''
                                        }
                                        error={error}
                                        {...params}
                                        fullWidth
                                        autoFocus={props.autoFocus}
                                        onChange={validate}
                                        onKeyDown={(e) => {
                                            if (
                                                e.keyCode === 13 &&
                                                inputValue &&
                                                props.freeSolo
                                            ) {
                                                addItem(
                                                    lowerCase
                                                        ? inputValue.toLowerCase()
                                                        : inputValue,
                                                );
                                                setInputValue('');
                                                setAutocompleteValue('');
                                            }
                                        }}
                                    />
                                );
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={1}>
                    {!input && isOpenField && (
                        <Box mx="auto">
                            <IconButton
                                disabled={error}
                                color="primary"
                                aria-label="add"
                                style={{ display: 'inline-block' }}
                                onClick={() => {
                                    addItem(
                                        lowerCase
                                            ? inputValue.toLowerCase()
                                            : inputValue,
                                    );
                                    setInputValue('');
                                    setAutocompleteValue('');
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
