import React from 'react';
import {
    goTo,
    Hidden,
    SectionWithTitle,
    WeChipsList,
    WeFundingList,
} from '@weco/ui';
import { useProjectProfileData } from './store/useProjectProfileData';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { Grid, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { objectToTypeValue, toChipItem } from '../../../helpers/utils';
import Box from '@material-ui/core/Box';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
export const ResourcesTabPage = () => {
    const { project } = useProjectProfileData();
    const { profile } = useMyUserProfileData();
    const classes = useTabStyles();
    const showEmptyPlaceHolder =
        isEmpty(project.compensations) &&
        isEmpty(project.investments) &&
        isEmpty(project.fundings);

    return (
        <Box className={classes.tabContent}>
            <Grid container>
                <Hidden when={!showEmptyPlaceHolder}>
                    <Box
                        my={4}
                        display="flex"
                        justifyContent="center"
                        width="100%"
                    >
                        <Typography variant="h5" align="center">
                            This project is not connected to resources
                        </Typography>
                    </Box>
                </Hidden>
                <Hidden when={isEmpty(project.compensations)}>
                    <Grid item xs={12} className={classes.section}>
                        <SectionWithTitle title="Compensation">
                            <WeChipsList
                                chipsGroupName="compensations"
                                items={toChipItem(
                                    project.compensations,
                                    profile?.investments,
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.investments)}>
                    <Grid item xs={12} className={classes.section}>
                        <SectionWithTitle title="Investment">
                            <WeChipsList
                                chipsGroupName="investments"
                                items={toChipItem(
                                    project.investments,
                                    profile?.compensations,
                                )}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
                <Hidden when={isEmpty(project.fundings)}>
                    <Grid item xs={12} className={classes.section}>
                        <SectionWithTitle title="Funding">
                            <WeFundingList
                                onClick={(uri) => goTo(uri)}
                                items={objectToTypeValue(project.fundings)}
                            />
                        </SectionWithTitle>
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    );
};
