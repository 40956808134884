import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { MyUserStore } from '../MyUserStore';
import {
    PersonConstants,
    UserProfileEntity,
    PersonProjectConnection,
} from '@weco/core';

export interface MyUserProfileData {
    name: string;
    lastName;
    currentUserId: string;
    changeProfileName?: any;
    profile: UserProfileEntity;
    constants: PersonConstants;
    areConnectionsLoading: boolean;
    getProfilePicture(picture?): string;
    userConnections: PersonProjectConnection[];
    deleteAccount: (id: string) => Promise<void>;
    loadProfile: () => Promise<UserProfileEntity>;
    updateImg: (profile: UserProfileEntity) => Promise<void>;
    updateProfile: (profile: UserProfileEntity) => Promise<void>;
}

export const useMyUserProfileData = (): MyUserProfileData => {
    const store = useStore<MyUserStore>(MyUserStore);

    return useObserver(
        () =>
            ({
                profile: store.profile,
                loadProfile: store.loadProfile,
                name: store.profile?.name,
                lastName: store.profile?.lastName,
                updateProfile: store.updateProfile,
                deleteAccount: store.deleteAccount,
                updateImg: store.updateImg,
                changeProfileName: store.changeProfileName,
                constants: store.constants,
                getProfilePicture: store.getProfilePicture,
                currentUserId: store.currentUserId,
                userConnections: store.happyConnectionsStore?.connections || [],
                areConnectionsLoading:
                    store.happyConnectionsStore?.areConnectionLoading,
            } as MyUserProfileData),
    );
};
