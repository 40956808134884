// TODO: this whole file is useless.
// TODO use UserProfile.settings.searches type instead of this types.

export class SearchFilter {
    departments: any;
    skills: any;
    causes: any;
    // industries: any;
    query: any;
    exclude: any;
    include: [];
    excludeNotFilledItem: boolean = true;
    shouldFilterEmptyPicture?: boolean;
}

export class AdvancedSearchFilter {
    /**User has ability to save searches and name them. */
    name?: string;
    causes?: string[];
    /** we do not search by roles, only have it here to update UI when we upload info */
    roles?: string[];
    skills?: string[];
    passions?: string[];
    languages?: string[];
    locations?: string[];
    compensations?: string[];
    objectives?: any;
    location?: {
        lat: number;
        lon: number;
    };
}
