import {
    Box,
    Button,
    Grid,
    IconButton,
    makeStyles,
    useMediaQuery,
    useTheme,
    Hidden,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TuneIcon from '@material-ui/icons/Tune';
import { RecentSearchLocalStorageKeys } from '@weco/common';
import {
    AvoidBottomBarInMobileBrowsers,
    SectionWithTitle,
    WeRecentSearches,
    NavTabs,
    useScrollTopHelper,
} from '@weco/ui';
import classNames from 'classnames';
import debug from 'debug';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import { RouterPaths } from '../../router/RouterPaths';
import { SearchFormControl } from './formControls/searchFormControl';
import SearchPeopleTab from './people/SearchPeopleTab';
import SearchProjectsTab from './projects/SearchProjectsTab';
import { useSearchData } from './useSearchData';
import ScrollTop from '../../helpers/ScrollTop';

const log = debug('SearchPage');

export const useStyles = makeStyles((theme) => ({
    tabContent: {
        padding: '0 15px',
    },
    tabContentMobile: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: '100vh',
        zIndex: 10,
        backgroundColor: theme.palette.common.white,
        marginTop: 50,
        padding: '10px 0',
    },
    appBarMobile: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 11,
        backgroundColor: theme.palette.primary.main,
        padding: '6px 15px 2px 15px',
        color: theme.palette.text.secondary,
        fontSize: '20px',
        '& .Mui-selected': {
            color: theme.palette.text.primary,
            fontSize: '20px',
            textTransform: 'capitalize',
        },
        '& .MuiButtonBase-root': {
            textTransform: 'capitalize',
        },
        '& .MuiTabs-root': {
            backgroundColor: '#f3f3f3',
            color: '#8E8E8E',
        },
        '& .MuiTab-wrapper': {
            fontWeight: 700,
        },
    },
    expansionSvg: {
        fontSize: 38,
        color: theme.palette.common.white,
    },
    advancedSearchTitle: {
        fontSize: '15px',
        fontWeight: 600,
        marginBottom: '10px',
        marginTop: '10px',
    },
    advancedFilterRow: {
        display: 'flex',
    },
    advancedFilterKey: {
        fontSize: '13px',
        fontWeight: 600,
        marginRight: '10px',
        marginTop: '10px',
    },
    advancedFilterParamOption: {
        padding: '5px',
        borderRadius: '10px',
        border: `1px solid ${theme.palette.text.secondary}`,
        margin: '5px',
    },
}));
// TODO: extract "recent searches" related code into separate component.

export const SearchPage = () => {
    const baseUrl = RouterPaths.search.root;
    const theme = useTheme();
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const isDesktopMode = useMediaQuery(theme.breakpoints.up('md'));
    const {
        query,
        searchPeople,
        resetPeopleLimit,
        searchProjects,
        resetProjectLimit,
        setLoading,
        advancedFilter,
    } = useSearchData();
    const [isProjectTab, setIsProjectsTab] = useState(
        !location.pathname.includes('people'),
    );
    const runSelectedSearchType = isProjectTab ? searchProjects : searchPeople;
    const resetLimit = isProjectTab ? resetProjectLimit : resetPeopleLimit;
    const advancedSearchLink = isProjectTab
        ? RouterPaths.advancedSearch.projects
        : RouterPaths.advancedSearch.people;

    const [storageKey, setStorageKey] = useState(
        isProjectTab
            ? RecentSearchLocalStorageKeys.project
            : RecentSearchLocalStorageKeys.person,
    );
    const [recentSearches, setRecentSearches] = useState<string[]>(
        JSON.parse(localStorage.getItem(storageKey) || '[]'),
    );

    const [advancedSearchSelected, setAdvancedSearchSelected] = useState([]);
    const [showAdvanced, setShowAdvanced] = useState(false);

    useEffect(() => {
        const filteredAdvanced = Object.entries(advancedFilter).filter(
            ([key]) =>
                key !== 'skills' &&
                key !== 'name' &&
                key !== 'type' &&
                key !== '__typename' &&
                key !== 'stages',
        );
        setAdvancedSearchSelected(filteredAdvanced);
        setShowAdvanced(
            filteredAdvanced.find(
                ([key, value]) => value !== undefined && value?.length !== 0,
            ) !== undefined,
        );
    }, [advancedFilter]);

    useEffect(() => {
        setStorageKey(
            isProjectTab
                ? RecentSearchLocalStorageKeys.project
                : RecentSearchLocalStorageKeys.person,
        );
    }, [isProjectTab]);

    useEffect(() => {
        setIsProjectsTab(!location.pathname.includes('people'));
    }, [location.pathname]);

    useEffect(() => {
        setRecentSearches(JSON.parse(localStorage.getItem(storageKey) || '[]'));
    }, [storageKey]);

    useScrollTopHelper();

    function addToRecentSearches(query) {
        if (recentSearches.includes(query)) {
            recentSearches.splice(recentSearches.indexOf(query), 1);
        }

        if (recentSearches.length >= 5) {
            recentSearches.pop();
        }

        recentSearches.unshift(query);
        setRecentSearches([...recentSearches]);

        localStorage.setItem(storageKey, JSON.stringify(recentSearches));
    }

    function clearLastSearches() {
        setRecentSearches([]);
        localStorage.setItem(storageKey, JSON.stringify([]));
        searchByQuery(
            { query: '' },
            { setSubmitting: () => {}, resetForm: () => null },
        );
    }

    function searchByQuery({ query }, { setSubmitting, resetForm }) {
        log('simple search submitted: ', query);

        resetLimit();
        setLoading(true);
        return runSelectedSearchType(query).then(() => {
            if (query.trim()) {
                addToRecentSearches(query);
            }
            setSubmitting(true);
            // resetForm({ values: { query: '' } });
            setLoading(false);
        });
    }

    log('Recent searches: %O', recentSearches);

    return (
        <Grid
            item
            xs={12}
            classes={{
                root: classNames({
                    [classes.tabContent]: true,
                    [classes.tabContentMobile]: !isDesktopMode,
                }),
            }}
        >
            <ScrollTop showBelow={100} />
            <Formik
                enableReinitialize
                onSubmit={searchByQuery}
                initialValues={{ query }}
            >
                {({ handleSubmit, setFieldValue, errors }) => (
                    <>
                        <Grid
                            item
                            className={classNames(
                                !isDesktopMode && classes.appBarMobile,
                            )}
                            id="container-anchor-search-input"
                        >
                            <Grid container>
                                <Hidden mdUp>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={history.goBack}
                                            style={{ padding: '10px 0' }}
                                            edge="start"
                                        >
                                            <ChevronLeftIcon
                                                className={classes.expansionSvg}
                                            />
                                        </IconButton>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={isDesktopMode ? 12 : 11}>
                                    <SearchFormControl
                                        name="query"
                                        handleSubmit={handleSubmit}
                                        error={Boolean(errors.query)}
                                        advanced={
                                            <Link to={advancedSearchLink}>
                                                <TuneIcon />
                                            </Link>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {recentSearches.length > 0 && (
                            <Grid item>
                                <Box
                                    mt={isDesktopMode ? 2 : 4}
                                    mx={isDesktopMode ? 0 : 2}
                                >
                                    <SectionWithTitle
                                        title="Recent Searches"
                                        rightAction={
                                            <Button onClick={clearLastSearches}>
                                                Clear
                                            </Button>
                                        }
                                    >
                                        <WeRecentSearches
                                            lastSearches={recentSearches}
                                            setSearches={setRecentSearches}
                                            storageKey={storageKey}
                                            handleSearch={(query) => {
                                                setFieldValue('query', query);
                                                handleSubmit();
                                            }}
                                        />
                                    </SectionWithTitle>
                                </Box>
                            </Grid>
                        )}
                        {showAdvanced && (
                            <Box className={classes.advancedSearchTitle}>
                                Advanced Search Selection:
                            </Box>
                        )}
                        {showAdvanced &&
                            advancedSearchSelected?.map(([key, value], i) => {
                                return (
                                    value?.length !== 0 && (
                                        <Grid
                                            key={`selectedSearchOptionTag${i}`}
                                            className={
                                                classes.advancedFilterRow
                                            }
                                        >
                                            <Box
                                                className={
                                                    classes.advancedFilterKey
                                                }
                                            >
                                                {capitalize(key)}:
                                            </Box>
                                            <Grid container item>
                                                {value?.map((item, i) => (
                                                    <Box
                                                        key={`selectedSearchOption${i}`}
                                                        className={
                                                            classes.advancedFilterParamOption
                                                        }
                                                    >
                                                        {capitalize(item)}
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    )
                                );
                            })}
                    </>
                )}
            </Formik>
            <Grid item>
                <Box>
                    <NavTabs
                        searchPage
                        tabs={[
                            {
                                label: 'Projects',
                                to: `${baseUrl}`,
                                component: SearchProjectsTab,
                                componentProps: `${baseUrl}`,
                            },
                            {
                                label: 'People',
                                to: `${baseUrl}/people`,
                                component: SearchPeopleTab,
                            },
                        ]}
                    />
                </Box>
            </Grid>
            <AvoidBottomBarInMobileBrowsers />
        </Grid>
    );
};
