import React from 'react';
import { QuizRow } from './QuizRow';
import { QuizPageWrapper } from './QuizPageWrapper';
import { observer } from 'mobx-react';
import { TestAnswerEntity } from '@weco/core';

/* eslint-disable-next-line */
export interface QuizSectionComponentProps {
    questions?: any[];
    answers?: TestAnswerEntity[][];
    activePage?: number;
    section?: any;
    sectionIndex?: number;
    setAnswer?: any;
    prevPage?: any;
    nextPage?: any;
    onClose?: () => void;
}

export const QuizSectionComponent = observer(
    ({
        questions,
        answers,
        activePage,
        nextPage,
        prevPage,
        setAnswer,
        sectionIndex,
        section,
        onClose,
    }: QuizSectionComponentProps) => {
        return (
            <QuizPageWrapper
                questions={questions}
                answers={answers}
                activePage={activePage}
                nextPage={nextPage}
                prevPage={prevPage}
                onClose={onClose}
            >
                {section.lines.map((item, i) => (
                    <QuizRow
                        key={item.id}
                        item={item}
                        setAnswer={(answer) => {
                            setAnswer(i, answer, sectionIndex);
                        }}
                        answer={
                            answers[sectionIndex][i]
                                ? answers[sectionIndex][i]
                                : null
                        }
                    />
                ))}
            </QuizPageWrapper>
        );
    },
);
