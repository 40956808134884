import React from 'react';
import { Theme } from '@material-ui/core';
import { NavTabs, NavTabsProps } from './NavTabs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: 'transparent!important',
    },
    indicator: {},
    tab: {
        '&.Mui-selected .tab-label': {
            fontSize: '16px',
            borderRadius: '45px',
            padding: '5px 10px 5px 10px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

export const SecondaryNavTabs = (props: NavTabsProps) => {
    const classes = useStyles();
    return (
        <NavTabs
            {...props}
            id="helphero-anchor-tab-secondary-nav"
            classes={classes}
        />
    );
};
