import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, memo } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        borderRadius: '7px',
        backgroundColor: theme.palette.secondary.main,
    },
    fillIndicator: {
        borderRadius: '7px',
        transition: 'width 1s',
        backgroundColor: theme.palette.primary.main,
    },
    text: {
        color: 'white',
        margin: '0 15px',
        textAlign: 'right',
    },
}));

const ProgressBar: FunctionComponent<{ percentage: number }> = ({
    percentage,
}) => {
    // Get rid of fraction points.
    percentage = Math.trunc(percentage);

    const cx = useStyles();

    let indicatorWidth = percentage;
    if (percentage >= 100) {
        indicatorWidth = 100;
    }
    // Portion of indicator must be visible even if percentage is small.
    else if (percentage <= 8) {
        indicatorWidth = 8;
    }

    return (
        <Box className={cx.container}>
            <Box
                paddingTop="2px"
                width={indicatorWidth + '%'}
                className={cx.fillIndicator}
            >
                <Typography className={cx.text}>
                    {(percentage >= 100 ? 100 : percentage) + '%'}
                </Typography>
            </Box>
        </Box>
    );
};

export const OnboardingProgressBar = memo(ProgressBar);
