import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { MyUserStore } from '../MyUserStore';
import {
    MyUserProjectsData,
    useMyUserProjectsData,
} from './useMyUserProjectsData';
import {
    MyUserProfileData,
    useMyUserProfileData,
} from './useMyUserProfileData';

export interface MyUserData extends MyUserProjectsData, MyUserProfileData {
    load: () => Promise<any>;
}

export const useMyUserData = () => {
    const store = useStore<MyUserStore>(MyUserStore);
    const profileData = useMyUserProfileData();
    const projectsData = useMyUserProjectsData();

    return useObserver(
        () =>
            ({
                load: store.load,
                ...profileData,
                ...projectsData,
            } as MyUserData),
    );
};
