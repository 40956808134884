import ApolloClient from 'apollo-boost';
import { gql } from 'apollo-boost';
import {
    createPassedTestResult,
    deletePassedTestResult,
    updatePassedTestResult,
} from './gqMutations/quiz';
import { TestResultEntity } from '@weco/core';
import _ from 'lodash';
import { addUpdatedAt, Transformers } from '@weco/common';

export class QuizService {
    constructor(private client: ApolloClient<any>) {}

    /**
     * @return Promise<string> with roomId
     */
    createPassedTest(input: TestResultEntity): Promise<string | void> {
        return this.client
            .mutate({
                mutation: gql(createPassedTestResult),
                variables: { input },
            })
            .then(({ data }: any) => data.createPassedTestResult);
    }

    /**
     * @return Promise<string> with roomId
     */
    updatePassedTest(input: TestResultEntity): Promise<string | void> {
        return this.client
            .mutate({
                mutation: gql(updatePassedTestResult),
                variables: {
                    input: {
                        ..._.omit(
                            new Transformers.ToPlainTransformer().transform({
                                ...input,
                                answers: input.answers.map((answerRow) =>
                                    answerRow.map((answer) =>
                                        _.omit(answer, ['__typename']),
                                    ),
                                ),
                                results: input.results.map((result) =>
                                    _.omit(result, ['__typename']),
                                ),
                            }),
                            [
                                'type',
                                'ownerId',
                                'owner',
                                'createdAt',
                                'updatedAt',
                                '__typename',
                            ],
                        ),
                    },
                },
            })
            .then(({ data }: any) => data.updatePassedTestResult);
    }

    /**
     * @return Promise<string> with roomId
     */
    deleteTest(input: { id: string }): Promise<string | void> {
        return this.client
            .mutate({
                mutation: gql(deletePassedTestResult),
                variables: { input },
            })
            .then(({ data }: any) => data.deletePassedTestResult);
    }
}
