import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
    AvatarListHorizontalLoadingAnimation,
    ProjectsListCompressed,
} from '@weco/ui';
import { useTabContentStyles } from 'apps/client/src/app/components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useMyUserData } from '../../../store/hooks/useMyUserData';
import { openChat } from '../../../helpers/utils';
import { useChatData } from '../../../store/hooks/useChatData';
import { useSearchProjectsData } from './useSearchProjectsData';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { ROOM_TYPE_PROJECTS } from '@weco/chat';
import { RouterPaths } from '../../../router/RouterPaths';

const SearchProjectsContent = () => {
    const history = useHistory();
    const classes = useTabContentStyles();
    const {
        step,
        items,
        search,
        loading,
        projectLimit,
        projectTotal,
        myUserStore,
    } = useSearchProjectsData();
    const { activeProject } = useMyUserData();
    const { profile } = useMyUserProfileData();
    const [isLoadingMore, setLoadingMore] = useState<boolean>(false);
    const { createRoom } = useChatData();

    async function loadMore() {
        setLoadingMore(true);
        try {
            await search(null, projectLimit + step);
            document
                .getElementById(items[items.length - 1].id)
                .scrollIntoView();
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingMore(false);
        }
    }

    const isMember = (projectId: string): boolean => {
        let connectionFound = false;
        myUserStore.happyConnectionsStore.connections.forEach((connection) => {
            if (
                connection.personId === profile.id &&
                connection.projectId === projectId
            ) {
                connectionFound = true;
            }
        });
        return connectionFound;
    };

    return (
        <Box mt={4}>
            {loading && (
                <Box mt={4} width="100%">
                    <AvatarListHorizontalLoadingAnimation count={10} />
                </Box>
            )}
            {!loading && (
                <ProjectsListCompressed
                    openChat={({ projectId, projectOwnerId }) => {
                        if (
                            projectOwnerId === profile?.id ||
                            isMember(projectId)
                        ) {
                            return history.push(
                                `${RouterPaths.chat.root}/${ROOM_TYPE_PROJECTS}/${projectId}`,
                            );
                        }
                        return openChat({
                            createRoom,
                            history,
                            id: projectOwnerId,
                            currentUserId: profile.id,
                            profile: {
                                id: projectOwnerId,
                                name: 'undefined',
                                lastName: 'undefined',
                                picture: null,
                            },
                        });
                    }}
                    activeProject={activeProject}
                    projects={items}
                    currentUserId={profile?.id}
                />
            )}
            <Box className={classes.loadMore}>
                {isLoadingMore && <CircularProgress size={20} />}
                {!isLoadingMore && projectTotal !== items?.length && (
                    <Button color="secondary" variant="text" onClick={loadMore}>
                        See More
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default SearchProjectsContent;
