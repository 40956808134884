import { CognitoHostedUIIdentityProvider as SocialMediaType } from '@aws-amplify/auth';
import {
    Box,
    Grid,
    Button,
    makeStyles,
    Typography,
    SvgIcon,
    CardMedia,
    IconButton,
} from '@material-ui/core';
import { Auth } from '@weco/common';
import { EmailWasSentDialog, SignUpForm, useNotice } from '@weco/ui';
import isEmpty from 'lodash/isEmpty';
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useToggle from 'react-use/lib/useToggle';
import { RouterPaths } from '../../../router/RouterPaths';
import { useCurrentAuthData } from '../../../store/hooks/useCurrentAuthData';
import { localStorageIsRedirectionRequiredKey } from '../onboardingConstants';
import { ReactComponent as GoogleIcon } from '../../../../assets/icons/social/g-google.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/social/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../../assets/icons/social/linkedin.svg';
import LoginBackground from '../../../../assets/images/login-background.png';
import FullLogo from '../../../../assets/images/full-logo-beta.svg';
import { WE_CLICKS } from '../../../../we_clicks';

const useStyles = makeStyles((theme) => ({
    root: {
        background: `linear-gradient(180deg, rgba(26, 24, 133, 0.8) 27.08%, rgba(19, 29, 119, 0) 100%), url(${LoginBackground}) no-repeat center top / cover`,
        width: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
        position: 'fixed',
        overflowY: 'scroll',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0 0 0',
        [theme.breakpoints.up('md')]: {
            padding: '60px 0 0 0',
        },
    },
    button: {
        width: '300px',
        height: '48px',
        borderRadius: '24px',
        marginBottom: 20,
        fontSize: '14px',
    },
    buttonText: {
        width: '100%',
        textAlign: 'initial',
        paddingLeft: '15px',
    },
    svgIcon: {
        marginRight: 10,
        width: '24px',
        height: '24px',
    },
    fieldBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    signInText: {
        color: theme.palette.common.white,
        margin: 40,
        fontWeight: 400,
    },
    logoBox: {
        padding: '20px',
    },
    logoImage: {
        width: '182px',
        height: 'auto',
    },
}));

const OnboardingSignUpStep = memo(function SignUpStep() {
    const history = useHistory();
    const [isDialogVisible, toggleDialog] = useToggle(false);
    const [isLoading, toggleLoading] = useToggle(false);
    const { authenticate, user, signup, resendSignUp } = useCurrentAuthData();
    const { error, info } = useNotice();
    const [authFormValues, setAuthFormValues] = useState(null);

    const classes = useStyles();

    // If user is already logged in, redirect him to next step.
    useEffect(() => {
        if (!isEmpty(user)) {
            history.push(`${RouterPaths.onboarding}/1`);
        }
    }, [user, history]);

    async function signUpViaSocialMedia(socialMediaType: SocialMediaType) {
        toggleLoading();
        /**
         * NOTE: after user signes up through social auth
         * providers, he is redirected to root route,
         * but we need him to be redirected back to onboarding
         * to finish the process.
         * Somehow this is hard to reconfigure in AWS/Google consoles.
         * This is a workaround to redirect user back to onboarding
         * after sign up.
         */
        localStorage.setItem(localStorageIsRedirectionRequiredKey, 'true');
        await authenticate(
            new Auth.AmplifyFederatedSignInToken(socialMediaType),
        )
            .then(() => {
                toggleLoading();
            })
            .catch((e) => {
                /**
                 * Snackbars omitted intentionally.
                 * This "authenticate" function allways throws
                 * "invalid credentials" error, but still signs user up though.
                 */
                toggleLoading();
                console.error(e);
            });
    }

    function signupViaEmail(values) {
        toggleLoading();
        localStorage.setItem(localStorageIsRedirectionRequiredKey, 'true');
        return signup(
            new Auth.SignUpUsernamePasswordToken(
                values.email,
                values.password,
                {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                },
            ),
        )
            .then(() => {
                toggleLoading();
                toggleDialog();
                setAuthFormValues(values);
            })
            .catch((e) => {
                console.error(e);
                toggleLoading();
                error(e?.message || e);
                return Promise.reject(e);
            });
    }

    function triggerResendSignUp() {
        resendSignUp(new Auth.AmplifyResendSignUpToken(authFormValues.email))
            .then(() => {
                info('Email was resent');
            })
            .catch((e) => {
                console.error(e);
                toggleLoading();
                error(e?.message || e);
                return Promise.reject(e);
            });
    }

    return (
        <>
            <Grid item className={classes.root}>
                <>
                    <Grid item container justify="center">
                        <Grid item sm={12} className={classes.container}>
                            <IconButton
                                className={classes.logoBox}
                                onClick={() =>
                                    history.push(RouterPaths.dashboard)
                                }
                            >
                                <CardMedia
                                    src={FullLogo}
                                    component="img"
                                    className={classes.logoImage}
                                />
                            </IconButton>
                            <Typography
                                variant="h2"
                                align="center"
                                className={classes.signInText}
                            >
                                Create WeCo account
                            </Typography>
                            <SignUpForm onSubmit={signupViaEmail} />
                        </Grid>
                    </Grid>
                    <Grid item container justify="center">
                        <Grid item sm={12}>
                            <Box className={classes.fieldBox}>
                                <Button
                                    id={WE_CLICKS.SIGNUP_GOOGLE}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={
                                        <SvgIcon className={classes.svgIcon}>
                                            <GoogleIcon />
                                        </SvgIcon>
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        signUpViaSocialMedia(
                                            SocialMediaType.Google,
                                        )
                                    }
                                >
                                    <Typography className={classes.buttonText}>
                                        Continue with Google
                                    </Typography>
                                </Button>
                            </Box>
                            <Box className={classes.fieldBox}>
                                <Button
                                    id={WE_CLICKS.SIGNUP_FACEBOOK}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={
                                        <SvgIcon className={classes.svgIcon}>
                                            <FacebookIcon />
                                        </SvgIcon>
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        signUpViaSocialMedia(
                                            SocialMediaType.Facebook,
                                        )
                                    }
                                >
                                    <Typography className={classes.buttonText}>
                                        Continue with Facebook
                                    </Typography>
                                </Button>
                            </Box>
                            <Box className={classes.fieldBox}>
                                <Button
                                    id={WE_CLICKS.SIGNUP_LINKEDIN}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={
                                        <SvgIcon className={classes.svgIcon}>
                                            <LinkedinIcon />
                                        </SvgIcon>
                                    }
                                    className={classes.button}
                                    onClick={() =>
                                        signUpViaSocialMedia(
                                            'LinkedIn' as SocialMediaType,
                                        )
                                    }
                                >
                                    <Typography className={classes.buttonText}>
                                        Continue with LinkedIn
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </>
                <EmailWasSentDialog
                    onClose={toggleDialog}
                    isOpen={isDialogVisible}
                    onResendButtonClicked={triggerResendSignUp}
                />
            </Grid>
        </>
    );
});

export { OnboardingSignUpStep };

export default OnboardingSignUpStep;
