import { memo } from 'react';
import React from 'react';
import classNames from 'classnames';
import { Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
    button: {
        color: 'white',
        backgroundColor: '#FFB200',
        fontSize: '22px',
        fontWeight: 400,
        boxShadow: 'none',
        marginTop: '30px',
        padding: '10px 40px',
    },
});

type OurPhilosophyButtonProps = {
    className?: string;
};

const OurPhilosophyButton = memo((props: OurPhilosophyButtonProps) => {
    const cx = useStyles();
    return (
        <Button
            variant="contained"
            className={classNames(props.className, cx.button)}
            // onClick={() => history.push(OurPhilosophyPage.URL)}
        >
            Our Philosophy
        </Button>
    );
});

export { OurPhilosophyButton };
