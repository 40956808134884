import React, { useEffect, useState } from 'react';
import { useMyHappyConnectionData } from '../../../../../../apps/client/src/app/store/hooks/useMyHappyConnectionData';
import { ChatInvite } from '@weco/ui';
import { RecallAction } from '../../../../../ui/src/lib/components/RecallAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SkillSetsEntity } from '@weco/core';

interface ProjectInvitePropsInterface {
    projectId: string;
    personId: string;
    requestOwnerId: string;
    messageId: string;
    ownerName: string;
    getSkillSetById: (roleId: string) => Promise<SkillSetsEntity | null>;
}

// requestOwnerId it's messageId of current user(who sends a request and sees this wmessageIdget)
export const ProjectInvite = ({
    projectId,
    personId,
    requestOwnerId,
    messageId,
    ownerName,
    getSkillSetById,
}: ProjectInvitePropsInterface) => {
    const {
        connection,
        requestConnection,
        chatConnectionLoadings,
    } = useMyHappyConnectionData({
        projectId,
        personId,
        requestOwnerId,
    });
    const [skillSet, setSkillSet] = useState<SkillSetsEntity | null>();
    // Dont worry about amount of requests, http client sends just one request with the same query data
    useEffect(() => {
        requestConnection({
            projectId,
            personId,
            requestOwnerId,
            id: messageId,
        });
    }, [projectId, personId, requestOwnerId]);
    useEffect(() => {
        if (
            connection &&
            connection.active !== false &&
            connection.data &&
            connection.data.skillSets &&
            connection.data.skillSets.length
        ) {
            getSkillSetById(connection.data.skillSets[0].roleId)
                .then((s) => {
                    setSkillSet(s);
                })
                .catch(console.error);
        }
    }, [!!connection]);

    const showLoading = !!(
        !connection ||
        chatConnectionLoadings.includes(messageId) ||
        (connection &&
            connection.data &&
            connection.data.skillSets &&
            connection.data.skillSets.length &&
            typeof skillSet === 'undefined')
    );

    return (
        <>
            {showLoading && <CircularProgress color="secondary" />}
            {!showLoading &&
                connection.active === false &&
                'This request was processed'}
            {!showLoading &&
                connection.active &&
                connection.canHandleInvite && (
                    <ChatInvite
                        avatar={null}
                        handleAccept={connection.connect}
                        handleReject={connection.decline}
                        title={connection.data.project.name}
                        connectType={'person'}
                        name={ownerName}
                        skillSet={skillSet || null}
                    />
                )}
            {!showLoading &&
                connection.active &&
                connection.canHandleApplication && (
                    <ChatInvite
                        avatar={null}
                        handleAccept={connection.connect}
                        handleReject={connection.decline}
                        title={`${connection.data.person.name} ${connection.data.person.lastName}`}
                        connectType={'project'}
                        name={connection.data.project.name}
                        skillSet={skillSet || null}
                    />
                )}
            {!showLoading &&
                connection.active &&
                connection.canRecallApplication && (
                    <RecallAction
                        userName=""
                        projectName={connection.data.project.name}
                        avatar={null}
                        connectType={'project'}
                        handleRecall={connection.recall}
                        skillSet={skillSet || null}
                    />
                )}
            {!showLoading &&
                connection.active &&
                connection.canRecallInvitation && (
                    <RecallAction
                        userName={`${connection.data.person.name} ${connection.data.person.lastName}`}
                        projectName={connection.data.project.name}
                        avatar={null}
                        connectType={'person'}
                        handleRecall={connection.recall}
                        skillSet={skillSet || null}
                    />
                )}
        </>
    );
};
