export const environment = {
    production: true,
    amplify: {
        aws_project_region: process.env.REACT_APP_REGION || 'us-west-2',
        aws_cognito_identity_pool_id:
            process.env.REACT_APP_IDENTITY_POOL_ID ||
            'us-west-2:f20734bc-08a5-49a4-bbdb-d6662bbe3229',
        aws_cognito_region: process.env.REACT_APP_REGION || 'us-west-2',
        aws_user_pools_id:
            process.env.REACT_APP_USER_POOL_ID || 'us-west-2_hCgvMDmPV',
        aws_user_pools_web_client_id:
            process.env.REACT_APP_CLIENT_ID || '5bpaguuv4lv8u8nnoeq330rkca',
        aws_user_files_s3_bucket:
            process.env.REACT_APP_FILE_BUCKET || 'weco-app-bucket-prod',
        aws_user_files_s3_bucket_region:
            process.env.REACT_APP_FILE_BUCKET_REGION || 'us-west-2',
        oauth: {
            domain: 'auth.weco.online',
            scope: [
                'phone',
                'email',
                'openid',
                'profile',
                'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: `${window.location.origin}`,
            redirectSignOut: `${window.location.origin}`,
            responseType: 'code',
        },
    },
    apollo: {
        endpoint:
            'https://va5pk5sqjzbp3ic7f4dfsyfwr4.appsync-api.us-west-2.amazonaws.com/graphql',
        ApolloUnAuthorizedApiKey: 'da2-y5rxp2w24bebrijrjroc73fuzm',
    },
    defaults: {
        queries: {
            limit: 50,
            searchLimit: 5,
            // max value for type int which support our backend (https://docs.aws.amazon.com/appsync/latest/devguide/scalars.html)
            APPSYNC_INT_MAX: 2147483647,
        },
        /**
         * Workaround, resolve amplify issue with require fields for connected field. Used instead of null.
         * For example in graphql schema you should mark ownerId as required:
         * ownerId: ID!
         * owner: Person @connection(fields: ["ownerId"])
         *  */
        emptyId: '-1',
        /**
         * score value returned from elastic for didn't match items. Used in matchProjects && matchPeople requests.
         */
        elasticNotMatchScore: 3,
    },
    firebaseConfig: {
        apiKey: 'AIzaSyAuiSHSgBXI4XPEV96qZKDItyL27K2i2zw',
        authDomain: 'weco-prod.firebaseapp.com',
        databaseURL: 'https://weco-prod.firebaseio.com',
        projectId: 'weco-prod',
        storageBucket: 'weco-prod.appspot.com',
        messagingSenderId: '73808192089',
        appId: '1:73808192089:web:532293e515bdc75bed8748',
        measurementId: 'G-R93D9KE4BX',
    },
    helphero: {
        clientId: 'biV4yIWnz2W',
    },
    mailchimp: {
        listId: 'ee07fa829b',
        apigate: 'https://qtshqcuu95.execute-api.us-west-2.amazonaws.com/prod',
    },
    analytics: {
        gtm: {
            id: 'GTM-NCVNWT7',
            auth: 'cbayhG4_-H9Zbbzn1NF-Iw',
            preview: 'env-1',
        },
        mixpanel: {
            id: '32601af3adae0b8e06a56c79ffddbbfd',
            debug: false,
        },
        smartlook: {
            appId: '6c52893f9091eaff573a70b14a578cd444ee61bb',
        },
    },
    sentry: {
        dsn:
            'https://e339624ede9c4f3b88f6ab7911facaad@o337363.ingest.sentry.io/1870489',
        environment: 'dev',
        autoSessionTracking: true,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.2,
    },
    recaptcha: {
        siteKey: '6LdZxs0ZAAAAALgLhmlJf0u6w9qwwkIAsGV5RZvY',
    },
    landingPage: 'http://www.weco.online',
    landingPageLogout: 'http://www.weco.online',
    prodLoginPage: 'http://app.weco.online/login',

    isRolesEnabled: false,
};
