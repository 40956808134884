import React from 'react';
import { Box, FormControl } from '@material-ui/core';
import { Field } from 'formik';
import { TypeValueInterface } from '@weco/core';
import { WeTextField, SectionWithTitle } from '@weco/ui';
import { validateUrl } from '@weco/common';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';

export interface FundingFormControlProps {
    name: string;
    label: string;
    options: TypeValueInterface[];
    helpIcon?: {};
}

export const FundingFormControl = ({
    options,
    label,
    name,
    helpIcon,
}: FundingFormControlProps) => {
    const classes = useTabStyles();
    return (
        <FormControl className={classes.formControlWidth}>
            <SectionWithTitle title={label} helpIcon={helpIcon} />
            {options.map((item) => (
                <Field
                    key={item.type}
                    label={item.type}
                    name={`${name}.${item.type}`}
                    component={WeTextField}
                    validate={(data) => {
                        if (!data) {
                            return;
                        }
                        if (!validateUrl(data)) {
                            return `Please specify correct url`;
                        }
                    }}
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">
                    //             <Icon fontSize="small">{item.type}</Icon>
                    //         </InputAdornment>
                    //     ),
                    // }}
                />
            ))}
            <Box m={1} />
        </FormControl>
    );
};
