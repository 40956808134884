import { ProjectEntity } from '../entity/ProjectEntity';
import { gql } from 'apollo-boost';
import { Transformers, Injectable, addUpdatedAt } from '@weco/common';
import makeSearchRequest from './makeSearchRequest';
import { GraphQlBaseRepository } from './GraphQlBaseRepository';
import { AdvancedSearchFilter, SearchFilter } from './SearchFilter';
import { SearchParams } from '../entity/SearchParams';
import { UserProfileEntity } from '../entity/UserProfileEntity';
import { getProject, matchProjects } from './graphql/queries';
import {
    projectPersonsConnection,
    updateProject,
    updatePerson,
    processProjectCreation,
    createApplication,
    personProjectConnection,
    declineInvitation,
    acceptInvitation,
    leaveProject,
    acceptApplication,
    declineApplication,
    createInvitation,
    removeProjectMember,
    recallApplication,
    recallInvitation,
    listPersonProjectConnections,
    deleteProject,
} from './gqlRequests';
import { TeamMember } from './PersonRepository';
import { config } from './config';
import { getAllowedInvestments } from '../utils';

export interface IProjectRepository {
    updateItem(item: Partial<ProjectEntity>): Promise<ProjectEntity>;

    getItem(id: string): Promise<ProjectEntity>;

    changeMyActiveProject(newProjectId: string): Promise<any>;

    loadMyOwnProjects(limit?: number): Promise<ProjectEntity[]>;

    loadPersonProjects(
        personId: string,
        limit?: number,
    ): Promise<ProjectEntity[]>;

    getMatchList({}: SearchParams): Promise<{
        projects: ProjectEntity[];
        total: number;
    }>;

    createProject(name: string): Promise<any>;
    deleteProject(id: string): Promise<any>;

    loadProjectsByPersonId(
        id,
        options?: {
            limit?: number;
            greed?: boolean;
        },
    ): Promise<ProjectEntity[]>;

    getPersonProjectConnection(personId: string, projectId: string);

    inviteToProject(personId: string, projectId: string, roleId?: string);
    applyToProject(projectId: string, roleId?: string): Promise<any>;
    declineInvitation(projectId: string, roleId?: string): Promise<any>;
    acceptInvitation(projectId: string, roleId?: string): Promise<any>;
    recallApplication(projectId: string, roleId?: string): Promise<any>;
    leaveProject(projectId: string): Promise<any>;
    acceptApplication(
        personId: string,
        projectId: string,
        roleId?: string,
    ): Promise<any>;
    declineApplication(
        personId: string,
        projectId: string,
        roleId?: string,
    ): Promise<any>;
    removeProjectMember(personId: string, projectId: string): Promise<any>;
    recallInvitation(
        personId: string,
        projectId: string,
        roleId?: string,
    ): Promise<any>;
    loadProjectTeam(projectId: string): Promise<UserProfileEntity[]>;
    loadProjectPersons(projectId: string): Promise<UserProfileEntity[]>;
}
@Injectable()
export class ProjectRepository extends GraphQlBaseRepository
    implements IProjectRepository {
    updateItem(item: ProjectEntity): Promise<ProjectEntity> {
        const mutation = gql(updateProject);
        const variables = {
            input: addUpdatedAt({
                ...new Transformers.ToPlainTransformer().transform(item, {
                    excludePrefixes: ['__'],
                    exclude: ['owner', 'school'],
                }),
            }),
        };

        return this.authorizedClient
            .mutate({ mutation, variables })
            .then(({ data }) => data.updateProject)
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer<ProjectEntity>(
                        ProjectEntity,
                    ),
                ),
            )
            .then((data) => {
                //Workaround: Update query doesn't return school
                data.school = item.school;
                return data;
            });
    }

    getItem(id: string): Promise<ProjectEntity> {
        const query = gql(getProject);
        const variables = { id: id };

        return this.unAuthorizedClient
            .query({ query: query, variables: variables })
            .then((data) => {
                return data.data.getProject;
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer<ProjectEntity>(
                        ProjectEntity,
                    ),
                ),
            )
            .then((item: ProjectEntity) => {
                // Workaround: clear bad data. Logic changed now that fields can contains only predefined values.
                item.investments = getAllowedInvestments(item.investments);
                item.compensations = getAllowedInvestments(item.compensations);
                return item;
            })
            .catch((err) => {
                console.log('err', err);
                return null;
            });
    }

    public async changeMyActiveProject(newProjectId: string): Promise<any> {
        const mutation = gql(updatePerson);
        return this.authorizedClient.mutate({
            mutation,
            variables: {
                input: addUpdatedAt({
                    id: this.currentUserProvider.UserId,
                    personActiveProjectId: newProjectId,
                }),
            },
            update: (cache) => {
                this.invalidateAllQueryFetching(
                    this.currentUserProvider.UserId,
                );
            },
        });
    }

    readonly OWNER_ROLE = 'owner';
    readonly MEMBER_ROLE = 'member';

    public async getMatchList({
        filter,
        advancedFilter,
        limit = config.defaults.queries.limit,
    }: SearchParams): Promise<{ projects: ProjectEntity[]; total: number }> {
        const defaultFilter: SearchFilter = {
            departments: [],
            skills: [],
            causes: [],
            // industries: [],
            query: '',
            include: [],
            exclude: [
                {
                    field: this.OWNER_ROLE,
                    value: this.currentUserProvider.UserId,
                },
            ],
            excludeNotFilledItem: true,
        };

        const defaultAdvancedFilter: AdvancedSearchFilter = {
            causes: [],
            skills: [],
            passions: [],
            languages: [],
            locations: [],
            objectives: [],
            location: null,
        };

        const query = gql(matchProjects);
        const req = makeSearchRequest({
            ...defaultFilter,
            ...defaultAdvancedFilter,
            ...filter,
            ...advancedFilter,
        });
        const variables = { query: JSON.stringify(req), limit: limit };

        return this.unAuthorizedClient
            .query({
                query: query,
                variables: variables,
                fetchPolicy: 'network-only',
            })
            .then(({ data }) => {
                return {
                    projects: data.matchProjects.items.map(
                        Transformers.promisePipeTransform(
                            new Transformers.ToTypeTransformer<ProjectEntity>(
                                ProjectEntity,
                            ),
                        ),
                    ),
                    total: data.matchProjects.total,
                };
            })
            .catch((error) => {
                console.log(error);
                return { projects: [], total: 0 };
            });
    }

    public async loadMyOwnProjects(
        limit = config.defaults.queries.limit,
    ): Promise<ProjectEntity[]> {
        return this.unAuthorizedClient
            .query({
                query: gql(listPersonProjectConnections),
                variables: {
                    limit: limit,
                    personId: this.currentUserProvider.UserId,
                },
            })
            .then((data) => {
                const result = data.data.listPersonProjectConnections.items;
                return result
                    .filter((item) => {
                        return item.roles?.includes(this.OWNER_ROLE);
                    })
                    .map((item) => item.project)
                    .filter((item) => item);
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<ProjectEntity>(
                            ProjectEntity,
                        ),
                    ),
                ),
            );
    }

    public async loadPersonProjects(
        personId,
        limit = config.defaults.queries.limit,
    ): Promise<ProjectEntity[]> {
        return this.unAuthorizedClient
            .query({
                query: gql(listPersonProjectConnections),
                variables: { limit: limit, personId: personId },
            })
            .then((data) => {
                const result = data.data.listPersonProjectConnections.items;
                return result
                    .filter((item) => {
                        return (
                            item.roles?.includes(this.OWNER_ROLE) ||
                            item.roles?.includes(this.MEMBER_ROLE)
                        );
                    })
                    .map((item) => item.project);
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<ProjectEntity>(
                            ProjectEntity,
                        ),
                    ),
                ),
            )
            .then((data) => {
                return Promise.all(
                    data.map((item) => {
                        return this.loadProjectTeam(item.id).then((team) => {
                            item.team = team;
                            return item;
                        });
                    }),
                ).then(data);
            });
    }

    public async loadProjectsByPersonId(
        id,
        options?: {
            limit?: number;
            greed?: boolean;
        },
    ): Promise<ProjectEntity[]> {
        return this.unAuthorizedClient
            .query({
                query: gql(listPersonProjectConnections),
                variables: {
                    limit: options?.limit || 50,
                    personId: id,
                },
                fetchPolicy: 'network-only',
            })
            .then((data) => {
                const result = data.data.listPersonProjectConnections.items;
                return result.map((item) => item.project).filter((i) => !!i);
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.CollectionTransformer(
                        new Transformers.ToTypeTransformer<ProjectEntity>(
                            ProjectEntity,
                        ),
                    ),
                ),
            )
            .then((data) => {
                if (!options?.greed) {
                    return data;
                }

                //TODO: generate tons request, needs change backend ASAP
                return Promise.all(
                    data.map((item) => {
                        return this.loadProjectTeam(item.id).then((team) => {
                            item.team = team;
                            return item;
                        });
                    }),
                ).then(data);
            });
    }
    public async loadProjectTeam(
        projectId: string,
    ): Promise<UserProfileEntity[]> {
        return this.unAuthorizedClient
            .query({
                query: gql(projectPersonsConnection),
                variables: { projectId },
                fetchPolicy: 'network-only',
            })
            .then((data) => {
                let result = data.data.ProjectPersonsConnection.items;
                result = result.filter((item) => {
                    return (
                        item.roles &&
                        (item.roles.includes(this.OWNER_ROLE) ||
                            item.roles.includes(this.MEMBER_ROLE))
                    );
                });
                return result.map((item) => ({
                    ...item.person,
                    roles: item.roles,
                }));
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer(TeamMember),
                ),
            );
    }

    public async loadProjectPersons(
        projectId: string,
    ): Promise<UserProfileEntity[]> {
        return this.unAuthorizedClient
            .query({
                query: gql(projectPersonsConnection),
                variables: { projectId },
                fetchPolicy: 'network-only',
            })
            .then((data) => {
                let result = data.data.ProjectPersonsConnection.items;

                return result.map((item) => ({
                    ...item.person,
                    roles: item.roles,
                }));
            })
            .then(
                Transformers.promisePipeTransform(
                    new Transformers.ToTypeTransformer(TeamMember),
                ),
            );
    }

    public async createProject(
        name: string,
    ): Promise<{ id: string; name: string }> {
        const mutation = gql(processProjectCreation);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    input: {
                        projectOwnerId: this.currentUserProvider.UserId,
                        name: name,
                    },
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching(
                        'listPersonProjectConnections',
                    ),
            })
            .then((result) => result.data.processProjectCreation);
    }

    public async deleteProject(id: string): Promise<any> {
        const mutation = gql(deleteProject);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    input: {
                        id: id,
                    },
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching([
                        'listPersonProjectConnections',
                        id,
                        this.currentUserProvider.UserId,
                    ]),
            })
            .then((result) => {
                console.log('deletion successful', result);
            })
            .catch((err) => {
                console.log('deletion error', err);
            });
    }

    public applyToProject(projectId: string, roleId?: string) {
        const mutation = gql(createApplication);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('create application', data);
                return data;
            });
    }

    public async getPersonProjectConnection(
        personId: string,
        projectId: string,
    ): Promise<any> {
        const query = gql(personProjectConnection);
        const variables = { personId: personId, projectId: projectId };

        return this.unAuthorizedClient
            .query({
                query: query,
                variables: variables,
                fetchPolicy: 'no-cache',
            })
            .then((data) => {
                return data.data.getPersonProjectConnection;
            });
    }

    public async declineInvitation(projectId: string, roleId?: string) {
        const mutation = gql(declineInvitation);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('decline invitation', data);
                return data;
            });
    }

    public async acceptInvitation(projectId: string, roleId?: string) {
        const mutation = gql(acceptInvitation);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('accept invitation', data);
                return data;
            });
    }

    public async recallApplication(projectId: string, roleId?: string) {
        const mutation = gql(recallApplication);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('recall application', data);
                return data;
            });
    }

    public async leaveProject(projectId: string) {
        const mutation = gql(leaveProject);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    projectId: projectId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('leave project', data);
                return data;
            });
    }

    public inviteToProject(
        personId: string,
        projectId: string,
        roleId?: string,
    ) {
        const mutation = gql(createInvitation);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    personId: personId,
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('create invitation', data);
                return data;
            });
    }

    public async acceptApplication(
        personId: string,
        projectId: string,
        roleId?: string,
    ) {
        const mutation = gql(acceptApplication);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    personId: personId,
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('accept application', data);
                return data;
            });
    }

    public async declineApplication(
        personId: string,
        projectId: string,
        roleId?: string,
    ) {
        const mutation = gql(declineApplication);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    personId: personId,
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('decline application', data);
                return data;
            });
    }

    public async removeProjectMember(personId: string, projectId: string) {
        const mutation = gql(removeProjectMember);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    personId: personId,
                    projectId: projectId,
                },
                //ProjectPersonsConnection
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('remove project member', data);
                return data;
            });
    }

    public async recallInvitation(
        personId: string,
        projectId: string,
        roleId?: string,
    ) {
        const mutation = gql(recallInvitation);
        return this.authorizedClient
            .mutate({
                mutation,
                variables: {
                    personId: personId,
                    projectId: projectId,
                    roleId: roleId,
                },
                update: (cache) =>
                    this.invalidateAllQueryFetching('personProjectConnection'),
            })
            .then((data) => {
                console.log('recall invitation', data);
                return data;
            });
    }
}
