import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import ProfileAvatar, { ProfileAvatarProps } from './ProfileAvatar';
import { useMyUserProfileData } from '../../store/hooks/useMyUserProfileData';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'relative',
        border: '2px solid #ffffff',
    },
    largeBordered: {
        border: '5px solid #ffffff',
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.14)',
    },
    small: {
        width: 24,
        height: 24,
    },
    middle: {
        width: 28,
        height: 28,
    },
    normal: {
        width: 32,
        height: 32,
    },
    'x-large': {
        width: 112,
        height: 112,
    },
    'xx-large': {
        width: 155,
        height: 155,
    },
    'desktop-large': {
        width: 120,
        height: 120,
    },
    large: {
        width: 64,
        height: 64,
    },
}));

export type AvatarIconProps = ProfileAvatarProps & {
    sizes?: string;
    style?: any;
    bordered?: boolean;
};

const AvatarIcon = ({ sizes, style, bordered, ...other }: AvatarIconProps) => {
    const classes = useStyles();

    const { getProfilePicture } = useMyUserProfileData();
    const { onClick, picture } = other;
    const nextProps = { onClick: onClick, picture: getProfilePicture(picture) };

    return (
        <Box
            className={[
                classes.root,
                sizes ? classes[sizes] : classes.normal,
                bordered && classes.largeBordered,
            ].join(' ')}
            style={{
                fontSize:
                    sizes === 'normal' || sizes === 'large'
                        ? '15px'
                        : sizes === 'x-large'
                        ? '32px'
                        : '12px',
                ...style,
            }}
        >
            <ProfileAvatar {...nextProps} />
        </Box>
    );
};

export default AvatarIcon;
