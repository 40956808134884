import React from 'react';
import {
    KeyboardDatePicker as MuiKeyboardDatePicker,
    KeyboardDatePickerProps as MuiKeyboardDatePickerProps,
} from '@material-ui/pickers';
import { FieldProps, getIn } from 'formik';
import { createErrorHandler } from './errorHandler';

export interface WeKeyboardDatePickerProps
    extends FieldProps,
        Omit<MuiKeyboardDatePickerProps, 'name' | 'value' | 'error'> {}

export const fieldToKeyboardDatePicker = ({
    disabled,
    field,
    form: { isSubmitting, touched, errors, setFieldValue, setFieldError },
    ...props
}: WeKeyboardDatePickerProps): MuiKeyboardDatePickerProps => {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled != undefined ? disabled : isSubmitting,
        onChange(date) {
            props.onChange
                ? props.onChange(date)
                : setFieldValue(field.name, date);
        },
        onError: createErrorHandler(fieldError, field.name, setFieldError),
    };
};

export const WeKeyboardDatePicker = ({
    children,
    ...props
}: WeKeyboardDatePickerProps) => {
    return (
        <MuiKeyboardDatePicker {...fieldToKeyboardDatePicker(props)}>
            {children}
        </MuiKeyboardDatePicker>
    );
};
