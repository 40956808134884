import {
    PersonService,
    Picture,
    ProfileItemInterface,
    ProjectEntity,
    ProjectsService,
    UserProfileEntity,
    defaultPicture,
} from '@weco/core';
import {
    COMMON_SERVICES,
    CoreEventDispatcher,
    Event,
    Inject,
    Injectable,
    getPictureUrl,
} from '@weco/common';
import { action, observable, runInAction } from 'mobx';
import { SERVICES } from '../../../../services/services';
import { AppStore } from '../../../../store/AppStore';
import { APP_EVENTS } from '../../../../../app_events';
import { Subscription } from 'rxjs';

export interface ProjectProfileStoreInterface {
    project: ProjectEntity;
    team: ProfileItemInterface[];
    invitedPersons: ProfileItemInterface[];
    appliedPersons: ProfileItemInterface[];
    personProjectConnection: {
        personId: string;
        projectId: string;
        roles: string[];
    };
    matchPercent: number;
    isEnvitee: () => boolean;
    isMember: () => boolean;
    ableToApply: () => boolean;
    applied: () => boolean;
    loadItem(id: string, notFoundRedirect: () => void): Promise<any>;
    loadTeam(id: string): Promise<any>;
    loadPersonProjectConnection(profile: UserProfileEntity): Promise<any>;
    acceptInvitation(): Promise<any>;
    declineInvitation(): Promise<any>;
    leaveProject(): Promise<any>;
    applyToProject(): Promise<any>;
    recallApplication(): Promise<any>;
    loadMatchPercent(): Promise<any>;
    getProjectPicture(): string;
    setPersonProjectConnection(param): void;
}

@Injectable()
export class ProjectProfileStore implements ProjectProfileStoreInterface {
    @Inject(PersonService)
    private personService: PersonService;

    @Inject(ProjectsService)
    service: ProjectsService;

    @Inject(SERVICES.AppStore)
    private appStore: AppStore;

    @observable project: ProjectEntity;
    @observable team: ProfileItemInterface[];
    @observable invitedPersons: ProfileItemInterface[];
    @observable appliedPersons: ProfileItemInterface[];
    @observable matchPercent: number;
    @observable personProjectConnection: {
        personId: string;
        projectId: string;
        roles: string[];
    };
    @observable applicant: UserProfileEntity;
    @observable computedProjectPicture: string;

    @Inject(COMMON_SERVICES.CoreEventDispatcher)
    eventDispatcher: CoreEventDispatcher;

    private subscriptions: Subscription[];

    constructor() {
        setTimeout(() => {
            this.subscriptions = [
                this.eventDispatcher.addListener(
                    APP_EVENTS.COOL_UP,
                    this.refreshCurrentData,
                ),
                this.eventDispatcher.addListener(
                    APP_EVENTS.COOL_DOWN,
                    this.refreshCurrentData,
                ),
            ];
        });
    }

    refreshCurrentData = () => {
        if (this.project?.id) {
            setTimeout(() => {
                this.loadItem(this.project.id);
            }, 500);
        }
    };

    getProjectPicture = () => {
        if (!this.computedProjectPicture) {
            this.computedProjectPicture = getPictureUrl(
                this.project.picture,
                defaultPicture,
            );
        }
        return this.computedProjectPicture;
    };

    isEnvitee = () => {
        return (
            this.personProjectConnection &&
            this.personProjectConnection.roles.includes('invitee')
        );
    };

    isMember = () => {
        return (
            this.personProjectConnection &&
            this.personProjectConnection.roles.includes('member') &&
            !this.personProjectConnection.roles.includes('owner')
        );
    };

    ableToApply = () => {
        if (!this.personProjectConnection) {
            return true;
        }
        return (
            this.personProjectConnection &&
            !['applicant', 'member', 'invitee'].some((p) =>
                this.personProjectConnection.roles.includes(p),
            )
        );
    };

    applied = () => {
        return (
            this.personProjectConnection &&
            this.personProjectConnection.roles.includes('applicant')
        );
    };

    @action.bound
    async loadItem(id: string, notFoundRedirect?: () => void): Promise<any> {
        const result = await this.service.loadItem(id);
        runInAction(() => {
            if (!result) {
                notFoundRedirect();
            }
            this.project = result;
        });
    }

    @action.bound
    async loadTeam(id: string): Promise<any[]> {
        try {
            const result = await this.personService.loadProjectPersons(id);
            runInAction(() => {
                this.team = result.filter((item) => {
                    return (
                        item.roles &&
                        (item.roles.includes('owner') ||
                            item.roles.includes('member'))
                    );
                });
                this.invitedPersons = result.filter((item) => {
                    return item.roles && item.roles.includes('invitee');
                });

                this.appliedPersons = result.filter((item) => {
                    return item.roles && item.roles.includes('applicant');
                });
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    async applyToProject(roleId?: string): Promise<any> {
        await this.service.applyToProject(this.project.id, roleId);
        const owner = await this.personService.findById(this.project.owner.id);
        this.eventDispatcher.dispatch(
            APP_EVENTS.APPLY_TO_PROJECT,
            new Event({
                projectName: this.project.name,
                projectId: this.project.id,
                email: owner.contacts && owner.contacts.email,
            }),
        );
        return this.loadPersonProjectConnection(this.applicant);
    }

    @action.bound
    async loadMatchPercent(): Promise<number> {
        try {
            const result = await this.service.getMatchPercent(this.project?.id);
            runInAction(() => {
                this.matchPercent = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    async loadPersonProjectConnection(
        profile: UserProfileEntity,
    ): Promise<any> {
        this.applicant = profile;
        try {
            const result = await this.service.getPersonProjectConnection(
                this.personService.currentUser.UserId,
                this.project?.id,
            );
            runInAction(() => {
                this.personProjectConnection = result;
            });
            return result;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    @action.bound
    setPersonProjectConnection(connection): void {
        runInAction(() => {
            this.personProjectConnection = connection;
        });
    }

    @action.bound
    async declineInvitation(): Promise<any> {
        await this.service.declineInvitation(this.project.id);
        // for mailchimp email send
        this.eventDispatcher.dispatch(
            APP_EVENTS.PROJECT_INVITE_RECALL_REMOVE,
            new Event({
                projectName: this.project.name,
                projectId: this.project.id,
            }),
        );
        // for analytics
        this.eventDispatcher.dispatch(
            APP_EVENTS.PROJECT_REJECT,
            new Event({
                projectName: this.project.name,
                projectId: this.project.id,
            }),
        );
        return this.loadPersonProjectConnection(this.applicant);
    }

    @action.bound
    async acceptInvitation(): Promise<any> {
        await this.service.acceptInvitation(this.project.id);
        const owner = await this.personService.findById(this.project.owner.id);
        this.eventDispatcher.dispatch(
            APP_EVENTS.PROFILE_ACCEPT,
            new Event({
                projectName: this.project.name,
                projectId: this.project.id,
                email: owner.contacts && owner.contacts.email,
            }),
        );
        return this.loadPersonProjectConnection(this.applicant);
    }

    @action.bound
    async recallApplication(): Promise<any> {
        await this.service.recallApplication(this.project.id);
        this.eventDispatcher.dispatch(
            APP_EVENTS.PROJECT_RECALL,
            new Event({
                projectName: this.project.name,
                projectId: this.project.id,
            }),
        );
        return this.loadPersonProjectConnection(this.applicant);
    }

    @action.bound
    async leaveProject(): Promise<any> {
        await this.service.leaveProject(this.project.id);
        return this.loadPersonProjectConnection(this.applicant);
    }

    // TODO: we will not use dispose, should be cleaned up
    dispose(): Promise<any> {
        this.project = null;
        this.team = [];
        this.matchPercent = 0;
        this.subscriptions?.forEach((s) => {
            s.unsubscribe();
        });
        return Promise.resolve(true);
    }
}
