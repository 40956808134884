import { Transformers } from '@weco/common';

const toClass = (value) => {
    if (!Array.isArray(value)) {
        return value;
    }
    const res = []
        .concat(value)
        .filter((v) => !!v)
        .reduce((acc, item) => {
            const splitValue = item?.split(/:(.+)/) || [];
            if (splitValue.length > 1) {
                acc[splitValue[0]] = splitValue[1] || '';
            }
            return acc;
        }, {});
    return res;
};

const toPlain = (value) => {
    return Object.keys(value || {})
        .filter((key) => !!value[key] && value[key] !== '')
        .map((key) => `${key}:${value[key]}`);
};
/**
 * @example ['facebook:345532'] > {facebook: 345532}
 */
export class ColonArrayToObjectPropertyTransformer extends Transformers.ClassPropertyTransformer {
    constructor() {
        super(toClass, toPlain);
    }
}
