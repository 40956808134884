import React from 'react';
import parse from 'html-react-parser';
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Theme,
    createStyles,
    ListItemIcon,
} from '@material-ui/core';
import MessageInterface, {
    MessagePropsInterface,
    WidgetMessageInterface,
} from '../models/interfaces/MessageInterface';
import { ChatAvatarProps } from '../models/interfaces/RoomAvatarInterface';
import { makeStyles } from '@material-ui/core/styles';
import { RouterPaths } from 'apps/client/src/app/router/RouterPaths';
import { getUserFirstLetters } from '../utils/room';
import { MESSAGE_TYPE_WIDGET, widgets } from '@weco/chat';
import WidgetMessageError from './WidgetMessageError';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        myMessage: {
            display: 'block',
            padding: '15px',
            marginLeft: '55px',
            borderRadius: '10px',
            float: 'right',
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            '& > a': {
                color: theme.palette.common.white,
            },
        },
        opponentMessage: {
            display: 'block',
            padding: '15px',
            borderRadius: '10px',
            background: theme.palette.grey['100'],
            color: theme.palette.grey.A200,
        },
        name: {
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        icon: {
            width: 40,
            minWidth: 40,
            height: 40,
            borderRadius: '50%',
            display: 'block',
            background: '#f5f5f5',
            textAlign: 'center',
            lineHeight: '40px',
            fontSize: 20,
            marginRight: 16,
        },
    }),
);

const Message = ({
    currentUser,
    users,
    message,
    sending,
    sameTime,
    onAvatarClick,
    getSkillSetById,
}: MessagePropsInterface) => {
    const classes = useStyles();
    const props: ChatAvatarProps = {
        alt: '',
        src: null,
        firstLetters: '',
    };

    if (users && users[message.owner.id]) {
        props.alt = users[message.owner.id].name;
        props.src = users[message.owner.id].image || props.src;
        props.firstLetters = getUserFirstLetters(users[message.owner.id]);
    }

    const renderWidget = (message: MessageInterface) => {
        const widgetMessage = message.message as WidgetMessageInterface;
        if (!widgets[widgetMessage.class]) {
            return <WidgetMessageError message="Widget doesn't exists" />;
        }
        return widgets[widgetMessage.class]({
            ...widgetMessage.data,
            requestOwnerId: currentUser.id,
            messageId: message.id,
            ownerName: users[message.owner.id].name,
            getSkillSetById,
        });
    };

    return (
        <>
            <ListItem
                button
                disabled={sending}
                style={{
                    background: message.error ? 'red' : 'transparent',
                    marginTop: sameTime ? '-25px' : '0',
                }}
            >
                {message.owner.id !== currentUser.id && (
                    <>
                        {!!props.src && (
                            <ListItemAvatar>
                                <Avatar
                                    {...props}
                                    onClick={() => {
                                        onAvatarClick({
                                            type: RouterPaths.profilePage,
                                            id: message.owner.id,
                                        });
                                    }}
                                />
                            </ListItemAvatar>
                        )}
                        {!props.src && (
                            <ListItemIcon
                                className={classes.icon}
                                onClick={() => {
                                    onAvatarClick({
                                        type: RouterPaths.profilePage,
                                        id: message.owner.id,
                                    });
                                }}
                            >
                                {props.firstLetters}
                            </ListItemIcon>
                        )}
                    </>
                )}
                <ListItemText
                    primary={''}
                    secondary={
                        <span
                            className={
                                message.owner.id === currentUser.id
                                    ? classes.myMessage
                                    : classes.opponentMessage
                            }
                        >
                            {!message.type ||
                            message.type !== MESSAGE_TYPE_WIDGET
                                ? parse(
                                      message.error
                                          ? message.error.message
                                          : message.message.toString(),
                                  )
                                : renderWidget(message)}
                        </span>
                    }
                />
            </ListItem>
        </>
    );
};

export default Message;
