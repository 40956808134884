import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { RouterPaths } from '../../router/RouterPaths';
import {
    Button,
    CardMedia,
    IconButton,
    Link,
    SvgIcon,
    Typography,
} from '@material-ui/core';
import FullLogo from '../../../assets/images/full-logo-beta.svg';
import { Auth } from '@weco/common';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ReactComponent as GoogleIcon } from '../../../assets/icons/social/g-google.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/social/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/social/linkedin.svg';
import { useHistory } from 'react-router';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import { useAuthorizationLayoutStyles } from './styles/AuthorizationLayout.styles';
import { MinimalisticFooter } from './particules/MinimalisticFooter';
import { WE_CLICKS } from '../../../we_clicks';

interface AuthorizationLayoutProps {
    children: JSX.Element;
}

const AuthorizationLayout = ({ children }: AuthorizationLayoutProps) => {
    const classes = useAuthorizationLayoutStyles();
    const history = useHistory();
    const { authenticate } = useCurrentAuthData();
    const [showSocials, setShowSocials] = useState(true);

    function goTo(path) {
        return () => history.push(path);
    }

    const socialLogin = (provider: CognitoHostedUIIdentityProvider) => {
        authenticate(new Auth.AmplifyFederatedSignInToken(provider));
    };

    return (
        <>
            <CssBaseline />
            <Box className={classes.root}>
                <Container maxWidth="sm">
                    <Box className={classes.container}>
                        <IconButton
                            style={{ padding: 20 }}
                            onClick={goTo(RouterPaths.dashboard)}
                        >
                            <CardMedia
                                src={FullLogo}
                                component="img"
                                className={classes.logoImage}
                            />
                        </IconButton>

                        <main className={classes.main}>
                            {React.cloneElement(children, {
                                hideSocials: () => {
                                    setShowSocials(false);
                                },
                            })}
                        </main>

                        {showSocials && (
                            <>
                                <Typography className={classes.text}>
                                    or
                                </Typography>
                                <Box className={classes.fieldBox}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon
                                                className={classes.svgIcon}
                                            >
                                                <GoogleIcon />
                                            </SvgIcon>
                                        }
                                        className={classes.button}
                                        id={WE_CLICKS.LOGIN_GOOGLE}
                                        onClick={() =>
                                            socialLogin(
                                                CognitoHostedUIIdentityProvider.Google,
                                            )
                                        }
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                        >
                                            Continue with Google
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box className={classes.fieldBox}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon
                                                className={classes.svgIcon}
                                            >
                                                <FacebookIcon />
                                            </SvgIcon>
                                        }
                                        className={classes.button}
                                        id={WE_CLICKS.LOGIN_FACEBOOK}
                                        onClick={() =>
                                            socialLogin(
                                                CognitoHostedUIIdentityProvider.Facebook,
                                            )
                                        }
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                        >
                                            Continue With Facebook
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box className={classes.fieldBox}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={
                                            <SvgIcon
                                                className={classes.svgIcon}
                                            >
                                                <LinkedinIcon />
                                            </SvgIcon>
                                        }
                                        className={classes.button}
                                        id={WE_CLICKS.LOGIN_LINKEDIN}
                                        onClick={() =>
                                            socialLogin(
                                                'LinkedIn' as CognitoHostedUIIdentityProvider,
                                            )
                                        }
                                    >
                                        <Typography
                                            className={classes.buttonText}
                                        >
                                            Continue With Linkedin
                                        </Typography>
                                    </Button>
                                </Box>
                                <Typography
                                    paragraph
                                    variant="caption"
                                    align="center"
                                >
                                    <Link
                                        href={RouterPaths.signup}
                                        className={classes.signUpLink}
                                    >
                                        No Account?
                                    </Link>
                                </Typography>
                                <MinimalisticFooter textColor="white" />
                            </>
                        )}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default React.memo(AuthorizationLayout);
