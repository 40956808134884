import { UserTokenInterface } from './common';

export class MockedUserToken implements UserTokenInterface {
    constructor(
        public user: Partial<{
            username?: string;
            role?: boolean;
            isAuthenticated?: boolean;
        }> = {},
    ) {}

    getUser() {
        return this.user;
    }

    getUsername(): string {
        return this.user.username || 'unknown';
    }

    hasRole(name): boolean {
        return !!this.user.role;
    }

    isAuthenticated(): boolean {
        return !!this.user.isAuthenticated;
    }
}
