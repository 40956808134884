import allCauses from './data/allCauses';
import allPassions from './data/allPassions';
import allObjectives from './data/allObjectives';
import allProjectStatus from './data/allProjectStatus';
import allLanguages from './data/allLanguages';
import allProfileStatus from './data/allProfileStatus';
import { TypeValueInterface } from '../types';
import allStatuses from './data/allStatuses';
import { SchoolEntity } from '../entity';

const contacts: TypeValueInterface[] = [
    { type: 'email' },
    { type: 'googlevoice' },
    { type: 'hangouts' },
    { type: 'phone' },
    { type: 'skype' },
    { type: 'telegram' },
    { type: 'website' },
    { type: 'whatsapp' },
];
const socialMedia: TypeValueInterface[] = [
    { type: 'facebook' },
    { type: 'instagram' },
    { type: 'linkedin' },
    { type: 'soundcloud' },
    { type: 'youtube' },
];

const fundings: TypeValueInterface[] = [
    { type: 'AngelList' },
    { type: 'CatarseMe' },
    { type: 'CauseMatch' },
    { type: 'CrowdFunder' },
    { type: 'EcrowdInvest' },
    { type: 'EquityNet' },
    { type: 'Fundable' },
    { type: 'GoFundMe' },
    { type: 'GoteoOrg' },
    { type: 'IdeaMe' },
    { type: 'Indiegogo' },
    { type: 'Kickante' },
    { type: 'KickStarter' },
    { type: 'KivaOrg' },
    { type: 'MicroVentures' },
    { type: 'WeFunder' },
    { type: 'Patreon' },
    { type: 'SeedInvest' },
];

export interface CoreConstantsInterface {
    causes: string[];
    passions: string[];
    objectives: string[];
    statuses: string[];
    investments: string[];
    tradings: string[];
    languages: any;
    stores: string[];
    socialMedia: TypeValueInterface[];
    fundings: TypeValueInterface[];
    contacts: TypeValueInterface[];
    project: { statuses: string[] };
    profile: { statuses: string[] };
    schools?: SchoolEntity[];
}

export const coreConstants: CoreConstantsInterface = {
    contacts,
    fundings,
    socialMedia,
    causes: allCauses,
    passions: allPassions,
    objectives: allObjectives,
    languages: allLanguages,
    statuses: allStatuses,
    investments: ['Money', 'Goods', 'Accommodation', 'Services'],
    tradings: ['Services', 'Office'],
    stores: [],
    project: {
        statuses: allProjectStatus,
    },
    profile: {
        statuses: allProfileStatus,
    },
    schools: [],
};
export const defaultPicture = '/assets/images/profile-placeholder.png';
