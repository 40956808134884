import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const ComingSoon = ({ to, label }) => {
    return (
        <div
            style={{
                lineHeight: '1.5',
                textAlign: 'center',
                verticalAlign: 'middle',
                padding: '10%',
            }}
        >
            <div>
                <h1>Coming Soon!</h1>
                <p>The team is busy working on it.</p>
                <Link to={to || '/'} className="spot-link">
                    {label || 'Home'}
                </Link>
            </div>
        </div>
    );
};

ComingSoon.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
};
