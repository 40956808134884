import { MailChimpEvent } from '../events/MailChimpEvent';
import { environment } from '../../environments/environment';

export const fetchDataToMailChimp = (data: MailChimpEvent) => {
    fetch(environment.mailchimp.apigate, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ info: data }),
    }).then((response) => {
        console.log('response from mailChimp Lambda', response);
    });
};
