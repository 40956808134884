import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import { ActionButton } from '@weco/ui';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        textAlign: 'center',
    },
    button: {
        marginTop: 15,
        maxWidth: 400,
        margin: 'auto',
    },
}));

const EmptyRolesRouting = memo(() => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Box className={classes.root}>
            <Typography className={classes.title}>
                You need to select a role.
                <Box m={2} />
                Navigate through your matching departments and choose which
                roles you would like to be assigned in a project.
            </Typography>
            <ActionButton
                className={classes.button}
                label="Let's Do It"
                onClick={() => {
                    history.push('me/profile/skills/ideas');
                }}
            />
        </Box>
    );
});

EmptyRolesRouting.displayName = 'EmptyRolesRouting';

export { EmptyRolesRouting };
