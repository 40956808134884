import React from 'react';
import { observer } from 'mobx-react';
import {
    ReactHelpers,
    useAsync,
    SkeletonAsyncLoader,
    AvatarListHorizontalLoadingAnimation,
} from '@weco/ui';
import { useSearchPeopleData } from './useSearchPeopleData';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router';
import { RecentSearchLocalStorageKeys } from '@weco/common';

export interface ProfilePeopleContainerProps {
    children: any;
}

export const ProfilePeopleContainer = observer(
    ({ children }: ProfilePeopleContainerProps) => {
        const {
            items,
            search,
            setAdvancedSearchFilter,
        } = useSearchPeopleData();
        const location = useLocation<any>();
        const result = useAsync(() => {
            if (!items) {
                // setAdvancedSearchFilter(null);
                return Promise.all([
                    search(
                        location?.state?.searchTag
                            ? location?.state?.searchTag
                            : JSON.parse(
                                  localStorage.getItem(
                                      RecentSearchLocalStorageKeys.person,
                                  ),
                              )?.[0],
                    ),
                ]).then(() => true);
            }
            return Promise.resolve(true);
        });
        return (
            <SkeletonAsyncLoader
                {...result}
                skeleton={
                    <Box mt={4} width="100%">
                        <AvatarListHorizontalLoadingAnimation
                            count={10}
                            variant="circle"
                        />
                    </Box>
                }
            >
                {!result.loading && ReactHelpers.renderChildren(children)}
            </SkeletonAsyncLoader>
        );
    },
);
