import classNames from 'classnames';
import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// TODO: add comment.

const useStyles = makeStyles((theme: Theme) => ({ root: {} }));

interface TypographyDemonstrationProps {
    children: JSX.Element;
    className?: string;
}

const TypographyDemonstration = memo((props: TypographyDemonstrationProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return <Box className={rootClasses}>{props.children}</Box>;
});

TypographyDemonstration.displayName = 'TypographyDemonstration';

export { TypographyDemonstration };
