import classNames from 'classnames';
import React, { memo, useState } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import {
    DepartmentEntityWithTestResultPercent,
    SkillSetsEntity,
    getDepartmentDisplayName,
} from '@weco/core';
import { BoxList } from './BoxList';
import AddIcon from '@material-ui/icons/Add';
import { SpriteSvgIconButton } from '../molecules/SpriteSvgIconButton';
import { departmentsDrawerStyles } from './DepartmentsDrawerStyles';

import { Alert } from '@material-ui/lab';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import { Link, useHistory } from 'react-router-dom';
import {
    AnalyticsEvent,
    COMMON_SERVICES,
    EventDispatcher,
    useAnalytics,
    useInjection,
} from '@weco/common';
import { WE_CLICKS } from '../../../../../apps/client/src/we_clicks';

class ToggleState {
    type: string;
    value: boolean;
}

export class DepartmentSection {
    department: DepartmentEntityWithTestResultPercent;
    items: SkillSetsEntity[];
    get name() {
        return this.department?.name;
    }
    constructor(
        department: DepartmentEntityWithTestResultPercent,
        items: SkillSetsEntity[],
    ) {
        this.department = department;
        this.items = items;
    }
}

interface DepartmentListProps {
    sections: DepartmentSection[];
    isPersonPage?: boolean;
    isThereFinishedTest?: boolean;
    onItemClick?(item: SkillSetsEntity);
}

const Component = ({
    sections,
    isPersonPage,
    onItemClick,
    isThereFinishedTest,
}: DepartmentListProps) => {
    const cx = departmentsDrawerStyles();
    const [sectionsToggleState, setSectionsToggleState] = useState<
        ToggleState[]
    >(sections.map((section) => ({ type: section.name, value: false })));
    const [] = useState<{
        userId: string;
        loading: boolean;
    }>({ userId: null, loading: false });
    const history = useHistory();
    const { event } = useAnalytics();

    const toggleSection = (sectionTitle) => {
        const updated = sectionsToggleState.map((item: ToggleState) => {
            if (item.type === sectionTitle) {
                item.value = !item.value;
            }
            return item;
        });
        setSectionsToggleState(updated);
    };

    const sectionOpened = (section: DepartmentSection) => {
        return sectionsToggleState.find((state) => state.type === section.name)
            .value;
    };

    return (
        <Box className={cx.customDrawer}>
            {!isThereFinishedTest && !!isPersonPage && (
                <Alert severity="warning" color="warning">
                    You haven't taken the test before
                    <IconButton
                        id={WE_CLICKS.TEST_ENTRY_SKILLS_STARTED}
                        disableRipple={true}
                        disableFocusRipple={true}
                        className={cx.warningButton}
                        onClick={() => {
                            history.push(RouterPaths.quiz);
                        }}
                    >
                        Start Now
                    </IconButton>
                </Alert>
            )}
            {sections.map((section) => {
                const sectionDisplayName = getDepartmentDisplayName(
                    section.name,
                );
                const numberOfSelectedItems = section.items?.filter(
                    (i) => i.isSelected,
                ).length;
                return (
                    <Box className={cx.customDrawerElement} key={section.name}>
                        <Box
                            className={cx.title}
                            onClick={() => toggleSection(section.name)}
                        >
                            <Box
                                className={
                                    cx.layoutToBreakChildStylesInheritance
                                }
                            >
                                <Box className={cx.sectionName}>
                                    <SpriteSvgIconButton
                                        id={
                                            section.name
                                                ? 'MainNavIcon-' + section.name
                                                : 'MainNavIcon-Notifications'
                                        }
                                    />
                                    {`${sectionDisplayName}(${numberOfSelectedItems})`}
                                </Box>
                                <Box>
                                    {isPersonPage &&
                                        `${section.department.testPercent}%`}
                                </Box>
                                <Box
                                    className={classNames({
                                        [cx.chevron]: true,
                                        [cx.rotated]: sectionOpened(section),
                                    })}
                                />
                            </Box>
                        </Box>
                        <Box
                            className={classNames({
                                [cx.opened]: sectionOpened(section),
                                [cx.closed]: !sectionOpened(section),
                            })}
                        >
                            <BoxList
                                items={section.items}
                                onItemClick={onItemClick}
                            />
                            <Box className={cx.actionBox}>
                                <Button
                                    id={WE_CLICKS.PERSON_PROFILE_POSITION_ADD}
                                    onClick={() => {
                                        const newPosition = new SkillSetsEntity();
                                        newPosition.departmentId =
                                            section.department.id;
                                        newPosition.department =
                                            section.department;
                                        newPosition.name = 'New Role';
                                        onItemClick(newPosition);
                                    }}
                                    startIcon={<AddIcon />}
                                    variant="text"
                                    color="secondary"
                                >
                                    Add Role
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};
export const DepartmentsDrawer = memo(Component);
