import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Theme,
    Typography,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { getPictureUrl } from '@weco/common';
import debug from 'debug';
import React, { memo } from 'react';
import { ProjectEntity } from '../../../../core/src/lib/entity/ProjectEntity';
import { WeChipsList } from '../molecules/WeChipsList';
import { FavoriteIconButton } from './FavoriteIconButton';
import { MatchPercentage } from './MatchPercentage';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import { ChildrenType, OnAnyEventHandler } from '../helpers';

const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        background: '#FFFFFF',
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.14)',
        borderColor: '#ffffff',
        borderStyle: 'solid',
        borderWidth: '6px',
        borderRadius: '50%',
    },
    avatarNoBorder: {
        background: '#FFFFFF',
        borderRadius: '50%',
    },
}));

export interface CircleAvatarProps {
    className?: string;
    image?: string;
    title?: string;
    isBorder?: boolean;
}

export const CircleAvatar = React.memo(function CircleAvatarComponent({
    image,
    title,
    className,
    isBorder,
}: CircleAvatarProps) {
    const classes = useStyles();

    const avatarClasses = !isBorder ? classes.avatarNoBorder : classes.avatar;

    return (
        <CardMedia
            image={image}
            component="img"
            alt={title}
            title={title}
            className={classNames(className, avatarClasses)}
        />
    );
});
