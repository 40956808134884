import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { WeChip, WeChipItem } from './WeChip';
import { ReactHelpers } from '../helpers';
import get from 'lodash/get';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export interface WeChipsListProps {
    items: (WeChipItem | string)[];
    onClick?: (item: any) => void;
    chipsGroupName?: string;
    chipSize?: string;
    chipsLengthLimitedTo?: number;
    chipItemComponent?: (props: {
        item: WeChipItem | string;
        index: number;
    }) => JSX.Element;
}

export const WeChipsList = ({
    items,
    onClick,
    chipSize,
    chipsLengthLimitedTo,
    chipItemComponent,
    chipsGroupName,
}: WeChipsListProps) => {
    const classes = useStyles();

    if (items === null) {
        return null;
    }

    items.forEach((item) => {
        const idx = items.indexOf(item);
        // @ts-ignore
        if (idx !== -1 && item?.isActive === true) {
            items.unshift(items.splice(idx, 1)[0]);
        }
    });

    return (
        <Box className={classes.root}>
            {[]
                .concat(items || [])
                .filter(Boolean)
                .map((item, index) => {
                    if (typeof item !== 'string' && item?.label === undefined) {
                        console.error('WeChipList label empty');
                        return null;
                    }
                    // TODO add comments
                    if (
                        chipsLengthLimitedTo &&
                        index > chipsLengthLimitedTo - 1
                    )
                        return null;

                    if (chipItemComponent)
                        return ReactHelpers.renderChildren(chipItemComponent, {
                            item,
                            index,
                        });

                    return (
                        <WeChip
                            chipsGroupName={chipsGroupName}
                            key={index}
                            item={item}
                            onClick={onClick}
                            chipSize={chipSize}
                        />
                    );
                })}
            {chipsLengthLimitedTo && chipsLengthLimitedTo < items.length ? (
                <WeChip
                    item="..."
                    chipSize={chipSize}
                    chipsGroupName={chipsGroupName}
                />
            ) : null}
        </Box>
    );
};

WeChipsList.objectToWeChipItems = (obj = {}): WeChipItem[] => {
    return Object.entries(obj).map(([label, value]) =>
        value ? { label, value } : null,
    );
};

WeChipsList.labelLinkToWeChipItems = (arr: any): WeChipItem[] => {
    return arr.map((element) => {
        return {
            label: element.label,
            value: element.link,
        };
    });
};
