import { useInjection } from '../ioc/hooks';
import { COMMON_SERVICES } from '../common_services';
import { AnalyticsService } from './AnalyticsService';
import { AnalyticsProviderInterface } from './AnalyticsProviderInterface';

export const useAnalytics = (
    service = COMMON_SERVICES.AnalyticsService,
): AnalyticsProviderInterface => {
    const analyticsService = useInjection<AnalyticsService>(service);
    return {
        pageView: analyticsService.pageView,
        event: analyticsService.event,
        exception: analyticsService.exception,
    } as AnalyticsProviderInterface;
};
