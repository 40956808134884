import { EventSubscriberInterface, Injectable } from '@weco/common';
import { APP_EVENTS } from '../../../app_events';
import { AuthEvent } from '../../events/AuthEvent';

@Injectable()
export class WeCoLoggedInEventSubscriber implements EventSubscriberInterface {
    subscribedEvents: Map<string | RegExp, (event: any) => void> = new Map();

    private weCoLoggedInRedirectKey = 'wlr';

    constructor() {
        this.subscribedEvents.set(
            APP_EVENTS.AUTH_USER_TOKEN_CHANGED,
            this.setCookie,
        );
    }

    private setCookie = (event: AuthEvent) => {
        const dc = event.subject.isAuthenticated()
            ? this.generateCookieString()
            : this.generateDeleteCookieString();
        console.log(dc);
        document.cookie = dc;
    };

    private generateCookieString() {
        return `${
            this.weCoLoggedInRedirectKey
        }=${WeCoLoggedInEventSubscriber.getRedirectUrl()}; domain=${WeCoLoggedInEventSubscriber.getMainDomain()}; Path=/;`;
    }

    private generateDeleteCookieString() {
        return `${this.generateCookieString()} Expires=${new Date().toUTCString()};`;
    }

    private static getRedirectUrl() {
        return `${window.location.origin}/dashboard`;
    }

    private static getMainDomain() {
        return window.location.hostname.split('.').slice(-2).join('.');
    }
}
