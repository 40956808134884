import React from 'react';
import clsx from 'clsx';

import { Typography, Box, Radio, RadioGroup } from '@material-ui/core';
import { useItemStyles } from './styles/quizStyles';
import { observer } from 'mobx-react';
import { TestAnswerEntity } from '@weco/core';

export interface QuizRowProps {
    item: any;
    answer: TestAnswerEntity;
    setAnswer: any;
}

export const QuizRow = observer(({ item, answer, setAnswer }: QuizRowProps) => {
    const itemClasses = useItemStyles();

    const handleChange = (e) => {
        setAnswer(parseInt(e.target.value, 10));
    };

    return (
        <div key={item.start} className="question-item">
            {item.question && !!item.isMain && (
                <Typography
                    variant="h6"
                    align="center"
                    color="textPrimary"
                    component="p"
                    className={itemClasses.questionText}
                >
                    {item.question}
                </Typography>
            )}
            <Box display="flex" justifyContent="space-between">
                <Typography component="span" color="textSecondary">
                    {item.start}
                </Typography>
                <Typography
                    component="span"
                    color="textPrimary"
                    className={itemClasses.questionSmallText}
                >
                    {!item.isMain && item.question}
                </Typography>
                <Typography component="span" color="textSecondary">
                    {item.end}
                </Typography>
            </Box>
            <RadioGroup
                aria-label="answer"
                name="answer"
                value={answer.option}
                onChange={handleChange}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    className={itemClasses.radioGroup}
                >
                    {item.points.map((point, i) => (
                        <Box className={itemClasses.radioBox} key={point}>
                            <Radio
                                className={itemClasses.radio}
                                icon={<span className={itemClasses.icon} />}
                                checkedIcon={
                                    <span
                                        className={clsx(
                                            itemClasses.icon,
                                            itemClasses.checkedIcon,
                                        )}
                                    />
                                }
                                name="answer"
                                value={point}
                            />
                        </Box>
                    ))}
                </Box>
            </RadioGroup>
        </div>
    );
});
