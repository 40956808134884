import classNames from 'classnames';
import React, { memo } from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AnimatedArrows from '../assets/animated_arrows_pointing_bottom.gif';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        maxWidth: 370,
        width: '100%',
        margin: '0 auto',
        display: 'block',
    },
}));

export interface AnimatedArrowsPointingToSomethingProps {
    additionalText?: string;
    className?: string;
}

const AnimatedArrowsPointingToSomething = memo(
    (props: AnimatedArrowsPointingToSomethingProps) => {
        const classes = useStyles();
        const rootClasses = classNames(classes.root, props.className);

        return (
            <img
                src={AnimatedArrows}
                className={rootClasses}
                alt="Animated arrows pointing."
            />
        );
    },
);

AnimatedArrowsPointingToSomething.displayName =
    'AnimatedArrowsPointingToSomething';

export { AnimatedArrowsPointingToSomething };
