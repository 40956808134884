import RoomHeaderContainer from '../containers/RoomHeaderContainer';
import RoomMessagesContainer from '../containers/RoomMessagesContainer';
import classNames from 'classnames';
import {
    Box,
    createStyles,
    Theme,
    WithStyles,
    withStyles,
} from '@material-ui/core';
import CreateMessageContainer from '../containers/CreateMessageContainer';
import React, { useState } from 'react';
import firebase from 'firebase';
import { OnAvatarClickInterface } from '@weco/chat';
import VisibilitySensor from 'react-visibility-sensor';
import { SkillSetsEntity } from '@weco/core';

const styles = (theme: Theme) =>
    createStyles({
        roomWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            paddingTop: 20,
        },
        roomWrapperWithFixedFooter: {
            paddingBottom: 64,
        },
        roomFooterFixed: {
            position: 'fixed',
            zIndex: 99,
            bottom: 0,
            margin: 'auto',
            backgroundColor: theme.palette.common.white,
        },
    });

interface ChatRoomWrapperProps extends WithStyles {
    firestore: firebase.firestore.Firestore;
    onAvatarClick: OnAvatarClickInterface;
    findUserByName: Function;
    addUserToRoom: Function;
    getSkillSetById(id: number): Promise<SkillSetsEntity>;
}

const ChatRoomWrapper = ({
    classes,
    firestore,
    onAvatarClick,
    addUserToRoom,
    findUserByName,
    getSkillSetById,
}: ChatRoomWrapperProps) => {
    const [isActionAreaVisible, toggleActionAreaVisibility] = useState(false);
    return (
        <Box
            className={classNames({
                [classes.roomWrapper]: true,
                [classes.roomWrapperWithFixedFooter]: !isActionAreaVisible,
            })}
            id="chat-window-box"
        >
            <RoomHeaderContainer
                firestore={firestore}
                addUserToRoom={addUserToRoom}
                findUserByName={findUserByName}
                onAvatarClick={onAvatarClick}
            />
            <RoomMessagesContainer
                firestore={firestore}
                onAvatarClick={onAvatarClick}
                getSkillSetById={getSkillSetById}
            />
            <VisibilitySensor
                partialVisibility
                onChange={toggleActionAreaVisibility}
            >
                <div style={{ opacity: 0, marginTop: 'auto' }}>
                    This is a placeholder for visibility sensor.
                </div>
            </VisibilitySensor>
            {document.getElementById('chat-window-box')?.clientWidth && (
                <Box
                    className={classNames({
                        [classes.roomFooterFixed]: !isActionAreaVisible,
                    })}
                    style={{
                        width:
                            isActionAreaVisible === false &&
                            `${
                                document.getElementById('chat-window-box')
                                    ?.clientWidth
                            }px`,
                    }}
                >
                    <CreateMessageContainer firestore={firestore} />
                </Box>
            )}
        </Box>
    );
};

export default withStyles(styles)(ChatRoomWrapper);
