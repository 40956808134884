import classNames from 'classnames';
import React, { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, IconButton, Theme } from '@material-ui/core';
import debug from 'debug';
import { SpriteSvgIcon } from '../molecules';
import { openChat } from '../../../../../apps/client/src/app/helpers/utils';
import { UserProfileEntity } from '@weco/core';

const log = debug('ProjectsDrawer');

const useStyles = makeStyles((theme: Theme) => ({
    customDrawer: {
        '& :first-child': {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
        },
        '& :first-child *': {
            borderTopRightRadius: 'initial',
            borderTopLeftRadius: 'initial',
        },
        '& :last-child': {
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        '& :last-child *': {
            borderBottomRightRadius: 'initial',
            borderBottomLeftRadius: 'initial',
        },
    },
    customDrawerElement: {
        padding: '15px',
        background: '#edf3fe',
        margin: '2px',
        minWidth: '260px',
    },
    title: {
        color: '#2d2963',
        fontWeight: 'bold',
        fontSize: '15px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
    },
    layoutToBreakChildStylesInheritance: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    itemsRow: {
        '&:first-child': {
            marginTop: '15px',
        },
        display: 'flex',
        padding: '5px',
        justifyContent: 'space-between',
    },
    itemField: {
        minWidth: '100px',
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    personField: {
        fontStyle: 'italic',
    },
    opened: {
        opacity: 1,
        transition: 'visibility 0s, opacity 0.3s ease-in-out',
        visibility: 'visible',
        height: 'auto',
    },
    closed: {
        opacity: 0,
        transition: 'visibility 0.1s, opacity 0.3s',
        visibility: 'hidden',
        height: 0,
        overflow: 'hidden',
    },
    chevron: {
        display: 'inline-block',
        borderRight: '2px solid black',
        borderBottom: '2px solid black',
        width: '10px',
        height: '10px',
        transform: 'rotate(-45deg)',
        transition: 'transform 0.2s ease-in-out',
    },
    rotated: {
        transform: 'rotate(-135deg)',
    },
    chatBubble: {
        padding: '8px',
        cursor: 'pointer',
        marginTop: '-15px',
    },
}));

class ToggleState {
    type: string;
    value: boolean;
}

const Component = ({
    sections,
    createRoom,
    currentUserId,
    history,
    roomType,
}) => {
    log('sections: %O', sections);

    const cx = useStyles();
    const [sectionsToggleState, setSectionsToggleState] = useState<
        ToggleState[]
    >(sections.map((section) => ({ type: section?.title, value: false })));
    const [loading, setLoading] = useState<{
        projectName: string;
        loading: boolean;
    }>({ projectName: null, loading: false });

    const toggleSection = (sectionTitle) => {
        const updated = sectionsToggleState.map((item: ToggleState) => {
            if (item.type === sectionTitle) {
                item.value = !item.value;
            }
            return item;
        });
        setSectionsToggleState(updated);
    };

    const detectIsSectionOpened = (section) => {
        return sectionsToggleState.find(
            (state) => state.type === section?.title,
        ).value;
    };

    const openChatHandler = (
        projectName: string,
        profile: UserProfileEntity,
    ) => {
        setLoading({
            projectName,
            loading: true,
        });
        openChat({
            createRoom,
            history,
            id: profile.id,
            currentUserId: currentUserId,
            profile,
        }).then(() => {
            setLoading({
                projectName,
                loading: false,
            });
        });
    };

    return (
        <Box className={cx.customDrawer}>
            {sections.map((section) => {
                return (
                    <Box
                        className={cx.customDrawerElement}
                        key={section?.title}
                    >
                        <Box
                            className={cx.title}
                            onClick={() => toggleSection(section?.title)}
                        >
                            <Box
                                className={
                                    cx.layoutToBreakChildStylesInheritance
                                }
                            >
                                <Box>
                                    {section.title} ({section.items.length})
                                </Box>
                                <Box
                                    className={classNames({
                                        [cx.chevron]: true,
                                        [cx.rotated]: detectIsSectionOpened(
                                            section,
                                        ),
                                    })}
                                />
                            </Box>
                        </Box>
                        <Box
                            className={classNames({
                                [cx.opened]: detectIsSectionOpened(section),
                                [cx.closed]: !detectIsSectionOpened(section),
                            })}
                        >
                            {section.items.map((item) => {
                                return (
                                    <Box
                                        className={cx.itemsRow}
                                        key={item.project.name}
                                    >
                                        <Box className={cx.itemField}>
                                            {item.project.name}
                                        </Box>
                                        <Box
                                            className={classNames({
                                                [cx.itemField]: true,
                                                [cx.personField]: true,
                                            })}
                                        >
                                            by {item.project.owner.name}
                                        </Box>
                                        <IconButton
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            className={cx.chatBubble}
                                            onClick={() =>
                                                openChatHandler(
                                                    item.project.name,
                                                    item.owner,
                                                )
                                            }
                                        >
                                            {loading.loading &&
                                                loading.projectName ===
                                                    item.project.name && (
                                                    <CircularProgress
                                                        size={24}
                                                    />
                                                )}
                                            {loading.projectName !==
                                                item.project.name && (
                                                <SpriteSvgIcon id="MainNavIcon-chat-custom" />
                                            )}
                                        </IconButton>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export const ProjectsDrawer = memo(Component);
