import { ApolloProvider } from '@apollo/react-hooks';
import { MuiThemeProvider } from '@material-ui/core';
import { useInjection } from '@weco/common';
import { CORE_SERVICES } from '@weco/core';
import 'mobx-react-lite/batchingForReactDom';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import './app.scss';
import 'flag-icon-css/css/flag-icon.css';
import { SplashScreenLoader } from './components/molecules/SplashScreenLoader';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { AppRoutes } from './router/AppRoutes';
import { RootStore } from './store/RootStore';
import { MainTheme } from './theme/MainTheme';
import { Notifier } from '@weco/ui';

/*
 * Note: The corresponding styles are in the ./${fileName}.${style} file.
 */

export const App = ({ ioc, rootStore }) => {
    const [isLoaded, setIsLoaded] = useState((rootStore as RootStore).isLoaded);
    const apolloClient = useInjection(CORE_SERVICES.ApolloAuthorizedClient);
    useEffect(() => {
        rootStore
            .bootstrap()
            .then(() => {
                setIsLoaded((rootStore as RootStore).isLoaded);
            })
            .catch((e) => {
                // todo: maybe should process bootstrap errors here
                setIsLoaded((rootStore as RootStore).isLoaded);
            });
    }, [rootStore]);

    return (
        <MuiThemeProvider theme={MainTheme}>
            <SnackbarProvider maxSnack={3}>
                <Notifier />
                <ApolloProvider client={apolloClient}>
                    <div className="weco-app" data-testid="app-container">
                        {isLoaded && (
                            <ErrorBoundary>
                                <AppRoutes />
                            </ErrorBoundary>
                        )}
                        {!isLoaded && <SplashScreenLoader />}
                    </div>
                </ApolloProvider>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};

export default App;
