import { CircularProgress, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { RouterPaths } from '../../router/RouterPaths';
import { useCurrentAuthData } from '../../store/hooks/useCurrentAuthData';
import { isEmpty } from 'lodash';
import { CurrentUserProfileContainer } from '../../store/containers/CurrentUserProfileContainer';

/**
 * This page is handling callback URL from Social auth services.
 *
 * One of the reason in particular, is to redirect user back to onboarding
 * page after successfull sign up to finish onboarding process.
 */
export const AuthCallbackPage = memo(
    observer(() => {
        const history = useHistory();
        const { user } = useCurrentAuthData();

        if (isEmpty(user)) {
            history.replace(`${RouterPaths.onboarding}/${0}`);
            return null;
        }

        return (
            <CurrentUserProfileContainer>
                {({ profile }) => {
                    if (profile.hasFinishedOnboarding) {
                        history.replace(RouterPaths.dashboard);
                    } else {
                        history.replace(`${RouterPaths.onboarding}/${1}`);
                    }

                    return (
                        <Grid container justify="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    );
                }}
            </CurrentUserProfileContainer>
        );
    }),
);
