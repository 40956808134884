export const formatDateTime = (date: Date): string => {
    const now = new Date();
    if (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
    ) {
        return formatTime(date);
    }
    return formatDate(date);
};

export const formatDate = (date: Date): string => {
    const now = new Date();
    if (
        date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
    ) {
        return 'Today';
    }
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
        -2,
    )}-${`0${date.getDate()}`.slice(-2)}`;
};

export const formatTime = (date: Date): string => {
    return `${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
        -2,
    )}`;
};
