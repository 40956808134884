import React from 'react';
import {
    DatePicker as MuiDatePicker,
    DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';
import { FieldProps, getIn } from 'formik';
import { createErrorHandler } from './errorHandler';

/**
 * NOTE: if you want to make this component work,
 * you need to wrap applicaiton in <MuiPickersUtilsProvider utils={DateFnsUtils} />
 * SEE: MUI docs for date time picker.
 */

export interface WeDatePickerProps
    extends FieldProps,
        Omit<MuiDatePickerProps, 'name' | 'value' | 'error'> {}

export const fieldToDatePicker = ({
    field,
    form: { isSubmitting, touched, errors, setFieldValue, setFieldError },
    disabled,
    ...props
}: WeDatePickerProps): MuiDatePickerProps => {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        ...props,
        ...field,
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled != undefined ? disabled : isSubmitting,
        onChange(date) {
            props.onChange
                ? props.onChange(date)
                : setFieldValue(field.name, date);
        },
        onError: createErrorHandler(fieldError, field.name, setFieldError),
    };
};

export const WeDatePicker = ({ children, ...props }: WeDatePickerProps) => {
    return (
        <MuiDatePicker {...fieldToDatePicker(props)}>{children}</MuiDatePicker>
    );
};
