import { Box, Button, CircularProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { memo } from 'react';
import { Hidden } from './Hidden';

const useStyles = makeStyles((theme: Theme) => {
    const primaryColors = theme.palette.primary;
    return {
        root: {
            padding: 10,
            margin: '20px 0',
            width: '100%',
            fontWeight: 500,
            background: `linear-gradient(101.02deg, ${primaryColors[475]} -0.1%, ${primaryColors[550]} 118.38%), linear-gradient(86.51deg, ${primaryColors[450]} 13.51%, ${primaryColors[480]} 72.7%)`,
            '&:focus': {
                background: `linear-gradient(180deg, ${primaryColors[475]} 0%, ${primaryColors[550]} 100%)`,
            },
            '&:hover': {
                background: `linear-gradient(180deg, ${primaryColors[475]} 0%, ${primaryColors[550]} 100%)`,
            },
        },
    };
});

export interface ActionButtonProps {
    label: string;
    className?: string;
    onClick: () => void;
    isLoading?: boolean;
    size?: 'small' | 'medium' | 'large';
}

const ActionButton = memo((props: ActionButtonProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return (
        <Button
            className={rootClasses}
            onClick={props.onClick}
            disabled={props.isLoading}
            size={props.size || 'medium'}
        >
            <Hidden when={props.isLoading}>{props.label}</Hidden>
            <Hidden when={!props.isLoading}>
                <Box display="flex">
                    <CircularProgress size="30px" color="secondary" />
                </Box>
            </Hidden>
        </Button>
    );
});

ActionButton.displayName = 'ActionButton';

export { ActionButton };
