import React, { useEffect, useState } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    scrollTop: {
        zIndex: 999,
        position: 'fixed',
        bottom: '3vh',
        backgroundColor: '#c3c1c1',
        color: 'black',
        right: '4%',
        '&:hover, &.Mui-focusVisible': {
            transition: '0.3s',
            color: 'black',
            backgroundColor: 'grey',
        },
    },
}));

const ScrollTop = ({ showBelow }: { showBelow: number }) => {
    const classes = useStyles();
    const [show, setShow] = useState(!showBelow);

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            !show && setShow(true);
        } else {
            show && setShow(false);
        }
    };

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: `smooth` });
    };

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll);
            return () => window.removeEventListener(`scroll`, handleScroll);
        }
    });

    return (
        <div>
            {show && (
                <IconButton onClick={handleClick} className={classes.scrollTop}>
                    <ExpandLess />
                </IconButton>
            )}
        </div>
    );
};

export default ScrollTop;
