import { ProjectEntity } from '../entity';
import { Inject, Injectable, Transformers } from '@weco/common';
import { CORE_SERVICES } from '../core_services';
import { SearchParams } from '../entity';
import { coreConstants } from '../constants/coreConstants';
import { ProjectConstants } from '../types';
import {
    CurrentUserProvider,
    IProjectRepository,
    SkillSetRepositoryInterface,
    SchoolsRepositoryInterface,
    ProfileItemInterface,
} from '../repositories';
import { MatchPercentServiceInterface } from './MatchPercentService';
import { SkillsService } from './SkillsService';
import { cloneDeep } from 'lodash';

export interface ProjectItemInterface extends ProjectEntity {
    matchPercent: number;
}

@Injectable()
export class ProjectsService {
    public async updateItem(profile: ProjectEntity): Promise<ProjectEntity> {
        /** @todo refactor it, move on store\form scope */
        let profileInstance = profile;
        if (!(profile instanceof ProjectEntity))
            profileInstance = new Transformers.ToTypeTransformer<ProjectEntity>(
                ProjectEntity,
            ).transform(profile);
        return this.repository.updateItem(profileInstance);
    }

    public async loadItem(id: string): Promise<ProjectEntity> {
        return this.repository.getItem(id);
    }

    @Inject(CORE_SERVICES.IProjectRepository)
    private repository: IProjectRepository;

    @Inject(CORE_SERVICES.ICurrentUserProvider)
    currentUser: CurrentUserProvider;

    @Inject(CORE_SERVICES.MatchPercentServiceInterface)
    percentRepository: MatchPercentServiceInterface;

    @Inject(CORE_SERVICES.SkillSetRepositoryInterface)
    skillSetsRepository: SkillSetRepositoryInterface;

    @Inject(SkillsService)
    skillSetsService: SkillsService;

    // @Inject(CORE_SERVICES.SchoolsRepositoryInterface)
    // schoolsRepository: SchoolsRepositoryInterface;

    public async getMatchPercent(projectId): Promise<number> {
        return this.percentRepository.matchProjectPercent(projectId);
    }

    public async search({
        filter,
        advancedFilter,
        limit,
    }: SearchParams): Promise<{
        projects: ProjectItemInterface[];
        total: number;
    }> {
        const data = await this.repository.getMatchList({
            filter: filter,
            advancedFilter: advancedFilter,
            limit: limit,
        });

        return {
            projects: data.projects, // await this.injectMatchPercent(data.projects),
            total: data.total,
        };
    }

    public async getMyOwnProjectsList(
        limit?: number,
    ): Promise<ProjectEntity[]> {
        return this.repository.loadMyOwnProjects(limit);
    }

    public async getPersonProjectsList(
        personId: string,
        limit?: number,
    ): Promise<ProjectItemInterface[]> {
        let projects = await this.repository.loadPersonProjects(
            personId,
            limit,
        );
        // projects = await this.injectSkillSets(projects);
        return projects; // this.injectMatchPercent(projects);
    }

    private async injectMatchPercent(projects) {
        const arr: ProjectItemInterface[] = await Promise.all(
            projects.map(async (item) => {
                const matchPercent = await this.percentRepository.matchProjectPercent(
                    item,
                );
                const injectedPercent: ProjectItemInterface = {
                    ...item,
                    matchPercent,
                };
                return injectedPercent;
            }),
        );

        return Promise.resolve(arr);
    }

    private async injectSkillSets(projects: ProjectEntity[]) {
        const arr: ProjectEntity[] = await Promise.all(
            projects.map(async (item) => {
                const skillSets = await this.skillSetsRepository.loadSkillSets({
                    projectId: item.id,
                });
                item.skillSets = skillSets;
                return item;
            }),
        );

        return Promise.resolve(arr);
    }

    public async changeMyActiveProject(newProjectId: string): Promise<void> {
        return this.repository.changeMyActiveProject(newProjectId);
    }

    public async createProject(
        name: string,
    ): Promise<{ id: string; name: string }> {
        return this.repository.createProject(name).then(async (data) => {
            const defaultRoles = await this.skillSetsService.loadDefaultsSkillSets();
            defaultRoles.forEach(async (i) => {
                const newItem = cloneDeep(i);
                newItem.id = null;
                newItem.projectId = data.id;
                newItem.isSelected = true;
                // todo: this call rise a lot unneccessary requests into the backend
                await this.skillSetsService.addSkillSets(newItem);
            });
            return data;
        });
    }

    public async deleteProject(id: string): Promise<any> {
        return this.repository.deleteProject(id);
    }

    public async getConstants(): Promise<ProjectConstants> {
        const { profile, project, ...rest } = coreConstants;
        // const schools = await this.schoolsRepository.getMatchList();
        return { ...rest, ...project };
    }
    // todo: we need to refactor it. move all logic from repositories regarding "my" and filters to service\store level
    /**
     * @deprecated use loadProjectsByPersonId
     */
    public async loadAllMyProjects(
        limit?: number,
        greed = false,
    ): Promise<ProjectEntity[]> {
        let projects = await this.repository.loadProjectsByPersonId(
            this.currentUser.UserId,
            { limit, greed },
        );
        // projects = await this.injectSkillSets(projects);
        return this.injectMatchPercent(projects);
    }

    public async loadProjectsByPersonId(
        id,
        options?: {
            limit?: number;
            greed?: boolean;
        },
    ): Promise<ProjectEntity[]> {
        let projects = await this.repository.loadProjectsByPersonId(
            id,
            options,
        );
        return projects; //this.injectMatchPercent(projects);
    }

    public applyToProject(projectId: string, roleId?: string): Promise<void> {
        return this.repository.applyToProject(projectId, roleId);
    }

    public async getPersonProjectConnection(
        personId: string,
        projectId: string,
    ): Promise<any> {
        if (!personId || !projectId) {
            return Promise.resolve(null);
        }
        return this.repository.getPersonProjectConnection(personId, projectId);
    }

    public async declineInvitation(
        projectId: string,
        roleId?: string,
    ): Promise<void> {
        return this.repository.declineInvitation(projectId, roleId);
    }

    public async acceptInvitation(
        projectId: string,
        roleId?: string,
    ): Promise<void> {
        return this.repository.acceptInvitation(projectId, roleId);
    }

    public async recallApplication(
        projectId: string,
        roleId?: string,
    ): Promise<void> {
        return this.repository.recallApplication(projectId, roleId);
    }

    public async declineApplication(
        personId: string,
        projectId: string,
        roleId?: string,
    ): Promise<void> {
        return this.repository.declineApplication(personId, projectId, roleId);
    }

    public async acceptApplication(
        personId: string,
        projectId: string,
        roleId?: string,
    ): Promise<void> {
        return this.repository.acceptApplication(personId, projectId, roleId);
    }

    public async leaveProject(projectId: string): Promise<void> {
        return this.repository.leaveProject(projectId);
    }

    public inviteToProject(
        personId: string,
        projectId: string,
        roleId?: string,
    ): Promise<void> {
        return this.repository.inviteToProject(personId, projectId, roleId);
    }

    public removeProjectMember(
        personId: string,
        projectId: string,
    ): Promise<void> {
        return this.repository.removeProjectMember(personId, projectId);
    }

    public recallInvitation(
        personId: string,
        projectId: string,
        roleId?: string,
    ): Promise<void> {
        return this.repository.recallInvitation(personId, projectId, roleId);
    }
}
