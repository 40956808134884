import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useControlPanelStyles = makeStyles((theme: Theme) => ({
    infoBanner: {
        width: '100%',
        background: '#F2F0FE',
        color: theme.palette.info[700],
        fontWeight: 500,
        textAlign: 'center',
        padding: '5px 15px',
    },
    infoBannerText: {
        fontWeight: 600,
    },
    nameEditable: {
        margin: '0 auto',
    },
    name: {
        textAlign: 'center',
        color: theme.palette.common.white,
        width: '100%',
    },
    favoriteIcon: {
        color: theme.palette.secondary[200],
        padding: 5,
    },
    root: {
        width: '100%',
        // height: '270px',
        padding: '30px 20px 0',
        position: 'relative',
        background: theme.palette.info[50],
        '& .MuiSkeleton-root': {
            backgroundColor: theme.palette.grey[400],
        },
    },
    shader: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#000',
        opacity: '.4',
        width: '100%',
        height: '100%',
    },
    main: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        color: theme.palette.common.white,
    },
    topBar: {
        top: 0,
        left: 0,
        width: '100%',
        height: '50%',
        display: 'flex',
        alignItems: 'flex-start',
        padding: 0,
        color: theme.palette.common.white,
    },
    settings: {
        position: 'absolute',
        zIndex: 1,
        left: 10,
        top: 17,
        minWidth: '50px',
    },
    bottomBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        padding: '5px 0px 15px 0px',
        color: theme.palette.secondary[650],
        position: 'relative',
    },
    nameEditRoot: {
        position: 'initial',
    },
    editFieldsBox: {
        width: '100%',
        padding: '0 70px 0 30px',
    },
    nameChildBox: {
        maxWidth: '100%',
    },
    chatIcon: {
        color: theme.palette.secondary[200],
        padding: '8px',
    },
    iconRight: {
        position: 'absolute',
        right: 25,
        top: 20,
    },
    bottomControlBox: {
        marginLeft: 'auto',
        display: 'flex',
    },
    bottomForumBox: {
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    matchPercentage: {
        top: '-15px',
    },
    shareButtonWrapper: {
        transform: 'translate(0, 60px)',
        zIndex: 9,
    },
    iconPlaceholder: {
        width: 20,
    },
    itemText: {
        color: theme.palette.common.white,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        marginRight: 40,
        width: '100%',
        wordBreak: 'break-word',
    },
}));
