import React from 'react';
import {
    ProjectProfileStore,
    ProjectProfileStoreInterface,
} from './ProjectProfileStore';
import { SingletonStoreContainer } from 'apps/client/src/app/store/containers/SingletonStoreContainer';
import { RouterPaths } from '../../../../router/RouterPaths';
import { useHistory } from 'react-router';

export interface ProjectProfileContainerProps {
    children: any;
    itemId: string;
}

export const ProjectProfileContainer = ({
    children,
    itemId,
}: ProjectProfileContainerProps) => {
    const history = useHistory();
    const loader = (store: ProjectProfileStoreInterface) =>
        store.loadItem(itemId, () =>
            history.push({
                pathname: `${RouterPaths.notFound}`,
                state: 'Project',
            }),
        );
    return (
        <SingletonStoreContainer
            loader={loader}
            classType={ProjectProfileStore}
        >
            {children}
        </SingletonStoreContainer>
    );
};
