import React from 'react';
import { Chip, ChipProps, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { TypeValueInterface } from '@weco/core';
import { WeChip } from './WeChip';
import { IconByType } from './IconByType';
import { FundingSvgIcon } from './FundingSvgIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export interface WeFundingItem {
    value?: any;
    label: string;
    isActive?: boolean;
    chipProps?: ChipProps;
}

export interface WeFundingListProps {
    items: WeFundingItem | TypeValueInterface[];
    onClick?: (item: string) => void;
    // TODO this need to be removed. Not used anywhere.
    name?: string;
}

export const WeFundingList = ({ items, onClick, name }: WeFundingListProps) => {
    if (items === null) return null;
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {[]
                .concat(items || [])
                .filter((v) => !!v)
                .map((v, i) => {
                    if (
                        !v ||
                        (v && typeof v !== 'string' && v.type === undefined)
                    ) {
                        console.error('WeChipList label empty');
                        return null;
                    }
                    return (
                        <FundingSvgIcon item={v} onClick={onClick} key={i} />
                    );
                })}
        </Box>
    );
};
