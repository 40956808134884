export const departmentColors = {
    hr: '#C901CB',
    ideas: '#C63125',
    legal: '#7D26D0',
    itweb: '#0143C2',
    finance: '#44B74F',
    strategy: '#FF9D01',
    marketing: '#FFCD01',
    execution: '#048DF2',
};
