import size from 'lodash/size';
import classNames from 'classnames';
import React, { memo } from 'react';
import { BottomSheet } from './BottomSheet';
import { ActionButton } from './ActionButton';
import useToggle from 'react-use/lib/useToggle';
import { BigNumberCard } from './BigNumberCard';
import { makeStyles } from '@material-ui/styles';
import { PeopleListCompressed } from './PeopleListCompressed';
import { Box, Grid, Theme, GridProps, Typography } from '@material-ui/core';
import { ProjectEntity, SkillSetsEntity, UserProfileEntity } from '@weco/core';
import { AnimatedArrowsWithAdditionalText } from './AnimatedArrowsWithAdditionalText';
import { PositionsListSeparatedByDepartments } from './PositionsListSeparatedByDepartments';
import { isEmpty } from 'lodash';
import { PeopleList } from './PeopleList';
import { WeMultilineText } from '../molecules';
import { AnimatedArrowsPointingToSomething } from './AnimatedArrowsPointingToSomething';
import { RouterPaths } from '../../../../../apps/client/src/app/router/RouterPaths';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 30,
    },
    placeholderTextBox: {
        maxWidth: 300,
        margin: '0 auto',
        marginBottom: 300,
    },
    actionButton: {
        position: 'fixed',
        bottom: '20px',
        width: '200px',
        zIndex: 9,
        left: '50%',
        transform: 'translate(-50%, 0)',
    },
    cardBox: {
        minWidth: '150px',
    },
}));

export interface MyProjectTeamOverviewProps {
    isLoading: boolean;
    className?: string;
    project: ProjectEntity;
    currentUserId: string;
    team: UserProfileEntity[];
    roles: SkillSetsEntity[];
    actionButtonLabel: string;
    onActionButtonClick: () => void;
    matchSkillSetsWith: string[];
    peopleInvitedToProject: UserProfileEntity[];
    peopleAppliedToProject: UserProfileEntity[];
    // TODO remove this functions?
    onCheckBoxClick: (position: SkillSetsEntity) => void;
    onEditButtonClick: (position: SkillSetsEntity) => void;
    onDeleteBtnClick: (position: SkillSetsEntity) => void;
}

const numberCardGridSize = {
    xs: 6,
    md: 6,
    item: true,
} as GridProps;

const MyProjectTeamOverview = memo((props: MyProjectTeamOverviewProps) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);
    const history = useHistory();

    const [isTeamPanelOpen, toggleTeamPanel] = useToggle(false);
    const [isRolesPanelOpen, toggleRolesPanel] = useToggle(false);
    const [isInvitedPeoplePanelOpen, toggleInvitedPeoplePanel] = useToggle(
        false,
    );
    const [isAppliedPeoplePanelOpen, toggleAppliedPeoplePanel] = useToggle(
        false,
    );

    const numberOfMembers = size(
        props.team.filter((i) => i.id !== props.currentUserId),
    );
    const numberOfSelectedRoles = size(props.roles.filter((i) => i.isSelected));
    const numberOfRoles = size(props.roles);
    const numberOfAppliedPeople = size(props.peopleAppliedToProject);
    const numberOfInvitedPeople = size(props.peopleInvitedToProject);

    // make sure owner is first in the list
    !isEmpty(props.team) &&
        props.team.unshift(
            props.team?.splice(
                props.team.findIndex(
                    (item) => item.id === props.project?.owner?.id,
                ),
                1,
            )[0],
        );

    const redirectToSearch = () => {
        history.push(RouterPaths.search.root);
    };

    return (
        <Box className={rootClasses}>
            <Grid container spacing={3} justify="center">
                <Grid {...numberCardGridSize} className={classes.cardBox}>
                    <BigNumberCard
                        title="Required Roles"
                        number={numberOfSelectedRoles}
                        isLoading={props.isLoading}
                        onClick={toggleRolesPanel}
                        isThisMyEntity
                    />
                </Grid>
                <Grid {...numberCardGridSize} className={classes.cardBox}>
                    <BigNumberCard
                        title="Team"
                        number={numberOfMembers}
                        onClick={
                            numberOfMembers === 0
                                ? redirectToSearch
                                : toggleTeamPanel
                        }
                        isLoading={props.isLoading}
                        isThisMyEntity
                    />
                </Grid>
                <Grid {...numberCardGridSize} className={classes.cardBox}>
                    <BigNumberCard
                        title="Applied"
                        subtitle="to join your project"
                        isLoading={props.isLoading}
                        number={numberOfAppliedPeople}
                        onClick={toggleAppliedPeoplePanel}
                    />
                </Grid>
                <Grid {...numberCardGridSize} className={classes.cardBox}>
                    <BigNumberCard
                        title="Invited"
                        subtitle="to join your project"
                        isLoading={props.isLoading}
                        number={numberOfInvitedPeople}
                        onClick={toggleInvitedPeoplePanel}
                    />
                </Grid>
            </Grid>
            <BottomSheet
                title="Project Roles"
                isOpen={isRolesPanelOpen}
                onClose={toggleRolesPanel}
            >
                <Box>
                    {numberOfRoles > 0 && (
                        <PositionsListSeparatedByDepartments
                            items={props.roles}
                            isCheckBoxDisplayed={true}
                            isEditButtonDisplayed={true}
                            onVisibilitySwitch={props.onCheckBoxClick}
                            onEditButtonClick={props.onEditButtonClick}
                            matchSkillSetsWith={props.matchSkillSetsWith}
                            onDeleteBtnClick={props.onDeleteBtnClick}
                        />
                    )}
                    {numberOfRoles <= 0 && (
                        <Box className={classes.placeholderTextBox}>
                            <Typography
                                align="center"
                                variant="body1"
                                color="textSecondary"
                            >
                                {props.project?.name} has no roles yet.
                            </Typography>
                        </Box>
                    )}
                </Box>
            </BottomSheet>
            <BottomSheet
                title="Team"
                isOpen={isTeamPanelOpen}
                onClose={toggleTeamPanel}
            >
                <Box>
                    {numberOfMembers > 0 ? (
                        <PeopleList
                            items={props.team}
                            currentUserId={props.currentUserId}
                            skillsSection
                        />
                    ) : (
                        <Box>
                            <Typography
                                align="center"
                                variant="body1"
                                color="textSecondary"
                            >
                                <WeMultilineText
                                    text={
                                        'Your project has no team. \nSearch for Candidates'
                                    }
                                />
                            </Typography>
                            <AnimatedArrowsPointingToSomething />
                        </Box>
                    )}
                    <ActionButton
                        className={classes.actionButton}
                        label={props.actionButtonLabel}
                        onClick={() => {
                            props.onActionButtonClick();
                            toggleTeamPanel();
                        }}
                    />
                </Box>
            </BottomSheet>
            <BottomSheet
                title="Invited"
                isOpen={isInvitedPeoplePanelOpen}
                onClose={toggleInvitedPeoplePanel}
            >
                <Box>
                    {numberOfInvitedPeople > 0 ? (
                        <PeopleList
                            items={props.peopleInvitedToProject}
                            currentUserId={props.currentUserId}
                            skillsSection
                        />
                    ) : (
                        <Box>
                            <Typography
                                align="center"
                                variant="body1"
                                color="textSecondary"
                            >
                                <WeMultilineText
                                    text={`Your project hasn't invited people. \nSearch for Candidates`}
                                />
                            </Typography>
                            <AnimatedArrowsPointingToSomething />
                        </Box>
                    )}
                    <ActionButton
                        className={classes.actionButton}
                        label={props.actionButtonLabel}
                        onClick={() => {
                            props.onActionButtonClick();
                            toggleInvitedPeoplePanel();
                        }}
                    />
                </Box>
            </BottomSheet>
            <BottomSheet
                title="Applied"
                isOpen={isAppliedPeoplePanelOpen}
                onClose={toggleAppliedPeoplePanel}
            >
                <Box>
                    {numberOfAppliedPeople > 0 ? (
                        <PeopleList
                            items={props.peopleAppliedToProject}
                            currentUserId={props.currentUserId}
                            skillsSection
                        />
                    ) : (
                        <Box>
                            <Typography
                                align="center"
                                variant="body1"
                                color="textSecondary"
                            >
                                <WeMultilineText
                                    text={`Your project has no applications. \nSearch for Candidates`}
                                />
                            </Typography>
                            <AnimatedArrowsPointingToSomething />
                        </Box>
                    )}
                    <ActionButton
                        className={classes.actionButton}
                        label={props.actionButtonLabel}
                        onClick={() => {
                            props.onActionButtonClick();
                            toggleAppliedPeoplePanel();
                        }}
                    />
                </Box>
            </BottomSheet>
        </Box>
    );
});

MyProjectTeamOverview.displayName = 'MyProjectTeamOverview';

export { MyProjectTeamOverview };
