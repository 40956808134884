import { useContext } from 'react';
import { InvalidConfigurationException } from '../../exceptions/exceptions';
import { AppContext } from '../AppContext';

export function useInjection<T = any>(identifier?: any): T {
    const { ioc } = useContext(AppContext);
    if (!ioc) {
        throw new InvalidConfigurationException(
            `Unable to locate DI Container`,
        );
    }
    return identifier ? ioc.get<T>(identifier) : (ioc as any);
}
