import { connect } from 'react-redux';
import RoomMessages from '../components/RoomMessages';
import { fetchMessages } from '../store/roomMessages/actions';
import firebase from 'firebase/app';
import { UserRoomInterface } from '../models/interfaces/UserRoomInterface';
import { updateUserRooms } from '../store/rooms/actions';
import MessageInterface from '../models/interfaces/MessageInterface';

const mapStateToProps = (state: any) => ({
    currentUser:
        state.chat.authUser.uid &&
        state.chat.users.list.items[state.chat.authUser.uid]
            ? state.chat.users.list.items[state.chat.authUser.uid]
            : null,
    users: state.chat.users.list.items,
    room: state.chat.rooms.list.items[state.chat.rooms.list.activeRoom] || null,
    roomsMessages: state.chat.roomMessages.messages,
    updatedRoomId: state.chat.roomMessages.updatedRoomId,
    loadedRoomsIds: state.chat.roomMessages.loadedRoomsIds,
});

const mapDispatchToProps = (dispatch: Function) => ({
    fetchMessages: (
        firestore: firebase.firestore.Firestore,
        roomId: string,
        lastTime: number,
    ) => dispatch(fetchMessages(firestore, roomId, lastTime)),
    updateUserRooms: (
        firestore: firebase.firestore.Firestore,
        userId: string,
        rooms: UserRoomInterface[],
    ) => dispatch(updateUserRooms(firestore, userId, rooms)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomMessages);
