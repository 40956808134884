export const filled = (object): boolean => {
    for (const key in object) {
        if (key === 'createdAt') {
            continue;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (!object[key] && object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};
