import classNames from 'classnames';
import React, { memo, ReactElement } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

/**
 * NOTE: see explanation on why this component
 * exists in component story file.
 */

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            minHeight: '80px',
            paddingBottom: '80px',
        },
    },
}));

interface Props {
    className?: string;
    children?: ReactElement;
}

const AvoidBottomBarInMobileBrowsers = memo((props: Props) => {
    const classes = useStyles();
    const rootClasses = classNames(classes.root, props.className);

    return <Box className={rootClasses}>{props.children}</Box>;
});

AvoidBottomBarInMobileBrowsers.displayName = 'AvoidBottomBarInMobileBrowsers';

export { AvoidBottomBarInMobileBrowsers };
