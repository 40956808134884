import firebase from 'firebase/app';
import * as actions from './actionCreators';
import UserInterface from '../../models/interfaces/UserInterface';
import { UserRoomInterface } from '../../models/interfaces/UserRoomInterface';
import { refToRefData } from '../../models/ReferenceData';
import { SearchUserInterface } from '../../models/interfaces/UsersInterface';

const userFromDocument = (
    doc: firebase.firestore.QueryDocumentSnapshot<UserInterface>,
): UserInterface => ({
    ...doc.data(),
    id: doc.id,
    rooms:
        doc && doc.data() && doc.data().rooms
            ? doc.data().rooms.map((userRoom) => ({
                  unreadCount: userRoom.unreadCount,
                  room: refToRefData(userRoom.room),
              }))
            : [],
});

export const fetchCurrentUser = (
    firestore: firebase.firestore.Firestore,
    id: string,
) => {
    return (dispatch: Function) => {
        firestore
            .collection('users')
            .doc(id)
            .onSnapshot(
                (doc: firebase.firestore.DocumentSnapshot<UserInterface>) => {
                    dispatch(actions.receiveListUser(userFromDocument(doc)));
                },
            );
    };
};

export const fetchUser = (
    firestore: firebase.firestore.Firestore,
    id: string,
) => {
    return (dispatch: Function) => {
        firestore
            .collection('users')
            .doc(id)
            .get()
            .then((doc: firebase.firestore.DocumentSnapshot<UserInterface>) => {
                dispatch(actions.receiveListUser(userFromDocument(doc)));
            });
    };
};

export const updateUserRooms = (
    userId: string,
    userRooms: UserRoomInterface[],
) => {
    return (dispatch: Function) => {
        dispatch(actions.updateUserRooms(userId, userRooms));
    };
};

export const searchUsers = (
    findUserByName: Function,
    q: string,
    exclude: string[],
) => {
    return (dispatch: Function) => {
        if (q.length) {
            findUserByName(q, exclude)
                .then((users: SearchUserInterface[]) => {
                    dispatch(actions.receiveSearchUsers(users));
                })
                .catch((err: Error) => {
                    console.error(err);
                    dispatch(actions.receiveSearchUsers([]));
                });
        } else {
            dispatch(actions.receiveSearchUsers([]));
        }
    };
};

export const clearSearchUsers = () => (dispatch: Function) => {
    dispatch(actions.clearSearchUsers());
};
