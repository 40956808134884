import React from 'react';
import { Chat, OnAvatarClickParametersInterface } from '@weco/chat';
import { useHistory, useRouteMatch } from 'react-router';
import { useChatData } from '../../store/hooks/useChatData';

export const ChatPage = () => {
    const {
        params: { id, type },
    } = useRouteMatch();
    const {
        token,
        chatUser,
        firestore,
        searchUsers,
        addUserToRoom,
        createRoom,
        getSkillSetById,
        tmpRoom,
    } = useChatData();
    const { push } = useHistory();
    const onAvatarClick = ({ type, id }: OnAvatarClickParametersInterface) => {
        push(`${type}/${id}`);
    };
    return (
        <Chat
            searchUsers={searchUsers}
            addUserToRoom={addUserToRoom}
            createRoom={createRoom}
            mbOpenRoom={{ id, type }}
            firestore={firestore}
            chatUserId={chatUser?.user?.uid || null}
            onAvatarClick={onAvatarClick}
            getSkillSetById={getSkillSetById}
            tmpRoom={tmpRoom}
            token={token}
        />
    );
};

ChatPage.URL = '/chat';
