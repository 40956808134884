import React from 'react';
import MuiTextField, {
    TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';
import { FieldProps, getIn } from 'formik';
import { makeStyles, Theme } from '@material-ui/core';

export interface WeTextFieldProps
    extends FieldProps,
        Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {}

export const fieldToTextField = ({
    disabled,
    field,
    defaultValue,
    form: { isSubmitting, touched, errors },
    ...props
}: WeTextFieldProps): MuiTextFieldProps => {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        ...props,
        ...field,
        value: field.value || defaultValue || '',
        error: showError,
        helperText: showError ? fieldError : props.helperText,
        disabled: disabled || isSubmitting,
        variant: props.variant,
    };
};
const weTextFieldStyles = makeStyles((theme: Theme) => ({
    /** @todo move styles to Theme for all inputs  */
    text: {
        '& input': {
            fontSize: '16px',
            color: '#BDBDBD',
        },
    },
}));
export const WeTextField = ({ children, ...props }: WeTextFieldProps) => {
    const classes = weTextFieldStyles();
    return (
        <MuiTextField
            fullWidth
            className={classes.text}
            {...fieldToTextField(props)}
            disabled={false}
        >
            {children}
        </MuiTextField>
    );
};
