import {
    AuthProviderInterface,
    AuthProviderSignupInterface,
    UserTokenInterface,
} from './common';
import { AnonymousUserToken } from './AnonymousUserToken';
import debug from 'debug';
import { RecentSearchLocalStorageKeys } from '../types/localStorageKeys';
import { ProviderNotFoundError } from './errors';
const log = debug('AuthService');

/**
 * @class Main Service do deal with user roles authentication
 */
export class AuthService {
    /**
     * List of available Auth providers
     */
    constructor(private providers: AuthProviderInterface[]) {}

    /**
     * Goes through all registered providers, finds the first one which supports given token
     * and tries to auth using it to authenticate
     * @param token
     * @return {Promise<UserTokenInterface|Error>}
     */
    async authenticate(token): Promise<UserTokenInterface | Error> {
        const provider = this.providers.find(
            (p) => p.support && p.support(token),
        );
        if (!provider) {
            throw new ProviderNotFoundError(`authenticate`);
        }
        return provider
            .authenticate(token)
            .then((newToken) => newToken)
            .catch((e) => Promise.reject(e));
    }

    async signup(token): Promise<UserTokenInterface | any> {
        const provider = this.providers.find(
            (p) => p.support && p.support(token),
        );
        if (!provider && !(provider as any).signup) {
            throw new ProviderNotFoundError(`signup`);
        }
        return (provider as AuthProviderSignupInterface).signup(token);
    }

    async resendSignUp(token): Promise<string> {
        const provider = this.providers.find(
            (p) => p.support && p.support(token),
        );
        if (!provider && !(provider as any).signup) {
            throw new ProviderNotFoundError(`resendSignUp`);
        }
        return (provider as AuthProviderSignupInterface).resendSignUp(token);
    }

    async forgotPassword(token): Promise<any> {
        const provider = this.providers.find(
            (p) => p.support && p.support(token),
        );
        if (!provider) {
            throw new ProviderNotFoundError(`completeNewPassword`);
        }
        return provider.forgotPassword(token);
    }

    async forgotPasswordSubmit(token): Promise<void> {
        const provider = this.providers.find(
            (p) => p.support && p.support(token),
        );
        if (!provider) {
            throw new ProviderNotFoundError(`forgotPasswordSubmit`);
        }
        return provider.forgotPasswordSubmit(token);
    }

    async logout(token?: UserTokenInterface): Promise<UserTokenInterface> {
        log('logout() called.');
        log('Clearing recent searches local storage data.');
        localStorage.removeItem(RecentSearchLocalStorageKeys.person);
        localStorage.removeItem(RecentSearchLocalStorageKeys.project);
        log('Logging out...');
        if (token) {
            const provider = this.providers.find(
                (p) => p.support && p.support(token),
            );
            if (!provider) {
                throw new ProviderNotFoundError(`logout`);
            }

            return provider.logout(token).then((newToken) => {
                return newToken;
            });
        }

        return Promise.all(
            // @ts-ignore
            this.providers.map((p: AuthProviderInterface) => p.logout()),
        )
            .then(() => new AnonymousUserToken())
            .catch(() => new AnonymousUserToken());
    }

    /**
     * Tries to restore user session using all registered providers one by one.
     * The first provider which will successfully be restored will be used.
     * @return {Promise<any>}
     */
    restore(): Promise<UserTokenInterface> {
        const promises = this.providers.map((p) => p.restore());
        // If a request fails, count that as a resolution so it will keep
        // waiting for other possible successes. If a request succeeds,
        // treat it as a rejection so Promise.all immediately bails out.
        return Promise.all(
            promises.map((p) =>
                p.then(
                    (val) => Promise.reject(val),
                    (err) => Promise.resolve(err),
                ),
            ),
        )
            .then(
                // If '.all' resolved, we've just got an array of errors.
                (errors) => Promise.reject(errors),
                // If '.all' rejected, we've got the result we wanted.
                (val) => Promise.resolve(val),
            )
            .then((newToken) => {
                return newToken;
            })
            .catch(() => {
                return new AnonymousUserToken();
            });
    }

    getProviderFor<T = AuthProviderInterface>(token): T {
        const provider = this.providers.find(
            (p) => p.support && p.support(token),
        );
        if (!provider) {
            throw new ProviderNotFoundError(`getProviderFor`);
        }
        return (provider as unknown) as T;
    }

    getProvider<T = AuthProviderInterface>(ProviderType): T {
        const provider = this.providers.find((p) => p instanceof ProviderType);
        if (!provider) {
            throw new Error(
                `Unable to find Auth Provider with type ${ProviderType}`,
            );
        }
        return (provider as unknown) as T;
    }
}
