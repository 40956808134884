import { Grid, TextField, withStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent, memo, useState } from 'react';

const StyledTextField = withStyles((theme: Theme) => ({
    root: {
        '& label': {
            color: theme.palette.text.secondary,
        },
        '&.Mui-focused label': {
            color: theme.palette.text.secondary,
        },
    },
}))(TextField);

interface Props {
    /**
     * Ovveride default input hint.
     */
    text?: string;
    onSubmit: (string) => void;
}

const SearchCauses: FunctionComponent<Props> = (props) => {
    const [input, setInput] = useState('');
    return (
        <Grid container spacing={2} direction="row" justify="space-around">
            <StyledTextField
                fullWidth
                value={input}
                onChange={(event) => setInput(event.target.value)}
                onKeyPress={(event) => {
                    if (event.key.toLowerCase() === 'enter') {
                        props.onSubmit(input);
                        setInput('');
                    }
                }}
                label={props.text ? props.text : '+ Add causes you care about.'}
            />
        </Grid>
    );
};

export const OnboardingSearchCauses = memo(SearchCauses);
