import Compressor from 'compressorjs';
import { environment } from '../../../../environments/environment';
import { Auth } from '@aws-amplify/auth';

const IMAGE_MIME_TYPE = 'image/png';

export const squeezeImage = async (file) => {
    const promise = new Promise((resolve, reject) => {
        new Compressor(file, {
            strict: true,
            checkOrientation: true,
            maxWidth: 800,
            maxHeight: 800,
            minWidth: 200,
            minHeight: 200,
            quality: 0.8,
            mimeType: IMAGE_MIME_TYPE,
            convertSize: 10000,
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err);
            },
        });
    });
    return promise;
};

export const generatePictureData = async (key) => {
    const date = Date.now();
    const { identityId } = await Auth.currentCredentials();

    return {
        bucket: environment.amplify.aws_user_files_s3_bucket,
        region: environment.amplify.aws_user_files_s3_bucket_region,
        identity: identityId,
        key,
        mimeType: IMAGE_MIME_TYPE,
        visibility: 'protected',
        // localUri: file,
        updatedAt: date,
    };
};
