import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import {
    PersonRolesStoreInterface,
    PersonRolesStore,
} from '../PersonRolesStore';

export const usePersonRolesData = (): Omit<PersonRolesStoreInterface, ''> => {
    const store = useStore<PersonRolesStoreInterface>(PersonRolesStore);

    return useObserver(() => ({
        activeRoles: store.activeRoles,
        isLoadingStoreData: store.isLoadingStoreData,
        departments: store.departments,
        isThereFinishedTest: store.isThereFinishedTest,
        allAddedRoles: store.allAddedRoles,
        allRoles: store.allRoles,
        defaultRoles: store.defaultRoles,
        loadDepartments: store.loadDepartments,
        loadRoles: store.loadRoles,
        addRole: store.addRole,
        updateRole: store.updateRole,
        deleteRole: store.deleteRole,
        loadDefaultsRoles: store.loadDefaultsRoles,
        loadAutocompleteSkillsOptions: store.loadAutocompleteSkillsOptions,
        loadMyLastPassedTestResults: store.loadMyLastPassedTestResults,
    }));
};
