import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import {
    WeChipsList,
    WeContactList,
    WeEditable,
    WeSocialMediaList,
    WeTextField,
    SectionWithTitle,
    WeContactItem,
    LocationAutoComplete,
    WeMultilineText,
    useScrollTopHelper,
} from '@weco/ui';
import { Field, Formik, FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import { UserProfileEntity } from '@weco/core';
import debug from 'debug';
import { getValidationResults } from '@weco/common';
import { DynamicChipsFormControl } from '../formControls/DynamicChipsFormControl';
import { useTypographyStyles } from '../../../theme/styles';
import { objectToTypeValue } from '../../../helpers/utils';
import {
    ContactsFormControl,
    AutocompleteFormControl,
    SocialMediaFormControl,
} from '../formControls';
import { useTabStyles } from '../../../components/molecules/ProjectProfilePage/MainTabs/styles/TabContent.styles';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { useLocation } from 'react-router-dom';

const log = debug('InfoTabPage');

// TODO: rename this file and component into something more obvious.
export const InfoTabPage = () => {
    const { profile, constants, updateProfile } = useMyUserProfileData();
    const typographyClasses = useTypographyStyles();
    const tabClasses = useTabStyles();
    const { state } = useLocation() as any;
    const fromBanner = state?.fromBanner;
    if (fromBanner) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollTopHelper();
    }

    function save(newProfile) {
        if (isEqual(newProfile, profile)) {
            return Promise.resolve();
        }
        return updateProfile(newProfile);
    }

    return (
        <Box className={tabClasses.tabContent}>
            <Formik
                initialValues={profile}
                validate={getValidationResults}
                enableReinitialize
                onSubmit={save}
            >
                {({
                    handleSubmit,
                    values,
                    errors,
                    isValid,
                }: FormikProps<UserProfileEntity>) => {
                    log('form values: %O', values);
                    return (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={tabClasses.section}
                                id="container-anchor-my-profile-dream"
                            >
                                <WeEditable
                                    fieldId="DREAM"
                                    isFormValid={isValid}
                                    hasError={!!errors.dream}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <SectionWithTitle title="About Me">
                                            <Field
                                                name="dream"
                                                component={WeTextField}
                                                multiline
                                            />
                                        </SectionWithTitle>
                                    }
                                >
                                    <SectionWithTitle
                                        title="About Me"
                                        isEmpty={!values.dream}
                                        placeholder="Introduce yourself in a sentence, and maybe even share a fun fact about you."
                                    >
                                        <Typography
                                            className={typographyClasses.info}
                                            component="div"
                                        >
                                            <WeMultilineText
                                                text={values.dream}
                                            />
                                        </Typography>
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={tabClasses.section}
                                id="container-anchor-my-profile-causes"
                            >
                                <WeEditable
                                    fieldId="CAUSES"
                                    isFormValid={isValid}
                                    hasError={!!errors.causes}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <AutocompleteFormControl
                                            label="Causes"
                                            name="causes"
                                            options={constants?.causes}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Causes"
                                        isEmpty={isEmpty(values.causes)}
                                        placeholder="If you could start a revolution, what would it be about?"
                                    >
                                        <WeChipsList items={values.causes} />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={tabClasses.section}
                                id="container-anchor-my-profile-passions"
                            >
                                <WeEditable
                                    fieldId="PASSIONS"
                                    isFormValid={isValid}
                                    hasError={!!errors.passions}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <DynamicChipsFormControl
                                            name="passions"
                                            label="Passions"
                                            lowerCase
                                            isOpenField
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Passions"
                                        isEmpty={isEmpty(values.passions)}
                                        placeholder="Motivations you can’t live without! Tacos maybe? Movies? Movies about tacos?"
                                    >
                                        <WeChipsList items={values.passions} />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={tabClasses.section}
                                id="container-anchor-my-profile-objectives"
                            >
                                <WeEditable
                                    fieldId="OBJECTIVES"
                                    isFormValid={isValid}
                                    hasError={!!errors.objectives}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <AutocompleteFormControl
                                            name="objectives"
                                            label="Objectives"
                                            options={constants?.objectives}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Objectives"
                                        isEmpty={isEmpty(values.objectives)}
                                        placeholder="WeCo is all about connections, but what kind of connections are you looking for?"
                                    >
                                        <WeChipsList
                                            items={values.objectives}
                                        />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={tabClasses.section}
                                id="container-anchor-my-profile-location"
                            >
                                <WeEditable
                                    fieldId="LOCATION"
                                    isFormValid={isValid}
                                    onSave={() => handleSubmit()}
                                    classes={{ editBox: tabClasses.editBox }}
                                    // TODO
                                    hasError={!!errors.location}
                                    editableComponent={
                                        <SectionWithTitle
                                            title="Location"
                                            children={
                                                <LocationAutoComplete
                                                // currentSelection={
                                                //     values?.location
                                                // }
                                                />
                                            }
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Location"
                                        isEmpty={isEmpty(values.location)}
                                        placeholder={
                                            <div>
                                                Like a rapper from England would
                                                say:
                                                <br />
                                                Where abouts you from, mate?
                                            </div>
                                        }
                                    >
                                        <Typography
                                            className={typographyClasses.info}
                                        >
                                            {values?.location?.name}
                                        </Typography>
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>
                            {/* NOTE: hidden until Dwight school collaboration will be established. */}
                            {/* <Grid item xs={12} className={tabClasses.section}> */}
                            {/*    <WeEditable */}
                            {/*        hasError={!!errors.school} */}
                            {/*        onSave={() => submit('schools')} */}
                            {/*        editableComponent={ */}
                            {/*            <SectionWithTitle */}
                            {/*                title="Schools" */}
                            {/*                children={ */}
                            {/*                    <SchoolPickerForm */}
                            {/*                        name="school" */}
                            {/*                        values={constants?.schools} */}
                            {/*                    /> */}
                            {/*                } */}
                            {/*            /> */}
                            {/*        } */}
                            {/*    > */}
                            {/*        <SectionWithTitle */}
                            {/*            title="Schools" */}
                            {/*            isEmpty={isEmpty(values.school)} */}
                            {/*        /> */}
                            {/*        <WeChipsList */}
                            {/*            items={[values.school?.name]} */}
                            {/*        /> */}
                            {/*    </WeEditable> */}
                            {/* </Grid> */}

                            <Grid
                                item
                                xs={12}
                                className={tabClasses.section}
                                id="container-anchor-my-profile-languages"
                            >
                                <WeEditable
                                    fieldId="LANGUAGES"
                                    isFormValid={isValid}
                                    hasError={!!errors.languages}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <AutocompleteFormControl
                                            label="Languages"
                                            name="languages"
                                            options={constants?.languages}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Languages"
                                        isEmpty={isEmpty(values.languages)}
                                    >
                                        <WeChipsList items={values.languages} />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={tabClasses.section}
                                id="container-anchor-my-profile-contacts"
                            >
                                <WeEditable
                                    fieldId="CONTACTS"
                                    isFormValid={isValid}
                                    hasError={!!errors.contacts}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <ContactsFormControl
                                            name="contacts"
                                            label="Contacts"
                                            options={constants?.contacts}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Contacts"
                                        isEmpty={isEmpty(values.contacts)}
                                    >
                                        <WeContactList
                                            onClick={console.log}
                                            items={
                                                objectToTypeValue(
                                                    values.contacts,
                                                ) as WeContactItem[]
                                            }
                                        />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                id="container-anchor-my-profile-social-media"
                            >
                                <WeEditable
                                    fieldId="SOCIAL_MEDIA"
                                    isFormValid={isValid}
                                    hasError={!!errors.socialMedia}
                                    onSave={handleSubmit}
                                    classes={{ editBox: tabClasses.editBox }}
                                    editableComponent={
                                        <SocialMediaFormControl
                                            name="socialMedia"
                                            label="Social Media"
                                            options={constants?.socialMedia}
                                        />
                                    }
                                >
                                    <SectionWithTitle
                                        title="Social Media"
                                        isEmpty={isEmpty(values.socialMedia)}
                                        placeholder="What were your favorite social media networks before WeCo?"
                                    >
                                        <WeSocialMediaList
                                            onClick={console.log}
                                            items={objectToTypeValue(
                                                values.socialMedia,
                                            )}
                                        />
                                    </SectionWithTitle>
                                </WeEditable>
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </Box>
    );
};
