import React from 'react';
import { makeStyles, Box, CircularProgress, Theme } from '@material-ui/core';

const useLoaderStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: 'transparent',
    },
    backdrop: {
        backgroundColor: 'rgba(0,0,0,.5)',
        zIndex: 1300,
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
    },
    backdropCenter: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    center: {
        textAlign: 'center',
    },
    loader: {
        color: theme.palette.info[200],
        position: 'relative',
        zIndex: 1301,
    },
}));

interface props {
    hideBackdrop?: boolean;
}

export const CircleLoader = (props: props) => {
    const { hideBackdrop } = props;

    const classes = useLoaderStyles();

    const backdropClass = hideBackdrop ? classes.root : classes.backdrop;
    const containerClass = hideBackdrop
        ? classes.center
        : classes.backdropCenter;

    return (
        <Box className={backdropClass}>
            <Box
                className={containerClass}
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress className={classes.loader} />
            </Box>
        </Box>
    );
};
