import React from 'react';
import {
    RoomsPropsInterface,
    RoomsStateInterface,
} from '../models/interfaces/RoomsInterfaces';
import { UserRoomInterface } from '../models/interfaces/UserRoomInterface';
import { RoomInterface } from '../models/interfaces/RoomInterface';
import {
    Box,
    Button,
    createStyles,
    Grid,
    IconButton,
    List,
    Theme,
    Typography,
    useTheme,
    withStyles,
} from '@material-ui/core';
import Room from './Room';
import { UsersListInterface } from '../models/interfaces/UserInterface';
import Loader from './Loader';
import { RoomsFilter } from './RoomsFilter';
import SearchUserDialog from './SearchUserDialog';
import { ReferenceDataInterface } from '../models/interfaces/ReferenceDataInterface';
import get from 'lodash/get';
import debug from 'debug';
import { ROOM_TYPE_PEOPLE } from '../utils/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';
import { SpriteSvgIcon } from '@weco/ui';

const styles: any = (theme: Theme) =>
    createStyles({
        chatHeader: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.secondary[650],
            fontWeight: 400,
        },
        arrowBack: {
            color: theme.palette.secondary[650],
            padding: 8,
            marginRight: 4,
        },
        newMessageIcon: {
            padding: 8,
        },
    });

const withDesktopMode = () => (Component) => (props) => {
    const theme = useTheme();
    const desktopMode = useMediaQuery(theme.breakpoints.up('md'));
    return <Component desktopMode={desktopMode} {...props} />;
};

const withHistory = () => (Component) => (props) => {
    const history = useHistory();
    return <Component history={history} {...props} />;
};

const log = debug('Rooms');

class Rooms extends React.PureComponent<
    RoomsPropsInterface,
    RoomsStateInterface
> {
    state = {
        searchUsersDialogOpen: false,
    };

    componentDidMount() {
        this.props.fetchRooms(
            this.props.firestore,
            this.props.currentUser.rooms,
        );
    }

    componentDidUpdate() {
        const unsubscribedRooms = (this.props.currentUser.rooms || []).filter(
            (userRoom: UserRoomInterface) => {
                const subscribed = Object.keys(this.props.rooms).findIndex(
                    (roomId: string) => roomId === userRoom.room.id,
                );
                return subscribed === -1;
            },
        );
        if (unsubscribedRooms.length) {
            this.props.fetchRooms(this.props.firestore, unsubscribedRooms);
        }
    }

    getRoomUnreadCount(room: RoomInterface): number {
        const r = (this.props.currentUser.rooms || []).find(
            (i) => i.room.id === room.id,
        );
        return get(r, 'unreadCount', 0);
    }

    setActiveRoom(roomId: string, subscribe: boolean) {
        const userRoom = this.props.currentUser.rooms.find(
            (userRoom) => userRoom.room.id === roomId,
        );
        if (userRoom && userRoom.unreadCount > 0) {
            this.props.updateUserRooms(
                this.props.firestore,
                this.props.currentUser.id,
                this.props.currentUser.rooms.map((userRoom) => {
                    if (userRoom.room.id === roomId) {
                        userRoom.unreadCount = 0;
                    }
                    return userRoom;
                }),
            );
        }
        this.props.setActiveRoom(this.props.firestore, roomId, subscribe);
    }

    render() {
        const { props, state } = this;
        const { currentUser, classes } = props;
        const userRoomsMap = new Map<string, string>();
        const filteredRooms = Object.values(props.rooms).filter(
            (room: RoomInterface) =>
                !!room &&
                (props.filterType === '' || room.type === props.filterType),
        );
        log('filteredRooms: ', filteredRooms);

        for (const roomId in props.rooms) {
            if (
                props.rooms[roomId] &&
                props.rooms[roomId].type === ROOM_TYPE_PEOPLE
            ) {
                const user: ReferenceDataInterface = props.rooms[
                    roomId
                ].members.find((member) => member.id !== currentUser.id);

                if (user) {
                    userRoomsMap.set(user.id, roomId);
                }
            }
        }

        return (
            <>
                {(props.loading || props.dataLoading) && <Loader />}
                <>
                    <Box display="flex" justifyContent="space-between" mb={3}>
                        <Typography
                            variant={props.desktopMode ? 'h1' : 'h2'}
                            component="div"
                            className={classes.chatHeader}
                        >
                            {!props.desktopMode && (
                                <IconButton
                                    className={classes.arrowBack}
                                    onClick={props.history.goBack}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                            {props.chatTitle}
                        </Typography>
                        <IconButton
                            className={classes.newMessageIcon}
                            onClick={() => {
                                props.clearSearchUsers();
                                this.setState({
                                    searchUsersDialogOpen: true,
                                });
                            }}
                        >
                            <SpriteSvgIcon id="Chat-New-Message" />
                        </IconButton>
                    </Box>
                    <RoomsFilter
                        rooms={props.rooms}
                        type={props.filterType}
                        setType={props.setFilterType}
                        currentUser={props.currentUser}
                    />
                    {!!Object.keys(props.rooms).length && (
                        <List component="nav" style={{ overflowY: 'scroll' }}>
                            {filteredRooms.map((room: RoomInterface) => {
                                const roomUsers: UsersListInterface = {};
                                room.members.forEach(({ id }) => {
                                    roomUsers[id] = props.users[id];
                                });

                                return (
                                    <Room
                                        room={room}
                                        key={room.id}
                                        users={roomUsers}
                                        currentUser={currentUser}
                                        isActive={room.id === props.activeRoom}
                                        unreadCount={this.getRoomUnreadCount(
                                            room,
                                        )}
                                        setActiveRoom={(roomId: string) => {
                                            this.setActiveRoom(
                                                roomId,
                                                !props.subscribedRoomsIds ||
                                                    !props.subscribedRoomsIds.includes(
                                                        roomId,
                                                    ),
                                            );
                                        }}
                                        onAvatarClick={this.props.onAvatarClick}
                                    />
                                );
                            })}
                        </List>
                    )}
                    <SearchUserDialog
                        excludedUsers={[]}
                        searchUsersDialogOpen={
                            state.searchUsersDialogOpen
                            // ||
                            // (props.activeRoom &&
                            //     !props.rooms[props.activeRoom])
                            //NOTE:
                            // Need to discuss about these commented lines. do we need them?
                            // I commented them for the correct loading of the new chat from other profile/project,
                            // but there is still a small jamb. This can be only temporary solution
                            // does anyone have an idea how to make the loading of this page better?
                            // anyway I think the logic of the fetchRooms function needs to be redone
                        }
                        handleCloseSearchUserDialog={() => {
                            this.setState({
                                searchUsersDialogOpen: false,
                            });
                            props.clearSearchUsers();
                        }}
                        searchUsers={props.searchUsers}
                        findUserByName={props.findUserByName}
                        searchedUsers={props.searchUsersList}
                        handleUserClick={(userId: string) => {
                            if (userRoomsMap.has(userId)) {
                                this.setActiveRoom(
                                    userRoomsMap.get(userId),
                                    false,
                                );
                            } else {
                                props.createPeopleRoom(
                                    this.props.firestore,
                                    props.createRoom,
                                    {
                                        type: ROOM_TYPE_PEOPLE,
                                        userIds: [currentUser.id, userId],
                                    },
                                );
                            }
                            this.setState({
                                searchUsersDialogOpen: false,
                            });
                        }}
                    />
                </>
            </>
        );
    }
}

export default withHistory()(withStyles(styles)(withDesktopMode()(Rooms)));
