import { useObserver } from 'mobx-react';
import { useStore } from '@weco/common';
import { QuizStore } from './QuizStore';
import { useCallback, useState } from 'react';

export const useQuizData = () => {
    const store = useStore<QuizStore>(QuizStore);
    const [loading, setLoading] = useState(false);
    const doLoadInitData = () => {
        setLoading(true);
        store
            .reset()
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    useCallback(doLoadInitData, []);

    return useObserver(() => ({
        questions: store.questions,
        answers: store.answers,
        activePage: store.activePage,
        showContinueQuestion: store.showContinueQuestion,
        loading: loading,
        test: store.test,
        setAnswer: store.setAnswer,
        nextPage: store.nextPage,
        onFinish: store.finish,
        reset: store.reset,
        handleStartNew: store.handleStartNew,
        handleTryAgain: store.handleTryAgain,
        handleContinue: store.handleContinue,
        onClose: store.onClose,
        calculateResult: store.calculateResult,
        prevPage: store.prevPage,
    }));
};
