import React from 'react';
import { Grid } from '@material-ui/core';
import { InfoTabPage } from './InfoTabPage';
import { SkillsTabPage } from './SkillsTabPage';
import { MyProfileHeader } from './MyProfileHeader';
import { ResourcesTabPage } from './ResourcesTabPage';
import { NavTabs, SpriteSvgIcon } from '@weco/ui';
import { useProjectProfileContainerStyles } from '../styles/MePage.styles';
import { useMyUserProfileData } from '../../../store/hooks/useMyUserProfileData';
import { PersonRolesContainer } from '../../../store/containers/PersonRolesContainer';

export const MyProfilePage = ({ baseUrl }) => {
    const classes = useProjectProfileContainerStyles();
    const { currentUserId } = useMyUserProfileData();

    return (
        <PersonRolesContainer personId={currentUserId}>
            <Grid container>
                <Grid item xs={12}>
                    <MyProfileHeader />
                </Grid>
                <Grid item xs={12} className={classes.root} id="tabs-layout">
                    <NavTabs
                        tabs={[
                            {
                                label: 'Bio',
                                icon: <SpriteSvgIcon id="bio-primary" />,
                                to: `${baseUrl}`,
                                component: InfoTabPage,
                            },
                            {
                                label: 'Skills',
                                icon: <SpriteSvgIcon id="skill-team-primary" />,
                                to: `${baseUrl}/skills/`,
                                component: SkillsTabPage,
                                componentProps: {
                                    baseUrl: `${baseUrl}/skills`,
                                },
                            },
                            {
                                label: 'Resources',
                                icon: <SpriteSvgIcon id="resources-primary" />,
                                to: `${baseUrl}/resources`,
                                component: ResourcesTabPage,
                                componentProps: {
                                    baseUrl: `${baseUrl}/resources`,
                                },
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </PersonRolesContainer>
    );
};
