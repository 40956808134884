import {
    Grid,
    TextField,
    IconButton,
    Box,
    makeStyles,
    Theme,
    createStyles,
} from '@material-ui/core';
import React, { memo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { SkillEntity } from '@weco/core';
import { APP_EVENTS } from '../../../../../apps/client/src/app_events';
import { COMMON_SERVICES, EventDispatcher, useInjection } from '@weco/common';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiInputBase-input': {
                textTransform: 'lowercase',
            },
            display: 'flex',
            justifyContent: 'center',
        },
        searchField: {
            minWidth: '150px',
        },
        autocompleteBox: {
            marginLeft: '35px',
            [theme.breakpoints.down(380)]: {
                marginLeft: '-15px',
            },
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
    }),
);

export interface SearchSkillFieldProps {
    add(item: SkillEntity);
    autocompleteOptions?: SkillEntity[];
    selectedSkills?: SkillEntity[];
}

const component = ({
    add,
    autocompleteOptions,
    selectedSkills,
}: SearchSkillFieldProps) => {
    const [value, setValue] = React.useState<SkillEntity | null>(null);
    autocompleteOptions = autocompleteOptions || [];
    const filter = createFilterOptions<SkillEntity>();
    const eventDispatcher = useInjection<EventDispatcher>(
        COMMON_SERVICES.CoreEventDispatcher,
    );
    const classes = useStyles();
    return (
        <Grid container className={classes.root} direction="row">
            <Box className={classes.autocompleteBox}>
                <Autocomplete
                    className={classes.searchField}
                    id="addSkillSets"
                    fullWidth
                    freeSolo
                    options={autocompleteOptions}
                    onChange={(event, value) => {
                        setValue(value as SkillEntity);
                    }}
                    filterOptions={(options, params) => {
                        let filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                            filtered.push({
                                value: params.inputValue.toLowerCase(),
                            });
                        }

                        const selectedSkill = selectedSkills.map(
                            (item) => item.value,
                        );
                        filtered = filtered.filter(
                            (defaultSkills) =>
                                !selectedSkill.includes(defaultSkills.value),
                        );

                        return filtered;
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    getOptionLabel={(item: SkillEntity) => item.value || ''}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search or add Skills."
                            variant="outlined"
                        />
                    )}
                />
                <IconButton
                    disabled={value == null}
                    onClick={() => {
                        add(value);
                        setValue(null);
                    }}
                    onMouseDown={() => {}}
                    edge="end"
                >
                    <AddIcon />
                </IconButton>
            </Box>
        </Grid>
    );
};

export const SearchSkillField = memo(component);
