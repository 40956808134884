import React, { useState } from 'react';
import {
    IconButton,
    Dialog,
    DialogTitle,
    Typography,
    DialogActions,
    Button,
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconTypes, IconByType } from './IconByType';
import { TypeValueInterface } from '@weco/core';
import IconClear from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        color: theme.palette.text.secondary,
        margin: 5,
    },
    container: {
        display: 'inline',
    },
    typography: {
        padding: 20,
    },
    button: {
        paddingLeft: '20px',
        paddingRight: '20px',
        margin: '0 10px',
        width: '78px',
    },
    buttonContainer: {
        paddingTop: 10,
        textAlign: 'center',
    },
    mediumIcon: {
        verticalAlign: 'middle',
        margin: '0 auto 12px',
        display: 'block',
    },
    iconButtonStyle: {
        paddingLeft: '0px',
        paddingRight: '20px',
    },
    dialogTitle: {
        padding: '20px',
        backgroundColor: 'transparent',
    },
    titleText: {
        color: theme.palette.text.secondary,
        textOverflow: 'ellipsis',
        width: '100%',
        flex: '0.95 1',
        overflow: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0px 0 25px',
    },
}));

const isLink = (v) =>
    v.search(/http:\/\/.+?\..+/gi) > -1 || v.search(/https:\/\/.+?\..+/gi) > -1;

const makeUri = ({ value }) => (isLink(value) ? value : `http://${value}`);

const goTo = (uri, isNewTab) => window.open(uri, isNewTab && '_blank');

export interface WeMediaItem {
    /**
     * Icon name.
     */
    type: IconTypes;
    value?: any;
}

export interface WeSocialMediaListProps {
    items: WeMediaItem[] | TypeValueInterface[];
    onClick?: (item: WeMediaItem) => void;
}

export const WeSocialMediaList = ({ items }: WeSocialMediaListProps) => {
    // const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    //** @todo how to declare empty initial object in hook? */
    const [currentItem, setCurrentItem] = useState({ type: '', value: '' });
    const [currentIconType, setCurrentIconType] = useState(null);
    // const { goTo } = useNav()
    // const open = Boolean(anchorEl);

    const onClick = (value) => (event) => {
        event.stopPropagation();
        setCurrentItem(value);
        setOpen(true);
        setCurrentIconType(value.type);
    };

    const onConnect = () => {
        setOpen(false);
        goTo(makeUri(currentItem), true);
    };

    const onCopy = () => {
        navigator.clipboard.writeText(currentItem.value);
        setOpen(false);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const handleDialogClick = (e) => {
        e.stopPropagation();
    };

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={closeDialog}
                onClick={handleDialogClick}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialogTitle}
                >
                    <IconButton
                        onClick={closeDialog}
                        style={{
                            color: 'lightgrey',
                            padding: '0',
                            position: 'absolute',
                            right: '6px',
                            top: '6px',
                        }}
                    >
                        <IconClear />
                    </IconButton>
                    {currentIconType && (
                        <Typography
                            align="center"
                            className={classes.titleText}
                        >
                            <IconButton className={classes.mediumIcon}>
                                <IconByType type={currentIconType} />
                            </IconButton>
                            {currentItem.value}
                        </Typography>
                    )}
                </DialogTitle>
                <DialogActions className={classes.dialogActions}>
                    <Box style={{ padding: '0 30px' }}>
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.button}
                            onClick={onCopy}
                        >
                            Copy
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => onConnect()}
                        >
                            Connect
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {(items as WeMediaItem[]).map((contact, i) => {
                if (!contact.value) return null;
                return (
                    <IconButton key={i} onClick={onClick(contact)}>
                        <IconByType type={contact.type} />
                    </IconButton>
                );
            })}
        </div>
    );
};
